import { StyleSheet } from 'react-native';

const style = StyleSheet.create({
    lr_container: {
      padding: 15,
      width: '400px',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      alignContent: 'center'
    },
    lr_header_tabs: {
      alignSelf: 'center',
      flexDirection: 'row',
      flex: 1
    },
    lr_tab: { 
      alignItems: 'center',
      flexDirection: 'column'
    },
    page_container: { 
      flex: 1,
      width: '100%',
      alignContent: 'center',
      alignItems: 'center'
    }
});

export {
    style
}