import { StyleSheet, Dimensions } from 'react-native';

let width = Dimensions.get('window').width;
let height = Dimensions.get('window').height;

let global_style = StyleSheet.create({
  drop_style: { 
    width: 180, 
    borderWidth: 1,
    padding: 5,
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 6,
    backgroundColor: 'white',
    fontSize: 14,
    fontFamily: 'Comfortaa',
    color: '#575762',
    borderColor: '#bbbbc0'
  },
  modal_x_wrapper: {
    alignSelf: 'flex-end',
    cursor: 'pointer',
    backgroundColor: '#e32652',
    borderRadius: 100,
    display: 'flex',
    justifyContent: 'center',
    top: 10,
    right: 10,
    zIndex: 1000000
  },
  modal_close_x: {
    fontFamily: 'Comfortaa',
    fontSize: 15,
    color: 'white',
    width: 20,
    height: 20,
    padding: 0,
    paddingTop: 6,
    lineHeight: 8,
    textAlign: 'center',
    zIndex: 1000000
  },
  message_link: {
    marginLeft: 20
  },
  one_message: {
    padding: 10,
    flexDirection: 'row'
  },
  no_messages_text: {
    fontFamily: 'Ysabeau',
    fontSize: 18,
    margin: 20
  },
  chat_text: {
    fontFamily: 'Ysabeau',
    fontSize: 18
  },
  chat_time: {
    fontFamily: 'Comfortaa',
    fontSize: 11
  },
  chat_data: {
    marginLeft: 10,
    width: width < 600 ? '70%' : 400
  },
  chat_bottom: {
    display: 'flex',
    flexDirection: 'row'
  },
  chat_messages_wrapper: {
    backgroundColor: '#f7f8fc',
    maxHeight: 200,
    marginBottom: 10,
    borderRadius: 5
  },
})

let style_small = StyleSheet.create({
  ...global_style,
  footer_container: {
    height: 303,
    width: '100%',
    flexDirection: 'row',
    padding: 10,
    paddingTop: 20,
    borderTopWidth: 1,
    borderColor: '#dcd3d2'
  },
  logo_text: {
    fontFamily: 'Ysabeau',
    fontSize: '20px',
    /* textShadowColor: 'rgba(0, 0, 0, 1)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 5 */
  },
  main_columns: {
    flexDirection: 'column',
    //height: 50,
    flex: 2,
    //marginTop: 20,
    width: '50%',
    marginTop: 40
  },
  main_columns_first: {
    width: 168,
    flex: 'unset',
    height: 303,
    justifyContent: 'space-between'
  },
  one_column: {
    flexDirection: 'column',
    //flex: 1
  },
  footer_link: {
    fontFamily: 'Ysabeau',
    textDecorationLine: 'underline',
    fontSize: 14,
    marginBottom: 10
  },
  contact_link: {
    fontFamily: 'Ysabeau',
    fontSize: 14,
    marginBottom: 10
  },
  copyrite: {
    marginTop: 0,
    marginBottom: 20,
    width: 200
  },
  footer_socials: {
    flexDirection: 'row'
  },
  social_insta: {
    marginRight: 10
  }
});

let style_normal = StyleSheet.create({
  ...global_style,
  footer_container: {
    height: 200,
    width: '100%',
    flexDirection: 'row',
    padding: 40,
    borderTopWidth: 1,
    borderColor: '#dcd3d2'
  },
  logo_text: {
    fontFamily: 'Ysabeau',
    fontSize: '30px',
    borderLeftWidth: 5,
    borderColor: '#201e1e',
    paddingLeft: 10,
    lineHeight: 30
    /* textShadowColor: 'rgba(0, 0, 0, 1)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 5 */
  },
  main_columns: {
    flexDirection: 'row',
    height: 50,
    flex: 2,
    marginTop: 20
  },
  one_column: {
    flexDirection: 'column',
    flex: 1
  },
  footer_link: {
    fontFamily: 'Ysabeau',
    textDecorationLine: 'underline',
    fontSize: 14,
    marginBottom: 10
  },
  contact_link: {
    fontFamily: 'Ysabeau',
    fontSize: 14,
    marginBottom: 10
  },
  copyrite: {
    marginTop: 40
  },
  footer_socials: {
    flexDirection: 'row'
  },
  social_insta: {
    marginRight: 10
  }
});

let style = width <= 600 ? style_small : style_normal;

export {
  style
}