import { StyleSheet, Dimensions } from 'react-native';

let width = Dimensions.get('window').width;
let height = Dimensions.get('window').height;

let style_small = StyleSheet.create({
  title: {
    fontFamily: 'Ysabeau',
    fontSize: 24,
    alignSelf: 'flex-start',
    marginTop: 30
  },
  label: {
    fontFamily: 'Comfortaa',
    fontSize: 16,
    alignSelf: 'flex-start',
    lineHeight: 30,
    marginBottom: 10,
    marginTop: 20
  },
  check_wrapper: {
    marginLeft: 35,
    marginBottom: 40
  },
  main_title: {
    fontFamily: 'Ysabeau',
    fontSize: 40,
    marginVertical: 40,
    textAlign: 'center'
  },
  explainer_intro: {
    fontFamily: 'Comfortaa',
    fontSize: 18,
    textAlign: 'center',
    alignSelf: 'flex-start',
    marginVertical: 20,
    lineHeight: 30,
  },
  underline: {
    fontFamily: 'Comfortaa',
    fontSize: 18,
    textAlign: 'center',
    alignSelf: 'flex-start',
    marginVertical: 20,
    lineHeight: 30,
    textDecorationLine: 'underline'
  },
  check_text_wrapper: {
    width: '80vw'
  },
  check_text: {
    marginLeft: 10,
    fontFamily: 'Ysabeau',
    fontSize: 16,
    
  },
  lr_header_tabs: {
    alignSelf: 'center',
    flexDirection: 'row',
    flex: 1
  },
  lr_tab: { 
    alignItems: 'center',
    flexDirection: 'column'
  },
  page_container: { 
    flex: 1,
    width: '90vw',
    alignContent: 'center',
    alignItems: 'center',
    margin: 'auto'
  },
  login_text: {
    fontFamily: 'Ysabeau',
    fontSize: 30,
    marginTop: 30
  },
  login_button: {
    backgroundColor: 'black',
    borderRadius: 30,
    fontFamily: 'Comfortaa',
    width: '70vw',
    alignSelf: 'center'
  },
  inactive_login_button: {
    backgroundColor: '#d9d8e4',
    borderRadius: 30,
    fontFamily: 'Comfortaa'
  },
  line_breaker: {
    height: 20
  },
  input_box: {
    width: 350
  },
  input_box_multi: {
    width: '80vw',
    alignSelf: 'center',
    height: 60,
  },
  register_wrapper: {
    width: 300,
    flexDirection: 'row',

    marginTop: 30,
    marginBottom: 10
  },
  dont_text: {
    fontFamily: 'Comfortaa',
    fontSize: 12
  },
  link_wrap: {

  },
  register_link: {
    fontFamily: 'Comfortaa',
    color: '#0b6665',
    fontSize: 12,
    marginLeft: 5,
    textDecorationLine: 'underline'
  },
  error_text: {
    color: 'red',
    marginTop: 5,
    textAlign: 'flex-end',
    fontFamily: 'Ysabeau',
    fontSize: 16,
    alignSelf: 'flex-end'
  },
  intro: {
    marginTop: 30,
    fontFamily: 'Ysabeau',
    fontSize: 16,
  }
});

let style_normal = StyleSheet.create({
    title: {
      fontFamily: 'Ysabeau',
      fontSize: 24,
      alignSelf: 'flex-start',
      marginTop: 30
    },
    label: {
      fontFamily: 'Comfortaa',
      fontSize: 16,
      alignSelf: 'flex-start',
      marginLeft: 40,
      lineHeight: 30,
      marginBottom: 10,
      marginTop: 20
    },
    check_wrapper: {
      marginLeft: 35,
      marginBottom: 40
    },
    main_title: {
      fontFamily: 'Ysabeau',
      fontSize: 40,
      marginVertical: 40,
      textAlign: 'center'
    },
    explainer_intro: {
      fontFamily: 'Comfortaa',
      fontSize: 18,
      textAlign: 'center',
      alignSelf: 'flex-start',
      marginVertical: 20,
      lineHeight: 30,
    },
    underline: {
      fontFamily: 'Comfortaa',
      fontSize: 18,
      textAlign: 'center',
      alignSelf: 'flex-start',
      marginVertical: 20,
      lineHeight: 30,
      textDecorationLine: 'underline'
    },
    check_text_wrapper: {
      width: 300
    },
    check_text: {
      marginLeft: 10,
      fontFamily: 'Ysabeau',
      fontSize: 16,
      
    },
    lr_header_tabs: {
      alignSelf: 'center',
      flexDirection: 'row',
      flex: 1
    },
    lr_tab: { 
      alignItems: 'center',
      flexDirection: 'column'
    },
    page_container: { 
      flex: 1,
      width: '600px',
      alignContent: 'center',
      alignItems: 'center',
      margin: 'auto'
    },
    login_text: {
      fontFamily: 'Ysabeau',
      fontSize: 30,
      marginTop: 30
    },
    login_button: {
      backgroundColor: 'black',
      borderRadius: 30,
      fontFamily: 'Comfortaa',
      width: 300,
      alignSelf: 'flex-end'
    },
    inactive_login_button: {
      backgroundColor: '#d9d8e4',
      borderRadius: 30,
      fontFamily: 'Comfortaa'
    },
    line_breaker: {
      height: 20
    },
    input_box: {
      width: 350
    },
    input_box_multi: {
      width: 500,
      alignSelf: 'flex-end',
      height: 60,
    },
    register_wrapper: {
      width: 300,
      flexDirection: 'row',

      marginTop: 30,
      marginBottom: 10
    },
    dont_text: {
      fontFamily: 'Comfortaa',
      fontSize: 12
    },
    link_wrap: {

    },
    register_link: {
      fontFamily: 'Comfortaa',
      color: '#0b6665',
      fontSize: 12,
      marginLeft: 5,
      textDecorationLine: 'underline'
    },
    error_text: {
      color: 'red',
      marginTop: 5,
      textAlign: 'flex-end',
      fontFamily: 'Ysabeau',
      fontSize: 16,
      alignSelf: 'flex-end'
    },
    intro: {
      marginTop: 30,
      fontFamily: 'Ysabeau',
      fontSize: 16,
    }
});

let style = width < 620 ? style_small : style_normal;


export {
    style
}