import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { StyleSheet, View, TouchableOpacity, Platform, Image, Dimensions } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { Text, Input, Button } from '../../components';
import UserController from '../../controllers/userController';
import { style } from './style';
import Icon from 'react-native-vector-icons/FontAwesome';
import { ScrollView } from 'react-native-gesture-handler';
import EventController from '../../controllers/eventController';
import { config } from '../../../config';
import { global_style } from '../../../global_style'

class Invites_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
     
    }


    this.drawInvitedEvents = this.drawInvitedEvents.bind(this);
    this.drawMediaElement = this.drawMediaElement.bind(this);

  }

  async componentDidMount() {
    let user_id = await localStorage.getItem('user_id')
    let invited_events = await EventController.getInvitedEvents({});

    let user_invited_events = await EventController.getInvitedUserEvents({});

    invited_events = invited_events.concat(user_invited_events);


    this.setState({
      user_id,
      user_invited_events,
      invited_events,
    })
  }

  drawMediaElement(place, event) {
    let { restaurant_name, link, main_image, main_video } = place ? place : {};

    main_image = main_image ? main_image : event.place_holder_image

    return <Image resizeMode="cover" style={style.image_main} imageStyle={{width: '100%'}} source={{uri: main_image}} />        
  }

  drawInvitedEvents(event, index) {
    let { user_id } = this.state;
    console.log('eventUnouser', event)
    let { event_data, event_id } = event;
    let { chosen_type, chosen_what, accept_invite, status, chosen_time, selected_day, max_age, min_age, message, gender, join_requests } = event_data;
    accept_invite = accept_invite ? accept_invite : [];
    join_requests = join_requests ? join_requests : [];

    let what_drop = config.what_drop;
    let type_drop = config.type_drop;

    console.log('what_drop', what_drop);
    console.log('type_drop', type_drop);

    let name = what_drop.filter(what=>what.value===chosen_what);
    console.log('name', name);
    name = name ? name[0] : {};
    name = name.name;

    let type = type_drop.filter(type=>type.value===chosen_type);
    console.log('type', type);
    type = type ? type[0] : {};
    let main_image = type.placeholder_image;
    type = type.name;

    /* {
      "gender": "Non-binary",
      "max_age": "73",
      "message": "asdf",
      "min_age": "44",
      "created_by": "cc467f19-6954-4151-ba3c-c45c8563511c",
      "chosen_time": "7:30 AM",
      "chosen_type": "1",
      "chosen_what": "1",
      "chosen_where": "985b8107-e91f-43da-9efd-03b624da40f3",
      "selected_day": "2/21/2024"
  } */

    let today_now = new Date();
    let dinner_time = new Date(selected_day)
    dinner_time.setHours(21, 0, 0, 0); // Set the time to 9:00 PM

    // if dinner date at 9pm is greater than now, don't show attendees
    let upcoming_event = dinner_time > today_now;


    let media_element = this.drawMediaElement({main_image}, event_data);
    let date_element = new Date(selected_day);

    let event_link = `/user_invite/${event_id}`

    let participants_element = upcoming_event ? <View style={{flexDirection: 'column'}}>
        <Text style={style.attendees_title}>UPCOMING EVENT</Text>
        <TouchableOpacity onPress={()=>{window.location.replace(event_link)}} style={[style.visit_restaurant, {width: 124}]}><Text style={style.visit_restaurant_text}> GO TO EVENT  &#x2197;</Text></TouchableOpacity>
      </View> : this.drawParticipants(event_id, event.event_data.host, event);

    let months = [
      "Jan", "Feb", "March", "April", "May", "June",
      "July", "Aug", "Sept", "Oct", "Nov", "Dec"
    ];
    date_element = months[date_element.getMonth()] + ' ' + date_element.getDate();

    let accepted_invite = accept_invite.indexOf(user_id) !== -1;

    let join_request = join_requests.filter(a=>a.user_id === user_id);
    join_request = join_request && join_request.length ? join_request[0] : null;

    let requested;
    if(join_request) {
      if(join_request.accepted) {
        accepted_invite = true;
      } else {
        requested = true;
      }
    }

    return <View style={style.event_wrapper}>
        
      <View style={global_style.row_mobile_switch_column}>
        <View style={style.media_wrapper}>
          {media_element}
        </View>

        <View style={[global_style.column_mobile_switch_row, {flex: 1}]}>
          <View style={{flex: 1}}>
            <Text style={style.date_element}>{date_element}</Text>
            <Text style={style.event_name_text}>{name} - {type}</Text>
          </View>
        </View>
      </View>
      <View style={style.bottom_panel}>
        <View>
        {participants_element}
        </View>
        <View>
          {accepted_invite ? <Text style={style.going_button}>You're going</Text> : null}
          {requested ? <Text style={style.request_button}>Request Received</Text> : null}
        </View>
      </View>
    </View>
  }

 render() {
  let { invited_events } = this.state;

  return invited_events && invited_events.length ? <><Text style={style.section_title}>You've been invited</Text>
    {invited_events.map(this.drawInvitedEvents)}
  </> : null
 }

}

function Invites(props) {
  if(Platform.OS === 'web') {
    let navigate = useNavigate();
    return  <Invites_Base {...props} navigate={navigate} />
  } else {
    return  <Invites_Base {...props} navigate={props.navigation.push}  />
  }
}

export default Invites;
