export default class StorageController  {
    
    static async storeVar (name, value, is_demo) {
        if(is_demo) {
            name = `${name}_demo`
        } else {
            let user_id = await localStorage.getItem('user_id');
            name = `${name}_${user_id}`
        }

        await localStorage.setItem(name, JSON.stringify(value))
    }

    static async addValue (name, value, is_demo) {
        if(is_demo) {
            name = `${name}_demo`
        } else {
            let user_id = await localStorage.getItem('user_id');
            name = `${name}_${user_id}`
        }

        let array = await localStorage.getItem(name);

        if(!array || array.length === 0) {
            array = '[]';
        }

        let json_array = JSON.parse(array);
        json_array.push(value);

        await localStorage.setItem(name, JSON.stringify(json_array))
    }

    static async addObjectValue (name, value, is_demo) {
        if(is_demo) {
            name = `${name}_demo`
        } else {
            let user_id = await localStorage.getItem('user_id');
            name = `${name}_${user_id}`
        }

        let jObject = await localStorage.getItem(name);

        //console.log('jObject priorprior', jObject)

        if(!jObject) {
            jObject = '[]';
        }

        /**
         * jObject:
         * project_<user_id> : []
         */

        //console.log('jObject prior', jObject)

        jObject = JSON.parse(jObject);
        jObject.push(value)

        //console.log('jObject after', jObject)

        await localStorage.setItem(name, JSON.stringify(jObject))
    }

    static async getJsonVariable(name, is_demo) {
        if(is_demo) {
            name = `${name}_demo`
        } else {
            let user_id = await localStorage.getItem('user_id');
            name = `${name}_${user_id}`
        }

        let json = await localStorage.getItem(name);

        return JSON.parse(json)
    }

    static async getValue(name) {
        let value = await localStorage.getItem(name);
        return value;
    }
}