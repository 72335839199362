import { StyleSheet, Dimensions } from 'react-native';

let width = Dimensions.get('window').width;
let height = Dimensions.get('window').height;

let global_style = {
  pager_wrapper: {
    alignSelf: 'flex-end',
    marginRight: width > 500 ? 100 : 0,
    flexDirection: 'row', 
    marginBottom: 20,
    width: 100,
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  page_number: {
    fontFamily: 'Ysabeau',
    fontSize: 20,
    paddingHorizontal: 10
  },
  pager_button_enabled: {
    backgroundColor: '#e32652',
    borderRadius: 10,
    paddingVertical: 5,
    paddingHorizontal: 10,
    cursor: 'pointer'
  },
  pager_button_disabled: {
    backgroundColor: '#ecd6db',
    borderRadius: 10,
    paddingVertical: 5,
    paddingHorizontal: 10
  },
  choose_button: {backgroundColor: '#25dafb', marginBottom: width > 500 ? 0 : 20},
  buttons_wrapper: {
    flexDirection: width < 500 ? 'column' : 'row', 
    justifyContent: 'space-between', 
    width: '100%'},
  uninvite_title:{
    fontFamily: 'Ysabeau',
    fontSize: 18,
    marginTop: 10,
    marginBottom: 10,
    textTransform: 'uppercase'
  },
  uninvite_name: {
    fontFamily: 'Ysabeau',
    fontSize: 16
  },
  uninvite_job: {
    fontFamily: 'Ysabeau',
    fontSize: 16
  },
  invite_column: {
    width: width < 500 ? '45%' : '40%'
  },
  uninvite_select: {
    backgroundColor: '#fb9e25',
    borderRadius: 2,
    alignSelf: width < 500 ? 'center' : 'flex-end',
    paddingVertical: 5,
    paddingHorizontal: 10,
    fontFamily: 'Ysabeau'
  },
  experience_text: {
    fontFamily: 'Ysabeau',
    fontSize: 18
  },
  load_more_button: {
    backgroundColor: '#25dafb',
    borderRadius: 2,
    alignSelf: 'center',
    paddingVertical: 5,
    paddingHorizontal: 10,
    fontFamily: 'Ysabeau'
  },
  payment_content: {
    position: 'fixed', 
    width: width <= 700 ? 'unset' : '100vw', 
    height: '100vh', 
    zIndex: 2000000
  },
  a_row: {
    flexDirection: 'row'
  },
  a_column: {
    flexDirection: 'column'
  },
  club_button_text: {
    fontFamily: 'Ysabeau',
    fontSize: 18,
    color: 'white'
  },
  rsvp_column: {
    width: width <= 700 ? 'unset' : 280,
    height: 448,
    borderWidth: 1,
    borderRadius: 7,
    marginRight: width <= 600 ? 0 : 20,
    paddingBottom: 20,
    marginBottom: 20,
    textAlign: 'left',
    borderColor: '#823e3e24',
    position: 'relative'
  },
  subscription_yearly: {
    backgroundColor: '#5134e8',
    width: '253px',
    textAlign: 'center',

  },
  subscription_monthly: {

    backgroundColor: '#218cc5',
    width: '253px',
    textAlign: 'center'
  },
  rsvp_title: {
    padding: 20,
    fontFamily: 'Comfortaa',
    fontSize: 14,
    textAlign: 'center',
    textTransform: 'uppercase',
    backgroundColor: 'black',
    color: 'white',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5
  },
  rsvp_line_top: {
    borderTopWidth: 1,
    borderColor: '#823e3e24',
  },
  rsvp_line_bottom: {
    borderBottomWidth: 1,
    borderColor: '#823e3e24',
  },
  rsvp_line_text: {
    fontFamily: 'Ysabeau',
    fontSize: 15,
    paddingVertical: 10,
    paddingHorizontal: 10
  },
  rsvp_button: {
    alignSelf: 'center',
    padding: 10,
    position: 'absolute',
    bottom: 0
  },
  rsvp_membership_button: {
    alignSelf: 'center',
    padding: 10,
  },
  rsvp_price: {

  },
  rsvp_price_one_time: {
    fontFamily: 'Comfortaa',
    fontSize: 40,
    //backgroundColor: '#11a1b4',
    borderRadius: 5,
    paddingHorizontal: 5,
    paddingBottom: 3,
    //color: 'white',
    alignSelf: 'center',
    /* width: 56,
    height: 40, */
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    fontSize: 24,
    margin: 20
  },
  rsvp_price_member: {
    fontFamily: 'Comfortaa',
    fontSize: 40,
    //backgroundColor: '#1fb411',
    borderRadius: 5,
    paddingHorizontal: 5,
    paddingBottom: 3,
    //color: 'white',
    alignSelf: 'center',
    /* width: 56,
    height: 40, */
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    fontSize: 24,
    margin: 20
  },
  rsvp_price_free: {
    fontFamily: 'Comfortaa',
    fontSize: 40,
    //backgroundColor: '#1fb411',
    borderRadius: 5,
    paddingHorizontal: 5,
    paddingBottom: 3,
    //color: 'white',
    alignSelf: 'center',
    /* width: 56,
    height: 40, */
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    fontSize: 24,
    margin: 20
  },
  more_info: {
    fontFamily: 'Ysabeau',
    color: 'white',
    fontSize: 20,
    marginBottom: 10
  },
  main_rsvp_wrapper: {
    //alignItems: width <= 700 ? 'auto' : 'flex-end',
    marginTop: 20
  },
  fee: {
    marginTop: width <= 700 ? 0 : 5,
    marginLeft: width <= 700 ? 10 : 0
  },
  price_title: {
    color: 'white',
    fontFamily: 'Ysabeau',
    fontSize: 14,
    width: 180,
    textTransform: 'uppercase'
  },
  price_explanation: {
    color: 'white',
    fontFamily: 'Ysabeau',
    fontSize: 14,
    display: 'flex',
    flexWrap: 'wrap',
    width: width <= 700 ? width - 80 : '350px'
  },
  pricing_divider: {
    display: 'flex',
    flexDirection: 'row',
    marginVertical: 5
  },
  price_text_free_event: {
    fontFamily: 'Ysabeau',
    fontSize: 18,
    backgroundColor: '#11a1b4',
    borderRadius: 5,
    paddingHorizontal: 5,
    paddingBottom: 3,
    color: 'white',
    marginRight: 10,
  },
  price_text: {
    fontFamily: 'Ysabeau',
    fontSize: 18,
    backgroundColor: '#11a1b4',
    borderRadius: 5,
    paddingHorizontal: 5,
    paddingBottom: 3,
    color: 'white',
    marginRight: 10,
    width: 36
  },
  price_text_member: {
    fontFamily: 'Ysabeau',
    fontSize: 18,
    backgroundColor: '#1fb411',
    borderRadius: 5,
    paddingHorizontal: 5,
    paddingBottom: 3,
    color: 'white',
    marginRight: 10,
    width: 36
  },
  price_text_free: {
    fontFamily: 'Ysabeau',
    fontSize: 18,
    backgroundColor: '#1fb411',
    borderRadius: 5,
    paddingHorizontal: 5,
    paddingBottom: 3,
    color: 'white',
    marginRight: 10,
    width: 36
  },
  price_explainer_wrapper: {
    color: '#1fb411',
    fontFamily: 'Ysabeau',
    fontSize: 16,
    lineHeight: 18,
    marginTop: 10,
    width: width <= 450 ? width - 40 : 400 
  },
  price_wrapper: {
    flexDirection: 'row',
    marginTop: 10,
    marginRight: 50,
    alignItems: 'flex-end'
  },
  description: {
    fontFamily: 'Comfortaa',
    fontSize: 16,
    lineHeight: 24,
    margin: 40,
    marginTop: 20
  },
  details_title: {
    fontFamily: 'Ysabeau',
    fontSize: 30,
    marginTop: 40,
    marginLeft: 40
  },
  message_link: {
    marginLeft: 20
  },
  one_message: {
    padding: 10,
    flexDirection: 'row'
  },
  no_messages_text: {
    fontFamily: 'Ysabeau',
    fontSize: 18,
    margin: 20
  },
  chat_text: {
    fontFamily: 'Ysabeau',
    fontSize: 18
  },
  chat_time: {
    fontFamily: 'Comfortaa',
    fontSize: 11
  },
  chat_data: {
    marginLeft: 10,
    width: width < 600 ? '70%' : 400
  },
  chat_bottom: {
    display: 'flex',
    flexDirection: 'row'
  },
  chat_messages_wrapper: {
    backgroundColor: '#f7f8fc',
    maxHeight: 400,
    marginBottom: 10,
    borderRadius: 5
  },
  needs_host: {
    fontFamily: 'Comfortaa',
    fontSize: 18,
    textAlign: 'center',
    marginBottom: 20,
    lineHeight: 26,
    color: 'yellow',
    textTransform: 'uppercase'
  },
  event_description_description: {
    fontFamily: 'Comfortaa',
    fontSize: 16,
    lineHeight: 26,
    marginVertical: 10
  },
  event_description_title: {
    fontFamily: 'Ysabeau',
    fontSize: 30,
    marginBottom: 20
  },
  info_bubble_popup: {
    backgroundColor: 'white',
    borderRadius: 10,
  },
  dont_shy: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    marginBottom: 20,
    lineHeight: 20,
    marginTop: 20
  },
  tentative_title: {
    fontFamily: 'Ysabeau',
    fontSize: 20,
    marginBottom: 5,
    position: 'relative'
  },
  one_bucket: {
    backgroundColor: '#f7f8fc',
    flexDirection: 'row',
    borderRadius: 2,
    padding: 5,
    width: '100%',
    marginBottom: 10,
    minHeight: 50,
    justifyContent: 'space-between',
  },
  first: {
    flexDirection: 'row',
    zIndex: 10,
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap'
  },
  invitee_bubble: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: 5,
    marginBottom: 5,
    cursor: 'pointer',
    position: 'relative',
    borderBottomWidth: 1,
    borderBottomColor: '#c8c8d8',
    paddingVertical: 10,
    flexDirection: width < 500 ? 'column' : 'row'
  },
  invitee_photo:  {
    width: 60,
    height: 60,
    borderRadius: 100
  },
  attendee_bubble: {
    borderRadius: 100,
    width: 40,
    height: 40,
    justifyContent: 'center',
    marginRight: 5,
    marginBottom: 5,
    cursor: 'pointer',
    position: 'relative'
  },
  attendee_photo:  {
    width: 40,
    height: 40,
    borderRadius: 100
  },
  floating_info: {
    position: 'absolute',
    width: 200,
    backgroundColor: 'white',
    borderWidth: 2,
    borderColor: '#0b6665',
    borderRadius: 10,
    flexDirection: 'column',
    padding: 10,
    zIndex: 100,
    top: '16px',
    left: '13px'
  },
  attendee_name: {
    fontFamily: 'Comfortaa',
    fontSize: 20,
    textAlign: 'cetner',
    marginBottom: 10
  },
  attendee_info: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    paddingVertical: 10,
    lineHeight: 20
  },
  you_goin: {
    fontFamily: 'Comfortaa',
    backgroundColor: '#1fb411',
    color: 'white',
    fontSize: 14,
    lineHeight: 30,
    borderRadius: 3,
    paddingHorizontal: 10,
    flex: 1,
    alignItems: 'center',
    display: 'flex'
  },
  attendee_name_text: {
    fontFamily: 'Comfortaa',
    color: 'white',
    fontSize: 14,
    textAlign: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center'
  },
  choose_text: {
    fontFamily: 'Ysabeau',
    fontSize: 16,
    lineHeight: 30,
    textDecorationLine: 'underline'
  },
  group_title: {
    fontFamily: 'Ysabeau',
    fontSize: 20,
    marginBottom: 5
  },
  group_title_main: {
    fontFamily: 'Comfortaa',
    fontSize: 18,
    marginBottom: 20
  },
  tentative_title: {
    fontFamily: 'Ysabeau',
    fontSize: 20,
    marginBottom: 5,
    position: 'relative'
  },
  tonights_title: {
    fontFamily: 'Comfortaa',
    fontSize: 18,
    marginBottom: 10,
    marginTop: 20
  },
  info_button: {
    backgroundColor: "#20cac8",
    paddingHorizontal: 9,
    paddingVertical: 1,
    borderRadius: 100,
    color: 'white',
    fontFamily: 'Ysabeau',
    fontSize: 14,
    marginLeft: 10
  },
  thumbs: {
    fontSize: 30,
    marginRight: 7
  },
}

let style_small = StyleSheet.create({
  ...global_style,
  text_line_wrapper: {
    flexDirection: 'row'
  },
  list_item_link: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    marginVertical: 5,
    lineHeight: 20,
    color: '#0b6665',
    textDecorationLine: 'underline',
  },
  list_item_text: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    marginVertical: 5,
    lineHeight: 20,
  },
  list_item: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Comfortaa',
    fontSize: 14,
    marginVertical: 5,
    marginLeft: 20,
    lineHeight: 20,
    listStyle: 'disc',
    display: 'list-item'
  },
  details_wrapper: {
    padding: 20
  },
  terms_link: {
    fontFamily: 'Comfortaa',
    color: '#0b6665',
    fontSize: 12,
    marginLeft: 5,
    textDecorationLine: 'underline',
    backgroundColor: 'white',
    borderRadius: 3,
    padding: 2,
    paddingHorizontal: 5
  },
  price_member_text: {
    fontFamily: 'Ysabeau',
    fontSize: 16,
    textDecorationLine: 'underline',
    color: 'white'
  },
  price_member_text_number: {
    fontFamily: 'Comfortaa',
    fontSize: 16,
    color: '#1fb411'
  },
  terms_label: {
    flexDirection: 'row'
  },
  agree_text: {
    fontFamily: 'Ysabeau',
    width: '100%',
    flexDirection: 'row',
    fontSize: '14px',
    color: 'white',
    marginLeft: '59px'
  },
  link: {
    fontFamily: 'Comfortaa',
    color: '#0b6665',
    fontSize: 12,
    marginLeft: 5,
    textDecorationLine: 'underline'
  },
  one_column: {
    flex: 1
  },
  page_container: {
    flex: 1, flexDirection: 'column',
  },
  page_wrapper: {flex: 1, flexDirection: 'column', position: 'relative'},
  slider_text: {
    fontSize: 20,
    fontWeight: '500',
    color: 'white',
    marginBottom: 5
  },
  logo_button: { paddingTop: 20, paddingBottom: 20, flexDirection: 'row', justifyContent: 'space-between' },
  main_image_wrapper: {
    position: 'relative',
    height: 500,
    /* "backgroundColor":"#e5e5f7","opacity":"1","backgroundImage":"linear-gradient(135deg, #301a19 25%, transparent 25%), linear-gradient(225deg, #301a19 25%, transparent 25%), linear-gradient(45deg, #301a19 25%, transparent 25%), linear-gradient(315deg, #301a19 25%, #e5e5f7 25%)","backgroundPosition":"40px 0, 40px 0, 0 0, 0 0","backgroundSize":"40px 40px","backgroundRepeat":"repeat" */
    /* "backgroundColor":"#ffffff","opacity":"1","backgroundImage":"linear-gradient(135deg, #2f1e1d 25%, transparent 25%), linear-gradient(225deg, #2f1e1d 25%, transparent 25%), linear-gradient(45deg, #2f1e1d 25%, transparent 25%), linear-gradient(315deg, #2f1e1d 25%, #ffffff 25%)","backgroundPosition":"40px 0, 40px 0, 0 0, 0 0","backgroundSize":"40px 40px","backgroundRepeat":"repeat" */
    "backgroundColor":"#ffffff","opacity":"1","backgroundImage":"linear-gradient(135deg, #2f1e1d 25%, transparent 25%), linear-gradient(225deg, #2f1e1d 25%, transparent 25%), linear-gradient(45deg, #2f1e1d 25%, transparent 25%), linear-gradient(315deg, #2f1e1d 25%, #ffffff 25%)","backgroundPosition":"40px 0, 40px 0, 0 0, 0 0","backgroundSize":"80px 80px","backgroundRepeat":"repeat"
  },
  main_text_wrapper: {
    position: 'absolute',
    left: 0,
    top: 60,
    width: '100%',
    alignContent: 'center', 
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  main_text_band: {
    width: '100%',
    position: 'absolute',
    top: 0,
    height: 500,
    backgroundColor: 'black',
    opacity: .9
  },
  main_callout: {
    fontFamily: 'Ysabeau',
    fontSize: '100px',
    color: 'white',

    textShadowColor: 'rgba(0, 0, 0, 1)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 5
  },
  main_sub_callout: {
    fontFamily: 'Ysabeau',
    fontSize: '30px',
    color: 'white',

    textShadowColor: 'rgba(0, 0, 0, 1)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 5
  },
  main_callout_phrase: {
    fontFamily: 'Ysabeau',
    fontSize: '50px',
    color: 'white',
    paddingTop: 40,
    paddingHorizontal: 30,
    color: '#8c8080',
    textShadowColor: 'rgba(0, 0, 0, 1)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 5
  },
  main_image: { 
    height: 500 ,
    width: '100%',
    position: 'absolute',
    left: 0,
    top: 0
  },
  home_breaker: {
    width: '100%',
    height: 100
  },
  secondary_title: {
    fontFamily: 'Comfortaa',
    fontSize: '50px',
    color: 'black',
    width: '100%',
    flexDirection: 'row',
    padding: 20,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderBottomWidth: 1
  },
  events_section: {
    position: 'relative'
  },
  carousel_wrapper: {
    flex: 1,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center'
  },
  carousel_image: {
    flex: 1
  },
  explainer_section: {
    flexDirection: 'column'
  },
  main_explainer_columns: {
    flexDirection: 'row'
  },
  explainer_column: {
    flex: 1
  },
  four_block_wrapper: {
    flexDirection: 'row'
  },
  row: {
    flex: 1,
    flexDirection: 'column'
  },
  block_column: {
    flex: 1,
    flexDirection: 'column',
    padding: 50
  },
  left_border: {
    borderLeftWidth: 1,
    borderColor: '#ccc'
  },
  right_border: {
    borderRightWidth: 1,
    borderColor: '#ccc'
  },
  top_border: {
    borderTopWidth: 1,
    borderColor: '#ccc'
  },
  bottom_border: {
    borderBottomWidth: 1,
    borderColor: '#ccc'
  },
  box_title: {
    fontFamily: 'Comfortaa',
    fontSize: '20px',
    color: 'black',
    width: '100%',
    flexDirection: 'row',
    padding: 20,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderColor: '#e3e1e1'
  },
  main_description_text: {
    fontFamily: 'Comfortaa',
    fontSize: '16px',
    color: 'black',
    width: '100%',
    flexDirection: 'row',
    padding: 20,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    lineHeight: 30
  },
  home_button: {
    borderRadius: 30,
    backgroundColor: 'white',
    padding: 8,
    marginTop: 50,
    width: 200,
    borderWidth: 3,
    bordercolor: 'black'
  },
  home_button_text: {
    fontFamily: 'Ysabeau',
    fontSize: '25px',
    textAlign: 'center',
    marginBottom: 7
  },
  center_image_wrapper: {
    position: 'absolute',
    zIndex: 1,
    top: '38%', 
    left: 'calc(50vw - 90px)'
  },
  center_image: {
    width: 180,
    height: 180,
    borderRadius: 100
  },
  bottom_join_wrapper: {
    backgroundColor: 'black',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    paddingVertical: 50
  },
  bottom_join_button: {
    borderRadius: 30,
    backgroundColor: 'white',
    padding: 8,
    width: 300,
    borderWidth: 3,
    bordercolor: 'black'
  },
  bottom_join_button_text: {
    fontFamily: 'Ysabeau',
    fontSize: '25px',
    textAlign: 'center',
    marginBottom: 7
  },
  restaurants_title: {
    fontFamily: 'Ysabeau',
    fontSize: '50px',
    color: 'black',
    width: '100%',
    flexDirection: 'row',
    padding: 20,
    marginBottom: 80,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  restaurant_title: {
    backgroundColor: 'black',
    fontFamily: 'Ysabeau',
    fontSize: 30,
    color: 'white',
    paddingVertical: 20,
    textAlign: 'center'
  },
  restaurants_wrapper: {
    flexDirection: 'column'
  },
  restaurant_wrapper: {
    flexDirection: 'column',
    width: width
  },
  kitchenstep_main: {
    width: '100%',
    height: 250,
    alignSelf: 'center',
    borderRadius: 3,
  },
  kitchenstep_main_wrapper: {
    
    padding: 20,

  },
  kitchestep_image_text_wrapper: {
    //backgroundColor: '#ffefd1',
    flexDirection: 'column'
  },

  kitchenstep_description_wrapper: {
   flexDirection: 'row'
  },
  kitchenstep_description: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    flexWrap: 'wrap',
    /* width: '100%',
    height: 310, */
    paddingVertical: 50,
    paddingHorizontal: 40,
    lineHeight: 30,
    color: 'white'
  },
  kitchenstep_background_image: {
    height: 155,
    //width: 'calc(100% - 40px)',
    top: 20,
    left: 20,
    position: 'absolute'
  },
  kitchenstep_link_button: {
    borderColor: 'white',
    borderWidth: 3,
    width: 240,
    alignSelf: 'center',
    marginBottom: '30px'
  },
  kitchenstep_link: {
    backgroundColor: 'white',
    borderColor: 'black',
    color: 'black',
    borderWidth: 3,
    paddingVertical: 3,
    fontSize: 14,
    paddingHorizontal: 20,
    textTransform: 'uppercase',
    textAlign: 'center'
  },
  franklinsocial_main: {
    width: '100%',
    height: 466,
  },
  restaurant_breaker: {
    height: 100
  },
  franklinsocial_image_text_wrapper: {
    backgroundColor: '#ffefd1',
    flexDirection: 'row'
  },
  franklinsocial_description: {
    backgroundColor: 'black',
    flexWrap: 'wrap',
    width: 350,
    height: 465,
    marginTop: 20,
    paddingVertical: 50,
    paddingHorizontal: 20,
    flexDirection: 'column'
  },
  franklinsocial_description_text: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    flexWrap: 'wrap',
    color: 'white',
    lineHeight: 30
  },
  franklinsocial_link_button: {
    borderColor: 'white',
    borderWidth: 3,
    width: 200,
    marginTop: 20
  },
  franklinsocial_link: {
    backgroundColor: 'white',
    color: 'black',
    borderColor: 'black',
    borderWidth: 3,
    paddingVertical: 3,
    fontSize: 12,
    paddingHorizontal: 20,
    textTransform: 'uppercase',
    textAlign: 'center'
  },
  kitchen_perks_wrapper_wrapper: {
    flexDirection: 'column',

    marginBottom: 50,
    width: '100vw',
    justifyContent: 'center'
  },
  perks_wrapper_wrapper: {
    flexDirection: 'row',
    marginTop: 50,
    paddingHorizontal: 40,
    marginBottom: 50,
    marginHorizontal: 10,
    width: '90%',
    justifyContent: 'center'
  },
  perks_wrapper: {
    flexDirection: 'column',
    paddingBottom: 40,
    paddingTop: 20,
    backgroundColor: 'white',
    color: 'black',
    borderWidth: 10,
  },
  kitchen_perks_wrapper: {
    flexDirection: 'column',
    margin: 10,
    marginTop: 30,
    paddingBottom: 30,
    paddingTop: 20,
    backgroundColor: 'white',
    color: 'black',
    borderWidth: 10,
    marginBottom: 50
  },
  perks_title: {
    fontFamily: 'Comfortaa',
    fontSize: 30,
    color: 'white',
    backgroundColor: 'black',
    lineHeight: 30,
    paddingVertical: 5,
    paddingHorizontal: 10,
    textAlign: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center'
  },
  perks_title_palcement: {

  },
  perks_list: {
    flexDirection: 'column',
    marginTop: 20
  },
  perks_item: {

  },
  kitchenstep_background_perks_image: {
    height: 200,
    //width: 'calc(100% - 10px)',
    top: 20,
    left: 10,
    position: 'absolute',
    borderWidth: 10
  },
  perks_title_wrapper: {
    flexDirection: 'column'
  },
  perks_explanation: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    flexWrap: 'wrap',
    paddingHorizontal: 40,
    lineHeight: 26,
    marginTop: 30
  },
  perks_description: {
    fontFamily: 'Comfortaa',
    fontSize: 18,
    flexWrap: 'wrap',
    width: '90vw',
    paddingHorizontal: 40,
    lineHeight: 40
  },
  signup_wrapper: {
    backgroundColor: 'black',
    flexDirection: 'column',
    textAlign: 'center', 
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 50,
    marginTop: 50
  },
  signup_text: {
    fontFamily: 'Comfortaa',
    fontSize: 30,
    flexWrap: 'wrap',
    
    color: 'white'
  },
  intro_row: {
    width: '100vw',
    justifyContent: 'space-around',
    flexDirection: 'column',
    backgroundColor: 'black'
  },
  directions_text: {
    fontFamily: 'Comfortaa',
    color: '#0b6665',
    fontSize: 12,
    marginLeft: 5,
    textDecorationLine: 'underline'
  },
  line_column: {
    flexDirection: 'column',
    marginVertical: 20,
  },
  bon_appetit: {
    marginTop: 40
  },
  line: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginVertical: 10,
  },
  line_name: {
    fontFamily: 'Comfortaa',
    color: 'white',
    fontSize: 20,
    alignSelf: 'flex-start'
  },
  line_date: {
    fontFamily: 'Ysabeau',
    fontSize: 18,
    alignSelf: 'flex-start',
    color: 'white',
    marginTop: 10,
    marginLeft: 20
  },
  line_column_name: {
    fontFamily: 'Comfortaa',
    fontSize: 16,
    marginRight: 20
  },
  notes_input: {
    width: 250,
    height: 80
  },
  restaurant_container: {
    order: 1,
    paddingBottom: 100
  },
  data_column: {
    padding: 20,
    boarderRadius: 2,
    order: 2,
    flex: 'unset',
    backgroundColor: 'black'
  },
  data_column_rsvped: {
    padding: 20,
    boarderRadius: 2,
    order: 1,
    flex: 'unset',
    backgroundColor: 'black'
  },
  main_title_name: {
    fontFamily: 'Comfortaa',
    fontSize: 30,
    textAlign: 'center',
    marginBottom: 20,
    lineHeight: 40,
    color: 'white',
  },
  main_title_name_mobile: {
    fontFamily: 'Comfortaa',
    fontSize: 30,
    textAlign: 'center',
    marginTop: 30,
    marginHorizontal: 20,
    color: 'black',

  },
  club_button: {
    backgroundColor: '#e32652',
    borderRadius: 3,
    paddingTop: 5,
    paddingBottom: 7,
    paddingHorizontal: 20,
    margin: 'auto'
  },
  club_button_inactive: {
    backgroundColor: '#debda3',
    borderRadius: 3,
    paddingTop: 5,
    paddingBottom: 7,
    paddingHorizontal: 20,
    margin: 'auto'
  },
  member_price: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    alignSelf: 'center',
    marginTop: 10
  },
  visit_restaurant: {
    borderColor: 'black',
    borderWidth: 3,
    width: '100%',
    marginTop: 10
  },
  visit_restaurant_text: {
    fontSize: 14,
    fontFamily: 'Ysabeau',
    borderColor: 'white',
    borderWidth: 1,
    backgroundColor: 'black',
    textAlign: 'center',
    color: 'white',
    paddingHorizontal: 10,
    paddingBottom: 3,
    paddingTop: 1
  },
  event_description_wrapper: {
    flexDirection: 'column',
  },
  event_description_column: {
    flex: 1, 
    flexDirection: 'column',
    padding: 40
  },
  event_description_description_last_line: {
    fontFamily: 'Comfortaa',
    fontSize: 16,
    lineHeight: 26,
    marginRight: 40
  },
  list_item_wrapper: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  event_description_list_item: {
    fontFamily: 'Comfortaa',
    fontSize: 16,
    marginVertical: 10,
    marginLeft: 20,
    lineHeight: 20
  },
  bullet: {
    backgroundColor: 'black',
    width: 5,
    height: 5,
    borderRadius: 100
  },
  picker: {
    
  }
});

let style_normal = StyleSheet.create({
  ...global_style,
  text_line_wrapper: {
    flexDirection: 'row'
  },
  list_item_link: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    marginVertical: 5,
    lineHeight: 20,
    color: '#0b6665',
    textDecorationLine: 'underline',
  },
  list_item_text: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    marginVertical: 5,
    lineHeight: 20,
  },
  list_item: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Comfortaa',
    fontSize: 14,
    marginVertical: 5,
    marginLeft: 20,
    lineHeight: 20,
    listStyle: 'disc',
    display: 'list-item'
  },
  details_wrapper: {
    padding: 20
  },
  terms_label: {
    flexDirection: 'row'
  },
  agree_text: {
    fontFamily: 'Ysabeau',
    width: '100%',
    flexDirection: 'row',
    fontSize: '14px',
    marginLeft: '59px',
    color: 'white'
  },
  link: {
    fontFamily: 'Comfortaa',
    color: '#0b6665',
    fontSize: 12,
    marginLeft: 5,
    textDecorationLine: 'underline'
  },
  terms_link: {
    fontFamily: 'Comfortaa',
    color: '#0b6665',
    fontSize: 12,
    marginLeft: 5,
    textDecorationLine: 'underline',
    backgroundColor: 'white',
    borderRadius: 3,
    padding: 2,
    paddingHorizontal: 5
  },
  event_description_wrapper: {
    flexDirection: 'row',
  },
  event_description_column: {
    flex: 1, 
    flexDirection: 'column',
    padding: 40
  },
  event_description_description_last_line: {
    fontFamily: 'Comfortaa',
    fontSize: 16,
    lineHeight: 26,
    marginRight: 40
  },
  list_item_wrapper: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  event_description_list_item: {
    fontFamily: 'Comfortaa',
    fontSize: 16,
    marginVertical: 10,
    marginLeft: 20,
    lineHeight: 20
  },
  bullet: {
    backgroundColor: 'black',
    width: 5,
    height: 5,
    borderRadius: 100
  },
  one_column: {
    flex: 1
  },
  page_container: {
    flexDirection: 'row'
  },
  page_wrapper: {flex: 1, flexDirection: 'column', position: 'relative'},
  slider_text: {
    fontSize: 20,
    fontWeight: '500',
    color: 'white',
    marginBottom: 5
  },
  logo_button: { paddingTop: 20, paddingBottom: 20, flexDirection: 'row', justifyContent: 'space-between' },
  main_image_wrapper: {
    position: 'relative',
    height: 500,
    /* "backgroundColor":"#e5e5f7","opacity":"1","backgroundImage":"linear-gradient(135deg, #301a19 25%, transparent 25%), linear-gradient(225deg, #301a19 25%, transparent 25%), linear-gradient(45deg, #301a19 25%, transparent 25%), linear-gradient(315deg, #301a19 25%, #e5e5f7 25%)","backgroundPosition":"40px 0, 40px 0, 0 0, 0 0","backgroundSize":"40px 40px","backgroundRepeat":"repeat" */
    /* "backgroundColor":"#ffffff","opacity":"1","backgroundImage":"linear-gradient(135deg, #2f1e1d 25%, transparent 25%), linear-gradient(225deg, #2f1e1d 25%, transparent 25%), linear-gradient(45deg, #2f1e1d 25%, transparent 25%), linear-gradient(315deg, #2f1e1d 25%, #ffffff 25%)","backgroundPosition":"40px 0, 40px 0, 0 0, 0 0","backgroundSize":"40px 40px","backgroundRepeat":"repeat" */
    "backgroundColor":"#ffffff","opacity":"1","backgroundImage":"linear-gradient(135deg, #2f1e1d 25%, transparent 25%), linear-gradient(225deg, #2f1e1d 25%, transparent 25%), linear-gradient(45deg, #2f1e1d 25%, transparent 25%), linear-gradient(315deg, #2f1e1d 25%, #ffffff 25%)","backgroundPosition":"40px 0, 40px 0, 0 0, 0 0","backgroundSize":"80px 80px","backgroundRepeat":"repeat"
  },
  main_text_wrapper: {
    position: 'absolute',
    left: 0,
    top: 60,
    width: '100%',
    alignContent: 'center', 
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  main_text_band: {
    width: '100%',
    position: 'absolute',
    top: 0,
    height: 500,
    backgroundColor: 'black',
    opacity: .9
  },
  main_callout: {
    fontFamily: 'Ysabeau',
    fontSize: '100px',
    color: 'white',

    textShadowColor: 'rgba(0, 0, 0, 1)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 5
  },
  main_sub_callout: {
    fontFamily: 'Ysabeau',
    fontSize: '30px',
    color: 'white',

    textShadowColor: 'rgba(0, 0, 0, 1)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 5
  },
  main_callout_phrase: {
    fontFamily: 'Ysabeau',
    fontSize: '50px',
    color: 'white',
    paddingTop: 40,
    paddingHorizontal: 30,
    color: '#8c8080',
    textShadowColor: 'rgba(0, 0, 0, 1)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 5
  },
  main_image: { 
    height: 500 ,
    width: '100%',
    position: 'absolute',
    left: 0,
    top: 0
  },
  home_breaker: {
    width: '100%',
    height: 100
  },
  secondary_title: {
    fontFamily: 'Comfortaa',
    fontSize: '50px',
    color: 'black',
    width: '100%',
    flexDirection: 'row',
    padding: 20,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderBottomWidth: 1
  },
  events_section: {
    position: 'relative'
  },
  carousel_wrapper: {
    flex: 1,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center'
  },
  carousel_image: {
    flex: 1
  },
  explainer_section: {
    flexDirection: 'column'
  },
  main_explainer_columns: {
    flexDirection: 'row'
  },
  explainer_column: {
    flex: 1
  },
  four_block_wrapper: {
    flexDirection: 'row'
  },
  row: {
    flex: 1,
    flexDirection: 'column'
  },
  block_column: {
    flex: 1,
    flexDirection: 'column',
    padding: 50
  },
  left_border: {
    borderLeftWidth: 1,
    borderColor: '#ccc'
  },
  right_border: {
    borderRightWidth: 1,
    borderColor: '#ccc'
  },
  top_border: {
    borderTopWidth: 1,
    borderColor: '#ccc'
  },
  bottom_border: {
    borderBottomWidth: 1,
    borderColor: '#ccc'
  },
  box_title: {
    fontFamily: 'Comfortaa',
    fontSize: '20px',
    color: 'black',
    width: '100%',
    flexDirection: 'row',
    padding: 20,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderColor: '#e3e1e1'
  },
  main_description_text: {
    fontFamily: 'Comfortaa',
    fontSize: '16px',
    color: 'black',
    width: '100%',
    flexDirection: 'row',
    padding: 20,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    lineHeight: 30
  },
  home_button: {
    borderRadius: 30,
    backgroundColor: 'white',
    padding: 8,
    marginTop: 50,
    width: 200,
    borderWidth: 3,
    bordercolor: 'black'
  },
  home_button_text: {
    fontFamily: 'Ysabeau',
    fontSize: '25px',
    textAlign: 'center',
    marginBottom: 7
  },
  center_image_wrapper: {
    position: 'absolute',
    zIndex: 1,
    top: '38%', 
    left: 'calc(50vw - 90px)'
  },
  center_image: {
    width: 180,
    height: 180,
    borderRadius: 100
  },
  bottom_join_wrapper: {
    backgroundColor: 'black',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    paddingVertical: 50
  },
  bottom_join_button: {
    borderRadius: 30,
    backgroundColor: 'white',
    padding: 8,
    width: 300,
    borderWidth: 3,
    bordercolor: 'black'
  },
  bottom_join_button_text: {
    fontFamily: 'Ysabeau',
    fontSize: '25px',
    textAlign: 'center',
    marginBottom: 7
  },
  restaurants_title: {
    fontFamily: 'Ysabeau',
    fontSize: '50px',
    color: 'black',
    width: '100%',
    flexDirection: 'row',
    padding: 20,
    marginBottom: 80,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  restaurant_title: {
    backgroundColor: 'black',
    fontFamily: 'Ysabeau',
    fontSize: 30,
    color: 'white',
    //paddingVertical: 20,
    textAlign: 'center'
  },
  restaurants_wrapper: {
    flexDirection: 'column'
  },
  restaurant_wrapper: {
    flexDirection: 'column',
    width: '100%'
  },
  kitchenstep_main: {
    width: '100%',
    height: 500,
    alignSelf: 'center',
    borderRadius: 3
  },
  kitchenstep_main_wrapper: {
    
    paddingHorizontal: 20,
    background: 'black'
  },
  kitchestep_image_text_wrapper: {
    //backgroundColor: '#ffefd1',
    flexDirection: 'column',
    backgroundColor: 'black'
  },

  kitchenstep_description_wrapper: {
   flexDirection: 'row'
  },
  kitchenstep_description: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    flexWrap: 'wrap',
    /* width: '100%',
    height: 310, */
    paddingVertical: 50,
    paddingHorizontal: 40,
    lineHeight: 30,
    color: 'white'
  },
  kitchenstep_background_image: {
    height: 155,
    //width: 'calc(100% - 40px)',
    top: 20,
    left: 20,
    position: 'absolute'
  },
  kitchenstep_link_button: {
    borderColor: 'white',
    borderWidth: 3,
    width: 240,
    marginLeft: 20,
    alignSelf: 'center',
    marginBottom: '30px'
  },
  kitchenstep_link: {
    backgroundColor: 'white',
    borderColor: 'black',
    color: 'black',
    borderWidth: 3,
    paddingVertical: 3,
    fontSize: 14,
    paddingHorizontal: 20,
    textTransform: 'uppercase',
    textAlign: 'center'
  },
  franklinsocial_main: {
    width: '100%',
    height: 466,
  },
  restaurant_breaker: {
    height: 100
  },
  franklinsocial_image_text_wrapper: {
    backgroundColor: '#ffefd1',
    flexDirection: 'row'
  },
  franklinsocial_description: {
    backgroundColor: 'black',
    flexWrap: 'wrap',
    width: 350,
    height: 465,
    marginTop: 20,
    paddingVertical: 50,
    paddingHorizontal: 20,
    flexDirection: 'column'
  },
  franklinsocial_description_text: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    flexWrap: 'wrap',
    color: 'white',
    lineHeight: 30
  },
  franklinsocial_link_button: {
    borderColor: 'white',
    borderWidth: 3,
    width: 200,
    marginTop: 20
  },
  franklinsocial_link: {
    backgroundColor: 'white',
    color: 'black',
    borderColor: 'black',
    borderWidth: 3,
    paddingVertical: 3,
    fontSize: 12,
    paddingHorizontal: 20,
    textTransform: 'uppercase',
    textAlign: 'center'
  },
  kitchen_perks_wrapper_wrapper: {
    flexDirection: 'column',

    marginBottom: 50,
    width: '100vw',
    justifyContent: 'center'
  },
  perks_wrapper_wrapper: {
    flexDirection: 'row',
    marginTop: 50,
    paddingHorizontal: 40,
    marginBottom: 50,
    width: '100vw',
    justifyContent: 'center'
  },
  perks_wrapper: {
    flexDirection: 'column',
    paddingBottom: 40,
    paddingTop: 20,
    backgroundColor: 'white',
    color: 'black',
    borderWidth: 10,
  },
  kitchen_perks_wrapper: {
    flexDirection: 'column',
    margin: 30,
    paddingBottom: 30,
    paddingTop: 20,
    backgroundColor: 'white',
    color: 'black',
    borderWidth: 10,
    width: '95vw'
  },
  perks_title: {
    fontFamily: 'Comfortaa',
    fontSize: 30,
    color: 'white',
    backgroundColor: 'black',
    lineHeight: 30,
    paddingVertical: 5,
    paddingHorizontal: 20,
    width: 400,
    textAlign: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center'
  },
  perks_title_palcement: {

  },
  perks_list: {
    flexDirection: 'column',
    marginTop: 20
  },
  perks_item: {

  },
  kitchenstep_background_perks_image: {
    height: 200,
    width: 'calc(100% - 40px)',
    top: 20,
    left: 20,
    position: 'absolute',
    borderWidth: 10
  },
  perks_title_wrapper: {
    flexDirection: 'row'
  },
  perks_explanation: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    flexWrap: 'wrap',
    width: '100vw',
    paddingHorizontal: 40,
    lineHeight: 20,
    marginTop: 30,
    width: 700
  },
  perks_description: {
    fontFamily: 'Comfortaa',
    fontSize: 18,
    flexWrap: 'wrap',
    width: '60vw',
    paddingHorizontal: 40,
    lineHeight: 40
  },
  signup_wrapper: {
    backgroundColor: 'black',
    flexDirection: 'column',
    textAlign: 'center', 
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 50,
    marginTop: 50
  },
  signup_text: {
    fontFamily: 'Comfortaa',
    fontSize: 30,
    flexWrap: 'wrap',
    
    color: 'white'
  },
  intro_row: {
    width: '100vw',
    justifyContent: 'space-around',
    flexDirection: 'column',
    backgroundColor: 'black'
  },
  directions_text: {
    fontFamily: 'Comfortaa',
    color: '#0b6665',
    fontSize: 12,
    marginLeft: 5,
    textDecorationLine: 'underline'
  },
  line: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginVertical: 10,
  },
  line_column: {
    flexDirection: 'column',
    marginVertical: 20,
  },
  bon_appetit: {
    marginTop: 40
  },
  line_name: {
    fontFamily: 'Comfortaa',
    color: 'white',
    fontSize: 16,
    marginRight: 20,
    width: 150,
    alignSelf: 'flex-start'
  },
  line_column_name: {
    fontFamily: 'Comfortaa',
    fontSize: 16,
    marginRight: 20
  },
  notes_input: {
    width: 300,
    height: 80
  },
  line_date: {
    fontFamily: 'Ysabeau',
    fontSize: 18,
    marginRight: 20,
    color: 'white'
  },
  data_column: {
    padding: 40,
    backgroundColor: 'black'
  },
  data_column_rsvped: {
    padding: 40,
    backgroundColor: 'black'
  },
  main_title_name: {
    fontFamily: 'Comfortaa',
    fontSize: 30,
    textAlign: 'center',
    marginBottom: 40,
    color: 'white',
    lineHeight: 40
  },
  club_button: {
    backgroundColor: '#e32652',
    borderRadius: 3,
    paddingTop: 5,
    paddingBottom: 7,
    paddingHorizontal: 20,
    alignSelf: 'flex-end'
  },
  club_button_inactive: {
    backgroundColor: '#aecccc',
    borderRadius: 3,
    paddingTop: 5,
    paddingBottom: 7,
    paddingHorizontal: 20,
    position: 'absolute',
    bottom: 20,
    right: 20
  },
  member_price: {
    fontFamily: 'Comfortaa',
    fontSize: 14
  },
  visit_restaurant: {
    borderColor: 'black',
    borderWidth: 3,
    width: '100%',
    marginTop: 10,
    marginBottom: 20
  },
  visit_restaurant_text: {
    fontSize: 14,
    fontFamily: 'Ysabeau',
    borderColor: 'white',
    borderWidth: 1,
    backgroundColor: 'black',
    textAlign: 'center',
    color: 'white',
    paddingHorizontal: 10,
    paddingBottom: 3,
    paddingTop: 1
  },
  price_member_text: {
    fontFamily: 'Ysabeau',
    fontSize: 16,
    textDecorationLine: 'underline',
    color: 'white'
  },
  price_member_text_number: {
    fontFamily: 'Comfortaa',
    fontSize: 16,
    color: '#1fb411'
  },
  info_bubble: {
    width: 200,
    height: 160,
    backgroundColor: 'white',
    zIndex: 10000000,
    position: 'absolute',
    padding: '20px',
    borderWidth: 1,
    borderRadius: 5,
    left: '158px',
    top: '15px'
  },
  overlay: {
    flex: 1,
    top: 0, 
    left: 0,
    opacity: .3,
    backgroundColor: 'black',
    width: '100vw',
    height: '100%',
    position: 'fixed',
    zIndex: 100000
  },
  modal_text: {
    fontFamily: 'Comfortaa',
    fontSize: 16
  },
  info_button: {
    backgroundColor: "#20cac8",
    paddingHorizontal: 9,
    paddingVertical: 1,
    borderRadius: 100,
    color: 'white',
    fontFamily: 'Ysabeau',
    fontSize: 14,
    marginLeft: 10
  },
  modal_text: {
    fontFamily: 'Comfortaa',
    fontSize: 12,
    lineHeight: 20
  },
  modal_text_title: {
    fontFamily: 'Comfortaa',
    fontSize: 16,
    lineHeight: 20,
    marginBottom: 15
  },
  canceled_text: {
    fontFamily: 'Ysabeau',
    fontSize: 20
  },
  info_bubble_text: {
    fontFamily: 'Ysabeau',
    fontSize: 14,
    backgroundColor: '#5ac3c4',
    width: 20,
    padding: 2,
    paddingTop: 0,
    paddingBottom: 4,
    textAlign: 'center',
    borderRadius: 100,
    alignSelf: 'flex-end',
    lineHeight: 16
  },
  info_bubble_popup: {
    
    backgroundColor: 'white',
    borderRadius: 10,
  },
});

let style = width < 1000 ? style_small : style_normal;

export {
  style
}
