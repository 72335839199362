import Utilities from '../utilities/index';

export default class PaymentController  {

    static async createCheckoutSession (data) {
        let response = await Utilities.doInternalPost('/data/payment/create_checkout_session', data);

        console.log('response', response)

        return response;
    }

    static async clickMe() {
        let response = await Utilities.doInternalPost('/stripe_webhook', {});

        console.log('response', response)

        return response;
    }

    static async createPortalSession (data) {
        let response = await Utilities.doInternalPost('/data/payment/create_portal_session', data);

        console.log('response', response)

        return response;
    }

    static async createEventSession (data) {
        let response = await Utilities.doInternalPost('/data/payment/event_checkout_session', data);

        console.log('response', response)

        return response;
    }

    static async rsvpChckOutCreate (data) {
        let response = await Utilities.doInternalPost('/data/payment/rsvp_check_out_create', data);

        console.log('response', response)

        return response;
    }

    static async rsvpCheckOut(data) {
        let response = await Utilities.doInternalPost('/data/payment/rsvp_check_out', data);

        return response;
    }

    static async rsvpMemberEvent(data) {
        let response = await Utilities.doInternalPost('/data/payment/rsvp_member_event', data);

        return response;
    }

    static async updateSubscription (data) {
        let response = await Utilities.doInternalPost('/data/payment/update_subscription', data);

        console.log('updateSubscription response', response)

        return response;
    }

    static async deleteUser (data) {
        let response = await Utilities.doInternalPost('/data/payment/delete_user', data);

        //console.log('response', response)

        return response;
    }

    static async downgradeToFree (data) {
        let response = await Utilities.doInternalPost('/data/payment/downgrade_to_free', data);

        //console.log('response', response)

        return response;
    }

    static async resumeSubscription (data) {
        let response = await Utilities.doInternalPost('/data/payment/resume_subscription', data);

        //console.log('response', response)

        return response;
    }
}