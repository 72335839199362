import { StyleSheet } from 'react-native';

const style = StyleSheet.create({
  page_wrapper: {flex: 1, flexDirection: 'column', position: 'relative'},
  slider_text: {
    fontSize: 20,
    fontWeight: '500',
    color: 'white',
    marginBottom: 5
  },
  logo_button: { paddingTop: 20, paddingBottom: 20, flexDirection: 'row', justifyContent: 'space-between' },
  main_image_wrapper: {
    position: 'relative',
    height: 500,
    /* "backgroundColor":"#e5e5f7","opacity":"1","backgroundImage":"linear-gradient(135deg, #301a19 25%, transparent 25%), linear-gradient(225deg, #301a19 25%, transparent 25%), linear-gradient(45deg, #301a19 25%, transparent 25%), linear-gradient(315deg, #301a19 25%, #e5e5f7 25%)","backgroundPosition":"40px 0, 40px 0, 0 0, 0 0","backgroundSize":"40px 40px","backgroundRepeat":"repeat" */
    /* "backgroundColor":"#ffffff","opacity":"1","backgroundImage":"linear-gradient(135deg, #2f1e1d 25%, transparent 25%), linear-gradient(225deg, #2f1e1d 25%, transparent 25%), linear-gradient(45deg, #2f1e1d 25%, transparent 25%), linear-gradient(315deg, #2f1e1d 25%, #ffffff 25%)","backgroundPosition":"40px 0, 40px 0, 0 0, 0 0","backgroundSize":"40px 40px","backgroundRepeat":"repeat" */
    "backgroundColor":"#ffffff","opacity":"1","backgroundImage":"linear-gradient(135deg, #2f1e1d 25%, transparent 25%), linear-gradient(225deg, #2f1e1d 25%, transparent 25%), linear-gradient(45deg, #2f1e1d 25%, transparent 25%), linear-gradient(315deg, #2f1e1d 25%, #ffffff 25%)","backgroundPosition":"40px 0, 40px 0, 0 0, 0 0","backgroundSize":"80px 80px","backgroundRepeat":"repeat"
  },
  main_text_wrapper: {
    position: 'absolute',
    left: 0,
    top: 100,
    width: '100%',
    alignContent: 'center', 
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  main_text_band: {
    width: '100%',
    position: 'absolute',
    top: 0,
    height: 500,
    backgroundColor: 'black',
    opacity: .9
  },
  main_callout: {
    fontFamily: 'Ysabeau',
    fontSize: '100px',
    color: 'white',

    textShadowColor: 'rgba(0, 0, 0, 1)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 5
  },
  main_sub_callout: {
    fontFamily: 'Ysabeau',
    fontSize: '50px',
    color: 'white',

    textShadowColor: 'rgba(0, 0, 0, 1)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 5
  },
  main_image: { 
    height: 500 ,
    width: '100%',
    position: 'absolute',
    left: 0,
    top: 0
  },
  home_breaker: {
    width: '100%',
    height: 100
  },
  secondary_title: {
    fontFamily: 'Comfortaa',
    fontSize: '50px',
    color: 'black',
    width: '100%',
    flexDirection: 'row',
    padding: 20,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderBottomWidth: 1,
    borderTopWidth: 1
  },
  events_section: {
    position: 'relative'
  }
});

export {
  style
}