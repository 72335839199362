import { StyleSheet, Dimensions } from 'react-native';

let width = Dimensions.get('window').width;
let height = Dimensions.get('window').height;

let global_style = {
  selected: {
    backgroundColor: '#0dc6e3', 
  },
  hobbies_edit_wrapper: {
    flexDirection: 'column', 
    width: width <= 700 ? 'calc(100% - 20px)' : 800
  },
  input_text_hobbie: {
    textTransform: 'capitalize',
    marginRight: 10,
    marginBottom: 10,
    backgroundColor: '#0dc6e3',
    borderRadius: 3,
    padding: 5,
    paddingHorizontal: 20,
    color: 'white',
    fontFamily: "Ysabeau",
    lineHeight: 30,
    fontSize: 18,
    marginTop: 5,
    textAlign: 'center'
  },
  hosting_host_text: {
    fontFamily: 'Comfortaa',
    fontSize: 18,
    color: 'green',
    lineHeight: 24
  },
  hosting_host_text_row: {
    alignSelf: 'center',
    width: 500
  },
  hosting_host_text_normal: {
    fontFamily: 'Comfortaa',
    fontSize: 18,
    lineHeight: 20
  },
  hosting_section_title: {
    fontFamily: "Ysabeau",
    fontSize: 30
  },
  drop_style: { 
    width: 50, 
    borderWidth: 1,
    padding: 10,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 12,
    backgroundColor: 'white',
    fontSize: 14,
    fontFamily: 'Comfortaa',
    color: '#575762',
    borderColor: '#bbbbc0'
  },
  drop_style_profile: { 
    width: 300, 
    borderWidth: 1,
    padding: 10,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 12,
    backgroundColor: 'white',
    fontSize: 14,
    fontFamily: 'Comfortaa',
    color: '#575762',
    borderColor: '#bbbbc0'
  }
}

const style_small = StyleSheet.create({
  ...global_style,
  dinners_description_panel_non_hosted: {
    backgroundColor:  '#8bfa8f',
    padding: 40,
    marginBottom: 20,
    borderRadius: 10,
  },
  non_hosted_wrapper: {
    marginHorizontal: 'auto',
  },
  non_hosted_title: {
    fontFamily: 'Ysabeau',
    fontSize: 26,
    marginBottom: 40,
    textAlign: 'center',
    lineHeight: 26
  },
  non_hosted_description: {
    fontFamily: 'Comfortaa',
    fontSize: 16,
    lineHeight: 26,
    textAlign: 'center'
  },
  rsvp_text: {
    fontFamily: 'Comfortaa',
    fontSize: 18,
    textDecorationLine: 'underline'
  },
  collapsable_burger: {
    display: 'flex',
    padding: 10,
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: '#f7f8fc'
  },
  burger: {
    width: 30,
    flexDirection: 'column',
    alignSelf: 'flex-end',
    borderWidth: 1,
    borderColor: '#ccc',
    padding: 3,
    paddingTop: 5,
    backgroundColor: '#f7f8fc'
  },
  burger_line: {
    borderBottomWidth: 3,
    marginBottom: 3
  },
  burger_close: {
    width: 30,
    alignSelf: 'flex-end',
    borderWidth: 1,
    borderColor: '#ccc',
    padding: 3,
    paddingTop: 5,
    textAlign: 'center',
    backgroundColor: '#f7f8fc'
  },
  burger_x: {
    fontFamily: 'Comfortaa'
  },
  info_bubble_text: {
    fontFamily: 'Ysabeau',
    fontSize: 14,
    backgroundColor: '#5ac3c4',
    width: 20,
    padding: 2,
    paddingTop: 0,
    paddingBottom: 4,
    textAlign: 'center',
    borderRadius: 100,
    alignSelf: 'flex-end',
    lineHeight: 16
  },
  info_bubble: {
    position: 'absolute',
    right: 10
  },
  info_bubble_popup: {
    marginVertical: 10,
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 10
  },
  attendee_wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  attendee_text: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    lineHeight: 26,
  },
  a_column: {
    flexDirection: 'column'
  },
  a_row: {
    flexDirection: 'row'
  },
  main_dash_wrapper: {
    flexDirection: 'column'
  },
  page_wrapper: {
    flex: 1, flexDirection: 'column', position: 'relative',
    backgroundColor: 'white',
    paddingBottom: 200
  },
  slider_text: {
    fontSize: 20,
    fontWeight: '500',
    color: 'white',
    marginBottom: 5
  },
  logo_button: { paddingTop: 20, paddingBottom: 20, flexDirection: 'row', justifyContent: 'space-between' },
  main_image_wrapper: {
    position: 'relative',
    height: 500,
    /* "backgroundColor":"#e5e5f7","opacity":"1","backgroundImage":"linear-gradient(135deg, #301a19 25%, transparent 25%), linear-gradient(225deg, #301a19 25%, transparent 25%), linear-gradient(45deg, #301a19 25%, transparent 25%), linear-gradient(315deg, #301a19 25%, #e5e5f7 25%)","backgroundPosition":"40px 0, 40px 0, 0 0, 0 0","backgroundSize":"40px 40px","backgroundRepeat":"repeat" */
    /* "backgroundColor":"#ffffff","opacity":"1","backgroundImage":"linear-gradient(135deg, #2f1e1d 25%, transparent 25%), linear-gradient(225deg, #2f1e1d 25%, transparent 25%), linear-gradient(45deg, #2f1e1d 25%, transparent 25%), linear-gradient(315deg, #2f1e1d 25%, #ffffff 25%)","backgroundPosition":"40px 0, 40px 0, 0 0, 0 0","backgroundSize":"40px 40px","backgroundRepeat":"repeat" */
    "backgroundColor":"#ffffff","opacity":"1","backgroundImage":"linear-gradient(135deg, #2f1e1d 25%, transparent 25%), linear-gradient(225deg, #2f1e1d 25%, transparent 25%), linear-gradient(45deg, #2f1e1d 25%, transparent 25%), linear-gradient(315deg, #2f1e1d 25%, #ffffff 25%)","backgroundPosition":"40px 0, 40px 0, 0 0, 0 0","backgroundSize":"80px 80px","backgroundRepeat":"repeat"
  },
  main_text_wrapper: {
    position: 'absolute',
    left: 0,
    top: 100,
    width: '100%',
    alignContent: 'center', 
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  main_text_band: {
    width: '100%',
    position: 'absolute',
    top: 0,
    height: 500,
    backgroundColor: 'black',
    opacity: .9
  },
  main_callout: {
    fontFamily: 'Ysabeau',
    fontSize: '100px',
    color: 'white',

    textShadowColor: 'rgba(0, 0, 0, 1)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 5
  },
  main_sub_callout: {
    fontFamily: 'Ysabeau',
    fontSize: '50px',
    color: 'white',

    textShadowColor: 'rgba(0, 0, 0, 1)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 5
  },
  main_image: { 
    height: 500 ,
    width: '100%',
    position: 'absolute',
    left: 0,
    top: 0
  },
  secondary_title: {
    fontFamily: 'Comfortaa',
    fontSize: '50px',
    color: 'black',
    width: '100%',
    flexDirection: 'row',
    padding: 20,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderBottomWidth: 1,
    borderTopWidth: 1
  },
  section_title: {
    fontFamily: 'Comfortaa',
    fontSize: '30px',
    color: 'black',
    width: '100%',
    flexDirection: 'row',
    padding: 20,
    marginTop: 30,
    textAlign: 'center'
  },
  events_section: {
    position: 'relative'
  },
  dashboard_breaker: {
    width: '100%',
    height: 100,
    /* borderTopWidth: 1,
    borderBottomWidth: 1 */
  },
  event_box: {
    //borderWidth: 1,
    backgroundColor: 'white',
    flexDirection: 'column',
    flex: 1,
    marginTop: 20
  },
  attencence_box: {
    flex: 1
  },
  event_day: {
    //borderWidth: 1,
    padding: 10,
    margin: 20,
    backgroundColor: '#0b6665',
    flexDirection: 'column',
    width: 150,
    borderRadius: 30,
  },
  non_event_day: {
    //borderWidth: 1,
    padding: 5,
    margin: 10,
    borderRadius: 20,
    flexDirection: 'column',
    width: 50,
    backgroundColor: '#d8e7ef',//d8e7ef
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    height: 50,
    alignSelf: 'center'
  },
  non_event_day_header: {
    //borderWidth: 1,
    height: 25,
    marginHorizontal: 10,
    marginVertical: 20,
    borderRadius: 30,
    flexDirection: 'column',
    width: 50,
    backgroundColor: '#9bc1bf',//d8e7ef
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    alignSelf: 'center'
  },
  non_event_day_text: {
    fontFamily: 'Ysabeau',
    fontSize: 10,
    textAlign: 'center',
    justifyContent: 'center',
    lineHeight: 20
  },
  no_dinners_drop: {
    width: '50%', fontSize: 16, 
    fontFamily: 'Ysabeau',
    lineHeight: 20
  },
  event_day_text: {
    fontFamily: 'Ysabeau',
    fontSize: 22,
    marginTop: -10,
    marginBottom: 20
  },
  event_day_text_header: {
    fontFamily: 'Ysabeau',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    alignSelf: 'center',
    color: 'white'
  },
  restaurant_text_style: {
    fontFamily: 'Comfortaa',
    fontSize: 20,
    paddingVertical: 10
  },
  choose_text: {
    fontFamily: 'Ysabeau',
    fontSize: 16,
    lineHeight: 30,
    textDecorationLine: 'underline'
  },
  action_rsvp: {
    bottom: 0,
    right: 0,
    position: 'absolute'
  },
  full_text: {
    fontFamily: 'Ysabeau',
    textAlign: 'center',
  },
  calendar_box: {
    flexDirection: 'column',
    paddingTop: 10,
    borderRadius: 2,
    width: 'calc(100vw - 40)'
  },
  week_box: {
    flexDirection: 'column'
  },
  calendar_panel: {

    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  title: {
    fontFamily: 'Comfortaa',
    fontSize: 18,
    marginTop: 20,
    marginBottom: 20
  },
  calendar_main_panel: {
    order: 1,
    position: 'relative'
  },
  side_calendar_panel: {
    flexDirection: 'column',
    width: width > 300 ? 300 : '100vw',
    order: 0
  },
  date_count_panel: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
    marginTop: 10,
    marginBottom: 20
  },
  date_panel: {
    flexDirection: 'column',
    flex: 1
  },
  notes_panel: {
    width: '100%'
  },
  review_content_wrapper: {
    flexDirection: 'row'
  },
  count_panel: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  funky_text: {
    fontFamily: 'Ysabeau',
  },
  normal_text: {
    fontFamily: 'Comfortaa',
    marginBottom: 5
  },
  review_month_title: {
    fontSize: 20,
    textAlign: 'center'
  },
  notes_title: {
    //textAlign: 'center'
  },
  multitext_box: {
    height: 50,
    width: 300
  },
  input_box: {
    width: 'calc(100% - 20px)',
    paddingVertical: 20
  },
  multitext_box_notes: {
    height: 50,
    width: '100%',
  },
  pagination_wrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  alight_left: {
    alignItems: 'flex-start',
  },
  alight_right: {
    alignItems: 'flex-end',
  },
  pagination_button_wrapper: {
    flexDirection: 'row',
    alignItems: 'center', 
    justifyContent: 'center'
  },
  carret_left: {
    color: '#0b6665',
    fontSize: 10,
    marginHorizontal: 5,
    textDecorationLine: 'none'
  },
  carret_right: {
    color: '#0b6665',
    fontSize: 10,
    marginLeft: 5,
    textDecorationLine: 'none'
  },
  carousel_progress_button: {
    color: '#0b6665',
    borderBottomWidth: 1,
    borderBottomColor: '#9bc1bf',
    fontSize: 16
  },
  category_header: {
    borderBottomWidth: 1,
    backgroundColor: 'white',
    borderBottomColor: 'white',
    opacity: 1
  },
  category_header_text: {
    fontFamily: 'Ysabeau',
    fontSize: 20,
    paddingHorizontal: 30,
    paddingVertical: 20
  },
  category_info_header: {
    backgroundColor: 'white',
  },
  info_panel: {
    backgroundColor: '#f7f8fc',
    marginLeft: 0,
    padding: 10,
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
    marginBottom: 40,
    width: '100%'
  },
  info_text_panel: {
    flexDirection: 'row',
    marginVertical: 6
  },
  wrap_text_panel: {
    flex:1, 
    flexDirection:'row',
    fontFamily: 'Comfortaa',
    fontSize: 14,
    lineHeight: 20
  },
  info_text: {
    fontFamily: 'Comfortaa'
  },
  category_info_header_text: {
    fontFamily: 'Ysabeau',
    fontSize: 18,
    //paddingHorizontal: 30,
    paddingVertical: 20,
    textDecorationLine: 'underline',
  },
  dashboard_panel: {
    backgroundColor: 'white',
    paddingHorizontal: 20,
  },
  color_explainer: {
    width: 20,
    height: 20,
    borderRadius: 5,
    marginBottom: -5,
    marginHorizontal: 5
  },
  chosen: {
    backgroundColor: '#feba2c'
  },
  approved: {
    backgroundColor: '#5ac3c4'
  },
  rsvped: {
    backgroundColor: '#ea5d47'
  },
  save_button: {
    alignItems: 'center',
    marginTop: 20
  },
  profile_button: {
    backgroundColor: '#e32652',
    color: 'white',
    borderRadius: 10,
    width: 80,
    textAlign: 'center'
  },
  profile_button_text: {
    paddingHorizontal: 20,
    paddingVertical: 6,
    color: 'white',
    fontFamily: 'Ysabeau'
  },
  interests_wrapper: {
    flexDirection: 'row'
  },
  interests_column_1: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: 200,
    marginRight: 30
  },
  interests_column_2: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: 200,
    marginRight: 30
  },
  interests_column_3: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: 200
  },
  subscribe_title_wrapper: {
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  subscription_label: {
    fontSize: 14,
    fontFamily: 'Comfortaa',
    marginBottom: 6
  },
  subscription_type: {
    fontSize: 30,
    marginLeft: 53,
    fontFamily: 'Comfortaa',
    
  },
  auto_renew_wrapper: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginTop: 30
  },
  auto_renew_label: {
    fontSize: 14,
    fontFamily: 'Comfortaa',
    marginBottom: 6
  },
  auto_renew_cancels_label: {
    fontSize: 14,
    fontFamily: 'Comfortaa',
    marginBottom: 6,
    marginRight: 40
  },
  auto_renew_date: {
    fontSize: 14,
    fontFamily: 'Comfortaa',
    marginBottom: 6,
    marginLeft: 30
  },
  active_wrapper: {
    fontSize: 14,
    fontFamily: 'Comfortaa',
    marginBottom: 6,
    marginTop: 20
  },
  active_label: {
    fontSize: 14,
    fontFamily: 'Comfortaa',
    marginBottom: 6,
    color: 'green',
    textDecorationLine: 'underline'
  },
  payment_link_auto_renew: {
    color: '#0b6665',
    textDecorationLine: 'underline',
    fontSize: 14,
    fontFamily: 'Ysabeau',
    marginBottom: 7,
    marginLeft: 20
  },
  payment_links: {
    marginTop: 30,
    flexDirection: 'row',
    alignItems: 'center',
    width: width <= 430 ? width - 20 : 400,
    justifyContent: 'space-between',
    alignSelf: 'flex-end',

  },
  payment_link: {
    color: '#0b6665',
    textDecorationLine: 'underline',
    fontSize: 14,
    fontFamily: 'Ysabeau',
  },
  subscription_box_small: {

    borderRadius: 20,
    padding: 7,
    width: 250,
    backgroundColor: '#0b6665',
    borderColor: 'white',
    borderWidth: 5
  },
  subscription_box_small_text: {
    fontFamily: 'Ysabeau',
    color: 'white',
    fontSize: 14,
    textAlign: 'center'
  },
  subscription_box: {
    borderRadius: 30,
    padding: 20,
    width: 300,
    backgroundColor: 'black',
    borderColor: 'white',
    borderWidth: 5
  },
  subscribe_wrapper: {
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: width <= 400 ? 20 : 120,
  },
  subscription_explainer: {
    marginTop: 10,
    marginBottom: 30,
    fontFamily: 'Comfortaa',
    width: width <= 430 ? width - 20 : 400,
    marginRight: 20
  },
  club_button_text: {
    fontFamily: 'Ysabeau',
    color: 'white',
    fontSize: 20,
    textAlign: 'center'
  },
  panel_white: {
    backgroundColor: 'white',
    height: '100vh'
  },
  subscription_panel: {
    flexDirection: 'column'
  },
  cancel_panel: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cancel_subscription_button: {
    borderRadius: 30,
    backgroundColor: 'red',
    width: width <= 430 ? width - 20 : 400,
    marginVertical: 30
  },
  cancel_subscription_button_text: {
    color: 'black',
    fontFamily: 'Comfortaa',
    fontSize: 16,
    padding: 20,
    textAlign: 'center'
  },
  resume_label: {
    fontSize: 20,
    fontFamily: 'Comfortaa',
    marginBottom: 6
  },
  resume_subscription_button: {
    backgroundColor: '#0b6665',
    borderRadius: 30,
    width: width <= 430 ? width - 20 : 400,
    marginVertical: 20
  },
  resume_button_text: {
    color: 'white',
    fontFamily: 'Comfortaa',
    fontSize: 16,
    padding: 20,
    textAlign: 'center',
  },
  keep_subscription_button: {
    borderRadius: 30,
    backgroundColor: 'white',
    width: width <= 430 ? width - 20 : 400,
    borderWidth: 2,
    borderColor: '#0b6665'
  },
  keep_subscription_button_text: {
    color: '#0b6665',
    fontFamily: 'Comfortaa',
    fontSize: 16,
    padding: 20,
    textAlign: 'center'
  },
  cancel_confirm_title: {
    fontFamily: 'Ysabeau',
    fontSize: 20
  },
  close_panel_button: {
    position: 'absolute',
    top: 0,
    right: 20,
    borderWidth: 1,
    borderRadius: 100,
    width: 20,
    height: 20,
    borderColor: '#0b6665'
  },
  close_panel_button_text: {
    color: '#0b6665',
    fontFamily: 'Comfortaa',
    textAlign: 'center',
  },
  red_text: {
    fontFamily: 'Ysabeau',
    color: 'red',
    textAlign: 'right'
  },
  cancel_reason_text: {
    marginTop: 30,
    width: width <= 430 ? width - 20 : 400,
    height: 100
  },
  gray_out_background: {
    backgroundColor: '#e4aaaa'
  },
  left_nav: {
    borderRightWidth: 1,
    borderColor: '#f7f8fc',
    backgroundColor: '#f7f8fc'
  },
  left_button: {
    fontFamily: 'Ysabeau',
    fontSize: 16,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderBottomWidth: 1,
    borderColor: '#f7f8fc',
    color: 'black'
  },
  left_button_selected: {
    backgroundColor: 'black',
    color: 'white',
  },
  home_breaker: {
    width: '100%',
    height: 50
  },
  line_break: {
    width: '100%',
    height: 10,
    borderBottomWidth: 1,
    borderColor: '#f7f8fc',
    marginLeft: -20
  },
  input_label: {
    fontFamily: "Comfortaa",
    textAlign: 'center',
    marginTop: 10
  },
  input_label_edit: {
    fontFamily: "Comfortaa",
    width: 120,
    marginBottom: 5,
   textAlign: 'center'
  },
  profile_line: {
    flexDirection: 'column',
    marginVertical: 10
  },
  edit_button_wrapper: {
    alignSelf: 'center',
    marginBottom: 20
  },
  input_text: {
    fontFamily: "Ysabeau",
    lineHeight: 30,
    fontSize: 30,
    marginTop: 5,
    textAlign: 'center'
  },
  input_text_interests: {
    fontFamily: "Ysabeau",
    lineHeight: 30,
    fontSize: 18,
    marginTop: 5,
    textAlign: 'center'
  },
  input_text_hobbies: {
    fontFamily: "Ysabeau",
    lineHeight: 30,
    fontSize: 18,
    marginTop: 5,
    textAlign: 'center'
  },
  edit_row_or_column: {
    flexDirection: 'column'
  },
  edit_row: {
    marginBottom: 20,
    alignItems: 'center'
  },
  profile_panel: {
    //width: 1000
  },
  profile_panel_wrapper: {
    width: width - 40,
  },
  pricing_text: {
    fontFamily: 'Ysabeau',
    fontSize: 16,
    cursor: 'pointer',
    textAlign: 'right',
    marginBottom: 20,
    textDecorationLine: 'underline'
  },
  club_button_text: {
    fontFamily: 'Ysabeau',
    color: 'white',
    fontSize: 20,
    textAlign: 'center'
  },
  subscription_box: {
    borderRadius: 30,
    padding: 20,
    width: 300,
    backgroundColor: 'black',
    borderColor: 'white',
    borderWidth: 5
  },
  subscription_yearly: {
    backgroundColor: '#1c1c25'
  },
  subscription_monthly: {
    backgroundColor: 'black'
  },
  label_explainer: {
    textAlign: 'center',
    fontFamily: "Ysabeau",
    marginBottom: 10,
    fontSize: 14,
    width: width - 40,
    lineHeight: 14
  },
  input_label_small: {
    fontFamily: "Comfortaa",
    marginBottom: 5,
    fontSize: 12,
    alignSelf: 'flex-start'
  },
  edit_column_one: {

  },
  age_wrapper_min: {
    marginRight: 20
  },
  age_drop_wrapper: {
    width: width - 40, 
    marginTop: 5,
    justifyContent: 'center'
  },
  rsvp_interim: {
    fontFamily: 'Ysabeau',
    fontSize: 20,
    textAlign: 'center', 
    marginTop: -10
  },
  rsvp_interim_date: {
    fontFamily: 'Ysabeau',
    fontSize: 20,
    textAlign: 'center',
    textDecorationLine: 'underline',
    paddingBottom: 30,
    borderBottomWidth: 1,
    borderColor: '#f7f8fc'
  },
  dinners_wrapper: {
    flexDirection: 'column'
  },
  dinners_header: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '100%'
  },
  dinner_row: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '100%'

  },
  dinner_content:  {
    fontFamily: 'Ysabeau',
    fontSize: 14,
    textAlign: 'center',
    width: width >= 500 ? 200 : 100,
    paddingVertical: 10
  },
  dinner_header_text: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    paddingVertical: 10,
    backgroundColor: '#f7f8fc',
    textAlign: 'center',
    width: width >= 500 ? 200 : 100
  },
  link: {
    color: '#0b6665',
    textDecorationLine: 'underline',
    fontSize: 14,
    fontFamily: 'Ysabeau',
    marginBottom: 7
  },
  image_main: {
    width: width > 500 ? '500px' : 'calc(100vw - 60px)',
    margin: 'auto',
    height: 200,
    borderRadius: 3,
    borderWidth: 2
  },
  main_restaurant_wrapper: {
   
  },
  main_main_wrapper: {
    flexDirection: 'column',
    marginTop: 30,
    borderWidth: 1,
    borderColor: '#b7d5d5',
    borderRadius: 5,
    padding: 10,
    alignContent: 'center'
  },
  attendee_bubble: {
    borderRadius: 100,
    margin: 5,
    width: 30,
    height: 30,
    justifyContent: 'center',
    cursor: 'pointer',
    position: 'relative'
  },
  floating_info: {
    position: 'absolute',
    width: 200,
    backgroundColor: 'white',
    borderWidth: 2,
    borderColor: '#0b6665',
    borderRadius: 10,
    flexDirection: 'column',
    padding: 10,
    zIndex: 100,
    top: '16px',
    left: '13px'
  },
  attendee_name: {
    fontFamily: 'Comfortaa',
    fontSize: 20,
    textAlign: 'cetner',
    marginBottom: 10
  },
  attendee_info: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    paddingVertical: 10,
    lineHeight: 20
  },
  you_goin: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    lineHeight: 30
  },
  attendee_name_text: {
    fontFamily: 'Comfortaa',
    color: 'white',
    fontSize: 14,
    textAlign: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center'
  },
  one_bucket: {
    backgroundColor: '#f7f8fc',
    flexDirection: 'row',
    borderRadius: 2,
    padding: 5,
    width: '100%',
    marginBottom: 10,
    minHeight: 40,
    justifyContent: 'space-between',
  },
  first: {
    flexDirection: 'row',
    zIndex: 10,
    maxWidth: 200,
    flexWrap: 'wrap',
  },
  group_title: {
    fontFamily: 'Ysabeau',
    fontSize: 20,
    marginBottom: 5
  },
  group_title_main: {
    fontFamily: 'Comfortaa',
    fontSize: 18,
    marginBottom: 20,
    marginTop: 20
  },
  profile_one: {
    backgroundColor: '#ff6961',
    zIndex: 'unset'
  },
  profile_two: {
    backgroundColor: '#ffb480',
    zIndex: 'unset'
  },
  profile_three: {
    backgroundColor: '#d4ce50',
    zIndex: 'unset'
  },
  profile_four: {
    backgroundColor: '#42d6a4',
    zIndex: 'unset'
  },
  profile_five: {
    backgroundColor: '#08cad1',
    zIndex: 'unset'
  },
  profile_six: {
    backgroundColor: '#59adf6',
    zIndex: 'unset'
  },
  profile_seven: {
    backgroundColor: '#9d94ff',
    zIndex: 'unset'
  },
  profile_eight: {
    backgroundColor: '#c780e8',
    zIndex: 'unset'
  },
  main_image_wrapper: {
    position: 'relative'
  },
  visit_restaurant: {
    borderColor: 'black',
    borderWidth: 3,
    width: '200px',
    margin: 'auto',
    marginTop: 10,
    marginBottom: 30,
  },
  visit_restaurant_text: {
    fontSize: 14,
    fontFamily: 'Ysabeau',
    borderColor: 'white',
    borderWidth: 1,
    backgroundColor: 'black',
    textAlign: 'center',
    color: 'white',
    paddingHorizontal: 10,
    paddingBottom: 3,
    paddingTop: 1
  },
  profile_image: {
    width: 200,
    height: 200,
    borderRadius: 100,
    borderWidth: 2
  },
  profile_image_wrapper: {
    width: 200,
    height: 200,
  },
  edit_photo_button: {
    color: 'white',
    fontFamily: 'Ysabeau',
    backgroundColor: '#e32652', 
    borderRadius: 5,
    position: 'absolute',
    bottom: 10,
    right: '32%',
    paddingHorizontal: 20
  },
  interest_button: {
    color: 'white',
    fontFamily: 'Ysabeau',
    fontSize: 16,
    backgroundColor: 'gray', 
    borderRadius: 5,
    paddingHorizontal: 20,
    paddingVertical: 5,
    marginRight: 10,
    marginBottom: 10
  },

});

const style_normal = StyleSheet.create({
  ...global_style,
  dinners_description_panel_non_hosted: {
    backgroundColor:  '#8bfa8f',
    padding: 40,
    marginBottom: 20,
    borderRadius: 10,
  },
  non_hosted_wrapper: {
    marginHorizontal: 'auto',
  },
  non_hosted_title: {
    fontFamily: 'Ysabeau',
    fontSize: 26,
    marginBottom: 40,
    textAlign: 'center',
    lineHeight: 26
  },
  non_hosted_description: {
    fontFamily: 'Comfortaa',
    fontSize: 16,
    lineHeight: 26,
    textAlign: 'center'
  },
  rsvp_text: {
    fontFamily: 'Comfortaa',
    fontSize: 18,
    textDecorationLine: 'underline'
  },
  info_bubble_text: {
    fontFamily: 'Ysabeau',
    fontSize: 14,
    backgroundColor: '#5ac3c4',
    width: 20,
    padding: 2,
    paddingTop: 0,
    paddingBottom: 4,
    textAlign: 'center',
    borderRadius: 100,
    alignSelf: 'flex-end',
    lineHeight: 16
  },
  info_bubble: {
    position: 'absolute',
    right: 10
  },
  info_bubble_popup: {
    marginVertical: 10,
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 10
  },
  attendee_wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  attendee_text: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    lineHeight: 26,
  },
  collapsable_burger: {
    display: 'none'
  },
  a_column: {
    flexDirection: 'column'
  },
  a_row: {
    flexDirection: 'row'
  },
  main_dash_wrapper: {
    flexDirection: 'row'
  },
  page_wrapper: {
    flex: 1, flexDirection: 'column', position: 'relative',
    backgroundColor: 'white',
    paddingBottom: 200
  },
  slider_text: {
    fontSize: 20,
    fontWeight: '500',
    color: 'white',
    marginBottom: 5
  },
  logo_button: { paddingTop: 20, paddingBottom: 20, flexDirection: 'row', justifyContent: 'space-between' },
  main_image_wrapper: {
    position: 'relative',
    height: 500,
    /* "backgroundColor":"#e5e5f7","opacity":"1","backgroundImage":"linear-gradient(135deg, #301a19 25%, transparent 25%), linear-gradient(225deg, #301a19 25%, transparent 25%), linear-gradient(45deg, #301a19 25%, transparent 25%), linear-gradient(315deg, #301a19 25%, #e5e5f7 25%)","backgroundPosition":"40px 0, 40px 0, 0 0, 0 0","backgroundSize":"40px 40px","backgroundRepeat":"repeat" */
    /* "backgroundColor":"#ffffff","opacity":"1","backgroundImage":"linear-gradient(135deg, #2f1e1d 25%, transparent 25%), linear-gradient(225deg, #2f1e1d 25%, transparent 25%), linear-gradient(45deg, #2f1e1d 25%, transparent 25%), linear-gradient(315deg, #2f1e1d 25%, #ffffff 25%)","backgroundPosition":"40px 0, 40px 0, 0 0, 0 0","backgroundSize":"40px 40px","backgroundRepeat":"repeat" */
    "backgroundColor":"#ffffff","opacity":"1","backgroundImage":"linear-gradient(135deg, #2f1e1d 25%, transparent 25%), linear-gradient(225deg, #2f1e1d 25%, transparent 25%), linear-gradient(45deg, #2f1e1d 25%, transparent 25%), linear-gradient(315deg, #2f1e1d 25%, #ffffff 25%)","backgroundPosition":"40px 0, 40px 0, 0 0, 0 0","backgroundSize":"80px 80px","backgroundRepeat":"repeat"
  },
  main_text_wrapper: {
    position: 'absolute',
    left: 0,
    top: 100,
    width: '100%',
    alignContent: 'center', 
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  main_text_band: {
    width: '100%',
    position: 'absolute',
    top: 0,
    height: 500,
    backgroundColor: 'black',
    opacity: .9
  },
  main_callout: {
    fontFamily: 'Ysabeau',
    fontSize: '100px',
    color: 'white',

    textShadowColor: 'rgba(0, 0, 0, 1)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 5
  },
  main_sub_callout: {
    fontFamily: 'Ysabeau',
    fontSize: '50px',
    color: 'white',

    textShadowColor: 'rgba(0, 0, 0, 1)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 5
  },
  main_image: { 
    height: 500 ,
    width: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    borderWidth: 5
  },
  secondary_title: {
    fontFamily: 'Comfortaa',
    fontSize: '50px',
    color: 'black',
    width: '100%',
    flexDirection: 'row',
    padding: 20,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderBottomWidth: 1,
    borderTopWidth: 1
  },
  section_title: {
    fontFamily: 'Comfortaa',
    fontSize: '30px',
    color: 'black',
    width: '100%',
    flexDirection: 'row',
    padding: 20,
    marginTop: 30,
    marginBottom: 30,
    textAlign: 'center'
  },
  events_section: {
    position: 'relative'
  },
  dashboard_breaker: {
    width: '100%',
    height: 100,
    /* borderTopWidth: 1,
    borderBottomWidth: 1 */
  },
  event_box: {
    //borderWidth: 1,
    backgroundColor: 'white',
    flexDirection: 'column',
    flex: 1
  },
  attencence_box: {
    flex: 1
  },
  event_day: {
    //borderWidth: 1,
    padding: 10,
    margin: 20,
    backgroundColor: '#0b6665',
    flexDirection: 'column',
    width: 150,
    borderRadius: 30,
  },
  non_event_day: {
    //borderWidth: 1,
    padding: 5,
    margin: 10,
    borderRadius: 20,
    flexDirection: 'column',
    width: 50,
    backgroundColor: '#d8e7ef',//d8e7ef
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    height: 50,
    alignSelf: 'center'
  },
  non_event_day_header: {
    //borderWidth: 1,
    height: 25,
    marginHorizontal: 10,
    marginVertical: 20,
    borderRadius: 30,
    flexDirection: 'column',
    width: 50,
    backgroundColor: '#9bc1bf',//d8e7ef
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    alignSelf: 'center'
  },
  non_event_day_text: {
    fontFamily: 'Ysabeau',
    fontSize: 10,
    textAlign: 'center',
    justifyContent: 'center',
    lineHeight: 20
  },
  no_dinners_drop: {
    width: '50%', fontSize: 16, 
    fontFamily: 'Ysabeau',
    lineHeight: 20
  },
  event_day_text: {
    fontFamily: 'Ysabeau',
    fontSize: 22,
    marginTop: -10,
    marginBottom: 20
  },
  event_day_text_header: {
    fontFamily: 'Ysabeau',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    alignSelf: 'center',
    color: 'white'
  },
  restaurant_text_style: {
    fontFamily: 'Comfortaa',
    fontSize: 20,
    paddingVertical: 10
  },
  choose_text: {
    fontFamily: 'Ysabeau',
    fontSize: 16,
    lineHeight: 30,
    textDecorationLine: 'underline'
  },
  action_rsvp: {
    bottom: 0,
    right: 0,
    position: 'absolute'
  },
  full_text: {
    fontFamily: 'Ysabeau',
    textAlign: 'center',
  },
  calendar_box: {
    flexDirection: 'column',
    paddingTop: 10,
    borderRadius: 2,
    width: 800
  },
  week_box: {
    flexDirection: 'column'
  },
  calendar_panel: {

    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  title: {
    fontFamily: 'Comfortaa',
    fontSize: 18,
    marginTop: 20,
    marginBottom: 20
  },
  side_calendar_panel: {
    flexDirection: 'column',
    width: 200
  },
  date_count_panel: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
    marginTop: 10,
    marginBottom: 20
  },
  date_panel: {
    flexDirection: 'column',
    flex: 1
  },
  notes_panel: {
    width: '100%'
  },
  review_content_wrapper: {
    flexDirection: 'row'
  },
  count_panel: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  funky_text: {
    fontFamily: 'Ysabeau',
  },
  normal_text: {
    fontFamily: 'Comfortaa',
    marginBottom: 5
  },
  review_month_title: {
    fontSize: 20,
    textAlign: 'center'
  },
  notes_title: {
    //textAlign: 'center'
  },
  multitext_box: {
    height: 50,
    width: 300
  },
  input_box: {
    width: 500,
    paddingVertical: 20
  },
  multitext_box_notes: {
    height: 50,
    width: '100%',
  },
  pagination_wrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  alight_left: {
    alignItems: 'flex-start',
  },
  alight_right: {
    alignItems: 'flex-end',
  },
  pagination_button_wrapper: {
    flexDirection: 'row',
    alignItems: 'center', 
    justifyContent: 'center'
  },
  carret_left: {
    color: '#0b6665',
    fontSize: 10,
    marginHorizontal: 5,
    textDecorationLine: 'none'
  },
  carret_right: {
    color: '#0b6665',
    fontSize: 10,
    marginLeft: 5,
    textDecorationLine: 'none'
  },
  carousel_progress_button: {
    color: '#0b6665',
    borderBottomWidth: 1,
    borderBottomColor: '#9bc1bf',
    fontSize: 16
  },
  category_header: {
    borderBottomWidth: 1,
    backgroundColor: 'white',
    borderBottomColor: 'white',
    opacity: 1
  },
  category_header_text: {
    fontFamily: 'Ysabeau',
    fontSize: 20,
    paddingHorizontal: 30,
    paddingVertical: 20
  },
  category_info_header: {
    backgroundColor: 'white',
  },
  info_panel: {
    backgroundColor: '#f7f8fc',
    marginLeft: 0,
    padding: 10,
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
    marginBottom: 40,
    width: '100%'
  },
  info_text_panel: {
    flexDirection: 'row',
    marginVertical: 6
  },
  wrap_text_panel: {
    flex:1, 
    flexDirection:'row',
    fontFamily: 'Comfortaa',
    fontSize: 14,
    lineHeight: 20
  },
  info_text: {
    fontFamily: 'Comfortaa'
  },
  category_info_header_text: {
    fontFamily: 'Ysabeau',
    fontSize: 18,
    //paddingHorizontal: 30,
    paddingVertical: 20,
    textDecorationLine: 'underline',
    paddingBottom: 40
  },
  dashboard_panel: {
    backgroundColor: 'white',
    paddingHorizontal: 20,
  },
  color_explainer: {
    width: 20,
    height: 20,
    borderRadius: 5,
    marginBottom: -5,
    marginHorizontal: 5
  },
  chosen: {
    backgroundColor: '#feba2c'
  },
  approved: {
    backgroundColor: '#5ac3c4'
  },
  rsvped: {
    backgroundColor: '#ea5d47'
  },
  profile_button: {
    backgroundColor: '#e32652',
    color: 'white',
    borderRadius: 10,
    width: 80,
    textAlign: 'center'
  },
  profile_button_text: {
    paddingHorizontal: 20,
    paddingVertical: 6,
    color: 'white',
    fontFamily: 'Ysabeau'
  },
  interests_wrapper: {
    flexDirection: 'row'
  },
  interests_column_1: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: 200,
    marginRight: 30
  },
  interests_column_2: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: 200,
    marginRight: 30
  },
  interests_column_3: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: 200
  },
  subscribe_title_wrapper: {
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  subscription_label: {
    fontSize: 14,
    fontFamily: 'Comfortaa',
    marginBottom: 6
  },
  subscription_type: {
    fontSize: 30,
    marginLeft: 53,
    fontFamily: 'Comfortaa',
    
  },
  auto_renew_wrapper: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginTop: 30
  },
  auto_renew_label: {
    fontSize: 14,
    fontFamily: 'Comfortaa',
    marginBottom: 6
  },
  auto_renew_cancels_label: {
    fontSize: 14,
    fontFamily: 'Comfortaa',
    marginBottom: 6,
    marginRight: 40
  },
  auto_renew_date: {
    fontSize: 14,
    fontFamily: 'Comfortaa',
    marginBottom: 6,
    marginLeft: 30
  },
  active_wrapper: {
    fontSize: 14,
    fontFamily: 'Comfortaa',
    marginBottom: 6,
    marginTop: 20
  },
  active_label: {
    fontSize: 14,
    fontFamily: 'Comfortaa',
    marginBottom: 6,
    color: 'green',
    textDecorationLine: 'underline'
  },
  payment_link_auto_renew: {
    color: '#0b6665',
    textDecorationLine: 'underline',
    fontSize: 14,
    fontFamily: 'Ysabeau',
    marginBottom: 7,
    marginLeft: 20
  },
  payment_links: {
    marginTop: 30,
    flexDirection: 'row',
    alignItems: 'center',
    width: width <= 430 ? width - 20 : 400,
    justifyContent: 'space-between',
    alignSelf: 'flex-end',

  },
  payment_link: {
    color: '#0b6665',
    textDecorationLine: 'underline',
    fontSize: 14,
    fontFamily: 'Ysabeau',
  },
  subscription_box_small: {

    borderRadius: 20,
    padding: 7,
    width: 250,
    backgroundColor: '#0b6665',
    borderColor: 'white',
    borderWidth: 5
  },
  subscription_box_small_text: {
    fontFamily: 'Ysabeau',
    color: 'white',
    fontSize: 14,
    textAlign: 'center'
  },
  subscription_box: {
    borderRadius: 30,
    padding: 20,
    width: 300,
    backgroundColor: 'black',
    borderColor: 'white',
    borderWidth: 5
  },
  subscribe_wrapper: {
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: 120,
  },
  subscription_explainer: {
    marginTop: 10,
    marginBottom: 30,
    fontFamily: 'Comfortaa',
    width: width <= 430 ? width - 20 : 400,
    marginRight: 20
  },
  club_button_text: {
    fontFamily: 'Ysabeau',
    color: 'white',
    fontSize: 20,
    textAlign: 'center'
  },
  panel_white: {
    backgroundColor: 'white',
    height: '100vh'
  },
  subscription_panel: {
    flexDirection: 'column'
  },
  cancel_panel: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cancel_subscription_button: {
    borderRadius: 30,
    backgroundColor: 'red',
    width: width <= 430 ? width - 20 : 400,
    marginVertical: 30
  },
  cancel_subscription_button_text: {
    color: 'black',
    fontFamily: 'Comfortaa',
    fontSize: 16,
    padding: 20,
    textAlign: 'center'
  },
  resume_label: {
    fontSize: 20,
    fontFamily: 'Comfortaa',
    marginBottom: 6
  },
  resume_subscription_button: {
    backgroundColor: '#0b6665',
    borderRadius: 30,
    width: width <= 430 ? width - 20 : 400,
    marginVertical: 20
  },
  resume_button_text: {
    color: 'white',
    fontFamily: 'Comfortaa',
    fontSize: 16,
    padding: 20,
    textAlign: 'center',
  },
  keep_subscription_button: {
    borderRadius: 30,
    backgroundColor: 'white',
    width: width <= 430 ? width - 20 : 400,
    borderWidth: 2,
    borderColor: '#0b6665'
  },
  keep_subscription_button_text: {
    color: '#0b6665',
    fontFamily: 'Comfortaa',
    fontSize: 16,
    padding: 20,
    textAlign: 'center'
  },
  cancel_confirm_title: {
    fontFamily: 'Ysabeau',
    fontSize: 20
  },
  close_panel_button: {
    position: 'absolute',
    top: 0,
    right: 20,
    borderWidth: 1,
    borderRadius: 100,
    width: 20,
    height: 20,
    borderColor: '#0b6665'
  },
  close_panel_button_text: {
    color: '#0b6665',
    fontFamily: 'Comfortaa',
    textAlign: 'center',
  },
  red_text: {
    fontFamily: 'Ysabeau',
    color: 'red',
    textAlign: 'right'
  },
  cancel_reason_text: {
    marginTop: 30,
    width: width <= 430 ? width - 20 : 400,
    height: 100
  },
  gray_out_background: {
    backgroundColor: '#e4aaaa'
  },
  left_nav: {
    borderRightWidth: 1,
    borderColor: '#f7f8fc',
    backgroundColor: '#f7f8fc'
  },
  left_button: {
    fontFamily: 'Ysabeau',
    fontSize: 16,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderBottomWidth: 1,
    borderColor: '#f7f8fc',
    color: 'black'
  },
  left_button_selected: {
    backgroundColor: 'black',
    color: 'white',
  },
  home_breaker: {
    width: '100%',
    height: 50
  },
  line_break: {
    width: '100%',
    height: 10,
    borderBottomWidth: 1,
    borderColor: '#f7f8fc',
    marginLeft: -20
  },
  input_label: {
    fontFamily: "Comfortaa",
    marginTop: 10
   /*  marginTop: 20,
    marginBottom: 5, */
    //alignSelf: 'center'
  },
  input_label_edit: {
    fontFamily: "Comfortaa",
    width: 120,
    marginBottom: 5
   /*  marginTop: 20,
    , */
    //alignSelf: 'center'
  },
  profile_line: {
    flexDirection: 'column',
    marginVertical: 10
  },
  edit_button_wrapper: {
    alignSelf: 'flex-end',
    marginRight: 100
  },
  input_text: {
    fontFamily: "Ysabeau",
    lineHeight: 30,
    fontSize: 30,
    marginLeft: 20,
    marginTop: 5
  },
  input_text_interests: {
    fontFamily: "Ysabeau",
    lineHeight: 30,
    fontSize: 18,
    marginLeft: 20,
    marginTop: 5
  },
  input_text_hobbies: {
    fontFamily: "Ysabeau",
    lineHeight: 30,
    fontSize: 18,
    marginLeft: 20,
    marginTop: 5
  },
  edit_row_or_column: {
    flexDirection: 'row'
  },
  edit_row: {
    marginBottom: 20
  },
  profile_panel: {
    //width: 1000
  },
  profile_panel_wrapper: {
    width: 1000,
  },
  pricing_text: {
    fontFamily: 'Ysabeau',
    fontSize: 16,
    cursor: 'pointer',
    textAlign: 'right',
    marginBottom: 20,
    textDecorationLine: 'underline'
  },
  club_button_text: {
    fontFamily: 'Ysabeau',
    color: 'white',
    fontSize: 20,
    textAlign: 'center'
  },
  subscription_box: {
    borderRadius: 30,
    padding: 20,
    width: 300,
    backgroundColor: 'black',
    borderColor: 'white',
    borderWidth: 5
  },
  subscription_yearly: {
    backgroundColor: '#1c1c25'
  },
  subscription_monthly: {
    backgroundColor: 'black'
  },
  label_explainer: {
    fontFamily: "Ysabeau",
    marginBottom: 10,
    fontSize: 14,
    width: 500,
    lineHeight: 14,

  },
  input_label_small: {
    fontFamily: "Comfortaa",
    marginBottom: 5,
    fontSize: 12,
    alignSelf: 'flex-start'
  },
  edit_column_one: {
    flex: 1
  },
  age_wrapper: {
    //marginRight: 40
  },
  age_drop_wrapper: {
    width: 145, marginTop: 5
  },
  rsvp_interim: {
    fontFamily: 'Ysabeau',
    fontSize: 20,
    textAlign: 'center', 
    marginTop: -10
  },
  rsvp_interim_date: {
    fontFamily: 'Ysabeau',
    fontSize: 20,
    textAlign: 'center',
    textDecorationLine: 'underline',
    paddingBottom: 30,
    borderBottomWidth: 1,
    borderColor: '#f7f8fc'
  },
  dinners_wrapper: {
    flexDirection: 'column'
  },
  dinners_header: {
    flexDirection: 'row'
  },
  dinner_row: {
    flexDirection: 'row',

  },
  dinner_content:  {
    fontFamily: 'Ysabeau',
    fontSize: 14,
    width: 200,
    margin: 10,
    padding: 10,
    textAlign: 'center'
  },
  dinner_header_text: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    width: 200,
    margin: 10,
    padding: 10,
    backgroundColor: '#f7f8fc',
    textAlign: 'center'
  },
  link: {
    color: '#0b6665',
    textDecorationLine: 'underline',
    fontSize: 14,
    fontFamily: 'Ysabeau',
    marginBottom: 7,
    marginLeft: 20
  },
  image_main: {
    width: '300px',
    height: 200,
    borderRadius: 3,
    borderWidth: 2
  },
  main_restaurant_wrapper: {
    marginLeft: 20
  },
  main_main_wrapper: {
    flexDirection: 'row',
    marginTop: 30,
    borderWidth: 1,
    borderColor: '#b7d5d5',
    borderRadius: 5,
    padding: 30
  },
  attendee_bubble: {
    borderRadius: 100,
    width: 30,
    height: 30,
    justifyContent: 'center',
    marginRight: 5,
    cursor: 'pointer',
    position: 'relative'
  },
  floating_info: {
    position: 'absolute',
    width: 200,
    backgroundColor: 'white',
    borderWidth: 2,
    borderColor: '#0b6665',
    borderRadius: 10,
    flexDirection: 'column',
    padding: 10,
    zIndex: 100,
    top: '16px',
    left: '13px'
  },
  attendee_name: {
    fontFamily: 'Comfortaa',
    fontSize: 20,
    textAlign: 'cetner',
    marginBottom: 10
  },
  attendee_info: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    paddingVertical: 10,
    lineHeight: 20
  },
  you_goin: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    lineHeight: 30
  },
  attendee_name_text: {
    fontFamily: 'Comfortaa',
    color: 'white',
    fontSize: 14,
    textAlign: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center'
  },
  one_bucket: {
    backgroundColor: '#f7f8fc',
    flexDirection: 'row',
    borderRadius: 2,
    padding: 5,
    width: '100%',
    marginBottom: 10,
    height: 40,
    justifyContent: 'space-between',
  },
  first: {
    flexDirection: 'row',
    zIndex: 10
  },
  group_title: {
    fontFamily: 'Ysabeau',
    fontSize: 20,
    marginBottom: 5
  },
  group_title_main: {
    fontFamily: 'Comfortaa',
    fontSize: 18,
    marginBottom: 20
  },
  profile_one: {
    backgroundColor: '#ff6961',
    zIndex: 'unset'
  },
  profile_two: {
    backgroundColor: '#ffb480',
    zIndex: 'unset'
  },
  profile_three: {
    backgroundColor: '#d4ce50',
    zIndex: 'unset'
  },
  profile_four: {
    backgroundColor: '#42d6a4',
    zIndex: 'unset'
  },
  profile_five: {
    backgroundColor: '#08cad1',
    zIndex: 'unset'
  },
  profile_six: {
    backgroundColor: '#59adf6',
    zIndex: 'unset'
  },
  profile_seven: {
    backgroundColor: '#9d94ff',
    zIndex: 'unset'
  },
  profile_eight: {
    backgroundColor: '#c780e8',
    zIndex: 'unset'
  },
  main_image_wrapper: {
    position: 'relative'
  },
  visit_restaurant: {
    borderColor: 'black',
    borderWidth: 3,
    width: '200px',
    position: 'absolute',
    bottom: 10,
    left: 68
  },
  visit_restaurant_text: {
    fontSize: 14,
    fontFamily: 'Ysabeau',
    borderColor: 'white',
    borderWidth: 1,
    backgroundColor: 'black',
    textAlign: 'center',
    color: 'white',
    paddingHorizontal: 10,
    paddingBottom: 3,
    paddingTop: 1
  },
  profile_image: {
    width: 200,
    height: 200,
    borderRadius: 100,
    borderWidth: 2
  },
  profile_image_wrapper: {
    width: 200,
    height: 200,
  },
  edit_photo_button: {
    color: 'white',
    fontFamily: 'Ysabeau',
    backgroundColor: '#e32652', 
    borderRadius: 5,
    position: 'absolute',
    bottom: 10,
    right: '32%',
    paddingBottom: 3,
    paddingHorizontal: 20,
    borderColor: 'white',
    borderWidth: 2
  },
  drop_style: { 
    width: 50, 
    borderWidth: 1,
    padding: 10,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 12,
    backgroundColor: 'white',
    fontSize: 14,
    fontFamily: 'Comfortaa',
    color: '#575762',
    borderColor: '#bbbbc0'
  },
  drop_style_profile: { 
    width: 300, 
    borderWidth: 1,
    padding: 10,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 12,
    backgroundColor: 'white',
    fontSize: 14,
    fontFamily: 'Comfortaa',
    color: '#575762',
    borderColor: '#bbbbc0'
  },
  age_wrapper: {
    marginRight: 40
  },
  interest_button: {
    color: 'white',
    fontFamily: 'Ysabeau',
    fontSize: 16,
    backgroundColor: 'gray', 
    borderRadius: 5,
    paddingHorizontal: 20,
    paddingVertical: 5,
    marginRight: 10,
    marginBottom: 10
  }
});

let style = width < 1160 ? style_small : style_normal;

export {
  style
}