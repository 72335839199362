import React, { Component, forwardRef } from 'react';
import * as Progress from 'react-native-progress';
import { ScrollView, View, TouchableOpacity, Platform, Dimensions } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { Text } from '../../components';
import { style } from './style';
import { global_style } from '../../../global_style';
let window_width = Dimensions.get('window').width;
let window_height = Dimensions.get('window').height;

class Modal_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      attendee: props.attendee,
      is_mobile: window_width < 500
    }

    this.setContent = this.setContent.bind(this);
    this.close = this.close.bind(this);
  }

  async componentDidMount() {
    let token = await localStorage.getItem('token');

    this.setState({
      token,
      mounted: true
    })
  }

// call this to Disable
  toggleScroll(disable) {
    if(disable) {
      document['body'].style.overflow = 'hidden';
      document['body'].style.height = '100%';
    } else {
      document['body'].style.overflow = 'unset';
      document['body'].style.height = 'unset';
    }
  }

  close() {
    this.props.putInParent(null)
    this.toggleScroll(false);
    this.setState({modal_content: null});

    let elem = document.getElementsByClassName('olark-button-focus-wrapper');
    console.log('elemclose', elem)
    if(elem && elem.length) {
      elem = elem[0];
      if(elem.setAttribute) {
        elem.setAttribute('style', 'display: block;')
      }
    }
  }

  setContent(content, width, height, put_in_header, execute_after, on_close, title_component, sticky_footer) {

    let elem = document.getElementsByClassName('olark-button-focus-wrapper');
    console.log('elemopen', elem)
    if(elem && elem.length) {
      elem = elem[0];
      if(elem.setAttribute) {
        elem.setAttribute('style', 'display: none;')
      }
    }

    this.toggleScroll(true);
    let { is_mobile } = this.state;

    height = 'calc(100vh - 40px)'

    let left, top, content_height, content_top_margin, modal_content_style, put_in_header_style, content_wrapper_style;
    if(is_mobile) {
      height = window_height;
      left = 0;
      top = 0;
      content_height = height;
      content_top_margin = 0;
      width = window_width;

      content_wrapper_style = {padding: 0, paddingTop: 0}

      modal_content_style = {
        flex: 1,
        backgroundColor: 'white',
        flexDirection: 'column',
        padding: 20,
        zIndex: 999999999,
        top: 0,
        left: 0,
        alignItems: 'center',
        display: 'flex', 
        width, 
        height, 
        position: 'absolute'
      }

      put_in_header_style = {
        flex: 1
        //position: 'absolute', top: 40, left: 40,
      }

    } else {
      top = '20px';
      left = `calc(50% - ${width / 2}px)`;
      content_height = 'calc(80vh - 20px)';
      content_top_margin = 0;

      content_wrapper_style = {padding: 40, paddingTop: 0}

      modal_content_style = {
        flex: 1,
        position: 'absolute',
        backgroundColor: 'white',
        flexDirection: 'column',
        padding: 0,
        zIndex: 200001,
        borderRadius: 10,
        top: 20,
        alignItems: 'center',
        borderRadius: 10,
        display: 'flex', width, height, left, top, position: 'absolute'
      }

      put_in_header_style = {
        flex: 1
        //position: 'absolute', top: 27, left: 20
      }
    }

    const close = () => {
        if(on_close) {
          on_close();
        } else {
          this.close();
        }
    }

    console.log('modal_content_style', modal_content_style)

    let modal_content = <View style={{flex: 1, zIndex: 99999999, position: 'fixed', width: '100vw', height: '100vh', overflow: 'hidden', left: 0}} >
      <>
        <TouchableOpacity onPress={()=>{this.setState({modal_content: null})}} style={global_style.modal_overlay}></TouchableOpacity>
        <View style={{position: 'fixed', top: 0, width: '100vw', height: '100vh', zIndex: 2000000}}>
          <View style={{position: 'relative', height: '100vh'}}>
            <View style={modal_content_style}>
              
              <View style={{flexDirection: 'row', justifyContent: 'center', width: '100%', alignItems: 'center', padding: 20}}>
                <View style={{ width: 100}}>{put_in_header}</View>
                <View style={{width: modal_content_style.width - 240}}>{title_component}</View>
                <View style={{width: 100}}>
                  <TouchableOpacity style={style.modal_x_wrapper} onPress={close} ><Text style={[style.modal_close_x]}>x</Text></TouchableOpacity>
                </View>
              </View>
              
                <ScrollView style={{flex: 1, height: content_height, marginTop: content_top_margin, width: '100%' }}>
                  <View style={[global_style.padding_20, content_wrapper_style]}>{content}</View>
              </ScrollView>
            </View>
            {sticky_footer ?
            <View style={{ position: 'absolute', borderBottomRightRadius: is_mobile ? 0 : 10,borderBottomLeftRadius: is_mobile ? 0 : 10, left: is_mobile ? 0 : left, bottom: is_mobile ? 0 : 20, width: is_mobile ? '100vw' : width, zIndex: 999999999, backgroundColor: 'white', boxShadow: is_mobile ? '3px 5px 7px 4px' : 'unset', paddingBottom: is_mobile ? 90 : 10, borderTopWidth: is_mobile ? 'unset'  : 1, borderColor: '#e2dddd' }}>
              {sticky_footer}
            </View> : null}
          </View>
        </View>
      </>

      </View>

      this.props.putInParent(modal_content)

      if(execute_after) {
        execute_after();
      }
  }

  render() {

    let { content, overwrite_style } = this.props;
    let { mounted, modal_content } = this.state;
    
    return mounted ? 
      <>

        
        
      </> : null
  }

}

function Modal(props, ref) {
  if(Platform.OS === 'web') {
    let navigate = useNavigate();
    return  <Modal_Base ref={ref} {...props} navigate={navigate} />
  } else {
    return  <Modal_Base {...props} navigate={props.navigation.push}  />
  }
}

export default forwardRef(Modal);
