import React, { Component } from 'react';
import { StyleSheet, Text, View, TextInput } from 'react-native';
import { Colors, Check } from '../components';

class Input extends Component {

  constructor(props) {
    super(props);
    this.state = {
      border_color: this.props.border_color || '#bbbbc0',
      is_active: false
    }

    this.inputRef = React.createRef();
    this.focus = this.focus.bind(this);
  }

  focus() {
    console.log('inputRef', this.inputRef);
    this.setState({is_active: true, border_color: Colors.PRIMARY})
    /* if(this.inputRef) {
      this.inputRef.onFocus();
    } */
  }

  render_text_input(combined_props) {

    return <TextInput 
                  ref={node=>{this.inputRef = node}}
                  multiline={this.props.multiline}
                  placeholder={ combined_props.placeholder }
                  value={combined_props.value}
                  defaultValue={ combined_props.defaultValue }
                  autoCapitalize={ combined_props.autoCapitalize }
                  autoCorrect={ combined_props.autoCorrect }
                  keyboardType={ combined_props.keyboardType }
                  secureTextEntry={ combined_props.secureTextEntry }
                  textContentType={ combined_props.textContentType }
                  onBlur={  () => { this.setState({ is_active: false, border_color: this.props.border_color || '#bbbbc0' }) }}
                  onFocus={ () => { this.setState({ is_active: true,  border_color: Colors.PRIMARY }) }}
                  style={[ styles.default_style, combined_props.active_style, combined_props.passed_style ]}
                  onChangeText={ combined_props.change_action }
                  onTextInput={combined_props.onTextInput}
                  onLayout={combined_props.onLayout}
                  />
  }

  render_phone_input(combined_props) {

    return <TextInput
              keyboardType={'phone-pad'} 
                  ref={node=>{this.inputRef = node}}
                  placeholder={ combined_props.placeholder }
                  value={combined_props.value}
                  defaultValue={ combined_props.defaultValue }
                  autoCapitalize={ combined_props.autoCapitalize }
                  autoCorrect={ combined_props.autoCorrect }
                  secureTextEntry={ combined_props.secureTextEntry }
                  textContentType={ combined_props.textContentType }
                  onBlur={  () => { this.setState({ is_active: false, border_color: this.props.border_color || '#bbbbc0' }) }}
                  onFocus={ () => { this.setState({ is_active: true,  border_color: Colors.PRIMARY }) }}
                  style={[ styles.default_style, combined_props.active_style, combined_props.passed_style ]}
                  onChangeText={ combined_props.change_action }
                  onTextInput={combined_props.onTextInput}
                  onLayout={combined_props.onLayout}
                  />
  }

  render_check() {
    return <Check {...this.props} />
  }

  render_input() {

    let value         = this.props.value || '';
    let change_action = this.props.onChangeText;
    let placeholder   = this.props.placeholder || '';
    let passed_style  = this.props.style || {};
    let active_style  = { borderColor: this.state.border_color }

    let textContentType = this.props.textContentType || 'none';
    let autoCapitalize  = this.props.autoCapitalize || 'none';
    let autoCorrect     = this.props.autoCorrect     === true ? true : false;
    let secureTextEntry = this.props.secureTextEntry === true ? true : false;
    let keyboardType    = this.props.keyboardType || 'default';
    let onTextInput = this.props.onTextInput;
    let onLayout = this.props.onLayout;

    let defaultValue = this.props.defaultValue;

    let combined_props = { value, defaultValue, change_action, placeholder, passed_style, active_style, autoCapitalize, autoCorrect, textContentType, secureTextEntry, keyboardType, onTextInput, onLayout };

    switch(this.props.type) {
      case "phone":
        return this.render_phone_input(combined_props);
      case "masked":
        return this.render_masked_input();
      case 'date-mmddyyyy':
        return this.render_date_input(combined_props);
      case 'date-mmddyyyy':
        return this.render_date_input(combined_props);
      case "check":
        return this.render_check();
      default:
        return this.render_text_input(combined_props);
    }
  }

  render_label = () => {
    let label = this.props.label || '';

    if (!label) {
      return null;
    }

    return <View style={{ marginLeft: 10, height: 18, backgroundColor: 'white', position: 'absolute', zIndex: 3, elevation: 0, paddingRight: 8, paddingLeft: 8 }}>
              <Text style={{ color: Colors.TEXT_GREY }}>{ label }</Text>
           </View>
  }

  render() {
    let label      = this.props.label || '';
    let pad_height = label ? 9 : 0;
    let type = this.props.type;
    
    return (
      <>
        <View style={[{ height: pad_height }]} />
        { type !== 'check' ? this.render_label() : null }
        { this.render_input() }
      </>
    );
  }

}

const styles = StyleSheet.create({
  default_style: {
    color: Colors.TEXT_GREY,
    borderWidth: 1,
    padding: 10,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 12,
    backgroundColor: 'white',
    fontSize: 14,
    fontFamily: 'Comfortaa'
  }
});

export default Input
