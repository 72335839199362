import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { Text as DefaultText, StyleSheet } from 'react-native';
import { Icon, Colors } from '../components';

class Text extends Component {

  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {

    let style       = this.props.style;
    let font_family = 'DMSans-Regular';
    let hide        = this.props.hide ? true : false;
    let font_weight = 'normal';
        font_weight = style && style.fontWeight ? style.fontWeight : font_weight;

    if (style && style.length) {
      style.forEach((styl_obj) => {
        if (styl_obj && styl_obj.fontWeight) {
          font_weight = styl_obj.fontWeight;
        }
      })
    }

    if (font_weight) {

      switch (font_weight) {
        /*
        case '100':
          font_family = 'Roboto-Thin';
          break;
        case '200':
          font_family = 'Roboto-Light';
          break;
        case '300':
          font_family = 'Roboto-Light';
          break; */
        case 'normal':
          font_family = 'DMSans-Regular';
          break;
        case '400':
          font_family = 'DMSans-Medium';
          break;
        case 'bold':
          font_family = 'DMSans-Bold';
          break;
        case '500':
          font_family = 'Lexend-Bold';
          break;
        default:
          font_family = 'DMSans-Regular'
      }

    }

    if (hide) {
      return null;
    }

    let style_prop = style && style.length ? [ { fontFamily: font_family, color: 'black' }, ...style ] : { fontFamily: font_family, color: 'black', ...style };

    return <DefaultText { ...this.props } style={style_prop} >{ this.props.children }</DefaultText>
  }

}

export default Text
