import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { StyleSheet, View, TouchableOpacity, Platform, Image, Dimensions } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { Text, Input, Button } from '../../components';
import UserController from '../../controllers/userController';
import { style } from './style';
import Icon from 'react-native-vector-icons/FontAwesome';
import { ScrollView } from 'react-native-gesture-handler';
import AuthController from '../../controllers/authController';

class Messaging_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      attendee: props.attendee,
      user: props.user,
      collapsed: true,
      event: props.event,
      hosts: props.hosts
    }

    this.sendMessage = this.sendMessage.bind(this);
    this.refreshEvery5 = this.refreshEvery5.bind(this);
    this.onClose = this.onClose.bind(this);
    this.enableChat = this.enableChat.bind(this);
  }

  async componentDidMount() {
    let user = this.state.user;
    let user_id = user.user_id;
    let to_user_id = this.state.attendee.user_id;

    console.log('in <Chat />', user)

    await UserController.markMessagesSeen({ from_user_id: to_user_id })
    let messages = await UserController.getChatMessages({to_user_id, user_id})
    let messaging = user.messaging;

    if(typeof messaging === 'string') {
      messaging = JSON.parse(messaging);
    }

    messaging = messaging ? messaging : {public: true, enabled_users: [], dining_users: []}

    let keys = Object.keys(messaging);

    if(keys.indexOf('public') === -1) {
      messaging = {
        ...messaging,
        public: true
      }
    }

    if(keys.indexOf('enabled_users') === -1) {
      messaging = {
        ...messaging,
        enabled_users: []
      }
    }

    if(keys.indexOf('dining_users') === -1) {
      messaging = {
        ...messaging,
        dining_users: []
      }
    }

    let attendee = await AuthController.getUser({user_id: this.state.attendee.user_id});

    let public_messaging = messaging.public;
    let enabled_users = messaging.enabled_users;
    let dining_users = messaging.dining_users;

    this.setState({
      attendee,
      messaging,
      public_messaging,
      enabled_users,
      dining_users,
      user,
      to_user_id,
      my_user_id: user_id,
      messages,
      mounted: true
    }, ()=>{
      this.refreshEvery5();
    })
  }

  componentWillUnmount() {
    if(this.intervalId) {
      clearInterval(this.intervalId);
    }

    if(this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
  }

  async refreshEvery5() {
    console.log('refreshing to another 10', new Date())
    let { to_user_id, my_user_id } = this.state;

    if(this.intervalId) {
      clearInterval(this.intervalId);
    }

    if(this.timeoutId) {
      clearTimeout(this.timeoutId)
    }

    const loadData = async () => {
      let messages = await UserController.getChatMessages({to_user_id, user_id: my_user_id});
      await UserController.markMessagesSeen({ from_user_id: to_user_id })
      let attendee = await AuthController.getUser({user_id: this.state.attendee.user_id});
      this.setState({messages, message_refresh: false, mounted: true, attendee})
    }

    const runRefresh = async () => {
      if(this.state.message_refresh) {
        this.setState({ mounted: this.state.message_refresh }, async ()=>{
          await loadData();
          this.setState({messages, message_refresh: false, mounted: true, attendee})
        })
      } else {
        await loadData();
      }
    }

    const codeToRunEvery5Seconds = async () => {
      console.log('Code running every 5 seconds...');
      await runRefresh()
    }

    const anotherFunctionAfter10Minutes = () => {
      console.log('Removing refresh after 10', new Date());
      this.setState({
        message_refresh: true
      })
    }

    this.intervalId = setInterval(codeToRunEvery5Seconds, 5000); // 20,000 milliseconds = 20 seconds

    // Set a timer to run another function after 10 minutes (600,000 milliseconds = 10 minutes)
    this.timeoutId = setTimeout(() => {
      clearInterval(this.intervalId); // Stop the code from running every 20 seconds
      anotherFunctionAfter10Minutes(); // Run another function
    }, 600000); // 600,000 milliseconds = 10 minutes

    await runRefresh();
  }

  async sendMessage() {
    if(this.state.message.trim() !== '') {
      this.setState({message_sending: true}, async () => {
        let { message, attendee } = this.state;
        console.log('attendee', attendee);
        let to_user_id = attendee.user_id;

        let user = await AuthController.getUser({user_id: to_user_id});

        console.log('user', user)

        let { show_settings, i_enabled_messaging_to_user, enable_chat, to_user_messaging_enabled } = this.enableChat(user);

        if(enable_chat) {
          let messages = await UserController.sendChatMessage({to_user_id, message})

          this.setState({
            messages,
            message: null,
            message_sending: false,
          }, ()=>{
            this.refreshEvery5()
          });
        } else {
          this.setState({
            attendee: user,
            message_sending: false
          }, ()=>{
            this.refreshEvery5()
          });
        }
      });
    }
  }

  onClose() {
    if(this.intervalId) {
      clearInterval(this.intervalId);
    }

    if(this.timeoutId) {
      clearTimeout(this.timeoutId)
    }

    this.props.onPress();
  }

  enableChat(attendee) {
    let { hosts, event, enabled_users, my_user_id, public_messaging, dining_users } = this.state;

    host_id = event ? event.event_data.host : null;
    let i_am_host = host_id === my_user_id || hosts.indexOf(my_user_id) !== -1;

    let  i_allowed_messaging, they_allowed_messaging, attendee_user_id, i_enabled_dining, host_id;

    attendee_user_id = attendee.user_id;

    i_allowed_messaging = public_messaging || enabled_users.indexOf(attendee_user_id) !== -1;

    i_enabled_dining = dining_users.indexOf(attendee_user_id) !== -1;

    if(attendee.messaging) {
      let attendee_messaging = attendee.messaging ? attendee.messaging : {public: false, enabled_users: []}

      let keys = Object.keys(attendee_messaging);

      if(keys.indexOf('public') === -1) {
        attendee_messaging = {
          ...attendee_messaging,
          public: false
        }
      }
  
      if(keys.indexOf('enabled_users') === -1) {
        attendee_messaging = {
          ...attendee_messaging,
          enabled_users: []
        }
      }

      they_allowed_messaging = attendee_messaging.public || attendee_messaging.enabled_users.indexOf(my_user_id) !== -1;
    }

    let enable_chat = i_allowed_messaging && they_allowed_messaging || my_user_id === 'cc467f19-6954-4151-ba3c-c45c8563511c' || i_am_host;

    let to_user_is_host = attendee_user_id === host_id || attendee_user_id === 'cc467f19-6954-4151-ba3c-c45c8563511c';

    let to_user_is_any_host = hosts.indexOf(attendee_user_id) !== -1;

    enable_chat = enable_chat || to_user_is_host || to_user_is_any_host;

    let show_settings = !to_user_is_host && !i_am_host && my_user_id !== 'cc467f19-6954-4151-ba3c-c45c8563511c' && !to_user_is_any_host;

    let to_user_messaging_enabled = they_allowed_messaging || to_user_is_host;

    let i_enabled_messaging_to_user = i_allowed_messaging || to_user_is_host;


    return { show_settings, i_enabled_messaging_to_user, enable_chat, to_user_messaging_enabled, i_enabled_dining }
  }

  render() {

    let { attendee, messages, mounted, message, message_sending, my_user_id, user, collapsed, message_refresh, hosts, public_messaging } = this.state;

    let photo_url, name, job, user_hobbies, bio, hobbies, profile, draw_user_hobbies, attendee_is_host, i_enabled_dining;

    console.log('user', user)

    let my_photo_url;
    let show_settings, i_enabled_messaging_to_user, enable_chat, to_user_messaging_enabled, user_id;

    if(mounted) {
      ({ show_settings, i_enabled_messaging_to_user, enable_chat, to_user_messaging_enabled, i_enabled_dining } = this.enableChat(attendee))

      my_photo_url = user.profile.photo_url;

      if(attendee) {
        profile = attendee.profile;
        user_id = attendee.user_id;

        if(typeof profile === 'string') {
          profile = JSON.parse(profile);
        }

        ({photo_url, name, job, user_hobbies, bio, hobbies} = profile);

        user_hobbies = user_hobbies ? user_hobbies : (hobbies ? hobbies : '')
        user_hobbies = user_hobbies.replace(/\//g, ',')

        if(typeof profile === 'string') {
          profile = JSON.parse(profile);
        }

        ({photo_url, name} = profile);

        if(attendee.name) {
          name = attendee.name;
        }

        attendee_is_host = hosts.indexOf(attendee.user_id) !== -1
      }

      draw_user_hobbies = user_hobbies.split(',').map(hobby=>{
        if(hobby.trim() !== '') {
          return <View><Text style={style.input_text_hobbie}>{hobby}</Text></View>
        } else {
          return null
        }
      })
    }

    const toggleMessaging = async () => {
      let { attendee, enabled_users, public_messaging } = this.state;
      let user_id = attendee.user_id;
      let messaging_index = enabled_users.indexOf(user_id)

      if( messaging_index === -1) {
        enabled_users.push(user_id)
      } else {
        enabled_users = enabled_users.filter(a=>a !== user_id)
      }

      let data = {
        is_public: public_messaging,
        enabled_users
      }
      console.log('userdata', data)
      await UserController.updateMessaging(data);
      this.setState({
        enabled_users
      })
    }

    const dineToggle = async () => {
      let { attendee, dining_users, enabled_users, public_messaging } = this.state;
      let user_id = attendee.user_id;
      let messaging_index = dining_users.indexOf(user_id)

      console.log('dining_users' ,dining_users)

      if(messaging_index === -1) {
        dining_users.push(user_id)
      } else {
        dining_users.splice(messaging_index, 1)
      }

      let data = {
        is_public: public_messaging,
        enabled_users,
        dining_users
      }
      console.log('userdata', data)
      await UserController.updateMessaging(data);
      this.setState({
        dining_users
      })
    }

    let is_mobile = Dimensions.get('window').width < 500;
    
    return <>
            <View style={{flex: 1, width: '100%'}}>

              {mounted ?
              <> 
              {collapsed ?     
              <>          
              <View style={[style.fat_top, {justifyContent: show_settings ? 'space-between' : 'unset'}]}>
                  <View style={style.profile_info_wrapper}>
                    <View style={style.profile_wrapper}>
                      <Image style={style.profile_image} resizeMode={'cover'} source={{uri: photo_url ? photo_url : 'https://diningsocial-assets.s3.amazonaws.com/empty_profile.png'}} />
                      <View style={{}}>
                        <TouchableOpacity onPress={()=>{this.setState({collapsed: !this.state.collapsed})}} ><Text style={style.view_profile_text}>view profile</Text></TouchableOpacity>
                      </View>
                    </View>
                    <View style={style.name_wrapper}>
                      <Text style={style.profile_name}>{name}</Text>
                      {attendee_is_host ?<Text style={style.host_title}>Host</Text>: null}
                    </View>
                  </View>
                  {show_settings ? 
                  <View style={style.checks_wrapper}>
                    <View style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                      <View>
                        <Input type={'check'} checked={to_user_messaging_enabled} label={<Text style={style.check_text}>Allowed messaging with you</Text>}/>
                      </View>
                      {public_messaging ? null :
                      <View>
                        <Input type={'check'} checked={i_enabled_messaging_to_user} label={<Text style={style.check_text}>Let member message you</Text>} onCheck={toggleMessaging} />
                      </View>}
                      <View>
                        <Input check_style={{ hilight_color: {backgroundColor: '#e8d519', borderColor: '#e8d519'}, check_text: style.unique_check_text_style}} onCheck={dineToggle} type={'check'} checked={i_enabled_dining} label={<Text style={style.check_text}>I'd like to dine with this member again</Text>} />
                      </View>
                    </View>
                  </View> : null}
                </View>

                {enable_chat ?
                <>
                {message_refresh ? <TouchableOpacity onPress={this.refreshEvery5}><Text style={style.message_refresh}>load latest messages</Text></TouchableOpacity> : null}
                <ScrollView style={[style.chat_messages_wrapper, {maxHeight: show_settings || !is_mobile ? '27vh' : '315px'}]}>
                  <View style={style.messages_wrapper}>
                    {messages.length ? 
                    messages.map(message=>{
                      let milliseconds = message.message_date * 1000;
                      let date = new Date(milliseconds);

                      let message_photo_url = my_user_id === message.from_user_id ? my_photo_url : photo_url;

                      date = date.toLocaleString('en-US', {
                        timeZone: 'America/New_York',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                      });

                      return <View>
                        <View style={{flexDirection: 'row'}}>
                          <View style={{marginRight: 20}}>
                            <Image style={style.small_profile_image} resizeMode={'cover'} source={{uri: message_photo_url ? message_photo_url : 'https://diningsocial-assets.s3.amazonaws.com/empty_profile.png'}} />
                          </View>
                          <View style={{flexDirection: 'column', flex: 1}}>
                            <Text style={style.date_text}>{date}</Text>
                            <Text style={style.message_text}>{message.message}</Text>
                          </View>
                        </View>
                        
                      </View>
                    }) : <View>
                        <Text style={style.date_text}>No messages yet.</Text>
                      </View>}
                  </View>
                </ScrollView>
                <View style={{marginTop: 10}}>

                  <View>
                    <Input placeholder={'Enter a message'} style={style.text_input} value={message} type={'text'} onChangeText={message=>{this.setState({message})}} multiline={true} />
                    <Button style={{backgroundColor: '#e32652', width: '200px', fontFamily: 'Ysabeau', borderRadius: 10, marginTop: 20, alignSelf: 'center', textAlign: 'center'}} loading={message_sending} onPress={this.sendMessage} title={'Send'} />
                  </View>
                </View>
                </> : <>
                  <View>
                    <Text style={style.enable_text}>When both of you enable messaging a chat window will appear here.</Text>
                  </View>
                </>}
                </>
                : <View style={{flex: 1, width: '100%'}}>
                    <View>
                      <View style={style.fat_top}>
                        <View style={style.profile_info_wrapper}>
                          <View style={style.profile_wrapper}>
                            <Image style={style.profile_image} resizeMode={'cover'} source={{uri: photo_url ? photo_url : 'https://diningsocial-assets.s3.amazonaws.com/empty_profile.png'}} />
                            <View style={{}}>
                              <TouchableOpacity onPress={()=>{this.setState({collapsed: !this.state.collapsed})}} ><Text style={style.view_profile_text}>close profile</Text></TouchableOpacity>
                            </View>
                          </View>
                          <View style={style.name_wrapper}>
                            {name ?
                            <View style={style.attendee_name}><Text style={style.attendee_name}>{name}</Text></View> : null}
                            {job ?
                            <View><Text style={style.attendee_info}>Job: {job}</Text></View> : null}
                          </View>
                        </View>
                      </View>
                      <View style={{paddingBottom: 20}}>
                        {bio ?
                            <View><Text style={style.attendee_info}>Bio: {bio}</Text></View> : null}
                        <View><Text style={style.attendee_info}><Text style={style.attendee_info_title}>Hobbies:</Text> {draw_user_hobbies}</Text></View>
                      </View>
                      <View style={{alignSelf: 'flex-end', justifyContent: 'flex-end'}}>
                            <TouchableOpacity onPress={()=>{this.setState({collapsed: !this.state.collapsed})}} ><Text style={style.view_profile_text}>close profile</Text></TouchableOpacity>
                          </View>
                    </View></View>}
              </> : <View style={{width: 60, height: 60, alignSelf: 'center', paddingVertical: 100}}>
                  <Progress.CircleSnail size={60} backgroundColor="white" fill='white' color={'#e32652' } />
                </View>}
            </View>
      </>
  }

}

function Messaging(props) {
  if(Platform.OS === 'web') {
    let navigate = useNavigate();
    return  <Messaging_Base {...props} navigate={navigate} />
  } else {
    return  <Messaging_Base {...props} navigate={props.navigation.push}  />
  }
}

export default Messaging;
