import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { View, Platform, Dimensions, TouchableOpacity, Image } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { Text } from '../../components';
import UserController from '../../controllers/userController';
import { config } from '../../../config';
import { style } from './style';
import ViewMoreText from 'react-native-view-more-text';
import Utilities from '../../utilities';
import AuthController from '../../controllers/authController';
import Profile from '../Profile';
import { Modal } from '../../containers';
import CircularProgress from 'react-native-circular-progress-indicator';

class Points_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {

  }

  CircleWithPartialBorder({ fillPercentage, borderColor }) {
    const circleSize = 150;
    const borderWidth = 5;

    const borderCoverPercentage = (100 - fillPercentage) * 3.6; // Convert fill percentage to degrees

    const arcStartDegree = 45;
    const arcEndDegree = (45 + fillPercentage) * 3.6;

    return (
      <View style={style.wrapper}>
        <View style={[style.arc, style.arc_start, {transform: [{rotate: `${arcStartDegree}deg`}]}]} />
        <View style={[style.arc, style.arc_end, {transform: [{rotate: `${arcEndDegree}deg`}]}]} />
      </View>
    );
  };

  goTo(link) {
    window.open(link);
  }

  render() {

    let points = this.props.points;

    let latest = points.filter(point=>{return !points.redeemed});
    if(latest.length) {
      latest = latest[0];
    } else {
      latest = { points: "0" }
    }

    return <View style={style.points_wrapper}>
      <View style={style.points_left}>
        <View style={style.point_circle}><Text style={style.points_count}>{latest.points} Total</Text></View>
      </View>
      <View style={style.points_right}>
        <Text style={style.points_title}>Your Points</Text>
        {/* <Text>3 point till non-hosted dinner</Text>
        <Text>6 point till hosted dinner</Text>
        <Text>26 point till free one month membership</Text> */}
        <TouchableOpacity onPress={()=>{this.goTo('/points')}}>
          <Text style={style.points_link}>Read More</Text>
        </TouchableOpacity>
      </View>
    </View>
  }

}

function Points(props) {
  if(Platform.OS === 'web') {
    let navigate = useNavigate();
    return  <Points_Base {...props} navigate={navigate} />
  } else {
    return  <Points_Base {...props} navigate={props.navigation.push}  />
  }
}

export default Points;
