import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { View, Platform, TouchableOpacity, ScrollView, Image, Picker, Dimensions } from 'react-native';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Input, Text } from '../../components';
import authController from '../../controllers/authController';
import UserController from '../../controllers/userController';
import EventController from '../../controllers/eventController';
import PaymentController from '../../controllers/paymentController';
import Utilities from '../../utilities';
import { EditProfile, Profile } from '../../containers';
import LoginRegisterScreen from '../LoginRegister';
import RegisterScreen from '../Register';
import { style } from './style';
import { global_style } from '../../../global_style'
import { Analytics, PageHit } from 'expo-analytics';
import { config } from '../../../config';
import AuthController from '../../controllers/authController';
import Icon from 'react-native-vector-icons/FontAwesome';
import { Video } from 'expo-av';
const analytics = new Analytics('G-YCC5QPC8BJ');

class SpecialRSVP_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password: null,
      tab: 0,
      terms: true,
      date: props.date,
      num_rsvp: 1,
      event_id: props.event_id,
      type: props.type,
      confirm_inactive: true
    }

    this.scrollViewRef = React.createRef();
    this.hostedRef = React.createRef();
    this.nonHostedRef = React.createRef();
    this.logisticsRef = React.createRef();
    this.attendeesRef = React.createRef();
    this.appsRef = React.createRef();

    this.rsvpChange = this.rsvpChange.bind(this);
    this.rsvpClick = this.rsvpClick.bind(this);
    this.updateInput = this.updateInput.bind(this);
    this.addCalendar = this.addCalendar.bind(this);
    this.scrollTo = this.scrollTo.bind(this);
    this.updateValue = Utilities.updateValue.bind(this);

    this.confirmMakeHost = this.confirmMakeHost.bind(this);
    this.confirmResignHost = this.confirmResignHost.bind(this);
    this.confirmCancel = this.confirmCancel.bind(this);

    this.drawAttendees = this.drawAttendees.bind(this);
    this.drawAttendee = this.drawAttendee.bind(this);
    this.openProfileBubble = this.openProfileBubble.bind(this);
    this.openInParent = this.openInParent.bind(this);
    this.fillOutProfile = this.fillOutProfile.bind(this);

    this.toggleConfirmRSVP = this.toggleConfirmRSVP.bind(this);
    this.toggleMakeHost = this.toggleMakeHost.bind(this);
    this.toggleResignHost = this.toggleResignHost.bind(this);
    this.toggleCancelRSVP = this.toggleCancelRSVP.bind(this);
    this.togglePreRSVP = this.togglePreRSVP.bind(this);

    this.toggleChooseAsMember = this.toggleChooseAsMember.bind(this);

    this.getCanCancel = this.getCanCancel.bind(this);

    this.drawMessage = this.drawMessage.bind(this);
    this.sendMessage = this.sendMessage.bind(this);

    this.refreshEvery20 = this.refreshEvery20.bind(this);
    this.onRegisterClick = this.onRegisterClick.bind(this);
    this.onLoginClick = this.onLoginClick.bind(this);

    this.updateUserLogging = this.updateUserLogging.bind(this);
    this.getEventStatus = this.getEventStatus.bind(this);

    this.openRSVP = this.openRSVP.bind(this);
    this.openPreConfirm = this.openPreConfirm.bind(this);
    this.chooseAfterLogin = this.chooseAfterLogin.bind(this);

    this.getSubscription = this.getSubscription.bind(this);
    this.drawTheButton = this.drawTheButton.bind(this);

    this.subscriptionStart = this.subscriptionStart.bind(this);
    this.toggle = this.toggle.bind(this);
    this.hasAnotherEventWithinHour = this.hasAnotherEventWithinHour.bind(this);

    this.afterInfo = this.afterInfo.bind(this);
  }

  async componentDidMount() {
    analytics.hit(new PageHit('RSVP'))
      .then(() => {})
      .catch(e => console.log("analytics error", e.message));

    let { type, event_id } = this.state;

    let org_user = await UserController.getUser();
    console.log('org_user', org_user)

    let user = org_user && org_user.user ? org_user.user : null;
    let user_id = user && user.user_id ? user.user_id : null;

    let token = await localStorage.getItem('token');

    let isLoggedIn = !!token;

    let internal = await AuthController.getInternal();

    let messages = await EventController.getMessages({event_id});


    let rsvps = [];
    let tentatives = [];

    let event, place, status, host, invited, rsvped, invite_only, date
    let event_data;
    
      ({rsvps, tentatives} = await UserController.getDateRsvpsForEvent({event_id}));
      event_data = await AuthController.getEventAndPlace({event_id});

      rsvped = rsvps.filter(rsvp=>rsvp.user_id === user_id);
      rsvped = rsvped && rsvped.length ? true : false;

      status = event_data.status;
      event = event_data.event_data;

      ({invited, invite_only, date, host} = event);

      if(invite_only) {
        window.location.replace(`/invite/${event_id}`)
      }

      if(event.hosted && event.hosted === 'false') {
        event.hosted = false;
      } 

      console.log('evetsdt', host)
      if(host) {
        let users = await AuthController.getUsers({user_ids: [host]})
        console.log('users', users)
        console.log('users[0]', users[0])
        let profile = JSON.parse(users[0].profile);
        console.log('profile', profile)
        host = users[0];
        console.log('host', host)
        host = {
          ...host,
          profile
        }
        console.log('host', host)
      }

    let cancel_url = `special/${event_id}`;
    let slug = cancel_url;

    date = date.replace(/\./g, "/");
    let day = (new Date(date)).getDay();

    var currentDate = new Date();
    let current_date = `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()}`;
    var currentHour = currentDate.getHours();
      
    place = event_data.place_data;


    let dinners = await UserController.getDinners();

    dinners = dinners && dinners.dinners ? dinners.dinners : [];
    let has_any_dinners = dinners.filter(din=>{
      return !din.event_data.free && !din.event_data.happy_hour
    });
    console.log('has_any_dinners2', has_any_dinners)
    has_any_dinners = !!has_any_dinners.length;
    
    console.log('has_any_dinners', has_any_dinners);
    dinners = dinners.filter(a=>{return a.dinner_date === date});
    let time = event.time;

    let has_event_within_hour = this.hasAnotherEventWithinHour(dinners, time);

    let has_event = dinners.filter(dinner => {return dinner.event_id === event_id})

    let day_name = Utilities.getDayName(day);

    const now = new Date();
    const hours = now.getHours();
    let discussion_open = true;

    let test_twelve = new Date();

    this.getSubscription(tentatives, event, org_user);
    
    let test_thirteen = new Date();
    console.log('eight', this.getMinuteDifference(test_twelve, test_thirteen));

    this.setState({
      user_id,
      time,
      has_any_dinners,
      subscription_loading: !!org_user,
      status,
      isLoggedIn,
      discussion_open,
      has_event_within_hour,
      rsvps,
      tentatives,
      host,
      event, place,
      internal,
      slug,
      day_name,
      rsvped,
      cancel_url,
      date,
      day,
      mounted: true,
      messages
    }, async ()=>{
      this.refreshEvery20();
      let people_i_met = await UserController.getPeopleIMet({page: 0});

      this.setState({
        people_i_met
      })
    })
  }

  hasAnotherEventWithinHour(dinners, time) {
    // Function to convert time representation (e.g., 13.5) to Date object
    function convertTimeToDate(hourDecimal) {
      const hours = Math.floor(hourDecimal);
      const minutes = Math.round((hourDecimal % 1) * 60);
      const date = new Date();
      date.setHours(hours, minutes, 0, 0);
      return date;
    }

    // Function to check if two times are more than an hour apart
    function areTimesMoreThanOneHourApart(time1, time2) {
      const date1 = convertTimeToDate(time1);
      const date2 = convertTimeToDate(time2);

      // Calculate time difference in milliseconds
      const timeDifference = Math.abs(date1 - date2);

      // Check if the time difference is greater than one hour (3600 seconds * 1000 milliseconds)
      const isMoreThanOneHourApart = timeDifference > 3600 * 1000;

      return isMoreThanOneHourApart;
    }

    let has_conflict = false;
    let time2, result;
    for(var i = 0; i < dinners.length; i++) {
      time2 = dinners[i].time;
      result = areTimesMoreThanOneHourApart(time, time2);

      if(result) {
        return true;
      }
    }
    
    return false;
  }

  toggle(name, callback) {
    console.log(name, this.state[name])
    this.setState({
      re_render: true,
      [name]: !this.state[name]
    }, () => {
      if(callback) {
        callback();
      }
    })
  }

  subscriptionStart(num) {
    let { isLoggedIn } = this.state;
    if(!isLoggedIn) {
      this.onLoginClick();
    } else {
      let { cancel_url } = this.state;
      let starting = num === 1 ? 'yearly_loading' : 'monthly_loading';

      this.setState({[starting]: true}, async () => {
        let response = await PaymentController.createCheckoutSession({num, cancel_url});

        if(response && response.success) {
          window.location.replace(response.url);
        }
      });
    }
  }

  getMinuteDifference(date1, date2) {
    const diffInMilliseconds = date2 - date1;
    const minutes = Math.floor(diffInMilliseconds / (100));
    return minutes;
  }

  async getSubscription(tentatives, event, user) {
    console.log('useruser', user)
    if(user) {
      let profile = user && user.profile ? user.profile : {};
      user = user.user;
      let user_id = user && user.user_id ? user.user_id : null;

      let { event_id } = event;
      let { subscription, is_member } = await UserController.getUserSubscriptionLive(user);

      console.log('subscriptionone', subscription, is_member, user)

      let is_host = user && user.is_host ? true : false;
      
      let host_account = user ? user.host_account : null;

      let selected_people, selected_people_uninvites, selected_people_invites;
      let user_is_uninvited = false;

      if(event && !event.free) {
        selected_people = event.uninvited;

        selected_people = selected_people ? selected_people[user_id] : null;

        if(!selected_people) {
          let uninvited = event.uninvited ? event.uninvited : {};

          uninvited =  [].concat(...Object.values(uninvited));

          console.log('uninvited3', uninvited)

          user_is_uninvited = uninvited.indexOf(user_id) !== -1;

          if(user_is_uninvited) {
            window.location.replace('/')
          }
        } else {
          selected_people_uninvites = await UserController.getUsers({user_ids: selected_people})
        }

        selected_people = event.invited;
        console.log('selected_people', selected_people);
        console.log('user_id', user_id);
        selected_people = selected_people ? selected_people[user_id] : null
        console.log('selected_people', selected_people);

        if(selected_people) {
          selected_people_invites = await UserController.getUsers({user_ids: selected_people});
        }
        console.log('selected_people_invites', selected_people_invites)
      }

      is_member = is_member || !!host_account;
      let pre_rsvped = tentatives && tentatives.length ? tentatives.filter(a=>{return a.user_id === user_id}).length : false;

      console.log('pre_rsvped', pre_rsvped, tentatives, event_id, user_id)
      console.log('is_member2', is_member)

      this.setState({
        selected_people_invites,
        selected_people_uninvites,
        subscription_loading: false,
        is_member,
        pre_rsvped,
        subscription,
        is_host,
        profile,
        selected_people,
        user,
        user_is_uninvited
      })
    } else {
      this.setState({
        subscription_loading: false
      })
    }
  }

  getEventStatus(date) {
    // check if event is more than 7 days away to mark it as status: pre
    let targetDate = new Date(date); // Replace with your specific target date.
    // Calculate the date 7 days from now.
    let sevenDaysFromNow = new Date();
    sevenDaysFromNow.setDate(sevenDaysFromNow.getDate() + 7);

    // Adjust for the time zone (EST).
    sevenDaysFromNow = new Date(sevenDaysFromNow.toLocaleString('en-US', { timeZone: 'America/New_York' }));

    // Check if the target date is exactly 7 days away from the adjusted date.
    let isSevenDaysAway = targetDate.getTime() < sevenDaysFromNow.getTime();

    let status = isSevenDaysAway ? 'public' : 'pre';

    return status;
  }

  async updateUserLogging() {
    window.location.reload();
    /* let { event_id, date, time } = this.state
    let token = await localStorage.getItem('token');

    this.props.onLogin();

    date = date.replace(/\./g, "/");

    let isLoggedIn = !!token;

    console.log('subscription', subscription);
    let org_user = await UserController.getUser();
    let profile = org_user.profile ? org_user.profile : {};
    let user = org_user.user;
    let is_host = org_user.user ? org_user.user.host : false;
    let user_id = org_user.user ? org_user.user.user_id : null;
    let host_account = user ? user.host_account : null;
    subscription = subscription.subscription;

    let is_member = subscription && subscription.status ? subscription.status === 'trialing' || subscription.status === 'active' : false;

    is_member = is_member || !!host_account;

    let rsvps, tentatives;
    ({rsvps, tentatives} = await UserController.getDateRsvpsForEvent({event_id}));
    let pre_rsvped = tentatives.length && event_id && tentatives.filter(a=>{return a.user_id === user_id}).length;
    pre_rsvped = !!pre_rsvped;

    let dinners = await UserController.getDinners();
    dinners = dinners && dinners.dinners ? dinners.dinners : [];

    
    let has_any_dinners = dinners.length > 0;

    dinners = dinners.filter(a=>{return a.dinner_date === date})

    let has_event_within_hour = this.hasAnotherEventWithinHour(dinners, time);

    let has_event = dinners.filter(dinner => {return dinner.event_id === event_id})
    let rsvped = !!dinners.length && event_id && has_event && has_event.length && has_event[0].event_id === event_id;

    console.log('rsvped', rsvped);

    this.setState({
      pre_rsvped,
      has_any_dinners,
      rsvped,
      is_member,
      user_id,
      is_host,
      has_event_within_hour,
      isLoggedIn,
      profile
    }, () => {
      if(afterRegisterClick) {
        console.log('getting here2');
        afterRegisterClick();
      }
    }) */
  }

  openInParent(content, state_variable, width, height, backgroundColor, no_scroll) {
    console.log('drawing in parent again', state_variable, this.state[state_variable]);

    const clickScreen = null;
    //() =>this.toggleState(state_variable)

    let window_width = Dimensions.get('window').width;
    let is_mobile = window_width <= 700;

    let inner_style = {display: 'block', width: is_mobile ? '100vw' : width, height, left: is_mobile ? 0 : `calc(50% - ${width / 2}px)`}

    this.props.putInParent(!this.state[state_variable] ? 
      <TouchableOpacity activeOpacity={1} style={{zIndex: 10000000, position: 'fixed', width: '100vw', height: '100vh'}} onPress={clickScreen}>
      <>
        <TouchableOpacity activeOpacity={1} onPress={()=>this.toggleState(state_variable)} style={global_style.modal_overlay}></TouchableOpacity>
        <View style={style.payment_content}>
        <View style={[global_style.modal_content, inner_style]}>
            <TouchableOpacity onPress={()=>this.toggleState(state_variable)}><Text style={global_style.modal_close_x}>x</Text></TouchableOpacity>
            {no_scroll ?
            <View style={{height: height - 80, backgroundColor}}>
              {content}
            </View>
            :
            <ScrollView style={{height: height - 80, backgroundColor}}>
              {content}
          </ScrollView>}
        </View>
        </View>
      </>

      </TouchableOpacity> : null);

    this.setState({
      [state_variable]: !this.state[state_variable]
    });
  }

  openProfileBubble(profile_attendee) {

    this.props.putInParent(!this.state.is_profile_open ? 
      <TouchableOpacity activeOpacity={1} style={{zIndex: 10000000, position: 'fixed', width: '100vw', height: '100vh'}} onPress={this.openProfileBubble}>
        <Profile onPress={this.openProfileBubble} attendee={profile_attendee} />      
      </TouchableOpacity> : null);

    this.setState({
      profile_attendee,
      is_profile_open: !this.state.is_profile_open
    });
  }

  drawAttendee(attendee) {
    let photo_url, name;

    console.log('attendee', attendee)

    if(attendee) {
      let profile = attendee.profile;

      if(typeof profile === 'string') {
        profile = JSON.parse(profile);
      }

      ({photo_url, name} = profile);

      if(attendee.name) {
        name = attendee.name;
      }
    }

    return <TouchableOpacity onPress={()=>this.openProfileBubble(attendee)} style={[style.attendee_bubble]}>
      <Image style={style.attendee_photo} source={{uri: photo_url ? photo_url : 'https://diningsocial-assets.s3.amazonaws.com/empty_profile.png'}} />
        {/* <Text style={style.attendee_name_text}>{name ? name[0].toUpperCase() : "M"}</Text> */}
    </TouchableOpacity>
  }

  drawAttendees() {
    let { rsvps, tentatives, event, rsvped, user_id, status, host } = this.state;
    status = 'public';
    let attendees = rsvps;
    console.log('rsvps', rsvps);
    console.log('tentatives', tentatives);

    console.log('host', host);
    console.log('host', event);

    let { free, special } = event;

    if(host) {
      attendees = attendees.filter(attend=>{return attend.user_id !== host.user_id})
    }

    let attendee_user_ids = rsvps.map(a=>{return a.user_id});

    tentatives = tentatives.filter((tent) => !attendee_user_ids.includes(tent.user_id));

    let { hosted, is_full, demographic, canceled, rsvp_limit } = event;

    if(!is_full && rsvp_limit) {
      if(typeof rsvp_limit === 'string') { rsvp_limit = parseInt(rsvp_limit)}
      is_full = rsvp_limit === attendees.length;
    }

    let created = !!event.demographic;

    let description = hosted ? `There's no risk in being the first to RSVP. Dinners don't happen if at least 3 people don't sign up.` : (demographic ? `The first person to RSVP chose the theme to be "${demographic}". If you're in that demographic, join them for dinner!` :`First person to RSVP chooses the restaurant and the demographic of the group, so don't be shy!`)

    return !canceled ? <View style={style.info_bubble_popup}>
      {status === 'public' || free ? <>
        <Text style={[global_style.panel_sub_title, global_style.padding_subtitle]}>Attendees</Text>
        {hosted ? <>
          {!created ? <Text style={style.dont_shy}>{description}</Text> : null}
          <Text style={style.tentative_title}>Host</Text>
          {host ? this.drawAttendee(host) : "Host is needed"}
          <View style={[global_style.line_breaker]}></View>
          <Text style={style.tentative_title}>RSVPs</Text>
          <View style={[style.one_bucket, {zIndex: 100}]}>
            <View style={style.first}>
              {attendees.map((attendee, i)=>{return this.drawAttendee(attendee)})}
            </View>
          </View>
          
        </> : <>
            {!created ? <Text style={style.dont_shy}>{description}</Text> : null}
            <View style={[style.one_bucket, {zIndex: 99}]}>
            <View style={style.first}>
              {attendees.map((attendee, i)=>{return this.drawAttendee(attendee)})}
            </View>
            </View>
        
        </>}
       </>  : <>
       {hosted ? <>
        <Text style={style.tentative_title}>Host</Text>
          {host ? this.drawAttendee(host) : "Host is needed"}
          <View style={[global_style.line_breaker]}></View> </> : null}

          {special && status === 'pre' ? null :
          <>
          <Text style={style.tentative_title}>Member RSVPs</Text>
          <View style={[style.one_bucket, {zIndex: 100}]}>
            <View style={style.first}>
              {attendees.map((attendee, i)=>{return this.drawAttendee(attendee)})}
            </View>
          </View>
          </>
          }

       {/* <Text style={[global_style.panel_sub_title, global_style.padding_subtitle]}>Pre-RSVPs</Text>
        <View style={[style.one_bucket, {zIndex: 99}]}>
            <View style={style.first}>
              {tentatives.map((attendee, i)=>{return this.drawAttendee(attendee)})}
            </View>
            
          </View>
          <Text style={[global_style.notification_text, {color: 'black'}]}>* RSVPs will open to non-members 7 days prior to the event</Text> */}
       </>}
    </View> : null
  }

  scrollTo(the_ref) {

    if(the_ref && the_ref.scrollIntoView) {
      the_ref.scrollIntoView();
    }
  }

  rsvpChange(num_rsvp, itemIndex) {
    this.setState({
      num_rsvp,
    })
  }

  checkProfile() {
    let { profile } = this.state;

    return !!profile.photo_url && !!profile.name && !!profile.job;
  }

  async confirmMakeHost() {
    let { event_id } = this.state;

    await UserController.confirmMakeHost({event_id});

    window.location.reload();
  }

  async confirmResignHost() {
    let { event_id } = this.state;

    await UserController.confirmResignHost({event_id});

    window.location.reload();
  }

  async confirmCancel() {
    let { event_id } = this.state;

    this.setState({cancel_loading: true}, async ()=>{
      await UserController.confirmCancel({event_id});

      window.location.reload();
    });
  }

  drawMessage(message) {
    let { profile, message_date } = message;

    console.log('message', message);
    console.log('profile', profile);

    let date = new Date(parseInt(message_date));

    let options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    let formatter = new Intl.DateTimeFormat('en-US', options);
    
    let date_time = formatter.format(date);

    return <View style={style.one_message}>
      {this.drawAttendee({profile, name: profile.name})}
      <View style={[global_style.column, style.chat_data]}>
        <Text style={style.chat_time}>{date_time}</Text>
        <Text style={style.chat_text}>{message.message}</Text>
      </View>
    </View>
  }

  async refreshEvery20() {
    let { event_id } = this.state;

    if(this.intervalId) {
      clearInterval(this.intervalId);
    }

    if(this.timeoutId) {
      clearTimeout(this.timeoutId)
    }

    const codeToRunEvery20Seconds = async () => {
      console.log('Code running every 20 seconds...');
      let messages = await EventController.getMessages({event_id});

      this.setState({
        messages
      })
    }

    const anotherFunctionAfter10Minutes = () => {
      console.log('Another function after 10 minutes...');
      this.setState({
        message_refresh: true
      })
    }

    this.intervalId = setInterval(codeToRunEvery20Seconds, 20000); // 20,000 milliseconds = 20 seconds

    // Set a timer to run another function after 10 minutes (600,000 milliseconds = 10 minutes)
    this.timeoutId = setTimeout(() => {
      clearInterval(this.intervalId); // Stop the code from running every 20 seconds
      anotherFunctionAfter10Minutes(); // Run another function
    }, 600000); // 600,000 milliseconds = 10 minutes
  }

  async sendMessage() {
    let { text_message, event_id } = this.state;

    if(text_message && text_message.trim()) {
      this.setState({sending_message: true}, async () => {

        let created_at = (new Date()).getTime();

        let messages = await UserController.sendMessage({message: text_message, event_id, created_at });

        this.refreshEvery20();

        this.setState({
          text_message: null,
          messages,
          sending_message: false
        })
      });
    }
  }

  async rsvpClick() {
    let has_profile = this.checkProfile();

    if(has_profile) {
      this.setState({rsvp_loading: true}, async () => {
        let { is_member, date, chosen_dinner_time, cancel_url, event, event_id, type, chosen_hosted, chosen_demographic, chosen_restaurant, checked_host, user_id, status } = this.state;

        event = {
          ...event,
          event_id,
          immediate_email: true
        }

        let { stripe_price_member, stripe_price_member_test, stripe_price_non_member, stripe_price_non_member_test } = event;

        let price = is_member ? stripe_price_member : stripe_price_non_member;

        let response = await PaymentController.createEventSession({ cancel_url, event, price, immediate_email: true });
        
        console.log('response', response);
    
        if(response && response.success) {
          window.location.replace(response.url);
        }
      });
    } else {
      this.fillOutProfile();
    }
  }

  fillOutProfile() {
    let { profile } = this.state;
    this.props.putInParent(null);


    const afterProfileUpdate = (profile) => {
      this.props.putInParent(null);
      this.setState({profile, is_open: false}, ()=>{
        this.rsvpClick(); 
      });
    }

    let window_width = Dimensions.get('window').width;

    this.openInParent(<View style={global_style.center_content}>
      
        <Text style={{fontFamily: 'Ysabeau', fontSize: 20, textAlign: 'center', marginBottom: 60 }}>A photo, name and your job are required in order to attend our events.</Text>
        <View style={{marginTop: -40, textAlign: 'left'}}>
        <EditProfile openEdit={true} profile={profile} afterUpdate={afterProfileUpdate} />
        </View>
    </View>, "is_open", window_width <= 700 ? window_width : 900, 500, 'white')
  }

  goDirections(link) {
    window.open(link);
  }

  goTo(link) {
    window.open(link)
  }

  addCalendar(event, place) {
    let { day, date, slug } = this.state;
    let { time, type } = event;

    let day_date, month, year;
    let tokens = date.split('/');

    day_date = tokens[1];
    if(day_date.length === 1) {
        day_date = `0${day_date}`
    }

    month = tokens[0];
    if(month.length === 1) {
        month = `0${month}`
    }

    year = tokens[2];

    let hours = Math.floor(time);
    let minutes = Math.round((time - hours) * 60);

    if(minutes.toString().length === 1) {
      minutes = `0${minutes}`
    }

    let end_time = time + 1.5;

    console.log('end_time', end_time)

    let end_hours = Math.floor(end_time);
    let end_minutes = Math.round((end_time - end_hours) * 60);

    if(end_minutes.toString().length === 1) {
      end_minutes = `0${end_minutes}`
    }

    let dinner_begin_time = `${hours}${minutes}00`;
    let dinner_end_time = `${end_hours}${end_minutes}00`;

    let dates_link = `${year}${month}${day_date}T${dinner_begin_time}/${year}${month}${day_date}T${dinner_end_time}`;

    let {restaurant_name, address } = place;

    let dining_type = Utilities.getEventTypeName(type);
    let dining_type_capitalized = `${dining_type[0].toUpperCase()}${dining_type.substring(1, dining_type.length)}`

    let link = `http://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(`Dining & Social Club ${dining_type_capitalized} at ${restaurant_name}`)}&dates=${dates_link}&details=${encodeURIComponent(`Dining & Social Club ${dining_type_capitalized} at ${restaurant_name} - Meet new friends over ${dining_type} - https://www.diningsocialnj.com/${slug}`)}&location=${address}&trp=false`;

    window.open(link);
  }

  updateInput(value, name) {

    this.setState({
      [name]: value
    });
  }

  updateState(value, name) {

    let { chosen_demographic, chosen_restaurant, chosen_dinner_time, chosen_hosted } = this.state;

    if(chosen_demographic !== null && chosen_demographic !== undefined) {
      chosen_demographic = chosen_demographic.toString();
    }

    if(chosen_restaurant !== null && chosen_restaurant !== undefined) {
      chosen_restaurant = chosen_restaurant.toString();
    }

    value = value.toString();

    let confirm_inactive = true;

    if(name === 'chosen_restaurant') {
      confirm_inactive = !chosen_hosted || chosen_hosted === "-1" || !chosen_dinner_time || chosen_dinner_time === "-1" || !chosen_demographic || chosen_demographic === "-1" || !value || value === "-1"
    }

    if(name === 'chosen_demographic') {
      confirm_inactive = !chosen_hosted || chosen_hosted === "-1" || !chosen_dinner_time || chosen_dinner_time === "-1" || !chosen_restaurant || chosen_restaurant === "-1" || !value || value === "-1"
    }

    if(name === 'chosen_dinner_time') {
      confirm_inactive = !chosen_hosted || chosen_hosted === "-1" || !chosen_restaurant || chosen_restaurant === "-1" || !chosen_demographic || chosen_demographic === "-1"  || !value || value === "-1"
    }

    if(name === 'chosen_hosted') {
      confirm_inactive = !chosen_dinner_time || chosen_dinner_time === "-1" || !chosen_restaurant || chosen_restaurant === "-1" || !chosen_demographic || chosen_demographic === "-1"  || !value || value === "-1"
    }

    this.setState({
      confirm_inactive,
      [name]: value
    })
  }

  toggleState(name, callback) {
    console.log('toggling', name, this.state[name])

    if(this.state[name]) {
      this.props.putInParent(null)
    }

    let data = {
      is_rsvp_confirm_open: false,
      is_register_open: false,
      is_login_open: false,
      is_open: false, 
      is_make_host_open: false, 
      is_resign_host_open: false,
      is_cancel_open: false
    }

    if(name) {
      data = {
        ...data,
        [name]: !this.state[name]
      }
    } else {
      this.props.putInParent(null)
    }

    this.setState(data, () => {
      if(callback) {callback()}
    })
  }

  onRegisterClick() {
    this.toggleState('is_register_open');

    let onRegister = async () => {
      console.log('getting here');
      await this.updateUserLogging();
    }

    this.props.putInParent(null);
    this.openInParent(<View style={global_style.center_content}><Text style={{fontFamily: 'Ysabeau', fontSize: 20, textAlign: 'center', marginBottom: 30, marginTop: -22}}><RegisterScreen onRegister={onRegister} onLoginClick={this.onLoginClick} /></Text>
    </View>, "is_register_open", 500, 550, '#d2d4e9')
  }

  onLoginClick() {
    console.log('getting to login open')
    this.toggleState('is_login_open');

    let onLogin = async () => {
      console.log('getting here');
      await this.updateUserLogging();
    }

    this.openInParent(<View style={global_style.center_content}><Text style={{fontFamily: 'Ysabeau', fontSize: 20, textAlign: 'center', marginBottom: 30, marginTop: -40}}><LoginRegisterScreen cancel_url={this.state.cancel_url} putInParent={this.props.putInParent} onLogin={onLogin} onRegisterClick={this.onRegisterClick} /></Text>
    </View>, "is_login_open", 400, 500, '#d2d4e9')
  }

  afterInfo() {
    this.props.putInParent(null);
    
    let { isLoggedIn } = this.state;

    if(!isLoggedIn) {
      this.onLoginClick(this.chooseAfterLogin);
    } else {
      let has_profile = this.checkProfile();
      if(has_profile) {
        this.openRSVP();
      } else {
        this.fillOutProfile();
      }
    }
  }

  drawPrice(event) {
    let { isLoggedIn, is_member, has_any_dinners } = this.state;

    let onPress;

    if(isLoggedIn) {
      onPress = () => { this.props.navigate('/dashboard/subscription') };
    } else {

      onPress = this.onLoginClick
    }

    let member_price = event.member_price
    let m_price = 46;

    let non_member_price = event.non_member_price;

    return <TouchableOpacity onPress={()=>this.openRSVP(this.afterInfo)} style={global_style.column}>
        <View style={style.pricing_divider}>
          <Text style={[style.price_title]}>One-time</Text><Text style={[style.price_text, {width: m_price}]}>${non_member_price}</Text>
        </View>
        <View style={style.pricing_divider}>
          <Text style={[style.price_title]}>Member </Text><Text style={[style.price_text_member, {width: m_price}]}>${member_price}</Text>
        </View>
    </TouchableOpacity>
      
  }

  chooseAfterLogin() {
    let { is_member, rsvped, pre_rsvped, event, user_id, status, has_any_dinners } = this.state;

    console.log('after login/regiser', is_member, rsvped, pre_rsvped, event, user_id);

    let is_events_host = event.hosted && event.host === user_id;
    window.location.reload();
    /* if(has_any_dinners && event.new_member) {
      window.location.reload();
    } else {
      if(!rsvped && !pre_rsvped && !is_events_host) {
        if(!is_member && status !== 'public') {
          this.openPreConfirm();
        } else {
          this.openRSVP();
        }
      } else {
        this.toggleState();
      }
    } */
  }

  openPreConfirm() {
    let { is_member, checked_host, event, user_id, rsvp_loading, isLoggedIn } = this.state;
    console.log('opening preconfirm again')
    this.openInParent(<View style={global_style.center_content}><Text style={{fontFamily: 'Ysabeau', fontSize: 20, textAlign: 'center', marginBottom: 30, marginTop: 20}}>Because this event is more than 7 days away, you're only allowed to pre-RSVP. When the event becomes open, we notify our pre-RSVPed members first.</Text>
      <Button title={<Text style={style.club_button_text}>Pre-RSVP</Text>}
          loading={rsvp_loading}
          disable_imediately={true}

          style_inactive={{backgroundColor: '#85bebe',
            alignSelf: 'center',
            borderRadius: 3,
            paddingTop: 5,
            paddingBottom: 7,
            paddingHorizontal: 20, width: 207}}

          style={{backgroundColor: '#0b6665',
            alignSelf: 'center',
            borderRadius: 3,
            paddingTop: 5,
            paddingBottom: 7,
            paddingHorizontal: 20, width: 207}}

          onPress={this.rsvpClick} /></View>, "is_pre_rsvp_open", 400, 400, 'white')
  }

  togglePreRSVP() {
    let { isLoggedIn } = this.state;

    if(!isLoggedIn) {
      this.onLoginClick(this.chooseAfterLogin);
    } else {
      let has_profile = this.checkProfile();
      if(has_profile) {
        this.openPreConfirm();
      } else {
        this.fillOutProfile();
      }
    }
  }

  drawInvitePanel() {

    return <View>
      <Text></Text>
    </View>
  }

  openRSVP(onClick) {
    console.log('opening rsvp again')
    let { is_member, event, non_member_open, open_member, loading, yearly_loading, monthly_loading, ack_check } = this.state;

    let page_width = Dimensions.get('window').width;
    let page_height = Dimensions.get('window').height;

    let { non_member_price, member_price } = event;
    let is_mobile = page_width <= 400;

    let modal_width = page_width <= 400 ? page_width * .9 : page_width * .55;
    let modal_member_width = is_mobile ? 'calc(100vw - 20px)' : 500;

    let modal_member_height = is_mobile ? 400 : page_height * .9;
    let modal_height = page_height * .9;

    const openNonMember = () => {
      this.props.putInParent(null)
      this.setState({
        is_rsvp_confirm_open: false,
        non_member_open: !this.state.non_member_open
      }, ()=>{
        this.openRSVP();
      })
    }

    const openMember = () => {

      this.props.putInParent(null)
      this.setState({
        is_rsvp_confirm_open: false,
        open_member: !this.state.open_member
      }, ()=>{
        this.openRSVP();
      })
    }

    let tile_style = is_mobile ? { padding: 50 } : {};

    let non_member_panel = <View style={[global_style.row_mobile_switch_column]}>
      <View style={[ global_style.colum, style.rsvp_column ]}>

        <TouchableOpacity onPress={openNonMember}><Text style={[style.rsvp_title, {cursor: 'pointer', backgroundColor: '#11a1b4'}, tile_style]}>${non_member_price} - One-time</Text></TouchableOpacity>
      
      {non_member_open || !is_mobile ?
        <View style={style.rsvp_inner}>
            <Text style={[style.rsvp_price_one_time]}>${non_member_price}</Text>

            <View style={[style.rsvp_line, {marginTop: -10}]}>
              <Text style={[style.rsvp_line_text]}>
                Join us one time for the special dinner with Cedric Gayon. 
              </Text>
              <Text style={[style.rsvp_line_text2, {textTransform: 'uppercase', marginTop: 20}]}>
                  *All sales are final.
                </Text>
                <Text style={[style.rsvp_line_text2, {textTransform: 'uppercase'}]}>
                  *No refunds provided.
                </Text>
            </View>
            <View style={{marginTop: 40, alignSelf: 'center'}}>
              <Button title={<Text style={style.club_button_text}>Confirm RSVP</Text>}
                loading={loading}
                style={[style.club_button, {paddingVertical: 15}]}
                disable_imediately={true}
                style_inactive={style.club_button}
                inactive={false}
                onPress={this.toggleConfirmRSVP} />
            </View>
        </View> : null}

      </View>
      <View style={[global_style.column, style.rsvp_column]}>
      <TouchableOpacity onPress={openMember}><Text style={[style.rsvp_title, {cursor: 'pointer', backgroundColor: '#1fb411'}, tile_style]}>${member_price} - Membership</Text></TouchableOpacity>
      {open_member || !is_mobile ? <View style={style.rsvp_inner}>
        <Text style={[style.rsvp_price_member]}>${member_price}</Text>
        <View>
            <Text style={[style.rsvp_line_text]}>
              Get a special discount with a membership. Subscribe to join an unlimited number of get-togethers for coffee, brunch, dinner or just drinks.
            </Text>
          <View style={[style.rsvp_membership_button, {marginTop: 10}]}>
            <Button loading={monthly_loading} 
              disable_imediately={true}
              onPress={()=>this.subscriptionStart(0)}
                            style={[style.subscription_monthly]} title={
              <View style={[style.a_column, {width: 250}]}>
                <Text style={style.club_button_text}>Subscribe Monthly</Text>
                <Text style={style.club_button_text}>$30/month</Text>
              </View>} />
          </View>
          <View style={[style.rsvp_membership_button]}>
            <Button loading={yearly_loading} 
              disable_imediately={true}
              onPress={()=>this.subscriptionStart(1)}
              style={[style.subscription_box, style.subscription_yearly]} title={
              <View style={[style.a_column, {width: 250}]}>
                <Text style={style.club_button_text}>Subscribe Yearly</Text>
                <Text style={style.club_button_text}>$25/month paid yearly</Text>
              </View>} />
          </View>
          
        </View>
        </View> : null}
      </View>
    </View>

      let member_panel = <>
      <View style={{flexDirection: 'column'}}>
          <Text style={{fontFamily: 'Comfortaa', fontSize: 30}}>Join us!</Text>
              <Text style={[style.rsvp_price_one_time]}>${member_price} for Members </Text>
              <View style={[style.rsvp_line, {marginTop: -10}]}>
                <Text style={{fontFamily: 'Ysabeau', fontSize: 16}}>
                  Join our special 5-course dinner created specifically for JCRF where our members get a 25% discount.
                </Text>
                <Text style={[style.rsvp_line_text2, {textTransform: 'uppercase', marginTop: 20}]}>
                  *All sales are final.
                </Text>
                <Text style={[style.rsvp_line_text2, {textTransform: 'uppercase'}]}>
                  *No refunds provided.
                </Text>
              </View>
              <View style={{alignSelf: 'center', marginTop: 30}}>
                <Button title={<Text style={style.club_button_text}>Confirm RSVP</Text>}
                  loading={loading}
                  disable_imediately={true}
                  style={style.club_button}
                  style_inactive={style.club_button}
                  inactive={false}
                  onPress={this.rsvpClick} />
              </View>
      </View>
      </> 

      this.openInParent(<View style={global_style.center_content}>

        {!is_member ? non_member_panel : member_panel}

        </View>, "is_rsvp_confirm_open", is_member ? modal_member_width : modal_width, is_member ? modal_member_height : modal_height, 'white')

  }

  toggleConfirmRSVP() {
    let { isLoggedIn, is_member } = this.state;

    if(!isLoggedIn) {
      this.onLoginClick(this.chooseAfterLogin);
    } else {
      let has_profile = this.checkProfile();
      if(has_profile) {
        this.rsvpClick();
      } else {
        this.fillOutProfile();
      }
    }
  }

  toggleMakeHost() {

    this.openInParent(<View style={global_style.center_content}>
  <Text style={{fontFamily: 'Ysabeau', fontSize: 20, textAlign: 'center', marginBottom: 60}}>
    Thanks for choosing to host! We'll send out a notification to all attendees letting them know.
  </Text>
  <Button title={<Text style={style.club_button_text}>Confirm</Text>}

style_inactive={{backgroundColor: '#85bebe',
            alignSelf: 'center',
            borderRadius: 3,
            paddingTop: 5,
            paddingBottom: 7,
            paddingHorizontal: 20, width: 207}}

      style={{backgroundColor: '#0b6665',
      borderRadius: 3,
      paddingTop: 5,
      paddingBottom: 7,
      paddingHorizontal: 20, width: 207}}
      disable_imediately={true}
      onPress={this.confirmMakeHost} /></View>, "is_make_host_open", 400, 300, 'white')
  }

  toggleResignHost() {

    let { can_cancel, can_cancel_by_date } = this.getCanCancel();

    let the_text;

    if(can_cancel_by_date) {
      the_text = <Text style={{fontFamily: 'Ysabeau', fontSize: 20, textAlign: 'center', marginBottom: 60}}>
        Once you resign as host, we'll send out a notification to all attendees letting them know a host is needed.
      </Text>
    } else {
      the_text = <Text style={{fontFamily: 'Ysabeau', fontSize: 20, textAlign: 'center', marginBottom: 60}}>
        Since you're past the deadline for cancelation, resigning as host means you could lose your host status. Please do still let us know if you can't make it.
      </Text>
    }

    this.openInParent(<View style={global_style.center_content}>
  {the_text}
  <Button title={<Text style={style.club_button_text}>Resign</Text>}
            style_inactive={{backgroundColor: '#85bebe',
            alignSelf: 'center',
            borderRadius: 3,
            paddingTop: 5,
            paddingBottom: 7,
            paddingHorizontal: 20, width: 207}}
      style={{backgroundColor: '#0b6665',
      borderRadius: 3,
      paddingTop: 5,
      paddingBottom: 7,
      paddingHorizontal: 20, width: 207}}
      disable_imediately={true}
      onPress={this.confirmResignHost} /></View>, "is_resign_host_open", 400, can_cancel_by_date ? 300 : 400, 'white')
  }

  toggleChooseAsMember() {


    let window_width = Dimensions.get('window').width;
    let is_mobile = window_width <= 700;

    this.openInParent(<View style={global_style.center_content}>
      <Text style={{fontFamily: 'Ysabeau', fontSize: 20, textAlign: 'center'}}>Not vibing with someone?</Text> 
      <Text style={style.experience_text}>Your experience is the most important thing to us. Don't be shy to let us know. As a member, you're able to tell us which members you would not like to dine with so that they cannot RSVP after you have.</Text> 
      <Button loading={false} title={<Text style={[style.club_button_text, {marginHorizontal: 0}]}>Become a Member</Text>}
                style_inactive={{backgroundColor: 'black',
            alignSelf: 'center',
            borderRadius: 3,
            paddingTop: 5,
            paddingBottom: 7,
            paddingHorizontal: 20, width: 207}}
          style={{backgroundColor: 'black',
          borderRadius: 3,
          paddingTop: 5,
          marginTop: 20,
          paddingBottom: 7,
          paddingHorizontal: 20, width: 250}}
          disable_imediately={true}
          onPress={()=>{window.location.replace('/dashboard/subscription')}} />
      </View>, "is_choose_member_open", is_mobile ? 200 : 400, 300, 'white', true)
  }

  toggleCancelRSVP() {
    let { cancel_loading, checked_host, event, user_id } = this.state;

    let { can_cancel, can_cancel_by_date } = this.getCanCancel();

    let is_host = checked_host || (event.hosted && event.host === user_id);

    this.openInParent(<View style={global_style.center_content}>
      {event.free ? <Text style={{fontFamily: 'Ysabeau', fontSize: 20, textAlign: 'center', marginTop: 40, marginBottom: 60}}>You're canceling your RSVP. Thank you for letting us know!</Text> : (
      can_cancel_by_date ?
        <Text style={{fontFamily: 'Ysabeau', fontSize: 20, textAlign: 'center', marginBottom: 40}}>
          You are within a timeframe where you can cancel without a charge. Thank you for letting us know!
        </Text> :
        (
          is_host ? 
          <Text style={{fontFamily: 'Ysabeau', fontSize: 20, textAlign: 'center', marginBottom: 40}}>Since you're past the deadline for cancelation, resigning as host means you could lose your host status. Please do still let us know if you can't make it.</Text>
          :
          <>
            <Text style={{fontFamily: 'Ysabeau', fontSize: 20, textAlign: 'center', marginBottom: 10}}>You are in a timeframe where your fee will not be refunded if you cancel. However, if you still cannot make it, we do appreciate you letting us know by canceling here so that others can RSVP. You receive 5 points every time you cancel without getting refunded.</Text>
            <TouchableOpacity onPress={()=>{window.open('/points')}}><Text style={[global_style.normal_link, {marginBottom: 30}]}>Points info</Text></TouchableOpacity>
          </>
        ))}

      <Button loading={cancel_loading} title={<Text style={[style.club_button_text, {marginHorizontal: 0}]}>Cancel RSVP</Text>}
                style_inactive={{backgroundColor: '#85bebe',
            alignSelf: 'center',
            borderRadius: 3,
            paddingTop: 5,
            paddingBottom: 7,
            paddingHorizontal: 20, width: 207}}
          style={{backgroundColor: '#0b6665',
          borderRadius: 3,
          paddingTop: 5,
          paddingBottom: 7,
          paddingHorizontal: 20, width: 208}}
          disable_imediately={true}
          onPress={this.confirmCancel} /></View>, "is_cancel_open", 400, 400, 'white')
  }

  getCanCancel(add_one) {
    let { rsvped, date, event, rsvps, chosen_hosted, checked_host, user_id } = this.state;

    console.log('date', date)

    let rsvp_nums = rsvps ? rsvps.length : 0;

    let hosted_event = chosen_hosted ? chosen_hosted === "1" : event.hosted;

    let rsvp_limit = hosted_event ? event.rsvp_limit_hosted : event.rsvp_limit_not_hosted;

    let rsvp_needed = event.rsvp_needed ? event.rsvp_needed : (event.hosted ? 4 : 3);

    let needs_more = rsvps ? ( rsvp_needed - rsvp_nums ) : null;

    let event_date_time = new Date(`${date}`);

    var previous_day = new Date(event_date_time.getTime() - (24 * 60 * 60 * 1000));

    // Set the time to 9:00 AM
    previous_day.setHours(9, 0, 0, 0);

    let current_date = new Date();
    let can_cancel;
    let can_cancel_by_date;

    console.log('previous_day < current_date', previous_day < current_date);

    if (previous_day < current_date) {
      can_cancel_by_date = false;
      can_cancel = needs_more > 0;
    } else {
      can_cancel_by_date = true;
      can_cancel = true;
    }

    console.log('can_cancel_by_date', can_cancel_by_date);

    let is_host = checked_host || (event.hosted && event.host === user_id);
    
    let host_text = hosted_event ?  ' plus the host' : '';

    let and_show = event.free ? ' and you DO NOT show up.': '.'

    let unless_more = needs_more > 0 && !is_host ? ` But still, you will only be charged when 3 people${host_text} RSVP${and_show}` : '';
    
    let cancel_date = previous_day.toLocaleString('en-US', { 
      weekday: 'long', 
      month: 'long', 
      day: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit'
    });

    let cancel_text =`${can_cancel_by_date ? `You can cancel without charge before ${cancel_date}` : `Please make sure you're able to make it. The deadline for canceling has past.`}${unless_more}`;

   /*  if(checked_host || (event.hosted && event.host === user_id)) {
      cancel_text = `As the host of the event, you will not be able to cancel your RSVP through the site. Please make sure you're able to make it!`
    } */

    let can_rsvp = rsvp_nums < rsvp_limit;

    console.log('needs_more, event.hosted, event.host, checked_host', needs_more, event.hosted, event.host, checked_host)

    if(rsvp_nums === rsvp_limit - 1 && event.hosted && !event.host && !checked_host) {
      can_rsvp = false;
      cancel_text = `Since you're the last person to RSVP, you must make yourself the host. Please read below about the hosts responsibilities.`
    }

    return { can_cancel, needs_more, cancel_text, can_cancel_by_date, can_rsvp };
  }

  drawTheButton(open_event, rsvps_open) {

    let { subscription_loading, mounted, rsvped, has_event_within_hour, is_member, pre_rsvped, status, isLoggedIn, loading, confirm_inactive, rsvp_loading, has_any_dinners, event, user_is_uninvited, user } = this.state;

    let { free, special } = event;

    status = 'public';

    let show_confirm_button = !user_is_uninvited && isLoggedIn && !rsvped && !has_event_within_hour && rsvps_open > 0 && (status === 'public' || is_member || free) && (event.new_member ? !has_any_dinners : true);

    let show_pre_rsvp_button = !free && !rsvped && !has_event_within_hour && !is_member && !pre_rsvped && status === 'pre' && isLoggedIn;

    show_pre_rsvp_button = false //special && !pre_rsvped && isLoggedIn;

    console.log('subscription_loading', subscription_loading, mounted)

    return <View>{subscription_loading || !mounted ? <View style={{width: 60, height: 60, alignSelf: 'center', paddingTop: 100}}>
    <Progress.CircleSnail size={60} backgroundColor="black" fill='black' color={'white' } />
    </View> : <View>

      {show_confirm_button ?
      <Button title={<Text style={style.club_button_text}>Confirm RSVP</Text>}
        loading={rsvp_loading}
        style={style.club_button}
        style_inactive={style.club_button}
        inactive={false}
        onPress={()=>this.openRSVP(this.afterInfo)} /> : null }

      {show_pre_rsvp_button ?
          <Button title={<Text style={style.club_button_text}>Pre-RSVP</Text>}
            loading={loading}
            style={style.club_button}
            style_inactive={style.club_button_inactive}
            inactive={!open_event ? false : confirm_inactive}
            onPress={this.togglePreRSVP} /> : null}

      {user_is_uninvited ? <Text style={{color: 'white'}}>This event is invite only</Text> : null}

      {has_event_within_hour && !rsvped ? <Text style={[global_style.medium_description, global_style.center_content, global_style.text_vibrant_red, global_style.text_uppercase, global_style.padding_top_20]}>You're already have another event within the hour</Text> : null}

      {rsvped ? <View style={style.buttons_wrapper}>

        {!free && !special ?
        (is_member ? <Button title={<Text style={style.club_button_text}>Choose Invites</Text>}
            style={[style.club_button, {backgroundColor: '#25dafb', marginBottom: 20}]}
            style_inactive={style.club_button_inactive}
            onPress={this.toggleChooseInvitesUninvites} /> : <Button title={<Text style={style.club_button_text}>Choose Invites</Text>}
            style={[style.club_button, {backgroundColor: '#25dafb', marginBottom: 20}]}
            style_inactive={style.club_button_inactive}
            onPress={this.toggleChooseAsMember} />) : <View style={{flex: 1}}></View>}
              
      {/* <Button title={<Text style={style.club_button_text}>Cancel RSVP</Text>}
            style={style.club_button}
            style_inactive={style.club_button_inactive}
            onPress={this.toggleCancelRSVP} /> */}


  
      </View> : null}
  
      {!isLoggedIn ?
          <Button title={<Text style={style.club_button_text}>RSVP</Text>}
            loading={loading}
            style={style.club_button}
            style_inactive={style.club_button_inactive}
            inactive={!open_event ? false : confirm_inactive}
            onPress={this.togglePreRSVP} /> : null}
  
    </View> }</View>
  }

  render() {
    let { subscription_loading, mounted, date, is_member, rsvped, day_name, event, place, chosen_demographic, chosen_restaurant, type, chosen_dinner_time, chosen_hosted, internal, is_host, checked_host, host, user_id, rsvps, discussion_open, messages, text_message, sending_message, message_refresh, isLoggedIn, status, pre_rsvped } = this.state; 

    let restaurant_container, price, restaurant_name, dinner_time, directions_link, background_color, event_name, dining_type, demographic, movie_url, demographics, description, free, tbd, place_holder_image, description_container, video_thumbnail, hosted, non_member_price, member_price;
    let place_saved = !!place;

    console.log('chosen_hosted', event)
    console.log('hosthost', host)

    if(mounted) {
      dinner_time = event.time ? Utilities.getEventTime(event.time) : null;
      event_name = event.name;
      ({type, demographic, hosted, description, place_holder_image, movie_url, video_thumbnail, non_member_price, member_price} = event)

      console.log('chosen_restaurant', chosen_restaurant, typeof chosen_restaurant)

      free = !!event.free;

      tbd = !!event.tbd;

      price = is_member ? member_price : non_member_price;

      let window_width = Dimensions.get('window').width;
      let is_mobile = window_width < 700;
      
      if(place) {
        restaurant_name = place.restaurant_name;
        directions_link = place.directions_link;

        description_container = <View> 
        <Text style={style.details_title}>{event_name}</Text>
        <Text style={style.description}>{ Utilities.getDescription(description, style, true)}</Text>
      </View>

      let video_url = movie_url ? movie_url : main_video;
      video_thumbnail = video_thumbnail ? video_thumbnail : 'https://diningsocial-assets.s3.amazonaws.com/HappyHourThumb.png';

        restaurant_container = <View style={style.restaurant_wrapper}>
          {/* {is_mobile ? <View style={{marginBottom: 50}}></View> : null} */}
          {/* {is_mobile ? (rsvped ? <Text style={[style.main_title_name_mobile]}>You're going to: {event_name}</Text> : <Text style={[style.main_title_name_mobile]}>{event_name}</Text>) : null} */}
          <View style={[style.kitchestep_image_text_wrapper]}>
            <View style={style.kitchenstep_main_wrapper}>
            {event.movie_url ? 
                <View style={style.thumbnail_wrapper}>
                  <View style={{width: 300, position: 'relative', marginHorizontal: 'auto'}}>
                    <Image style={{width: 300, height: 520, position: 'absolute'}} source={{uri: video_thumbnail}} />
                  </View>
                <Video
                  posterSource={{uri: video_url}}
                  source={{ uri: event.movie_url }}
                  style={{width: 300, position: 'relative', marginHorizontal: 'auto'}}
                  videoStyle={{position: 'relative'}}
                  useNativeControls={true}
                  shouldPlay={false}
                  resizeMode="contain" // Or adjust as needed
                />
                </View> :
              <Image resizeMode={'contain'} style={style.kitchenstep_main} source={{uri: place.main_image}} />}
              <TouchableOpacity onPress={()=>{window.open(place.link)}} style={style.visit_restaurant}><Text style={style.visit_restaurant_text}>Visit {restaurant_name}  &#x2197;</Text></TouchableOpacity>
            </View>
          </View>
        </View>;
      } else {
        restaurant_name = 'Restaurant TBD';
        restaurant_container = <View style={style.restaurant_wrapper}>
          <Text style={style.restaurant_title}>{restaurant_name}</Text>
          <View style={[style.kitchestep_image_text_wrapper]}>
            <View style={style.kitchenstep_main_wrapper}>
              <Image resizeMode={'contain'} style={style.kitchenstep_main} source={{uri: place_holder_image ? place_holder_image : 'https://s3.amazonaws.com/www.diningsocialnj.com/restaurant_placeholder.png'}} />
            </View>
          </View>
          <View> 
            <Text style={style.details_title}>Event Details</Text>
            <Text style={style.description}>{description}</Text>
          </View>
        </View>;
      }

      dining_type = Utilities.getEventTypeName(type);

      ({ demographics } = internal);
    }

    let open_event_types = [{name: "Choose an event type...", value: -1}].concat(demographics);

    let dinner_times = [{name: '5:30pm', value: 17.5}, {name: '6pm', value: 18}, {name: '6:30pm', value: 18.5}, {name: '7pm', value: 19}, {name: '7:30pm', value: 19.5}, {name: '8pm', value: 20}]

    let open_event = mounted ? !demographic && (type === -1 || type === "-1") : false;

    let non_hosted = mounted ? !event.hosted : false;

    let needs_host = mounted ? !open_event && !non_hosted && !event.host : false;

    let needs_more;
    let charged_at, can_cancel, cancel_text, rsvps_open, invited;
    if(event) {
      ({invited} = event);
      
      ({ can_cancel, needs_more, cancel_text } = this.getCanCancel(true));

      charged_at = <TouchableOpacity onPress={()=>{this.scrollTo(this.logisticsRef)}}><Text style={global_style.notification_text}>{cancel_text}</Text></TouchableOpacity>;

      let current_rsvps = rsvps.length;

      rsvps_open = event.hosted ? event.rsvp_limit_hosted - current_rsvps : event.rsvp_limit_not_hosted - current_rsvps;
    }

    console.log('status234, mounted', status, status === 'pre', true)

    console.log('!is_member && !checked_host && !subscription_loading rsvped || rsvps_open === 0', !is_member, !checked_host, !subscription_loading, rsvped, rsvps_open)

    return (<ScrollView ref={ref => this.scrollViewRef = ref}><View style={{paddingBottom: 100}}>
        {mounted ? 
        <View style={{flexDirection: 'column'}}>
          <View style={[style.page_container, {backgroundColor: background_color}]}>
            <View style={[style.one_column, style.restaurant_container]}>{restaurant_container}</View>
            <View style={[style.one_column, style.description_container]}>{description_container}</View>
            
          </View>
          <View style={[style.one_column, rsvped ? style.data_column_rsvped : style.data_column]}>

              {status === 'public' ?
                  (needs_more > 0 ? <TouchableOpacity onPress={()=>{this.scrollTo(this.logisticsRef)}} style={[]}><Text style={style.needs_host}>⭐️ This event needs {needs_more} more RSVPS ⭐️</Text></TouchableOpacity> : <TouchableOpacity onPress={()=>{this.scrollTo(this.logisticsRef)}} style={[]}><Text style={style.needs_host}>{rsvps_open === 0 ? 'FULL' : `${rsvps_open} RSVPs open`}</Text></TouchableOpacity>) : null}

              {needs_host ? <TouchableOpacity onPress={()=>{this.scrollTo(this.hostedRef)}} style={[]}><Text style={style.needs_host}>⭐️ This event needs a host ⭐️</Text></TouchableOpacity> : null}

              {rsvped ? <Text style={style.main_title_name}>You're going to: {event_name}</Text> : <Text style={style.main_title_name}>{event_name}</Text>}

              {/* {charged_at && rsvps_open !== 0 && !is_member && status === 'public' ? charged_at : null}
 */}
              { open_event ?
                <View style={style.line}>
                  <Text style={style.line_name}>What</Text>
                  <Text style={style.line_date}>
                    <View style={style.picker}>
                      <Picker
                        selectedValue={chosen_demographic}
                        mode={'dialog'}
                        style={style.drop_style}
                        onValueChange={(value)=>this.updateState(value, 'chosen_demographic')}
                      >
                        {open_event_types.map(v => {
                          return <Picker.Item
                            fontSize={16}
                            style={{ fontSize: 16 }}
                            key={v.value}
                            label={v.name}
                            value={v.value}
                          />
                        })}
                      </Picker>
                    </View> {type === -1 || type === "-1" ? 'Dinner' : 'Brunch'}
                  </Text>
                  
                </View> : null}


              { open_event ?
                <View style={style.line}>
                  <Text style={style.line_name}>Type</Text>
                  <Text style={style.line_date}>
                    <View style={style.picker}>
                      <Picker
                        selectedValue={chosen_hosted}
                        mode={'dialog'}
                        style={style.drop_style}
                        onValueChange={(value)=>this.updateState(value, 'chosen_hosted')}
                      >
                        {[{name: "Choose a type", value: "-1"}, {name: "Not Hosted", value: "0"}, {name: "Hosted", value: "1"}].map(v => {
                          return <Picker.Item
                            fontSize={16}
                            style={{ fontSize: 16 }}
                            key={v.value}
                            label={v.name}
                            value={v.value}
                          />
                        })}
                      </Picker>
                    </View> <TouchableOpacity onPress={()=>{this.scrollTo(this.hostedRef)}} style={[]}><Text style={style.info_button}>i</Text></TouchableOpacity>
                  </Text>
                  
                </View> : <View style={style.line}>
                  <Text style={style.line_name}>Type</Text>
                  <Text style={style.line_date}>
                      {event.hosted ? "Hosted" : "Not hosted" }
                    <TouchableOpacity onPress={()=>{this.scrollTo(this.nonHostedRef)}} style={[]}><Text style={style.info_button}>i</Text></TouchableOpacity>

                    <View style={{marginLeft: 10}}>
                  {chosen_hosted || event.hosted ?
                  <>
                    {chosen_hosted === "0" || (!event.hosted && !open_event) ?
                    <></> : 
                    <View style={global_style.column}>
                      <Text style={[{ flexDirection: "row", display: "flex", alignItems: "flex-end" }]}>
                    
                        {is_host && (((!event.host && !open_event) || open_event) || (event.host && event.hosted && host && host.user_id === user_id)) ? 
                            (
                              (event.host && event.hosted && host && host.user_id === user_id) ? 
                            <><Input 
                              check_style={{check_wrapper: {flexDirection: 'row', flexWrap: 'wrap', display: 'flex', marginLeft: 10}, check_text: {
                                  marginLeft: 10,
                                  fontFamily: 'Ysabeau',
                                  fontSize: 16,
                                  width: 190, 
                                  color: 'white'}}}
                              type={"check"} 
                              onCheck={this.toggleResignHost} 
                              checked={true}
                              label={<Text style={[global_style.normal_text, global_style.padding_5_20, {color: 'yellow'}]}>You're hosting this event</Text>} />
                            </>
                              :
                            <><Input 
                              check_style={{check_wrapper: {flexDirection: 'row', flexWrap: 'wrap', display: 'flex', marginLeft: 10}, check_text: {
                                  marginLeft: 10,
                                  fontFamily: 'Ysabeau',
                                  fontSize: 16,
                                  width: 170, 
                                  color: 'white'}}}
                              type={"check"} 
                              onCheck={()=>this.updateValue("checked_host", true)} 
                              checked={checked_host}
                              label={event.host && event.hosted && host && host.user_id === user_id ? <Text style={[global_style.normal_text, global_style.padding_5_20,  {color: 'yellow'}]}>You're hosting this event</Text> : <Text  style={[global_style.normal_text]}>I'd like to be the host</Text>} />
                              
                              {(rsvped || pre_rsvped) && checked_host !== undefined && checked_host !== !!event.host && !open_event ? 
                                <TouchableOpacity onPress={this.toggleMakeHost} style={global_style.small_button_light}>
                                  <Text style={global_style.small_button_text}>Confirm</Text>
                                </TouchableOpacity> : null }
                            </>
                            ) : (
                              (!event.host && event.hosted || (chosen_hosted === "1" && open_event)) ?
                              <TouchableOpacity onPress={()=>{this.scrollTo(this.hostedRef)}}>
                                <Text style={global_style.normal_light_link}>This event will need a host</Text>
                              </TouchableOpacity> : 
                              <TouchableOpacity onPress={()=>{this.scrollTo(this.attendeesRef)}} >
                                <Text style={[global_style.normal_text, {color: 'yellow'}]}>
                                  Your host is {host.profile.name}
                                </Text>
                              </TouchableOpacity> 
                            )
                          }
                      </Text>
                    </View>}
                    </> : null}
                </View>
                  </Text>
                  
                </View>}

              <View style={style.line}>
                <Text style={style.line_name}>When</Text>
                <Text style={style.line_date}>
                  {day_name} - {date} {dinner_time ? dinner_time : null}&nbsp;&nbsp;
                
                  {rsvped ?
                  <View>
                    <TouchableOpacity onPress={()=>this.addCalendar(event, place)}><Text style={global_style.normal_light_link}>Add to Calendar</Text></TouchableOpacity>
                  </View> : null}
                </Text>
                
                {!dinner_time ?
                <Text style={style.line_date}>
                <View style={style.picker}>
                  <Picker
                    selectedValue={chosen_dinner_time}
                    mode={'dialog'}
                    style={style.drop_style}
                    onValueChange={(value)=>this.updateState(value, 'chosen_dinner_time')}
                    >
                      {[{name: "Choose a dinner time", value: -1}].concat(dinner_times).map(v => {
                        return <Picker.Item
                          fontSize={16}
                          style={{ fontSize: 16 }}
                          key={v.value}
                          label={v.name}
                          value={v.value}
                        />
                      })}
                    </Picker>
                  </View>
                </Text> : null}
              </View>
              
              {tbd ? <View style={style.line}>
                <Text style={style.line_name}>Where</Text>
                <View style={global_style.column}>
                <Text style={style.line_date}>
                  TBD
                </Text>
                </View>
              </View> : null}
              
              {place_saved ? 
              <View style={style.line}>
                <Text style={style.line_name}>Where</Text>
                <View style={global_style.column}>
                <Text style={style.line_date}>
                  {restaurant_name}&nbsp;&nbsp;<TouchableOpacity onPress={()=>this.goDirections(directions_link)}><Text style={global_style.normal_light_link}>Get Directions &#x2197;</Text></TouchableOpacity>
                </Text>
                </View>
              </View> : null}

              {!subscription_loading ?
              (rsvped || rsvps_open === 0 ? null :
                <>
                  <View style={style.line}>
                    <View style={[style.line_name, global_style.column_mobile_switch_row]}>
                      <Text style={style.line_name}>Price</Text></View>
                    <View style={[style.line_date]}>
                      {this.drawPrice(event)}
                    </View>
                  </View>
                </>
              ) : null}

              <View style={style.main_rsvp_wrapper}>{this.drawTheButton(open_event, rsvps_open)}</View>

              {rsvped ? <View style={style.bon_appetit}><Text style={style.main_title_name}>Bon Appétit!</Text></View> : null}
            </View>

          <View ref={node => this.attendeesRef = node} style={[global_style.whole_width_row_mobile_switch_column, global_style.panel_padding_with_mobile, global_style.panel_border]}>
            <View style={[global_style.flex_one_mobile_none, global_style.sub_panel_padding]}>
              {this.drawAttendees()}
            </View>
            <View style={[global_style.flex_one_mobile_none, global_style.sub_panel_padding, global_style.flex_one]}>
              <View style={global_style.row_mobile_switch_column}>
                <Text style={[global_style.panel_sub_title, global_style.padding_subtitle]}>Discussion</Text>
                {message_refresh ? <TouchableOpacity style={style.message_link} onPress={()=>{window.location.reload()}}><Text style={global_style.normal_link}>New messages, refresh page</Text></TouchableOpacity> : null}
              </View>
                
              {discussion_open ? <View style={[global_style.flex_one, global_style.space_between]}>
                <ScrollView style={[global_style.flex_one, style.chat_messages_wrapper]}>
                  {messages && messages.length ?
                    messages.map(message => { return this.drawMessage(message)}) :
                    <Text style={[style.no_messages_text]}>No messages yet</Text>
                    }
                </ScrollView>
                <View style={style.chat_bottom}>
                  <Input placeholder={"Have a comment or question?"} style={{flex: 12}} value={text_message} onChangeText={(event)=>{this.updateState(event, 'text_message')}} multiline={"true"} />

                  {isLoggedIn ?
                  <Button title={<Text style={style.club_button_text}><Icon name="arrow-right" size={40} /></Text>}
                        style={{
                          flex: 1,
                        borderRadius: 10,
                        paddingTop: 5,
                        paddingBottom: 7,
                        paddingHorizontal: 20, width: 50, marginLeft: 5}}
                        loading={sending_message} 
                        onPress={this.sendMessage} /> : 
                        <Button title={<Text style={style.club_button_text}><Icon name="arrow-right" size={40} /></Text>}
                        style={{
                          flex: 1,
                        borderRadius: 10,
                        paddingTop: 5,
                        paddingBottom: 7,
                        paddingHorizontal: 20, width: 50, marginLeft: 5}}
                        loading={sending_message} 
                        onPress={this.onLoginClick} />}
                </View>
              </View> : <View>
                <Text>Not open yet.</Text>
              </View>}
            </View>
          </View>

        </View> : <View style={{backgroundColor: '#black', width: '100vw', height: '100vh'}}>
          <View style={{width: 60, height: 60, alignSelf: 'center', paddingTop: 100}}>
          <Progress.CircleSnail size={60} backgroundColor="black" fill='black' color={'#e32652' } />
          </View>
        </View>}
      </View>
      </ScrollView>
    );
  }
}

function SpecialRSVP(props) {
  if (Platform.OS === 'web') {
    let navigate = useNavigate();
    let { date, type, event_id } = useParams();

    return <SpecialRSVP_Base {...props} event_id={event_id} type={type} date={date} navigate={navigate} />
  } else {
    return <SpecialRSVP_Base {...props} navigate={props.navigation.push} />
  }
}

export default SpecialRSVP;
