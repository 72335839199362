import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { View, Platform, TouchableOpacity, Dimensions, Image, Picker } from 'react-native';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Input, Text } from '../../components';
import authController from '../../controllers/authController';
import UserController from '../../controllers/userController';
import EventController from '../../controllers/eventController';
import PaymentController from '../../controllers/paymentController';
import Utilities from '../../utilities';
import { Modal } from '../../containers';
import { style } from './style';
import { Analytics, PageHit } from 'expo-analytics';
import { config } from '../../../config';
import AuthController from '../../controllers/authController';
const analytics = new Analytics('G-YCC5QPC8BJ');

class IceBreakers_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mounted: true
    }

  }

  async componentDidMount() {
    analytics.hit(new PageHit('IceBreakers'))
      .then(() => {})
      .catch(e => console.log("analytics error", e.message));


  }

  render() {
    let { mounted } = this.state; 

    const goTo = (link) => {
      window.open(link)
    }

    return (
      <View style={style.explainer_section}>
      <View style={style.restaurants_wrapper}>
        <View style={style.restaurant_wrapper}>
          <Text style={style.restaurant_title}>Ice breakers</Text>

          <View style={style.whole_wrapper}>
          <View style={style.intro}>
            <Text style={style.intro_text}>
            Meeting new people shouldn't be that hard. In fact, at the beginning of getting to know someone there's loads of questions and conversation topics that you can dive into. Here are some ice breakers that you can always refer to during our dinners.
            </Text>
            <Text style={style.intro_text}></Text>
          </View>
            <View style={[style.intro, {marginBottom: 100}]}>
              <Text style={style.intro_text}>

              </Text>
              <View style={style.list_item_wrapper}>
                  <View style={style.bullet} />
                  <Text style={style.event_description_list_item}>

                    Where were you born?
                  </Text>
                </View>
                <View style={style.list_item_wrapper}>
                  <View style={style.bullet} />
                  <Text style={style.event_description_list_item}>

                    Where in JC do you live and how long have you been in JC?
                  </Text>
                </View>
                <View style={style.list_item_wrapper}>
                  <View style={style.bullet} />
                  <Text style={style.event_description_list_item}>

                    Does anyone in the group have any fun hobbies?
                  </Text>
                </View>
                <View style={style.list_item_wrapper}>
                  <View style={style.bullet} />
                  <Text style={style.event_description_list_item}>

                    Is anyone in the group watching any cool shows?
                  </Text>
                </View>
                <View style={style.list_item_wrapper}>
                  <View style={style.bullet} />
                  <Text style={style.event_description_list_item}>
                    Do you like what you do and if so, what do you like about it?
                  </Text>
                </View>

                <View style={style.list_item_wrapper}>
                  <View style={style.bullet} />
                  <Text style={style.event_description_list_item}>

                    If you could do anything other than your current job, what would it be?
                  </Text>
                </View>
                <View style={style.list_item_wrapper}>
                  <View style={style.bullet} />
                  <Text style={style.event_description_list_item}>

                    Where have you traveled to outside the US and what's your favorite place to go to?
                  </Text>
                </View>
                <View style={style.list_item_wrapper}>
                  <View style={style.bullet} />
                  <Text style={style.event_description_list_item}>

                    Do you have any siblings and do they live near?
                  </Text>
                </View>
                <View style={style.list_item_wrapper}>
                  <View style={style.bullet} />
                  <Text style={style.event_description_list_item}>
                    What's your favorite day of the week and why? How do you like to spend it?
                  </Text>
                </View>
          </View>
        </View>
        </View>
      </View>
      </View>
    );
  }
}

function IceBreakers(props) {
  if (Platform.OS === 'web') {
    let navigate = useNavigate();
    let { date, type, event_id, group } = useParams();

    return <IceBreakers_Base {...props} group={group} event_id={event_id} type={type} date={date} navigate={navigate} />
  } else {
    return <IceBreakers_Base {...props} navigate={props.navigation.push} />
  }
}

export default IceBreakers;
