import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { StyleSheet, View, Platform, Linking, TouchableOpacity, Text, Image } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { style } from './style';
import { Analytics, PageHit } from 'expo-analytics';
const analytics = new Analytics('G-YCC5QPC8BJ');
const home_image = require('../../../assets/jc_social_main.jpeg');

class HomeScreen_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      display_section: 'phone_input',
      phone_number: '',
      phone_number_valid: false,
      validation_code: '',
      email: '',
      password: '',
      tos_accepted: false
    }


  }

  render() {

    return (
      <View style={style.page_wrapper}>
        <View style={style.main_image_wrapper}>
          <Image contentType={'scale'} style={style.main_image}  source={home_image} />

          <View style={style.main_text_band}></View>
          <View style={style.main_text_wrapper}>
            <Text style={style.main_callout}>Jersey City Social</Text>
          </View>
        </View>
        <View style={style.home_breaker}></View>
        <View style={style.events_section}>
          <Text style={style.secondary_title}>Next Event</Text>
          <View style={style.events_list_wrapper}>

          </View>
        </View>
        <View style={style.home_breaker}></View>
        <View style={style.subscriptions_wrapper}>
          <Text style={style.secondary_title}>JC Social Clubs</Text>
          <View style={style.clubs_wrapper}> 
            <View style={[style.one_club_wrapper, style.club_dining]}>
              <Text style={[style.club_title, style.dining_title]}>Dining & Social Club</Text>
              <TouchableOpacity onPress={ () => { this.props.navigate('/diningclub') }}
                                style={style.club_button}>
                <Text style={style.club_button_text}>Find out</Text>
              </TouchableOpacity>
            </View>
            <View style={style.one_club_wrapper}>
              <Text style={style.club_title}>JC Social - 20s/30s</Text>
              <TouchableOpacity onPress={ () => { this.props.navigate('/jcsocial') }}
                                style={style.club_button}>
                <Text style={style.club_button_text}>Find out</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

function HomeScreen(props) {
  if(Platform.OS === 'web') {
    let navigate = useNavigate();
    return  <HomeScreen_Base {...props} navigate={navigate} />
  } else {
    return  <HomeScreen_Base {...props} navigate={props.navigation.push}  />
  }
}

export default HomeScreen;
