import { StyleSheet, Dimensions } from 'react-native';

let width = Dimensions.get('window').width;
let height = Dimensions.get('window').height;

let global_style = StyleSheet.create({
  input_text_hobbie: {
    textTransform: 'capitalize',
    marginRight: 10,
    marginBottom: 10,
    backgroundColor: '#0dc6e3',
    borderRadius: 3,
    padding: 5,
    paddingHorizontal: 2,
    color: 'white',
    fontFamily: "Ysabeau",
    lineHeight: 20,
    fontSize: 18,
    marginTop: 5,
    textAlign: 'center'
  },
  overlay: {
    flex: 1,
    top: 0, 
    left: 0,
    opacity: .3,
    backgroundColor: 'black',
    width: '100vw',
    height: '100%',
    position: 'fixed',
    zIndex: 100000
  }
})

let style_small = StyleSheet.create({
  ...global_style,
  user_email: {
    fontFamily: 'Ysabeau',
    paddingHorizontal: 5,
    paddingBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc'
  },
  header_container: {
    backgroundColor: 'white',
    height: '50px',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 40,
    zIndex: 2,
    borderBottomWidth: 1,
    borderColor: '#dcd3d2'
  },
  logo_text: {
    fontFamily: 'Ysabeau',
    fontSize: '30px',
    /* textShadowColor: 'rgba(0, 0, 0, 1)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 5 */
  },
  account_button: { 
    paddingTop: 20, 
    paddingBottom: 20, 
    flexDirection: 'row', 
    justifyContent: 'space-between' 
  },
  account_drop: {
    backgroundColor: 'white',
    position: 'absolute',
    right: 40,
    top: 50,
    zIndex: 100,
    borderWidth: 1,
    borderRadius: 3,
    paddingVertical: 10
  },
  account_link_text: {
    fontFamily: 'Ysabeau',
    paddingVertical: 5,
    paddingHorizontal: 20
  },
  right_buttons: {
    flexDirection: 'row'
  },
  attendee_bubble: {
    borderRadius: 100,
    width: 30,
    height: 30,
    justifyContent: 'center',
    marginRight: 5,
    cursor: 'pointer',
    position: 'relative'
  },
  attendee_name: {
    fontFamily: 'Comfortaa',
    fontSize: 20,
    textAlign: 'cetner',
    marginBottom: 10
  },
  attendee_info: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    paddingVertical: 10,
    lineHeight: 20
  },
  attendee_info_title: {
    fontFamily: 'Comfortaa',
    fontSize: 18,
    paddingVertical: 10,
    lineHeight: 20
  },
  attendee_photo: {
    width: 50,
    height: 50,
    borderRadius: 100
  },
  floating_info: {
    flex: 1,
    position: 'absolute',
    width: '100%',
    height: 'calc(100vh - 40px)',
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    padding: 40,
    zIndex: 200001,
    top: 20,
    //left: 'calc(50vw - 400px)'
  },
  profile_image: {
    width: 200,
    height: 200,
    borderRadius: 100,
    borderWidth: 2
  },
  profile_image_wrapper: {
    width: 200,
    height: 200,
  },
  fat_top: {
    flexDirection: 'column'
  },
  profile_wrapper: {
    alignItems: 'center',
    marginBottom: 40
  },
  close_x: {
    fontFamily: 'Ysabeau',
    fontSize: 30,
    display: 'flex',
    justifyContent: 'flex-end',
    cursor: 'pointer'
  }
});

let style_normal = StyleSheet.create({
  ...global_style,
  user_email: {
    fontFamily: 'Ysabeau',
    paddingHorizontal: 5,
    paddingBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc'
  },
  header_container: {
    backgroundColor: 'white',
    height: '50px',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 40,
    zIndex: 2,
    borderBottomWidth: 1,
    borderColor: '#dcd3d2'
  },
  logo_text: {
    fontFamily: 'Ysabeau',
    fontSize: '30px',
    /* textShadowColor: 'rgba(0, 0, 0, 1)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 5 */
  },
  account_button: { 
    paddingTop: 20, 
    paddingBottom: 20, 
    flexDirection: 'row', 
    justifyContent: 'space-between' 
  },
  account_drop: {
    backgroundColor: 'white',
    position: 'absolute',
    right: 40,
    top: 50,
    zIndex: 100,
    borderWidth: 1,
    borderRadius: 3,
    paddingVertical: 10
  },
  account_link_text: {
    fontFamily: 'Ysabeau',
    paddingVertical: 5,
    paddingHorizontal: 20
  },
  right_buttons: {
    flexDirection: 'row'
  },
  attendee_bubble: {
    borderRadius: 100,
    width: 30,
    height: 30,
    justifyContent: 'center',
    marginRight: 5,
    cursor: 'pointer',
    position: 'relative'
  },
  attendee_name: {
    fontFamily: 'Comfortaa',
    fontSize: 20,
    textAlign: 'cetner',
    marginBottom: 10
  },
  attendee_info: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    paddingVertical: 10,
    lineHeight: 20
  },
  attendee_info_title: {
    fontFamily: 'Comfortaa',
    fontSize: 18,
    paddingVertical: 10,
    lineHeight: 20
  },
  attendee_photo: {
    width: 50,
    height: 50,
    borderRadius: 100
  },
  floating_info: {
    flex: 1,
    position: 'absolute',
    width: 800,
    height: 'calc(100vh - 40px)',
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    padding: 40,
    zIndex: 200001,
    left: 'calc(50% - 400px)',
    top: 20,
    //left: 'calc(50vw - 400px)'
  },
  profile_image: {
    width: 200,
    height: 200,
    borderRadius: 100,
    borderWidth: 2
  },
  profile_image_wrapper: {
    width: 200,
    height: 200,
  },
  fat_top: {
    flexDirection: 'row',
    marginBottom: 40
  },
  profile_wrapper: {
    marginRight: 40
  },
  close_x: {
    fontFamily: 'Ysabeau',
    fontSize: 30,
    display: 'flex',
    justifyContent: 'flex-end',
    cursor: 'pointer'
  }
});

let style = width < 1160 ? style_small : style_normal;

export {
  style
}