import { StyleSheet, Dimensions } from 'react-native';

let width = Dimensions.get('window').width;
let height = Dimensions.get('window').height;

let plans_wrapper, intro;
if(width > 500) {
  plans_wrapper = {
    flexDirection: 'row', 
    width: 900, 
    justifyContent: 'space-between', 
    margin: 'auto'
  };
  intro = {
    marginBottom: 50,
    flexDirection: 'row',
    margin: 'auto',
    //width: 850,
    justifyContent: 'center',
    flexDirection: 'column',
  }
} else {
  plans_wrapper = {
    flexDirection: 'column', 
    //width: 900, 

  };
  intro = {
    marginBottom: 50,
    flexDirection: 'row',
    margin: 50,
    justifyContent: 'center',
    flexDirection: 'column',
  }
}

const style = StyleSheet.create({
  plans_wrapper,
  intro,
  join_button_free: {
    backgroundColor: '#e32652',
  },
  join_button_monthly: {
    backgroundColor: '#e32652',
  },
  join_button_yearly: {
    backgroundColor: '#e32652',
  },
  intro_text: {
    fontFamily: 'Comfortaa',
    fontSize: 16,
    lineHeight: 26,
    backgroundColor: 'white',
    color: 'black',
    textAlign: 'center'
  },
  one_plan: {
    flexDirection: 'column', 
    width: 280, 
    alignContent: 'center',
    margin: 'auto',
    marginBottom: 40
  },
  plan_cap: {
    flexDirection: 'column', 
    backgroundColor: '#222326', 
    borderTopLeftRadius: 10, 
    borderTopRightRadius: 10,
    alignContent: 'center', 
    alignItems: 'center', 
    padding: 20,
    //border: 'solid 3px #e32652'
  },
  join_button: {

    paddingVertical: 10,
    width: 200,
    borderRadius: 30,
    //marginTop: 40,
    marginBottom: 20,
    marginTop: 20,
    alignSelf: 'center'
  },
  join_button_text: {
    fontFamily: 'Ysabeau',
    fontSize: 20,
    textAlign: 'center',
    color: '#fbf5f0'
  },
  coffee_callout: {
    backgroundColor: '#0eff00',
    borderRadius: 20,
    padding: 20
  },
  coffee_callout_title: {
    fontFamily: 'Ysabeau',
    fontSize: 24,
    color: 'black',
    textAlign: 'center'
  },
  coffee_callout_desc: {
    fontFamily: 'Ysabeau',
    fontSize: 16,
    color: 'black',
    textAlign: 'center',
    lineHeight: 20,
    marginTop: 10
  },
  selected_hobby: {
    backgroundColor: '#0dc6e3', 
  },
  hobbies_edit_wrapper: {
    flexDirection: 'column',
  },
  hobbies_wrapper: {
    height: 300,
    width: '100%',
    overflow: 'scroll'
  },
  interest_button: {
    color: 'black',
    fontFamily: 'Ysabeau',
    fontSize: 16,
    backgroundColor: 'gray', 
    borderRadius: 5,
    paddingHorizontal: 20,
    paddingVertical: 5,
    marginRight: 10,
    marginBottom: 10
  },
  dont_text: {
    fontFamily: 'Comfortaa',
    fontSize: 12,
    color: 'black',
  },
  profile_image: {
    width: 200,
    height: 200,
    borderRadius: 100,
    borderWidth: 2
  },
  profile_image_wrapper: {
    width: 200,
    height: 200,
  },
  edit_photo_button: {
    color: 'black',
    fontFamily: 'Ysabeau',
    backgroundColor: '#7ce1e0', 
    borderRadius: 5,
    position: 'absolute',
    bottom: 10,
    right: '32%',
    paddingHorizontal: 20
  },
  register_wrapper: {
    width: 300,
    flexDirection: 'row',

    marginTop: 30,
    marginBottom: 10
  },
  check_wrapper: {
    width: '100%',
    flexDirection: 'row',
    marginVertical: 20, 
    marginLeft: -10
  },
  a_row:  {
    flexDirection: 'row'
  },
    lr_container: {
      padding: 15,
      paddingTop: 0,
      width: '400px',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      alignContent: 'center',
      paddingBottom: 100
    },
    lr_header_tabs: {
      alignSelf: 'center',
      flexDirection: 'row',
      flex: 1
    },
    lr_tab: { 
      alignItems: 'center',
      flexDirection: 'column'
    },
    page_container: { 
      flex: 1,
      width: '100%',
      alignContent: 'center',
      alignItems: 'center',
      backgroundColor: 'white'
    },
    login_text: {
      fontFamily: 'Ysabeau',
      fontSize: 30,
      marginBottom: 20,
      color: 'black',
    },
    login_button: {
      backgroundColor: '#e32652',
      borderRadius: 30,
      fontFamily: 'Comfortaa'
    },
    inactive_login_button: {
      backgroundColor: '#d78599',
      borderRadius: 30,
      fontFamily: 'Comfortaa'
    },
    line_breaker: {
      height: 20
    },
    input_box: {
      width: 300
    },
    secondary_title: {
      fontFamily: 'Comfortaa',
      fontSize: 18,
      marginTop: 30,
      marginBottom: 0,
      color: 'black',
    },
    explainer_text: {
      fontFamily: 'Ysabeau',
      fontSize: 16,
      marginTop: 0,
      marginBottom: 20,
      textAlign: 'center'
    },
    preferences_tab: {
      width: width <= 400 ? width - 40 : 400
    },
    explainer_text_two: {
      fontFamily: 'Ysabeau',
      fontSize: 16,
      marginTop: 0,
      marginBottom: 20,
      textAlign: 'flex-start',
      color: 'black',
    },
    june: {
      fontFamily: 'Ysabeau',
      fontSize: 16,
      marginTop: 0,
      marginBottom: 20,
      textAlign: 'center',
      textDecorationLine: 'underline'
    },
    min_max_box: {
      width: 50
    },
    interests_column_1: {
      //width: 600,
      flexDirection: 'column',
      flexWrap: 'wrap'
    },
    interests_column_1_content: {
      flexDirection: 'row',
      flexWrap: 'wrap'
    },
    interests_title: {
      fontFamily: "Comfortaa",
      fontSize: 20,
      marginTop: 30
    },
    interests_input: {
      width: 300,
      height: 50
    },
    input_label: {
      fontFamily: "Comfortaa",
      marginTop: 20,
      marginBottom: 5,
      alignSelf: 'flex-start',
      color: 'black',
    },
    input_label_small: {
      fontFamily: "Comfortaa",
      marginBottom: 5,
      fontSize: 12,
      alignSelf: 'flex-start',
      color: 'black',
    },
    label_explainer: {
      fontFamily: "Ysabeau",
      marginBottom: 5,
      fontSize: 16,
      marginBottom: 20,
      width: 300,
      lineHeight: 20,
      color: 'black',
      textAlign: 'center'
    },
    drop_style: { 
      width: 50, 
      borderWidth: 1,
      padding: 10,
      paddingTop: 10,
      paddingBottom: 10,
      borderRadius: 12,
      backgroundColor: 'white',
      fontSize: 14,
      fontFamily: 'Comfortaa',
      color: '#575762',
      borderColor: '#bbbbc0'
    },
    drop_style_profile: { 
      width: 300, 
      borderWidth: 1,
      padding: 10,
      paddingTop: 10,
      paddingBottom: 10,
      borderRadius: 12,
      backgroundColor: 'white',
      fontSize: 14,
      fontFamily: 'Comfortaa',
      color: '#575762',
      borderColor: '#bbbbc0'
    },
    age_wrapper: {
      marginRight: 40
    },
    progress_wrapper: {
      flexDirection: 'row',
      alignSelf: 'center',
      margin: 'auto'
    },
    selected: {
      borderBottomColor: '#e32652'
    },
    progress_item: {
      paddingHorizontal: 10,
      marginHorizontal: 10,
      borderBottomWidth: 2,
    },
    progress_text: {
      fontFamily: "Ysabeau",
      fontSize: 12,
      color: 'black',
    },
    subscription_box: {
      borderRadius: 30,
      padding: 20,
      width: 300,
      backgroundColor: 'white',
      borderColor: 'white',
      borderWidth: 5
    },
    subscription_yearly: {
      backgroundColor: '#1c1c25'
    },
    subscription_monthly: {
      backgroundColor: 'white'
    },
    subscription_free: {
      backgroundColor: '#84977e'
    },
    subscription_coffees: {
      backgroundColor: 'yellow'
    },
    subscribe_wrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      marginLeft: 120,
      marginTop: 50
    },
    subscription_explainer: {
      marginTop: 10,
      fontFamily: 'Comfortaa',
      width: 400,
      marginRight: 20
    },
    club_button_text: {
      fontFamily: 'Ysabeau',
      color: 'black',
      fontSize: 20,
      textAlign: 'center'
    },
    continue_free: {
      fontFamily: 'Ysabeau',
      color: 'black',
      fontSize: 14,
      textAlign: 'center'
    },
    pricing_text: {
      fontFamily: 'Ysabeau',
      fontSize: 16,
      cursor: 'pointer',
      textAlign: 'right',
      marginBottom: 20,
      textDecorationLine: 'underline'
    },
    agree_text: {
      fontFamily: 'Ysabeau',
      width: '100%',
      flexDirection: 'row',
      width: '256px',
      color: 'black',
    },
    skip_wrap: {
      textAlign: 'flex-end',
      alignSelf: 'flex-end'
    },
    skip_wrap_center: {
      textAlign: 'center',
      alignSelf: 'center'
    },
    link: {
      fontFamily: 'Comfortaa',
      color: '#0b6665',
      fontSize: 12,
      marginLeft: 5,
      textDecorationLine: 'underline'
    },
    terms_label: {
      flexDirection: 'row'
    },
    register_link: {
      fontFamily: 'Comfortaa',
      color: '#7ce1e0',
      fontSize: 12,
      marginLeft: 5,
      textDecorationLine: 'underline'
    },
    error_text: {
      color: 'red',
      marginTop: 5,
      textAlign: 'flex-start',
      fontFamily: 'Ysabeau',
      fontSize: 14,
      alignSelf: 'flex-start'
    },
});

export {
    style
}