import { StyleSheet, Dimensions } from 'react-native';

let width = Dimensions.get('window').width;
let height = Dimensions.get('window').height;

let style_small = StyleSheet.create({
  footer_container: {
    height: 303,
    width: '100%',
    flexDirection: 'row',
    padding: 10,
    paddingTop: 20,
    borderTopWidth: 1,
    borderColor: '#dcd3d2'
  },
  logo_text: {
    fontFamily: 'Ysabeau',
    fontSize: '20px',
    /* textShadowColor: 'rgba(0, 0, 0, 1)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 5 */
  },
  main_columns: {
    flexDirection: 'column',
    //height: 50,
    flex: 2,
    //marginTop: 20,
    width: '50%',
    marginTop: 40
  },
  main_columns_first: {
    width: 168,
    flex: 'unset',
    height: 303,
    justifyContent: 'space-between'
  },
  one_column: {
    flexDirection: 'column',
    //flex: 1
  },
  footer_link: {
    fontFamily: 'Ysabeau',
    textDecorationLine: 'underline',
    fontSize: 14,
    marginBottom: 10
  },
  contact_link: {
    fontFamily: 'Ysabeau',
    fontSize: 14,
    marginBottom: 10
  },
  copyrite: {
    marginTop: 0,
    marginBottom: 20,
    width: 200
  },
  footer_socials: {
    flexDirection: 'row'
  },
  social_insta: {
    marginRight: 10
  }
});

let style_normal = StyleSheet.create({
  footer_container: {
    height: 200,
    width: '100%',
    flexDirection: 'row',
    padding: 40,
    borderTopWidth: 1,
    borderColor: '#dcd3d2'
  },
  logo_text: {
    fontFamily: 'Ysabeau',
    fontSize: '30px',
    borderLeftWidth: 5,
    borderColor: '#201e1e',
    paddingLeft: 10,
    lineHeight: 30
    /* textShadowColor: 'rgba(0, 0, 0, 1)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 5 */
  },
  main_columns: {
    flexDirection: 'row',
    height: 50,
    flex: 2,
    marginTop: 20
  },
  one_column: {
    flexDirection: 'column',
    flex: 1
  },
  footer_link: {
    fontFamily: 'Ysabeau',
    textDecorationLine: 'underline',
    fontSize: 14,
    marginBottom: 10
  },
  contact_link: {
    fontFamily: 'Ysabeau',
    fontSize: 14,
    marginBottom: 10
  },
  copyrite: {
    marginTop: 40
  },
  footer_socials: {
    flexDirection: 'row'
  },
  social_insta: {
    marginRight: 10
  }
});

let style = width <= 600 ? style_small : style_normal;

export {
  style
}