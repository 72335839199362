import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { StyleSheet, View, TouchableOpacity, Platform, Image, Picker, Dimensions } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import { useNavigate, useParams } from 'react-router-dom';
import { Text, Check } from '../../components';
import { Profile, Chat, Modal } from '../../containers';
import UserController from '../../controllers/userController';
import { style } from './style';
import { Video } from 'expo-av';
import Icon from 'react-native-vector-icons/FontAwesome';
import { global_style } from '../../../global_style'
import AuthController from '../../controllers/authController';
import EventController from '../../controllers/eventController';
import { FontAwesome5 } from '@expo/vector-icons';
let window_width = Dimensions.get('window').width;
let window_height = Dimensions.get('window').height;

class YourMessages_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
      user: props.user,
      users_enabled_messaging_with_you: [],
      loading: true
    }

    this.openProfileBubble = this.openProfileBubble.bind(this);
    this.drawMessage = this.drawMessage.bind(this);
    this.openMessage = this.openMessage.bind(this);
    this.drawUser = this.drawUser.bind(this);

    this.updateMessaging = this.updateMessaging.bind(this);
  }

  async componentDidMount() {
    let users_enabled_messaging_with_you = await UserController.getUsersEnabledMessagingWithYou();
    let messages = await UserController.getAllUserMessages({})
    let hosts = await AuthController.getHosts();
    hosts = hosts.map(host=>host.user_id)
    let { user } = this.state;

    let my_user_id = await localStorage.getItem('user_id')

    console.log('messages', messages)

    let messaging = user.messaging;

    if(typeof messaging === 'string') {
      messaging = JSON.parse(messaging);
    }

    messaging = messaging ? messaging : {public: true, enabled_users: []}

    let keys = Object.keys(messaging);

    if(keys.indexOf('public') === -1) {
      messaging = {
        ...messaging,
        public: true
      }
    }

    if(keys.indexOf('enabled_users') === -1) {
      messaging = {
        ...messaging,
        enabled_users: []
      }
    }

    let public_messaging = messaging.public;
    let enabled_users = messaging.enabled_users;

    console.log('users_enabled_messaging_with_you', users_enabled_messaging_with_you)

    this.setState({
      hosts,
      my_user_id,
      public_messaging,
      enabled_users,
      mounted: true,
      messages,
      loading: false,
      users_enabled_messaging_with_you
    })
  }

  drawUser(user) {
    let profile = user.profile;

    if(typeof profile === 'string') {
      profile = JSON.parse(profile);
    }

    let {photo_url} = profile;
    
    return <TouchableOpacity onPress={()=>this.openMessage(user)} style={[style.attendee_bubble_enabled]}>
      <Image style={style.attendee_photo} source={{uri: photo_url ? photo_url : 'https://diningsocial-assets.s3.amazonaws.com/empty_profile.png'}} />
    </TouchableOpacity>
  }

  async openMessage(profile_attendee, index) {
      
    this.modal_ref.setContent(<Chat hosts={this.state.hosts} user={this.state.user} onPress={this.openMessage} attendee={profile_attendee} />, 600, '100vh', null)
 
    /* this.props.putInParent(!this.state.is_messaging_open ? 
      <View style={{zIndex: 10000000, position: 'fixed', width: '100vw', height: '100vh'}}>
            
      </View> : null); */

    let messages = this.state.messages;
    if(!this.state.is_messaging_open) {
      let { page } = this.state;
      page = page ? page : 0;
      console.log('before profile_attendee', profile_attendee)
      await UserController.markMessagesSeen({ from_user_id: profile_attendee.user_id })
      messages = await UserController.getAllUserMessages({ page })  
    }

    this.setState({
      messages,
      profile_attendee,
      is_messaging_open: !this.state.is_messaging_open
    });
  }

  openProfileBubble(profile_attendee) {
    this.modal_ref.setContent(<Profile onPress={this.openBubble} attendee={profile_attendee} />, 600, '100vh', null)
  }

  drawMessage(whole_message, index) {
    let { my_user_id } = this.state;
    let { message, message_date, user, seen, to_user_id, from_user_id } = whole_message;

    let photo_url = user && user.profile ? user.profile.photo_url : null;

    let milliseconds = message_date * 1000;
    let date = new Date(milliseconds);
    date = date.toLocaleString('en-US', {
      timeZone: 'America/New_York',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });

    return <TouchableOpacity onPress={()=>this.openMessage(user, index)} style={style.one_message_wrapper}>
      {!seen && from_user_id !== my_user_id ? <View style={{backgroundColor: 'red', height: 10, width: 10, borderRadius: 100}}></View> : null}
      <View style={{marginRight: 20}}>
        <View style={[style.attendee_bubble]}>
          <Image style={style.attendee_photo} source={{uri: photo_url ? photo_url : 'https://diningsocial-assets.s3.amazonaws.com/empty_profile.png'}} /></View>
      </View>
      <View style={style.chat_message_wrapper}>
        <View style={{}}>        
          <Text style={style.date_style}>{date}</Text>
          <Text style={style.message_style}>{message}</Text>
        </View>
      </View>
        <FontAwesome5 style={{justifyContent: 'center', marginLeft: '10px', alignSelf: 'center'}} name="caret-square-right" size={24} color="#2a98d2" />
      </TouchableOpacity>
  }

  drawPager() {
    let { page, messages, no_more_messages } = this.state;

    page = page ? page : 0;
    const goPage = async (page) => {
      this.setState({loading: true}, async () => {
        let messages = await UserController.getAllUserMessages({page})
        let no_more_messages = messages.length === 0;
        this.setState({
          messages: no_more_messages ? this.state.messages : messages,
          no_more_messages,
          page: no_more_messages ? this.state.page : page,
          loading: false
        });
      });
    }

    let page_element = <Text style={style.page_number}>{page+1}</Text>
    let prev = page !== 0 ? 
        <TouchableOpacity onPress={()=>goPage(page-1)}>
          <Text style={style.pager_button_enabled}><Icon color="white" name="arrow-left" size={20} /></Text>
        </TouchableOpacity> : 
        <Text style={style.pager_button_disabled}><Icon name="arrow-left" color={'white'} size={20} /></Text>;

    let next = messages.length === 3 && !no_more_messages ? 
        <TouchableOpacity onPress={()=>goPage(page+1)}>
          <Text style={style.pager_button_enabled}><Icon name="arrow-right" color="white" size={20} /></Text>
        </TouchableOpacity> : 
        <Text style={style.pager_button_disabled}><Icon name="arrow-right" color="white" size={20} /></Text>;

    return <View style={style.pager_wrapper}>
      {prev}{page_element}{next}
    </View>
  }

  async updateMessaging() {
    let { user, public_messaging } = this.state;
    let messaging = user.messaging;

    if(typeof messaging === 'string') {
      messaging = JSON.parse(messaging);
    }

    messaging = messaging ? messaging : {public: true, enabled_users: [], dining_users: []}

    messaging = {
      ...messaging,
      public: !public_messaging
    }

    user = {
      ...user,
      messaging
    }

    let data = {
      is_public: !public_messaging,
      enabled_users: messaging.enabled_users,
      dining_users: messaging.dining_users
    }
    console.log('userdata', data)
    await UserController.updateMessaging(data);

    this.setState({
      user,
      public_messaging: !public_messaging
    })
  }

  render() {
    let { mounted, messages, users_enabled_messaging_with_you, loading, public_messaging } = this.state;

    return mounted ? 
    <View>
      <Modal ref={node=>this.modal_ref=node} putInParent={this.props.putInParent} />
        <View style={[style.dashboard_panel, style.profile_panel, {paddingBottom: 100, alignItems: 'center', alignContent: 'center', justifyContent: 'center'}]}>
          <Text style={style.people_text}>People that enabled messaging with you</Text>
          <View style={style.enabled_with_you_wrapper}>
            {users_enabled_messaging_with_you.map(this.drawUser)}
          </View>
          <View>
            <Text style={{paddingTop: 20}}>
              <Check label={<Text style={global_style.normal_text_dark_medium}>Let anyone I've met message me by default</Text>} onCheck={this.updateMessaging} checked={public_messaging} />
            </Text>
          </View>
          <Text style={style.section_title}>Messages</Text>
            {messages && messages.length ? 
            <>
              {this.drawPager()}
              {loading ? <View style={{width: 60, height: 60, alignSelf: 'center', paddingVertical: 100}}>
                  <Progress.CircleSnail size={60} backgroundColor="white" fill='white' color={'#e32652' } />
                </View> :
              <View style={style.dinners_wrapper}>
                {messages.map(this.drawMessage)}
              </View>}
              {this.drawPager()}
            </> :
            <View style={[style.a_column, {alignItems: 'center', alignContent: 'center', justifyContent: 'center'}]}>
              <Text style={style.text_normal}>You don't have any messges yet. Make sure to enable messaging with specific members you met at events. </Text>
              <TouchableOpacity onPress={()=>this.props.navigate('/dashboard/my_events')}><Text style={style.go_to_button}>Go to Your Events</Text></TouchableOpacity>
            </View>}
        </View>
      </View> : <View style={{width: 60, height: 60, alignSelf: 'center', paddingVertical: 100}}>
                  <Progress.CircleSnail size={60} backgroundColor="white" fill='white' color={'#e32652' } />
                </View>;
  }

}

function YourMessages(props) {
  if(Platform.OS === 'web') {
    let navigate = useNavigate();
    let { to_user } = useParams();
    return  <YourMessages_Base {...props} to_user={to_user} navigate={navigate} />
  } else {
    return  <YourMessages_Base {...props} navigate={props.navigation.push}  />
  }
}

export default YourMessages;
