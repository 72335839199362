import { config } from '../../config';
import { Platform } from 'react-native';
import Constants from 'expo-constants';
import moment from 'moment';
import AsyncStorage from '@react-native-async-storage/async-storage';
import io from 'socket.io-client';

let sockets = {};

export default class UtilitiesController {

  static getDatesOfWeek = (num_weeks) => {
    var currentDate = new Date();
    var currentDay = currentDate.getDay(); // Get the current day (0 = Sunday, 1 = Monday, ..., 6 = Saturday)

    currentDate.setDate(currentDate.getDate() + (7 * num_weeks));

    // Check if it's Sunday after 6:30 PM
    if (currentDay === 0 && currentDate.getHours() >= 18 && currentDate.getMinutes() >= 30) {
        currentDate.setDate(currentDate.getDate() + 1); // Move to Monday of next week
    }

    currentDay = currentDate.getDay();

    // Calculate the start date of the week (Monday)
    var startDate = new Date(currentDate);
    startDate.setDate(currentDate.getDate() - currentDay + (currentDay === 0 ? -6 : 1));

    var dates = [];
    for (var i = 0; i < 7; i++) {
        var date = new Date(startDate);
        date.setDate(startDate.getDate() + i);
        var dayOfWeek = date.getDay();
        var formattedDate = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
        //dayOfWeek === 3 || 
        if (dayOfWeek === 6 || dayOfWeek === 0) {
          dates.push(formattedDate);
        }
    }

    return dates;
  }

  static postExceptionWrapper = (fn) => async (url, body, internal, clearContentType) => {
    try {
      return await fn(url, body, internal, clearContentType).catch((err) => {
        throw err;
      });
    } catch (err) {
      console.log('endpoint err', err);
      return await UtilitiesController.getError_SaveError({err, url, body, internal, clearContentType});
    }
  };

  static getError_SaveError = async ({err, url, body, internal, clearContentType}) => {
    let error = { success: false }

    if(err.app_error) {
        error = {...error, error: err.app_error }

        if(err.already_exists) {
            error = { ...error, data: { already_exists: true } }
        }
    } else {
        error = { ...error, error: err.message ? err.message : JSON.stringify(err) }
    }

    // TODO: call endpoint to save error

    return error;
  }

  static getSocket({query, name, notification}) {

    let the_socket = sockets[name];

    if(!the_socket || !the_socket.connected) {
      let chat_url = UtilitiesController.getUrl('', !notification, notification);

      let options = {
        query,
      }

      if(chat_url.indexOf('localhost') === -1) {
        options = {
          ...options,
          path: notification ? '/notifications' : '/chat'
        }
      }

      console.log('chat_url', chat_url, options);

      the_socket = io(chat_url, options);

      the_socket.on("connect_error", (err) => {
        //console.log(`connect_error due to ${err.message}`);
        //console.log(err)
      });

      sockets = {
        ...sockets,
        [name]: the_socket
      }
    }

    return the_socket;
  }

  static getUrl(url, useChat = false, useNotif = false) {
    if(useChat) {
      return `${config.CHAT_URL}${url}`
    } else if(useNotif) {
      return `${config.NOTIF_URL}${url}`
    } else {
      return `${config.API_URL}${url}`
    }
  }

  static getChatUrl() {
    return UtilitiesController.getUrl('', true);
  }

  static async getPets(get_new) {
    let pets_array_string = await AsyncStorage.getItem('pets');
    let pets_array        = pets_array_string ? JSON.parse(pets_array_string) : [];
    return pets_array;
  }

  static async getToken() {
    let token = await AsyncStorage.getItem('token');
    //let token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiNWUxNGFhMTNmYWJkZmQ3MGJjODRmNDkzIiwicm9sZSI6IkNMSUVOVCIsImlzX3ZlZyI6ZmFsc2UsImlzX2luc3RhbnQiOmZhbHNlLCJpYXQiOjE2NDQ5NjAzMjYsImV4cCI6MTY1MjczNjMyNn0.MHiO1JI6HiW6Y7RCsVkezyRnoKl66tjuBJyWTU76src';

    return token;
  }

  static post = UtilitiesController.postExceptionWrapper(async (url, body, internal, clearContentType) => {
      if(internal) url = UtilitiesController.getUrl(url, false);

      let token = await UtilitiesController.getToken();

      let headers = {
          Accept: 'application/json',
          Authorization: token
      }

      if(!clearContentType) {
        headers = {
          ...headers,
          'Content-Type': 'application/json',
        }
      } else {
        headers = {
          ...headers,
          'Content-Type': 'multipart/form-data'
        }
      }

      let response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(body)
      });

      console.log('response', response);

      let res = await response.json();

      console.log('res', res);

      if(!res.success) {
        if(res.message === 'jwt expired') {
          res.error = 'Your session has expired. Please log in.'
        } else {
          res.error = res.error;
        }
      }

      if (!res || !res.success) {
        // console.log(response)
      }

      // console.log('POST Response', res);

      return res;
  })

  static put = UtilitiesController.postExceptionWrapper(async (url, body, internal, clearContentType) => {
    if(internal) url = UtilitiesController.getUrl(url, false);

    let token = await UtilitiesController.getToken();
    console.log('PUT to: ', url);
    console.log('PUT BODY', body);
    console.log('token', token);

    let headers = {
        Accept: 'application/json',
        Authorization: token
    }

    if(!clearContentType) {
      headers = {
        ...headers,
        'Content-Type': 'application/json',
      }
    } else {
      headers = {
        ...headers,
        'Content-Type': 'multipart/form-data'
      }
    }

    let response = await fetch(url, {
      method: 'PUT',
      headers,
      body: JSON.stringify(body)
    });

    console.log('response', response);

    let res = await response.json();

    console.log('res', res);

    if(!res.success) {
      if(res.message === 'jwt expired') {
        res.error = 'Your session has expired. Please log in.'
      } else {
        res.error = res.error;
      }
    }

    if (!res || !res.success) {
      // console.log(response)
    }

    // console.log('PUT Response', res);

    return res;
  })

  static async get(url, data, internal) {
    try {

      if(internal) url = UtilitiesController.getUrl(url, false);

      let token = await UtilitiesController.getToken();

      let response = await fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: token
        }
      });

      // console.log('GET to: ', url);
      // console.log('token: ', token);

      let res = await response.json();

      // console.log('GET response: ', res);

      if(!res.success) {
        if(res.message === 'jwt expired') {
          res.error = 'Your session has expired. Please log in.'
        }
      }

      if (!res || !res.success) {
        // console.log(response)
      }

      return res;
    } catch(err) {
      console.log(err);
      // alert('The server is updating. Please wait a few seconds.', err);
    }
  }

  static async update(url, body, internal) {
    try {

      if(internal) url = UtilitiesController.getUrl(url, false);

      // console.log('UPDATE to: ', url);
      // console.log('UPDATE BODY', body);

      let token = await UtilitiesController.getToken();

      let response = await fetch(url, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: token
        },
        body: JSON.stringify(body)
      });

      let res = await response.json();

      if(!res.success) {
        if(res.message === 'jwt expired') {
          res.error = 'Your session has expired. Please log in.'
        } else {
          res.error = res.error
        }
      }

      // console.log('UPDATE Response', res);

      return res;
    } catch(err) {
      console.log(err);
    }
  }

  static futch = (url, opts={}, onProgress) => {
    // console.log(url, opts)
    return new Promise( (res, rej)=>{
        var xhr = new XMLHttpRequest();
        xhr.open(opts.method || 'get', url);
        for (var k in opts.headers||{})
            xhr.setRequestHeader(k, opts.headers[k]);
        xhr.onload = e => res(e.target);
        xhr.onerror = rej;
        if (xhr.upload && onProgress)
            xhr.upload.onprogress = onProgress; // event.loaded / event.total * 100 ; //event.lengthComputable
        xhr.send(opts.body);
    });
  }

  static async uploadFile(image, values, url) {
    return new Promise(async (resolve, reject) => {
      try {
        const data = new FormData();

        if(image) {
          data.append('file', {
            uri: image,
            type: Platform.OS === 'android' ? 'image/jpeg' : image.type,
            name: 'image_from_care_mobile',
          });
        }

        if(values) {
          data.append('values', JSON.stringify(values));
        }

        let token = await UtilitiesController.getToken();

        this.futch(UtilitiesController.getUrl(url), {
          method: 'post',
          headers: {
            Authorization: token
          },
          body: data
        }, (progressEvent) => {
          let progress = progressEvent.loaded / progressEvent.total;
          console.log('progress', progress)
        }).then(async (res, err) => {
          if(err) {
            console.log("err", err);
            reject(err);
          } else {
            console.log("upload response", res._response);
            let response = JSON.parse(res._response)
            resolve(response);
          }
        });
      } catch(err) {
        reject({
          success: false,
          error: err
        });
      }
    });
  }

  static async uploadImageWithValues(data, url) {
    return new Promise(async (resolve, reject) => {
      try {
        let token = await UtilitiesController.getToken();

        url = UtilitiesController.getUrl(url);

        console.log('url', url)

        this.futch(url, {
          method: 'post',
          headers: {
            Authorization: token
          },
          body: data
        }, (progressEvent) => {
          let progress = progressEvent.loaded / progressEvent.total;
          console.log('progress', progress)
        }).then(async (res, err) => {
          if(err) {
            console.log("err", err);
            reject(err);
          } else {
            console.log("upload response", res._response);
            let response = JSON.parse(res._response)
            resolve(response);
          }
        });
      } catch(err) {
        reject({
          success: false,
          error: err
        });
      }
    });
  }

  /* static async registerForPushNotificationsAsync() {
    try {
      let token;
      let device_token = '';
      if (Constants.isDevice) {
        const { status: existingStatus } = await Notifications.getPermissionsAsync();
        let finalStatus = existingStatus;

        console.log('existingStatus', existingStatus);

        if (existingStatus !== 'granted') {
          console.log('getting to ask');
          const { status } = await Notifications.requestPermissionsAsync();
          finalStatus = status;
        }
        if (finalStatus !== 'granted') {
          // alert('Failed to get push token for push notification!');
          return;
        }
        token = (await Notifications.getExpoPushTokenAsync({ experienceId: '@teletails/dodovet' }));

        let device_token_response = (await Notifications.getDevicePushTokenAsync());
            device_token = device_token_response && device_token_response.data ? device_token_response.data : '';

        if (device_token) {
          if (Platform.OS == 'android' || Platform.OS === 'ios') {
            let intercom_package = require('@intercom/intercom-react-native');
            let Intercom         = intercom_package && intercom_package.default ? intercom_package.default : null;
            if (Intercom) {
              Intercom.sendTokenToIntercom(device_token);
            }
          }
        }
      } else {
        //alert('Must use physical device for Push Notifications');
      }

      if (Platform.OS === 'android') {
        Notifications.setNotificationChannelAsync('default', {
          name: 'default',
          importance: Notifications.AndroidImportance.MAX,
          vibrationPattern: [0, 250, 250, 250],
          lightColor: '#FF231F7C',
        });
      }

      let expo_token = token && token.data ? token.data : '';

      let notification_token_data = {
        expo_token: expo_token,
        device_token: device_token
      }

      return notification_token_data;
    } catch (err) {
      console.log('err', err);
      return null;
    }
  } */

  static async addressPush(navigate, link) {
    // ex: link = "/consultation_video_appointment/60b4f64bb40021593f93a954"
    if(link) {

      await AsyncStorage.removeItem('push_link');

      let tokens = link.split('/');
      let params = {}

      if(tokens.length > 2) {
        link = `/${tokens[1]}`;
        params = { consultation_id: tokens[2]}
      }

       navigate(link, params)
    }

  }

  /* static async checkNotifications() {
    try {
      if(Platform.OS !== 'web') {
        console.log('checking notifs')
        let token = await UtilitiesController.getToken();
        console.log('checking notifs', token)
        if(token) {
          let pushupdated = await AsyncStorage.getItem('pushupdated');

          console.log('pushupdated', pushupdated)
          let check_for_push = !pushupdated;

          if(!check_for_push) {
            let updated_date = new Date(pushupdated);
            let now = moment();
            let days = now.diff(updated_date, "days");
            check_for_push = days > 3;
          }
          let push_updated = new Date();
          check_for_push = true;
          console.log('push_updated', push_updated)
          console.log('check_for_push', check_for_push)

          if(check_for_push) {
            if (Constants.isDevice) {
              UtilitiesController.registerForPushNotificationsAsync().then(async notification_token_data => {

                /*
                  pushtoken Object {
                    "data": "ExponentPushToken[qZ1iKPAIJhC7n8EzdqyZD2]",
                    "type": "expo",
                  }
                  pushenabled true
                

                let expo_token   = notification_token_data.expo_token;
                let device_token = notification_token_data.device_token;
                let push_enabled = true;

                let request_data = {
                  push_enabled: push_enabled,
                  expo_token: expo_token
                }

                if (Platform.OS == 'android') {
                  request_data['android_device_token'] = device_token;
                }

                await UtilitiesController.post('/v4/api/user/update_push', request_data, true)

                await AsyncStorage.setItem('pushupdated', push_updated.toString());
                await AsyncStorage.setItem('pushenabled', push_enabled.toString());
              });
            } else {
              // alert('Must use physical device for Push Notifications');
            }
          }
        }
      }
    } catch(err) {
      console.log('err', err);
    }
  } */

  static async getLinkPathAndParams(push_link) {
    try {
      console.log('push_link', push_link)
      let link_tokens = push_link.split('/');
      let route, params_array;

      console.log('link_tokens', link_tokens)
      console.log('push_link[0]', push_link[0])

      if(push_link[0] === "/") {
        route = link_tokens[1];
        params_array = link_tokens.slice(2, link_tokens.length)
      } else {
        route = link_tokens[0];
        params_array = link_tokens.slice(1, link_tokens.length)
      }

      console.log('route, params_array', route, params_array)

      switch(route) {
        case 'consultations_completed':
        case 'consultation_video_appointment':
          if(params_array.length)
            return { route, params: { consultation_id: params_array[0] } };
          else
            return {route, params: {}};
          break;
        case 'article':
          if(params_array.length)
            return { route, params: { article_id: params_array[0] } };
          else
            return {route, params: {}};
          break;
        default: break;
      }
    } catch(error) {
      return null
    }
  }

  static getTodayString = () => {
    return (new Date()).toLocaleDateString()
  }

  static isToday = (date_to_check) => {
    return UtilitiesController.getTodayString() === date_to_check;
  }
}
