import { StyleSheet, Dimensions } from 'react-native';

let width = Dimensions.get('window').width;
let height = Dimensions.get('window').height;

let global_style = {
  plans_wrapper: {
    flexDirection: 'row', 
    width: 900, 
    justifyContent: 'space-between', 
    margin: 'auto'
  },
  plan_cap: {
    flexDirection: 'column', 
    backgroundColor: '#222326', 
    borderTopLeftRadius: 10, 
    borderTopRightRadius: 10,
    alignContent: 'center', 
    alignItems: 'center', 
    padding: 20,
    //border: 'solid 3px #e32652'
  },
  join_button_free: {
    backgroundColor: '#e32652',
  },
  join_button_monthly: {
    backgroundColor: '#e32652',
  },
  join_button_yearly: {
    backgroundColor: '#e32652',
  },
  legal_text: {
    fontFamily: 'Ysabeau',
    fontSize: 14,
    paddingVertical: 10,
    backgroundColor: 'black',
    color: 'white'
  },
  legal_title: {
    fontFamily: 'Comfortaa',
    fontSize: 14
  },
  intro_text: {
    fontFamily: 'Comfortaa',
    fontSize: 16,
    lineHeight: 26,
    backgroundColor: 'black',
    color: 'white',
    textAlign: 'center'
  },
  intro: {
    marginBottom: 50,
    flexDirection: 'row',
    margin: 'auto',
    width: 850,
    justifyContent: 'center',
    flexDirection: 'column',
  },
  restaurant_title: {
    fontFamily: 'Ysabeau',
    fontSize: 50,
    paddingVertical: 50,
    textAlign: 'center',
    color: 'white',
    backgroundColor: 'black'
  },
  one_plan: {
    flexDirection: 'column', 
    width: 280, 
    alignContent: 'center',
    margin: 'auto',
    marginBottom: 40
  },
}

let style_small = StyleSheet.create({
  ...global_style,
  intro: {
    marginBottom: 50,
    flexDirection: 'row',
    margin: 50,
    justifyContent: 'center',
    flexDirection: 'column',
  },
  plans_wrapper: {
    flexDirection: 'column', 
    //width: 900, 

  },
  explainer_section: {
    flexDirection: 'column'
  },
  restaurants_title: {
    fontFamily: 'Ysabeau',
    fontSize: '50px',
    color: 'black',
    width: '100%',
    flexDirection: 'row',
    padding: 20,
    marginBottom: 80,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  restaurants_wrapper: {
    flexDirection: 'column'
  },
  restaurant_wrapper: {
    flexDirection: 'column'
  },
  memberships: {
    paddingTop: 50,
    flexDirection: 'column',
    margin: 'auto',
    justifyContent: 'center',
  },
  whole_wrapper: {
    backgroundColor: 'black'
    /* "backgroundColor":"#ffffff","opacity":"1","backgroundImage":"linear-gradient(135deg, #efebea 15%, transparent 15%), linear-gradient(225deg, #efebea 15%, transparent 15%), linear-gradient(45deg, #efebea 15%, transparent 15%), linear-gradient(315deg, #efebea 15%, #ffffff 15%)","backgroundPosition":"0px 0, 0px 0, 0 0, 0 0","backgroundSize":"40px 40px","backgroundRepeat":"repeat", */
    //borderTopWidth: 3,
    //borderColor: '#efebea'
  },
  membership: {
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: 10,
    borderWidth: 3,
    borderColor: '#1e1007',
    width: 270
    //margin: 30
  },
  white_panel: {
    //position: 'absolute',
    height: 272,
    backgroundColor: 'white',
    width: 200,
    //left: 200,
    //borderRadius: 30
  },
  panel_monthly: {
    borderColor: 'black',
    borderWidth: 3
  },
  panel_free: {
    borderColor: '#84977e',
    borderWidth: 3
  },
  panel_yearly: {
    borderColor: '#1e1007',
    borderWidth: 3
  },
  membership_left: {
    flex: 1,
    padding: 50,
    /* borderTopLeftRadius: 26,
    borderTopRightRadius: 26, */
    width: 270,
    alignItems: 'center'
  },
  left_wrapper: {

    alignItems: 'center',

  },
  free_membership: {
    borderColor: '#84977e',
    marginBottom: 50,

  },
  monthly_membership: {
    borderColor: 'black',
    marginBottom: 50,
  },
  yearly_membership: {
    
  },
  membership_left_free: {
    backgroundColor: '#84977e',
    borderRadius: 10
  },
  membership_left_monthly: {
    backgroundColor: 'black',
    "backgroundColor":"#332c28","opacity":"1","backgroundImage":"linear-gradient(135deg, black 25%, transparent 25%), linear-gradient(225deg, black 25%, transparent 25%), linear-gradient(45deg, black 25%, transparent 25%), linear-gradient(315deg, black 25%, #332c28 25%)","backgroundPosition":"80px 0, 80px 0, 0 0, 0 0","backgroundSize":"80px 80px",
    borderRadius: 10
  },
  membership_left_yearly: {
    //backgroundColor: '#1e1007',
    "backgroundColor":"black","opacity":"1","backgroundImage":"linear-gradient(135deg, #1c1c25 25%, transparent 25%), linear-gradient(225deg, #1c1c25 25%, transparent 25%), linear-gradient(45deg, #1c1c25 25%, transparent 25%), linear-gradient(315deg, #1c1c25 25%, black 25%)","backgroundPosition":"80px 0, 80px 0, 0 0, 0 0","backgroundSize":"80px 80px","backgroundRepeat":"repeat",
    borderRadius: 10
  },
  membership_right: {
    flex: 1,

    //margin: 10
   
    //padding: 20
  },
  membership_title: {
    fontFamily: 'Comfortaa',
    color: "#fbf5f0",
    fontSize: 30,
    textAlign: 'center',
    marginBottom: 14
  },
  membership_price: {
    fontFamily: 'Ysabeau',
    color: 'white',
    textAlign: 'center',
    fontSize: 20
  },
  membership_explainer: {
    fontFamily: 'Ysabeau',
    color: '#33342f',
    marginTop: 30,
    width: 250,
    fontSize: 16
  },
  membership_explainer_add: {
    fontFamily: 'Ysabeau',
    color: '#33342f',
    width: 220,
    fontSize: 16,
    margin: 20,
    textAlign: 'center',
    height: 100
  },
  membership_explainer_title: {
    fontFamily: 'Comfortaa',
    fontSize: 26,
    color: '#33342f'
  },
  join_button: {

    paddingVertical: 10,
    paddingHorizontal: 20,
    width: 200,
    borderRadius: 30,
    //marginTop: 40,
    marginBottom: 20,
    marginTop: 30,
    alignSelf: 'center'
  },
  join_button_text: {
    fontFamily: 'Ysabeau',
    fontSize: 20,
    textAlign: 'center',
    color: '#fbf5f0'
  },
  bottom_breaker: {
    height: 50
  },
  link: {
    fontFamily: 'Comfortaa',
    color: '#0b6665',
    fontSize: 12,
    textDecorationLine: 'underline'
  },
  legal: {
    marginTop: 50,

    flexDirection: 'row',
    margin: 'auto',
    justifyContent: 'center',
    marginBottom: 100,
    flexDirection: 'column',
    backgroundColor: 'white'
  },
});

let style_normal = StyleSheet.create({
  ...global_style,
  explainer_section: {
    flexDirection: 'column'
  },
  restaurants_title: {
    fontFamily: 'Ysabeau',
    fontSize: '50px',
    color: 'black',
    width: '100%',
    flexDirection: 'row',
    padding: 20,
    marginBottom: 80,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  restaurants_wrapper: {
    flexDirection: 'column'
  },
  restaurant_wrapper: {
    flexDirection: 'column'
  },
  memberships: {
    paddingTop: 50,
    flexDirection: 'row',
    margin: 'auto',
    width: 1100,
    justifyContent: 'center',
  },
  whole_wrapper: {
    backgroundColor: 'black'
    /* "backgroundColor":"#ffffff","opacity":"1","backgroundImage":"linear-gradient(135deg, #efebea 15%, transparent 15%), linear-gradient(225deg, #efebea 15%, transparent 15%), linear-gradient(45deg, #efebea 15%, transparent 15%), linear-gradient(315deg, #efebea 15%, #ffffff 15%)","backgroundPosition":"0px 0, 0px 0, 0 0, 0 0","backgroundSize":"40px 40px","backgroundRepeat":"repeat", */
    //borderTopWidth: 3,
    //borderColor: '#efebea'
  },
  membership: {
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: 10,
    borderWidth: 3,
    borderColor: '#1e1007'
    //margin: 30
  },
  white_panel: {
    //position: 'absolute',
    height: 272,
    backgroundColor: 'white',
    width: 200,
    //left: 200,
    //borderRadius: 30
  },
  panel_monthly: {
    borderColor: 'black',
    borderWidth: 3
  },
  panel_free: {
    borderColor: '#84977e',
    borderWidth: 3
  },
  panel_yearly: {
    borderColor: '#1e1007',
    borderWidth: 3
  },
  membership_left: {
    flex: 1,
    padding: 50,
    /* borderTopLeftRadius: 26,
    borderTopRightRadius: 26, */
    width: 270,
    alignItems: 'center'
  },
  left_wrapper: {

    alignItems: 'center',

  },
  free_membership: {
    borderColor: '#84977e',
    marginRight: 30
  },
  monthly_membership: {
    borderColor: 'black',
    marginRight: 30
  },
  yearly_membership: {
    
  },
  membership_left_free: {
    backgroundColor: '#84977e',
  },
  membership_left_monthly: {
    backgroundColor: 'black',
    "backgroundColor":"#332c28","opacity":"1","backgroundImage":"linear-gradient(135deg, black 25%, transparent 25%), linear-gradient(225deg, black 25%, transparent 25%), linear-gradient(45deg, black 25%, transparent 25%), linear-gradient(315deg, black 25%, #332c28 25%)","backgroundPosition":"80px 0, 80px 0, 0 0, 0 0","backgroundSize":"80px 80px",
  },
  membership_left_yearly: {
    //backgroundColor: '#1e1007',
    "backgroundColor":"black","opacity":"1","backgroundImage":"linear-gradient(135deg, #1c1c25 25%, transparent 25%), linear-gradient(225deg, #1c1c25 25%, transparent 25%), linear-gradient(45deg, #1c1c25 25%, transparent 25%), linear-gradient(315deg, #1c1c25 25%, black 25%)","backgroundPosition":"80px 0, 80px 0, 0 0, 0 0","backgroundSize":"80px 80px","backgroundRepeat":"repeat"
  },
  membership_right: {
    flex: 1,

    //margin: 10
   
    //padding: 20
  },
  membership_title: {
    fontFamily: 'Comfortaa',
    color: "#fbf5f0",
    fontSize: 30,
    textAlign: 'center',
    marginBottom: 14
  },
  membership_price: {
    fontFamily: 'Ysabeau',
    color: 'white',
    textAlign: 'center',
    fontSize: 20
  },
  membership_explainer: {
    fontFamily: 'Ysabeau',
    color: '#33342f',
    marginTop: 30,
    width: 250,
    fontSize: 16
  },
  membership_explainer_add: {
    fontFamily: 'Ysabeau',
    color: '#33342f',
    width: 220,
    fontSize: 16,
    margin: 20,
    textAlign: 'center',
    height: 100
  },
  membership_explainer_title: {
    fontFamily: 'Comfortaa',
    fontSize: 26,
    color: '#33342f'
  },
  join_button: {

    paddingVertical: 10,
    paddingHorizontal: 20,
    width: 200,
    borderRadius: 30,
    //marginTop: 40,
    marginBottom: 20,
    marginTop: 30,
    alignSelf: 'center'
  },
  join_button_text: {
    fontFamily: 'Ysabeau',
    fontSize: 20,
    textAlign: 'center',
    color: '#fbf5f0'
  },
  bottom_breaker: {
    height: 200
  },
  legal_title: {
    fontFamily: 'Comfortaa',
    fontSize: 14
  },
  link: {
    fontFamily: 'Comfortaa',
    color: '#0b6665',
    fontSize: 12,
    textDecorationLine: 'underline'
  },
  legal: {
    marginTop: 50,

    flexDirection: 'row',
    margin: 'auto',
    width: 850,
    justifyContent: 'center',
    marginBottom: 100,
    flexDirection: 'column',
    backgroundColor: 'white'
  }
});

let style = width < 1000 ? style_small : style_normal;

export {
  style
}