import { StyleSheet, Dimensions } from 'react-native';

let width = Dimensions.get('window').width;
let height = Dimensions.get('window').height;

let global_style = {
  events_wrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: width < 700 ? '100%' : '63vw'
  },
  summary_info_bubble_popup: {

  },
  summary_visit_restaurant_text: {
    fontSize: 14,
    fontFamily: 'Comfortaa',
    color: 'white',
    marginLeft: width < 700 ? 0 : 20,
    textAlign: width < 700 ? 'center': 'center',
    marginTop: 7
  },
  summary_image_main: {
    width: width > 500 ? '100%' : '100%',
    margin: 'auto',
    height: width > 500 ? 150 : 200,
    /* borderRadius: 3,
    borderWidth: 2 */
  },
  summary_one_event_user: {
    width: width < 700 ? 'calc(100vw - 20px)' : '250px',
    flexDirection: 'column',
    backgroundColor: '#4a443d',
    borderRadius: 5,
    padding: 10,
    height: 'auto',
    marginBottom: 20,
    marginRight: width < 700 ? 0 : 10
  },
  summary_one_event: {
    width: width < 700 ? 'calc(100vw - 20px)' : '250px',
    flexDirection: 'column',
    backgroundColor: 'black',
    borderRadius: 5,
    padding: 10,
    height: 'auto',
    marginBottom: 20,
    marginRight: width < 700 ? 0 : 10
  },
  summary_event_day_text_date: {
    fontFamily: 'Ysabeau',
    textAlign: 'center',
    fontSize: width < 700 ? 22 : 16,
    marginLeft: 20,
    color: 'white',
  },
  summary_event_day_text: {
    fontFamily: 'Ysabeau',
    fontSize: width < 700 ? 22 : 18,
    marginTop: 10,
    lineHeight: 20,
    textAlign: 'center',
    color: 'white',
    overflowWrap: 'anywhere',
    height: 40
  },
  summary_description: {
    fontFamily: 'Comfortaa',
    fontSize: 16,
    lineHeight: 24,
    marginLeft: width < 700 ? 0 : 20,
    color: 'white',
    textAlign: width < 700 ? 'center' : 'left',
    marginBottom: width < 700 ? 20 : 0
  },
  visit_restaurant: {
    /* borderColor: 'black',
    borderWidth: 3,
    width: 360,
    marginTop: 10,
    marginLeft: 10 */
  },
  visit_restaurant_text: {
    fontSize: 24,
    fontFamily: 'Comfortaa',
    //borderColor: 'white',
    //borderWidth: 1,
    //backgroundColor: 'black',
    //textAlign: 'center',
    color: 'white',
    //paddingHorizontal: 10,
    //paddingBottom: 3,
    //paddingTop: 1,
    marginLeft: width < 700 ? 0 : 20,
    textAlign: width < 700 ? 'center': 'left',
    marginTop: width < 700 ? 10 : 40,
  },
  sugest_go_button: {
    backgroundColor: 'black', 
    borderRadius: 5,
    color: 'white',
    fontFamily: 'Ysabeau',
    fontSize: 14,
    paddingHorizontal: 10
  },
  suggest_description: {
    fontFamily: 'Ysabeau',
    fontSize: 18,
    marginHorizontal: 20,
    marginTop: 83,
    lineHeight: 24
  },
  description: {
    fontFamily: 'Comfortaa',
    fontSize: 16,
    lineHeight: 24,
    marginLeft: width < 700 ? 0 : 20,
    color: 'white',
    textAlign: width < 700 ? 'center' : 'left',
    marginBottom: width < 700 ? 20 : 0
  },
  calendar_wrapper: {
    justifyContent: 'center'
  },
  you_goin: {
    fontFamily: 'Comfortaa',
    backgroundColor: '#1fb411',
    color: 'white',
    fontSize: 14,
    lineHeight: 30,
    borderRadius: 3,
    paddingHorizontal: 10,
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  action_rsvp: {
    alignContent: 'center',
    alignItems: 'center'
  },
  one_bucket: {
    //backgroundColor: '#f7f8fc',
    borderColor: 'white',
    borderWidth: 1,
    flexDirection: 'column',
    borderRadius: 2,
    padding: 5,
    width: '100%',
    minHeight: 50,
    justifyContent: 'space-between',
  },
  attendee_bubble: {
    borderRadius: 100,
    borderColor: '#1fb411',
    borderWidth: 2,
    width: 40,
    height: 40,
    justifyContent: 'center',
    margin: 5,
    cursor: 'pointer',
    position: 'relative'
  },
}

const style_small = StyleSheet.create({
  ...global_style,
  thumbs: {
    fontSize: 30,
    marginRight: 7
  },
  info_button: {
    backgroundColor: "#20cac8",
    paddingHorizontal: 9,
    paddingVertical: 1,
    borderRadius: 100,
    color: 'white',
    fontFamily: 'Ysabeau',
    fontSize: 14,
    marginLeft: 10
  },
  info_bubble: {
    width: 200,
    height: 160,
    backgroundColor: 'black',
    zIndex: 10000000,
    position: 'absolute',
    padding: '20px',
    borderWidth: 1,
    borderRadius: 5,
    left: (width / 2) - 140,
    top: '15px'
  },
  overlay: {
    flex: 1,
    top: 0, 
    left: 0,
    opacity: .3,
    backgroundColor: 'black',
    width: '100vw',
    height: '100%',
    position: 'fixed',
    zIndex: 100000
  },
  modal_text: {
    fontFamily: 'Comfortaa',
    fontSize: 12,
    lineHeight: 20
  },
  modal_text_title: {
    fontFamily: 'Comfortaa',
    fontSize: 16,
    lineHeight: 20,
    marginBottom: 15
  },
  canceled_text: {
    fontFamily: 'Ysabeau',
    fontSize: 20
  },
  display_date: {
    fontFamily: 'Comfortaa',
    fontSize: 30,
    textAlign: 'center'
  },
  info_bubble_text: {
    fontFamily: 'Ysabeau',
    fontSize: 14,
    backgroundColor: '#5ac3c4',
    width: 20,
    padding: 2,
    paddingTop: 0,
    paddingBottom: 4,
    textAlign: 'center',
    borderRadius: 100,
    alignSelf: 'flex-end',
    lineHeight: 16
  },
  info_bubble_popup: {
    //marginVertical: 10,
    backgroundColor: 'black',
    //borderRadius: 10,
    padding: 10
  },
  attendee_wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  attendee_text: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    lineHeight: 26,
  },
  collapsable_burger: {
    display: 'none'
  },
  a_column: {
    flexDirection: 'column'
  },
  a_row: {
    flexDirection: 'row'
  },
  main_dash_wrapper: {
    flexDirection: 'row'
  },
  page_wrapper: {
    flex: 1, flexDirection: 'column', position: 'relative',
    backgroundColor: 'white',
    paddingBottom: 200
  },
  slider_text: {
    fontSize: 20,
    fontWeight: '500',
    color: 'white',
    marginBottom: 5
  },
  logo_button: { paddingTop: 20, paddingBottom: 20, flexDirection: 'row', justifyContent: 'space-between' },
  main_text_wrapper: {
    position: 'absolute',
    left: 0,
    top: 100,
    width: '100%',
    alignContent: 'center', 
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  main_text_band: {
    width: '100%',
    position: 'absolute',
    top: 0,
    height: 500,
    backgroundColor: 'black',
    opacity: .9
  },
  main_callout: {
    fontFamily: 'Ysabeau',
    fontSize: '100px',
    color: 'white',

    textShadowColor: 'rgba(0, 0, 0, 1)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 5
  },
  main_sub_callout: {
    fontFamily: 'Ysabeau',
    fontSize: '50px',
    color: 'white',

    textShadowColor: 'rgba(0, 0, 0, 1)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 5
  },
  main_image: { 
    height: 500 ,
    width: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    borderWidth: 5
  },
  secondary_title: {
    fontFamily: 'Comfortaa',
    fontSize: '50px',
    color: 'black',
    width: '100%',
    flexDirection: 'row',
    padding: 20,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderBottomWidth: 1,
    borderTopWidth: 1
  },
  section_title: {
    fontFamily: 'Comfortaa',
    fontSize: '30px',
    color: 'black',
    width: '100%',
    flexDirection: 'row',
    padding: 20,
    marginTop: 30,
    marginBottom: 30,
    textAlign: 'center'
  },
  events_section: {
    position: 'relative'
  },
  dashboard_breaker: {
    width: '100%',
    height: 100,
    /* borderTopWidth: 1,
    borderBottomWidth: 1 */
  },
  event_box: {
    //borderWidth: 1,
    backgroundColor: 'black',
    flexDirection: 'column',
    marginTop: 20,
    marginBottom: 20
  },
  attencence_box: {
    flex: 1
  },
  event_day: {
    //borderWidth: 1,
    padding: 10,
    margin: 20,
    backgroundColor: '#0b6665',
    flexDirection: 'column',
    width: 150,
    borderRadius: 30,
  },
  non_event_day: {
    //borderWidth: 1,
    padding: 5,
    margin: 10,
    borderRadius: 20,
    flexDirection: 'column',
    width: 50,
    backgroundColor: '#d8e7ef',//d8e7ef
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    height: 50,
    alignSelf: 'center'
  },
  non_event_day_header: {
    //borderWidth: 1,
    height: 25,
    marginHorizontal: 10,
    marginVertical: 20,
    borderRadius: 30,
    flexDirection: 'column',
    width: 50,
    backgroundColor: '#9bc1bf',//d8e7ef
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    alignSelf: 'center'
  },
  non_event_day_text: {
    fontFamily: 'Ysabeau',
    fontSize: 10,
    textAlign: 'center',
    justifyContent: 'center',
    lineHeight: 20
  },
  no_dinners_drop: {
    width: '50%', fontSize: 16, 
    fontFamily: 'Ysabeau',
    lineHeight: 20
  },
  event_day_text: {
    fontFamily: 'Ysabeau',
    fontSize: 30,
    marginTop: 10,
    lineHeight: 34,
    textAlign: 'center',
    color: 'white'
  },
  event_day_text_date: {
    fontFamily: 'Ysabeau',
    textAlign: 'center',
    fontSize: 20,
    marginLeft: 20,
    color: 'white',
  },
  event_day_text_header: {
    fontFamily: 'Ysabeau',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    alignSelf: 'center',
    color: 'white'
  },
  restaurant_text_style: {
    fontFamily: 'Comfortaa',
    fontSize: 20,
    paddingVertical: 10,
    textAlign: 'center'
  },
  choose_text: {
    fontFamily: 'Ysabeau',
    fontSize: 16,
    lineHeight: 30,
    textDecorationLine: 'underline'
  },
  full_text: {
    fontFamily: 'Ysabeau',
    textAlign: 'center',
  },
  calendar_box: {
    flexDirection: 'column',
    paddingTop: 10,
    borderRadius: 2,
    width: 'calc(100vw - 40)'
  },
  week_box: {
    flexDirection: 'column'
  },
  calendar_panel: {

    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  title: {
    fontFamily: 'Comfortaa',
    fontSize: 18,
    marginTop: 20,
    marginBottom: 20
  },
  calendar_main_panel: {
    order: 1
  },
  side_calendar_panel: {
    flexDirection: 'column',
    width: width > 300 ? 300 : '100vw',
    order: 0
  },
  date_count_panel: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
    marginTop: 10,
    marginBottom: 20
  },
  date_panel: {
    flexDirection: 'column',
    flex: 1
  },
  notes_panel: {
    width: '100%'
  },
  review_content_wrapper: {
    flexDirection: 'row'
  },
  count_panel: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  funky_text: {
    fontFamily: 'Ysabeau',
  },
  normal_text: {
    fontFamily: 'Comfortaa',
    marginBottom: 5
  },
  review_month_title: {
    fontSize: 20,
    textAlign: 'center'
  },
  notes_title: {
    //textAlign: 'center'
  },
  multitext_box: {
    height: 50,
    width: 300
  },
  multitext_box_notes: {
    height: 50,
    width: '100%',
  },
  pagination_wrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 40
  },
  alight_left: {
    alignItems: 'flex-start',
  },
  alight_right: {
    alignItems: 'flex-end',
  },
  pagination_button_wrapper: {
    flexDirection: 'row',
    alignItems: 'center', 
    justifyContent: 'center'
  },
  carret_left: {
    color: '#0b6665',
    fontSize: 10,
    marginHorizontal: 5,
    textDecorationLine: 'none'
  },
  carret_right: {
    color: '#0b6665',
    fontSize: 10,
    marginLeft: 5,
    textDecorationLine: 'none'
  },
  carousel_progress_button: {
    color: '#0b6665',
    borderBottomWidth: 1,
    borderBottomColor: '#9bc1bf',
    fontSize: 20,
    fontFamily: 'Ysabeau'
  },
  category_header: {
    borderBottomWidth: 1,
    backgroundColor: 'white',
    borderBottomColor: 'white',
    opacity: 1
  },
  category_header_text: {
    fontFamily: 'Ysabeau',
    fontSize: 20,
    paddingHorizontal: 30,
    paddingVertical: 20
  },
  category_info_header: {
    backgroundColor: 'white',
  },
  info_panel: {
    backgroundColor: '#f7f8fc',
    marginLeft: 0,
    padding: 10,
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
    marginBottom: 40,
    width: '100%'
  },
  info_text_panel: {
    flexDirection: 'row',
    marginVertical: 6
  },
  wrap_text_panel: {
    flex:1, 
    flexDirection:'row',
    fontFamily: 'Comfortaa',
    fontSize: 14,
    lineHeight: 20
  },
  info_text: {
    fontFamily: 'Comfortaa'
  },
  category_info_header_text: {
    fontFamily: 'Ysabeau',
    fontSize: 18,
    //paddingHorizontal: 30,
    paddingVertical: 20,
    textDecorationLine: 'underline',
  },
  dashboard_panel: {
    backgroundColor: 'white',
    paddingHorizontal: 20,
  },
  color_explainer: {
    width: 20,
    height: 20,
    borderRadius: 5,
    marginBottom: -5,
    marginHorizontal: 5
  },
  chosen: {
    backgroundColor: '#feba2c'
  },
  approved: {
    backgroundColor: '#5ac3c4'
  },
  rsvped: {
    backgroundColor: '#ea5d47'
  },
  profile_button: {
    backgroundColor: '#0b6665',
    color: 'white',
    borderRadius: 30,
    width: 80,
    textAlign: 'center'
  },
  profile_button_text: {
    paddingHorizontal: 20,
    paddingVertical: 5,
    color: 'white',
    fontFamily: 'Ysabeau'
  },
  interests_wrapper: {
    flexDirection: 'row'
  },
  interests_column_1: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: 200,
    marginRight: 30
  },
  interests_column_2: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: 200,
    marginRight: 30
  },
  interests_column_3: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: 200
  },
  subscribe_title_wrapper: {
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  subscription_label: {
    fontSize: 14,
    fontFamily: 'Comfortaa',
    marginBottom: 6
  },
  subscription_type: {
    fontSize: 30,
    marginLeft: 53,
    fontFamily: 'Comfortaa',
    
  },
  auto_renew_wrapper: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginTop: 30
  },
  auto_renew_label: {
    fontSize: 14,
    fontFamily: 'Comfortaa',
    marginBottom: 6
  },
  auto_renew_cancels_label: {
    fontSize: 14,
    fontFamily: 'Comfortaa',
    marginBottom: 6,
    marginRight: 40
  },
  auto_renew_date: {
    fontSize: 14,
    fontFamily: 'Comfortaa',
    marginBottom: 6,
    marginLeft: 30
  },
  active_wrapper: {
    fontSize: 14,
    fontFamily: 'Comfortaa',
    marginBottom: 6,
    marginTop: 20
  },
  active_label: {
    fontSize: 14,
    fontFamily: 'Comfortaa',
    marginBottom: 6,
    color: 'green',
    textDecorationLine: 'underline'
  },
  payment_link_auto_renew: {
    color: '#0b6665',
    textDecorationLine: 'underline',
    fontSize: 14,
    fontFamily: 'Ysabeau',
    marginBottom: 7,
    marginLeft: 20
  },
  payment_links: {
    marginTop: 30,
    flexDirection: 'row',
    alignItems: 'center',
    width: width <= 430 ? width - 20 : 400,
    justifyContent: 'space-between',
    alignSelf: 'flex-end',

  },
  payment_link: {
    color: '#0b6665',
    textDecorationLine: 'underline',
    fontSize: 14,
    fontFamily: 'Ysabeau',
  },
  subscription_box_small: {

    borderRadius: 20,
    padding: 7,
    width: 250,
    backgroundColor: '#0b6665',
    borderColor: 'white',
    borderWidth: 5
  },
  subscription_box_small_text: {
    fontFamily: 'Ysabeau',
    color: 'white',
    fontSize: 14,
    textAlign: 'center'
  },
  subscription_box: {
    borderRadius: 30,
    padding: 20,
    width: 300,
    backgroundColor: 'black',
    borderColor: 'white',
    borderWidth: 5
  },
  subscribe_wrapper: {
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: 120,
  },
  subscription_explainer: {
    marginTop: 10,
    marginBottom: 30,
    fontFamily: 'Comfortaa',
    width: width <= 430 ? width - 20 : 400,
    marginRight: 20
  },
  club_button_text: {
    fontFamily: 'Ysabeau',
    color: 'white',
    fontSize: 20,
    textAlign: 'center'
  },
  panel_white: {
    backgroundColor: 'white',
    height: '100vh'
  },
  subscription_panel: {
    flexDirection: 'column'
  },
  cancel_panel: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cancel_subscription_button: {
    borderRadius: 30,
    backgroundColor: 'red',
    width: width <= 430 ? width - 20 : 400,
    marginVertical: 30
  },
  cancel_subscription_button_text: {
    color: 'black',
    fontFamily: 'Comfortaa',
    fontSize: 16,
    padding: 20,
    textAlign: 'center'
  },
  resume_label: {
    fontSize: 20,
    fontFamily: 'Comfortaa',
    marginBottom: 6
  },
  resume_subscription_button: {
    backgroundColor: '#0b6665',
    borderRadius: 30,
    width: width <= 430 ? width - 20 : 400,
    marginVertical: 20
  },
  resume_button_text: {
    color: 'white',
    fontFamily: 'Comfortaa',
    fontSize: 16,
    padding: 20,
    textAlign: 'center',
  },
  keep_subscription_button: {
    borderRadius: 30,
    backgroundColor: 'white',
    width: width <= 430 ? width - 20 : 400,
    borderWidth: 2,
    borderColor: '#0b6665'
  },
  keep_subscription_button_text: {
    color: '#0b6665',
    fontFamily: 'Comfortaa',
    fontSize: 16,
    padding: 20,
    textAlign: 'center'
  },
  cancel_confirm_title: {
    fontFamily: 'Ysabeau',
    fontSize: 20
  },
  close_panel_button: {
    position: 'absolute',
    top: 0,
    right: 20,
    borderWidth: 1,
    borderRadius: 100,
    width: 20,
    height: 20,
    borderColor: '#0b6665'
  },
  close_panel_button_text: {
    color: '#0b6665',
    fontFamily: 'Comfortaa',
    textAlign: 'center',
  },
  red_text: {
    fontFamily: 'Ysabeau',
    color: 'red',
    textAlign: 'right'
  },
  cancel_reason_text: {
    marginTop: 30,
    width: width <= 430 ? width - 20 : 400,
    height: 100
  },
  gray_out_background: {
    backgroundColor: '#e4aaaa'
  },
  left_nav: {
    borderRightWidth: 1,
    borderColor: '#f7f8fc',
    backgroundColor: '#f7f8fc'
  },
  left_button: {
    fontFamily: 'Ysabeau',
    fontSize: 16,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderBottomWidth: 1,
    borderColor: '#f7f8fc',
    color: 'black'
  },
  left_button_selected: {
    backgroundColor: 'black',
    color: 'white',
  },
  home_breaker: {
    width: '100%',
    height: 50
  },
  line_break: {
    width: '100%',
    height: 10,
    borderBottomWidth: 1,
    borderColor: '#f7f8fc',
    marginLeft: -20
  },
  input_label: {
    fontFamily: "Comfortaa"
   /*  marginTop: 20,
    marginBottom: 5, */
    //alignSelf: 'center'
  },
  input_label_edit: {
    fontFamily: "Comfortaa",
    width: 120,
    marginBottom: 5
   /*  marginTop: 20,
    , */
    //alignSelf: 'center'
  },
  profile_line: {
    flexDirection: 'column',
    marginVertical: 10
  },
  edit_button_wrapper: {
    alignSelf: 'flex-end',
    marginRight: 100
  },
  input_text: {
    fontFamily: "Ysabeau",
    lineHeight: 30,
    fontSize: 30,
    marginLeft: 20,
    marginTop: 5
  },
  input_text_interests: {
    fontFamily: "Ysabeau",
    lineHeight: 30,
    fontSize: 18,
    marginLeft: 20,
    marginTop: 5
  },
  edit_row_or_column: {
    flexDirection: 'row'
  },
  edit_row: {
    marginBottom: 20
  },
  profile_panel: {
    //width: 1000
  },
  profile_panel_wrapper: {
    width: 1000,
  },
  pricing_text: {
    fontFamily: 'Ysabeau',
    fontSize: 16,
    cursor: 'pointer',
    textAlign: 'right',
    marginBottom: 20,
    textDecorationLine: 'underline'
  },
  club_button_text: {
    fontFamily: 'Ysabeau',
    color: 'white',
    fontSize: 20,
    textAlign: 'center'
  },
  subscription_box: {
    borderRadius: 30,
    padding: 20,
    width: 300,
    backgroundColor: 'black',
    borderColor: 'white',
    borderWidth: 5
  },
  subscription_yearly: {
    backgroundColor: '#1c1c25'
  },
  subscription_monthly: {
    backgroundColor: 'black'
  },
  label_explainer: {
    fontFamily: "Ysabeau",
    marginBottom: 5,
    fontSize: 14,
    width: 500,
    lineHeight: 14,
    marginTop: 5
  },
  input_label_small: {
    fontFamily: "Comfortaa",
    marginBottom: 5,
    fontSize: 12,
    alignSelf: 'flex-start'
  },
  edit_column_one: {
    flex: 1
  },
  age_wrapper: {
    marginRight: 40
  },
  age_drop_wrapper: {
    width: 145, marginLeft: 70, marginTop: 5
  },
  rsvp_interim: {
    fontFamily: 'Ysabeau',
    fontSize: 20,
    textAlign: 'center', 
    marginTop: -10
  },
  rsvp_interim_date: {
    fontFamily: 'Ysabeau',
    fontSize: 20,
    textAlign: 'center',
    textDecorationLine: 'underline',
    paddingBottom: 30,
    borderBottomWidth: 1,
    borderColor: '#f7f8fc'
  },
  dinners_wrapper: {
    flexDirection: 'column'
  },
  dinners_header: {
    flexDirection: 'row'
  },
  dinner_row: {
    flexDirection: 'row',

  },
  dinner_content:  {
    fontFamily: 'Ysabeau',
    fontSize: 14,
    width: 200,
    margin: 10,
    padding: 10,
    textAlign: 'center'
  },
  dinner_header_text: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    width: 200,
    margin: 10,
    padding: 10,
    backgroundColor: '#f7f8fc',
    textAlign: 'center'
  },
  link: {
    color: '#0b6665',
    textDecorationLine: 'underline',
    fontSize: 18,
    fontFamily: 'Ysabeau',
    marginBottom: 7
  },
  image_main: {
    width: width > 500 ? '500px' : 'calc(100vw - 60px)',
    margin: 'auto',
    height: 300,
    /* borderRadius: 3,
    borderWidth: 2 */
  },
  main_restaurant_wrapper: {
   
  },
  main_main_wrapper: {
    flexDirection: 'column',
    marginTop: 10,
    //borderWidth: 1,
    //borderColor: '#b7d5d5',
    borderRadius: 5,
    padding: 10,
    alignContent: 'center',
    backgroundColor: 'black'
  },
  main_content_wrapper: {
    flexDirection: 'column',
    zIndex: 1,
    flex: 1
  },
  floating_info: {
    position: 'absolute',
    width: 200,
    backgroundColor: 'white',
    borderWidth: 2,
    borderColor: '#0b6665',
    borderRadius: 10,
    flexDirection: 'column',
    padding: 10,
    zIndex: 100,
    top: '16px',
    left: '13px'
  },
  attendee_name: {
    fontFamily: 'Comfortaa',
    fontSize: 20,
    textAlign: 'cetner',
    marginBottom: 10
  },
  attendee_info: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    paddingVertical: 10,
    lineHeight: 20
  },
  attendee_name_text: {
    fontFamily: 'Comfortaa',
    color: 'white',
    fontSize: 14,
    textAlign: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center'
  },
  first: {
    flexDirection: 'row',
    zIndex: 10,
    overflowWrap: 'anywhere',
    width: '100%',
    flexWrap: 'wrap',
  },
  group_title: {
    fontFamily: 'Ysabeau',
    fontSize: 20,
    marginBottom: 5
  },
  group_title_main: {
    fontFamily: 'Comfortaa',
    fontSize: 18,
    marginBottom: 20,
  },
  tentative_title: {
    fontFamily: 'Ysabeau',
    fontSize: 16,
    marginTop: 10,
    marginBottom: 5,
    position: 'relative',
    color: 'white'
  },
  profile_one: {
    backgroundColor: '#ff6961',
    zIndex: 'unset'
  },
  profile_two: {
    backgroundColor: '#ffb480',
    zIndex: 'unset'
  },
  profile_three: {
    backgroundColor: '#d4ce50',
    zIndex: 'unset'
  },
  profile_four: {
    backgroundColor: '#42d6a4',
    zIndex: 'unset'
  },
  profile_five: {
    backgroundColor: '#08cad1',
    zIndex: 'unset'
  },
  profile_six: {
    backgroundColor: '#59adf6',
    zIndex: 'unset'
  },
  profile_seven: {
    backgroundColor: '#9d94ff',
    zIndex: 'unset'
  },
  profile_eight: {
    backgroundColor: '#c780e8',
    zIndex: 'unset'
  },
  main_image_wrapper: {
    position: 'relative'
  },
  tonights_title: {
    fontFamily: 'Comfortaa',
    fontSize: 18,
    marginBottom: 10,
    marginTop: 20
  },
  tonights_description: {
    fontFamily: 'Ysabeau',
    fontSize: 16,
    /* lineHeight: 20,
    marginBottom: 20, */
    overflow: 'hidden',
    numberOfLines: 3,
    lineHeight: 20
  },
  read_roster_more: {
    fontFamily: 'Comfortaa',
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
    fontSize: '12px',
    color: '#0b6665',
    marginTop: 10
  },
  dont_shy: {
    fontFamily: 'Comfortaa',
    color: 'white',
    fontSize: 14,
    marginBottom: width < 700 ? 0 : 20,
    marginTop: width < 700 ? 20 : 0,
    lineHeight: 20
  },
  attendee_photo:  {
    width: 40,
    height: 40,
    borderRadius: 100
  },
  floating_info: {
    position: 'absolute',
    width: 200,
    backgroundColor: 'white',
    borderWidth: 2,
    borderColor: '#0b6665',
    borderRadius: 10,
    flexDirection: 'column',
    padding: 10,
    zIndex: 100,
    top: '16px',
    left: '13px'
  },
  attendee_name: {
    fontFamily: 'Comfortaa',
    fontSize: 20,
    textAlign: 'cetner',
    marginBottom: 10
  },
  attendee_info: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    paddingVertical: 10,
    lineHeight: 20
  },
  attendee_name_text: {
    fontFamily: 'Comfortaa',
    color: 'white',
    fontSize: 14,
    textAlign: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center'
  },
  price_wrapper: {
    flexDirection: 'row',
    marginTop: 20,
    justifyContent: 'center',
    alignItems: 'flex-end'
  },
  price_text: {
    fontFamily: 'Ysabeau',
    fontSize: 18,
    backgroundColor: '#1fb411',
    borderRadius: 5,
    paddingHorizontal: 5,
    paddingBottom: 3,
    color: 'white',
    marginRight: 10
  },
  price_member_text: {
    fontFamily: 'Ysabeau',
    fontSize: 16,
    textDecorationLine: 'underline'
  },
  price_member_text_number: {
    fontFamily: 'Comfortaa',
    fontSize: 16,
    color: '#1fb411'
  },

  day_events_wrapper: {
    width: '100%',
    flexDirection: 'column',
    alignContent: 'center'
  },
  day_events_wrapper_none: {
    width: '100%',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignContent: 'center',
    backgroundColor: '#cccccc',
    padding: 20,
    borderRadius: 30,
    marginTop: 40,
    textAlign: 'center',
    marginBottom: 40
  },
  reset_link: {
    color: '#0b6665',
    textDecorationLine: 'underline',
    fontSize: 18,
    fontFamily: 'Ysabeau',
    marginBottom: 7,
    marginLeft: 20,
    marginTop: 20
  },
  event_summary: {
    flexDirection: 'column', 
    width: 'calc(100vw - 20px)', 
    //height: 200, 
    borderWidth: 1,
    borderColor: '#b7d5d5',
    borderRadius: 5,
    paddin: 10,
    alignSelf: 'center',
    marginVertical: 30
  },
  event_summary_price_wrap: {
    padding: 10,
    paddingTop: 0
  },
  event_summary_top: {
    position: 'relative',
    height: 250
  },
  event_summary_image: {
    width: 'calc(100vw - 20px)', 
    height: 250,
    position: 'absolute',
    top: -1,
    left: -1,
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5
  },
  event_summary_name: {
    fontFamily: 'Ysabeau',
    fontSize: 30,
    backgroundColor: 'white',
    borderRadius: 5,
    padding: 3,
    paddingHorizontal: 5,
    top: 10,
    left: 10,
    position: 'absolute',
    maxWidth: 'calc(100vh - 80px)',
    borderWidth: 2,
    boxShadow: '4px 4px'
  },
  event_summary_location_time_wrapper: {
    position: 'absolute',
    bottom: 10,
    right: 10,
  },
  event_summary_location_time: {
    fontFamily: 'Ysabeau',
    fontSize: 26,
    color: 'white',
    backgroundColor: 'black',
    padding: 2,
    paddingHorizontal: 5,
    borderRadius: 5
  },
  event_summary_location: {
    flexDirection: 'row',
    padding: 10
  },
  event_summary_location_title: {
    fontFamily: 'Comfortaa',
    fontSize: 20
  },
  event_summary_location_at: {
    fontFamily: 'Comfortaa',
    fontSize: 12,
    marginHorizontal: 5
  },
  event_summary_location_at: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
  },
  event_summary_hosted_wrap: {
    flexDirection: 'row',
    padding: 10,
    alignItems: 'center'
  },
  event_summary_hosted_hosted: {
    fontFamily: 'Ysabeau',
    fontSize: 18,
    backgroundColor: 'black',
    borderRadius: 3,
    paddingVertical: 2,
    paddingHorizontal: 5,
    color: 'white'
  },
  event_summary_hosted_not_hosted: {
    fontFamily: 'Ysabeau',
    fontSize: 14,
    backgroundColor: '#cccccc',
    borderRadius: 3,
    paddingVertical: 2,
    paddingHorizontal: 5,
    color: 'black'
  },
  event_summary_hosted_by: {
    fontFamily: 'Ysabeau',
    fontSize: 12,
    paddingHorizontal: 3
  },
  event_summary_hosted_name: {
    fontFamily: 'Ysabeau',
    fontSize: 18,
  },
  filters_wrapper: {
    position: 'relative',
    alignItems: 'center',
    marginHorizontal: 'auto'
  },
  filter_choice: {
    fontFamily: 'Comfortaa',
    fontSize: 16,
    borderRadius: 20,
    backgroundColor: 'lightgrey',
    padding: '10px',
    width: '150px',
    textAlign: 'center',
    marginBottom: '10px'
  },
  filters_title: {
    fontFamily: 'Ysabeau',
    fontSize: 24,
    marginTop: 20,
    marginBottom: 10
  },
  types_wrapper: {
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 20,
    position: 'absolute',
    top: 10,
    left: 0,
    borderWidth: 1,
    flexDirection: 'column', 
    zIndex: 1
  },
  type_selection: {
    textAlign: 'center',
    paddingVertical: 10,
    fontFamily: 'Comfortaa',
    fontSize: 18
  },
  no_events_text: {
    fontFamily: 'Comfortaa',
    fontSize: 18
  },
  details_title: {
    fontFamily: 'Comfortaa',
    fontSize: 16,
    marginBottom: 10
  },
  text_line_wrapper: {
    flexDirection: 'row'
  },
  list_item_link: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    marginVertical: 5,
    lineHeight: 20,
    color: '#0b6665',
    textDecorationLine: 'underline',
    marginLeft: 20
  },
  list_item_text: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    marginVertical: 5,
    lineHeight: 20,
  },
  list_item: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Comfortaa',
    fontSize: 14,
    marginVertical: 5,
    marginLeft: 20,
    lineHeight: 20,
    listStyle: 'disc',
    display: 'list-item'
  },
  details_wrapper: {
    padding: 20
  },
  main_content_cols: {flexDirection: 'column'},
  calendar_filters_wrapper: {
    flexDirection: 'row',
    padding: 20,
    justifyContent: 'center',
    alignSelf: 'center'
  },
  main_day_calendar: {
    flexDirection: 'column',
    alignItems: 'center', 
    margin: 'auto'
  },
  one_event: {
    width: '100%',
    alignSelf: 'center',
    flexDirection: 'column',
    backgroundColor: 'black',
    borderRadius: 5,
    padding: 20,
    height: 'auto',
    marginBottom: 20
  },
  event_details_wrapper: {
    //alignSelf: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  filter_button: {
    flex: 1,
    alignSelf: 'center',
    borderRadius: 3,
    justifyContent: 'space-between',
    paddingVertical: 6,
    paddingHorizontal: 0
  }
});

const style_normal = StyleSheet.create({
  ...global_style,
  filter_button: {
    flex: 1,
    alignSelf: 'center',
    borderRadius: 3,
    paddingTop: 5,
    paddingBottom: 7,
    paddingHorizontal: 5, width: 207
  },
  days_wrapper: {
    //width: 800,
    marginRight: 20
  },
  main_day_calendar: {
    flexDirection: 'row',
  },
  one_event: {
    flexDirection: 'row',
    backgroundColor: 'black',
    borderRadius: 5,
    padding: 20,
    //width: '100%',
    height: 'auto',
    marginBottom: 20
  },
  thumbs: {
    fontSize: 30,
    marginRight: 7
  },
  info_bubble: {
    width: 200,
    height: 160,
    backgroundColor: 'white',
    zIndex: 10000000,
    position: 'absolute',
    padding: '20px',
    borderWidth: 1,
    borderRadius: 5,
    left: '158px',
    top: '15px'
  },
  overlay: {
    flex: 1,
    top: 0, 
    left: 0,
    opacity: .3,
    backgroundColor: 'black',
    width: '100vw',
    height: '100%',
    position: 'fixed',
    zIndex: 100000
  },
  modal_text: {
    fontFamily: 'Comfortaa',
    fontSize: 16
  },
  info_button: {
    backgroundColor: "#20cac8",
    paddingHorizontal: 9,
    paddingVertical: 1,
    borderRadius: 100,
    color: 'white',
    fontFamily: 'Ysabeau',
    fontSize: 14,
    marginLeft: 10
  },
  modal_text: {
    fontFamily: 'Comfortaa',
    fontSize: 12,
    lineHeight: 20
  },
  modal_text_title: {
    fontFamily: 'Comfortaa',
    fontSize: 16,
    lineHeight: 20,
    marginBottom: 15
  },
  canceled_text: {
    fontFamily: 'Ysabeau',
    fontSize: 20
  },
  info_bubble_text: {
    fontFamily: 'Ysabeau',
    fontSize: 14,
    backgroundColor: '#5ac3c4',
    width: 20,
    padding: 2,
    paddingTop: 0,
    paddingBottom: 4,
    textAlign: 'center',
    borderRadius: 100,
    alignSelf: 'flex-end',
    lineHeight: 16
  },
  info_bubble_popup: {
    marginLeft: 10,
    backgroundColor: 'black',
    //borderRadius: 10,
    padding: 10,
    paddingTop: 0
  },
  attendee_wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  attendee_text: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    lineHeight: 26,
  },
  collapsable_burger: {
    display: 'none'
  },
  a_column: {
    flexDirection: 'column'
  },
  a_row: {
    flexDirection: 'row'
  },
  main_dash_wrapper: {
    flexDirection: 'row'
  },
  page_wrapper: {
    flex: 1, flexDirection: 'column', position: 'relative',
    backgroundColor: 'white',
    paddingBottom: 200
  },
  slider_text: {
    fontSize: 20,
    fontWeight: '500',
    color: 'white',
    marginBottom: 5
  },
  logo_button: { paddingTop: 20, paddingBottom: 20, flexDirection: 'row', justifyContent: 'space-between' },
  main_text_wrapper: {
    position: 'absolute',
    left: 0,
    top: 100,
    width: '100%',
    alignContent: 'center', 
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  main_text_band: {
    width: '100%',
    position: 'absolute',
    top: 0,
    height: 500,
    backgroundColor: 'black',
    opacity: .9
  },
  main_callout: {
    fontFamily: 'Ysabeau',
    fontSize: '100px',
    color: 'white',

    textShadowColor: 'rgba(0, 0, 0, 1)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 5
  },
  main_sub_callout: {
    fontFamily: 'Ysabeau',
    fontSize: '50px',
    color: 'white',

    textShadowColor: 'rgba(0, 0, 0, 1)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 5
  },
  main_image: { 
    height: 500 ,
    width: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    borderWidth: 5
  },
  secondary_title: {
    fontFamily: 'Comfortaa',
    fontSize: '50px',
    color: 'black',
    width: '100%',
    flexDirection: 'row',
    padding: 20,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderBottomWidth: 1,
    borderTopWidth: 1
  },
  section_title: {
    fontFamily: 'Comfortaa',
    fontSize: '30px',
    color: 'black',
    width: '100%',
    flexDirection: 'row',
    padding: 20,
    marginTop: 30,
    marginBottom: 30,
    textAlign: 'center'
  },
  events_section: {
    position: 'relative'
  },
  dashboard_breaker: {
    width: '100%',
    height: 100,
    /* borderTopWidth: 1,
    borderBottomWidth: 1 */
  },
  event_box: {
    flex: 1,
    backgroundColor: 'black',
    flexDirection: 'column',
  },
  attencence_box: {
    flex: 1,
    marginBottom: 20, marginRight: 10
  },
  event_day: {
    //borderWidth: 1,
    padding: 10,
    margin: 20,
    backgroundColor: '#0b6665',
    flexDirection: 'column',
    width: 150,
    borderRadius: 30,
  },
  non_event_day: {
    //borderWidth: 1,
    padding: 5,
    margin: 10,
    borderRadius: 20,
    flexDirection: 'column',
    width: 50,
    backgroundColor: '#d8e7ef',//d8e7ef
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    height: 50,
    alignSelf: 'center'
  },
  non_event_day_header: {
    //borderWidth: 1,
    height: 25,
    marginHorizontal: 10,
    marginVertical: 20,
    borderRadius: 30,
    flexDirection: 'column',
    width: 50,
    backgroundColor: '#9bc1bf',//d8e7ef
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    alignSelf: 'center'
  },
  non_event_day_text: {
    fontFamily: 'Ysabeau',
    fontSize: 10,
    textAlign: 'center',
    justifyContent: 'center',
    lineHeight: 20
  },
  no_dinners_drop: {
    width: '50%', fontSize: 16, 
    fontFamily: 'Ysabeau',
    lineHeight: 20
  },
  event_day_text: {
    fontFamily: 'Ysabeau',
    fontSize: 28,
    marginLeft: 20,
    color: 'white',
    textTransform: 'uppercase'
    //textDecorationLine: 'underline'
  },
  event_day_text_date: {
    fontFamily: 'Ysabeau',
    fontSize: 20,
    marginLeft: 20,
    color: 'white',
  },
  event_details_price_wrapper: {
    marginHorizontal: 20
  },
  event_day_text_header: {
    fontFamily: 'Ysabeau',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    alignSelf: 'center',
    color: 'white'
  },
  restaurant_text_style: {
    fontFamily: 'Comfortaa',
    fontSize: 20,
    marginBottom: 10
  },
  choose_text: {
    fontFamily: 'Ysabeau',
    fontSize: 16,
    lineHeight: 30,
    textDecorationLine: 'underline'
  },
  full_text: {
    fontFamily: 'Ysabeau',
    textAlign: 'center',
  },
  full_text_2: {
    fontFamily: 'Ysabeau',
    textAlign: 'right',
    fontSize: 20,
    color: '#1fb411',
  },
  calendar_box: {
    flexDirection: 'column',
    borderRadius: 2,
    paddingHorizontal: 20
  },
  week_box: {
    flexDirection: 'column'
  },
  calendar_panel: {

    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  title: {
    fontFamily: 'Comfortaa',
    fontSize: 18,
    marginTop: 20,
    marginBottom: 20
  },
  side_calendar_panel: {
    flexDirection: 'column',
    width: 200
  },
  date_count_panel: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
    marginTop: 10,
    marginBottom: 20
  },
  date_panel: {
    flexDirection: 'column',
    flex: 1
  },
  notes_panel: {
    width: '100%'
  },
  review_content_wrapper: {
    flexDirection: 'row'
  },
  count_panel: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  funky_text: {
    fontFamily: 'Ysabeau',
  },
  normal_text: {
    fontFamily: 'Comfortaa',
    marginBottom: 5
  },
  review_month_title: {
    fontSize: 20,
    textAlign: 'center'
  },
  notes_title: {
    //textAlign: 'center'
  },
  multitext_box: {
    height: 50,
    width: 300
  },
  multitext_box_notes: {
    height: 50,
    width: '100%',
  },
  pagination_wrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 40
  },
  alight_left: {
    alignItems: 'flex-start',
  },
  alight_right: {
    alignItems: 'flex-end',
  },
  pagination_button_wrapper: {
    flexDirection: 'row',
    alignItems: 'center', 
    justifyContent: 'center'
  },
  carret_left: {
    color: '#0b6665',
    fontSize: 10,
    marginHorizontal: 5,
    textDecorationLine: 'none'
  },
  carret_right: {
    color: '#0b6665',
    fontSize: 10,
    marginLeft: 5,
    textDecorationLine: 'none'
  },
  carousel_progress_button: {
    color: '#0b6665',
    borderBottomWidth: 1,
    borderBottomColor: '#9bc1bf',
    fontSize: 20,
    fontFamily: 'Ysabeau'
  },
  category_header: {
    borderBottomWidth: 1,
    backgroundColor: 'white',
    borderBottomColor: 'white',
    opacity: 1
  },
  category_header_text: {
    fontFamily: 'Ysabeau',
    fontSize: 20,
    paddingHorizontal: 30,
    paddingVertical: 20
  },
  category_info_header: {
    backgroundColor: 'white',
  },
  info_panel: {
    backgroundColor: '#f7f8fc',
    marginLeft: 0,
    padding: 10,
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
    marginBottom: 40,
    width: '100%'
  },
  info_text_panel: {
    flexDirection: 'row',
    marginVertical: 6
  },
  wrap_text_panel: {
    flex:1, 
    flexDirection:'row',
    fontFamily: 'Comfortaa',
    fontSize: 14,
    lineHeight: 20
  },
  info_text: {
    fontFamily: 'Comfortaa'
  },
  category_info_header_text: {
    fontFamily: 'Ysabeau',
    fontSize: 18,
    //paddingHorizontal: 30,
    paddingVertical: 20,
    textDecorationLine: 'underline',
    paddingBottom: 40
  },
  dashboard_panel: {
    backgroundColor: 'white',
    paddingHorizontal: 20,
  },
  color_explainer: {
    width: 20,
    height: 20,
    borderRadius: 5,
    marginBottom: -5,
    marginHorizontal: 5
  },
  chosen: {
    backgroundColor: '#feba2c'
  },
  approved: {
    backgroundColor: '#5ac3c4'
  },
  rsvped: {
    backgroundColor: '#ea5d47'
  },
  profile_button: {
    backgroundColor: '#0b6665',
    color: 'white',
    borderRadius: 30,
    width: 80,
    textAlign: 'center'
  },
  profile_button_text: {
    paddingHorizontal: 20,
    paddingVertical: 5,
    color: 'white',
    fontFamily: 'Ysabeau'
  },
  interests_wrapper: {
    flexDirection: 'row'
  },
  interests_column_1: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: 200,
    marginRight: 30
  },
  interests_column_2: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: 200,
    marginRight: 30
  },
  interests_column_3: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: 200
  },
  subscribe_title_wrapper: {
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  subscription_label: {
    fontSize: 14,
    fontFamily: 'Comfortaa',
    marginBottom: 6
  },
  subscription_type: {
    fontSize: 30,
    marginLeft: 53,
    fontFamily: 'Comfortaa',
    
  },
  auto_renew_wrapper: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginTop: 30
  },
  auto_renew_label: {
    fontSize: 14,
    fontFamily: 'Comfortaa',
    marginBottom: 6
  },
  auto_renew_cancels_label: {
    fontSize: 14,
    fontFamily: 'Comfortaa',
    marginBottom: 6,
    marginRight: 40
  },
  auto_renew_date: {
    fontSize: 14,
    fontFamily: 'Comfortaa',
    marginBottom: 6,
    marginLeft: 30
  },
  active_wrapper: {
    fontSize: 14,
    fontFamily: 'Comfortaa',
    marginBottom: 6,
    marginTop: 20
  },
  active_label: {
    fontSize: 14,
    fontFamily: 'Comfortaa',
    marginBottom: 6,
    color: 'green',
    textDecorationLine: 'underline'
  },
  payment_link_auto_renew: {
    color: '#0b6665',
    textDecorationLine: 'underline',
    fontSize: 14,
    fontFamily: 'Ysabeau',
    marginBottom: 7,
    marginLeft: 20
  },
  payment_links: {
    marginTop: 30,
    flexDirection: 'row',
    alignItems: 'center',
    width: width <= 430 ? width - 20 : 400,
    justifyContent: 'space-between',
    alignSelf: 'flex-end',

  },
  payment_link: {
    color: '#0b6665',
    textDecorationLine: 'underline',
    fontSize: 14,
    fontFamily: 'Ysabeau',
  },
  subscription_box_small: {

    borderRadius: 20,
    padding: 7,
    width: 250,
    backgroundColor: '#0b6665',
    borderColor: 'white',
    borderWidth: 5
  },
  subscription_box_small_text: {
    fontFamily: 'Ysabeau',
    color: 'white',
    fontSize: 14,
    textAlign: 'center'
  },
  subscription_box: {
    borderRadius: 30,
    padding: 20,
    width: 300,
    backgroundColor: 'black',
    borderColor: 'white',
    borderWidth: 5
  },
  subscribe_wrapper: {
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: 120,
  },
  subscription_explainer: {
    marginTop: 10,
    marginBottom: 30,
    fontFamily: 'Comfortaa',
    width: width <= 430 ? width - 20 : 400,
    marginRight: 20
  },
  club_button_text: {
    fontFamily: 'Ysabeau',
    color: 'white',
    fontSize: 20,
    textAlign: 'center'
  },
  panel_white: {
    backgroundColor: 'white',
    height: '100vh'
  },
  subscription_panel: {
    flexDirection: 'column'
  },
  cancel_panel: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cancel_subscription_button: {
    borderRadius: 30,
    backgroundColor: 'red',
    width: width <= 430 ? width - 20 : 400,
    marginVertical: 30
  },
  cancel_subscription_button_text: {
    color: 'black',
    fontFamily: 'Comfortaa',
    fontSize: 16,
    padding: 20,
    textAlign: 'center'
  },
  resume_label: {
    fontSize: 20,
    fontFamily: 'Comfortaa',
    marginBottom: 6
  },
  resume_subscription_button: {
    backgroundColor: '#0b6665',
    borderRadius: 30,
    width: width <= 430 ? width - 20 : 400,
    marginVertical: 20
  },
  resume_button_text: {
    color: 'white',
    fontFamily: 'Comfortaa',
    fontSize: 16,
    padding: 20,
    textAlign: 'center',
  },
  keep_subscription_button: {
    borderRadius: 30,
    backgroundColor: 'white',
    width: width <= 430 ? width - 20 : 400,
    borderWidth: 2,
    borderColor: '#0b6665'
  },
  keep_subscription_button_text: {
    color: '#0b6665',
    fontFamily: 'Comfortaa',
    fontSize: 16,
    padding: 20,
    textAlign: 'center'
  },
  cancel_confirm_title: {
    fontFamily: 'Ysabeau',
    fontSize: 20
  },
  close_panel_button: {
    position: 'absolute',
    top: 0,
    right: 20,
    borderWidth: 1,
    borderRadius: 100,
    width: 20,
    height: 20,
    borderColor: '#0b6665'
  },
  close_panel_button_text: {
    color: '#0b6665',
    fontFamily: 'Comfortaa',
    textAlign: 'center',
  },
  red_text: {
    fontFamily: 'Ysabeau',
    color: 'red',
    textAlign: 'right'
  },
  cancel_reason_text: {
    marginTop: 30,
    width: width <= 430 ? width - 20 : 400,
    height: 100
  },
  gray_out_background: {
    backgroundColor: '#e4aaaa'
  },
  left_nav: {
    borderRightWidth: 1,
    borderColor: '#f7f8fc',
    backgroundColor: '#f7f8fc'
  },
  left_button: {
    fontFamily: 'Ysabeau',
    fontSize: 16,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderBottomWidth: 1,
    borderColor: '#f7f8fc',
    color: 'black'
  },
  left_button_selected: {
    backgroundColor: 'black',
    color: 'white',
  },
  home_breaker: {
    width: '100%',
    height: 50
  },
  line_break: {
    width: '100%',
    height: 10,
    borderBottomWidth: 1,
    borderColor: '#f7f8fc',
    marginLeft: -20
  },
  input_label: {
    fontFamily: "Comfortaa"
   /*  marginTop: 20,
    marginBottom: 5, */
    //alignSelf: 'center'
  },
  input_label_edit: {
    fontFamily: "Comfortaa",
    width: 120,
    marginBottom: 5
   /*  marginTop: 20,
    , */
    //alignSelf: 'center'
  },
  profile_line: {
    flexDirection: 'column',
    marginVertical: 10
  },
  edit_button_wrapper: {
    alignSelf: 'flex-end',
    marginRight: 100
  },
  input_text: {
    fontFamily: "Ysabeau",
    lineHeight: 30,
    fontSize: 30,
    marginLeft: 20,
    marginTop: 5
  },
  input_text_interests: {
    fontFamily: "Ysabeau",
    lineHeight: 30,
    fontSize: 18,
    marginLeft: 20,
    marginTop: 5
  },
  edit_row_or_column: {
    flexDirection: 'row'
  },
  edit_row: {
    marginBottom: 20
  },
  profile_panel: {
    //width: 1000
  },
  profile_panel_wrapper: {
    width: 1000,
  },
  pricing_text: {
    fontFamily: 'Ysabeau',
    fontSize: 16,
    cursor: 'pointer',
    textAlign: 'right',
    marginBottom: 20,
    textDecorationLine: 'underline'
  },
  club_button_text: {
    fontFamily: 'Ysabeau',
    color: 'white',
    fontSize: 20,
    textAlign: 'center'
  },
  subscription_box: {
    borderRadius: 30,
    padding: 20,
    width: 300,
    backgroundColor: 'black',
    borderColor: 'white',
    borderWidth: 5
  },
  subscription_yearly: {
    backgroundColor: '#1c1c25'
  },
  subscription_monthly: {
    backgroundColor: 'black'
  },
  label_explainer: {
    fontFamily: "Ysabeau",
    marginBottom: 5,
    fontSize: 14,
    width: 500,
    lineHeight: 14,
    marginTop: 5
  },
  input_label_small: {
    fontFamily: "Comfortaa",
    marginBottom: 5,
    fontSize: 12,
    alignSelf: 'flex-start'
  },
  edit_column_one: {
    flex: 1
  },
  age_wrapper: {
    marginRight: 40
  },
  age_drop_wrapper: {
    width: 145, marginLeft: 70, marginTop: 5
  },
  rsvp_interim: {
    fontFamily: 'Ysabeau',
    fontSize: 20,
    textAlign: 'center', 
    marginTop: -10
  },
  rsvp_interim_date: {
    fontFamily: 'Ysabeau',
    fontSize: 20,
    textAlign: 'center',
    textDecorationLine: 'underline',
    paddingBottom: 30,
    borderBottomWidth: 1,
    borderColor: '#f7f8fc'
  },
  dinners_wrapper: {
    flexDirection: 'column'
  },
  dinners_header: {
    flexDirection: 'row'
  },
  dinner_row: {
    flexDirection: 'row',

  },
  dinner_content:  {
    fontFamily: 'Ysabeau',
    fontSize: 14,
    width: 200,
    margin: 10,
    padding: 10,
    textAlign: 'center'
  },
  dinner_header_text: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    width: 200,
    margin: 10,
    padding: 10,
    backgroundColor: '#f7f8fc',
    textAlign: 'center'
  },
  link: {
    color: '#0b6665',
    textDecorationLine: 'underline',
    fontSize: 18,
    fontFamily: 'Ysabeau',
    marginBottom: 7
  },
  image_main: {
   // width: '300px',
    height: '100%',
    flex: 1
    //marginLeft: -1
  },
  main_restaurant_wrapper: {
    //marginLeft: 20
  },
  attendee_photo:  {
    width: 40,
    height: 40,
    borderRadius: 100
  },
  floating_info: {
    position: 'absolute',
    width: 200,
    backgroundColor: 'black',
    borderWidth: 2,
    borderColor: '#0b6665',
    borderRadius: 10,
    flexDirection: 'column',
    padding: 10,
    zIndex: 100,
    top: '16px',
    left: '13px'
  },
  attendee_name: {
    fontFamily: 'Comfortaa',
    fontSize: 20,
    textAlign: 'cetner',
    marginBottom: 10
  },
  attendee_info: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    paddingVertical: 10,
    lineHeight: 20
  },
  attendee_name_text: {
    fontFamily: 'Comfortaa',
    color: 'white',
    fontSize: 14,
    textAlign: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center'
  },
  first: {
    flexDirection: 'row',
    zIndex: 10,
    overflowWrap: 'anywhere',
    width: '100%',
    flexWrap: 'wrap',
  },
  group_title: {
    fontFamily: 'Ysabeau',
    fontSize: 20,
    marginBottom: 5
  },
  group_title_main: {
    fontFamily: 'Comfortaa',
    fontSize: 18,
    marginBottom: 20
  },
  tentative_title: {
    fontFamily: 'Ysabeau',
    fontSize: 16,
    marginTop: 10,
    marginBottom: 5,
    position: 'relative',
    color: 'white'
  },
  tonights_title: {
    fontFamily: 'Comfortaa',
    fontSize: 18,
    marginBottom: 10,
    marginTop: 20
  },

  tonights_description: {
    fontFamily: 'Ysabeau',
    fontSize: 16,
    /* lineHeight: 20,
    marginBottom: 20, */
    overflow: 'hidden',
    numberOfLines: 3,
    lineHeight: 20
  },
  read_roster_object: {

  },
  profile_one: {
    backgroundColor: '#ff6961',
    zIndex: 'unset'
  },
  profile_two: {
    backgroundColor: '#ffb480',
    zIndex: 'unset'
  },
  profile_three: {
    backgroundColor: '#d4ce50',
    zIndex: 'unset'
  },
  profile_four: {
    backgroundColor: '#42d6a4',
    zIndex: 'unset'
  },
  profile_five: {
    backgroundColor: '#08cad1',
    zIndex: 'unset'
  },
  profile_six: {
    backgroundColor: '#59adf6',
    zIndex: 'unset'
  },
  profile_seven: {
    backgroundColor: '#9d94ff',
    zIndex: 'unset'
  },
  profile_eight: {
    backgroundColor: '#c780e8',
    zIndex: 'unset'
  },
  main_image_wrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    padding: 20
  },
  read_roster_more: {
    fontFamily: 'Comfortaa',
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
    fontSize: '12px',
    color: '#0b6665',
    marginTop: 10
  },
  dont_shy: {
    fontFamily: 'Comfortaa',
    color: 'white',
    fontSize: 16,
    lineHeight: 20,
    //marginLeft: 10,
    marginTop: 20,
    //marginBottom: 10
  },
  display_date: {
    fontFamily: 'Comfortaa',
    fontSize: 40,
    textAlign: 'center',
    alignSelf: 'center'
  },
  price_wrapper: {
    flexDirection: 'row',
    marginTop: 10,
    marginRight: 50,
    alignItems: 'flex-end'
  },
  price_text: {
    fontFamily: 'Ysabeau',
    fontSize: 18,
    backgroundColor: '#1fb411',
    borderRadius: 5,
    paddingHorizontal: 5,
    paddingBottom: 3,
    color: 'white',
    marginRight: 10
  },
  price_member_text: {
    fontFamily: 'Ysabeau',
    fontSize: 16,
    textDecorationLine: 'underline'
  },
  price_member_text_number: {
    fontFamily: 'Comfortaa',
    fontSize: 16,
    color: '#1fb411'
  },
  day_events_wrapper: {
    width: 540,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'flex-start'
  },
  day_events_wrapper_none: {
    width: 540,
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignContent: 'center',
    backgroundColor: '#cccccc',
    padding: 20,
    borderRadius: 30,
    marginTop: 40,
    textAlign: 'center'
  },
  reset_link: {
    color: '#0b6665',
    textDecorationLine: 'underline',
    fontSize: 18,
    fontFamily: 'Ysabeau',
    marginBottom: 7,
    marginLeft: 20,
    marginTop: 20
  },
  event_summary: {
    flexDirection: 'column', 
    width: 250, 
    //height: 200, 
    borderWidth: 1,
    borderColor: '#b7d5d5',
    borderRadius: 5,
    paddin: 10,
    marginRight: 15,
    marginTop: 15
  },
  event_summary_price_wrap: {
    padding: 10,
    paddingTop: 0
  },
  event_summary_top: {
    position: 'relative',
    height: 150
  },
  event_summary_image: {
    width: 250, 
    height: 150,
    position: 'absolute',
    top: -1,
    left: -1,
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5
  },
  event_summary_name: {
    fontFamily: 'Ysabeau',
    fontSize: 20,
    backgroundColor: 'black',
    borderRadius: 5,
    padding: 3,
    paddingHorizontal: 10,
    top: 10,
    left: 10,
    position: 'absolute',
    maxWidth: 190,
    borderWidth: 2,
    boxShadow: '4px 4px'
  },
  event_summary_location_time_wrapper: {
    position: 'absolute',
    bottom: 10,
    right: 10,
  },
  event_summary_location_time: {
    fontFamily: 'Ysabeau',
    fontSize: 20,
    color: 'white',
    backgroundColor: 'black',
    padding: 2,
    paddingHorizontal: 5,
    borderRadius: 5
  },
  event_summary_location: {
    flexDirection: 'row',
    padding: 10
  },
  event_summary_location_title: {
    fontFamily: 'Comfortaa',
    fontSize: 14
  },
  event_summary_location_at: {
    fontFamily: 'Comfortaa',
    fontSize: 12,
    marginHorizontal: 5
  },
  event_summary_location_at: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
  },
  event_summary_hosted_wrap: {
    flexDirection: 'row',
    padding: 10,
    alignItems: 'center'
  },
  event_summary_hosted_hosted: {
    fontFamily: 'Ysabeau',
    fontSize: 14,
    backgroundColor: 'black',
    borderRadius: 3,
    paddingVertical: 2,
    paddingHorizontal: 5,
    color: 'white'
  },
  event_summary_hosted_not_hosted: {
    fontFamily: 'Ysabeau',
    fontSize: 14,
    backgroundColor: '#cccccc',
    borderRadius: 3,
    paddingVertical: 2,
    paddingHorizontal: 5,
    color: 'black'
  },
  event_summary_hosted_by: {
    fontFamily: 'Ysabeau',
    fontSize: 12,
    paddingHorizontal: 3
  },
  event_summary_hosted_name: {
    fontFamily: 'Ysabeau',
    fontSize: 14,
  },
  filters_wrapper: {
    position: 'relative'
  },
  filter_choice: {
    fontFamily: 'Comfortaa',
    fontSize: 16,
    borderRadius: 20,
    backgroundColor: 'lightgrey',
    padding: '10px',
    width: '150px',
    textAlign: 'center',
    marginBottom: '10px'
  },
  filters_title: {
    fontFamily: 'Ysabeau',
    fontSize: 24,
    marginTop: 20,
    marginBottom: 10
  },
  types_wrapper: {
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 20,
    position: 'absolute',
    top: 10,
    left: 0,
    borderWidth: 1,
    flexDirection: 'column', 
    zIndex: 1
  },
  type_selection: {
    textAlign: 'center',
    paddingVertical: 10,
    fontFamily: 'Comfortaa',
    fontSize: 18
  },
  no_events_text: {
    fontFamily: 'Comfortaa',
    fontSize: 18
  },
  details_title: {
    fontFamily: 'Comfortaa',
    fontSize: 16,
    marginBottom: 10
  },
  text_line_wrapper: {
    flexDirection: 'row'
  },
  list_item_link: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    marginVertical: 5,
    lineHeight: 20,
    color: '#0b6665',
    textDecorationLine: 'underline',
    marginLeft: 20
  },
  list_item_text: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    marginVertical: 5,
    lineHeight: 20,
  },
  list_item: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Comfortaa',
    fontSize: 14,
    marginVertical: 5,
    marginLeft: 20,
    lineHeight: 20,
    listStyle: 'disc',
    display: 'list-item'
  },
  details_wrapper: {
    padding: 20
  },
  main_content_cols: {flexDirection: 'row', alignSelf: 'center'},
  calendar_filters_wrapper: {
    width: '220px',
    flexDirection: 'column',
    marginTop: 60
  },
});

let style = width < 1160 ? style_small : style_normal;

export {
  style
}