import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { StyleSheet, View, TouchableOpacity, Platform, Image, Picker, Dimensions } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import { useNavigate } from 'react-router-dom';
import { Text, Input, Button } from '../../components';
import UserController from '../../controllers/userController';
import { style } from './style';
import { global_style } from '../../../global_style'
import AuthController from '../../controllers/authController';
import EventController from '../../controllers/eventController';
import AdminController from '../../controllers/adminController';
import AdminCalendar from '../AdminCalendar';

class AdminPortal_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
      search_text: 'soft',
      tab: 2
    }

    this.drawEvent = this.drawEvent.bind(this);
    this.drawPlace = this.drawPlace.bind(this);
    this.createEvent = this.createEvent.bind(this);
    this.updateEvent = this.updateEvent.bind(this);
    this.updatePlace = this.updatePlace.bind(this);
    this.updateRSVPs = this.updateRSVPs.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.confirmRestaurant = this.confirmRestaurant.bind(this);

    this.sendMessage = this.sendMessage.bind(this);
    this.groupMe = this.groupMe.bind(this);
  }

  async componentDidMount() {
    let internal = await AuthController.getInternal();
    let { events, hosts } = await AuthController.getAdminEvents();
    let places = await AuthController.getPlaces();

    console.log('events', events)
    console.log('places', places)
    let updates = {};

    for(var i = 0; i < places.length; i++) {  
      let place = places[i];
      let place_data = JSON.stringify(place.place_data);
  
      updates = {
        ...updates,
        [`updated_place_data_${i}`]: place_data
      }
    }

    let event_rsvps = {};

    let event;
    let meetup_rsvp_count;

    console.log('before sort', events)

    events.sort((event1, event2) =>{ return new Date(event1.date) < new Date(event2.date) ? -1 : 1})
   
    this.setState({
      ...updates,
      event_rsvps,
      places,
      events,
      mounted: true
    })
  }

  async deleteUser() {

    let { update_email } = this.state;
    
    let response = await UserController.deleteUser(update_email);

    this.setState({
      response_message: JSON.stringify(response)
    })
  }

  async createEvent(event_id, group, meetup_id) {

    let response = await EventController.createMeetupEvent({event_id, group, meetup_id});

    if(response.success) {
      window.location.reload();
    } else {
      this.setState({
        response_message: JSON.stringify(response)
      })
    }
  }

  async updateEvent(event_id, index) {
    let updated_event_data = this.state[`updated_event_data_${index}`]

    let event_data = updated_event_data;

    console.log('event_data', event_data);

    let response = await EventController.updateEvent({event_id, event_data});

    if(response.success) {
      alert('done')
      //window.location.reload();
    } else {
      this.setState({
        response_message: JSON.stringify(response)
      })
    }
  }

  async updatePlace(place_id, index) {
    let updated_place_data = this.state[`updated_place_data_${index}`]

    let place_data = updated_place_data;

    console.log('place_data', place_data);

    let response = await EventController.updatePlace({place_id, place_data});

    if(response.success) {
      window.location.reload();
    } else {
      this.setState({
        response_message: JSON.stringify(response)
      })
    }
  }

  async updateRSVPs(event_id, meetup_id) {
    let response = await EventController.updateRSVPs({event_id, meetup_id});

    this.setState({
      response_message: JSON.stringify(response)
    })
  }

  async confirmRestaurant(event_id) {
    let { selected_place, when } = this.state;

    console.log('evnet_id', event_id, selected_place);

    await AdminController.confirmPlace({
      place_id: selected_place,
      event_id,
      when
    })
  }

  drawEvent(event, index) {
    let { event_rsvps, places, selected_place, when } = this.state;

    let updated_event_data = this.state[`updated_event_data_${index}`]

/*     let { name, date, event_id, meetup_rsvp_count } = event;

    delete event.event_data;

    let rsvp_count = event_rsvps[event_id].length;
    let meetup_id_1 = event.meetup_id_1;

    let place = places.filter(pl=>pl.id === event.place_id)
    place = place && place.length ? place[0] : null;

    let event_data = JSON.stringify(event);
 */
    const updateData = (updated_event_data) => {
      /* this.setState({
        [`updated_event_data_${index}`]: updated_event_data
      }) */
    }

    let chosen_where = event.event_data.chosen_where;
    let event_data = event.event_data;
    let event_data_str = JSON.stringify(event);

    let chosen_places = places.filter(a=>chosen_where.indexOf(a.id) !== -1)

    return <View style={{flexDirection: 'row', marginBottom: 40, backgroundColor: 'gray', borderRadius: 10, padding: 20}}>
      {/* <View style={{width: 100}}>
        <Text style={{marginBottom: 20}}>{name}</Text>
        <Text>{date}</Text>
        <Text>Our rsvps: {rsvp_count}</Text>
        <Text>Meetup rsvps: {meetup_rsvp_count}</Text>
        <Text>{place && place.place_data ? place.place_data.restaurant_name : null}</Text>
      </View> */}

      <View>
        <Text>{event_data.selected_day} {event_data.time}</Text>
        <Input style={{height: 200, width: 300, marginHorizontal: 20}} type={'text'} multiline={true} defaultValue={event_data_str} value={event_data_str} />
      </View>
      <View style={{flexDirection: 'column'}}>
        <Text>chosen places</Text>
        {chosen_places.map(a=>a.place_data.restaurant_name)}
        <Input style={{height: 200, width: 300, marginHorizontal: 20}} type={'text'} defaultValue={when} value={when} onChangeText={(when)=>{this.setState({when})}} />
      </View>
      
      <Picker
              mode={'dialog'}
              defaultValue={selected_place}
              style={[style.drop_style, {width: 100}]}
              onValueChange={(selected_place)=>this.setState({selected_place})}
              >
              {places.map(v => {
                return <Picker.Item
                  fontSize={16}
                  style={{ fontSize: 16 }}
                  key={v.id}
                  label={v.place_data.restaurant_name}
                  value={v.id}
                />
              })}
              </Picker>
      <View style={{flexDirection: 'column'}}>
        <Button title={'Confirm Restaurant'}
            loading={false}

            style={{
              backgroundColor: 'black',
              alignSelf: 'center',
              borderRadius: 3,
              paddingTop: 5,
              paddingBottom: 7,
              paddingHorizontal: 5, marginBottom: 10}}

            onPress={()=>{this.confirmRestaurant(event.id, index)}} />

      </View>
    
    </View>
  }

  drawPlace(place, index) {

    let updated_place_data = this.state[`updated_place_data_${index}`]

    let place_id = place.id;

    let { restaurant_name, meetup_id } = place.place_data;

    let place_data = JSON.stringify(place.place_data);

    const updateData = (updated_place_data) => {
      this.setState({
        [`updated_place_data_${index}`]: updated_place_data
      })
    }

    return <View style={{flexDirection: 'row', marginBottom: 20}}>
       <View style={{marginBottom: 20, width: 100}}>
        <Text>{restaurant_name}</Text>
        <Text>{place_id}</Text>
      </View>

      <Text><Input style={{height: 200, width: 300, marginHorizontal: 20}} type={'text'} multiline={true} defaultValue={place_data} value={updated_place_data} onChangeText={updateData} /></Text>
      <View style={{flexDirection: 'column'}}>
        {meetup_id ? <Text>IN MEETUP</Text> : <Text>no id</Text>}
        <Text><Button title={<Text style={style.club_button_text}>Update place</Text>}
              loading={false}

              style_inactive={{backgroundColor: '#85bebe',
                alignSelf: 'center',
                borderRadius: 3,
                paddingTop: 5,
                paddingBottom: 7,
                paddingHorizontal: 20}}

              style={{backgroundColor: 'black',
                alignSelf: 'center',
                borderRadius: 3,
                paddingTop: 5,
                paddingBottom: 7,
                paddingHorizontal: 5}}

              onPress={()=>{this.updatePlace(place_id, index)}} />
        </Text>
      </View>
    
    </View>
  }

  async sendMessage() {
    let { email_email, email_message, email_subject, email_name } = this.state;

    if(email_email || email_message || email_subject) {
      let data = {
        email: email_email,
        message: email_message,
        subject: email_subject,
        name: email_name
      }
      await AdminController.sendEmail(data)
    }
  }

  async groupMe() {
    let { group_me_date, group_me_time } = this.state;

      let data = {
        day: group_me_date, 
        time: group_me_time
      }
   
      await AdminController.groupMe(data)
    
  }

  render() {

    let { mounted, events, response_message, places, update_email, tab, email_email, email_subject, email_message, email_name, group_me_time, group_me_date } = this.state;


    return mounted ? 
    <View style={style.dashboard_panel}>
      {response_message ? <Text style={{color: 'red'}}>{response_message}</Text> : null}
      <Text style={style.section_title}>Admin Portal</Text>

      <View>
        <TouchableOpacity onPress={()=>this.setState({tab: 0})}><Text>tab 1</Text></TouchableOpacity>
        <TouchableOpacity onPress={()=>this.setState({tab: 1})}><Text>tab 2</Text></TouchableOpacity>
        <TouchableOpacity onPress={()=>this.setState({tab: 2})}><Text>tab 3</Text></TouchableOpacity>
      </View>
      {tab === 0 ? <><View>
        <Text>Events</Text>
        {events.map(this.drawEvent)}
      </View>
      <View>
        <Text>Places</Text>
        {places.map(this.drawPlace)}
      </View></> : null}

      {tab === 1 ?
      <>
      <View>
        <Text>Send Group Me</Text>
        <Input value={group_me_date} type={'text'} placeholder={'date'} onChangeText={(group_me_date)=>{this.setState({group_me_date})}} />
        <Input value={group_me_time} type={'text'} placeholder={'time'} onChangeText={(group_me_time)=>{this.setState({group_me_time})}} />
        <Button title={'Send Group Me'} onPress={this.groupMe} />
      </View>
      <View>
        <Text>DELETE USER</Text>
        <Input value={update_email} type={'text'} onChangeText={(update_email)=>{this.setState({update_email})}} />
        <Button title={'Delete'} onPress={this.deleteUser} />
      </View>
      <View>
        <View>
          <Text>To: </Text>
          <Input value={email_email} type={'text'} onChangeText={(email_email)=>{this.setState({email_email})}} />
        </View>
        <View>
          <Text>Subject: </Text>
          <Input value={email_subject} type={'text'} onChangeText={(email_subject)=>{this.setState({email_subject})}} />
        </View>

        <View>
          <Text>Name: </Text>
          <Input value={email_name} type={'text'} onChangeText={(email_name)=>{this.setState({email_name})}} />
        </View>

        
        <View>
          <Text>Message: </Text>
          <Input multiline={true} style={{height: 200}} value={email_message} type={'text'} onChangeText={(email_message)=>{this.setState({email_message})}} />
        </View>
        <Button title={'Send'} onPress={this.sendMessage} />
      </View>
      </> : null}

      {tab === 2 ? <AdminCalendar putInParent={this.props.putInParent} /> : null}

      
    </View> : null;
  }

}

function AdminPortal(props) {
  if(Platform.OS === 'web') {
    let navigate = useNavigate();
    return  <AdminPortal_Base {...props} navigate={navigate} />
  } else {
    return  <AdminPortal_Base {...props} navigate={props.navigation.push}  />
  }
}

export default AdminPortal;
