import React, { Component } from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import { Text } from '../components';

class Link extends Component {

  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    let { link, children, style } = this.props;

    const goTo = () => {
      window.open(link)
    }

    let default_style = { fontFamily: 'Comfortaa', fontSize: 16, textDecorationLine: 'underline' };

    style = style ? [default_style, style] : default_style;
    
    return (
      <TouchableOpacity onPress={goTo}>
          <Text style={style}>{children}</Text>
      </TouchableOpacity>
    );
  }
}

export default Link
