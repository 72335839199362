import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { StyleSheet, View, TouchableOpacity, Platform } from 'react-native';
import { Icon, Colors, Text } from '../components';

class Button extends Component {

  constructor(props) {
    super(props);
    this.state = {
      state_loading: false,
      state_inactive: false
    }
  }

  render() {

    let btn_style  = this.props.style   || {};
    let btn_style_inactive = this.props.style_inactive || {};
    let btn_title  = this.props.title   || 'Submit';
    let is_loading = this.props.loading === true || this.state.state_loading ? true : false;
    let btn_action;
    let inactive   = this.props.inactive || this.state.state_inactive;
    let icon_name  = this.props.icon ? this.props.icon : '';
    let outline    = this.props.outline === true ? true : false;

    let title_style  = this.get_title_style();

    let styles_array = [ styles.default_style, { backgroundColor: Colors.PRIMARY }, btn_style ];
        styles_array = outline ? [ styles.outline_style, { borderColor: Colors.PRIMARY }, btn_style ] : styles_array;

    if(inactive) {
      styles_array.push(btn_style_inactive);
    } else {
      btn_action = () => {
        console.log('GETTING TO ACTION')

        if(this.props.disable_imediately) {
          console.log('GETTING TO ACTION2')
          this.setState({
            state_loading: true,
            state_inactive: true
          })
        }
        if(this.props.onPress) {
          this.props.onPress();
        }
      }
    }

    /* if (Platform.OS === 'web' && inactive) {
      let combined_array = [];

      styles_array.forEach((i_style, i) => {
        if (i_style && i_style.backgroundColor) {
          let updated_object = Object.assign({}, i_style);
          updated_object.backgroundColor = Colors.PRIMARY_FADED;
          combined_array.push(updated_object)
        } else {
          combined_array.push(i_style)
        }
      });

      styles_array = combined_array;
    } */

    return (
      <TouchableOpacity style={styles_array}
                        onPress={btn_action}>
        {this.props.disable_loading ? null :  
        <View style={{ height: 25, width: 25 }}>
          { is_loading || this.state.state_loading ? <Progress.CircleSnail size={25} fill='#00000000' indeterminate={true} color={ outline ? Colors.PRIMARY : 'white' } /> : null }
          { !is_loading && icon_name ? <Icon name={icon_name} size={19} style={{ marginTop: 2 }} solid={true} color={ outline ? Colors.PRIMARY : 'white' } /> : null }
        </View>}
        <Text style={title_style}>{ btn_title }</Text>
        {this.props.disable_loading ? null :  
        <View style={{ height: 25, width: 25 }}></View>}
      </TouchableOpacity>
    );
  }

  get_title_style = () => {

    let btn_style         = this.props.style     || {};

    let title_color       = btn_style.color      || null;
    let title_font_size   = btn_style.fontSize   || null;
    let title_font_weight = btn_style.fontWeight || null;
    let title_font_family = btn_style.fontFamily || null;
    let outline           = this.props.outline === true ? true : false;

    let title_style_obj   = {};

    if (outline)           { title_style_obj['color']      = Colors.PRIMARY    }
    if (title_color)       { title_style_obj['color']      = title_color       }
    if (title_font_size)   { title_style_obj['fontSize']   = title_font_size   }
    if (title_font_weight) { title_style_obj['fontWeight'] = title_font_weight }
    if (title_font_family) { title_style_obj['fontFamily'] = title_font_family }

    return [ styles.default_title_style, { fontWeight: '500' }, title_style_obj ]
  }

}

const styles = StyleSheet.create({
  default_style: {
    backgroundColor: '#1dc2ff',
    padding:10,
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
  },
  default_title_style: {
    color: 'white',
    fontWeight: '500',
    marginRight: 10,
    marginLeft: 10,
    fontSize: 16
  },
  inactive_button: {
    backgroundColor: '#add5f5'
  },
  outline_style: {
    borderWidth: 2,
    borderRadius: 8,
    padding: 15,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  line_breaker: {
    height: 50
  }
});

export default Button
