import React from 'react';
import UtilitiesController from './utilitiesController';
import { Platform } from 'react-native';
import { config } from '../../config';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Utilities from '../utilities/index';
import StorageController from './storageController';
import UserController from './userController';

export default class AdminController {

    static exceptionWrapper = (fn) => async (data) => {
        try {
            return await fn(data).catch((err) => {
                throw err;
            });
        } catch (err) {
            return {
                success: false,
                error: err.message
            }
        }
    };

    static async getAvailability(data) {
        let response = await Utilities.doInternalPost('/data/admin/get_availability', data);
        return response
    }

    static async groupMe (data) {
        let response = await Utilities.doInternalPost('/data/admin/group_me', data, false);

        return response;
    }

    static async sendEmail (data) {
        let response = await Utilities.doInternalPost('/data/admin/send_email', data, false);

        return response;
    }

    static async confirmPlace (data) {
        let response = await Utilities.doInternalPost('/data/admin/confirm_place', data, false);

        return response;
    }
}
