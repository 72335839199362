import { StyleSheet, Dimensions } from 'react-native';

let width = Dimensions.get('window').width;
let height = Dimensions.get('window').height;

const style = StyleSheet.create({
  page_wrapper: {flex: 1, flexDirection: 'column', position: 'relative'},
  slider_text: {
    fontSize: 20,
    fontWeight: '500',
    color: 'white',
    marginBottom: 5
  },
  logo_button: { paddingTop: 20, paddingBottom: 20, flexDirection: 'row', justifyContent: 'space-between' },
  main_image_wrapper: {
    position: 'relative',
    height: 500
  },
  main_text_wrapper: {
    position: 'absolute',
    left: 0,
    top: 70,
    width: '100%',
    alignContent: 'center', 
    justifyContent: 'center',
    flexDirection: 'row'
  },
  main_text_band: {
    width: '100%',
    position: 'absolute',
    top: 75,
    height: 150,
    backgroundColor: 'black',
    opacity: .5
  },
  main_callout: {
    fontFamily: 'Ysabeau',
    fontSize: '100px',
    color: 'white',

    textShadowColor: 'rgba(0, 0, 0, 1)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 5
  },
  main_image: { 
    height: 500 ,
    width: '100%',
    position: 'absolute',
    left: 0,
    top: 0
  },
  home_breaker: {
    width: '100%',
    height: 100
  },
  secondary_title: {
    fontFamily: 'Comfortaa',
    fontSize: '50px',
    color: 'black',
    width: '100%',
    flexDirection: 'row',
    padding: 20,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderBottomWidth: 1,
    borderTopWidth: 1
  },
  events_section: {
    position: 'relative'
  },
  events_list_wrapper: {
    height: 500
  },
  subscriptions_wrapper: {
    flexDirection: 'column'
  },
  clubs_wrapper: {
    flexDirection: 'row',
    flex: 1,
  },
  club_title: {
    fontFamily: 'Comfortaa',
    fontSize: '40px',
    color: 'black',
    width: '100%',
    flexDirection: 'row',
    padding: 20,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  one_club_wrapper: {
    flexDirection: 'column',
    flex: 1,
    height: '400px',

  },
  dining_title: {
    color: 'white'
  },
  club_dining: {
    backgroundColor: 'black'
  },
  club_button: { 
    paddingTop: 20, paddingBottom: 20, 
    flexDirection: 'row', justifyContent: 'center',
    shadowColor: '#171717',
    shadowOffset: {width: -2, height: 4},
    shadowOpacity: 0.2,
    shadowRadius: 3,
    borderWidth: 2,
    borderColor: 'white',
    borderRadius: 15,
    width: 200
  },
  club_button_text: {
    fontFamily: 'Ysabeau',
    color: 'white',
    fontSize: '20px',
    textTransform: 'uppercase',
    textAlign: 'center'
  }
});

export {
  style
}