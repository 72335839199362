import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { View, Platform, TouchableOpacity, ScrollView, Image, Picker, Dimensions } from 'react-native';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Input, Text } from '../../components';
import authController from '../../controllers/authController';
import UserController from '../../controllers/userController';
import EventController from '../../controllers/eventController';
import PaymentController from '../../controllers/paymentController';
import Utilities from '../../utilities';
import { FAQ, MemberPortal, Profile } from '../../containers';
import LoginRegisterScreen from '../LoginRegister';
import RegisterScreen from '../Register';
import { style } from './style';
import { global_style } from '../../../global_style'
import { Analytics, PageHit } from 'expo-analytics';
import { config } from '../../../config';
import AuthController from '../../controllers/authController';
import Icon from 'react-native-vector-icons/FontAwesome';
import { Video } from 'expo-av';
import * as CalendarRN from 'react-native-calendars';
const CalendarModule = CalendarRN.Calendar;
const WeekCalendar = CalendarRN.WeekCalendar;
const analytics = new Analytics('G-YCC5QPC8BJ');
let width = Dimensions.get('window').width;

class CreateInvite_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password: null,
      tab: 0,
      terms: true,
      num_rsvp: 1,
      event_id: props.event_id,
      confirm_inactive: true,
      show_calendar: true,
      chosen_time: 7
    }

    this.scrollViewRef = React.createRef();
    this.faqRef = React.createRef();

    this.scrollTo = this.scrollTo.bind(this);
    this.scrollToFaq = this.scrollToFaq.bind(this);

    this.updateState = this.updateState.bind(this);
    this.drawWhat = this.drawWhat.bind(this);
    this.generateArrayOfObjects = this.generateArrayOfObjects.bind(this);
    this.drawType = this.drawType.bind(this);
    this.createInvite = this.createInvite.bind(this);
    this.publishEvent = this.publishEvent.bind(this);
    this.openInParent = this.openInParent.bind(this);
    this.openTags = this.openTags.bind(this);
    this.toggleState = this.toggleState.bind(this);
    this.drawHobbies = this.drawHobbies.bind(this);
    this.drawWhere = this.drawWhere.bind(this);

    this.openMemberSearch = this.openMemberSearch.bind(this);
    this.openAfterDraft = this.openAfterDraft.bind(this);
    this.publishConfirm = this.publishConfirm.bind(this);
    this.notYetPublish = this.notYetPublish.bind(this);

    this.drawRequest = this.drawRequest.bind(this);
    this.openProfileBubble = this.openProfileBubble.bind(this);

    this.approveRSVP = this.approveRSVP.bind(this);
    this.confirmApproveRSVP = this.confirmApproveRSVP.bind(this);

    this.deleteConfirm = this.deleteConfirm.bind(this);
    this.deleteEvent = this.deleteEvent.bind(this);
  }

  async componentDidMount() {
    analytics.hit(new PageHit('CreateEvent'))
      .then(() => {})
      .catch(e => console.log("analytics error", e.message));

    let org_user = await UserController.getUser();

    let { event_id } = this.state;
    let event = {}
    console.log('event_id', event_id)
    console.log('propseventid', this.props.event_id)
    let join_requests;
    if(event_id) {
      event = await UserController.getUserEvent({event_id});
      event = event.event_data;
      console.log('event', event);
      join_requests = event.join_requests;
      join_requests = join_requests && join_requests.length ? join_requests : [];

      if(join_requests.length) {
        let users = await UserController.getUsers({user_ids: join_requests.map(a=>a.user_id)})

        
        join_requests = users.map(user=>{
          return {
            ...user,
            accepted: join_requests.filter(a=>a.user_id === user.user_id)[0].accepted
          }
        })
      }
    }

    let interest_list = await UserController.getInterestList();
    let places = await AuthController.getPlaces();

    console.log('placesplaces', places)

    let interests = interest_list && interest_list.interests && interest_list.interests.interests ?  interest_list.interests.interests : null ;

    if(interests) {
      interests = JSON.parse(interests);
    }

    let hobbies_list = interests ? interests.hobbies : [];

    let width = Dimensions.get('window').width;
    let is_mobile = width <= 700;

    let day = new Date();

    let selected_day = `${day.getMonth()+1}/${day.getDate()}/${day.getFullYear()}`;
    selected_day = event.selected_day ? event.selected_day : selected_day;

    let today = new Date();
    
    selected_day = new Date(selected_day) < today ? `${today.getMonth()+1}/${today.getDate()}/${today.getFullYear()}` : selected_day;

    this.setState({
      org_user,
      show_calendar: false,
      places,
      is_mobile,
      mounted: true,
      hobbies_list,
      event,
      ...event,
      selected_day,
      join_requests
    })
  }

  updateState(value, name, extra_state) {

    extra_state = extra_state ? extra_state : {};

    this.setState({
      ...extra_state,
      is_search_open: false,
      [name]: value
    })
  }

  toggleState(name) {
    console.log('toggling', name, this.state[name])

    if(this.state[name]) {
      this.props.putInParent(null)
    }

    this.setState({
      [name]: !this.state[name]
    })
  }

  openInParent(content, title, state_variable, width, height) {
    console.log('drawing in parent again', state_variable, this.state[state_variable]);
    this.props.putInParent(!this.state[state_variable] ? 
      <View style={{zIndex: 10000000, position: 'fixed', width: '100vw', height: '100vh'}}>
      <>
        <TouchableOpacity onPress={()=>this.toggleState(state_variable)} style={global_style.modal_overlay}></TouchableOpacity>
        <View style={{position: 'fixed', width: '100vw', height: '100vh', zIndex: 2000000}}>
        <View style={[global_style.modal_content_mobile, {display: 'block', width, height, left: `calc(50% - ${width / 2}px)`}]}>
            <View style={style.modal_header}>
              <Text style={style.modal_title}>{title}</Text>
              <TouchableOpacity onPress={()=>this.toggleState(state_variable)}><Text style={[global_style.modal_close_x]}>x</Text></TouchableOpacity>
            </View>
            <ScrollView style={{flex: 1, height: 'calc(80vh - 20px)' }}>
              <View style={[global_style.padding_20]}>{content}</View>
          </ScrollView>
        </View>
        </View>
      </>

      </View> : null);

    this.setState({
      [state_variable]: !this.state[state_variable]
    });
  }

  drawHobbies() {
    let { hobbies_list, chosen_interests } = this.state;
    chosen_interests = chosen_interests ? chosen_interests : ""
    let hobby_titles = Object.keys(hobbies_list);

    console.log('hobbies_listhobbies_list', hobbies_list)

    const updateHobby = (hobby) => {
      hobby = hobby.toLowerCase();
      let { chosen_interests } = this.state;
      chosen_interests = chosen_interests ? chosen_interests : ""

      if(chosen_interests.indexOf(`,${hobby},`) !== -1) {
        chosen_interests = chosen_interests.replace(`,${hobby},`, '')
      } else {
        chosen_interests += `,${hobby},`
      }

      this.setState({chosen_interests, is_hobbies_open: false}, ()=>{
        this.openTags();
      })

    }

    console.log('chosen_interests', chosen_interests)

    return hobby_titles.map(hobby_title=>{
      return <View style={style.hobbies_edit_wrapper}>
        <Text style={{fontFamily: 'Ysabeau', fontSize: 16}}>{hobby_title}</Text>
        <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
          {hobbies_list[hobby_title].map(hobby=>{
            let selected = chosen_interests.indexOf(`,${hobby.toLowerCase()},`) !== -1 ? style.selected : {};
            return <TouchableOpacity onPress={()=>{updateHobby(hobby)}}>
                  <Text style={[style.interest_button, selected]}>{hobby}</Text>
                </TouchableOpacity>
          })}
        </View>
      </View>
    })
  }

  openTags() {
    this.props.putInParent(null);
    this.openInParent(<View >
      {this.drawHobbies()}
    </View>, "Choose hobbies 👩‍🔬", "is_hobbies_open", 800, '90vh')
  }

  openMemberSearch() {

    const updateInvited = (invited_members) => {
      this.setState({invited_members});
    }

    let is_mobile = width < 700;

    this.props.putInParent(null);
    this.openInParent(<View >
      <MemberPortal updateInvited={updateInvited} invited_members={this.state.invited_members} />
    </View>, "Search Members to Invite", "is_search_open", is_mobile ? '100vw' : 800, '90vh')
    
  }

  notYetPublish() {
    this.props.putInParent(null);
    this.openInParent(<View style={{alignSelf: 'center', flexDirection: 'column'}}>
      <Text style={[global_style.normal_text_dark_medium, {marginBottom: 20}]}>You have to save the draft before publishing. Publishing means your event will show on our calendar and any members that were specifically invited will get a message.</Text>
      <Button title={<Text style={style.button_text}>Keep Editing</Text>}
                  loading={false}
                  style={{
                    alignSelf: 'center',
                    width: 200,
                    backgroundColor: '#0dc6e3',
                    borderRadius: 10,
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingHorizontal: 0}}

                  onPress={()=>{this.toggleState('is_not_publish_open')}} />
    </View>, "Publish Invite", "is_not_publish_open", 320, 300)
  }

  scrollToFaq() {
    console.log('this.faqRef', this.faqRef)
    if(this.faqRef && this.faqRef.scrollIntoView) {
      this.faqRef.scrollIntoView()
    }
  }

  async deleteEvent() {
    let  { event_id } = this.state;
    await EventController.deleteUserEvent({event_id});

    window.location.replace('/dashboard/my_events')
  }

  deleteConfirm() {
    this.props.putInParent(null);
    this.openInParent(<View style={{alignSelf: 'center', textAlign: 'center', alignItems: 'center'}}>
      <Text style={[global_style.normal_text_dark_medium, {marginBottom: 20}]}>Are you sure you'd like to delete this invite? Your approved attendees will be notified.</Text>
      <Button title={<Text style={style.button_text}>Delete Invite</Text>}
                  loading={false}
                  style={{
                    width: 200,
                    backgroundColor: '#e32652',
                    borderRadius: 10,
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingHorizontal: 0, marginBottom: 10}}

                  onPress={this.deleteEvent} />
      <Button title={<Text style={style.button_text}>Cancel</Text>}
                  loading={false}
                  style={{
                    width: 200,
                    backgroundColor: '#0dc6e3',
                    borderRadius: 10,
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingHorizontal: 0, marginBottom: 10}}

                  onPress={()=>{this.toggleState('is_delete_open')}} />
    </View>, "Delete Invite", "is_delete_open", 400, 350)
  }

  publishConfirm() {
    this.props.putInParent(null);
    this.openInParent(<View style={{alignSelf: 'center', textAlign: 'center', alignItems: 'center'}}>
      <Text style={[global_style.normal_text_dark_medium, {marginBottom: 20}]}>When you publish this event, applicable members will be notified and will be able to see this invite in their events calendar.</Text>
      <Button title={<Text style={style.button_text}>Publish Event</Text>}
                  loading={false}
                  style={{
                    width: 200,
                    backgroundColor: '#e32652',
                    borderRadius: 10,
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingHorizontal: 0, marginBottom: 10}}

                  onPress={this.publishEvent} />
      <Button title={<Text style={style.button_text}>Keep Editing</Text>}
                  loading={false}
                  style={{
                    width: 200,
                    backgroundColor: '#0dc6e3',
                    borderRadius: 10,
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingHorizontal: 0, marginBottom: 10}}

                  onPress={()=>{this.toggleState('is_draft_open')}} />
    </View>, "Publish Invite", "is_draft_open", 400, 350)
  }

  openAfterDraft(user_event) {
    this.props.putInParent(null);
    this.openInParent(<View style={{alignSelf: 'center', paddingTop: 20}}>
      <Button title={<Text style={style.button_text}>Go To Events</Text>}
                  loading={false}
                  style={{
                    width: 200,
                    backgroundColor: '#0dc6e3',
                    alignSelf: 'flex-end',
                    borderRadius: 10,
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingHorizontal: 0, marginBottom: 10}}

                  onPress={()=>{window.location.replace('/dashboard/my_events')}} />
      <Button title={<Text style={style.button_text}>Keep Editing</Text>}
                  loading={false}
                  style={{
                    width: 200,
                    backgroundColor: '#0dc6e3',
                    alignSelf: 'flex-end',
                    borderRadius: 10,
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingHorizontal: 0, marginBottom: 10}}

                  onPress={()=>{window.location.replace(`/create_invite/${user_event.id}`)}} />
    </View>, "Draft Saved", "is_draft_open", 300, 250)
  }

  drawWhat() {
    let { chosen_what, new_what_title } = this.state;

    let what_drop = config.what_drop;

    console.log('chosen_what', chosen_what);

    return <View style={{flexDirection: 'column'}}><Picker
              selectedValue={chosen_what}
              mode={'dialog'}
              style={style.drop_style}
              onValueChange={(value)=>this.updateState(value, 'chosen_what')}
            >
              {what_drop.map(v => {
                return <Picker.Item
                  fontSize={16}
                  style={{ fontSize: 16 }}
                  key={v.value}
                  label={v.name}
                  value={v.value}
                />
              })}
        </Picker><Text>
        {chosen_what === "-2" ? <View>
          <Input placeholder={"Suggest a title and we'll be in touch shortly"} style={{flex: 12, width: '350px'}} value={new_what_title} onChangeText={(event)=>{this.updateState(event, 'new_what_title')}} /></View> : null}</Text>
      </View>
  }

  generateArrayOfObjects(start, end, step) {
    const result = [];
  
    let i_int;
    for (let i = start; i <= end; i += step) {

      let name;
      i_int = parseInt(i);

      let has_half = i_int < i;

      let the_hour = i_int <= 12 ? i_int : i_int - 12;
      let the_post_fix = i_int < 12 ? 'AM' : 'PM';

      name = `${the_hour}${has_half ? ':30' : ''} ${the_post_fix}`

      result.push({ name });
    }
  
    return result;
  }

  drawWhen() {
    let { chosen_time, selected_day, show_calendar } = this.state;

    let is_mobile = width < 700;

    let starting_date = new Date();

    starting_date = `${starting_date.getMonth() + 1}/${starting_date.getDate()}/${starting_date.getFullYear()}`;

    let calendar_module = <CalendarModule
          minDate={starting_date}
          initialDate={starting_date}
          style={{
            borderWidth: 1,
            borderColor: '#b7d5d5',
            width: is_mobile ? 'calc(100vw - 40px)' : 220,
            borderRadius: 5
          }} 
          renderArrow={(direction)=>{
            if(direction === 'left') {
              return <Text style={{color: '#b7d5d5'}}>&lt;</Text>
            } else {
              return <Text style={{color: '#b7d5d5'}}>&gt;</Text>
            }
          }}
          
          // Specify the current date
          current={starting_date}
          // Callback that gets called when the user selects a day
          onDayPress={day => {
            let selected_day = `${day.month}/${day.day}/${day.year}`;

            this.setState({selected_day, show_calendar: false})
          }}
        />;

      let time_values = this.generateArrayOfObjects(7, 19, 0.5);

      let time_picker = <View style={{marginLeft: 10}}><Picker
          selectedValue={chosen_time}
          mode={'dialog'}
          style={style.drop_style}
          onValueChange={(value)=>this.updateState(value, 'chosen_time', {show_calendar: false})}
        >
          {time_values.map(v => {
            return <Picker.Item
              fontSize={16}
              style={{ fontSize: 16 }}
              key={v.name}
              label={v.name}
              value={v.name}
            />
          })}
    </Picker></View>

      return <View>
        <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 10}}>
          {selected_day} {time_picker}
        </View>

        {show_calendar ?
        <View>
          <View>
            {calendar_module}
          </View>
        </View> : <TouchableOpacity onPress={()=>{this.setState({show_calendar: true})}}><Text style={global_style.normal_link}>show calendar</Text></TouchableOpacity>}
        
        {/* <View>
          <Text>Time</Text>
          <View>
            {time_picker}
          </View>
        </View> */}
      </View>
  }

  drawType() {
    let { chosen_type } = this.state;

    let type_drop = config.type_drop;
    const chooseType = async (chosen_type) => {
      this.setState({
        chosen_type
      })
    }

    return <View><Picker
              selectedValue={chosen_type}
              mode={'dialog'}
              style={style.drop_style}
              onValueChange={chooseType}
            >
              {type_drop.map(v => {
                return <Picker.Item
                  fontSize={16}
                  style={{ fontSize: 16 }}
                  key={v.value}
                  label={v.name}
                  value={v.value}
                />
              })}
        </Picker>
      </View>
  }

  drawWhere() {
    let { chosen_where, places, chosen_type } = this.state;


    let regular_places = [];
    let extra_places;

    let intro_one;
    let intro_two;

    console.log('drawing whre', chosen_type)

    console.log('placesr', places)

    //if(places && places.length && chosen_type && chosen_type !== "-1") {
      chosen_type = "1";
      switch(chosen_type) {
        case "1":
          regular_places = places.filter(place=>place.place_data.type === 'cafe')
          break;
        case "2":
          regular_places = places.filter(place=>place.place_data.hh);
          break;
        case "3":
         // intro_one = "Restaurants that allow for separate checks."
          regular_places = places.filter(place=>place.place_data.type === 'restaurant');

         // intro_two = "Restaurants that do not allow separate checks"
          //extra_places = places.filter(place=>place.place_data.type === 'restaurant');
          break;
        default: break;
      }

      console.log('regular_places', regular_places)

      regular_places.sort((a, b)=>{
        
        if(a.place_data.separate_checks) {
          if(b.place_data.separate_checks) {
            return a.place_data.restaurant_name > b.place_data.restaurant_name ? 1 : -1;
          } else {
            return -1;
          }
        } else {
          if(b.place_data.separate_checks) {
            return 1;
          } else {
            return a.place_data.restaurant_name > b.place_data.restaurant_name ? 1 : -1;
          }
        }      
      })

      regular_places = regular_places ? regular_places.map(place=>{return {
        name: `${place.place_data.restaurant_name}${place.place_data.separate_checks ? ' - Separate Check Allowed' : '' }`,
        value: place.id
      }}) : [];

      regular_places = [{name: "Choose a place", value: -1}].concat(regular_places);

/*       extra_places = extra_places ? extra_places.map(place=>{return {
        name: place.place_data.restaurant_name,
        value: place.id
      }}) : [] */
    //}


    return <View>

      <View>
        {regular_places && regular_places.length ?
        <Picker
                selectedValue={chosen_where}
                mode={'dialog'}
                style={style.drop_style}
                onValueChange={(value)=>this.updateState(value, 'chosen_where')}
              >
                {regular_places.map(v => {
                  return <Picker.Item
                    fontSize={16}
                    style={{ fontSize: 16 }}
                    key={v.value}
                    label={v.name}
                    value={v.value}
                  />
                })}
          </Picker>: <Text style={style.placeholder_text}>Choose type of event first</Text>}
        </View>
        
      </View>
  }

  async approveRSVP(confirm_user_request) {
    console.log('confirm_user_request', confirm_user_request)

    this.setState({
      confirm_user_request
    })

    let name = JSON.parse(confirm_user_request.profile).name;

    this.props.putInParent(null);
    this.openInParent(<View style={{alignSelf: 'center', textAlign: 'center', alignItems: 'center'}}>
      <Text style={[global_style.normal_text_dark_medium, {marginBottom: 20}]}>Reminder: You Cannot undo this action. </Text>
      <Button title={<Text style={style.button_text}>Approve {name}'s RSVP</Text>}
                  loading={false}
                  style={{
                    width: 200,
                    backgroundColor: '#e32652',
                    borderRadius: 10,
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingHorizontal: 0, marginBottom: 10}}

                  onPress={this.confirmApproveRSVP} />
      <Button title={<Text style={style.button_text}>Cancel</Text>}
                  loading={false}
                  style={{
                    width: 200,
                    backgroundColor: '#0dc6e3',
                    borderRadius: 10,
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingHorizontal: 0, marginBottom: 10}}

                  onPress={()=>{this.toggleState('open_confirm')}} />
    </View>, "Approve RSVP", "open_confirm", 400, 350)
  }

  async confirmApproveRSVP() {
    let { event_id, confirm_user_request, join_requests } = this.state;

    let user_request = { user_id: confirm_user_request.user_id, accepted: confirm_user_request.accepted }

    await EventController.approveRSVP({ user_request, event_id });

    let index;
    join_requests.forEach((a, i)=>{
        if(a.user_id === confirm_user_request.user_id) {
            index = i;
        }
    });

    join_requests[index] = {
        ...join_requests[index],
        accepted: true
    }

    this.props.putInParent(null);
    this.setState({
      join_requests,
      open_confirm: false
    })
  }

  async publishEvent() {
    let { min_age, max_age, gender, message, chosen_interests, chosen_where, selected_day, chosen_time, chosen_what, chosen_type, event_id, tab, invited_members, event, males, females, twenties, thirties, forties, fifties, sixties } = this.state; 

    let go_through = true;


    invited_members = invited_members ? invited_members : []

    invited_members = invited_members.map(user=>user.user_id)
    
    chosen_type = "1";
    chosen_what = "1";

    console.log('invited_members', invited_members)

    if(go_through) {
      // min_age, max_age, gender, message, chosen_interests, chosen_where, selected_day, chosen_time, chosen_what, chosen_type
      // -1 88 Female asdf asdf ,furniture restoration, 78b6bd95-b3fe-47e0-9d45-b63b14539815 2/7/2024 8.5 2 1

      let all_required_present = message && message.length && chosen_where && chosen_where !== "-1" && selected_day && chosen_time && chosen_what && chosen_what !== "-1" && chosen_type && chosen_type !== "-1";

      console.log('min_age, max_age, gender, message, chosen_interests, chosen_where, selected_day, chosen_time, chosen_what, chosen_type');
      console.log(min_age, max_age, gender, message, chosen_interests, chosen_where, selected_day, chosen_time, chosen_what, chosen_type);

      //let action = status && status === 'published' ? 'update' : 'publish'
      let action = 'publish';

      let data = {
        ...event,
        status: 'published',
        min_age, max_age, gender, message, chosen_interests, chosen_where, selected_day, chosen_time, chosen_what, chosen_type, event_id, invited_members, tab, action, males, females, twenties, thirties, forties, fifties, sixties
      }

      console.log('all_required_present', all_required_present) 

      if(all_required_present) {
        let response = await EventController.createUpdateEvent(data);

        window.location.reload();
      } else {
        this.setState({
          error_message: "Please make sure to include the place, datetime and invite message."
        })
      }
    } else {
      this.props.putInParent(null)
      this.setState({
        is_draft_open: false,
        error_message: "Please make sure to include at least 1 individual or simply choose a demographic."
      })
    }
  }

  async createInvite() {
    let { min_age, max_age, gender, message, chosen_interests, chosen_where, selected_day, chosen_time, chosen_what, chosen_type, event_id, tab, invited_members, is_private, event, males, females, twenties, thirties, forties, fifties, sixties } = this.state; 

    // min_age, max_age, gender, message, chosen_interests, chosen_where, selected_day, chosen_time, chosen_what, chosen_type
    // -1 88 Female asdf asdf ,furniture restoration, 78b6bd95-b3fe-47e0-9d45-b63b14539815 2/7/2024 8.5 2 1

    chosen_type = "1";
    chosen_what = "1";

    let all_required_present = message && message.length && chosen_where && chosen_where !== "-1" && selected_day && chosen_time && chosen_what && chosen_what !== "-1" && chosen_type && chosen_type !== "-1";

    console.log('min_age, max_age, gender, message, chosen_interests, chosen_where, selected_day, chosen_time, chosen_what, chosen_type');
    console.log(min_age, max_age, gender, message, chosen_interests, chosen_where, selected_day, chosen_time, chosen_what, chosen_type);

    if(invited_members && invited_members.length) {
      invited_members = invited_members.map(user=>user.user_id)
    }

    let data = {
      ...event,
      is_private,
      males, females, twenties, thirties, forties, fifties, sixties,
      min_age, max_age, gender, message, 
      chosen_interests, chosen_where, 
      selected_day, chosen_time, chosen_what, 
      chosen_type, event_id, invited_members, tab
    }

    console.log('data', data)

    console.log('all_required_present', all_required_present) 

    if(all_required_present) {
      let response = await EventController.createUpdateEvent(data);

      console.log('responseresponse', response)

      this.setState({
        error_message: null
      }, ()=>{
        this.openAfterDraft(response.user_event);
      })
      
    } else {
      this.setState({
        error_message: "Please make sure to include type, place, topic, date & time and an invite message."
      })
    }
  }

  scrollTo(the_ref) {

    if(the_ref && the_ref.scrollIntoView) {
      the_ref.scrollIntoView();
    }
  }

  openProfileBubble(profile_attendee) {
    let user = this.state.org_user.user;

    console.log('profile_attendee', profile_attendee)

    profile_attendee = {profile: profile_attendee}

    this.props.putInParent(!this.state.is_profile_open ? 
      <TouchableOpacity activeOpacity={1} style={{zIndex: 10000000, position: 'fixed', width: '100vw', height: '100vh'}} onPress={this.openProfileBubble}>
        <Profile onPress={this.openProfileBubble} attendee={profile_attendee} />  
      </TouchableOpacity> : null);

    this.setState({
      profile_attendee,
      is_profile_open: !this.state.is_profile_open
    });
  }

  drawRequest(user_request) {
    console.log('user_request', user_request)

    let { profile, accepted } = user_request;
    profile = JSON.parse(profile);
    let { name, photo_url } = profile;

    return <View style={style.one_request_wrapper}>
          <TouchableOpacity onPress={()=>this.openProfileBubble(profile)} style={{width: 50}}>
            <Image style={style.profile_image} resizeMode={'cover'} source={{uri: photo_url ? photo_url : 'https://diningsocial-assets.s3.amazonaws.com/empty_profile.png'}} />
          </TouchableOpacity>
            <Text style={style.profile_name}>{name}</Text>
      <Text>{accepted ? 'accepted' : 'requested'}</Text>
      {!accepted ?
      <TouchableOpacity 
          onPress={()=>{this.approveRSVP(user_request)}} 
          style={{
          backgroundColor: '#e32652',
          alignSelf: 'flex-end',
          borderRadius: 3,
          paddingTop: 5,
          paddingBottom: 7,
          paddingHorizontal: 21}}

        ><Text style={style.button_text}>Approve</Text></TouchableOpacity> : null}
    </View>
  }

  render() {
    let { tab, mounted, min_age, max_age, gender, message, chosen_interests, is_mobile, error_message, event_id, is_private, join_requests, status, males, females, twenties, thirties, forties, fifties, sixties } = this.state; 

    let age_array = Array.from({ length: 98 }, (value, index) => {return {value: index, name: index}}).splice(21, 100);

    age_array = [{name: "Choose an age...", value: -1}].concat(age_array);

    chosen_interests = chosen_interests ? chosen_interests : '';
    let drawTags = chosen_interests.split(',').map(hobby=>{
      if(hobby.trim() !== '') {
        return <View><Text style={style.input_text_hobbie}>{hobby}</Text></View>
      } else {
        return null
      }
    });

    const drawInvitedMembers = () => {
      let { invited_members } = this.state;

      invited_members = invited_members && invited_members.length ? invited_members : [];

      return invited_members.map(result => {

        let { profile } = result;
    
        profile = profile ? JSON.parse(profile) : {};
        console.log('profile3', profile)
    
        let { name, photo_url } = profile;

        const removeInvited = (user) => {
          let { invited_members } = this.state;

          invited_members = invited_members.filter(a=>a.user_id !== user.user_id);

          this.setState({
            invited_members
          })
        }

        return <View style={style.profile_wrapper}>
          <TouchableOpacity style={style.x_wrapper} onPress={()=>removeInvited(result)}><Text style={style.invited_x} >x</Text></TouchableOpacity>
          <View style={{width: 50}}>
            <Image style={style.profile_image} resizeMode={'cover'} source={{uri: photo_url ? photo_url : 'https://diningsocial-assets.s3.amazonaws.com/empty_profile.png'}} />
          </View>
            <Text style={style.profile_name}>{name}</Text>
        </View>
      })
    }

    let requests_section;
    if(join_requests && join_requests.length) {

      requests_section = <View style={style.requests_section_wrapper}>
        <Text style={style.rsvp_requests_title}>RSVP Requests</Text>
        <View style={style.requests_wrapper}>
        {join_requests.map(this.drawRequest)}
        </View>
      </View>
    }

    return (<ScrollView ref={ref => this.scrollViewRef = ref}><View style={{paddingBottom: 100}}>

      <View>
        <Text style={style.intro_title}>Create a Coffee Invite</Text>
        <Text style={style.intro_text}>Coffee invites are limited to 4 people. Choose the time and whom you'd like to meet. After your event is published, you will get an email when someone requests to attend your invite. People you invite specifically will get approved right away. Other people you will need to approve 🙌. Read more FAQs below.</Text>
      </View>

        {mounted ? 

        <View style={style.create_wrapper}>
                  {requests_section}
              <View style={style.line}>
                    <Text style={style.column_title}>Logistics</Text>
              </View>
          <View style={style.logistics_wrapper}>

              {/* <View style={style.line2}>
                    <Text style={style.line_name}>What would you like to get?</Text>
                    <Text style={style.line_date}>
                      <View style={style.picker}>
                        {this.drawType()}
                      </View>
                    </Text>
              </View> */}

              <View style={style.line2}>
                    <Text style={style.line_name}>Where would you like to meet?</Text>
                    <Text style={style.line_date}>
                      <View style={style.picker}>
                        {this.drawWhere()}
                      </View>
                    </Text>
              </View>

              {/* <View style={style.line2}>
                    <Text style={style.line_name}>What would you like to connect over?</Text>
                    <Text style={style.line_date}>
                      <View style={style.picker}>
                        {this.drawWhat()}
                      </View>
                    </Text>
                    
              </View> */}
              <View style={style.line2}>
                    <Text style={style.line_name}>When would you like to meet?</Text>
                    <Text style={style.line_date}>
                      <View style={style.picker}>
                        {this.drawWhen()}
                      </View>
                    </Text>
                    
              </View>
  
          </View>  
          <View style={style.line}>
                      <Text style={style.column_title}>People you'd like to meet</Text>
                </View>
          <View style={style.logistics_wrapper}>
            <View style={style.section_1_wrapper}>

                {/* <View style={{flexDirection: 'row', alignItems: 'center'}}>
                  <TouchableOpacity onPress={()=>{this.setState({tab: 0})}}><Text style={[style.toggle_button, tab === 0 ? style.toggle_button_selected : {}]}>Select Demographic</Text></TouchableOpacity>
                  <TouchableOpacity onPress={()=>{this.setState({tab: 1})}}><Text style={[style.toggle_button, tab === 1 ? style.toggle_button_selected : {}]}>Select Individuals</Text></TouchableOpacity>
                </View> */}
                {/* {tab === 0 ? */}
                {/* <View style={style.section}>
                  <Text style={global_style.normal_text_dark_medium}>Select Demographic</Text>
                  <View style={style.line}>
                        <Text style={style.line_name}>Age Range (optional)</Text>
                        <Text style={style.line_date}>
                          <View style={style.picker}>
                          <View style={[style.edit_row, {marginTop: 10}]}>
                              <View style={[style.a_row, style.age_drop_wrapper]}>
                                <View style={style.age_wrapper}>
                                    <Text style={style.input_label_small}>Min age</Text>
                                    <Picker
                                        selectedValue={min_age}
                                        mode={'dialog'}
                                        style={style.drop_style_small}
                                        onValueChange={(value)=>this.updateState(value, 'min_age')}
                                      >
                                        {age_array.map(v => {
                                          return <Picker.Item
                                            fontSize={16}
                                            style={{ fontSize: 16 }}
                                            key={v.value}
                                            label={v.name}
                                            value={v.value}
                                          />
                                        })}
                                      </Picker>
                                  </View>
                                  <View>
                                    <Text style={style.input_label_small}>Max age</Text>
                                  <Picker
                                      selectedValue={max_age}
                                      mode={'dialog'}
                                      style={style.drop_style_small}
                                      onValueChange={(value)=>this.updateState(value, 'max_age')}
                                    >
                                      {age_array.map(v => {
                                        return <Picker.Item
                                            fontSize={16}
                                            style={{ fontSize: 16 }}
                                            key={v.value}
                                            label={v.name}
                                            value={v.value}
                                          />
                                      })}
                                    </Picker>
                                  </View>
                              </View>
                            </View>
                          </View>
                        </Text>
                  </View>
                  <View style={style.line}>
                        <Text style={style.line_name}>Hobby Tags (optional)</Text>
                        <Text style={style.line_name}>Members that have these hobbies selected on their profile (or select Open To Trying New Things) will get a notification when you publish this event.</Text>
                        <Text style={style.line_date}>
                        <TouchableOpacity onPress={this.openTags}><Text style={global_style.normal_link}>choose hobbies</Text></TouchableOpacity>
                        </Text>
                        <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
                          {drawTags}
                          </View>
                  </View>
                  <View style={style.line}>
                        <Text style={style.line_name}>Gender (optional)</Text>
                        <Text style={style.line_date}>
                          <View style={style.picker}>
                          <View style={[style.a_row, style.age_drop_wrapper]}>
                          <View style={style.age_wrapper}>
                            <Picker
                                  selectedValue={gender}
                                  mode={'dialog'}
                                  style={style.drop_style}
                                  onValueChange={(value)=>this.updateState(value, 'gender')}
                                >
                                  {['Choose gender of participants...', 'Female', 'Male', 'Non-binary'].map(v => {
                                    return <Picker.Item
                                      fontSize={16}
                                      style={{ fontSize: 16 }}
                                      key={v}
                                      label={v}
                                      value={v}
                                    />
                                  })}
                                </Picker>
                          </View>
                        </View>
                          </View>
                        </Text>
                  </View>
                </View> */}

                <View style={[style.line, {flexDirection: 'row'}]}>
                  <View style={{width: 200}}>
                    <Input checked={twenties} onCheck={()=>{this.toggleState('twenties')}}
                    check_style={{check_wrapper: {flexDirection: 'row', flexWrap: 'wrap', display: 'flex', marginLeft: 10}, check_text: {
                      flexWrap: 'wrap',
                        overflowWrap: 'anywhere',
                        textWrap: 'wrap',
                        marginLeft: 10,
                        fontFamily: 'Ysabeau',
                        fontSize: 16,
                        width: is_mobile ? 'calc(100vw - 80px)' : 'unset',
                        color: 'white'}}}
                    type="check" label={<Text style={global_style.normal_text_dark_medium}>20s</Text>} />

                    <Input checked={thirties} onCheck={()=>{this.toggleState('thirties')}}
                    check_style={{check_wrapper: {flexDirection: 'row', flexWrap: 'wrap', display: 'flex', marginLeft: 10}, check_text: {
                      flexWrap: 'wrap',
                        overflowWrap: 'anywhere',
                        textWrap: 'wrap',
                        marginLeft: 10,
                        fontFamily: 'Ysabeau',
                        fontSize: 16,
                        width: is_mobile ? 'calc(100vw - 80px)' : 'unset',
                        color: 'white'}}}
                    type="check" label={<Text style={global_style.normal_text_dark_medium}>30s</Text>} />
                     <Input checked={forties} onCheck={()=>{this.toggleState('forties')}}
                    check_style={{check_wrapper: {flexDirection: 'row', flexWrap: 'wrap', display: 'flex', marginLeft: 10}, check_text: {
                      flexWrap: 'wrap',
                        overflowWrap: 'anywhere',
                        textWrap: 'wrap',
                        marginLeft: 10,
                        fontFamily: 'Ysabeau',
                        fontSize: 16,
                        width: is_mobile ? 'calc(100vw - 80px)' : 'unset',
                        color: 'white'}}}
                    type="check" label={<Text style={global_style.normal_text_dark_medium}>40s</Text>} />
                     <Input checked={fifties} onCheck={()=>{this.toggleState('fifties')}}
                    check_style={{check_wrapper: {flexDirection: 'row', flexWrap: 'wrap', display: 'flex', marginLeft: 10}, check_text: {
                      flexWrap: 'wrap',
                        overflowWrap: 'anywhere',
                        textWrap: 'wrap',
                        marginLeft: 10,
                        fontFamily: 'Ysabeau',
                        fontSize: 16,
                        width: is_mobile ? 'calc(100vw - 80px)' : 'unset',
                        color: 'white'}}}
                    type="check" label={<Text style={global_style.normal_text_dark_medium}>50s</Text>} />
                     <Input checked={sixties} onCheck={()=>{this.toggleState('sixties')}}
                    check_style={{check_wrapper: {flexDirection: 'row', flexWrap: 'wrap', display: 'flex', marginLeft: 10}, check_text: {
                      flexWrap: 'wrap',
                        overflowWrap: 'anywhere',
                        textWrap: 'wrap',
                        marginLeft: 10,
                        fontFamily: 'Ysabeau',
                        fontSize: 16,
                        width: is_mobile ? 'calc(100vw - 80px)' : 'unset',
                        color: 'white'}}}
                    type="check" label={<Text style={global_style.normal_text_dark_medium}>60s+</Text>} />
                  </View>
                  <View>
                    <Input checked={males} onCheck={()=>{this.toggleState('males')}}
                    check_style={{check_wrapper: {flexDirection: 'row', flexWrap: 'wrap', display: 'flex', marginLeft: 10}, check_text: {
                      flexWrap: 'wrap',
                        overflowWrap: 'anywhere',
                        textWrap: 'wrap',
                        marginLeft: 10,
                        fontFamily: 'Ysabeau',
                        fontSize: 16,
                        width: is_mobile ? 'calc(100vw - 80px)' : 'unset',
                        color: 'white'}}}
                    type="check" label={<Text style={global_style.normal_text_dark_medium}>Males</Text>} />

                    <Input checked={females} onCheck={()=>{this.toggleState('females')}}
                    check_style={{check_wrapper: {flexDirection: 'row', flexWrap: 'wrap', display: 'flex', marginLeft: 10}, check_text: {
                      flexWrap: 'wrap',
                        overflowWrap: 'anywhere',
                        textWrap: 'wrap',
                        marginLeft: 10,
                        fontFamily: 'Ysabeau',
                        fontSize: 16,
                        width: is_mobile ? 'calc(100vw - 80px)' : 'unset',
                        color: 'white'}}}
                    type="check" label={<Text style={global_style.normal_text_dark_medium}>Females</Text>} />

                  </View>
                </View>
                
                {/*  : null} */}
                {/* {tab === 1 ?  */}
            </View>
            <View style={style.section_2_wrapper}>
                <View style={{flexDirection: 'row', justifyContent: 'space-between'}}><Text style={global_style.normal_text_dark_medium}>Invite Individuals</Text><Button title={<Text style={style.button_text}>Search Members</Text>}
                  loading={false}
                  style={{
                    backgroundColor: '#e32652',
                    alignSelf: 'flex-end',
                    borderRadius: 10,
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingHorizontal: 10}}

                  onPress={this.openMemberSearch} /></View>
                <View style={style.invited_wrapper}>
                {drawInvitedMembers()}
                
                </View>
            </View>
          </View>     
          <View style={style.line}></View>
          <View style={style.line}>
                <Text style={style.column_title}>Invite Message</Text>
          </View>             
          <View style={style.bottom_wrapper}>
              <View style={{flex: 1}}>
                <View style={style.line}>
                    <Text style={style.line_date}>
                      <Input multiline={true} style={[style.multitext_box, {width: is_mobile ? 'calc(100vw - 40px)' : 400, height: 100}]} type="text" defaultValue={message} value={message} placeholder="Welcome your participants and include more details." onChangeText={event => { this.updateState(event, 'message') }} />
                    </Text>
                </View>
              </View>
              <View style={style.buttons_wrapper}>
              {/* <View>
                <View style={style.line}>
                  <Input checked={is_private} onCheck={()=>{this.toggleState('is_private')}}
                    check_style={{check_wrapper: {flexDirection: 'row', flexWrap: 'wrap', display: 'flex', marginLeft: 10}, check_text: {
                      flexWrap: 'wrap',
                        overflowWrap: 'anywhere',
                        textWrap: 'wrap',
                        marginLeft: 10,
                        fontFamily: 'Ysabeau',
                        fontSize: 16,
                        width: is_mobile ? 'calc(100vw - 80px)' : 'unset',
                        color: 'white'}}}
                    type="check" label={<Text style={global_style.normal_text_dark_medium}>Make this a private event only for the people I've invited.</Text>} />
                </View>
                <View style={style.line}>
                  <Text style={style.explanation_bottom}>
                  {is_private ? `Right now only the people you've invited will be able to see and rsvp for this event.` : `Anyone will be able to RSVP for this event, but you'll get the option to accept their RSVP. Make sure to choose a demographic you're comfortable with.`}</Text>
                </View>
              </View> */}

              <View style={style.edit_button_wrapper}>
                {status !== 'published' ?
              <Button title={<Text style={style.button_text}>{event_id ? 'Update Draft' : 'Create Draft'}</Text>}
                loading={false}
                style={{
                  backgroundColor: '#0dc6e3',
                  alignSelf: is_mobile ? 'unset' : 'flex-end',
                  borderRadius: 3,
                  paddingTop: 5,
                  paddingBottom: 7,
                  width: 200,
                  paddingHorizontal: 5, marginBottom: 10}}

                onPress={this.createInvite} /> : null}

              {error_message ? <Text style={global_style.error_text}>{error_message}</Text> : null}
              </View>

            <View style={style.publish_button_wrapper}>
            
              <TouchableOpacity 
                  onPress={event_id ? this.publishConfirm : this.notYetPublish } 
                  style={{
                  backgroundColor: event_id ? '#e32652' : '#c5adb3',
                  alignSelf: 'flex-end',
                  borderRadius: 3,
                  paddingTop: 5,
                  paddingBottom: 7,
                  paddingHorizontal: 21}}

                ><Text style={style.button_text}>Publish {status === 'published' ? "Changes" : "Event"}</Text></TouchableOpacity>
                <TouchableOpacity onPress={this.scrollToFaq}><Text style={style.info_bubble}>?</Text></TouchableOpacity>
            </View>
            {event_id ?
            <View style={style.edit_button_wrapper}>
              <Button title={<Text style={style.button_text}>Delete Event</Text>}
                loading={false}
                style={{
                  backgroundColor: 'red',
                  alignSelf: is_mobile ? 'unset' : 'flex-end',
                  borderRadius: 3,
                  paddingTop: 5,
                  paddingBottom: 7,
                  width: 200,
                  paddingHorizontal: 5, marginTop: 10}}

                onPress={this.deleteConfirm} />
            </View> : null}
          </View>
          </View>
          
          
        </View> : 
        <View style={{backgroundColor: '#black', width: '100vw', height: '100vh'}}>
          
        </View>}
        <View ref={node => this.faqRef = node} >
        <FAQ chosen_section={1} />
        </View>
        
      </View>
      </ScrollView>
    );
  }
}

function CreateInvite(props) {
  if (Platform.OS === 'web') {
    let navigate = useNavigate();
    let { event_id } = useParams();

    return <CreateInvite_Base {...props} event_id={event_id} navigate={navigate} />
  } else {
    return <CreateInvite_Base {...props} navigate={props.navigation.push} />
  }
}

export default CreateInvite;
