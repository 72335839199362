import React, { Component } from 'react';
import { View, Image, StyleSheet, TouchableOpacity, Dimensions, Text } from 'react-native';
import { manipulateAsync, SaveFormat } from 'expo-image-manipulator';
import { PanGestureHandler, State } from 'react-native-gesture-handler';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default class CropImage extends Component { 
  constructor(props) {
    super(props);
    this.state = {
      topLeft: { x: 50, y: 50 },
      topRight: { x: this.props.style.width - 50, y: 50 },
      bottomLeft: { x: 50, y: this.props.style.height - 150 },
      bottomRight: { x: this.props.style.width - 50, y: this.props.style.height - 150 },
      lastX: 0,
      lastY: 0,
    };

    this.styles = {
      corner: {
        width: 30,
        height: 30,
        backgroundColor: 'red',
        position: 'absolute',
      },
      cropButton: {
        position: 'absolute',
        bottom: 50,
        left: this.props.style.width / 2 - 50,
        backgroundColor: '#1e90ff',
        borderRadius: 5,
        padding: 10,
      },
    }
  }

  cropImage = async () => {
    const { topLeft, topRight, bottomLeft } = this.state;
    const { photo } = this.props;

    const croppedImage = await manipulateAsync(
      photo.uri,
      [
        {
          crop: {
            originX: topLeft.x,
            originY: topLeft.y,
            width: topRight.x - topLeft.x,
            height: bottomLeft.y - topLeft.y,
          },
        },
      ],
      { compress: 1, format: SaveFormat.PNG }
    );
    console.log(croppedImage);
  };

  handleDrag = (corner, event) => {
    const { translationX, translationY } = event.nativeEvent;
    const { lastX, lastY } = this.state;

    switch (corner) {
      case 'topLeft':
        this.setState({
          topLeft: { x: lastX + translationX, y: lastY + translationY },
          lastX: lastX + translationX,
          lastY: lastY + translationY,
        });
        break;
      case 'topRight':
        this.setState({
          topRight: { x: lastX + translationX, y: lastY + translationY },
          lastX: lastX + translationX,
          lastY: lastY + translationY,
        });
        break;
      case 'bottomLeft':
        this.setState({
          bottomLeft: { x: lastX + translationX, y: lastY + translationY },
          lastX: lastX + translationX,
          lastY: lastY + translationY,
        });
        break;
      case 'bottomRight':
        this.setState({
          bottomRight: { x: lastX + translationX, y: lastY + translationY },
          lastX: lastX + translationX,
          lastY: lastY + translationY,
        });
        break;
      default:
        break;
    }
  };

  render() {
    const { photo } = this.props;
    const { topLeft, topRight, bottomLeft, bottomRight } = this.state;

    return (
      <View style={{ flex: 1 }}>
        <Image source={{ uri: photo.uri }} style={StyleSheet.absoluteFill} />
        <PanGestureHandler
          onGestureEvent={(e) => this.handleDrag('topLeft', e)}
          onHandlerStateChange={(e) => {
            if (e.nativeEvent.state === State.END) {
              this.setState({ lastX: topLeft.x, lastY: topLeft.y });
            }
          }}
        >
          <View style={[this.styles.corner, { left: topLeft.x, top: topLeft.y }]} />
        </PanGestureHandler>
        <PanGestureHandler
          onGestureEvent={(e) => this.handleDrag('topRight', e)}
          onHandlerStateChange={(e) => {
            if (e.nativeEvent.state === State.END) {
              this.setState({ lastX: topRight.x, lastY: topRight.y });
            }
          }}
        >
          <View style={[this.styles.corner, { left: topRight.x, top: topRight.y }]} />
        </PanGestureHandler>
        <PanGestureHandler
          onGestureEvent={(e) => this.handleDrag('bottomLeft', e)}
          onHandlerStateChange={(e) => {
            if (e.nativeEvent.state === State.END) {
              this.setState({ lastX: bottomLeft.x, lastY: bottomLeft.y });
            }
          }}
        >
          <View style={[this.styles.corner, { left: bottomLeft.x, top: bottomLeft.y }]} />
        </PanGestureHandler>
        <PanGestureHandler
          onGestureEvent={(e) => this.handleDrag('bottomRight', e)}
          onHandlerStateChange={(e) => {
            if (e.nativeEvent.state === State.END) {
              this.setState({ lastX: bottomRight.x, lastY: bottomRight.y });
            }
          }}
        >
          <View style={[this.styles.corner, { left: bottomRight.x, top: bottomRight.y }]} />
        </PanGestureHandler>
        <TouchableOpacity style={this.styles.cropButton} onPress={this.cropImage}>
          <Text style={{ color: 'white', fontSize: 18 }}>Crop</Text>
        </TouchableOpacity>
      </View>
    );
  }
}
