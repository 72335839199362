import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { View, Platform, TouchableOpacity, ScrollView, Image, Picker, Dimensions } from 'react-native';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Input, Text } from '../../components';
import authController from '../../controllers/authController';
import UserController from '../../controllers/userController';
import EventController from '../../controllers/eventController';
import PaymentController from '../../controllers/paymentController';
import Utilities from '../../utilities';
import { EditProfile, Profile } from '../../containers';
import LoginRegisterScreen from '../LoginRegister';
import RegisterScreen from '../Register';
import { style } from './style';
import { global_style } from '../../../global_style'
import { Analytics, PageHit } from 'expo-analytics';
import { config } from '../../../config';
import AuthController from '../../controllers/authController';
import Icon from 'react-native-vector-icons/FontAwesome';
import { Video } from 'expo-av';
const analytics = new Analytics('G-YCC5QPC8BJ');

class RSVPInvite_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password: null,
      tab: 0,
      terms: true,
      num_rsvp: 1,
      event_id: props.event_id,
      confirm_inactive: true
    }

    this.scrollViewRef = React.createRef();
    this.hostedRef = React.createRef();
    this.nonHostedRef = React.createRef();
    this.logisticsRef = React.createRef();
    this.attendeesRef = React.createRef();
    this.appsRef = React.createRef();

    this.updateEvent = this.updateEvent.bind(this);

    this.rsvpChange = this.rsvpChange.bind(this);
    this.rsvpClick = this.rsvpClick.bind(this);
    this.addCalendar = this.addCalendar.bind(this);
    this.scrollTo = this.scrollTo.bind(this);

    this.confirmMakeHost = this.confirmMakeHost.bind(this);
    this.confirmResignHost = this.confirmResignHost.bind(this);
    this.confirmCancel = this.confirmCancel.bind(this);

    this.drawAttendees = this.drawAttendees.bind(this);
    this.drawAttendee = this.drawAttendee.bind(this);
    this.openProfileBubble = this.openProfileBubble.bind(this);
    this.openInParent = this.openInParent.bind(this);
    this.fillOutProfile = this.fillOutProfile.bind(this);

    this.toggleConfirmRSVP = this.toggleConfirmRSVP.bind(this);
    this.toggleCancelRSVP = this.toggleCancelRSVP.bind(this);
    this.toggleChooseInvites = this.toggleChooseInvites.bind(this);

    this.drawMessage = this.drawMessage.bind(this);
    this.sendMessage = this.sendMessage.bind(this);

    this.refreshEvery20 = this.refreshEvery20.bind(this);
    this.onRegisterClick = this.onRegisterClick.bind(this);
    this.onLoginClick = this.onLoginClick.bind(this);

    this.updateUserLogging = this.updateUserLogging.bind(this);

    this.openRSVP = this.openRSVP.bind(this);

    this.chooseAfterLogin = this.chooseAfterLogin.bind(this);

    this.drawTheButton = this.drawTheButton.bind(this);

    this.subscriptionStart = this.subscriptionStart.bind(this);
    this.toggle = this.toggle.bind(this);
    this.hasAnotherEventWithinHour = this.hasAnotherEventWithinHour.bind(this);
    this.drawInvitee = this.drawInvitee.bind(this);

    this.selectInvitee = this.selectInvitee.bind(this);
    this.saveInvited = this.saveInvited.bind(this);

    this.updateEvent = this.updateEvent.bind(this);
    this.updateState = this.updateState.bind(this);
  }

  async componentDidMount() {
    analytics.hit(new PageHit('RSVPInvite'))
      .then(() => {})
      .catch(e => console.log("analytics error", e.message));

    let { event_id } = this.state;
    let org_user = await UserController.getUser();

    let isLoggedIn = !!org_user;

    if(!event_id || !org_user) {
      window.location.replace('/')
    }

    let user = org_user && org_user.user ? org_user.user : null;
    let profile = org_user && org_user.profile ? org_user.profile : null;
    let user_id = user && user.user_id ? user.user_id : null;

    let {rsvps} = await UserController.getDateRsvpsForEvent({event_id});
    let event_data = await AuthController.getEventAndPlace({event_id});

    rsvped = rsvps.filter(rsvp=>rsvp.user_id === user_id);
    rsvped = rsvped && rsvped.length ? true : false;

    let event, rsvped;
    event = event_data.event_data;

    let { invited, date, created_by } = event;
    console.log('eventevent', event)
    console.log('user_id', user_id)
    let i_am_owner = created_by === user_id;

    let creator = await UserController.getUsers({user_ids: [created_by]});
    creator = creator && creator.length ? creator[0] : null;

    let selected_people, selected_people_invites, people_i_met;
    if(!i_am_owner) {
      if(invited) {
        invited = invited[created_by]
        if(invited && invited.indexOf(user_id) === -1) {
          window.location.replace('/dashboard');
        }
      } else {
        window.location.replace('/dashboard');
      }
    } else {
      selected_people = invited;
      if(selected_people) {
        selected_people_invites = await UserController.getUsers({user_ids: selected_people});
      }

      people_i_met = await UserController.getPeopleIMet({page: 0});
    }

    let messages = await EventController.getMessages({event_id});

    let dinners = await UserController.getDinners();
    dinners = dinners && dinners.dinners ? dinners.dinners : [];
    let has_any_dinners = dinners.filter(din=>{
      return !din.event_data.free && !din.event_data.happy_hour
    });

    has_any_dinners = !!has_any_dinners.length;
    dinners = dinners.filter(a=>{return a.dinner_date === date});

    let time = event.time;
    let has_event_within_hour = this.hasAnotherEventWithinHour(dinners, time);

    let day = (new Date(date)).getDay();
    let day_name = Utilities.getDayName(day);

    this.setState({
      profile,
      isLoggedIn,
      people_i_met,
      selected_people,
      selected_people_invites,
      creator,
      user_id,
      time,
      i_am_owner,
      has_any_dinners,
      has_event_within_hour,
      rsvps,
      event,
      day_name,
      rsvped,
      date,
      day,
      mounted: true,
      messages
    }, async ()=>{
      this.refreshEvery20();
    })
  }

  async updateEvent() {

      let { event, event_id } = this.state;

      event = {
        ...event,
        event_id
      }

      let event_data = event;

      await EventController.updateEvent({event_id, event_data})

  
      window.location.reload();

  }

  hasAnotherEventWithinHour(dinners, time) {
    // Function to convert time representation (e.g., 13.5) to Date object
    function convertTimeToDate(hourDecimal) {
      const hours = Math.floor(hourDecimal);
      const minutes = Math.round((hourDecimal % 1) * 60);
      const date = new Date();
      date.setHours(hours, minutes, 0, 0);
      return date;
    }

    // Function to check if two times are more than an hour apart
    function areTimesMoreThanOneHourApart(time1, time2) {
      const date1 = convertTimeToDate(time1);
      const date2 = convertTimeToDate(time2);

      // Calculate time difference in milliseconds
      const timeDifference = Math.abs(date1 - date2);

      // Check if the time difference is greater than one hour (3600 seconds * 1000 milliseconds)
      const isMoreThanOneHourApart = timeDifference > 3600 * 1000;

      return isMoreThanOneHourApart;
    }

    let has_conflict = false;
    let time2, result;
    for(var i = 0; i < dinners.length; i++) {
      time2 = dinners[i].time;
      result = areTimesMoreThanOneHourApart(time, time2);

      if(result) {
        return true;
      }
    }
    
    return false;
  }

  toggle(name, callback) {
    console.log(name, this.state[name])
    this.setState({
      re_render: true,
      [name]: !this.state[name]
    }, () => {
      if(callback) {
        callback();
      }
    })
  }

  subscriptionStart(num) {
    let { cancel_url } = this.state;
    let starting = num === 1 ? 'yearly_loading' : 'monthly_loading';

    this.setState({[starting]: true}, async () => {
      let response = await PaymentController.createCheckoutSession({num, cancel_url});

      if(response && response.success) {
        window.location.replace(response.url);
      }
    });
  }

  getMinuteDifference(date1, date2) {
    const diffInMilliseconds = date2 - date1;
    const minutes = Math.floor(diffInMilliseconds / (100));
    return minutes;
  }

  getEventStatus(date) {
    // check if event is more than 7 days away to mark it as status: pre
    let targetDate = new Date(date); // Replace with your specific target date.
    // Calculate the date 7 days from now.
    let sevenDaysFromNow = new Date();
    sevenDaysFromNow.setDate(sevenDaysFromNow.getDate() + 7);

    // Adjust for the time zone (EST).
    sevenDaysFromNow = new Date(sevenDaysFromNow.toLocaleString('en-US', { timeZone: 'America/New_York' }));

    // Check if the target date is exactly 7 days away from the adjusted date.
    let isSevenDaysAway = targetDate.getTime() < sevenDaysFromNow.getTime();

    let status = isSevenDaysAway ? 'public' : 'pre';

    return status;
  }

  async updateUserLogging(afterRegisterClick) {
    let { event_id, date, time } = this.state
    let token = await localStorage.getItem('token');

    this.props.onLogin();

    let isLoggedIn = !!token;

    let subscription = await UserController.saveUser();

    let profile = subscription.profile ? subscription.profile : {};
    let user = subscription.user;
    let is_host = subscription.user ? subscription.user.host : false;
    let user_id = subscription.user ? subscription.user.user_id : null;
    let host_account = user ? user.host_account : null;
    subscription = subscription.subscription;

    let is_member = subscription && subscription.status ? subscription.status === 'trialing' || subscription.status === 'active' : false;

    is_member = is_member || !!host_account;

    let rsvps;
    ({rsvps} = await UserController.getDateRsvpsForEvent({event_id}));

    let dinners = await UserController.getDinners();
    dinners = dinners && dinners.dinners ? dinners.dinners : [];

    let has_any_dinners = dinners.length > 0;

    dinners = dinners.filter(a=>{return a.dinner_date === date})

    let has_event_within_hour = this.hasAnotherEventWithinHour(dinners, time);

    let rsvped = rsvps.filter(r=r.user_id === user_id);
    rsvped = rsvped && rsvped.length ? true : false;

    console.log('rsvped', rsvped);

    this.setState({
      has_any_dinners,
      rsvped,
      is_member,
      user_id,
      is_host,
      has_event_within_hour,
      isLoggedIn,
      profile
    }, () => {
      if(afterRegisterClick) {
        console.log('getting here2');
        afterRegisterClick();
      }
    })
  }

  openInParent(content, state_variable, width, height, backgroundColor, no_scroll) {
    console.log('drawing in parent again', state_variable, this.state[state_variable]);

    const clickScreen = null;
    //() =>this.toggleState(state_variable)

    let window_width = Dimensions.get('window').width;
    let is_mobile = window_width <= 700;

    let inner_style = {display: 'block', width: is_mobile ? '100vw' : width, height, left: is_mobile ? 0 : `calc(50% - ${width / 2}px)`}

    this.props.putInParent(!this.state[state_variable] ? 
      <TouchableOpacity activeOpacity={1} style={{zIndex: 10000000, position: 'fixed', width: '100vw', height: '100vh'}} onPress={clickScreen}>
      <>
        <TouchableOpacity activeOpacity={1} onPress={()=>this.toggleState(state_variable)} style={global_style.modal_overlay}></TouchableOpacity>
        <View style={style.payment_content}>
        <View style={[global_style.modal_content, inner_style]}>
            <TouchableOpacity onPress={()=>this.toggleState(state_variable)}><Text style={global_style.modal_close_x}>x</Text></TouchableOpacity>
            {no_scroll ?
            <View style={{height: height - 80, backgroundColor}}>
              {content}
            </View>
            :
            <ScrollView style={{height: height - 80, backgroundColor}}>
              {content}
          </ScrollView>}
        </View>
        </View>
      </>

      </TouchableOpacity> : null);

    this.setState({
      [state_variable]: !this.state[state_variable]
    });
  }

  openProfileBubble(profile_attendee) {

    this.props.putInParent(!this.state.is_profile_open ? 
      <TouchableOpacity activeOpacity={1} style={{zIndex: 10000000, position: 'fixed', width: '100vw', height: '100vh'}} onPress={this.openProfileBubble}>
        <Profile onPress={this.openProfileBubble} attendee={profile_attendee} />      
      </TouchableOpacity> : null);

    this.setState({
      profile_attendee,
      is_profile_open: !this.state.is_profile_open
    });
  }

  drawAttendee(attendee) {
    let photo_url, name;

    console.log('attendee', attendee)

    if(attendee) {
      let profile = attendee.profile;

      if(typeof profile === 'string') {
        profile = JSON.parse(profile);
      }

      ({photo_url, name} = profile);

      if(attendee.name) {
        name = attendee.name;
      }
    }

    return <TouchableOpacity onPress={()=>this.openProfileBubble(attendee)} style={[style.attendee_bubble]}>
      <Image style={style.attendee_photo} source={{uri: photo_url ? photo_url : 'https://diningsocial-assets.s3.amazonaws.com/empty_profile.png'}} />
        {/* <Text style={style.attendee_name_text}>{name ? name[0].toUpperCase() : "M"}</Text> */}
    </TouchableOpacity>
  }

  drawAttendees() {
    let { rsvps, event, creator } = this.state;

    let attendees = rsvps;
    console.log('rsvps', rsvps);

    return <View style={style.info_bubble_popup}>
        <Text style={[global_style.panel_sub_title, global_style.padding_subtitle]}>Attendees</Text>
          <Text style={style.tentative_title}>Host</Text>
          {this.drawAttendee(creator)}
          <View style={[global_style.line_breaker]}></View>
          <Text style={style.tentative_title}>RSVPs</Text>
          <View style={[style.one_bucket, {zIndex: 100}]}>
            <View style={style.first}>
              {attendees.map((attendee, i)=>{return this.drawAttendee(attendee)})}
            </View>
          </View>
        </View>
  }

  scrollTo(the_ref) {

    if(the_ref && the_ref.scrollIntoView) {
      the_ref.scrollIntoView();
    }
  }

  rsvpChange(num_rsvp, itemIndex) {
    this.setState({
      num_rsvp,
    })
  }

  checkProfile() {
    let { profile } = this.state;

    return !!profile.photo_url && !!profile.name && !!profile.job;
  }

  async confirmMakeHost() {
    let { event_id } = this.state;

    await UserController.confirmMakeHost({event_id});

    window.location.reload();
  }

  async confirmResignHost() {
    let { event_id } = this.state;

    await UserController.confirmResignHost({event_id});

    window.location.reload();
  }

  async confirmCancel() {
    let { event_id } = this.state;

    this.setState({cancel_loading: true}, async ()=>{
      await UserController.confirmCancel({event_id});

      window.location.reload();
    });
  }

  drawMessage(message) {
    let { profile, message_date } = message;

    console.log('message', message);
    console.log('profile', profile);

    let date = new Date(parseInt(message_date));

    let options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    let formatter = new Intl.DateTimeFormat('en-US', options);
    
    let date_time = formatter.format(date);

    return <View style={style.one_message}>
      {this.drawAttendee({profile, name: profile.name})}
      <View style={[global_style.column, style.chat_data]}>
        <Text style={style.chat_time}>{date_time}</Text>
        <Text style={style.chat_text}>{message.message}</Text>
      </View>
    </View>
  }

  async refreshEvery20() {
    let { event_id } = this.state;

    if(this.intervalId) {
      clearInterval(this.intervalId);
    }

    if(this.timeoutId) {
      clearTimeout(this.timeoutId)
    }

    const codeToRunEvery20Seconds = async () => {
      console.log('Code running every 20 seconds...');
      let messages = await EventController.getMessages({event_id});

      this.setState({
        messages
      })
    }

    const anotherFunctionAfter10Minutes = () => {
      console.log('Another function after 10 minutes...');
      this.setState({
        message_refresh: true
      })
    }

    this.intervalId = setInterval(codeToRunEvery20Seconds, 20000); // 20,000 milliseconds = 20 seconds

    // Set a timer to run another function after 10 minutes (600,000 milliseconds = 10 minutes)
    this.timeoutId = setTimeout(() => {
      clearInterval(this.intervalId); // Stop the code from running every 20 seconds
      anotherFunctionAfter10Minutes(); // Run another function
    }, 600000); // 600,000 milliseconds = 10 minutes
  }

  async sendMessage() {
    let { text_message, event_id } = this.state;

    if(text_message && text_message.trim()) {
      this.setState({sending_message: true}, async () => {

        let created_at = (new Date()).getTime();

        let messages = await UserController.sendMessage({message: text_message, event_id, created_at });

        this.refreshEvery20();

        this.setState({
          text_message: null,
          messages,
          sending_message: false
        })
      });
    }
  }

  async rsvpClick() {
    let has_profile = this.checkProfile();

    if(has_profile) {
      this.setState({rsvp_loading: true}, async () => {
        let { is_member, date, chosen_dinner_time, cancel_url, event, event_id, chosen_hosted, chosen_demographic, chosen_restaurant, checked_host, user_id, status } = this.state;

        let { invite_only } = event;

        if(date) {
          event = {
            ...event,
            date
          }
        }

        if(chosen_dinner_time) {
          event = {
            ...event,
            time: parseFloat(chosen_dinner_time)
          }
        }

        if(event_id) {
          event = {
            ...event,
            event_id
          }
        }

        console.log('event', event)

        let old_event_id = event_id;

        // Host gets free admission
        if(is_member || checked_host || event.free || status === 'pre' || invite_only) {
          console.log('1')
            let response = await UserController.confirmRSVP({date, event, status});
            let { event_id } = response;

            if(old_event_id) {
              window.location.reload();
            } else {
              window.location.replace(`${window.location.pathname}/${event_id}`);
            }
        } else {
          console.log('2')

          let response = await PaymentController.createEventSession({ cancel_url, event });
          
          console.log('response', response);
      
          if(response && response.success) {
            window.location.replace(response.url);
          }
        }
      });
    } else {
      this.fillOutProfile();
    }
  }

  fillOutProfile() {
    let { profile } = this.state;
    this.props.putInParent(null);


    const afterProfileUpdate = (profile) => {
      this.props.putInParent(null);
      this.setState({profile, is_open: false}, ()=>{
        this.rsvpClick(); 
      });
    }

    let window_width = Dimensions.get('window').width;

    this.openInParent(<View style={global_style.center_content}>
      
        <Text style={{fontFamily: 'Ysabeau', fontSize: 20, textAlign: 'center', marginBottom: 60 }}>A photo, name and your job are required in order to attend our events.</Text>
        <View style={{marginTop: -40, textAlign: 'left'}}>
        <EditProfile openEdit={true} profile={profile} afterUpdate={afterProfileUpdate} />
        </View>
    </View>, "is_open", window_width <= 700 ? window_width : 900, 500, 'white')
  }

  goDirections(link) {
    window.open(link);
  }

  goTo(link) {
    window.open(link)
  }

  addCalendar(event, place) {
    let { day, date, slug } = this.state;
    let { time, type } = event;

    let day_date, month, year;
    let tokens = date.split('/');

    day_date = tokens[1];
    if(day_date.length === 1) {
        day_date = `0${day_date}`
    }

    month = tokens[0];
    if(month.length === 1) {
        month = `0${month}`
    }

    year = tokens[2];

    let hours = Math.floor(time);
    let minutes = Math.round((time - hours) * 60);

    if(minutes.toString().length === 1) {
      minutes = `0${minutes}`
    }

    let end_time = time + 1.5;

    console.log('end_time', end_time)

    let end_hours = Math.floor(end_time);
    let end_minutes = Math.round((end_time - end_hours) * 60);

    if(end_minutes.toString().length === 1) {
      end_minutes = `0${end_minutes}`
    }

    let dinner_begin_time = `${hours}${minutes}00`;
    let dinner_end_time = `${end_hours}${end_minutes}00`;

    let dates_link = `${year}${month}${day_date}T${dinner_begin_time}/${year}${month}${day_date}T${dinner_end_time}`;

    let {restaurant_name, address } = place;

    let dining_type = Utilities.getEventTypeName(type);
    let dining_type_capitalized = `${dining_type[0].toUpperCase()}${dining_type.substring(1, dining_type.length)}`

    let link = `http://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(`Dining & Social Club ${dining_type_capitalized} at ${restaurant_name}`)}&dates=${dates_link}&details=${encodeURIComponent(`Dining & Social Club ${dining_type_capitalized} at ${restaurant_name} - Meet new friends over ${dining_type} - https://www.diningsocialnj.com/${slug}`)}&location=${address}&trp=false`;

    window.open(link);
  }

  updateInput(value, name) {

    this.setState({
      [name]: value
    });
  }

  updateEvent(value, name) {
    this.setState({updating_event: true}, () => {
      let { event } = this.state;

      event = {
        ...event,
        [name]: value
      }

      this.setState({
        event,
        updating_event: false
      })
    })
  }

  updateState(value, name, loading_name) {
    this.setState({[loading_name]: true}, () => {
      this.setState({
        [name]: value,
        [loading_name]: false
      })
    })
  }

  toggleState(name, callback) {
    console.log('toggling', name, this.state[name])

    if(this.state[name]) {
      this.props.putInParent(null)
    }

    let data = {
      is_rsvp_confirm_open: false,
      is_register_open: false,
      is_login_open: false,
      is_open: false, 
      is_make_host_open: false, 
      is_resign_host_open: false,
      is_cancel_open: false
    }

    if(name) {
      data = {
        ...data,
        [name]: !this.state[name]
      }
    } else {
      this.props.putInParent(null)
    }

    this.setState(data, () => {
      if(callback) {callback()}
    })
  }

  onRegisterClick(afterRegisterClick) {
    this.toggleState('is_register_open');

    let onRegister = async () => {
      console.log('getting here');
      await this.updateUserLogging(afterRegisterClick);
    }

    this.props.putInParent(null);
    this.openInParent(<View style={global_style.center_content}><Text style={{fontFamily: 'Ysabeau', fontSize: 20, textAlign: 'center', marginBottom: 30, marginTop: -22}}><RegisterScreen onRegister={onRegister} onLoginClick={this.onLoginClick} /></Text>
    </View>, "is_register_open", 500, 550, 'white')
  }

  onLoginClick(afterLoginCallback) {
    this.toggleState('is_login_open');

    let onLogin = async () => {
      console.log('getting here');
      await this.updateUserLogging(afterLoginCallback);
    }

    this.openInParent(<View style={global_style.center_content}><Text style={{fontFamily: 'Ysabeau', fontSize: 20, textAlign: 'center', marginBottom: 30, marginTop: -40}}><LoginRegisterScreen cancel_url={this.state.cancel_url} putInParent={this.props.putInParent} onLogin={onLogin} onRegisterClick={()=>this.onRegisterClick(afterLoginCallback)} /></Text>
    </View>, "is_login_open", 400, 500, 'white')
  }

  drawPrice(price, free, event) {
    let { isLoggedIn, is_member, has_any_dinners } = this.state;

    let onPress;

    if(isLoggedIn) {
      onPress = () => { this.props.navigate('/dashboard/subscription') };
    } else {

      onPress = this.onLoginClick
    }

    let fee_back = !is_member ? <View><Text style={style.price_explainer_wrapper}>Get your full fee back when you 1. follow us on Instagram or Facebook, 2. take a photo of your food and your new friends at dinner, 3. and tag us with the photo/s on Instagram or Facebook.</Text></View> : null;

    const afterInfo = () => {
      this.props.putInParent(null);
      
      let { isLoggedIn } = this.state;

      let has_profile = this.checkProfile();

      if(!isLoggedIn) {
        this.onLoginClick(this.chooseAfterLogin);
      } else {
        if(has_profile) {
          this.openRSVP();
        } else {
          this.fillOutProfile();
        }
      }
    }

    let member_price = event.member_price ? event.member_price : 0;
    let fee_back_price = event.fee_back_price ? event.fee_back_price : 0;
    let is_fee_back = event.is_fee_back ? event.is_fee_back : true;

    price = typeof price === 'string' ? price : `$${price}`;
    let p_width = typeof price === 'string' ? 46 : 36;

    fee_back_price = typeof fee_back_price === 'string' ? fee_back_price : `$${fee_back_price}`;
    let f_width = typeof fee_back_price === 'string' ? 46 : 36;

    member_price = typeof member_price === 'string' ? member_price : `$${member_price}`;
    let m_price = typeof member_price === 'string' ? 46 : 36;

    return free ? <View style={style.price_wrapper}>
      { isLoggedIn && has_any_dinners && event.new_member || (!isLoggedIn && event.new_member) ? <Text style={style.price_text_free_event}>EVENT ONLY OPEN TO NEW MEMBERS</Text> :
      <Text style={style.price_text_free_event}>FREE EVENT</Text>}
    </View> : 
    
    <TouchableOpacity onPress={()=>{this.openRSVP(isLoggedIn ? null : afterInfo)}} style={global_style.row}>
      <View style={global_style.column}>
        <View style={style.pricing_divider}>
          <Text style={[style.price_title]}>One-time</Text><Text style={[style.price_text, {width: p_width}]}>{price}</Text>
        </View>
        <View style={style.pricing_divider}>
          <Text style={[style.price_title]}>Member </Text><Text style={[style.price_text_member, {width: m_price}]}>{member_price}</Text>
        </View>
      {is_fee_back ?
        <View style={style.pricing_divider}>
          <Text style={[style.price_title]}>Fee Back Program</Text><Text style={[style.price_text_free, {width: f_width}]}>{fee_back_price}</Text>
        </View> : null}
        <View><Text style={[style.price_explanation]}>* This fee does not include the cost of the dinner. The one-time fee is offered to non-members who would like to attend an event before becoming a member.</Text></View>
        {/* <View style={style.price_wrapper}>
          <Text style={style.price_text}>${price}</Text> 
          
          <TouchableOpacity onPress={onPress}><Text style={style.price_member_text}>(member price<Text style={style.price_member_text_number}> $0.00</Text>)</Text></TouchableOpacity>


        </View>
      {fee_back} */}

      </View>
      
    </TouchableOpacity>
  }

  chooseAfterLogin() {
    window.location.reload();
  }

  togglePreRSVP() {
    let { isLoggedIn } = this.state;

    let has_profile = this.checkProfile();

    if(!isLoggedIn) {
      this.onLoginClick(this.chooseAfterLogin);
    } else {
      if(has_profile) {
        this.openPreConfirm();
      } else {
        this.fillOutProfile();
      }
    }
  }

  drawInvitePanel() {

    return <View>
      <Text></Text>
    </View>
  }

  openRSVP(onClick) {
    let rsvp_confirm_text = "Please be sure to let us know if you need to cancel your RSVP so that the event creator can plan accordingly";

    let member_panel = <>
          <Text style={{fontFamily: 'Ysabeau', fontSize: 20, textAlign: 'center', marginBottom: 30, marginTop: 20}}>{rsvp_confirm_text}</Text>

          <Button title={<Text style={style.club_button_text}>RSVP</Text>}
            disable_imediately= {true} 

            style_inactive={{backgroundColor: '#85bebe',
              alignSelf: 'center',
              borderRadius: 3,
              paddingTop: 5,
              paddingBottom: 7,
              paddingHorizontal: 20, width: 207}}

            style={{backgroundColor: '#0b6665',
            alignSelf: 'center',
            borderRadius: 3,
            paddingTop: 5,
            paddingBottom: 7,
            paddingHorizontal: 20, width: 207}}
            onPress={this.rsvpClick} />
        </>

    this.openInParent(<View style={global_style.center_content}>
        {member_panel}
        </View>, "is_rsvp_confirm_open", 400, 360, 'white')
  }

  toggleConfirmRSVP() {
    let { isLoggedIn } = this.state;

    let has_profile = this.checkProfile();

    if(!isLoggedIn) {
      this.onLoginClick(this.chooseAfterLogin);
    } else {
      if(has_profile) {
        this.openRSVP();
      } else {
        this.fillOutProfile();
      }
    }
  }

  selectInvitee(attendee) {
    let { user_id } = attendee;
    let { selected_people, selected_people_invites } = this.state;
    selected_people = selected_people ? selected_people : [];
    selected_people_invites = selected_people_invites ? selected_people_invites : [];

    let index_of_user = selected_people.indexOf(user_id);
    if(index_of_user === -1) {
      selected_people.push(user_id);
      selected_people_invites.push(attendee);
    } else {
      selected_people.splice(index_of_user, 1)

      selected_people_invites = selected_people_invites.filter(a => a.user_id !== user_id);
    }

    this.setState({
      selected_people_invites,
      selected_people,
      is_choose_invites_open: false
    }, ()=>{
      this.toggleChooseInvites();
    })
  }

  drawInvitee(attendee, onSelect) {
    let photo_url, name, job;

    let { selected_people, user_id } = this.state;

    console.log('attendee', attendee)

    if(attendee) {
      let profile = attendee.profile;
      user_id = attendee.user_id;

      if(typeof profile === 'string') {
        profile = JSON.parse(profile);
      }

      ({photo_url, name, job} = profile);

      if(attendee.name) {
        name = attendee.name;
      }
    }

    console.log('selected_people', selected_people);
    let selected = selected_people ? selected_people.indexOf(user_id) !== -1 : false;

    return <TouchableOpacity onPress={()=>onSelect(attendee)} style={[style.invitee_bubble]}>
      
      <Image style={style.invitee_photo} source={{uri: photo_url ? photo_url : 'https://diningsocial-assets.s3.amazonaws.com/empty_profile.png'}} />
      <View style={{flexDirection: 'column', width: 100, overflowWrap: 'anywhere'}}>
        <Text style={style.uninvite_name}>{name}</Text>
        <Text style={style.uninvite_job}>{job}</Text>
      </View>
      <Text style={style.uninvite_select}>{selected ? 'de-select': 'select'}</Text>
    </TouchableOpacity>
  }

  async saveInvited() {
    let { event_id, selected_people } = this.state;

    await UserController.saveInvited({event_id, selected_people});

    this.toggleChooseInvites()
  }

  async saveNonInvited() {
    let { event_id, selected_people } = this.state;

    await UserController.saveUnInvited({event_id, selected_people});

    this.toggleChooseUnInvites()
  }

  toggleChooseAsMember() {


    let window_width = Dimensions.get('window').width;
    let is_mobile = window_width <= 700;

    this.openInParent(<View style={global_style.center_content}>
      <Text style={{fontFamily: 'Ysabeau', fontSize: 20, textAlign: 'center'}}>Not vibing with someone?</Text> 
      <Text style={style.experience_text}>Your experience is the most important thing to us. Don't be shy to let us know. As a member, you're able to tell us which members you would not like to dine with so that they cannot RSVP after you have.</Text> 
      <Button loading={false} title={<Text style={[style.club_button_text, {marginHorizontal: 0}]}>Become a Member</Text>}
                style_inactive={{backgroundColor: 'black',
            alignSelf: 'center',
            borderRadius: 3,
            paddingTop: 5,
            paddingBottom: 7,
            paddingHorizontal: 20, width: 207}}
          style={{backgroundColor: 'black',
          borderRadius: 3,
          paddingTop: 5,
          marginTop: 20,
          paddingBottom: 7,
          paddingHorizontal: 20, width: 250}}
          disable_imediately={true}
          onPress={()=>{window.location.replace('/dashboard/subscription')}} />
      </View>, "is_choose_member_open", is_mobile ? 200 : 400, 300, 'white', true)
  }

  async toggleChooseInvites() {
    this.props.putInParent(null)
    this.setState({is_choose_open: false}, ()=>{
      let { people_i_met, selected_people_invites } = this.state;

      console.log('people_i_met', people_i_met);

      const getMore = async () => {
        let { people_page, people_i_met } = this.state;
        people_page = people_page ? people_page + 1 : 1;

        let new_people_i_met = await UserController.getPeopleIMet({page: people_page});

        people_i_met = people_i_met.concat(new_people_i_met);

        console.log('people_i_met2', people_i_met);

        this.setState({
          people_i_met,
          people_page,
          is_choose_invites_open: false
        }, ()=>{
          this.toggleChooseInvites()
        })
      }
      
      let invited_people = selected_people_invites ? selected_people_invites : [];

      this.openInParent(<View style={global_style.center_content}>
        <Text style={{fontFamily: 'Ysabeau', fontSize: 20, textAlign: 'center'}}>Choose which members you'd like to invite</Text> 
        <Text style={style.experience_text}>Only the invited members will be able to RSVP.</Text> 

        <View style={{flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
          <View style={style.invite_column}>
            <Text style={style.uninvite_title}>People you've met</Text>
            <ScrollView style={{height: 100, paddingBottom: 100}}>
              <View style={{height: 270, paddingBottom: 100, overflow: 'scroll'}}>
                {people_i_met.length ?
                  people_i_met.map((a)=>this.drawInvitee(a,this.selectInvitee)) : <Text style={[global_style.normal_text_dark, {backgroundColor: '#d0edd0', borderRadius: 10, padding: 20}]}>You haven't met any people yet. Once you do, you'll be able to say which people you'd like to meet again when you RSVP for an event.
                  </Text>}
                  {people_i_met.length ?
              <TouchableOpacity onPress={getMore}><Text style={style.load_more_button}>load more</Text></TouchableOpacity> : null}
              </View>
            </ScrollView>
          </View>
          <View style={style.invite_column}>
          <Text style={style.uninvite_title}>People you've invited</Text>
            <ScrollView style={{flex: 1, height: 200}}>
            <View style={{height: 270, paddingBottom: 100, overflow: 'scroll'}}>
              {invited_people.map((a)=>this.drawInvitee(a,this.selectInvitee))}
              </View>
            </ScrollView>
          </View>
        </View>
        <View style={{marginTop: 20}}>
        <Button title={<Text style={style.club_button_text}>Save</Text>}
          loading={false}
          style={style.club_button}
          style_inactive={style.club_button}
          inactive={false}
          onPress={this.saveInvited} />
          </View>
        </View>, "is_choose_invites_open", 800, 550, 'white', true);
    });
  }

  toggleCancelRSVP() {
    let { cancel_loading, event, user_id } = this.state;

    this.openInParent(<View style={global_style.center_content}>
      <Text style={{fontFamily: 'Ysabeau', fontSize: 20, textAlign: 'center', marginTop: 40, marginBottom: 60}}>You're canceling your RSVP. Thank you for letting us know!</Text>

      <Button loading={cancel_loading} title={<Text style={[style.club_button_text, {marginHorizontal: 0}]}>Cancel RSVP</Text>}
                style_inactive={{backgroundColor: '#85bebe',
            alignSelf: 'center',
            borderRadius: 3,
            paddingTop: 5,
            paddingBottom: 7,
            paddingHorizontal: 20, width: 207}}
          style={{backgroundColor: '#0b6665',
          borderRadius: 3,
          paddingTop: 5,
          paddingBottom: 7,
          paddingHorizontal: 20, width: 208}}
          disable_imediately={true}
          onPress={this.confirmCancel} /></View>, "is_cancel_open", 400, 400, 'white')
  }

  drawTheButton() {

    let { subscription_loading, mounted, rsvped, has_event_within_hour, is_member, pre_rsvped, status, isLoggedIn, loading, confirm_inactive, event } = this.state;

    console.log('isLoggedInisLoggedIn', isLoggedIn)

    let show_confirm_button = !rsvped && !has_event_within_hour;

    return <View>{subscription_loading || !mounted ? <View style={{width: 60, height: 60, alignSelf: 'center', paddingTop: 100}}>
    <Progress.CircleSnail size={60} backgroundColor="black" fill='black' color={'white' } />
    </View> : <View>

      {show_confirm_button ?
      <Button title={<Text style={style.club_button_text}>Confirm RSVP</Text>}
        loading={loading}
        style={style.club_button}
        style_inactive={style.club_button}
        inactive={false}
        onPress={this.toggleConfirmRSVP} /> : null }

      {has_event_within_hour && !rsvped ? <Text style={[global_style.medium_description, global_style.center_content, global_style.text_vibrant_red, global_style.text_uppercase, global_style.padding_top_20]}>You're already have another event within the hour</Text> : null}

      {rsvped ? <View style={style.buttons_wrapper}>      
          <Button title={<Text style={style.club_button_text}>Cancel RSVP</Text>}
            style={style.club_button}
            style_inactive={style.club_button_inactive}
            onPress={this.toggleCancelRSVP} />
        </View> : null}
  
      {!isLoggedIn ?
          <Button title={<Text style={style.club_button_text}>RSVP</Text>}
            loading={loading}
            style={style.club_button}
            style_inactive={style.club_button_inactive}
            inactive={confirm_inactive}
            onPress={this.togglePreRSVP} /> : null}
  
    </View> }</View>
  }

  render() {
    let { mounted, date, rsvped, day_name, event, user_id, messages, text_message, sending_message, message_refresh, isLoggedIn, updating_event, i_am_owner } = this.state; 

    let restaurant_container, background_color, event_name, description, tbd, place_holder_image, event_location, time;

    console.log('chosen_hosted', event)
    let window_width = Dimensions.get('window').width;
    let is_mobile = window_width < 700;

    if(mounted) {
      time = event.time;
      event_name = event.name;
      ({ description, place_holder_image, event_location} = event)

      tbd = !!event.tbd;

      restaurant_container = <View style={style.restaurant_wrapper}>
        <View style={[style.kitchestep_image_text_wrapper]}>
          <View style={style.kitchenstep_main_wrapper}>
            <Image resizeMode={'contain'} style={style.kitchenstep_main} source={{uri: place_holder_image ? place_holder_image : 'https://s3.amazonaws.com/www.diningsocialnj.com/restaurant_placeholder.png'}} />
          </View>
        </View>
      </View>
    }

    return (<ScrollView ref={ref => this.scrollViewRef = ref}><View style={{paddingBottom: 100}}>
        {mounted ? 
        <View style={{flexDirection: 'column'}}>
          <View style={[style.page_container, {backgroundColor: background_color}]}>
            <View style={[style.one_column, style.restaurant_container]}>{restaurant_container}</View>
            <View style={[style.one_column, rsvped ? style.data_column_rsvped : style.data_column]}>

              {rsvped ? <Text style={style.main_title_name}>You're going to: {event_name}</Text> : <Text style={style.main_title_name}>{event_name}</Text>}

              <View style={style.line}>
                <Text style={style.line_name}>When</Text>
                {!i_am_owner ?
                <Text style={style.line_date}>
                  {day_name} - {date} {time ? time : null}&nbsp;&nbsp;
                
                  {rsvped ?
                  <View>
                    <TouchableOpacity onPress={()=>this.addCalendar(event, place)}><Text style={global_style.normal_light_link}>Add to Calendar</Text></TouchableOpacity>
                  </View> : null}
                </Text> : null}
                
                {i_am_owner ?
                <Text style={style.line_date}>
                <View style={style.picker}>
                  <Input style={style.value_input} placeholder={"When are people meeting?"} value={time} onChangeText={(event)=>{this.updateEvent(event, 'time')}} multiline={"true"} />
                  </View>
                </Text> : null}
              </View>

              <View style={style.line}>
                <Text style={style.line_name}>Where</Text>
                {i_am_owner ?
                <Text style={[style.line_date, global_style.column, {display: 'flex'}]}>
                  <View style={style.picker}>
                    <Input style={style.value_input} placeholder={"Where is the event taking place"} value={event_location} onChangeText={(event)=>{this.updateEvent(event, 'event_location')}} multiline={"true"} />
                  </View>
                </Text> : null}

                {!i_am_owner ?
                <Text style={style.line_date}>
                  {event_location ? event_location : 'TBD'}
                </Text> : null}
              </View>

              <View style={style.line}>
                <Text style={style.line_name}>What</Text>
                {i_am_owner ?
                <Text style={[style.line_date, global_style.column, {display: 'flex'}]}>
                  <View style={style.picker}>
                    <Input style={style.value_input} placeholder={"What are we doing?"} value={description} onChangeText={(event)=>{this.updateEvent(event, 'description')}} multiline={"true"} />
                  </View>
                </Text> : null}

                {!i_am_owner ?
                <Text style={style.line_date}>
                  {description ? description : 'TBD'}
                </Text> : null}
              </View>
              
              {i_am_owner ? <View style={style.main_rsvp_wrapper}>
                <Button title={<Text style={style.club_button_text}>Choose Invites</Text>}
                  loading={false}
                  style={style.club_button}
                  style_inactive={style.club_button}
                  inactive={false}
                  onPress={this.toggleChooseInvites} /> </View> : null }

              {i_am_owner ?
              <View style={style.main_rsvp_wrapper}>
                <Button title={<Text style={style.club_button_text}>Update Event</Text>}
                  loading={updating_event}
                  style={style.club_button}
                  style_inactive={style.club_button}
                  inactive={updating_event}
                  onPress={this.updateEvent} /> </View> : null}
              {!i_am_owner ? <View style={style.main_rsvp_wrapper}>{this.drawTheButton()}</View> : null}

            </View>
          </View>

          <View ref={node => this.attendeesRef = node} style={[global_style.whole_width_row_mobile_switch_column, global_style.panel_padding_with_mobile, global_style.panel_border]}>
            <View style={[global_style.flex_one_mobile_none, global_style.sub_panel_padding]}>
              {this.drawAttendees()}
            </View>
            <View style={[global_style.flex_one_mobile_none, global_style.sub_panel_padding, global_style.flex_one]}>
              <View style={global_style.row_mobile_switch_column}>
                <Text style={[global_style.panel_sub_title, global_style.padding_subtitle]}>Discussion</Text>
                {message_refresh ? <TouchableOpacity style={style.message_link} onPress={()=>{window.location.reload()}}><Text style={global_style.normal_link}>New messages, refresh page</Text></TouchableOpacity> : null}
              </View>
                
              <View style={[global_style.flex_one, global_style.space_between]}>
                <ScrollView style={[global_style.flex_one, style.chat_messages_wrapper]}>
                  {messages && messages.length ?
                    messages.map(message => { return this.drawMessage(message)}) :
                    <Text style={[style.no_messages_text]}>No messages yet</Text>
                    }
                </ScrollView>
                <View style={style.chat_bottom}>
                  <Input placeholder={"Have a comment or question?"} style={{flex: 12}} value={text_message} onChangeText={(event)=>{this.updateState(event, 'text_message', "sending_message")}} multiline={"true"} />

                  {isLoggedIn ?
                  <Button title={<Text style={style.club_button_text}><Icon name="arrow-right" size={40} /></Text>}
                        style={{
                          flex: 1,
                        borderRadius: 10,
                        paddingTop: 5,
                        paddingBottom: 7,
                        paddingHorizontal: 20, width: 50, marginLeft: 5}}
                        loading={sending_message} 
                        onPress={this.sendMessage} /> : 
                        <Button title={<Text style={style.club_button_text}><Icon name="arrow-right" size={40} /></Text>}
                        style={{
                          flex: 1,
                        borderRadius: 10,
                        paddingTop: 5,
                        paddingBottom: 7,
                        paddingHorizontal: 20, width: 50, marginLeft: 5}}
                        loading={sending_message} 
                        onPress={this.onLoginClick} />}
                </View>
              </View>
            </View>
          </View>

        </View> : <View style={{backgroundColor: '#black', width: '100vw', height: '100vh'}}>
          <View style={{width: 60, height: 60, alignSelf: 'center', paddingTop: 100}}>
          <Progress.CircleSnail size={60} backgroundColor="black" fill='black' color={'#e32652' } />
          </View>
        </View>}
      </View>
      </ScrollView>
    );
  }
}

function RSVPInvite(props) {
  if (Platform.OS === 'web') {
    let navigate = useNavigate();
    let { event_id } = useParams();

    return <RSVPInvite_Base {...props} event_id={event_id} navigate={navigate} />
  } else {
    return <RSVPInvite_Base {...props} navigate={props.navigation.push} />
  }
}

export default RSVPInvite;
