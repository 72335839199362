import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { View, Platform, TouchableOpacity, Dimensions, Image, Picker } from 'react-native';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Input, Text } from '../../components';
import authController from '../../controllers/authController';
import UserController from '../../controllers/userController';
import EventController from '../../controllers/eventController';
import PaymentController from '../../controllers/paymentController';
import Utilities from '../../utilities';
import { Modal } from '../../containers';
import { style } from './style';
import { Analytics, PageHit } from 'expo-analytics';
import { config } from '../../../config';
import AuthController from '../../controllers/authController';
import { global_style } from '../../../global_style';
const analytics = new Analytics('G-YCC5QPC8BJ');

class About_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mounted: true
    }

    this.scrollTo = this.scrollTo.bind(this);

  }

  async componentDidMount() {
    analytics.hit(new PageHit('About'))
      .then(() => {})
      .catch(e => console.log("analytics error", e.message));


  }

  scrollTo(the_ref) {

    if(the_ref && the_ref.scrollIntoView) {
      the_ref.scrollIntoView();
    }
  }

  render() {
    let { mounted } = this.state; 

    const goTo = (link) => {
      window.open(link)
    }

    return (
      <View style={style.main_wrapper}>
            <View style={style.image_wrap}>
              {/* <Image style={{width: 300, height: 800, alignContent: 'center', alignItems: 'center'}} resizeMode='contain' source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/feathers2.png'}} /> */}
              <View style={style.menu_float}>
                <TouchableOpacity onPress={()=>{this.scrollTo(this.intro_ref)}}>
                  <Text style={style.menu_text}>Intro</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={()=>{this.scrollTo(this.vibe_ref)}}>
                  <Text style={style.menu_text}>Vibe</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={()=>{this.scrollTo(this.real_ref)}}>
                  <Text style={style.menu_text}>Real Friendships</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={()=>{this.scrollTo(this.how_ref)}}>
                  <Text style={style.menu_text}>How Events Go</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={()=>{this.scrollTo(this.my_ref)}}>
                  <Text style={style.menu_text}>My Story</Text>
                </TouchableOpacity>
              </View>
              <Image style={style.main_photo} source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/AboutImage.jpeg'}} />
              {/* <Image style={{transform: 'scaleX(-1)', width: 300, height: 800}} resizeMode='contain' source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/bird.png'}} /> */}
              <View style={style.image_description_wrapper}>
                <Text style={style.image_description}>Dining Social dinner with Lisa, Aleksandra and Jan at Frankie Sept'23</Text></View>
            </View>
            
            <View style={style.goose_back} ref={node=>this.intro_ref=node}>
              <Text style={style.description_paragraph}>
              <Text style={{fontSize: 30, fontFamily: 'Comfortaa'}}>Hi! I'm Aleksandra</Text> - Founder of Dining Social. I started Dining Social because I've always loved meeting new people and thought there should be a way to do so over dinner and insightful conversation. Our small (4-8 people) dinners, brunches, happy hours and coffees offer a great way to meet new people by actually finding out who they are. By sitting down over delicious food and drink, you can get to know them in a cozy setting free of interruptions that bigger events bring.
              </Text>

              <View ref={node=>this.vibe_ref=node}>
                <View style={style.image_opacity_wrapper}>
                  <View style={style.goose_back_mobile}></View>
                  <View style={style.image_opacity}></View>
                  <View style={style.text_mobile}>
                    <Text style={[style.description_paragraph]}>
                    If you think you're not going to fit in at one of our events - don't be silly, you silly goose. There are no clicks here and no expectations of you to be a certain way, other than respectful. We want to know the real you! So bring your awkward, your weird, your silly... and your goose (No geese are actually invited). 
                  </Text>
                  </View>
                </View>
                <View style={style.image_opacity_wrapper}>
                  <View style={style.bird_back_mobile}></View>
                  <View style={style.image_opacity}></View>
                  <View style={style.text_mobile}>
                    <View style={style.bird_back} ref={node=>this.coming_ref=node}>
                      <Text style={style.description_paragraph}>
                        Dining Social always welcomes your feedback and ideas. We've currently only been having events in Jersey City downtown, but we're spreading. Our next major roll-out is a Members' Portal and dinners in Hoboken. With the portal, you'll be able to search for other members based on age, gender and interests to create dinners around specific topics (like Language Exchange) and age-ranges. Stay tuned!
                      </Text>
                    </View>
                  </View>
              </View>
              </View>
               
              <View ref={node=>this.real_ref=node} style={style.image_wrap2}>
                <Image style={style.main_photo} source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/social.png'}} />
                <View style={style.image_description_wrapper}>
                <Text style={style.image_description}>Jan's Birthday Party Oct'23</Text></View>
              </View>

              <Text style={[style.title]}>Real Friendships 🌸</Text>
              <Text style={style.description_paragraph}>
                 Do people actually form real friendships here? Absolutely. We've just been around since last June '23 and have cultivated a nice group of regular members that hang out at and away from Dining Social. Join our <a href="https://www.instagram.com/diningsocialnj">instagram channel</a> and see them in action.
              </Text>

              <View ref={node=>this.how_ref=node}><Text style={style.title}>How Our Meet-Ups Go</Text></View>
              <View style={style.flowerone_back} ref={node=>this.how_ref=node}>
                <View style={style.image_opacity_wrapper}>
                    <View style={style.flowerone_back_mobile}></View>
                    <View style={[style.image_opacity, style.height_450]}></View>
                    <View style={style.text_mobile}>
                      <Text style={style.description_paragraph}>Our meet-ups start with introduction. Members that are aquainted with DiningSocial take the group through introductions and ice-breakers. At any point, our conversation is free-form and easy going. There's never a strict structure. So, if someone's going through their introduction, and you have questions, you're always welcome to be inquisitive. We're here to get to know each other. </Text>
                    </View>
                  </View>
              </View>

              <View ref={node=>this.my_ref=node} ><Text style={style.title}>My Personal Story</Text></View>
              <Text style={style.description_paragraph}>
                <View style={style.jan_and_i_wrapper}>
                    <Image style={style.jan_and_i} source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/jan_and_i.png'}} />
                  <View style={style.image_description_wrapper_jan}>
                    <Text style={style.image_description}>Jan & Me at 143 Social almost twice a month</Text>
                  </View>
                </View>
              At 11 years old, I emigrated from Otwock, Poland to Brooklyn, NY. At 18, I went on to college to receive a Bachelor's from Cornell University with a double major in Computer Science and Engineering Physics. After my Bachelor's, I completed my Master’s in Applied & Engineering Physics with a focus on computer simulations (thank you fin aid and just paid off those loans 😅). My modus operandi in college was to learn what I was interested in, and so, naturally, I had no idea what I wanted to do after school.
        
              <Text style={[style.description_paragraph, {height: 50, width: '100%', paddingVertical: 20, display: 'block'}]}></Text>
              My first job out of grad school was working for a big, chip-making company, which I ended up quitting after a year and a half. This was my longest salaried position. After quitting, I was introduced to the world of contracting and the rest was history. I started my own freelance LLC and never came back to a salaried position. Connect with me professionally on <TouchableOpacity onPress={()=>window.open('http://www.linkedin.com/pub/aleksandra-czajka/5/3a4/13b')}><Text style={style.link}>LinkedIN</Text></TouchableOpacity>
              
        
              </Text>

              <View style={style.badger_back}>
                <View style={style.image_opacity_wrapper}>
                      <View style={style.badger_back_mobile}></View>
                      <View style={[style.image_opacity, style.height_800]}></View>
                      <View style={style.text_mobile}>
                        <Text style={style.description_paragraph}>
                          I've been building web apps, mobile apps, desktop apps and all types of custom software, learning languages on the job and picking up per-job requirements on the fly for the past 15 years. Alongside my software freelance business, I've dabbled in one start-up venture or another over the past 10 years - it’s easy when you’re a software engineer. Most of my ideas (even though fabulous :-D) ended up flopping. </Text>

                          <Text style={style.description_paragraph}>I was working on one such floppy idea in Sept ‘22 when I broke up with a boyfriend, who lived in NYC. To be more social in Jersey City vs NYC, I stared the Jersey City Social meetup. For quite a few months, we’ve had different types of events based on our members’ interest. I quickly realized that small, cozy groups much better cater to getting to know each other and forming real bonds. 
                        </Text>
                      </View>
                </View>
              </View>
              <Text style={style.description_paragraph}>Huge crowds and loud environments, while popular, are not conducive to forming real, solid friendships. And so, after a few months of trying different formats, Dining Social was born in June of '23. Meeting so many people in a cozy setting since June has changed my life and perspective completely. Because of this, while we grow, our mission is to keep the personal and cozy nature of Dining Social a must in our events.
              </Text>
              <Text style={style.description_paragraph}>
                I do hope you can join us soon, and please do not hesitate to reach out at team@diningsocialnj.com. I'm always open to discussing partnerships and ideas.
              </Text>

              <Text style={[style.description_paragraph, {display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', alignContent: 'flex-end'}]}>
                <View style={style.image_opacity_wrapper}>
                      <View style={style.gang_back_mobile}></View>
                      <View style={[style.image_opacity, style.height_250]}></View>
                      <View style={[style.text_mobile, style.mobile_signature]}>
                        <Text style={style.gang_back} ref={node=>this.signature_ref=node}>
                          
                          <Text style={style.my_best} ref={node=>this.signature_ref=node}>All My Best,</Text>
                          Aleksandra
                        </Text>
                        </View>
                  </View>
              </Text>
            </View>
      </View>
    );
  }
}

function AboutScreen(props) {
  if (Platform.OS === 'web') {
    let navigate = useNavigate();
    let { date, type, event_id, group } = useParams();

    return <About_Base {...props} group={group} event_id={event_id} type={type} date={date} navigate={navigate} />
  } else {
    return <About_Base {...props} navigate={props.navigation.push} />
  }
}

export default AboutScreen;
