import { StyleSheet } from 'react-native';

const style = StyleSheet.create({
    lr_container: {
      padding: 15,
      width: '400px',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      alignContent: 'center'
    },
    lr_header_tabs: {
      alignSelf: 'center',
      flexDirection: 'row',
      flex: 1
    },
    lr_tab: { 
      alignItems: 'center',
      flexDirection: 'column'
    },
    page_container: { 
      flex: 1,
      width: '100%',
      alignContent: 'center',
      alignItems: 'center'
    },
    login_text: {
      fontFamily: 'Ysabeau',
      fontSize: 30,
      marginTop: 30
    },
    login_button: {
      backgroundColor: 'black',
      borderRadius: 30,
      fontFamily: 'Comfortaa'
    },
    inactive_login_button: {
      backgroundColor: '#d9d8e4',
      borderRadius: 30,
      fontFamily: 'Comfortaa'
    },
    line_breaker: {
      height: 20
    },
    input_box: {
      width: 350
    },
    input_box_multi: {
      width: 350,
      height: 100,
      marginTop: 20
    },
    register_wrapper: {
      width: 300,
      flexDirection: 'row',

      marginTop: 30,
      marginBottom: 10
    },
    dont_text: {
      fontFamily: 'Comfortaa',
      fontSize: 12
    },
    link_wrap: {

    },
    register_link: {
      fontFamily: 'Comfortaa',
      color: '#0b6665',
      fontSize: 12,
      marginLeft: 5,
      textDecorationLine: 'underline'
    },
    error_text: {
      color: 'red',
      marginTop: 5,
      textAlign: 'flex-start',
      fontFamily: 'Ysabeau',
      fontSize: 14,
      alignSelf: 'flex-start'
    },
    intro: {
      marginTop: 30,
      fontFamily: 'Ysabeau',
      fontSize: 16,
    }
});

export {
    style
}