import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { StyleSheet, View, TouchableOpacity, Platform, Image } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { Text } from '../../components';
import UserController from '../../controllers/userController';
import { style } from './style';
import { ScrollView } from 'react-native-gesture-handler';

class Profile_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      attendee: props.attendee
    }

  }

  async componentDidMount() {
    let token = await localStorage.getItem('token');

    this.setState({
      token,
      mounted: true
    })
  }

  render() {

    let { attendee } = this.state;

    console.log('attendee', attendee);

    let photo_url, name, job, user_hobbies, bio, hobbies;
    let profile = attendee.profile;

    if(attendee) {
      if(typeof profile === 'string') {
        profile = JSON.parse(profile);
      }

      ({photo_url, job, name, user_hobbies, bio, hobbies} = profile);

      user_hobbies = user_hobbies ? user_hobbies : (hobbies ? hobbies : '')
      
      if(attendee.name) {
        name = attendee.name;
      }
    }

    user_hobbies = user_hobbies.replace(/\//g, ',')

    let draw_user_hobbies = user_hobbies.split(',').map(hobby=>{
      if(hobby.trim() !== '') {
        return <View><Text style={style.input_text_hobbie}>{hobby}</Text></View>
      } else {
        return null
      }
    })

    console.log('attendee', attendee)
    
    return attendee ? 
      <>
                <View style={style.fat_top}>
                  <View style={style.profile_wrapper}>
                    <Image style={style.profile_image} resizeMode={'cover'} source={{uri: photo_url ? photo_url : 'https://diningsocial-assets.s3.amazonaws.com/empty_profile.png'}} />
                  </View>
                  <View style={{flex: 1}}>
                    {name ?
                    <View style={style.attendee_name}><Text style={style.attendee_name}>{name}</Text></View> : null}
                    {job ?
                    <View><Text style={style.attendee_info}>Job: {job}</Text></View> : null}
                    {bio ?
                    <View><Text style={style.attendee_info}>Bio: {bio}</Text></View> : null}
                  </View>
                </View>
                <View style={{paddingBottom: 200}}>
                  <View><Text style={style.attendee_info}><Text style={style.attendee_info_title}>Hobbies:</Text> {draw_user_hobbies}</Text></View>
                </View>
        
      </> : null
  }

}

function Profile(props) {
  if(Platform.OS === 'web') {
    let navigate = useNavigate();
    return  <Profile_Base {...props} navigate={navigate} />
  } else {
    return  <Profile_Base {...props} navigate={props.navigation.push}  />
  }
}

export default Profile
