import { StyleSheet, Dimensions } from 'react-native';

let width = Dimensions.get('window').width;
let height = Dimensions.get('window').height;

let is_mobile_500 = width < 500;

let global_style = {
  section_title: {
    fontFamily: 'Ysabeau', 
    fontSize: is_mobile_500 ? 30 : 50, 
    color: 'black',
    textTransform: 'uppercase',
    borderRightWidth: is_mobile_500 ? 0 : 1,
    borderColor: '#999999',
    paddingVertical: 40,
    paddingHorizontal: is_mobile_500 ? 20 : 40
  },
  four_buttons_button: {
    fontFamily: 'Ysabeau', 
    fontSize: 25, 
    color: 'white', 
    border: 'solid 1px white', 
    paddingVertical: 5, 
    paddingHorizontal: 20,
    width: 187
  },
  wrap_dining_image: {
    paddingVertical: 40,
    marginHorizontal: is_mobile_500 ? 20 : 60
  },
  our_dining_image: {
    width: '100%',
    height: 400
  },
  paging_button: { 
    backgroundColor: '#222326', 
    borderRadius: 100, 
    color: 'black', 
    textAlign: 'center' },
  membership_tab: {
    fontFamily: 'Comfortaa', 
    fontSize: 18, 
    lineHeight: 24,
    paddingVertical: 20, 
    justifyContent: 'center'
  },
  three_buttons_button: {
    fontFamily: 'Ysabeau', 
    fontSize: 20, color: 'white', 
    border: 'solid 1px white', 
    paddingVertical: 5, 
    paddingHorizontal: 20,
    width: 187
  },
  three_title: {
    fontFamily: 'Ysabeau', 
    fontSize: 40, 
    color: 'white',
    textTransform: 'uppercase'
  },
  our_dining_image: {
    width: '100%',
    height: 400
  },
  dining_sub_title: {
    fontFamily: 'Ysabeau', 
    fontSize: 40,
    marginTop: 28,
    marginBottom: 30, 
    color: 'black',
    textTransform: 'uppercase',
    lineHeight: 40
  },
  dining_text: {
    fontFamily: 'Ysabeau', 
    fontSize: 20,
    lineHeight: 30, 
    color: 'black'
  },
}

let style_small = StyleSheet.create({
  ...global_style
});

let style_normal = StyleSheet.create({
  ...global_style
});

let style = width < 1000 ? style_small : style_normal;

export {
  style
}