const colors = {
  PRIMARY: '#3497E8',
  PRIMARY_TRANSLUCENT: '#E6EBFF',
  PRIMARY_FADED: '#c2dff8',
  DARK_GREY: '#404040',
  TEXT_GREY: '#575762',
  CONSULT_TITLE_GREY: '#4b4b4b',
  BORDER_GREY: '#e7e7e7',
  GREY: 'grey',
  GREEN: '#66D56F',
  RED: '#FF495C',
}

//2E58FF

export default colors
