import { StyleSheet } from 'react-native';

const style = StyleSheet.create({
  explainer_section: {
    flexDirection: 'column'
  },
  restaurants_title: {
    fontFamily: 'Ysabeau',
    fontSize: '50px',
    color: 'black',
    width: '100%',
    flexDirection: 'row',
    padding: 20,
    marginBottom: 80,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  restaurant_title: {
    fontFamily: 'Ysabeau',
    fontSize: 50,
    marginVertical: 50,
    marginLeft: 40,
    textAlign: 'center'
  },
  restaurants_wrapper: {
    flexDirection: 'column'
  },
  restaurant_wrapper: {
    flexDirection: 'column'
  },
  memberships: {
    paddingTop: 50,
    flexDirection: 'row',
    margin: 'auto',
    width: 1100,
    justifyContent: 'center',
    paddingBottom: 100
  },
  whole_wrapper: {
    "backgroundColor":"#ffffff","opacity":"1","backgroundImage":"linear-gradient(135deg, #dcd3d2 15%, transparent 15%), linear-gradient(225deg, #dcd3d2 15%, transparent 15%), linear-gradient(45deg, #dcd3d2 15%, transparent 15%), linear-gradient(315deg, #dcd3d2 15%, #ffffff 15%)","backgroundPosition":"0px 0, 0px 0, 0 0, 0 0","backgroundSize":"40px 40px","backgroundRepeat":"repeat",
    borderTopWidth: 3,
    borderColor: '#dcd3d2'
  },
  membership: {
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: 30,
    borderWidth: 3,
    borderColor: '#1e1007'
    //margin: 30
  },
  white_panel: {
    //position: 'absolute',
    height: 272,
    backgroundColor: 'white',
    width: 200,
    //left: 200,
    borderRadius: 30
  },
  panel_monthly: {
    borderColor: '#4f3f33',
    borderWidth: 3
  },
  panel_free: {
    borderColor: '#b09f93',
    borderWidth: 3
  },
  panel_yearly: {
    borderColor: '#1e1007',
    borderWidth: 3
  },
  membership_left: {
    flex: 1,
    padding: 50,
    borderTopLeftRadius: 26,
    borderTopRightRadius: 26,
    width: 300,
    alignItems: 'center'
  },
  left_wrapper: {

    alignItems: 'center',

  },
  membership_left_free: {
    backgroundColor: '#b09f93',
    
  },
  membership_left_monthly: {
    backgroundColor: '#4f3f33',
  },
  membership_left_yearly: {
    backgroundColor: '#1e1007',
  },
  membership_right: {
    flex: 1,

    //margin: 10
   
    //padding: 20
  },
  membership_title: {
    fontFamily: 'Comfortaa',
    color: "#fbf5f0",
    fontSize: 30,
    textAlign: 'center',
    marginBottom: 14
  },
  membership_price: {
    fontFamily: 'Ysabeau',
    color: 'white',
    textAlign: 'center',
    fontSize: 20
  },
  membership_explainer: {
    fontFamily: 'Ysabeau',
    color: '#33342f',
    marginTop: 30,
    width: 300,
    fontSize: 16
  },
  membership_explainer_add: {
    fontFamily: 'Ysabeau',
    color: '#33342f',
    width: 260,
    fontSize: 16,
    margin: 20,
    textAlign: 'center',
    height: 100
  },
  membership_explainer_title: {
    fontFamily: 'Comfortaa',
    fontSize: 26,
    color: '#33342f'
  },
  join_button: {

    paddingVertical: 10,
    paddingHorizontal: 20,
    width: 200,
    borderRadius: 30,
    //marginTop: 40,
    marginBottom: 40,
    alignSelf: 'center'
  },
  join_button_text: {
    fontFamily: 'Ysabeau',
    fontSize: 20,
    textAlign: 'center',
    color: '#fbf5f0'
  },
  join_button_free: {
    backgroundColor: '#b09f93',
  },
  join_button_monthly: {
    backgroundColor: '#4f3f33',
  },
  join_button_yearly: {
    backgroundColor: '#1e1007',
  },
  bottom_breaker: {
    height: 200
  },
  legal: {
    paddingTop: 50,
    flexDirection: 'row',
    margin: 'auto',
    width: 800,
    justifyContent: 'center',
    paddingBottom: 100,
    flexDirection: 'column',
    backgroundColor: 'white',
    fontSize: 16
  },
  legal_text: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Comfortaa',
    fontSize: 14,
    marginVertical: 10,
    lineHeight: 20
  },
  legal_title: {
    display: 'flex',
    fontFamily: 'Comfortaa',
    marginTop: 40,
    marginBottom: 10,
    fontSize: 18
  },
  legal_text_list: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Comfortaa',
    fontSize: 14,
    marginVertical: 5,
    marginLeft: 20,
    lineHeight: 20,
    listStyle: 'disc',
    display: 'list-item'
  }
});

export {
  style
}