import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { StyleSheet, View, TouchableOpacity, Platform } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { Text } from '../../components';
import { style } from './style';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import AuthController from '../../controllers/authController';

//library.add(faInstagram);

class FAQ_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      chosen_section: props.chosen_section,
      faq: [
        {
          section_title: "How does Guided Dining go?",
          contents: [
            {title: "How many people will I meet?", description: "Most guided dining is limited to 6 people including the host. This is so that we all get the chance to know each other. Occassionally we host events where there are more people."},
            {title: "How do we take care of the bill?", description: "Your host uses an app to split the bill according to what we each had. After verifying your total, you can Venmo, Zelle or give cash to the host."},
            {title: "How does guided dining go?", description: "Guided dining is very free-form. We start with introductions if we're not already doing them naturally. Sometimes we have one conversation going so that everyone gets to know each other. Other times, people naturally split up and have organic conversations. Our host is there to make sure that there's never a lul in the conversation and that, most importantly, no one is left out."},
            {title: "What happens after the meal?", description: "If the group is hitting it of, you are very much encouraged to find the next spot to mingle!"},
          ]
        },
        {
          section_title: "How does Guided Dining go?",
          contents: [
            {title: "How many people will I meet?", description: "Most guided dining is limited to 6 people including the host. This is so that we all get the chance to know each other. Occassionally we host events where there are more people."},
            {title: "How do we take care of the bill?", description: "Your host uses an app to split the bill according to what we each had. After verifying your total, you can Venmo, Zelle or give cash to the host."},
            {title: "How does guided dining go?", description: "Guided dining is very free-form. We start with introductions if we're not already doing them naturally. Sometimes we have one conversation going so that everyone gets to know each other. Other times, people naturally split up and have organic conversations. Our host is there to make sure that there's never a lul in the conversation and that, most importantly, no one is left out."},
            {title: "What happens after the meal?", description: "If the group is hitting it of, you are very much encouraged to find the next spot to mingle!"},
          ]
        },
        {
          section_title: "How do virtual events work?",
          contents: [
            {title: "Why virtual?", description: "Going out to restaurants can take a toll on your wallet. We don't want that to be the limiting factor for our members. Having a virtual event, opens up the opportunity for all of us to mingle without the bottleneck of finances."},
            {title: "Why do we do only a side dish?", description: "We're looking for relatively quick things we can learn to make and not spend all day at the stove. Our primary goal is for our member to meet each other and mingle. We feel that if we make the cooking portion too long, it won't give our members enough time to socialize. We might re-conider this in the future :-)"},
            {title: "Do I have to participate in the cooking?", description: "We know that our members are a very special social bunch :-). And, we've already anticipated that some people might just want to come and spectate. This is perfectly acceptable and also enjoyable as you'll have the ability to watch as others carry out the recipe. You can also make your own dinner."},
            {title: "How do I connect with other participants?", description: "Throughout the entire duration of our zoom call, you will have access to a private chat with any participant. Look for the 'private chat' option on the user's profile."},
            {title: "What does being a host entail?", description: "Primarily and most importantly, you have to have the desire to help people get through the steps and help them make connections. You have to be good at encouraging and accepting all kinds of people. Reach out to us and we'll have a chat! "}
          ]
        },
  
      ]
    },

    this.faqRef = props.ref;

    this.drawOneFAQ = this.drawOneFAQ.bind(this);
    this.drawFAQSections = this.drawFAQSections.bind(this);
    this.toggleIt = this.toggleIt.bind(this);
  }

  async componentDidMount() {
    //let faq = await AuthController.getFAQ({});
    let { faq, chosen_section } = this.state;

    if(chosen_section || chosen_section === 0) {
      faq = faq[chosen_section]
    }

    this.setState({
      faq,
      mounted: true
    });
  }

  toggleIt(section_index, faq_index) {
    let { faq } = this.state;

    console.log('section_index, faq_index', section_index, faq_index)

    if(faq_index || faq_index === 0) {
      if(section_index || section_index === 0) {
        faq[section_index].contents[faq_index] = {
          ...faq[section_index].contents[faq_index],
          open: !faq[section_index].contents[faq_index].open
        }
      } else {
        faq.contents[faq_index] = {
          ...faq.contents[faq_index],
          open: !faq.contents[faq_index].open
        }
      }
    } else {
      faq[section_index] = {
        ...faq[section_index],
        open: !faq[section_index].open
      }
    }
    
    this.setState({
      faq
    })
  }

  drawOneFAQ(faq, index, section_index) {

    console.log('drawing faq, index, section_index', faq, index, section_index)

    return <View style={{flexDirection: 'column', borderWidth: 1, borderColor: '#ccc', padding: 20, marginBottom: 20, width: '100%'}}>
      <TouchableOpacity onPress={()=>this.toggleIt(section_index, index)}><Text style={{fontFamily: 'Ysabeau', fontSize: 25}}>{faq.title}</Text></TouchableOpacity>
      {faq.open ? <Text style={{fontFamily: 'Ysabeau', fontSize: 18, marginTop: 20}}>{faq.description}</Text> : null}
    </View>
  }

  drawFAQSections(section, section_index) {
    
    return <View style={{flexDirection: 'column', borderWidth: 1, borderColor: '#ccc', padding: 20, marginBottom: 20, width: '100%'}}>
      <TouchableOpacity onPress={()=>this.toggleIt(section_index)}><Text style={{fontFamily: 'Ysabeau', fontSize: 25}}>{section.section_title}</Text></TouchableOpacity>
      {section.open ? <Text style={{fontFamily: 'Ysabeau', fontSize: 18, marginTop: 20}}>{section.contents.map((faq, index) => this.drawOneFAQ(faq, index, section_index))}</Text> : null}
    </View>
  }

  render() {
    let { faq, chosen_section, mounted } = this.state;

    console.log('faq', faq)

    let draw_one = chosen_section || chosen_section === 0;

    console.log('c', draw_one)
    
    return (
      mounted ? 
      <View ref={node => this.faqRef = node} style={{flexDirection: 'column', padding: 40}}>
        <Text style={{fontFamily: 'Ysabeau', fontSize: 50, marginBottom: 40}}>FAQs</Text>
        {draw_one ?
          faq.contents.map((a, i) => this.drawOneFAQ(a, i)) : faq.map(this.drawFAQSections) }
      </View> : null
    );
  }

}

function FAQ(props) {
  if(Platform.OS === 'web') {
    let navigate = useNavigate();
    return  <FAQ_Base ref={props.ref} {...props} navigate={navigate} />
  } else {
    return  <FAQ_Base {...props} navigate={props.navigation.push}  />
  }
}

export default FAQ
