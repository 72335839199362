import Utilities from '../utilities/index';
import StorageController from './storageController';

export default class UserController  {

    static async login (data) {
        let response = await Utilities.doInternalPost('/login', data, true);

        console.log('response', response);
        //save token if success
        if(response.success) {
            localStorage.setItem("token", response.token);

            if(response.user) {
                await UserController.saveUser(response);
            }

            return response;
        } else {
            return {
                success: false,
                error: response.error
            };
        }
    }

    static async register (data) {
        let response = await Utilities.doInternalPost('/register', data, !!data.user_id);
        console.log('register response' ,response)
        //save token if success
        if(response && response.success) {
            localStorage.setItem("token", response.token);

            if(response.user) {
                await UserController.saveUser(response)
            }

            return response;
        } else {
            return {
                success: false,
                error: response.error
            };
        }
    }

    static async verifyCode (data) {
        let response = await Utilities.doInternalPost('/data/user/verify_code', data);

        return response
    }

    static async updateMessaging (data) {
        let response = await Utilities.doInternalPost('/data/user/update_messaging', data);

        return response
    }
    
    static async getNotifPreferences() {
        let response = await Utilities.doInternalPost('/data/user/notification_preferences', {});
        return response && response.success ? response.notification_preferences : [];
    }

    static async updateNotifPreferences (data) {
        let response = await Utilities.doInternalPost('/data/user/update_notif_preferences', data);

        return response
    }

    static async getUsersEnabledMessagingWithYou() {
        let response = await Utilities.doInternalPost('/data/user/user_enabled_with_you', {});
        return response && response.success ? response.users_enabled_messaging_with_you : [];
    }

    static async getChatMessages(data) {
        let response = await Utilities.doInternalPost('/data/user/get_messages', data);
        return response && response.success ? response.messages : [];
    }

    static async markMessagesSeen(data) {
        let response = await Utilities.doInternalPost('/data/user/mark_seen', data);
        return response && response.success ? response.messages : [];
    }

    static async sendChatMessage(data) {
        let response = await Utilities.doInternalPost('/data/user/send_message', data);
        return response && response.success ? response.messages : [];
    }

    static async getAllUserMessages(data) {
        let response = await Utilities.doInternalPost('/data/user/user_messages', data);
        return response && response.success ? response.messages : [];
    }

    static async anyNewMessages(data) {
        let response = await Utilities.doInternalPost('/data/user/any_new', data);
        return response && response.success ? response.unseen_messages : 0;
    }

    static async logout () {
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        localStorage.removeItem("host_account");
        localStorage.removeItem("profile");
        localStorage.removeItem("points");
        localStorage.removeItem("user_id");
        localStorage.removeItem("subscription");
        localStorage.removeItem("site_admin");
        localStorage.removeItem("status");
        localStorage.removeItem("onboarding_tab");
    }

    static async userLoggedIn() {
        let token = await StorageController.getValue('token');

        return !!token;
    }

    static async getDinners(data) {
        let response = await Utilities.doInternalPost('/data/user/dinners', data);
        return response;
    }

    static async sendMessage(data) {
        let response = await Utilities.doInternalPost('/data/user/message', data);
        return response && response.success ? response.messages : [];
    }

    static async getDateRsvps() {
        let response = await Utilities.doInternalGet(`/rsvps`);
        return response.success && response.rsvps ? { rsvps: response.rsvps, tentative: response.tentative} : [];
    }

    static async getDateRsvpsForEvent(data) {
        let response = await Utilities.doInternalPost(`/event_rsvps`, data);
        return response.success ? response : [];
    }

    static async searchMembers(data) {
        let response = await Utilities.doInternalPost(`/data/user/search_members`, data);
        return response.success ? response.search_results : [];
    }

    static async getPeopleIMet(data) {
        let response = await Utilities.doInternalPost(`/data/user/people_i_met`, data);
        return response.success ? response.people_i_met : [];
    }

    static async getUsers(data) {
        let response = await Utilities.doInternalPost(`/get_users`, data);
        return response && response.success ? response.users : [];
    }

    static async saveUnInvited(data) {
        let response = await Utilities.doInternalPost(`/data/user/save_un_invited`, data);
        return response;
    }

    static async saveInvited(data) {
        let response = await Utilities.doInternalPost(`/data/user/save_invited`, data);
        return response;
    }

    static async getTransactions() {
        let response = await Utilities.doInternalGet('/data/user/transactions');
        return response;
    }

    static async getUserSubscriptionLive(user) {
        //alert('getting subscirption')

        user = user && user.user ? user.user : user;

        if(user && user.user_id === 'cc467f19-6954-4151-ba3c-c45c8563511c') {
            return { subscription: {status: 'active', type: 2}, is_member: true };
        } else {
            let response = await Utilities.doInternalPost('/data/user/get_user_subscription', {});
            console.log('response', response)
            let subscription = response.subscription;

            let host_account = user ? user.host_account : null;
            let is_member = subscription && subscription.status ? subscription.status === 'trialing' || subscription.status === 'active' : false;
            is_member = is_member || !!host_account;

            if(host_account) {
                subscription = {
                    ...subscription,
                    type: 2
                }
            }

            return { subscription, is_member };
        }
    }

    static async getUserSubscriptionStatic() {
        //alert('getting subscirption')
        let response = await Utilities.doInternalPost('/data/user/get_user_subscription_static', {});

        return response.subscription;
    }

    static async getUser (get_new) {

        if(get_new) {
            await UserController.saveUser();
        }

        let user_logged_in = await UserController.userLoggedIn();

        if(user_logged_in) {
            let email = await localStorage.getItem('email');

            if(!email) {

                await UserController.saveUser();
            }

            email = await localStorage.getItem('email');

            let user_id = await localStorage.getItem('user_id');

            let site_admin = await localStorage.getItem('site_admin');

            if(site_admin) {
                site_admin = site_admin === 'true';
            }

            let host = await localStorage.getItem('host');
            host = host && host === 'true' ? true : false;

            let host_account = await localStorage.getItem('host_account');
            host_account = host_account && host_account === 'true' ? true : false;

            let status = await localStorage.getItem('status');

            if(status) {
                status = parseInt(status);
            }
               
            let subscription = await localStorage.getItem('subscription');
   
            if(subscription) {
                subscription = JSON.parse(subscription);
            }

            let profile = await localStorage.getItem('profile');
            if(profile) {
                console.log('profile', profile)
                try{
                    profile = JSON.parse(profile);
                } catch(err) {
                    profile = {}
                }
            }

            let points = await localStorage.getItem('points');
            if(points) {
                points = JSON.parse(points);
            }

            let messaging = await localStorage.getItem('messaging');
            if(messaging) {
                messaging = JSON.parse(messaging);
            }
            
            let user = {
                points,
                profile,
                user: {
                    profile,
                    messaging,
                    host_account,
                    is_host: host,
                    email,
                    user_id,
                    site_admin,
                    status,
                    id: user_id
                },
                subscription,
                
            }
            
            return user;
        } else {
            return null;
        }
    }

    static async saveUser() {
        let response = await Utilities.doInternalPost('/data/user/get_user', {});

        console.log('user response', response)

        if(response.success) {
            if(response.user.email) {
                await localStorage.setItem('email',response.user.email);
            }

            if(response.user.user_id) {
                await localStorage.setItem('user_id', response.user.user_id);
            }

            if(response.user.host_account) {
                await localStorage.setItem('host_account', response.user.host_account ? 'true' : 'false');
            }

            if(response.user.site_admin === true || response.user.site_admin === false) {
                await localStorage.setItem('site_admin',response.user.site_admin === false ? 'false' : 'true');
            }

            if(response.user.host === true || response.user.host === false) {
                await localStorage.setItem('host',response.user.host === false ? 'false' : 'true');
            }

            if(response.user.status) {
                await localStorage.setItem('status',response.user.status.toString());
            }

            if(response.user.messaging) {
                await localStorage.setItem('messaging', JSON.stringify(response.user.messaging));
            }

            if(response.profile) {
                let profile = response.profile;
                if(typeof response.profile !== 'string') {
                    profile = JSON.stringify(profile);
                }

                await localStorage.setItem('profile', profile);
            }

            if(response.points) {
                await localStorage.setItem('points', JSON.stringify(response.points));
            }
        }

        return response;
    }

    static async sendFeedback(data) {
        let response = await Utilities.doInternalPost('/feedback', data);

        return response;
    }

    static async sendForgot(data) {
        let response = await Utilities.doInternalPost('/forgot', data);

        return response;
    }

    static async checkRandomReset(data) {
        let response = await Utilities.doInternalPost('/check_random_reset', data);

        return response;
    }

    static async verifyChangeLink(data) {
        let response = await Utilities.doInternalPost('/verify_change_link', data);

        return response;
    }

    static async sendChange(data) {
        let response = await Utilities.doInternalPost('/verify_change_password', data);

        return response;
    }

    static async sendUserFeedback(data) {
        let response = await Utilities.doInternalPost('/data/user/feedback', data);

        return response;
    }

    static async confirmRSVP(data) {
        let response = await Utilities.doInternalPost('/data/user/confirm_rsvp', data);

        return response;
    }

    static async confirmMakeHost(data) {
        let response = await Utilities.doInternalPost('/data/user/confirm_make_host', data);

        return response;
    }

    static async confirmResignHost(data) {
        let response = await Utilities.doInternalPost('/data/user/confirm_resign_host', data);

        return response;
    }

    static async confirmCancel(data) {
        let response = await Utilities.doInternalPost('/data/user/cancel_rsvp', data);

        return response;
    }

    static async onLike(data) {
        let response = await Utilities.doInternalPost('/data/user/like', data);

        return response;
    }

    static async saveProfile(data) {
        let response = await Utilities.doInternalPost('/data/user/profile', data);

        return response;
    }

    static async getUserEvent(data) {
        let response = await Utilities.doInternalPost('/get_user_event', data);

        return response && response.success ? response.event: null;
    }

    static async getUserId () {
        let user_id = await localStorage.getItem('user_id')

        return user_id;
    }

    static async getInterestList() {
        let response = await Utilities.doInternalGet('/interest_list');
        return response;
    }

    static async changePassword (password) {
        let response = await Utilities.doInternalPost('/data/user/change_password', {password});

        return response;
    }

    static async addNewsletter (email) {
        let response = await Utilities.doInternalPost('/newsletter', {email}, true);

        return response;
    }

    static async getBetaCode (data) {
        let response = await Utilities.doInternalPost('/beta_code', data, true);

        return response;
    }

    static async addDemo (data) {
        let response = await Utilities.doInternalPost('/demo', data, true);

        return response;
    }

    static async forgot(email) {
        let response = await Utilities.doInternalPost('/forgot', {email}, true);

        return response;
    }

    static async deleteUser(email) {
        let response = await Utilities.doInternalPost('/data/admin/delete_user', {email});

        return response;
    }

    static async acceptInvite (data) {
        let response = await Utilities.doInternalPost('/data/user/accept_invite', data);

        return response
    }

    static async memberAcceptInvite (data) {
        let response = await Utilities.doInternalPost('/data/user/member_accept_invite', data);

        return response
    }

    static async removeRequest (data) {
        let response = await Utilities.doInternalPost('/data/user/remove_join_request', data);

        return response
    }

    static async requestJoin (data) {
        let response = await Utilities.doInternalPost('/data/user/request_join', data);

        return response
    }

    static async getDemoEvents (data) {
        let response = await Utilities.doInternalPost('/data/user/demo_events', data);

        return response.success ? response : {}
    }

    static async confirmPhone (data) {
        let response = await Utilities.doInternalPost('/data/user/verify_phone', data);

        return response.success ? response.code : {}
    }

}