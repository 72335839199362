import React, { Component, forwardRef } from 'react';
import * as Progress from 'react-native-progress';
import { View, ScrollView, Platform, Dimensions, TouchableOpacity, Image } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { Video } from 'expo-av';
import { Text, Button, Pager } from '../../components';
import UserController from '../../controllers/userController';
import { config } from '../../../config';
import { style } from './style';
import { global_style } from '../../../global_style';
import ViewMoreText from 'react-native-view-more-text';
import Utilities from '../../utilities';
import AuthController from '../../controllers/authController';
import Profile from '../Profile';
import { Modal } from '../../containers';
import * as CalendarRN from 'react-native-calendars';
import moment from 'moment';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
let width = Dimensions.get('window').width;

const CalendarModule = CalendarRN.Calendar;
const WeekCalendar = CalendarRN.WeekCalendar;

class Calendar_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: props.user
    }

    this.attendee_colors = {};

    this.calendarRef = React.createRef();
    this.scrollTo = this.scrollTo.bind(this);

    this.drawCalendar = this.drawCalendar.bind(this);
    this.drawAttendees = this.drawAttendees.bind(this);
    this.openBubble = this.openBubble.bind(this);
    this.selectDay = this.selectDay.bind(this);
    this.selectDayEvent = this.selectDayEvent.bind(this);
    this.drawMonthCalendar = this.drawMonthCalendar.bind(this);
    this.resetFilters = this.resetFilters.bind(this);

    this.openInParent = this.openInParent.bind(this);
    this.toggleState = this.toggleState.bind(this);

    this.dateInPast = this.dateInPast.bind(this);

    this.filterFreeEvents = this.filterFreeEvents.bind(this);
    this.filterFreeEventsNewMember = this.filterFreeEventsNewMember.bind(this);
    this.filterHostedEvents = this.filterHostedEvents.bind(this);
    this.filterJCRFEvents = this.filterJCRFEvents.bind(this);
    this.filterUserEvents = this.filterUserEvents.bind(this);
    this.filterOnFilter = this.filterOnFilter.bind(this);

    this.drawEventSummary = this.drawEventSummary.bind(this);
  }

  async componentDidMount() {
    let { user } = this.state;
    if(!user) {
      user = await UserController.getUser();
      user = user ? user.user : null;
    }

    let token = await localStorage.getItem('token');
    let isLoggedIn = !!token;

    let user_id = user ? user.id : null;

    let internal = await AuthController.getInternal();
    let { events, hosts } = await AuthController.getEvents();
    let places = await AuthController.getPlaces();

    events = events.filter(event=>!event.canceled)

    console.log('events', events);

    let start_date = new Date().toLocaleString("en-US", { timeZone: "America/New_York" });
    start_date = new Date(start_date);

    let date_approved = this.validDay(start_date, internal, events);
    if(!date_approved) {
      start_date = this.getNextValidDay(start_date, internal, events);
    }

    let start_date_clone = _.cloneDeep(start_date);
    //let end_date = new Date(start_date_clone.setDate(start_date_clone.getDate() + 28));
    let end_date = new Date(start_date_clone.setDate(start_date_clone.getDate() + 28));

    start_date = `${start_date.getMonth() + 1}/${start_date.getDate()}/${start_date.getFullYear()}`;
    end_date = `${end_date.getMonth() + 1}/${end_date.getDate()}/${end_date.getFullYear()}`;
    
    let dinners; 
    if(isLoggedIn) {
      dinners = await UserController.getDinners();
      dinners = dinners.dinners;
    }

    let calendar_days = this.calculateDatesWithEvents(start_date, end_date, internal, events);

    start_date = calendar_days[0];

    let today = new Date();

    let today_str = (today.getMonth() + 1)+'/'+(today.getDate())+'/'+(today.getFullYear());
    let starting_date = today_str;

    let event_list = events;

    event_list = event_list.sort((a, b)=>{
      if(a.date === b.date) {
        return a.time < b.time ? -1 : 1
      } else {
        return new Date(a.date ) < new Date(b.date) ? -1 : 1
      }
    });

    this.setState({
      today_str,
      starting_date,
      filter: {},
      hosts,
      user_id,
      calendar_days,
      event_list,
      calendar_event_list: events,
      events,
      places,
      internal,
      end_date,
      start_date,
      isLoggedIn,
      dinners,
      mounted: true
    }, async () => {
      let event_rsvps = {};
      let event, rsvps, event_id;

      for(var i = 0; i < events.length; i++) {
        event = events[i];
        event_id = event.event_id;

        console.log('grabbingevent', event)
        if(event.user_event) {
          let user_ids = event.accept_invite ? event.accept_invite : []
          user_ids = user_ids.concat(event.created_by);

          let join_requests = event.join_requests ? event.join_requests : [];

          join_requests = join_requests.filter(a=>a.accepted)
          join_requests = join_requests.map(a=>a.user_id);

          user_ids = user_ids.concat(join_requests)

          rsvps = await AuthController.getUsers({user_ids}) 

          console.log('rsvpsrsvps', rsvps)
        } else {
          ({rsvps} = await UserController.getDateRsvpsForEvent({event_id}))
        }

        event_rsvps = {
          ...event_rsvps,
          [event_id]: rsvps
        }
        this.setState({event_rsvps})
      }
    })
  }

  scrollTo(the_ref) {

    if(the_ref && the_ref.scrollIntoView) {
      the_ref.scrollIntoView();
    }
  }

  getRandStyle() {
    let objects = [style.profile_one, style.profile_two, style.profile_three, style.profile_four, style.profile_five, style.profile_six, style.profile_seven, style.profile_eight]
    let randomIndex = Math.floor(Math.random() * objects.length);

    return objects[randomIndex]
  }

  rsvpDate(event) {
    let { isLoggedIn } = this.state;

    console.log('event', event)

    this.props.putInParent(null)

    if(event.special) {
      this.props.navigate(`/special/${event.event_id}`);
    } else if(event.user_event) {
      this.props.navigate(`/user_invite/${event.event_id}`);
    } else {
      this.props.navigate(`/rsvp/${event.date.replace(/\//g, ".")}/${event.type}${event.event_id ? `/${event.event_id}`: ''}`);
    }

    //if(isLoggedIn) {
     
    /* } else {
      this.props.navigate('/register')
    } */
  }

  drawAttendee(attendee) {
    let photo_url, name;

    console.log('attendee', attendee)

    if(attendee) {
      let profile = attendee.profile;

      if(typeof profile === 'string') {
        profile = JSON.parse(profile);
      }

      ({photo_url, name} = profile);

      if(attendee.name) {
        name = attendee.name;
      }
    }

    return <TouchableOpacity onPress={()=>this.openBubble(attendee)} style={[style.attendee_bubble]}>
      <Image style={style.attendee_photo} source={{uri: photo_url ? photo_url : 'https://diningsocial-assets.s3.amazonaws.com/empty_profile.png'}} />
        {/* <Text style={style.attendee_name_text}>{name ? name[0].toUpperCase() : "M"}</Text> */}
    </TouchableOpacity>
  }

  drawAttendees(attendees, rsvped, onPress, event, tentative, onLike, liked) {
    //let buckets = [[],[]];

    let attendee_user_ids = attendees.map(a=>{return a.user_id});

    tentative = tentative.filter((tent) => !attendee_user_ids.includes(tent.user_id));

    console.log('tentative', tentative)

    /* buckets[0] = attendees.filter(a=>{return a.group === 0});
    buckets[1] = attendees.filter(a=>{return a.group === 1});
    buckets[2] = attendees.filter(a=>{return a.group === 2}); */

    let { hosted, is_full, group, demographic, canceled, rsvp_limit, name, date, time, user_event } = event;

    let date_is_in_past = this.dateInPast(date, time);

    if(!is_full && rsvp_limit) {
      if(typeof rsvp_limit === 'string') { rsvp_limit = parseInt(rsvp_limit)}
      is_full = rsvp_limit === attendees.length;
    }

    let created = !!event.demographic;
//title above descrition 'Everyone Always Welcome' mention how friendly host will welcome your -> maybe a note to new participants

    let description = `Everyone is always welcome. As we get seated, our friendly host will take us through icebreakers and other conversation topics.`;

    //description = event.description ? event.description : description;

    return !canceled ? <View style={style.summary_info_bubble_popup}>
      {/* {hosted ? <> */}
        <Text style={style.tentative_title}>RSVPs {user_event ? '(limit to 4 rsvps)' : ''}</Text>
        <View style={[style.one_bucket, {zIndex: 100}]}>
          {attendees.length ? <View style={style.first}>
            {attendees.map((attendee, i)=>{return this.drawAttendee(attendee)})}
          </View> : <Text style={{height: 50, color: 'white', fontFamily: 'Ysabeau', fontSize: '17px',textAlign: 'center'}}>Be the 1st to RSVP and choose attendees.</Text>}

          {!rsvped && !is_full && !date_is_in_past ? 
          <TouchableOpacity onPress={()=>onPress(event)} style={style.second}>
            <Text style={style.you_goin}>RSVP &#x2197;</Text>
          </TouchableOpacity> : null}
        </View>
        
       {/* </> : <>
          {created ? <Text style={style.dont_shy}>{description}</Text> : null}
          <View style={[style.one_bucket, {zIndex: 99}]}>
          <View style={style.first}>
            {attendees.map((attendee, i)=>{return this.drawAttendee(attendee)})}
          </View>
          {!rsvped && !is_full && !date_is_in_past ? 
          <TouchableOpacity onPress={()=>onPress(event)} style={style.second}>
            <Text style={style.you_goin}>RSVP &#x2197;</Text>
          </TouchableOpacity> : null}
          </View>
       
       </>} */}
    </View> : null
  }

  dateInPast(the_date, num_hour) {
    let today = new Date();
    let today_date = `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`;

    let date_is_in_past = new Date(the_date) < new Date(today_date)

    if(the_date === today_date) {
      let currentTime = new Date().toLocaleString("en-US", { timeZone: "America/New_York" });

      // Extract the hour from the current time
      let currentHour = new Date(currentTime).getHours();

      if(currentHour >= num_hour + 2) {
        date_is_in_past = true
      }
    }

    return date_is_in_past;
  }

  drawEventSummary(event, the_day_name, display_date) {
    let { places, dinners, event_rsvps, tentative, user_id, selected_date, internal } = this.state;

    let uninvited = event.uninvited ? event.uninvited : {};
    uninvited =  [].concat(...Object.values(uninvited));
    let user_is_uninvited = uninvited.indexOf(user_id) !== -1;

    let place_id;

    let is_user_event = !!event.user_event;

    if(event.user_event) {
      place_id = event.chosen_where;
    } else {
      place_id = event.place_id;
    }

    let place = places.filter(a=>{return a.id === place_id});
    place = place && place.length ? place[0].place_data : {};

    let the_date = selected_date;

    let the_dinner = dinners ? dinners.filter(a=>{
      let event_data = a.event_data;
        return a.event_id === event.event_id
      }) : null
    the_dinner = the_dinner ? the_dinner[0] : null;

    let rsvped = !!the_dinner;

    let prelim_attendees = event_rsvps ? event_rsvps[event.event_id] : []

    let attendees = prelim_attendees && prelim_attendees.length ? prelim_attendees.map(a=>{
      let profile = JSON.parse(a.profile);
      let group = a.group;
      //let age = calculate_age(`${profile.birth_month ? profile.birth_month : '1'}/1/${profile.birth_year}`);
      let name = profile ? profile.name : null;
      let user_id = a.user_id;

      name = name ? name.split(' ')[0] : 'member';
      return {
        user_id,
        name,
        //age,
        profile,
        group
      }
    }) : [];

    let tentatives = tentative && tentative[event.event_id] ? tentative[event.event_id] : [];

    let liked = !!tentatives.filter(tent=>{return tent.user_id === user_id}).length;

    let limit_reached = event.rsvp_limit && event.rsvp_limit === attendees.length;

    const navigate = (link) => {
      let { isLoggedIn } = this.state;

      if(isLoggedIn) {
        this.props.navigate(link);
      } else {
        this.props.navigate('/pricing')
      }
    }

    const onLike = async () => {
      let { isLoggedIn } = this.state;

      if(isLoggedIn) {
        event.date = the_date;
        let response = await UserController.onLike({event});
        let events = await AuthController.getEvents();
        let { rsvps, tentative } = await UserController.getDateRsvps();

        this.setState({rsvps, tentative, events});
      } else {
        this.props.navigate('/register')
      }
    }

    const drawRSVP = function(onPress, rsvped, date_is_in_past, event) {
      let { new_create, is_full, rsvp_limit, canceled, event_rsvps } = event;

      if(!is_full && rsvp_limit) {
        if(typeof rsvp_limit === 'string') { rsvp_limit = parseInt(rsvp_limit)}
        let attendees = event_rsvps ? event_rsvps[event.event_id] : [];
        is_full = rsvp_limit === attendees.length;
      }

      return <View style={[style.action_rsvp, {flex: 1}]}>
        {is_full && !canceled && !date_is_in_past ? <Text style={style.full_text}>FULL</Text> : null}
        {rsvped && !date_is_in_past && !canceled ? <TouchableOpacity onPress={()=>onPress(event)}><Text style={style.you_goin}>you're going!</Text></TouchableOpacity> : null}

        {date_is_in_past && !canceled ? <Text style={style.canceled_text}>passed</Text> : null}

        {new_create ?
            <View style={style.first}>
              <TouchableOpacity style={style.start_event}><Text>Create Event</Text></TouchableOpacity>
            </View> : null}

        {canceled ? 
            <View onPress={()=>onPress(0)}>
              <Text style={style.canceled_text}>Canceled</Text>
            </View> : null}
      </View>
    }

    let onPress = (event) => this.rsvpDate(event);

    let { place_holder_image_calendar, movie_url, video_thumbnail, user_event, chosen_what, chosen_type, main_image } = event;

    let date_is_in_past = this.dateInPast(event.date, event.time);

    let { restaurant_name, link, main_video } = place;

    main_image = main_image ? main_image : place.main_image;

    let restaurant_button;
    if(!restaurant_name) {
      restaurant_name = "Create dinner";
      main_image = place_holder_image_calendar ? place_holder_image_calendar : 'https://s3.amazonaws.com/www.diningsocialnj.com/restaurant_placeholder.png';
      restaurant_button = null;
    } else {
      restaurant_button = <Text style={style.summary_visit_restaurant_text}>{restaurant_name}</Text>
    }

    let chosen_style = { };

    let created = event.demographic || event.type !== -1;

    let description = created ? event.description : `Start by creating a new dinner that others can choose to join. The person to initiate the dinner gets to choose the restaurant, exact dinner time and demographic of the dinner. Choose where you'd like to eat and who you'd like to meet!`;

    console.log('event', event);

    let video_url = movie_url ? movie_url : main_video;
    video_thumbnail = video_thumbnail ? video_thumbnail : 'https://diningsocial-assets.s3.amazonaws.com/HappyHourThumb.png';

    let is_mobile = width < 500;

    let name, time;
    if(user_event) {
      let what_drop = config.what_drop;
      let type_drop = config.type_drop;

      let { females, males, twenties, thirties, forties, fifties, sixties } = event;
  
      name = what_drop.filter(what=>what.value===chosen_what);
      name = name ? name[0] : {};
      name = name.name;
  
      let type = type_drop.filter(type=>type.value===chosen_type);
      type = type ? type[0] : {};
      main_image = type.placeholder_image;
      type = type.name;

      let ages = `${twenties ? '20s,' : ''}${thirties ? '30s,' : ''}${forties ? '40s,' : ''}${fifties ? '50s,' : ''}${sixties ? '60s+' : ''}`;

      ages = ages[ages.length-1] === "," ? ages.substr(0, ages.length - 1) : ages;

      ages = ages && ages !== '' ? ages : 'Mixed Ages';

      let genders  = (females && males) || (!females && !males) ? 'Mixed Genders' : (females ? 'Women' : 'Men');

      /* {
        "tab": 0,
        "males": true,
        "price": 5,
        "status": "published",
        "females": true,
        "message": "Let's get coffee!",
        "fee_back": false,
        "forties": true,
        "thirties": true,
        "created_by": "cc467f19-6954-4151-ba3c-c45c8563511c",
        "non_hosted": true,
        "user_event": true,
        "chosen_time": "3 PM",
        "chosen_type": "1",
        "chosen_what": "1",
        "chosen_where": "3be1a76a-b36a-4210-b268-c10b9cc276ac",
        "selected_day": "3/19/2024",
        "invited_members": [],
        "stripe_price_not_hosted_live": "price_1OqiQPF4ANwwDrzGsJaiXhdK",
        "stripe_price_not_hosted_test": "price_1OqiQ2F4ANwwDrzG3rsIA1vF"
      } */

      name = `${ages} - ${genders} - ${type}`;
      time = event.chosen_time;
    } else {
      name = event.name;
      time = event.time ? Utilities.getEventTime(event.time) : null;
    }

    let wrap_style = is_user_event ? style.summary_one_event_user : style.summary_one_event;

    let media_element = video_url ?
              <Image style={{width: '100%', height: is_mobile ? 200 : 150}} source={{uri: video_thumbnail}} />
            : <Image style={style.summary_image_main} source={{uri: main_image}} />

    return <View style={wrap_style}>
      <View style={{flexDirection: 'column'}}>
        {media_element} 
        {user_is_uninvited ?
            <View style={{flexDirection: 'column'}}>
              <Text style={style.summary_event_day_text}>Invite only Event</Text>
              <Text style={style.summary_event_day_text_date}>{the_day_name} {display_date} {event.time ? Utilities.getEventTime(event.time): null}</Text>
              <View>
                {user_is_uninvited ? null : restaurant_button}
              </View>
            </View>
          : <TouchableOpacity onPress={()=>onPress(event)}>
            <View style={{flexDirection: 'column'}}>
              <Text style={style.summary_event_day_text}>{name}</Text>
              <Text style={style.summary_event_day_text_date}>{the_day_name} {display_date} {time ? time : null}</Text>
              <View>
                {user_is_uninvited ? null : restaurant_button}
              </View>
            </View>
          </TouchableOpacity>}
      </View>
         
        <View style={{flexDirection: 'column', flex: 2}}>

          {user_is_uninvited ? null : this.drawAttendees(attendees, rsvped, onPress, event, tentatives, onLike, liked) }
        
          {user_is_uninvited ? null : (
          limit_reached ?
          <Text style={style.full_text_2}>This event is FULL</Text> :
          drawRSVP(onPress, rsvped, date_is_in_past, event))}
        </View>
      </View>
  }

  drawEventDetails(event, the_day_name, display_date) {
    let { places, dinners, event_rsvps, tentative, user_id, selected_date, internal } = this.state;

    let uninvited = event.uninvited ? event.uninvited : {};
    uninvited =  [].concat(...Object.values(uninvited));
    let user_is_uninvited = uninvited.indexOf(user_id) !== -1;

    let place = places.filter(a=>{return a.id === event.place_id});
    place = place && place.length ? place[0].place_data : {};

    let the_date = selected_date;

    let the_dinner = dinners ? dinners.filter(a=>{
      let event_data = a.event_data;
        return a.event_id === event.event_id
      }) : null
    the_dinner = the_dinner ? the_dinner[0] : null;

    let rsvped = !!the_dinner;

    let prelim_attendees = event_rsvps ? event_rsvps[event.event_id] : []

    let attendees = prelim_attendees && prelim_attendees.length ? prelim_attendees.map(a=>{
      let profile = JSON.parse(a.profile);
      let group = a.group;
      //let age = calculate_age(`${profile.birth_month ? profile.birth_month : '1'}/1/${profile.birth_year}`);
      let name = profile ? profile.name : null;
      let user_id = a.user_id;

      name = name ? name.split(' ')[0] : 'member';
      return {
        user_id,
        name,
        //age,
        profile,
        group
      }
    }) : [];

    let tentatives = tentative && tentative[event.event_id] ? tentative[event.event_id] : [];

    let liked = !!tentatives.filter(tent=>{return tent.user_id === user_id}).length;

    let limit_reached = event.rsvp_limit && event.rsvp_limit === attendees.length;

    const navigate = (link) => {
      let { isLoggedIn } = this.state;

      if(isLoggedIn) {
        this.props.navigate(link);
      } else {
        this.props.navigate('/pricing')
      }
    }

    const onLike = async () => {
      let { isLoggedIn } = this.state;

      if(isLoggedIn) {
        event.date = the_date;
        let response = await UserController.onLike({event});
        let events = await AuthController.getEvents();
        let { rsvps, tentative } = await UserController.getDateRsvps();

        this.setState({rsvps, tentative, events});
      } else {
        this.props.navigate('/register')
      }
    }

    const drawRSVP = function(onPress, rsvped, date_is_in_past, event) {
      let { new_create, is_full, rsvp_limit, canceled, event_rsvps } = event;

      if(!is_full && rsvp_limit) {
        if(typeof rsvp_limit === 'string') { rsvp_limit = parseInt(rsvp_limit)}
        let attendees = event_rsvps ? event_rsvps[event.event_id] : [];
        is_full = rsvp_limit === attendees.length;
      }

      return <View style={[style.action_rsvp, {flex: 1}]}>
        {is_full && !canceled && !date_is_in_past ? <Text style={style.full_text}>FULL</Text> : null}
        {rsvped && !date_is_in_past && !canceled ? <TouchableOpacity onPress={()=>onPress(event)}><Text style={style.you_goin}>you're going!</Text></TouchableOpacity> : null}

        {date_is_in_past && !canceled ? <Text style={style.canceled_text}>passed</Text> : null}

        {new_create ?
            <View style={style.first}>
              <TouchableOpacity style={style.start_event}><Text>Create Event</Text></TouchableOpacity>
            </View> : null}

        {canceled ? 
            <View onPress={()=>onPress(0)}>
              <Text style={style.canceled_text}>Canceled</Text>
            </View> : null}
      </View>
    }

    let onPress = (event) => this.rsvpDate(event);

    let { place_holder_image_calendar, movie_url, video_thumbnail} = event;

    let date_is_in_past = this.dateInPast(the_date, event.time);

    let { restaurant_name, link, main_image, main_video } = place;
    let restaurant_button;
    if(!restaurant_name) {
      restaurant_name = "Create dinner";
      main_image = place_holder_image_calendar ? place_holder_image_calendar : 'https://s3.amazonaws.com/www.diningsocialnj.com/restaurant_placeholder.png';
      /* restaurant_button = <TouchableOpacity onPress={()=>onPress(-1)} style={style.visit_restaurant}><Text style={style.visit_restaurant_text}>@ {restaurant_name}  &#x2197;</Text></TouchableOpacity> */
      restaurant_button = null;
    } else {
      restaurant_button = <Text style={style.visit_restaurant_text}>@ {restaurant_name}</Text>

      {/* <TouchableOpacity onPress={()=>{window.open(link)}} style={style.visit_restaurant}><Text style={style.visit_restaurant_text}>@ {restaurant_name}  &#x2197;</Text></TouchableOpacity> */}
    }

    let chosen_style = { };

    let created = event.demographic || event.type !== -1;

    let description = created ? event.description : `Start by creating a new dinner that others can choose to join. The person to initiate the dinner gets to choose the restaurant, exact dinner time and demographic of the dinner. Choose where you'd like to eat and who you'd like to meet!`;

    console.log('event', event);

    let video_url = movie_url ? movie_url : main_video;
    video_thumbnail = video_thumbnail ? video_thumbnail : 'https://diningsocial-assets.s3.amazonaws.com/HappyHourThumb.png';

    let media_element = video_url ? <View style={{position: 'relative'}}>
            <View style={{width: 300, position: 'relative', marginHorizontal: 'auto'}}>
              <Image style={{width: 300, height: 520, position: 'absolute'}} source={{uri: video_thumbnail}} />
            </View>
          <Video
            posterSource={{uri: 'https://diningsocial-assets.s3.amazonaws.com/HappyHourThumb.png'}}
            source={{ uri: video_url }}
            style={{width: 300, position: 'relative', marginHorizontal: 'auto'}}
            videoStyle={{position: 'relative'}}
            useNativeControls={true}
            shouldPlay={false}
            resizeMode="contain" // Or adjust as needed
          /></View> : <Image style={style.image_main} source={{uri: main_image}} />

    let description_component = Utilities.getDescription(description, style, false);

    return <View style={style.one_event}>
        {media_element}  
        <View style={{flexDirection: 'column', flex: 2, marginRight: 20}}>
          {user_is_uninvited ?
            <>
              <Text style={style.event_day_text}>Invite only Event</Text>
              <Text style={style.event_day_text_date}>{the_day_name} {display_date} {event.time ? Utilities.getEventTime(event.time): null}</Text>
            </>
          : <TouchableOpacity onPress={()=>onPress(event)}>
            <>
              <Text style={style.event_day_text}>{event.name}</Text>
              <Text style={style.event_day_text_date}>{the_day_name} {display_date} {event.time ? Utilities.getEventTime(event.time): null}</Text>
            </>
          </TouchableOpacity>}
          
          <View>
            {user_is_uninvited ? null : restaurant_button}
          </View>
          {user_is_uninvited ? 
            <View>
            <Text style={style.description}>This event is invite only</Text>
          </View>
          :
          <View>
            <Text style={style.description}>{description_component}</Text>
          </View>}
          {user_is_uninvited ? null : this.drawAttendees(attendees, rsvped, onPress, event, tentatives, onLike, liked)}
        
          {user_is_uninvited ? null : (
          limit_reached ?
          <Text style={style.full_text_2}>This event is FULL</Text> :
          drawRSVP(onPress, rsvped, date_is_in_past, event))}
        </View>
      </View>
  }

  drawPrice(price) {

    return <View style={style.price_wrapper}>
      <Text style={style.price_text}>${price}</Text><TouchableOpacity onPress={()=>{this.props.navigate('/dashboard/subscription')}}><Text style={style.price_member_text}>(member price<Text style={style.price_member_text_number}> $0.00</Text>)</Text></TouchableOpacity>
    </View>
  }
  
  drawEvent(event, i) {
    let { places, dinners, event_rsvps, selected_event, hosts } = this.state;

    let prelim_attendees = event_rsvps ? event_rsvps[event.event_id] : []

    let place = places.filter(a=>{return a.id === event.place_id});
    place = place && place.length ? place[0].place_data : {};

    let the_dinner = dinners ? dinners.filter(a=>{
      let event_data = a.event_data;
        return a.event_id === event.event_id
      }) : null
    the_dinner = the_dinner ? the_dinner[0] : null;

    let rsvped = !!the_dinner;

    let { restaurant_name, link, main_image } = place;
    
    let host_obj;
    if(event.hosted && event.host) {
      console.log('hosts', hosts)
      host_obj = hosts ? hosts.filter(host => {return host.user_id === event.host }) : null;
      console.log('host_obj', host_obj)
      host_obj = JSON.parse(host_obj[0].profile);
    }

    let chosen_style = {};

    let is_mobile = width < 700;

    if(!is_mobile && ((!selected_event && i === 0) || (selected_event && selected_event.event_id === event.event_id))) {
      chosen_style = {
        backgroundColor: '#ebf3f5'
      }
    }

    let current_rsvps = prelim_attendees.length;

    let rsvp_needed = event.rsvp_needed ? event.rsvp_needed : (event.hosted ? 4 : 3);

    let needs_more = event.event_id && prelim_attendees ? ( rsvp_needed - current_rsvps ) : null;

    let needed_rsvps = needs_more;

    console.log('needed_rsvps', needed_rsvps)

    let rsvps_open = event.hosted ? event.rsvp_limit_hosted - current_rsvps : event.rsvp_limit_not_hosted - current_rsvps;

    return <TouchableOpacity onPress={()=>{event.time ? this.selectDayEvent(i) : this.rsvpDate(event)}}>
      <View style={[style.event_summary, chosen_style]}>
        <View style={style.event_summary_top}>
          <Image style={style.event_summary_image} source={{uri: main_image}} />
          <Text style={style.event_summary_name}>{event.name}</Text>
          {event.time ?
            <View style={style.event_summary_location_time_wrapper}><Text style={style.event_summary_location_time}>{Utilities.getEventTime(event.time)}</Text></View> : <View><Text style={style.suggest_description}>Suggest a dinner time and restaurant to better fit your schedule. <TouchableOpacity onPress={()=>this.rsvpDate(event)}><Text style={style.sugest_go_button}>Suggest</Text></TouchableOpacity></Text></View>}

        </View>
        
        <View style={style.event_summary_location}>
          <Text style={style.event_summary_location_title}>{restaurant_name}</Text>
          {/* <Text style={style.event_summary_location_at}>at</Text> */}
        </View>
        {/* {event.price ?
        <View style={style.event_summary_price_wrap}>
          {is_member || rsvped ? null : this.drawPrice(event.price)}
        </View> : null} */}
        {event.time ?
        ( needed_rsvps > 0 ? <View style={style.event_summary_location}><TouchableOpacity onPress={()=>this.rsvpDate(event)}><Text style={[global_style.normal_link]}>⭐️NEEDS {needed_rsvps} RSVPs⭐️</Text></TouchableOpacity></View> : <View style={style.event_summary_location}><TouchableOpacity onPress={()=>this.rsvpDate(event)}><Text style={[global_style.normal_link]}>{rsvps_open === 0 ? 'FULL' : `${rsvps_open} seats open`}</Text></TouchableOpacity></View>) : null}

        {event.hosted ?
        <>
          {host_obj ?
          <View style={style.event_summary_hosted_wrap}>
            <Text style={style.event_summary_hosted_hosted}>HOSTED</Text>
            <Text style={style.event_summary_hosted_by}>by</Text>
            <Text style={style.event_summary_hosted_name}>{host_obj.name}</Text>
          </View> : 
          <View style={style.event_summary_hosted_wrap}>
            <Text style={style.event_summary_hosted_hosted}>HOSTED</Text>
            <Text style={style.event_summary_hosted_by}> </Text>
            <TouchableOpacity onPress={()=>this.rsvpDate(event)}><Text style={[global_style.normal_link]}>⭐️NEEDS A HOST⭐️</Text></TouchableOpacity>
          </View>}
        </> : (
          event.hosted === false ?
        <View>
          <View style={style.event_summary_hosted_wrap}>
            <Text style={style.event_summary_hosted_not_hosted}>NOT-HOSTED</Text>
          </View>
          </View> : null)}
      </View>
    </TouchableOpacity>
  }

  weekDays(start_date) {
    start_date = new Date(start_date);

    let start_date_clone = _.cloneDeep(start_date);

    let date_1 = new Date(start_date_clone.setDate(start_date_clone.getDate() + 1));
    let date_1_clone = _.cloneDeep(date_1);

    let date_2 = new Date(date_1_clone.setDate(date_1_clone.getDate() + 1));
    let date_2_clone = _.cloneDeep(date_2);

    let date_3 = new Date(date_2_clone.setDate(date_2_clone.getDate() + 1));

    return { date_1, date_2, date_3 }
  }

  getMonthName(month) {
    switch(month) {
      case 0:
        return 'January';
      case 1:
        return 'February';
      case 2:
        return 'March';
      case 3:
        return 'April';
      case 4:
        return 'May';
      case 5:
        return 'June';
      case 6:
        return 'July';
      case 7:
        return 'August';
      case 8:
        return 'September';
      case 9:
        return 'October';
      case 10:
        return 'November';
      case 11:
        return 'December';
    }
  }

  /**
   * check
   *  events created in the events table
   *    whether any of these events was canceled
   *  whether day is a holiday
   *  whether day was canceled
   */
  validDay(selected_date, internal, events) {
    let { canceled_days, holidays } = internal;
    events = events ? events : [];
    let approved;

    let the_date = `${selected_date.getMonth() + 1}/${selected_date.getDate()}/${selected_date.getFullYear()}`;

    // Get any non-canceled events
    let existing_events = events.filter(event => { 
      let date_is_in_past = this.dateInPast(event.event_data.date, event.event_data.time);
      return event.event_data.date === the_date && !date_is_in_past && !event.event_data.canceled
    });

    // Check if the whole day was canceled
    let is_canceled = canceled_days.includes(the_date);
    let is_holiday = holidays.includes(the_date);

    approved = !is_holiday && existing_events && existing_events.length && !is_canceled;

    return approved;
  }

  getNextValidDay(selected_date, internal, events) {
    let approved;
    do {
      selected_date = new Date(selected_date.setDate(selected_date.getDate() + 1));
      approved = this.validDay(selected_date, internal, events)
    } while(!approved)

    return selected_date;
  }

  calculateDatesWithEvents(start_date, end_date, internal, events) {

    let the_date = start_date;
    let calendar_days = [];
    let string_date;

    while(the_date !== end_date) {
      string_date = the_date;
      the_date = new Date(the_date);

      if(this.validDay(the_date, internal, events)) {
        calendar_days.push(string_date);
      }
      
      the_date = new Date(the_date.setDate(the_date.getDate() + 1));
      the_date = `${the_date.getMonth() + 1}/${the_date.getDate()}/${the_date.getFullYear()}`;
    }

    console.log('calendar_days', calendar_days);
    return calendar_days;
  }

  // Draw calendar two months in advance
  drawCalendar() {

    /* let { selected_date, props_date_set, calendar_days } = this.state;

    if(this.props.updateDate && selected_date !== this.props.updateDate && !props_date_set) {
      selected_date = this.props.updateDate;
      props_date_set = true;
    }

    let selected_date_str = `${selected_date.getMonth() + 1}/${selected_date.getDate()}/${selected_date.getFullYear()}`;

    let index_of_day = calendar_days.indexOf(selected_date_str);

    let has_prev = index_of_day !== 0;
    let has_next = index_of_day !== calendar_days.length - 1;

    let next_date, prev_date;
    if(has_next) {
      next_date = calendar_days[index_of_day + 1];
    }

    if(has_prev) {
      prev_date = calendar_days[index_of_day - 1];
    }

    const prevDay = () => {
      let selected_date = new Date(prev_date)

      this.setState({
        props_date_set,
        selected_date
      })
    }

    const nextDay = () => {
      let selected_date = new Date(next_date)

      this.setState({
        props_date_set,
        selected_date
      })
    } */

    return <View ref={node => this.calendarRef = node} style={style.calendar_box}>
      <View style={style.days_wrapper}>
        <View style={style.main_day_calendar}>{this.drawDay()}</View>
      </View>
    </View>
  }

  getRealDate(date) {
    let date_number = date.getDate();
    let year = date.getFullYear();
    let month_num_real = date.getMonth() + 1;
    let real_date = `${month_num_real}/${date_number}/${year}`;

    return real_date;
  }

  drawChosenDate(date, data) {
    let today = new Date();
    let today_date = `${today.getMonth() + 1}/${today.getDate()}/${today  .getFullYear()}`;

    let date_is_in_past = new Date(date) < new Date(today_date);
    return date_is_in_past || !data.chosen ? null :<TouchableOpacity key={`date_${date}`} onPress={()=>this.updateDate(new Date(date))}>
        <Text style={[style.funky_text]}>{date}</Text>
    </TouchableOpacity>
  }

  openBubble(profile_attendee) {
    this.modal_ref.setContent(<Profile onPress={this.openBubble} attendee={profile_attendee} />, 600, '100vh', null)
  }

  /* openBubble(profile_attendee) {

    this.props.putInParent(!this.state.is_profile_open ? 
      <TouchableOpacity activeOpacity={1} style={{zIndex: 10000000, position: 'fixed', width: '100vw', height: '100vh'}} onPress={this.openBubble}>
        <Profile onPress={this.openBubble} attendee={profile_attendee} />      
      </TouchableOpacity> : null);

    this.setState({
      profile_attendee,
      is_profile_open: !this.state.is_profile_open
      //is_open: !this.state.is_open
    })
  } */

  selectDayEvent(i) {
    let { events, selected_date } = this.state;

    let is_mobile = width < 700;

    let the_date = `${selected_date.getMonth() + 1}/${selected_date.getDate()}/${selected_date.getFullYear()}`;

    let day_events = events.filter(event=>{
      return event.event_data.date === the_date
    });

    day_events = day_events.sort((a, b)=>{
      return a.time < b.time ? -1 : 1
    });

    let selected_event = day_events[i];

    this.setState({
      selected_event
    }, ()=>{
      if(is_mobile) {
        this.openInParent(<View>
          {this.drawEventSummary(selected_event)}
        </View>, "is_mobile_event_open", '100%', '90vh')
      }
    })
  }

  selectDay(new_selected_date) {
    let { events, filter, selected_date } = this.state;

    selected_date = selected_date === new_selected_date ? null : new_selected_date;

    if(width < 700) {
      this.scrollTo(this.calendarRef);
    }

    this.setState({
      selected_date,
      more_clicked: false
    }, () => {
      this.filterOnFilter(!selected_date, filter);
    })
  }

  resetFilters() {
    let { events } = this.state;

    this.setState({
      page: 0,
      event_list_page: null,
      more_clicked: false,
      filter: null,
      selected_date: null,
      calendar_event_list: events,
      event_list: events
    })
  }

  toggleState(name) {
    console.log('toggling', name, this.state[name])

    if(this.state[name]) {
      this.props.putInParent(null)
    }

    this.setState({
      [name]: !this.state[name]
    })
  }

  openInParent(content, state_variable, width, height) {
    console.log('drawing in parent again', state_variable, this.state[state_variable]);
    this.props.putInParent(!this.state[state_variable] ? 
      <TouchableOpacity style={{zIndex: 10000000, position: 'fixed', width: '100vw', height: '100vh'}} onPress={()=>this.toggleState(state_variable)}>
      <>
        <TouchableOpacity onPress={()=>this.toggleState(state_variable)} style={global_style.modal_overlay}></TouchableOpacity>
        <View style={{position: 'fixed', width: '100vw', height: '100vh', zIndex: 2000000}}>
        <View style={[global_style.modal_content_mobile, {display: 'block', width, height, left: `calc(50% - ${width / 2}px)`}]}>
            <Text style={[global_style.modal_close_x, global_style.padding_20]}>x</Text>
            <ScrollView style={{flex: 1, height: 'calc(80vh - 20px)' }}>
              <View style={[global_style.padding_20]}>{content}</View>
          </ScrollView>
        </View>
        </View>
      </>

      </TouchableOpacity> : null);

    this.setState({
      [state_variable]: !this.state[state_variable]
    });
  }

  drawDay() {

    let is_mobile = width < 700;
    let page_size = is_mobile ? 1 : 6;
    
    let { event_list, event_list_page, page } = this.state;
    page = page ? page : 0;
    let day_events = event_list;
    day_events = event_list_page ? event_list_page : event_list.slice(0, page_size);

    const goPage = (page) => {
      let { event_list } = this.state;

      let start_index = (page) * page_size;
      let end_index = start_index + page_size;
      let event_list_page = event_list.slice(start_index, end_index);

      this.setState({
        page,
        event_list_page
      })
    }

    return <View >
              {<Pager goPage={goPage} page={page} search_results={day_events} num_per_page={page_size} />}
      {day_events.length ?
      <View style={style.events_wrapper}>
        {day_events.map(event => {
          let date = new Date(event.date);

          let the_date = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
          let display_date = `${date.getMonth() + 1}/${date.getDate()}`;

          let the_day = (new Date(the_date)).getDay();
          let the_day_name = Utilities.getDayName(the_day);

          return <View style={style.event_details_wrapper}>
            {this.drawEventSummary(event, the_day_name, display_date)}
          </View>
        })}
      </View> : 
      <View style={style.events_wrapper}>
        <View style={style.day_events_wrapper_none}>
          <Text style={style.no_events_text}>No events with the chosen filters</Text>
          <TouchableOpacity onPress={this.resetFilters}><Text style={style.reset_link}>Reset filters</Text></TouchableOpacity>
        </View>
        <View>
          
        </View>
      </View>}
      {<Pager goPage={goPage} page={page} search_results={day_events} num_per_page={page_size} />}
    </View>
  }

  filterOnFilter(clear_date, filter) {
    console.log('clear_date, filter', clear_date, filter)
    let { events, selected_date, starting_date } = this.state;
    let event_list = events;
    let calendar_event_list;

    let today = new Date();
    let today_str = (today.getMonth() + 1)+'/'+(today.getDate())+'/'+(today.getFullYear());
    starting_date = selected_date ? selected_date : today_str;
    
    switch(filter) {
      case 1:
        event_list = events.filter(ev=>{
          return ev.free
        });
        starting_date = selected_date ? selected_date : today_str;
        break;
      case 2:
        event_list = events.filter(ev=>{
          return ev.free && ev.new_member
        });
        starting_date = selected_date ? selected_date : today_str;
        break;
      case 3: 
        event_list = events.filter(ev=>{
          return !ev.free || (ev.free && ev.new_member)
        });
        starting_date = selected_date ? selected_date : today_str;
        break;
      case 4:
        event_list = events.filter(ev=>{
            return !!ev.jcrf;
        });
        starting_date = '3/2/2024'
        break;
      case 5:
        event_list = events.filter(ev=>{
          return !!ev.user_event
        });
        starting_date = selected_date ? selected_date : today_str;
        break;
      default: 
        event_list = events;
        break;
    }

    calendar_event_list = event_list;
    if(clear_date) {
      selected_date = null;
    } else if(selected_date) {
      event_list = event_list.filter(event=>event.date === selected_date)
    }

    if(selected_date) {
      event_list = event_list.filter(event=>{
        return event.date === selected_date
      });
    }

    event_list = event_list.sort((a, b)=>{
      if(a.date === b.date) {
        return a.time < b.time ? -1 : 1
      } else {
        return new Date(a.date ) < new Date(b.date) ? -1 : 1
      }
    });

    this.setState({
      event_list_page: null,
      calendar_event_list,
      starting_date,
      selected_date,
      filter,
      event_list,
      page: 0
    }, ()=>{
      if(width < 700) {
        this.scrollTo(this.calendarRef);
      }
    })
  }

  filterJCRFEvents(clear_date) {
    let { filter } = this.state;
    filter = filter === 4 && !clear_date ? null : 4;
    this.filterOnFilter(clear_date, filter)
  }

  filterUserEvents(clear_date) {
    let { filter } = this.state;
    filter = filter === 5 && !clear_date ? null : 5;
    this.filterOnFilter(clear_date, filter)
  }

  filterHostedEvents(clear_date) {
    let { filter } = this.state;
    filter = filter === 3 ? null : 3;
    this.filterOnFilter(clear_date, filter)
  }

  filterFreeEventsNewMember(clear_date) {
    let { filter } = this.state;
    filter = filter === 2 ? null : 2;
    this.filterOnFilter(clear_date, filter)
  }

  filterFreeEvents(clear_date) {
    let { filter } = this.state;
    filter = filter === 1 ? null : 1;
    this.filterOnFilter(clear_date, filter)
  }

  drawMonthCalendar() {

    let { events, internal, filter, starting_date, today_str, start_date, mobile_calendar_open, event_list, selected_date, calendar_event_list } = this.state;

    let first_event = events[0];

    console.log('first_event', first_event);
    console.log('first_event', first_event);

    let the_dates = {}, the_date;
    calendar_event_list.map(event =>{
      the_date = moment(event.date).format('YYYY-MM-DD');
      the_dates = {
        ...the_dates,
        [the_date]: { marked: true, selectedColor: 'blue'}
      }

    });

    console.log('the_dates', the_dates);

    /**
     *  -1 : Open Dinner
     *  -2 : Open Lunch
     *  -3 : Open Coffee
     */

    /**
     * Demographic
     * 0 - Mixed Ages
     * 1 - 20s
     * 2 - 20s/30s
     * 3 - 30s
     * 4 - 40s
     * 5 - 40s+
     * 6 - 50s
     * 7 - 50s+
     */



    let types = internal.demographics;

    let hosted_open = false;
    const toggleHosted = () => {
      this.setState({
        hosted_filter_open: !this.state.hosted_filter_open,
        type_filter_open: false
      })
    }

    const toggleType = () => {
      this.setState({
        type_filter_open: !this.state.type_filter_open,
        hosted_filter_open: false
      })
    }

    const selectHosted = (hosted) => {
      let { filter, selected_date, events } = this.state;

      filter.hosted = hosted ? 'Hosted' : 'Non-Hosted';

      events = events.map(event => {
        let keys = Object.keys(event);

        if(event.hosted === hosted || keys.indexOf('hosted') === -1) {
          event.not_view = false;
        } else {
          event.not_view = true;
        }

        return event;
      });
      
      this.setState({
        events,
        filter,
        type_filter_open: false,
        hosted_filter_open: false
      })
    }

    const selectType = (type) => {
      let filter = this.state.filter;

      filter.type = type;

      events = events.map(event => {
        if(event.hosted === hosted) {
          event.not_view = false;
        } else {
          event.not_view = true;
        }

        return event;
      });
      
      this.setState({
        filter,
        type_filter_open: false,
        hosted_filter_open: false
      })
    }

    let is_mobile = width < 700;

    console.log('starting_date', starting_date)

    let calendar_module = <CalendarModule
          minDate={starting_date}
          initialDate={starting_date}
          style={{
            borderWidth: 1,
            borderColor: '#b7d5d5',
            width: is_mobile ? 'calc(100vw - 40px)' : 220,
            borderRadius: 5
          }} 
          renderArrow={(direction)=>{
            if(direction === 'left') {
              return <Text style={{color: '#b7d5d5'}}>&lt;</Text>
            } else {
              return <Text style={{color: '#b7d5d5'}}>&gt;</Text>
            }
          }}
          
          // Specify the current date
          current={starting_date}
          // Callback that gets called when the user selects a day
          onDayPress={day => {
            /* let the_date = new Date(`${day.month}/${day.day}/${day.year}`);
            if(this.validDay(the_date, internal, events)) {
              this.selectDay(the_date);
            } */
            this.selectDay(`${day.month}/${day.day}/${day.year}`);
          }}
          // Mark specific dates as marked
          markedDates={the_dates}
        />


    const openCalendar = () => {
      this.setState({
        mobile_calendar_open: !this.state.mobile_calendar_open
      })
    }

    mobile_calendar_open = is_mobile;

    const resetFilters = () => {
      event_list = events

      this.setState({
        event_list_page: null,
        page: 0,
        more_clicked: false,
        selected_date: null,
        filter: null,
        event_list,
        calendar_event_list: event_list
      })
    }

    let backgroundColor = filter === 2 ? '#e32652' : 'gray';
    let backgroundColorFree = filter === 1 ? '#e32652' : 'gray';
    let backgroundColorHosted = filter === 3 ? '#e32652' : 'gray';
    let backgroundColorJCRF = filter === 4 ? '#e32652' : 'gray';
    let backgroundColorUserEvents = filter === 5 ? '#e32652' : 'gray';

    let selected_date_display;

    if(selected_date) {
      let el_date = new Date(selected_date);

      selected_date_display = `${el_date.getMonth() + 1}/${el_date.getDate()}`
    }

    const createEvent = () => {
      let { selected_date } = this.state;
      this.props.navigate(`/rsvp/${selected_date.replace(/\//g, ".")}/-1`);
    }

    return <View style={[style.calendar_filters_wrapper, {flexDirection: 'column'} ]}>
      {/* <View style={[global_style.mobile_view]}>
        <TouchableOpacity onPress={openCalendar}><FontAwesomeIcon icon={faCalendar} size={40} color="light-grey" /></TouchableOpacity>
      </View> */}
      <View style={[mobile_calendar_open ? global_style.mobile_view : global_style.desktop_view]}>
          <View style={style.calendar_wrapper}>{calendar_module}</View>
      </View>
      <View style={{marginTop: 10}}>
        <View style={{marginBottom: 10, width: '100%'}}>
        <Button title={<Text style={[style.club_button_text, {textAlign: 'center'}]}>JCRF Events</Text>}
                loading={false}
                disable_loading={true}
                disable_imediately={false}

                style_inactive={{backgroundColor: '#85bebe',
                  alignSelf: 'center',
                  borderRadius: 3,
                  paddingTop: 5,
                  paddingBottom: 7,
                  paddingHorizontal: 5}}

                  style={[style.filter_button, { backgroundColor: backgroundColorJCRF, flex: 1, width: '100%', justifyContent: 'center', marginBottom: is_mobile ? 0 : 10}]}

                onPress={()=>this.filterJCRFEvents(false)} />
        </View>
        <View style={{marginBottom: 10, width: '100%'}}>
        <Button title={<Text style={[style.club_button_text, {textAlign: 'center'}]}>Coffee Invites</Text>}
                loading={false}
                disable_loading={true}
                disable_imediately={false}

                style_inactive={{backgroundColor: '#85bebe',
                  alignSelf: 'center',
                  borderRadius: 3,
                  paddingTop: 5,
                  paddingBottom: 7,
                  paddingHorizontal: 5}}

                  style={[style.filter_button, { backgroundColor: backgroundColorUserEvents, flex: 1, width: '100%', justifyContent: 'center', marginBottom: is_mobile ? 0 : 10}]}

                onPress={()=>this.filterUserEvents(false)} />
        </View>
        <View style={[global_style.column_mobile_switch_row, {justifyContent: 'space-evenly'}]}>
          
          <View style={{marginBottom: 10, flex: 1}}>
            <Button title={<Text style={style.club_button_text}>FREE Events</Text>}
              loading={false}
              disable_loading={true}
              disable_imediately={false}

              style_inactive={{backgroundColor: '#85bebe',
                alignSelf: 'center',
                borderRadius: 3,
                paddingTop: 5,
                paddingBottom: 7,
                paddingHorizontal: 20, width: 207}}

              style={[style.filter_button, {marginRight: is_mobile ? 10 : 0, backgroundColor: backgroundColorFree}]}

              onPress={()=>this.filterFreeEvents(false)} />
          </View>
          <View style={{marginBottom: 10, flex: 2}}>
            <Button title={<Text style={style.club_button_text}>New Member FREE Events</Text>}
              loading={false}
              disable_loading={true}
              disable_imediately={false}

              style_inactive={{backgroundColor: '#85bebe',
                alignSelf: 'center',
                borderRadius: 3,
                paddingTop: 5,
                paddingBottom: 7,
                paddingHorizontal: 5, width: 207}}

                style={[style.filter_button, { backgroundColor}]}

              onPress={()=>this.filterFreeEventsNewMember(false)} />
          </View>
          
        </View>
        <View style={[global_style.column_mobile_switch_row, {justifyContent: 'space-evenly'}]}>

              <Button title={<Text style={[style.club_button_text, {textAlign: 'center'}]}>Hosted Events</Text>}
                loading={false}
                disable_loading={true}
                disable_imediately={false}

                style_inactive={{backgroundColor: '#85bebe',
                  alignSelf: 'center',
                  borderRadius: 3,
                  paddingTop: 5,
                  paddingBottom: 7,
                  paddingHorizontal: 5, width: 207}}

                  style={[style.filter_button, { backgroundColor: backgroundColorHosted, flex: 1, justifyContent: 'center', marginBottom: is_mobile ? 0 : 10}]}

                onPress={()=>this.filterHostedEvents(false)} />
       
            {selected_date ? 
              <Button title={<Text style={style.club_button_text}>{selected_date_display}</Text>}
                loading={false}
                disable_loading={true}
                disable_imediately={false}

                style_inactive={{backgroundColor: '#85bebe',
                  alignSelf: 'center',
                  borderRadius: 3,
                  paddingHorizontal: 5, width: 207}}

                  style={[style.filter_button, { backgroundColor: '#e32652', flex: 1, marginLeft: is_mobile ? 10 : 0, justifyContent: 'center'}]}

                onPress={()=>{this.selectDay(null)}} />
             : null}
          </View>
        
        <TouchableOpacity onPress={resetFilters}><Text style={[style.link, { marginLeft: is_mobile ? 0 : 10, marginTop: 10}]}>Clear filters</Text></TouchableOpacity>

        {/* {selected_date && is_member ?
          <View style={{marginBottom: 10, flex: 2 }}>
            <Button title={<Text style={style.club_button_text}>Create Event</Text>}
              loading={false}
              disable_loading={true}
              disable_imediately={false}

              style_inactive={{backgroundColor: '#85bebe',
                alignSelf: 'center',
                borderRadius: 3,
                paddingTop: 5,
                paddingBottom: 7,
                paddingHorizontal: 5, width: 207}}

                style={[style.filter_button, { backgroundColor: '#e32652'}]}

              onPress={createEvent} />
          </View> : null} */}
      </View>


      {/* <View style={[style.filters_wrapper, global_style.desktop_view]}>
        <Text style={style.filters_title}>Filters</Text>
        <TouchableOpacity onPress={toggleHosted}><Text style={[style.filter_choice, { backgroundColor: hosted ? '#20cac8' : 'lightgrey' }]}>{hosted ? hosted : 'Hosted'}</Text></TouchableOpacity>
        <View style={[style.types_wrapper, {display: hosted_filter_open ? 'block' : 'none'}, {top: 98}]}>
          <TouchableOpacity onPress={()=>selectHosted(true)}><Text style={style.type_selection}>Hosted</Text></TouchableOpacity>
          <TouchableOpacity onPress={()=>selectHosted(false)}><Text style={style.type_selection}>Non-Hosted</Text></TouchableOpacity>
        </View>
        <TouchableOpacity onPress={toggleType}><Text style={[style.filter_choice, { backgroundColor: type ? '#20cac8' : 'lightgrey' }]}>{type ? type.name : 'Age Range'}</Text></TouchableOpacity>
        <View style={[style.types_wrapper, {display: type_filter_open ? 'block' : 'none'}, { top: 152 }]}>{types.map(type=>{return <TouchableOpacity onPress={()=>selectType(type)}><Text style={style.type_selection}>{type.name}</Text></TouchableOpacity>})}</View>

        <TouchableOpacity onPress={this.resetFilters}><Text style={style.link}>Reset filters</Text></TouchableOpacity>
      </View> */}
    </View>

  }

  render() {
    let { mounted, is_open, profile_attendee, bubble_open } = this.state;

    console.log('is_open, profile_attendee ', is_open, profile_attendee )


    return (mounted ? <>
      <Modal ref={node=>this.modal_ref=node} putInParent={this.props.putInParent} />
      {/* {is_open ? <TouchableOpacity style={{zIndex: 10000000}} onPress={this.openBubble}><Profile onPress={this.openBubble} attendee={profile_attendee} /></TouchableOpacity> : null} */}
      <View style={style.main_content_cols}>
        {/* {this.drawMonthCalendar()} */}
        {this.drawCalendar()}
      </View>
    </> : <View style={{width: 60, height: 60, alignSelf: 'center', paddingVertical: 100}}>
      <Progress.CircleSnail size={60} backgroundColor="white" fill='white' color={'#e32652' } />
    </View>)
  }

}

function Calendar(props, ref) {
  if(Platform.OS === 'web') {
    let navigate = useNavigate();
    return  <Calendar_Base ref={ref} {...props} navigate={navigate} />
  } else {
    return  <Calendar_Base ref={ref} {...props} navigate={props.navigation.push}  />
  }
}

export default forwardRef(Calendar);
