import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { StyleSheet, View, TouchableOpacity, Platform, Image, Picker, Dimensions } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import { useNavigate } from 'react-router-dom';
import { Text, Input, Button } from '../../components';
import UserController from '../../controllers/userController';
import PaymentController from '../../controllers/paymentController';
import { style } from './style';
import { MaterialIcons } from '@expo/vector-icons';
import { FontAwesome } from '@expo/vector-icons';
import { global_style } from '../../../global_style';

class UserSubscription_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: props.user
    }

    this.subscribeDining = this.subscribeDining.bind(this);
    this.subscribeSocial = this.subscribeSocial.bind(this);
    this.subscriptionStart = this.subscriptionStart.bind(this);
    this.goToSubscription = this.goToSubscription.bind(this);
    this.confirmToFree = this.confirmToFree.bind(this);  
    
    this.monthlySubscribe = this.monthlySubscribe.bind(this);

    this.toggleCancel = this.toggleCancel.bind(this);
    this.getCancelReasonLeft = this.getCancelReasonLeft.bind(this);
    this.toggleCancelConfirm = this.toggleCancelConfirm.bind(this);
    this.toggleResume = this.toggleResume.bind(this);
    this.toggleSwitch = this.toggleSwitch.bind(this);

    this.updateInput = this.updateInput.bind(this);
  }

  async componentDidMount() {
    let user = await UserController.getUser();
    user = user && user.user ? user.user : null;

    let { subscription, is_member } = await UserController.getUserSubscriptionLive(user);

    this.setState({
      subscription,
      mounted: true
    })
  }

  getCancelReasonLeft() {
    let { cancel_reason } = this.state;

    let left_num = 50;
    if(cancel_reason) {
      left_num -= cancel_reason.length;
    }

    if(left_num > 0) {
      return `Include ${left_num} characters.`
    } else {
      return null
    }
  }

  updateInput(value, name) {

    this.setState({
      [name]: value
    });
  }

  toggleSwitch() {
    this.setState({
      is_switch_edit: !this.state.is_switch_edit
    })
  }

  async goToSubscription() {
    this.setState({starting: true}, async () => {
      let response = await PaymentController.createPortalSession({});

      if(response.success && response.url) {
        window.open(response.url)
      }

      setTimeout(()=>{
        this.setState({starting: false})
      }, 2000)
    })
  }

  async confirmToFree() {
    let { cancel_reason } = this.state;

    await PaymentController.downgradeToFree({cancel_reason});

    await localStorage.removeItem('subscription');

    window.location.reload();
  }

  async subscriptionUpdate(num) {
    let starting = num === 1 ? 'basic_loading' : 'monthly_loading';
    this.setState({[starting]: true}, async () => {
      let response = await PaymentController.updateSubscription({num});

      window.location.reload();
    });
  }

  async resumeConfirm() {
    await PaymentController.resumeSubscription();
    window.location.reload();
  }

  subscribeDining(num) {
    this.subscriptionStart(num);
  }

  subscribeSocial() {
    this.subscriptionStart(1);
  }

  subscriptionStart(num) {
    let starting = num === 1 ? 'basic_loading' : 'monthly_loading';

    this.setState({[starting]: true}, async () => {
      let cancel_url = 'dashboard/subscription'
      let response = await PaymentController.createCheckoutSession({num, cancel_url});

      if(response && response.success) {
        window.location.replace(response.url);
      }
    });
  }

  monthlySubscribe() {
    this.setState({monthly_subscribe_loading: true}, async () => {
      let cancel_url = 'dashboard/subscription'
      let response = await PaymentController.createCheckoutSession({num: 1, cancel_url});

      if(response && response.success) {
        window.location.replace(response.url);
      }
    });
  }


  toggleCancel() {
    this.setState({
      is_cancel_edit: !this.state.is_cancel_edit,
      is_cancel_confirm: false
    })
  }

  toggleCancelConfirm() {
    this.setState({
      is_cancel_confirm: !this.state.is_cancel_confirm
    })
  }

  toggleResume() {
    this.setState({
      is_resume_edit: !this.state.is_resume_edit,
    })
  }

  render() {

    let { monthly_subscribe_loading, basic_loading, monthly_loading, is_cancel_edit, is_cancel_confirm, cancel_reason, is_resume_edit, is_switch_edit, user, subscription, mounted } = this.state;

    let cancel_at, status, 
    is_cancelled, sub_type;

    let left_text = this.getCancelReasonLeft();
    let onCancelConfirm;

    let cancel_button_style = [style.cancel_subscription_button];

    /* if(left_text) {
      cancel_button_style.push(style.gray_out_background)
    } else { */
      onCancelConfirm = this.confirmToFree;
    //}

    let host_account = user ? user.host_account : null;

    if(subscription) {
      sub_type = subscription.type;
      cancel_at = subscription.cancel_at;
      status = subscription.status;
      is_cancelled = status === 'canceled';
    }

    console.log('subscription', subscription)

    let active_until = subscription ? (subscription.status === 'trialing' ? ` right away.` : ` on ${subscription.current_period_end}`) : null;

    let cancel_panel = subscription ? <View >
          {is_cancel_confirm ? <View style={style.cancel_panel}>
                <TouchableOpacity onPress={this.toggleCancel} style={style.close_panel_button}>
                  <Text style={style.close_panel_button_text}>x</Text>
                </TouchableOpacity>
                <Text style={style.subscription_label}>Please let us know why you're canceling so that we can improve.</Text>
                <Input type="text" style={style.cancel_reason_text} value={cancel_reason} onChangeText={event => this.updateInput(event, 'cancel_reason')} multiline={true} placeholder={"Your reason for canceling"} />
                {/* <View><Text style={style.red_text}>{left_text}</Text></View> */}
                  <TouchableOpacity style={cancel_button_style} onPress={onCancelConfirm}>
                  <Text style={style.cancel_subscription_button_text}>Confirm Cancel Subscription</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={this.toggleCancel} style={style.keep_subscription_button}>
                  <Text style={style.keep_subscription_button_text}>I'll keep it for now.</Text>
                </TouchableOpacity>
            </View> :
            <View style={style.cancel_panel}>
              <TouchableOpacity onPress={this.toggleCancel} style={style.close_panel_button}>
                <Text style={style.close_panel_button_text}>x</Text>
              </TouchableOpacity>
              <Text style={style.cancel_confirm_title}>Are you sure you'd like to cancel your subscription to Dining Club?</Text>
              <Text style={style.woul}>Your subscription will cancel <Text style={style.active_label}>{active_until}</Text></Text>
              <TouchableOpacity style={style.cancel_subscription_button} onPress={this.toggleCancelConfirm}>
                <Text style={style.cancel_subscription_button_text}>Continue to Cancel Subscription</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={this.toggleCancel} style={style.keep_subscription_button}>
                <Text style={style.keep_subscription_button_text}>I'll keep it for now.</Text>
              </TouchableOpacity>
            </View>}
        </View> : null

    let resume_panel = subscription ? <View>
          <View style={style.cancel_panel}>
              <TouchableOpacity onPress={this.toggleResume} style={style.close_panel_button}>
                <Text style={style.close_panel_button_text}>x</Text>
              </TouchableOpacity>
              <Text style={style.resume_label}>Would you like to resume your subscription?</Text>
              <TouchableOpacity style={style.resume_subscription_button} onPress={this.resumeConfirm}>
                <Text style={style.resume_button_text}>Resume Auto-Renew</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={this.toggleResume} style={style.keep_subscription_button}>
                <Text style={style.keep_subscription_button_text}>Cancel</Text>
              </TouchableOpacity>
          </View>
        </View> : null;

    let switch_panel = subscription ? <View>
        <View style={style.cancel_panel}>
              <TouchableOpacity onPress={this.toggleSwitch} style={style.close_panel_button}>
                <Text style={style.close_panel_button_text}>x</Text>
              </TouchableOpacity>

              <Text style={style.resume_label}>Would you like to switch your subscription to {sub_type === 0 ? 'Yearly - $300 / year' : 'Monthly - $30 / month'}</Text>

              <Text style={style.active_wrapper}>Your current subscription period will end on <Text style={style.active_label}>{subscription.current_period_end} after which your subscription period will be updated to {sub_type === 0 ? 'yearly' : 'monthly'}</Text></Text>


              {sub_type === 0 ? <TouchableOpacity onPress={()=>this.subscriptionUpdate(1)}
                                  style={style.resume_subscription_button}>
                  <Text style={style.resume_button_text}>Switch to Yearly - $300 / year</Text>
                </TouchableOpacity> : <TouchableOpacity onPress={()=>this.subscriptionUpdate(0)}
                                  style={style.resume_subscription_button}>
                  <Text style={style.resume_button_text}>Switch to Monthly - $30 / month</Text>
                </TouchableOpacity>}

              <TouchableOpacity onPress={this.toggleSwitch} style={style.keep_subscription_button}>
                <Text style={style.keep_subscription_button_text}>Cancel</Text>
              </TouchableOpacity>


          </View>
        </View> : null;

    

    let active_subscription = subscription && (subscription.status === 'active' || subscription.status === 'trialing');

    let has_any_subscription = active_subscription && !is_cancelled;

    let next_auto_renew = subscription ? (subscription.status === 'trialing' ?
    <>
      <Text style={style.auto_renew_label}>Subscription start:</Text>
      <Text style={style.auto_renew_date}>{subscription.billing_cycle_anchor}</Text>
    </> :
    <>
      <Text style={style.auto_renew_label}>Next auto-renew:</Text>
      <Text style={style.auto_renew_date}>{subscription.current_period_end}</Text>
    </>) : null;

    let subscription_none = <View style={[style.dashboard_panel, style.panel_white]}>
        <Text style={style.section_title}>Your Subscription</Text>
        <View style={style.home_breaker}></View>
          <View style={style.subscribe_title_wrapper}><Text style={style.subscription_label}>Subscription:</Text><Text style={style.subscription_type}>None</Text></View>

          <View style={style.subscribe_wrapper}>

              <Button loading={false} onPress={()=>this.subscribeDining(1)}
                              style={[style.subscription_box, style.subscription_yearly]} title={
                <View style={style.a_column}>
                  <Text style={style.club_button_text}>Subscribe Yearly</Text>
                  <Text style={style.club_button_text}>$25/month</Text>
                </View>} />

              <Button loading={monthly_loading} onPress={()=>this.subscribeDining(0)}
                              style={[style.subscription_box, style.subscription_monthly]} title={
                <View style={style.a_column}>
                  <Text style={style.club_button_text}>Subscribe Monthly</Text>
                  <Text style={style.club_button_text}>$30/month</Text>
                </View>} />

          </View>
          
      </View>

    let host_account_panel = <View style={[style.dashboard_panel, style.panel_white]}>
        <Text style={style.section_title}>Your Subscription</Text>
        <View style={style.home_breaker}></View>
        
        {!is_cancel_edit && !is_resume_edit && !is_switch_edit ?
        <View>
          <View style={style.subscribe_title_wrapper}><Text style={style.subscription_label}>Subscription:</Text><Text style={style.subscription_type}>Monthly - FREE Host Account</Text></View>
          <View style={style.auto_renew_wrapper}>
            <Text style={style.auto_renew_cancels_label}>Renews on:</Text>
            <Text style={style.auto_renew_date}>{host_account}</Text>
          </View>
          <View style={style.home_breaker}></View>
          <View style={style.line_break}></View>
        </View> : null
        }
    </View>

    //let button_basic_action = sub_type !== 1 ? (has_any_subscription ? ()=>{this.subscriptionUpdate(1)} : ()=>this.subscribeDining(1)) : this.toggleSwitch;

    let button_basic_action = sub_type !== 1 ? (has_any_subscription ? ()=>{this.subscriptionUpdate(1)} : ()=>this.subscribeDining(1)) : this.toggleSwitch;


    let button_premium_action = sub_type !== 2 ? (has_any_subscription ? ()=>{this.subscriptionUpdate(2)} : ()=>this.subscribeDining(2)) : this.toggleSwitch;

    let width = Dimensions.get('window').width;
    let height = Dimensions.get('window').height;

    let is_mobile = width < 500;

    /* let pricing_columns = <View style={style.plans_wrapper}>
    <View style={[style.one_plan, !has_any_subscription ? style.selected_plan : {}]}>
      <View style={style.plan_cap}>
        <Text style={{fontFamily: 'Ysabeau', fontSize: 26, color: 'white'}}>Free</Text>
        <Text style={{fontFamily: 'Ysabeau', fontSize: 20, color: 'white'}}><Text style={{color: '#e32652',fontFamily: 'Ysabeau', fontSize: 30 }}>$0</Text> / month</Text>
      </View>
      <View style={{backgroundColor: 'white'}}>
        <View>
          <Text style={{fontFamily: 'Comfortaa', fontSize: 14, padding: 20, borderBottom: 'solid 2px #e32652', lineHeight: 20}}>Join without a subscription and simply pay a small attendance fee per event.</Text>
        </View>
        <View>
            <View style={{ padding: 20, borderBottom: 'solid 2px #e32652', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center'}}>
              <View style={{flex: 1}}>
                <FontAwesome name="coffee" size={30} color="#e32652" />
              </View>
              <View style={{flex: 4}}>
                <Text style={{textAlign: 'center', fontFamily: 'Comfortaa', fontSize: 14,}}><Text style={{fontFamily: 'Comfortaa', fontSize: 14, color: '#e32652'}}>$5</Text> / Coffee Invites</Text>
              </View>
          </View>
        </View>
        <View>
            <View style={{ padding: 20, borderBottom: 'solid 2px #e32652', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center'}}>
              <View style={{flex: 1}}>
              <MaterialIcons name="dinner-dining" size={33} color="#e32652" />
              </View>
              <View style={{flex: 4}}>
                <Text style={{textAlign: 'center', fontFamily: 'Comfortaa', fontSize: 14,}}><Text style={{fontFamily: 'Comfortaa', fontSize: 14, color: '#e32652'}}>$10</Text> / Dinner, Brunch RSVP</Text>
              </View>
          </View>
        </View>
        <View>
            <Text style={{fontFamily: 'Comfortaa', fontSize: 14, padding: 20, borderBottom: 'solid 2px #e32652'}}>Join any number of our free events.</Text>
        </View>
        <View style={{height: 80}}>
          {has_any_subscription ?
        <TouchableOpacity onPress={this.toggleCancel} style={[style.join_button, style.join_button_free]}>
          <Text style={style.join_button_text}>Continue for Free</Text>
        </TouchableOpacity> : null}
        </View>
      </View>
    </View>

    <View style={[style.one_plan, has_any_subscription && sub_type === 1 ? style.selected_plan : {}]}>
      <View style={[style.plan_cap]}>
        <Text style={{fontFamily: 'Ysabeau', fontSize: 26, color: 'white'}}>Basic</Text>
        <Text style={{fontFamily: 'Ysabeau', fontSize: 20, color: 'white'}}><Text style={{color: '#e32652',fontFamily: 'Ysabeau', fontSize: 30 }}>$9.99</Text> / month</Text>
      </View>
      <View style={{backgroundColor: 'white'}}>
      <View>
          <Text style={{fontFamily: 'Comfortaa', fontSize: 14, padding: 20, borderBottom: 'solid 2px #e32652', lineHeight: 20}}>Join and attend an unlimited number of coffee invites with a discount on dinners & brunches.</Text>
        </View>
      <View>
          <View style={{ padding: 20, borderBottom: 'solid 2px #e32652', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center'}}>
              <View style={{flex: 1}}>
                <FontAwesome name="coffee" size={30} color="#e32652" />
              </View>
              <View style={{flex: 4}}>
                <Text style={{textAlign: 'center', fontFamily: 'Comfortaa', fontSize: 14,}}>Unlimited Coffee Invites</Text>
              </View>
          </View>
        </View>
        <View>
            <View style={{ padding: 20, borderBottom: 'solid 2px #e32652', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center'}}>
              <View style={{flex: 1}}>
              <MaterialIcons name="dinner-dining" size={33} color="#e32652" />
              </View>
              <View style={{flex: 4}}>
                <Text style={{textAlign: 'center', fontFamily: 'Comfortaa', fontSize: 14,}}><Text style={{fontFamily: 'Comfortaa', fontSize: 14, color: '#e32652'}}>$8</Text> / Dinner, Brunch RSVP</Text>
              </View>
          </View>
        </View>
        <View>
            <Text style={{fontFamily: 'Comfortaa', fontSize: 14, padding: 20, borderBottom: 'solid 2px #e32652'}}>Message members you've met and get notified when they join another event.</Text>
        </View>
        {sub_type !== 1 ?
        <View>
          <Button loading={basic_loading} onPress={button_basic_action}
                    style={[style.join_button, style.join_button_monthly]} title={
              <View style={{width: 130}}><Text style={style.join_button_text}>Switch to Basic for $9.99/mo</Text></View>} />
        </View> : <View>
          {cancel_at || is_cancelled ?
            <View style={style.auto_renew_wrapper}>
              <Text style={style.auto_renew_cancels_label}>Cancels on:</Text>
              <Text style={style.auto_renew_date}>{subscription.cancel_at}</Text>
              <TouchableOpacity onPress={this.toggleResume}
                                style={style.club_button}>
                <Text style={style.payment_link_auto_renew}>Resume auto-renew</Text>
              </TouchableOpacity>
            </View>
            :
            <View style={style.auto_renew_wrapper}>
              <>{next_auto_renew}</>
              <TouchableOpacity onPress={this.toggleCancel}
                                style={style.club_button}>
                <Text style={style.payment_link_auto_renew}>Pause auto-renew</Text>
              </TouchableOpacity>
            </View>}
          </View>}
      </View>
    </View>

    <View style={[style.one_plan, has_any_subscription && sub_type === 2 ? style.selected_plan : {}]}>
      <View style={{flexDirection: 'column', backgroundColor: '#222326', borderTopLeftRadius: 10, borderTopRightRadius: 10, alignContent: 'center', alignItems: 'center', padding: 20}}>
        <Text style={{fontFamily: 'Ysabeau', fontSize: 26, color: 'white'}}>Full</Text>
        <Text style={{fontFamily: 'Ysabeau', fontSize: 20, color: 'white'}}><Text style={{color: '#e32652',fontFamily: 'Ysabeau', fontSize: 30 }}>$24.99</Text>  / month</Text>
      </View>
      <View style={{backgroundColor: 'white'}}>
        <View>
          <Text style={{fontFamily: 'Comfortaa', fontSize: 14, padding: 20, borderBottom: 'solid 2px #e32652', lineHeight: 20}}>Join to attend an unlimited number of coffee invites, hosted dinners and brunches.</Text>
        </View>
        <View>
          <View style={{ padding: 20, borderBottom: 'solid 2px #e32652', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center'}}>
              <View style={{flex: 1}}>
                <FontAwesome name="coffee" size={30} color="#e32652" />
              </View>
              <View style={{flex: 4}}>
                <Text style={{textAlign: 'center', fontFamily: 'Comfortaa', fontSize: 14,}}>Unlimited Coffee Invites</Text>
              </View>
          </View>
        </View>
        <View>
            <View style={{ padding: 20, borderBottom: 'solid 2px #e32652', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center'}}>
              <View style={{flex: 1}}>
              <MaterialIcons name="dinner-dining" size={33} color="#e32652" />
              </View>
              <View style={{flex: 4}}>
                <Text style={{textAlign: 'center', fontFamily: 'Comfortaa', fontSize: 14,}}>Unlimited Dinner, Brunch RSVPs</Text>
              </View>
          </View>
        </View>
        <View>
          <Text style={{fontFamily: 'Comfortaa', fontSize: 14, padding: 20, borderBottom: 'solid 2px #e32652'}}>Discounts on other, one-off events.</Text>
        </View>
        <View>
        {sub_type !== 2 ?
        <View>
          <Button loading={monthly_loading} onPress={button_premium_action}
                    style={[style.join_button, style.join_button_monthly]} title={
              <View style={{width: 130}}><Text style={style.join_button_text}>Switch to Full for 24.99/mo</Text></View>} />
        </View> : <View>
          {cancel_at || is_cancelled ?
            <View style={style.auto_renew_wrapper}>
              <Text style={style.auto_renew_cancels_label}>Cancels on:</Text>
              <Text style={style.auto_renew_date}>{subscription.cancel_at}</Text>
              <TouchableOpacity onPress={this.toggleResume}
                                style={style.club_button}>
                <Text style={style.payment_link_auto_renew}>Resume auto-renew</Text>
              </TouchableOpacity>
            </View>
            :
            <View style={style.auto_renew_wrapper}>
              <>{next_auto_renew}</>
              <TouchableOpacity onPress={this.toggleCancel}
                                style={style.club_button}>
                <Text style={style.payment_link_auto_renew}>Pause auto-renew</Text>
              </TouchableOpacity>
            </View>}
          </View>}
        </View>
      </View>
    </View>

    </View> */

let pricing_columns = <View style={style.plans_wrapper}>
 <View style={{flexDirection: 'row', margin: 'auto', marginBottom: 100, marginTop: 20, width: is_mobile ? '100%' : 'unset', overflow: is_mobile ? 'scroll' : 'unset', paddingLeft: is_mobile ? 20 : 'unset'}}>
           <View style={{flexDirection: 'column', width: is_mobile ? 180 : 280}}>
            <View style={{flexDirection: 'row', height: 101}}></View>
            <View style={{flexDirection: 'row', height: is_mobile ? 106 : 85, borderWidth: 1, borderRightWidth: 0, borderLeftWidth: 0, borderColor: '#a19f9f'}}>
               <Text style={style.membership_tab}>Dinner/Brunch Around Your Schedule</Text>
            </View>
            <View style={{flexDirection: 'row', height: is_mobile ? 105 : 84, borderColor: '#a19f9f', borderBottomWidth: 1, alignItems: 'center'}}>
              <Text style={style.membership_tab}>Free Subscription Eligibility</Text>
            </View>
            <View style={{flexDirection: 'row', borderColor: '#a19f9f', height: is_mobile ? 105 : 84, borderBottomWidth: 1, alignItems: 'center'}}>
              <Text style={style.membership_tab}>Subscription</Text>
            </View>
            <View style={{flexDirection: 'row', borderColor: '#a19f9f', height: is_mobile ? 105 : 84, borderBottomWidth: 1, alignItems: 'center'}}>
              <Text style={style.membership_tab}>Dinner/Brunch Attendance Fee</Text>
            </View>
            <View style={{flexDirection: 'row', borderColor: '#a19f9f', height: is_mobile ? 105 : 84, borderBottomWidth: 1, alignItems: 'center'}}>
              <Text style={style.membership_tab}>Bigger Dining Event Fee</Text>
            </View>
           </View>
           <View style={[{flexDirection: 'column', borderWidth: 1, borderTopWidth: 2, borderLeftWidth: 2, width: 340, borderColor: '#a19f9f'}, sub_type === 1 ? style.selected_plan : {}]}>
              <View style={{flexDirection: 'row', height: is_mobile ? 105 : 84, height: 100, borderColor: '#a19f9f', borderBottomWidth: 1, alignItems: 'center', justifyContent: 'center'}}><Text style={{fontFamily: 'Ysabeau', fontSize: 40}}>Member</Text></View>
              <View style={{flexDirection: 'row', height: is_mobile ? 105 : 84, borderColor: '#a19f9f', borderBottomWidth: 1, alignItems: 'center', justifyContent: 'center'}}>
                <FontAwesome name="check" size={30} />
              </View>
              <View style={{flexDirection: 'row', height: is_mobile ? 105 : 84, borderColor: '#a19f9f', borderBottomWidth: 1, alignItems: 'center', justifyContent: 'center'}}>
                <FontAwesome name="check" size={30} />
              </View>
              <View style={{flexDirection: 'row', height: is_mobile ? 105 : 84, borderColor: '#a19f9f', borderBottomWidth: 1, alignItems: 'center', justifyContent: 'center'}}>
                <Text style={{fontFamily: 'Comfortaa', fontSize: 20 }}>$30 / mo</Text>
              </View>
              <View style={{flexDirection: 'column', height: is_mobile ? 105 : 84, borderColor: '#a19f9f', borderBottomWidth: 1, alignItems: 'center', justifyContent: 'center'}}>
                <Text style={{fontFamily: 'Comfortaa', fontSize: 20 }}>$0 / dinner or brunch</Text>
                <Text style={{fontFamily: 'Ysabeau', fontSize: 16, marginTop: 0 }}>(unlimited times per month)</Text>
              </View>
              <View style={{flexDirection: 'row', height: is_mobile ? 105 : 84, borderColor: '#a19f9f', borderBottomWidth: 1, alignItems: 'center', justifyContent: 'center'}}>
                <Text style={{fontFamily: 'Comfortaa', fontSize: 20}}>$5 / event</Text>
              </View>
              <View style={{flexDirection: 'row', height: is_mobile ? 105 : 84, borderColor: '#a19f9f', alignItems: 'center', justifyContent: 'center'}}>
              {sub_type !== 1 ?
              <View>
                <Button loading={monthly_subscribe_loading} onPress={this.monthlySubscribe}
                          style={[style.join_button, style.join_button_monthly]} title={
                    <View style={{width: 130}}><Text style={style.join_button_text}>Subscribe</Text></View>} />
              </View> : <View>
                {cancel_at || is_cancelled ?
                  <View style={style.auto_renew_wrapper}>
                    <Text style={style.auto_renew_cancels_label}>Cancels on:</Text>
                    <Text style={style.auto_renew_date}>{subscription.cancel_at}</Text>
                    <TouchableOpacity onPress={this.toggleResume}
                                      style={style.club_button}>
                      <Text style={style.payment_link_auto_renew}>Resume auto-renew</Text>
                    </TouchableOpacity>
                  </View>
                  :
                  <View style={style.auto_renew_wrapper}>
                    <>{next_auto_renew}</>
                    <TouchableOpacity onPress={this.toggleCancel}
                                      style={style.club_button}>
                      <Text style={style.payment_link_auto_renew}>Pause auto-renew</Text>
                    </TouchableOpacity>
                  </View>}
                </View>}
              </View>
           </View>
           <View style={[{flexDirection: 'column', borderColor: '#a19f9f', borderWidth: 1, width: 340, borderTopWidth: 2, borderRightWidth: 2}, sub_type !== 1 ? style.selected_plan : {}]}>
              <View style={{flexDirection: 'row', height: is_mobile ? 105 : 84, borderColor: '#a19f9f', height: 100, borderBottomWidth: 1, alignItems: 'center', justifyContent: 'center'}}><Text style={{fontFamily: 'Ysabeau', fontSize: 40}}>Visitor</Text></View>
              <View style={{flexDirection: 'row', height: is_mobile ? 105 : 84, borderColor: '#a19f9f', borderBottomWidth: 1, alignItems: 'center', justifyContent: 'center'}}>
                <FontAwesome name="times" size={30} color="#e32652" />
              </View>
              <View style={{flexDirection: 'row', height: is_mobile ? 105 : 84, borderColor: '#a19f9f', borderBottomWidth: 1, alignItems: 'center', justifyContent: 'center'}}>
                <FontAwesome name="times" size={30} color="#e32652" />
              </View>
              <View style={{flexDirection: 'row', height: is_mobile ? 105 : 84, borderColor: '#a19f9f', borderBottomWidth: 1, alignItems: 'center', justifyContent: 'center'}}>
                <Text style={{fontFamily: 'Comfortaa', fontSize: 20 }}>$0 / mo</Text>
              </View>
              <View style={{flexDirection: 'row', height: is_mobile ? 105 : 84, borderColor: '#a19f9f', borderBottomWidth: 1, alignItems: 'center', justifyContent: 'center'}}>
                <Text style={{fontFamily: 'Comfortaa', fontSize: 20 }}>$19 / dinner or brunch</Text>
              </View>
              <View style={{flexDirection: 'row', height: is_mobile ? 105 : 84, borderColor: '#a19f9f', borderBottomWidth: 1, alignItems: 'center', justifyContent: 'center'}}>
                <Text style={{fontFamily: 'Comfortaa', fontSize: 20}}>$25 / event</Text>
              </View>
              <View style={{flexDirection: 'row', height: is_mobile ? 105 : 84, borderColor: '#a19f9f', alignItems: 'center', justifyContent: 'center'}}>
              {has_any_subscription ?
        <TouchableOpacity onPress={this.toggleCancel} style={[style.join_button, style.join_button_free]}>
          <Text style={style.join_button_text}>Switch for Free</Text>
        </TouchableOpacity> : null}
              </View>
           </View>
          </View>

</View>

    let subscription_element = !host_account ? pricing_columns : host_account_panel

    return mounted ? <View style={{backgroundColor: 'white'}}>
      <Text style={style.section_title}>Your Subscription</Text>
        <View style={style.home_breaker}></View>
        {is_resume_edit ? resume_panel : null}
        {is_cancel_edit ? cancel_panel : null}
        
        {!is_cancel_edit && !is_resume_edit ? subscription_element : null}
        </View> : <View style={{width: 60, height: 60, alignSelf: 'center', paddingVertical: 100}}>
    <Progress.CircleSnail size={60} backgroundColor="white" fill='white' color={'#e32652' } />
    </View>;
  }

}

{/* <View style={[style.dashboard_panel, style.panel_white]}>
        <Text style={style.section_title}>Your Subscription</Text>
        <View style={style.home_breaker}></View>
        {is_cancel_edit ? cancel_panel : null}

        {is_resume_edit ? resume_panel : null}

        {is_switch_edit ? switch_panel : null}
         
        {!is_cancel_edit && !is_resume_edit && !is_switch_edit ?
        <View>
          <View style={style.subscribe_title_wrapper}><Text style={style.subscription_label}>Subscription:</Text>{sub_type === 0 ? <Text style={style.subscription_type}>Monthly - $30 / month</Text> :<Text style={style.subscription_type}>Yearly - $300 / year</Text> }</View>
          {cancel_at || is_cancelled ?
          <View style={style.auto_renew_wrapper}>
            <Text style={style.auto_renew_cancels_label}>Cancels on:</Text>
            <Text style={style.auto_renew_date}>{subscription.cancel_at}</Text>
            <TouchableOpacity onPress={this.toggleResume}
                              style={style.club_button}>
              <Text style={style.payment_link_auto_renew}>Resume auto-renew</Text>
            </TouchableOpacity>
          </View>
          :
          <View style={style.auto_renew_wrapper}>
            <>{next_auto_renew}</>
            <TouchableOpacity onPress={this.toggleCancel}
                              style={style.club_button}>
              <Text style={style.payment_link_auto_renew}>Pause auto-renew</Text>
            </TouchableOpacity>
          </View>}
          <View style={style.home_breaker}></View>
          <View style={style.line_break}></View>
          <View style={style.payment_links}>
            {is_cancelled ? null : (
              sub_type === 0 ? 
                <TouchableOpacity onPress={this.toggleSwitch}
                                    style={style.subscription_box_small}>
                    <Text style={style.subscription_box_small_text}>Switch to Yearly - $300 / year</Text>
                  </TouchableOpacity> : <TouchableOpacity onPress={this.toggleSwitch}
                                    style={style.subscription_box_small}>
                    <Text style={style.subscription_box_small_text}>Switch to Monthly - $30 / month</Text>
                  </TouchableOpacity>)}
            <TouchableOpacity onPress={this.goToSubscription}>
              <Text style={style.payment_link}>Manage Payment</Text>
            </TouchableOpacity>
            
          </View>
        </View> : null
        }
    </View> */}

function UserSubscription(props) {
  if(Platform.OS === 'web') {
    let navigate = useNavigate();
    return  <UserSubscription_Base {...props} navigate={navigate} />
  } else {
    return  <UserSubscription_Base {...props} navigate={props.navigation.push}  />
  }
}

export default UserSubscription
