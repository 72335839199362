import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { StyleSheet, View, TouchableOpacity, Platform, Image, ScrollView, Dimensions } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import { useNavigate, useParams } from 'react-router-dom';
import { Text, Button, Input } from '../../components';
import { Chat, Invites, MeetUpEvent, Modal } from '../../containers';
import UserController from '../../controllers/userController';
import { style } from './style';
import { Video } from 'expo-av';
import Icon from 'react-native-vector-icons/FontAwesome';
import { global_style } from '../../../global_style'
import AuthController from '../../controllers/authController';
import EventController from '../../controllers/eventController';
import { config } from '../../../config';
import Utilities from '../../utilities';
let width = Dimensions.get('window').width;

class YourEvents_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user_page: 0,
      mounted: false,
      user: props.user,
      to_user: props.to_user,
      page: 0,
      loading: true,
      user_events_loading: true,
      places: config.places
    }

    this.drawEvents = this.drawEvents.bind(this);
    this.drawMediaElement = this.drawMediaElement.bind(this);
    this.openProfileBubble = this.openProfileBubble.bind(this);
    this.drawParticipant = this.drawParticipant.bind(this);
    this.drawParticipants = this.drawParticipants.bind(this);
    this.drawPager = this.drawPager.bind(this);
    this.drawUserEvent = this.drawUserEvent.bind(this);
    this.openCreate = this.openCreate.bind(this);
    this.drawUserEventParticipants = this.drawUserEventParticipants.bind(this);

    this.getUserEvents = this.getUserEvents.bind(this);
    this.drawUserPager = this.drawUserPager.bind(this);

    this.openInParent = this.openInParent.bind(this);
  }

  async componentDidMount() {
    let { page } = this.state;
    let dinners = await UserController.getDinners({page});
    dinners = dinners.dinners;
    dinners.sort((dinner1, dinner2)=>{return new Date(dinner1.dinner_date) < new Date(dinner2.dinner_date) ? 1 : -1})

    let hosts = await AuthController.getHosts();

    hosts = hosts.map(host=>host.user_id)

    let my_user_id = await localStorage.getItem('user_id');


    let internal = await AuthController.getInternal();
    let allowed_members = internal.coffee_beta_members;

    let is_allowed = allowed_members.indexOf(my_user_id) !== -1;

    let { user } = this.state;
    let subscription = await UserController.getUserSubscriptionStatic();

    let host_account = user ? user.host_account : null;

    let is_member = subscription && subscription.status ? subscription.status === 'trialing' || subscription.status === 'active' : false;

    is_member = is_member || !!host_account;

    let messaging = user.messaging;

    if(typeof messaging === 'string') {
      messaging = JSON.parse(messaging);
    }

    messaging = messaging ? messaging : {public: false, enabled_users: []}

    let keys = Object.keys(messaging);

    if(keys.indexOf('public') === -1) {
      messaging = {
        ...messaging,
        public: false
      }
    }

    if(keys.indexOf('enabled_users') === -1) {
      messaging = {
        ...messaging,
        enabled_users: []
      }
    }

    let public_messaging = messaging.public;
    let enabled_users = messaging.enabled_users;

    this.getUserEvents();

    this.setState({
      is_allowed,
      hosts,
      my_user_id,
      public_messaging,
      enabled_users,
      is_member,
      dinners,
      mounted: true,
      user_events_loading: true
    }, async ()=>{
      let event_rsvps = {};

      for(var i = 0; i < dinners.length; i++) {
        let event_id = dinners[i].event_id;
        let {rsvps, tentatives} = await UserController.getDateRsvpsForEvent({event_id})

        event_rsvps = {
          ...event_rsvps,
          [event_id]: rsvps
        }

        this.setState({event_rsvps, loading: false})
      }

      let { is_member } = await UserController.getUserSubscriptionLive(user);

      this.setState({
        is_member
      });
    })
  }

  async getUserEvents() {
    let { user_page, user_events } = this.state;
    let is_mobile = width < 500;
    let new_user_events = await EventController.getUserEvents({page: user_page, is_mobile});

    console.log('new_user_events', new_user_events)

    new_user_events = new_user_events.map(event=>{
      return {
        ...event.event_data,
        event_id: event.id
      }
    });

    user_events = new_user_events && new_user_events.length ? new_user_events : user_events;
    let next_disabled = !new_user_events || !new_user_events.length

    user_events = user_events ? user_events : [];

    let event, event_id, user_event_rsvps = {};
    for(var i = 0; i < user_events.length; i++) {

      event = user_events[i];
      event_id = event.event_id

      let user_ids = event.rsvps;

      let rsvps = await AuthController.getUsers({user_ids}) 

      console.log('rsvpsrsvps', rsvps)

      user_event_rsvps = {
        ...user_event_rsvps,
        [event_id]: rsvps
      }
    }

    this.setState({
      user_events_loading: false, 
      user_event_rsvps, 
      user_events, 
      user_page: next_disabled && user_page !== 0 ? user_page - 1 : user_page,
      next_disabled
    })
  }

  openInParent(content, state_variable, width, height, put_in_header) {
    console.log('drawing in parent again', state_variable, this.state[state_variable]);

    let modal_content = 
      <View style={{flex: 1, zIndex: 99999999, position: 'fixed', width: '100vw', height: '100vh', overflow: 'hidden'}} >
      <>
        <TouchableOpacity onPress={()=>{this.setState({modal_content: null})}} style={global_style.modal_overlay}></TouchableOpacity>
        <View style={{position: 'fixed', top: 0, width: '100vw', height: '100vh', zIndex: 2000000}}>
          <View style={{position: 'relative'}}>
            <View style={[global_style.modal_content_mobile, { display: 'flex', width, height, left: `calc(50% - ${width / 2}px - 112px)`, position: 'absolute'}]}>
              {put_in_header}
              <TouchableOpacity style={style.modal_x_wrapper} onPress={()=>{this.setState({modal_content: null})}} ><Text style={[style.modal_close_x]}>x</Text></TouchableOpacity>
                <ScrollView style={{flex: 1, height: 'calc(80vh - 20px)', marginTop: 40 }}>
                  <View style={[global_style.padding_20, {paddingTop: 0}]}>{content}</View>
              </ScrollView>
            </View>
          </View>
        </View>
      </>

      </View>

    this.setState({
      modal_content
    });
  }

  drawMediaElement(place, event) {
    let { restaurant_name, link, main_image, main_video } = place ? place : {};

    main_image = main_image ? main_image : event.place_holder_image

    return <Image resizeMode="cover" style={style.image_main} imageStyle={{width: '100%'}} source={{uri: main_image}} />
    /* main_video ? 
      <View style={{position: 'relative'}}>
        <View style={{width: '40%', position: 'relative', marginHorizontal: 'auto'}}>
          <Image style={{width: '40%', height: '200px', position: 'absolute'}} source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/HappyHourThumb.png'}} />
        </View>
        <Video
          posterSource={{uri: 'https://diningsocial-assets.s3.amazonaws.com/HappyHourThumb.png'}}
          source={{ uri: main_video }}
          style={{width: '40%', position: 'relative', marginHorizontal: 'auto'}}
          videoStyle={{position: 'relative'}}
          useNativeControls={true}
          shouldPlay={false}
          resizeMode="contain"
        /></View> : */
        
  }

  openProfileBubble(profile_attendee, event) {
    this.props.putInParent(!this.state.is_profile_open ? 
      <View style={{zIndex: 10000000, position: 'fixed', width: '100vw', height: '100vh'}}>
        <Chat hosts={this.state.hosts} event={event} onPress={this.openProfileBubble} user={this.state.user}  attendee={profile_attendee} />      
      </View> : null);

    this.setState({
      profile_attendee,
      is_profile_open: !this.state.is_profile_open
    });
  }

  drawParticipant(attendee, enable_chat_global, event) {
    let photo_url;
    let { user, public_messaging, enabled_users, my_user_id, hosts } = this.state;

    console.log('attendee', attendee)

    let i_allowed_messaging = public_messaging || enabled_users.indexOf(attendee.user_id) !== -1;
    let they_allowed_messaging;

    let is_host;
    if(attendee) {
      let profile = attendee.profile;

      if(attendee) {
        if(attendee.messaging) {
          let attendee_messaging = attendee.messaging ? attendee.messaging : {public: false, enabled_users: []}

          let keys = Object.keys(attendee_messaging);

          if(keys.indexOf('public') === -1) {
            attendee_messaging = {
              ...attendee_messaging,
              public: false
            }
          }
      
          if(keys.indexOf('enabled_users') === -1) {
            attendee_messaging = {
              ...attendee_messaging,
              enabled_users: []
            }
          }

          they_allowed_messaging = attendee_messaging.public || attendee_messaging.enabled_users.indexOf(user.user_id) !== -1;

          is_host = hosts.indexOf(attendee.user_id) !== -1
        }
      }

      if(typeof profile === 'string') {
        profile = JSON.parse(profile);
      }

      ({photo_url, name} = profile);

      if(attendee.name) {
        name = attendee.name;
      }
    }

    let i_am_host = hosts.indexOf(my_user_id) !== -1

    let enable_chat = i_allowed_messaging && they_allowed_messaging || my_user_id === 'cc467f19-6954-4151-ba3c-c45c8563511c' || attendee.user_id === 'cc467f19-6954-4151-ba3c-c45c8563511c' || enable_chat_global || is_host || i_am_host;

    let photo_style = [style.attendee_photo];

    if(enable_chat) {
      photo_style.push(style.selected_photo)
    }

    return <View style={{marginRight: 5, marginBottom: 5, flexDirection: 'column'}}>
        <TouchableOpacity onPress={()=>this.openProfileBubble(attendee, event)} style={[style.attendee_bubble]}>
          <Image style={photo_style} source={{uri: photo_url ? photo_url : 'https://diningsocial-assets.s3.amazonaws.com/empty_profile.png'}} />
        </TouchableOpacity>     
    </View>
  }

  drawParticipants(event_id, host_id, event) {
    let { event_rsvps, my_user_id } = this.state;
    let participants = event_rsvps ? _.cloneDeep(event_rsvps[event_id]) : [];

    participants = participants ? participants : [];

    let host_index;
    let host = participants.filter((user, i)=>{
      if(user.user_id===host_id) {
        host_index = i;
      }
      return user.user_id===host_id
    })

    let host_element;
    if(host && host.length) {
      host_element = <View style={{flexDirection: 'row', marginTop: 20}}>
        <Text style={style.your_host}>Your Host</Text>
        <Text>{this.drawParticipant(host[0], true, event)}</Text>
      </View>

      participants.splice(host_index, 1)
    }

    let self_index;
    participants.filter((user, i) => {
      if(user.user_id===my_user_id) {
        self_index = i;
      }
      return user.user_id===my_user_id
    });

    if(self_index || self_index === 0) {
      participants.splice(self_index, 1);
    }

    return <View style={{flexDirection: 'column'}}>
      {host_element}
      <Text style={style.attendees_title}>Connect with Attendees</Text>
      <View style={{flexDirection: 'row', width: '100%', flexWrap: 'wrap'}}>
        {participants.map(participant=>this.drawParticipant(participant, false, event))}
      </View>
    </View>
  }

  drawUserEventParticipants(event, event_id) {
    let { user_event_rsvps, my_user_id } = this.state;
    let participants = user_event_rsvps ? _.cloneDeep(user_event_rsvps[event_id]) : [];

    participants = participants ? participants : [];


    let self_index;
    participants.filter((user, i) => {
      if(user.user_id===my_user_id) {
        self_index = i;
      }
      return user.user_id===my_user_id
    });

    if(self_index || self_index === 0) {
      participants.splice(self_index, 1);
    }

    return <View style={{flexDirection: 'column'}}>
      <Text style={style.attendees_title}>Connect with Attendees</Text>
      <View style={{flexDirection: 'row', width: '100%', flexWrap: 'wrap'}}>
        {participants.map(participant=>this.drawParticipant(participant, false, event))}
      </View>
    </View>
  }

  drawEvents(event, index) {
    console.log('eventUno', event)
    let { place_data, dinner_date, event_id, event_data } = event;
    let { event_type, time, special } = event_data;

    let tbd = place_data ? false : true;

    place_data = place_data ? place_data : {restaurant_name: "TBD"}
    let { restaurant_name } = place_data;

    let today_now = new Date();
    let dinner_time = new Date(dinner_date)
    dinner_time.setHours(21, 0, 0, 0); // Set the time to 9:00 PM

    // if dinner date at 9pm is greater than now, don't show attendees
    let upcoming_event = dinner_time > today_now;

    let media_element = this.drawMediaElement(place_data, event_data);
    let date_element = new Date(dinner_date);

    let event_link = special ?  `/special/${event_id}` : `/rsvp/${dinner_date.replace(/\//g, '.')}/-1/${event_id}`

    let restaurant_element = tbd ? null : <Text><TouchableOpacity onPress={()=>{window.open(place_data.link)}} style={style.visit_restaurant}><Text style={style.visit_restaurant_text}> {restaurant_name}  &#x2197;</Text></TouchableOpacity></Text>;
    let participants_element = upcoming_event ? <View style={{flexDirection: 'column'}}>
        <Text style={style.attendees_title}>UPCOMING EVENT</Text>
        <TouchableOpacity onPress={()=>{window.location.replace('/dashboard')}} style={[style.visit_restaurant, {width: 124}]}><Text style={style.visit_restaurant_text}> GO TO EVENT  &#x2197;</Text></TouchableOpacity>
      </View> : this.drawUserEventParticipants(event, event_id);

    let months = [
      "Jan", "Feb", "March", "April", "May", "June",
      "July", "Aug", "Sept", "Oct", "Nov", "Dec"
    ];
    date_element = months[date_element.getMonth()] + ' ' + date_element.getDate();

    let type = event_type ? event_type : (time > 17.5 ? 'Dinner' : (time > 14 ? 'Happy Hour' : 'Brunch'));

    return <View style={style.event_wrapper}>
        
      <View style={global_style.row_mobile_switch_column}>
        <View style={style.media_wrapper}>
          {media_element}
        </View>

        <View style={[global_style.column_mobile_switch_row]}>
          <View style={{flex: 1}}>
            <Text style={style.date_element}>{date_element}</Text>
            <Text style={style.title_element}>{type} at </Text>
          </View>
          <View style={style.restaurant_wrapper}>
            {restaurant_element}
          </View>
        </View>
      </View>
      <View>{participants_element}</View>
    </View>
  }

  drawPager() {
    let { page, dinners } = this.state;

    const goPage = async (page) => {
      this.setState({loading: true}, async () => {
        let dinners = await UserController.getDinners({page});
        dinners = dinners.dinners;
        dinners.sort((dinner1, dinner2)=>{return new Date(dinner1.dinner_date) < new Date(dinner2.dinner_date) ? 1 : -1});

        this.setState({
          dinners,
          page
        }, async () =>{
          let event_rsvps = {};

          for(var i = 0; i < dinners.length; i++) {
            let event_id = dinners[i].event_id;
            let {rsvps, tentatives} = await UserController.getDateRsvpsForEvent({event_id})

            event_rsvps = {
              ...event_rsvps,
              [event_id]: rsvps
            }
          }

          this.setState({
            event_rsvps,
            loading: false
          })
        })
      });
    }

    let page_element = <Text style={style.page_number}>{page+1}</Text>
    let prev = page !== 0 ? 
        <TouchableOpacity onPress={()=>goPage(page-1)}>
          <Text style={style.pager_button_enabled}><Icon color="white" name="arrow-left" size={20} /></Text>
        </TouchableOpacity> : 
        <Text style={style.pager_button_disabled}><Icon name="arrow-left" color={'white'} size={20} /></Text>;


    let next = dinners.length === 6 ? 
        <TouchableOpacity onPress={()=>goPage(page+1)}>
          <Text style={style.pager_button_enabled}><Icon name="arrow-right" color="white" size={20} /></Text>
        </TouchableOpacity> : 
        <Text style={style.pager_button_disabled}><Icon name="arrow-right" color="white" size={20} /></Text>;

    return <View style={style.pager_wrapper}>
      {prev}{page_element}{next}
    </View>
  }

  drawInvitedEventsOld(event, index) {
    console.log('eventUno', event)
    let { event_data } = event;
    let { time, name, date, event_id } = event_data;

    let today_now = new Date();
    let dinner_time = new Date(date)
    dinner_time.setHours(21, 0, 0, 0); // Set the time to 9:00 PM

    // if dinner date at 9pm is greater than now, don't show attendees
    let upcoming_event = dinner_time > today_now;

    let media_element = this.drawMediaElement({}, event_data);
    let date_element = new Date(date);

    let event_link = `/invite/${event_id}`

    let participants_element = upcoming_event ? <View style={{flexDirection: 'column'}}>
        <Text style={style.attendees_title}>UPCOMING EVENT</Text>
        <TouchableOpacity onPress={()=>{window.location.replace('/dashboard')}} style={[style.visit_restaurant, {width: 124}]}><Text style={style.visit_restaurant_text}> GO TO EVENT  &#x2197;</Text></TouchableOpacity>
      </View> : this.drawParticipants(event_id, event.event_data.host, event);

    let months = [
      "Jan", "Feb", "March", "April", "May", "June",
      "July", "Aug", "Sept", "Oct", "Nov", "Dec"
    ];
    date_element = months[date_element.getMonth()] + ' ' + date_element.getDate();

    return <View style={style.event_wrapper}>
        
      <View style={global_style.row_mobile_switch_column}>
        <View style={style.media_wrapper}>
          {media_element}
        </View>

        <View style={[global_style.column_mobile_switch_row]}>
          <View style={{flex: 1}}>
            <Text style={style.date_element}>{date_element} {time}</Text>
            <Text style={style.title_element}>{name}</Text>
          </View>
        </View>
      </View>
      <View>{participants_element}</View>
    </View>
  }

  drawUserEvent(event, index) {
    console.log('eventUnouser', event)
    let { event_data, id } = event;
    let { chosen_type, chosen_what, status, chosen_time, selected_day, max_age, min_age, message, gender } = event_data;

    let what_drop = config.what_drop;
    let type_drop = config.type_drop;

    console.log('what_drop', what_drop);
    console.log('type_drop', type_drop);

    let name = what_drop.filter(what=>what.value===chosen_what);
    console.log('name', name);
    name = name ? name[0] : {};
    name = name.name;

    let type = type_drop.filter(type=>type.value===chosen_type);
    console.log('type', type);
    type = type ? type[0] : {};
    let main_image = type.placeholder_image;
    type = type.name;

    /* {
      "gender": "Non-binary",
      "max_age": "73",
      "message": "asdf",
      "min_age": "44",
      "created_by": "cc467f19-6954-4151-ba3c-c45c8563511c",
      "chosen_time": "7:30 AM",
      "chosen_type": "1",
      "chosen_what": "1",
      "chosen_where": "985b8107-e91f-43da-9efd-03b624da40f3",
      "selected_day": "2/21/2024"
  } */

    let event_id = id;

    let today_now = new Date();

    const getDate = (dateString) => {
      //var dateString = "12/3/2024 3 pm";
      var dateParts = dateString.split(" "); // Split the string into date and time parts

      var date = dateParts[0]; // Extract the date part
      var time = dateParts[1] + " " + dateParts[2]; // Extract the time part

      // Split the date part into day, month, and year
      var dateSplit = date.split("/");
      var month = parseInt(dateSplit[0]) - 1; // Months are 0-indexed in JavaScript
      var day = parseInt(dateSplit[1]);
      var year = parseInt(dateSplit[2]);

      // Split the time part into hour and minute
      var timeSplit = time.split(":");
      let h = timeSplit[0];
      let m = timeSplit.length > 1 ? timeSplit[1] : '0'
      var hour = parseInt(h);
      var minute = parseInt(m);

      // Adjust hour if it's PM
      if (dateParts[3] === "pm" && hour !== 12) {
          hour += 12;
      }

      console.log('year, month, day, hour, minute', year, month, day, hour, minute)

      // Create a new Date object
      var newDate = new Date(year, month, day, hour, minute);

      return newDate;
    }

    let dinner_time = getDate(`${selected_day} ${chosen_time}`)

    //dinner_time.setHours(21, 0, 0, 0); // Set the time to 9:00 PM

    // if dinner date at 9pm is greater than now, don't show attendees
    let upcoming_event = dinner_time > today_now;
    console.log('upcoming_event', upcoming_event);
    console.log('dinner_time', dinner_time)


    let media_element = this.drawMediaElement({main_image}, event_data);
    let date_element = new Date(selected_day);

    let event_link = `/create_invite/${event_id}`;
    let rsvp_link = `/user_invite/${event_id}`

    let participants_element = upcoming_event || status !== 'published' ? <View style={{flexDirection: 'column'}}>
        {upcoming_event ? <Text style={style.attendees_title}>UPCOMING EVENT</Text> : null}
        <TouchableOpacity onPress={()=>{window.location.replace(event_link)}} style={[style.visit_restaurant, {width: 124}]}><Text style={style.visit_restaurant_text}>MANAGE EVENT</Text></TouchableOpacity>{status === 'published' ? <TouchableOpacity onPress={()=>{window.location.replace('/dashboard')}} style={[style.visit_restaurant, {width: 124}]}><Text style={style.visit_restaurant_text}>GO TO EVENT</Text></TouchableOpacity> : null}
      </View> : this.drawParticipants(event_id, event.event_data.host, event);

    let months = [
      "Jan", "Feb", "March", "April", "May", "June",
      "July", "Aug", "Sept", "Oct", "Nov", "Dec"
    ];
    date_element = months[date_element.getMonth()] + ' ' + date_element.getDate();

    let status_style = status === 'draft' ? style.draft_style : style.published_style;

    return <View style={style.event_wrapper}>
        
      <View style={global_style.row_mobile_switch_column}>
        <View style={style.media_wrapper}>
          {media_element}
        </View>

        <View style={[global_style.column_mobile_switch_row, {flex: 1}]}>
          <View style={{flex: 1}}>
            <Text style={style.date_element}>{date_element}</Text>
            <Text style={style.event_name_text}>{name} - {type}</Text>
          </View>
        </View>
      </View>
      <View>
        {participants_element}
        <Text style={status_style}>{status}</Text>
      </View>
    </View>
  }

  openCreate() {
    let { is_member } = this.state;

    if(!is_member) {
      this.openInParent(<View style={global_style.center_content}>
        <Text style={[global_style.medium_description, {marginBottom: 20, paddingTop: 0}]}>You're not a member just yet. As a member, you'll be able to invite others to coffee / happy hour / dinner.  </Text>
        <Button title={'View Our Plans'}
            loading={false}
            style={{
              fontFamily: 'Ysabeau',
              backgroundColor: 'black',
              alignSelf: 'center',
              borderRadius: 3,
              paddingTop: 5,
              paddingBottom: 7,
              paddingHorizontal: 5, marginBottom: 10}}
            onPress={()=>{window.location.replace('/dashboard/subscription')}} /> 
      </View>, "is_create_notify_open", 400, 300)
    } else {
      window.location.replace('/create_invite')
    }
  }

  drawUserPager() {
    let { user_events, user_page, next_disabled } = this.state;
    user_events = user_events && user_events.length ? user_events : [];

    let is_mobile = width < 500;

    let page_length = is_mobile ? 1 : 4;

    let prev_enabled = user_page !== 0;
    let next_enabled = user_events.length === page_length && !next_disabled;

    const changePage = (num) => {
      let { user_page } = this.state;
      user_page = user_page + num;
      this.setState({
        user_page
      }, this.getUserEvents)
    }

    return user_events.length ? <View style={{flexDirection: 'row', justifyContent: 'space-between', marginBottom: 40}}><TouchableOpacity onPress={prev_enabled ? ()=>changePage(-1) : null}><Text style={prev_enabled ? global_style.club_button_full : global_style.club_button_disabled}>Prev</Text></TouchableOpacity><TouchableOpacity onPress={next_enabled ? ()=>changePage(1) : null}><Text style={next_enabled ? global_style.club_button_full : global_style.club_button_disabled}>Next</Text></TouchableOpacity></View> : null
  }

  render() {
    let { mounted, dinners, loading, user_events, user_id, user_event_rsvps, user_events_loading, modal_content, places, my_user_id } = this.state;
    user_events = user_events && user_events.length ? user_events : [];

    let is_mobile = width < 500;

    return mounted ? 
    <View>
      <Modal ref={node=>this.modal_ref=node} putInParent={this.props.putInParent} />
        <View style={[style.dashboard_panel, style.profile_panel, {paddingBottom: 100}]}>
          {user_events.length ? <>
            <Text style={style.section_title}>Meet-Ups</Text>
            {modal_content}
            {user_events_loading ? <View style={{width: 60, height: 60, alignSelf: 'center', paddingVertical: 100}}>
                    <Progress.CircleSnail size={60} backgroundColor="white" fill='white' color={'#e32652' } />
                  </View> : <>
            <View>
              <View style={{flexDirection: 'column'}}>
                {this.drawUserPager()}
                <View style={[style.dinners_wrapper, {justifyContent: is_mobile ? 'center' : 'unset'}]}>
                  
                  {user_events.map(event=>{
                    let { selected_day } = event;

                    let the_date = new Date(selected_day);
                    let day_of_week = (the_date).getDay();
                    let day_name = Utilities.getDayName(day_of_week);
                    
                    return <View style={{margin: 20}}><Text style={{fontFamily: 'Ysabeau', fontSize: 30, textAlign: 'center', marginBottom: 10}}>{day_name.substr(0, 3)} {the_date.getMonth()+1}/{the_date.getDate()}</Text>{<MeetUpEvent read_only={true} places={places} event={event} user_id={my_user_id} event_rsvps={user_event_rsvps} putInParent={this.props.putInParent} />}</View>})}
                
                  {user_events.length ? null : <Text style={{textAlign: 'center', fontFamily: 'Ysabeau', fontSize: 18}}>You haven't rsvped for any meet-ups yet.</Text>}
                </View>
              </View>
            </View>
            </>}
          </> : null}
          <View>
            <Invites />
          </View>
          <Text style={style.section_title}>Events You've RSVPed</Text>
          
            {dinners && dinners.length ? 
            <>
              {this.drawPager()}
              {loading ? <View style={{width: 60, height: 60, alignSelf: 'center', paddingVertical: 100}}>
                  <Progress.CircleSnail size={60} backgroundColor="white" fill='white' color={'#e32652' } />
                </View> :
              <View style={style.dinners_wrapper}>
                {dinners.map(this.drawEvents)}
              </View>}
              {this.drawPager()}
            </>
             :
            <View style={[style.a_column, {alignContent: 'center', alignItems: 'center', alignSelf: 'center'}]}>
              <Text style={{textAlign: 'center', fontFamily: 'Ysabeau', fontSize: 18}}>You haven't participated in any events yet.</Text>
              <TouchableOpacity onPress={()=>this.props.navigate('/dashboard')}><Text style={style.go_to_button}>Go to Calendar</Text></TouchableOpacity>
            </View>}
        </View>
      </View> : <View style={{width: 60, height: 60, alignSelf: 'center', paddingVertical: 100}}>
                  <Progress.CircleSnail size={60} backgroundColor="white" fill='white' color={'#e32652' } />
                </View>;
  }

}

function YourEvents(props) {
  if(Platform.OS === 'web') {
    let navigate = useNavigate();
    let { to_user } = useParams();
    return  <YourEvents_Base {...props} to_user={to_user} navigate={navigate} />
  } else {
    return  <YourEvents_Base {...props} navigate={props.navigation.push}  />
  }
}

export default YourEvents;
