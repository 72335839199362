import axios from 'axios';
import { config } from '../../config';
import * as uuid from 'uuid';
import * as Crypto from 'expo-crypto';
import { View, TouchableOpacity } from 'react-native';
import { Text } from '../components';
import { global_style } from '../../global_style';

export default class Utilities {

    static onlyNumbers(phone) {
        phone = phone.replace(/\D/g, '');
        return phone;
    }

    static isPhoneValid(phone) {

        return phone.length === 10;
    }

    static isDateLessThanToday(dateString) {
        // Create a Date object from the input string
        const inputDate = new Date(dateString);
        
        // Get today's date and set the time to 00:00:00 for comparison
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        
        // Compare the input date with today's date
        return inputDate < today;
      }

    static getDatesForNextTwoMonths() {
        const result = [[], [], []];
        const today = new Date();
        const currentYear = today.getFullYear();
        const currentMonth = today.getMonth();
      
        // Helper function to format a date as 'YYYY-MM-DD'
        const formatDate = (date) => {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          return `${year}-${month}-${day}`;
        };
      
        // Helper function to calculate the difference in days between two dates
        const dateDiffInDays = (date1, date2) => {
          const diffTime = date2.getTime() - date1.getTime();
          return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        };
      
        // Helper function to get the week number for a given date
        const getWeekNumber = (date) => {
          const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
          const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
          return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
        };
      
        // Loop through the current month and the next two months
        for (let i = 0; i < 3; i++) {
          const monthIndex = (currentMonth + i) % 12;
          const year = monthIndex < currentMonth ? currentYear + 1 : currentYear;
          const daysInMonth = new Date(year, monthIndex + 1, 0).getDate();
          const weeksInMonth = [];
      
          let weekArray = [];
      
          for (let day = 1; day <= daysInMonth; day++) {
            const date = new Date(year, monthIndex, day);
            const dateStr = formatDate(date);
            const diffInDays = dateDiffInDays(today, date);
            const isVisible = diffInDays >= 0 && diffInDays <= 60;
      
            weekArray.push({ date: dateStr, isVisible });
      
            // If it's Saturday or the last day of the month, push the weekArray to weeksInMonth
            if (date.getDay() === 6 || day === daysInMonth) {
                weeksInMonth.push(weekArray);
                weekArray = [];
            }
          }
      
          // Add the weeksInMonth to the result
          result[i] = weeksInMonth;
        }
      
        return result;
      }
      
    static getDescription(description, style, keep_links) {
        let tokens = description.split('<br/>');

        return tokens.map(token=>{

            var pattern = /^(.*?)<link>(.*?)<\/link>(.*)$/;
            var patternMedium = /^(.*?)<MEDIUM>(.*?)<\/MEDIUM>(.*)$/;
            var patternPARAGRAPH = /^(.*?)<MEDIUM>(.*?)<\/MEDIUM>(.*)$/;
            

            // Search for the pattern in the text
            var match = token.match(pattern);

            // If a match is found, extract the text before, between, and after the tags
            let beforeText, linkText, afterText, displayLinkText, link;
            if (match) {
                beforeText = match[1];
                linkText = match[2];
                afterText = match[3];

                console.log('linkText', linkText)
                displayLinkText = linkText;

                if(keep_links) {
                    link = linkText;
                    if(link.indexOf('https') === -1 && link.indexOf('http') === -1) {
                        link = `https://${link}`
                    }

                    linkText = <TouchableOpacity onPress={()=>{window.open(link)}}><Text style={global_style.normal_link}>{displayLinkText}</Text></TouchableOpacity>
                } else {
                    linkText = null;
                }
            } else {
              beforeText = token;
            }

              // Search for the pattern in the text
              var matchMEDIUM = beforeText.match(patternMedium);

              if(matchMEDIUM) {
                beforeText = <><Text style={style.description}>{matchMEDIUM[1]}</Text><Text style={style.medium_description}>{matchMEDIUM[2]}</Text><Text style={style.description}>{matchMEDIUM[3]}</Text></>
              }
            

            return <View style={{marginBottom: 20}}><Text style={style.description}>{beforeText}{linkText}{afterText}</Text></View>
          });
    }

    static updateValue(name, toggle, value) {

        let the_value;
        if(toggle) {
            the_value = !this.state[name]
        }
        this.setState({
            [name]: the_value
        })
    }

    static stripUnsafe(str) {
        try {
            return str.replace(/[-+()"']/g, '');
        } catch (err) {
            return false;
        }
    }

    static getDayName(day) {
        switch(day) {
          case 0:
            return 'Sunday';
          case 1:
            return 'Monday';
          case 2:
            return 'Tuesday';
          case 3:
            return 'Wednesday';
          case 4:
            return 'Thursday';
          case 5:
            return 'Friday';
          case 6:
            return 'Saturday';
        }
    }

    static getEventTime(number) {
        let specifier = '';
    
        let am_pm = number >= 12 ? ' PM' : ' AM';
        let hour_str = '';
        let tokens = number.toString().split('.');
        let minutes = tokens.length > 1 ? parseInt(tokens[1]) : 0;
    
        if(minutes) {
          minutes = parseFloat("."+minutes);
          hour_str = `:${minutes * 60}`;
        } else {
          hour_str = `:00`; 
        }
    
        if(number > 12) {
          hour_str = `${parseInt(tokens[0]) - 12}` + hour_str;
        } else {
          hour_str =  `${tokens[0]}` + hour_str;
        }
    
        return hour_str + am_pm;
    }

    static getMonthName(month) {
        var monthNames = [
            "January", "February", "March",
            "April", "May", "June", "July",
            "August", "September", "October",
            "November", "December"
          ];

        return monthNames[month];
    }

    static getDateSuffix(date) {
        // Extract the last digit of the date
        var lastDigit = date % 10;
        
        // Extract the last two digits of the date
        var lastTwoDigits = date % 100;
        
        // 'th' is used for 11-13 as they are exceptions
        if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
          return 'th';
        }
        
        // Otherwise, return suffix based on the last digit
        switch (lastDigit) {
          case 1:
            return 'st';
          case 2:
            return 'nd';
          case 3:
            return 'rd';
          default:
            return 'th';
        }
      }

    static getEventDate(date) {
        let obj_date = new Date(date);

        let day = obj_date.getDay();
        day = Utilities.getDayName(day);

        let date_tokens = date.split('/');

        let month_name = Utilities.getMonthName(obj_date.getMonth())

        return day.slice(0, 3) + ', ' + month_name + ' ' + date_tokens[1]+Utilities.getDateSuffix(date_tokens[1]);
    }

    static getEventTypeName(type) {
        type = type.toString();
        let dining_type;

        /* type

            0 - 20s/30s coffee
            1 - 40s+ coffee
            2 - 20s/30s non-hosted dinner
            3 - 40s+ hosted dinner
            4 - 40s+ non-hosted dinner
            5 - 20s/30s brunch */
        
        switch(type) {
            case "0":
            case "1":
                dining_type = "coffee";
                break;
            case "-1":
            case "2":
            case "3":
            case "4":
                dining_type = "dinner";
                break;
            case "6":
                dining_type = "brunch";
                break;
            default: break;
        }

        return dining_type;
    }

    static stripUnsafeDB(str) {
        try {
            return str.replace(/[-+()"'@#$%^&*`~.,:;\\|\][}{=/><‘“?!]/g, '');
        } catch (err) {
            return false;
        }
    }

    static parseConnectionString(url, driver) {
        try {
            let connection_components = {};

            switch (driver) {
                case "mongodb":
                    if (url.indexOf('mongodb://') === -1 && url.indexOf('mongodb+srv://') === -1) {
                        throw { message: `Invalid scheme, expected connection string to start with "mongodb://" or "mongodb+srv://` }
                    }
                    break;
                case "postgresql":
                    if (url.indexOf('postgres://') === -1) {
                        throw { message: `Invalid scheme, expected connection string to start with "postgres://"` }
                    }
                    break;
                default: break;
            }

            console.log('HERE IN PARSE STRING', url)

            connection_components = this.parseGeneralConnection(url);

            return connection_components;
        } catch (err) {
            console.log('throwing err', err)
            throw err;
        }
    }

    static parseGeneralConnection(url) {
        try {
            let username, password, host, port, database, no_credentials, driver;
            console.log('URL', url);
            if (url.indexOf('://') === -1) {
                throw { message: "Driver not detected. Make sure you have the driver prefix before the literal '://'." };
            }

            driver = url.split('://')[0];
            driver = driver.indexOf('mongodb') !== -1 ? 'mongodb' : (driver.indexOf('postgres') !== -1 ? 'postgresql' : null)

            let split_at_sign = url.split('@');
            if (split_at_sign.length < 2) {
                no_credentials = true;

                // If there's no credentials, cannot have :
                let second = url.split('://')[1];

                if (second.length === 0) {
                    throw { message: "No connection details detected." }
                }

                console.log('second', second)

                if (second.indexOf(':') !== -1) {
                    throw { message: "Host not detected. Cannot enter credentials for a non-existant host." }
                }
            }
            else {
                if (split_at_sign.length > 2) {
                    throw { message: "Connection string has too many @ signs. If the @ sign is included in the password, make sure to url encode the password with encodeURIComponent(password)." };
                }
            }
            let top_at_split = split_at_sign[0].split(':');
            if (top_at_split.length < 2) {
                throw { message: "Driver not detected. Make sure you have the driver prefix before the literal '://'." };
            }
            if (top_at_split.length > 3) {
                throw { message: "Connection string has too many colons before the @ sign. If the : sign is included in the password, make sure to url encode the password with encodeURIComponent(password)." };
            }
            if (top_at_split.length === 3 && !no_credentials) {
                username = top_at_split[1].replace("//", "");
                password = top_at_split[2];
            }
            let host_db_split, port_db_split;
            if (no_credentials) {

                host_db_split = url.split('://')[1].split('/');
                //sizzle-production-db.cbvxlsii5kbi.us-east-1.rds.amazonaws.com:5432
                // /sizzledev
                // localhost:27017
                // TeleTailsDBNew
                if (host_db_split.length < 2) {
                    throw { message: "Database or host not detected in connection string." };
                } else {
                    let port_db_split = host_db_split[0].split(':');
                    if (port_db_split.length === 2) {
                        port = port_db_split[1];
                        host = port_db_split[0];
                        database = host_db_split[1].split('?')[0];
                    } else {
                        host = host_db_split[0];
                        database = host_db_split[1].split('?')[0];
                    }
                }
            }
            else {

                let bottom_at_split = split_at_sign[1].split(':');

                if (bottom_at_split.length === 2) {
                    host = bottom_at_split[0];
                    port_db_split = bottom_at_split[1].split('/');
                    port = port_db_split[0];

                    if (port_db_split.length > 1)
                        database = port_db_split[1].split('?')[0];
                }
                else {
                    host_db_split = bottom_at_split[0].split('/');
                    host = host_db_split[0].split(':')[0];

                    if (host_db_split.length > 1)
                        database = host_db_split[1].split('?')[0];
                }
            }

            if (!database) {
                throw { message: "Database not detected in connection string." };
            }

            console.log('host', host);
            console.log('port', port);
            console.log('username', username);
            console.log('password', password);
            console.log('database', database);


            if (!port) {
                switch (driver) {
                    case "mongodb":
                        port = "27017";
                        break;
                    case "postgresql":
                        port = "5432";
                        break;
                    default: break;
                }
            }

            return {
                user: username,
                host,
                port,
                username,
                password,
                database
            };
        }
        catch (err) {
            console.log('err', err)
            throw err;
        }
    }

    // Helper functions to convert between byte arrays and hexadecimal strings
    static bytesToHex(bytes) {
        return bytes.reduce((str, byte) => str + byte.toString(16).padStart(2, '0'), '');
    }

    static hexToBytes(hex) {
        console.log('hex', hex);
        return new Uint8Array(hex.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));
    }

    static encodeUtf8(text) {
        const encoder = new TextEncoder();
        return encoder.encode(text);
    }

    static async encryptEntity(entity) {
        return new Promise(async (resolve, reject) => {
            try {
                let secret = config.encryption_key.substr(0, 32);

                const iv = Utilities.hexToBytes('0123456789abcdef'); // 16-byte IV            

                console.log('secret', secret);
                console.log('iv', iv);

                // Convert the secret and IV from hexadecimal strings to byte arrays
                const keyBytes = Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.SHA256, secret).then(Utilities.hexToBytes);
                const ivBytes = iv //Utilities.hexToBytes(iv);

                // Use the secret and IV to encrypt the entity message
                const ciphertextBytes = await Crypto.encryptAsync(
                    Crypto.CryptoAlgorithm.AES,
                    await keyBytes,
                    await ivBytes,
                    Utilities.encodeUtf8(entity)
                );

                // Convert the encrypted bytes to a hexadecimal string
                const ciphertextHex = Utilities.bytesToHex(ciphertextBytes);

                // Output the encrypted message
                console.log(ciphertextHex);

                resolve({ content: content.toString('hex'), iv: iv.toString('hex') });
            } catch (err) {
                console.log('Encrypt error: ', err);
                reject(err)
            }
        });
    }

    static async encryptEntity_reactJC(entity) {
        return new Promise((resolve, reject) => {
            try {
                let algo = 'aes-256-ctr';
                let secret = config.encryption_key.substr(0, 32);

                let iv = Crypto.getRandomBytes(16);

                let cipher = Crypto.createCipheriv(algo, secret, iv);
                let content = Buffer.concat([cipher.update(entity), cipher.final()])

                resolve({ content: content.toString('hex'), iv: iv.toString('hex') });
            } catch (err) {
                console.log('Encrypt error: ', err);
                reject(err)
            }
        });
    }

    static async decryptEntity(hash) {
        return new Promise((resolve, reject) => {
            try {
                let algo = 'aes-256-ctr';
                let secret = config.encryption_key.substr(0, 32);

                let decipher = Crypto.createDecipheriv(algo, secret, Buffer.from(hash.iv, 'hex'));

                let entity = Buffer.concat([decipher.update(Buffer.from(hash.content, 'hex')), decipher.final()]);

                resolve(entity.toString());
            } catch (err) {
                console.log('Encrypt error: ', err);
                reject(err)
            }
        });
    }

    static doLocalHostPost(url, data) {
        return new Promise(async (resolve, reject) => {
            try {
                let post_url = `http://localhost:3211/${url}`;
                let headers = {};

                axios.post(post_url, data, headers).then(async response => {

                    if (response.data && response.data.data) {
                        console.log('response.data.data', response.data.data)
                        let json_data = JSON.parse(response.data.data);

                        resolve(json_data);
                    } else {
                        resolve(response);
                    }
                }).catch(err => {
                    console.log('err', err)
                    reject(err)
                })
            } catch (err) {
                reject(err)
            }
        })
    }

    static doLocalHostGet(url) {
        return new Promise(async (resolve, reject) => {
            try {
                let post_url = `http://localhost:3211/${url}`;
                let headers = {};

                axios.get(post_url).then(async response => {

                    if (response.data && response.data.data) {
                        console.log('response.data.data', response.data.data)
                        let json_data = JSON.parse(response.data.data);

                        resolve(json_data);
                    } else {
                        resolve(response);
                    }
                }).catch(err => {
                    console.log('err', err)
                    reject(err)
                })
            } catch (err) {
                reject(err)
            }
        })
    }

    static doInternalPost_encrypted(url, unencrypted_data, remove_token?) {
        return new Promise(async (resolve, reject) => {
            try {
                let headers = {};

                let authorization;
                if (!remove_token) {
                    authorization = localStorage.getItem('token');
                }

                //authorization = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiOGU1YjAxNTEtMzBkZi00NWNjLTgxNjgtODhjZWQ2NDBkOWExIiwiaWF0IjoxNjQ0NDAwNDgwfQ.8wZ4JKf_ESgEKHA3Sc5UFhSmP7J-AyE8N1NBrrhqZjw'

                // step 1: stringify body
                // step 2: encrypt stringified body
                // step 3: stringify encryption since it's a json
                // encode body with validation_key - as header x-request-signature-sha-256
                // send {data: step 3} as req.body

                // on api
                // step 1: req.body.data validate against header x-request-signature-sha-256
                // step 2: req.body.data - parse json 
                // step 3: decrypt

                // encrypt data
                let string_data = JSON.stringify(unencrypted_data);
                let encrypted_string_data = await Utilities.encryptEntity(string_data)

                let encrypted_string_data_stringified = JSON.stringify(encrypted_string_data)

                // add validation on body
                const hash = Crypto
                    .createHmac("sha256", config.validation_key)
                    .update(encrypted_string_data_stringified)
                    .digest("hex");

                headers = {
                    headers: {
                        "x-request-signature-sha-256": hash
                    }
                }

                if (authorization) {
                    headers = {
                        headers: {
                            authorization,
                            "x-request-signature-sha-256": hash
                        }
                    }
                }

                let post_url = `${config.apiURL}${url}`;

                axios.post(post_url, { data: encrypted_string_data_stringified }, headers).then(async response => {

                    if (response.data && response.data.data) {
                        let decrypted_data = await Utilities.decryptEntity(response.data.data);
                        let json_data = JSON.parse(decrypted_data);

                        resolve(json_data);
                    } else {
                        resolve(response);
                    }
                }).catch(err => {
                    console.log('1')
                    console.log('err', err)
                    reject(err)
                })
            } catch (err) {
                console.log('2')
                reject(err)
            }
        })
    }

    static doInternalPost(url, unencrypted_data, remove_token?) {
        return new Promise(async (resolve, reject) => {
            try {
                let headers = {};

                let authorization;
                if (!remove_token) {
                    authorization = localStorage.getItem('token');
                }

                if (authorization) {
                    headers = {
                        headers: {
                            authorization
                        }
                    }
                }

                let post_url = `${config.apiURL}${url}`;

                console.log('post_url', post_url);
                axios.post(post_url, unencrypted_data, headers).then(async response => {
                    console.log('res', response);
                    if (response.data) {

                        resolve(response.data);
                    } else {
                        resolve(response);
                    }
                }).catch(err => {
                    console.log('1')
                    console.log('err', err)
                    reject(err)
                })
            } catch (err) {
                console.log('2')
                reject(err)
            }
        })
    }

    static doInternalGet(url, unencrypted_data, remove_token?) {
        return new Promise(async (resolve, reject) => {
            try {
                let headers = {};

                let authorization;
                if (!remove_token) {
                    authorization = localStorage.getItem('token');
                }

                if (authorization) {
                    headers = {
                        headers: {
                            authorization
                        }
                    }
                }

                let get_url = `${config.apiURL}${url}`;

                console.log('get_url', get_url);
                axios.get(get_url, headers).then(async response => {
                    console.log('res', response);
                    if (response.data) {

                        resolve(response.data);
                    } else {
                        console.log('response error above')
                        resolve(response);
                    }
                }).catch(err => {
                    console.log('1')
                    console.log('err', err)
                    resolve(err)
                })
            } catch (err) {
                console.log('2')
                resolve(err)
            }
        })
    }

    static validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    static isInViewport(element) {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    static uuidv4() {
        var d = new Date().getTime();//Timestamp
        var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16;//random number between 0 and 16
            if (d > 0) {//Use timestamp until depleted
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else {//Use microseconds since page-load if supported
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }
}
