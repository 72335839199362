import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { View, Platform, TouchableOpacity } from 'react-native';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Input, Text } from '../../components';
import { Event } from '../../containers';
import authController from '../../controllers/authController';
import UserController from '../../controllers/userController';
import EventController from '../../controllers/eventController';
import Utilities from '../../utilities';
import { Header } from '../../containers';
import { style } from './style';

class Events_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password: null,
      tab: 0,
      terms: true,
      event_id: props.event_id,
      mounted: true
    }

    this.rsvpClick = this.rsvpClick.bind(this);
  }

  async componentDidMount() {
    console.log('props', this.props);


  }

  rsvpClick() {
    let { event } = this.state;
    this.props.navigate(`/rsvp/${event.slug}`);
  }

  render() {
    let { event, event_id, mounted } = this.state; 

    return (
      mounted ? 
      <View style={[style.page_container, {marginBottom: 200}]}>
        <Event putInParent={this.props.putInParent} event_id={event_id} />
      </View> : null
    );
  }
}

function EventsScreen(props) {
  if (Platform.OS === 'web') {
    let navigate = useNavigate();
    let { event_id } = useParams();

    return <Events_Base {...props} event_id={event_id} navigate={navigate} />
  } else {
    return <Events_Base {...props} navigate={props.navigation.push} />
  }
}

export default EventsScreen;
