import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { StyleSheet, View, TouchableOpacity, Platform, Image, Picker, Dimensions } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import { useNavigate } from 'react-router-dom';
import { Text, Input } from '../../components';
import UserController from '../../controllers/userController';
import { style } from './style';
import { global_style } from '../../../global_style';
import Icon from 'react-native-vector-icons/FontAwesome';

class MemberPortal_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
      page: 0,
      invited_members: props.invited_members
    }

    if(props.style) {
      style = props.style;
    }

    this.updateState = this.updateState.bind(this);
    this.searchMembers = this.searchMembers.bind(this);
    this.drawUser = this.drawUser.bind(this);
    this.drawHobbies = this.drawHobbies.bind(this);
    this.updateHobby = this.updateHobby.bind(this);
  }

  async componentDidMount() {

    let interest_list = await UserController.getInterestList();

    let interests = interest_list && interest_list.interests && interest_list.interests.interests ?  interest_list.interests.interests : null ;

    if(interests) {
      interests = JSON.parse(interests);
    }

    let hobbies_list = interests ? interests.hobbies : [];

    if(this.state.search_text) {
      this.searchMembers();
    }
    this.setState({
      hobbies_list,
      mounted: true
    })
  }

  updateState(event, name) {
    event = event === "-1" ? null : event;
    this.setState({
      [name]: event
    })
  }

  async searchMembers(new_page) {
    let { search_text, page, max_age, min_age, chosen_interests, gender } = this.state;

    page = new_page ? new_page : 0;

    gender = gender === 'Choose gender...' ? null : gender;

    let data = {
      max_age, min_age, search_text, page, chosen_interests, gender
    }

    let search_results = await UserController.searchMembers(data);

    console.log('search_results', search_results);

    this.setState({
      page,
      search_results
    })
  }

  drawPager() {
    let { page, search_results } = this.state;

    const goPage = async (page) => {
      this.setState({loading: true}, ()=>this.searchMembers(page));
    }

    let page_element = <Text style={style.page_number}>{page+1}</Text>
    let prev = page !== 0 ? 
        <TouchableOpacity onPress={()=>goPage(page-1)}>
          <Text style={style.pager_button_enabled}><Icon color="white" name="arrow-left" size={20} /></Text>
        </TouchableOpacity> : 
        <Text style={style.pager_button_disabled}><Icon name="arrow-left" color={'white'} size={20} /></Text>;


    let next = search_results.length === 10 ? 
        <TouchableOpacity onPress={()=>goPage(page+1)}>
          <Text style={style.pager_button_enabled}><Icon name="arrow-right" color="white" size={20} /></Text>
        </TouchableOpacity> : 
        <Text style={style.pager_button_disabled}><Icon name="arrow-right" color="white" size={20} /></Text>;

    return <View style={style.pager_wrapper}>
      {prev}{page_element}{next}
    </View>
  }

  updateHobby(hobby) {
    hobby = hobby.toLowerCase();
    let { chosen_interests } = this.state;
    chosen_interests = chosen_interests ? chosen_interests : ""

    if(chosen_interests.indexOf(`,${hobby},`) !== -1) {
      chosen_interests = chosen_interests.replace(`,${hobby},`, '')
    } else {
      chosen_interests += `,${hobby},`
    }

    this.setState({chosen_interests, is_hobbies_open: false})
  }

  drawHobbies() {
    let { hobbies_list, chosen_interests } = this.state;
    chosen_interests = chosen_interests ? chosen_interests : ""
    let hobby_titles = Object.keys(hobbies_list);

    console.log('hobbies_listhobbies_list', hobbies_list)

    console.log('chosen_interests', chosen_interests)

    return hobby_titles.map(hobby_title=>{
      return <View style={style.hobbies_edit_wrapper}>
        <Text style={{fontFamily: 'Ysabeau', fontSize: 16}}>{hobby_title}</Text>
        <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
          {hobbies_list[hobby_title].map(hobby=>{
            let selected = chosen_interests.indexOf(`,${hobby.toLowerCase()},`) !== -1 ? style.selected : {};
            return <TouchableOpacity onPress={()=>{this.updateHobby(hobby)}}>
                  <Text style={[style.interest_button, selected]}>{hobby}</Text>
                </TouchableOpacity>
          })}
        </View>
      </View>
    })
  }

  drawUser(result) {
    let { invited_members } = this.state;
    invited_members = invited_members && invited_members.length ? invited_members : [];
    console.log('result', result)

    let { profile } = result;

    profile = profile ? JSON.parse(profile) : {};
    console.log('profile1', profile)

    let { name, job, bio, hobbies, photo_url, user_hobbies } = profile;

    user_hobbies = user_hobbies ? user_hobbies : '';

    user_hobbies += hobbies;
    user_hobbies = user_hobbies.replace(/\//g, ',')

    let draw_user_hobbies = user_hobbies.split(',').map(hobby=>{
      if(hobby.trim() !== '') {
        return <View><Text style={style.input_text_hobbie}>{hobby}</Text></View>
      } else {
        return null
      }
    });

    const inviteMember = (the_user) => {
      let { invited_members } = this.state;

      invited_members = invited_members && invited_members.length ? invited_members : [];

      if(!invited_members.filter(a=>a.user_id === the_user.user_id).length) {
        invited_members.push(the_user);
      } else {
        invited_members = invited_members.filter(a=>a.user_id !== the_user.user_id)
      }

      this.setState({
        invited_members
      })

      this.props.updateInvited(invited_members)
    }

    let invited = invited_members.filter(a=>a.user_id === result.user_id).length !== 0

    return <View style={style.profile_wrapper}>
              <TouchableOpacity onPress={()=>inviteMember(result)} style={style.invite_button}>
                <Text style={[style.profile_button_text, {backgroundColor: invited ? '#e32652' : '#0dc6e3'}]}>{invited ? 'un-Invite' : 'Invite'}</Text>
              </TouchableOpacity>
      <View style={{width: 100}}>
        <Image style={style.profile_image} resizeMode={'cover'} source={{uri: photo_url ? photo_url : 'https://diningsocial-assets.s3.amazonaws.com/empty_profile.png'}} />
      </View>
      <View style={style.profile_right}>
        <Text style={style.profile_name}>{name}</Text>
        <Text style={style.profile_bio}>{bio}</Text>
        <View style={style.profile_hobbies}>
          <Text style={style.profile_title}>Hobbies</Text>
          <Text>{draw_user_hobbies}</Text>
        </View>
      </View>
    </View>
  }

  render() {

    let { mounted, search_text, search_results, max_age, min_age, gender, chosen_interests, open_choose_hobbies, open_filter } = this.state;
    let width = Dimensions.get('window').width;
    let is_mobile = width <= 700;

    let age_array = Array.from({ length: 98 }, (value, index) => {return {value: index, name: index}}).splice(21, 100);

    age_array = [{name: "Choose an age...", value: -1}].concat(age_array);

    chosen_interests = chosen_interests ? chosen_interests : '';

    chosen_interests = chosen_interests.split(',');
    chosen_interests = chosen_interests.filter(a=>a!=="")

    const drawChosenHobbies = (hobby) => {
      return <TouchableOpacity onPress={()=>{this.updateHobby(hobby)}}>
            <Text style={[style.interest_button, style.selected]}>{hobby}</Text>
          </TouchableOpacity>
    }

    return mounted ? 
    <View style={style.dashboard_panel}>
      <View style={style.profile_panel_wrapper}>
          <View style={{flexDirection: is_mobile ? 'column' : 'row'}}>
            <View style={style.edit_row}>
              <Input style={[style.input_box, {width: is_mobile ? 'calc(100vw - 40px)' : 'unset'}]} type="text" defaultValue={search_text} value={search_text} placeholder="Search bio, job, name" onChangeText={event => { this.updateState(event, 'search_text') }} />
            </View>
            <View style={{flexDirection: 'column'}}>
              <TouchableOpacity onPress={()=>{this.setState({open_filter: !this.state.open_filter})}}><Text style={[global_style.normal_link, {marginLeft: 10}]}>{open_filter ? 'collapse filter' : 'filter'}</Text></TouchableOpacity>
              
                {open_filter ? <View style={style.filter}>
                    <View style={style.line}>
                          <Text style={style.line_date}>
                            <View style={style.picker}>
                            <View style={[style.edit_row, {marginTop: 10}]}>
                                <View style={[style.a_row, style.age_drop_wrapper]}>
                                  <View style={style.age_wrapper}>
                                      <Text style={style.input_label_small}>Min age</Text>
                                      <Picker
                                          selectedValue={min_age}
                                          mode={'dialog'}
                                          style={style.drop_style_small}
                                          onValueChange={(value)=>this.updateState(value, 'min_age')}
                                        >
                                          {age_array.map(v => {
                                            return <Picker.Item
                                              fontSize={16}
                                              style={{ fontSize: 16 }}
                                              key={v.value}
                                              label={v.name}
                                              value={v.value}
                                            />
                                          })}
                                        </Picker>
                                    </View>
                                    <View>
                                      <Text style={style.input_label_small}>Max age</Text>
                                    <Picker
                                        selectedValue={max_age}
                                        mode={'dialog'}
                                        style={style.drop_style_small}
                                        onValueChange={(value)=>this.updateState(value, 'max_age')}
                                      >
                                        {age_array.map(v => {
                                          return <Picker.Item
                                              fontSize={16}
                                              style={{ fontSize: 16 }}
                                              key={v.value}
                                              label={v.name}
                                              value={v.value}
                                            />
                                        })}
                                      </Picker>
                                    </View>
                                </View>
                              </View>
                            </View>
                          </Text>
                    </View>
                    <View style={style.line}>
                          <Text style={style.line_name}>Gender</Text>
                          <Text style={style.line_date}>
                            <View style={style.picker}>
                            <View style={[style.a_row, style.age_drop_wrapper]}>
                            <View style={style.age_wrapper}>
                              <Picker
                                    selectedValue={gender}
                                    mode={'dialog'}
                                    style={style.drop_style}
                                    onValueChange={(value)=>this.updateState(value, 'gender')}
                                  >
                                    {['Choose gender...', 'Female', 'Male', 'Non-binary'].map(v => {
                                      return <Picker.Item
                                        fontSize={16}
                                        style={{ fontSize: 16 }}
                                        key={v}
                                        label={v}
                                        value={v}
                                      />
                                    })}
                                  </Picker>
                            </View>
                          </View>
                            </View>
                          </Text>
                    </View>
                    <View style={[style.line, {marginTop: 20}]}>
                          <Text style={style.line_name}>Hobby Tags</Text>
                          <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
                            {chosen_interests.map(hobby=>drawChosenHobbies(hobby))}
                          </View>
                          <Text style={style.line_date}>
                          <TouchableOpacity onPress={()=>{this.setState({open_choose_hobbies: !this.state.open_choose_hobbies})}}><Text style={global_style.normal_link}>{open_choose_hobbies ? 'COLLAPSE' : 'choose hobbies'}</Text></TouchableOpacity>
                          </Text>
                          <View style={{flexDirection: 'row', flexWrap: 'wrap', flex: 1}}>
                            {open_choose_hobbies ?  <View style={{flex: 1}}>{this.drawHobbies()}</View> : null}
                          </View>
                    </View>
                </View> : null}
            </View>
          </View>
          <View style={{alignItems: 'flex-end', marginTop: 10}}>

              <TouchableOpacity onPress={()=>this.searchMembers()} style={style.profile_button}>
                <Text style={style.profile_button_text}>Search</Text>
              </TouchableOpacity>
          </View>

          {search_results && search_results.length ? 
            <View style={{flexWrap: 'wrap', flexDirection: 'row', flex: 1}}>
              {this.drawPager()}
              {search_results.map(result=>this.drawUser(result))}
            </View> : null }

          <View style={style.dashboard_breaker}></View>
        </View>
    </View> : null;
  }

}

function MemberPortal(props) {
  if(Platform.OS === 'web') {
    let navigate = useNavigate();
    return  <MemberPortal_Base {...props} navigate={navigate} />
  } else {
    return  <MemberPortal_Base {...props} navigate={props.navigation.push}  />
  }
}

export default MemberPortal;
