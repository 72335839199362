import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { StyleSheet, View, Platform, Linking, TouchableOpacity, Text, Image } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { style } from './style';
import { Analytics, PageHit } from 'expo-analytics';
const analytics = new Analytics('G-YCC5QPC8BJ');
const home_image = require('../../../assets/jc_social_main.jpeg');

class JCSocial_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      display_section: 'phone_input',
      phone_number: '',
      phone_number_valid: false,
      validation_code: '',
      email: '',
      password: '',
      tos_accepted: false
    }


  }

  render() {

    return (
      <View style={style.page_wrapper}>
        <View style={style.main_image_wrapper}>
          
          <View style={style.main_text_band}></View>
          <View style={style.main_text_wrapper}>
            <Text style={style.main_callout}>Jersey City Social</Text>
            <Text style={style.main_sub_callout}>20s / 30s</Text>
          </View>
        </View>
        <View style={style.home_breaker}></View>
        <View style={style.events_section}>
          <Text style={style.secondary_title}>Next Event</Text>
        </View>
      </View>
    );
  }
}

function JCSocialScreen(props) {
  if(Platform.OS === 'web') {
    let navigate = useNavigate();
    return  <JCSocial_Base {...props} navigate={navigate} />
  } else {
    return  <JCSocial_Base {...props} navigate={props.navigation.push}  />
  }
}

export default JCSocialScreen;
