import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { View, Platform, Dimensions, TouchableOpacity, Image, Animated } from 'react-native';
import { useNavigate, useParams } from 'react-router-dom';
import { Text, Button, Input, Check } from '../../components';
import authController from '../../controllers/authController';
import UserController from '../../controllers/userController';
import EventController from '../../controllers/eventController';
import Utilities from '../../utilities';
import { Modal, Profile, Event } from '../../containers';
import { style } from './style';
import { Analytics, PageHit } from 'expo-analytics';
const analytics = new Analytics('G-YCC5QPC8BJ');
import { init, track } from '@amplitude/analytics-react-native';

import Icon from 'react-native-vector-icons/FontAwesome';
import { MaterialIcons } from '@expo/vector-icons';
import { FontAwesome } from '@expo/vector-icons';
import { FontAwesome5 } from '@expo/vector-icons';
import AuthController from '../../controllers/authController';
import PaymentController from '../../controllers/paymentController';
import { Video } from 'expo-av';
const moment = require('moment-timezone');

class Calendar_Base extends Component {

  constructor(props) {
    super(props);
    let width = Dimensions.get('window').width;
    let height = Dimensions.get('window').height;
    this.state = {
      mounted: true,
      dates: Utilities.getDatesForNextTwoMonths(),
      selected_index: 0,
      isLoggedIn: this.props.isLoggedIn,
      is_mobile: width < 500,
      currentIndex: 0,
      currentIndexM: 0,
      event_dining_photos: [
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/amelias_one.png', order: 1}, 
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/amelias_two.MOV', type: 'movie', order: 0}, 
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/amelias_one.mov', type: 'movie', order: 2}, 
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/amelias_two.png', order: 3}, 
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/amelias_three.JPG', order: 4},
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/amelias_last.JPG', order: 6}],
      member_dining_photos: [
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/m_eight.png', order: 8},
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/home_carousel/bottom/seven.jpg', order: 0},
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/m_one.png', order: 1},
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/m_twelve.png', order: 2},
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/m_thirteen.png', order: 2.5},
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/m_three.png', order: 3},
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/m_five.png', order: 6},
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/m_ten.png', order: 10},
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/m_eleven.png', order: 11},
      ]
    }

    this.translateX = new Animated.Value(0);
    this.translateXM = new Animated.Value(0);

    this.modal_ref = React.createRef();
    this.typesRef = React.createRef();

    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
    this.availabilityModal = this.availabilityModal.bind(this);
    this.updateAvailability = this.updateAvailability.bind(this);
    this.getAvailability = this.getAvailability.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openEvent = this.openEvent.bind(this);
    this.rsvp = this.rsvp.bind(this);
    this.updatePhone = this.updatePhone.bind(this);
    this.updateProfile = this.updateProfile.bind(this);

    this.cancelRSVP = this.cancelRSVP.bind(this);
  }

  async componentDidMount() {
    track('Calendar Page');
    analytics.hit(new PageHit('Calendar'))
    .then(() => {})
    .catch(e => console.log("analytics error", e.message));

    let events = await AuthController.getEvents();
    let places = await AuthController.getPlaces();
    events = events && events.events ? events.events : [];
    console.log('events', events)

    this.setState({events, places});

    if(this.state.isLoggedIn) {
      this.getAvailability();
    }
  }

  async getAvailability() {
    let user = await UserController.getUser(true);
    let user_id = user && user.user ? user.user.id : null;
  
    let { subscription, is_member } = await UserController.getUserSubscriptionLive(user);
    
    let dinners = await UserController.getDinners({});
    dinners = dinners && dinners.dinners ? dinners.dinners : [];
console.log('dinners', dinners)

    let availability = await EventController.getAvailability({});

    availability = availability && availability.availability && availability.availability.data ? availability.availability.data : []

    user = user && user.user ? user.user : user;
    this.setState({
      user,
      availability,
      is_member, user_id, dinners
    })
  }

  next() {
    let { selected_index } = this.state;

    selected_index = selected_index === 2 ? 2 : selected_index + 1;

    this.setState({selected_index})
  }

  prev() {
    let { selected_index } = this.state;

    selected_index = selected_index === 0 ? 0 : selected_index - 1;

    this.setState({selected_index})
  }

  availabilityModal() {

    let { notes, date, isLoggedIn, events } = this.state;

    let window_width = Dimensions.get('window').width;
    let is_mobile = window_width < 500;



    if(isLoggedIn) {

      const saveAvailability = async () => {
        let data = {
          date,
          notes,
          is_available: true
        }

        await EventController.saveAvailability(data);
        this.getAvailability();
        this.modal_ref.close();
      }

      let date_events = events.filter(event=>event.calendar_date === date);

      let event_exists = date_events.length > 0;

      let exists_content = <View style={{alignSelf: 'center', textAlign: 'center', alignItems: 'center', justifyContent: 'center'}}>
        
            {is_mobile ? <Text style={{paddingHorizontal: 20, lineHeight: is_mobile ? 36 : 26, marginTop: 0, fontFamily: 'Comfortaa', fontSize: is_mobile ? 20 : 20, textAlign: 'center', marginBottom: 5}}>Event Exists</Text> : null}

            <Text style={{paddingHorizontal: 20, lineHeight: is_mobile ? 26 : 26, marginTop: is_mobile ? 80 : 80, fontFamily: 'Comfortaa', fontSize: 20, textAlign: 'center', marginBottom: 40}}>An event already exists for this date. Please RSVP.</Text>
        </View>

      let not_exists_content = <View style={{alignSelf: 'center', textAlign: 'center', alignItems: 'center'}}>
        
        {is_mobile ? <Text style={{paddingHorizontal: 20, lineHeight: is_mobile ? 36 : 26, marginTop: 0, fontFamily: 'Comfortaa', fontSize: is_mobile ? 20 : 20, textAlign: 'center', marginBottom: 5}}>Your availability</Text> : null}

        <Text style={{paddingHorizontal: 20, lineHeight: is_mobile ? 26 : 26, marginTop: is_mobile ? 80 : 80, fontFamily: 'Comfortaa', fontSize: is_mobile ? 16 : 16, textAlign: 'center', marginBottom: 40}}>Let us know whom you'd like to meet & if you're open to meeting others and any other notes.</Text>

      <View>
        <Input type="text" style={{height: 100, width: 300}} value={notes} onChangeText={notes => {
          this.setState({notes}, this.availabilityModal);
        }} multiline={true} placeholder={"Whom would you like to meet?"} />
      </View>

          <View style={{marginTop: is_mobile ? 20 : 20, alignSelf: 'center', textAlign: 'center'}}>
              <Button title={<Text style={{fontFamily: 'Ysabeau', fontSize: 16,color: 'white'}}>Save</Text>}
                    loading={false}
                    style={{
                      backgroundColor: '#e32652',
                      borderRadius: 3,
                      paddingTop: 5,
                      paddingBottom: 7,
                      width: 200,
                      paddingHorizontal: 5, marginBottom: 10}}
                    onPress={saveAvailability} />
          </View>
        </View>

      let the_content = event_exists ? exists_content : not_exists_content;

      this.modal_ref.setContent(the_content, is_mobile ? window_width : 500, '100vh', null, null, null, is_mobile ? null : <Text style={{paddingHorizontal: 20, lineHeight: is_mobile ? 36 : 26, marginTop: 0, fontFamily: 'Comfortaa', fontSize: is_mobile ? 20 : 20, textAlign: 'center', marginBottom: 5}}>Your availability</Text>)

    } else {
      this.modal_ref.setContent(<View style={{alignSelf: 'center', textAlign: 'center', alignItems: 'center'}}>
        
        <Text style={{paddingHorizontal: 20, lineHeight: is_mobile ? 36 : 30, marginTop: is_mobile ? 80 : 80, fontFamily: 'Comfortaa', fontSize: is_mobile ? 30 : 24, textAlign: 'center', marginBottom: 5}}>Please login or register to set your availability for brunch or dinner.</Text>

          <View style={{marginTop: is_mobile ? 20 : 20, alignSelf: 'center', textAlign: 'center'}}>
              <Button title={<Text style={{fontFamily: 'Ysabeau', fontSize: 16,color: 'white'}}>Login</Text>}
                    loading={false}
                    style={{
                      backgroundColor: '#e32652',
                      borderRadius: 3,
                      paddingTop: 5,
                      paddingBottom: 7,
                      width: 200,
                      paddingHorizontal: 5, marginBottom: 10}}
                    onPress={()=>{window.location.replace('/login'); this.modal_ref.close(); }} />
          </View>
          <View style={{marginTop: is_mobile ? 0 : 0, alignSelf: 'center', textAlign: 'center'}}>
              <Button title={<Text style={{fontFamily: 'Ysabeau', fontSize: 16,color: 'white'}}>Register for Free</Text>}
                    loading={false}
                    style={{
                      backgroundColor: 'black',
                      borderRadius: 3,
                      paddingTop: 5,
                      paddingBottom: 7,
                      width: 200,
                      paddingHorizontal: 5, marginBottom: 10}}
                    onPress={()=>{window.location.replace('/register'); this.modal_ref.close();}} />
          </View>
        </View>, is_mobile ? window_width : 500, '100vh', null, null, null, null)
    }
  }

  closeModal() {
    this.setState({
      update_notes: null,
    }, this.modal_ref.close())
  }

  updateAvailability() {

    let { availability, update_date, update_notes } = this.state;

    availability = availability && availability[update_date] ? availability[update_date] : null;

    let notes;
    if(availability) {
      ({notes} = availability)

      update_notes = update_notes ? update_notes : notes;
    }

    const saveAvailability = async () => {
      let data = {
        date: update_date,
        notes: update_notes,
        is_available: true
      }

      await EventController.saveAvailability(data);
      this.getAvailability();
      this.closeModal()
    }

    const deleteAvailability = async () => {
      let data = {
        date: update_date,
        is_available: false,
      }

      await EventController.saveAvailability(data);
      this.getAvailability();
      this.closeModal()
    }

    let window_width = Dimensions.get('window').width;
    let is_mobile = window_width < 500;
    this.modal_ref.setContent(<View style={{alignSelf: 'center', textAlign: 'center', alignItems: 'center'}}>
      
      {is_mobile ? <Text style={{paddingHorizontal: 20, lineHeight: is_mobile ? 36 : 26, marginTop: 0, fontFamily: 'Comfortaa', fontSize: is_mobile ? 20 : 20, textAlign: 'center', marginBottom: 5}}>Your availability</Text> : null}

      <Text style={{paddingHorizontal: 20, lineHeight: is_mobile ? 36 : 26, marginTop: is_mobile ? 80 : 80, fontFamily: 'Comfortaa', fontSize: is_mobile ? 16 : 16, textAlign: 'center', marginBottom: 40}}>Let us know whom you'd like to meet & if you're open to meeting others and any other notes.</Text>

     <View>
      <Input type="text" style={{height: 100, width: 300}} value={update_notes} onChangeText={update_notes => {
        this.setState({update_notes}, this.updateAvailability);
      }} multiline={true} placeholder={"Whom would you like to meet?"} />
     </View>

        <View style={{marginTop: is_mobile ? 20 : 20, alignSelf: 'center', textAlign: 'center'}}>
            <Button title={<Text style={{fontFamily: 'Ysabeau', fontSize: 16,color: 'white'}}>Update</Text>}
                  loading={false}
                  style={{
                    backgroundColor: '#e32652',
                    borderRadius: 3,
                    paddingTop: 5,
                    paddingBottom: 7,
                    width: 200,
                    paddingHorizontal: 5, marginBottom: 10}}
                  onPress={saveAvailability} />
            <Button title={<Text style={{fontFamily: 'Ysabeau', fontSize: 16, color: 'white'}}>Remove</Text>}
                  loading={false}
                  style={{
                    backgroundColor: 'black',
                    borderRadius: 3,
                    paddingTop: 5,
                    paddingBottom: 7,
                    width: 200,
                    paddingHorizontal: 5, marginBottom: 10}}
                  onPress={deleteAvailability} />
        </View>
      </View>, is_mobile ? window_width : 500, '100vh', null, null, this.closeModal, is_mobile ? null : <Text style={{paddingHorizontal: 20, lineHeight: is_mobile ? 36 : 26, marginTop: is_mobile ? 80 : 0, fontFamily: 'Comfortaa', fontSize: is_mobile ? 30 : 20, textAlign: 'center', marginBottom: 5}}>Your availability</Text>)
  }

  getPriceNote(price) {

    let { is_member } = this.state;

    return <View style={{flexDirection: 'row', width: '100%', justifyContent: 'space-between'}}>
      <View>
      <Text style={{fontFamily: 'Ysabeau', fontSize: 20 }}>Subtotal:</Text>
      </View>
      <View style={{flexDirection: 'column', alignItems: 'flex-end'}}>
        <Text style={{fontFamily: 'Ysabeau', fontSize: 20, }}>${price}.00</Text>
        {!is_member ? <TouchableOpacity style={{borderBottomWidth: 1, borderColor: '#1fb411'}} onPress={()=>{window.location.replace('/dashboard/subscription')}}>
          <Text style={{marginTop: 10, fontFamily: 'Comfortaa', fontSize: 16 }}>(with membership <Text style={{color: '#1fb411', marginLeft: 5, fontFamily: 'Ysabeau' , fontSize: 22}}>$0.00</Text>)</Text>
        </TouchableOpacity> : null}
      </View>
      </View>
  }

  async rsvp(event) {
    try {
    this.setState({rsvp_loading: true}, async () => {
      let { event_id } = event;
      let { update_phone } = this.state;
  
      let response = await PaymentController.rsvpMemberEvent({event_id, update_phone});
  
      if(response.url) {
        window.location.replace(response.url);
      } else {
        this.modal_ref.close();
        this.setState({
          rsvp_loading: false
        }, this.getAvailability)
      }
    })
    } catch(err) {
      console.log('errrr', err)
    }
  }

  async updateProfile() {
    let that = this;
    this.setState({updating_profile: true}, async () => {

      let { user, update_phone } = this.state;
      let profile = user && user.profile ? user.profile : {};

      profile = {
        ...profile,
        phone: update_phone
      }

      await UserController.saveProfile({profile});

      console.log('getting after save')

      user = {
        ...user,
        profile
      }

      this.setState({
        update_phone: null,
        updating_profile: false,
        user
      }, that.openEvent)
    })
  }

  updatePhone(update_phone) {
    if(update_phone.trim() !== '') {
      update_phone = Utilities.onlyNumbers(update_phone)
    }

    this.setState({update_phone}, this.openEvent)
  }

  async cancelRSVP() {
    this.setState({cancel_rsvp_loading: true}, async ()=> {
      let { event } = this.state;

      let { event_id } = event;
  
      await EventController.cancelEventRSVP({event_id});
  
      this.modal_ref.close();
      this.setState({
        rsvp_loading: false
      }, this.getAvailability)
    })
  }

  async openEvent() {
    let { places, save_not_rsvp_valid, isLoggedIn, is_member, user_id, profile_open, event, profile_attendee, user, update_phone, updating_profile } = this.state;
    let { attendees, type, price, event_id, place_id, rsvp_limit, big_event, event_type, virtual, is_free } = event;
    price = is_member ? 0 : price;

    console.log('user',user)

    let user_profile = user && user.profile ? user.profile : {};
    console.log('user_profile', user_profile)
    let phone = user_profile.phone;
    console.log('phone', phone)
    update_phone = update_phone || update_phone === '' ? update_phone : phone;

    if(big_event) {
      if(virtual) {
        window.open(`/virtual/${event_id}`)
      } else {
        window.open(`/event/${event_id}`)
      }
    } else {

      let {rsvps} = await UserController.getDateRsvpsForEvent({event_id})

      console.log('places', places)

      let place = places.filter(pl=>pl.id === place_id)
      place = place && place.length ? place[0].place_data : null

      console.log('place', place)

      attendees = attendees ? attendees : [];
      places = places ? places : [];
      let selected_city = 'Jersey City';

      let window_width = Dimensions.get('window').width;
      let is_mobile = window_width < 500;

      console.log('rsvps', rsvps)

      let event_component, sticky_footer;

      let on_close = this.closeModal;

      let rsvp_limit_reached = rsvps && rsvps.length >= rsvp_limit;

      if(isLoggedIn) {

        if(profile_open) {
          event_component = <Profile onPress={this.openBubble} attendee={profile_attendee} />
          on_close = ()=>{
            this.setState({
              profile_open: false
            }, this.openEvent)
          }
        } else {
          

          let rsvped = rsvps.filter(rsvp=>rsvp.user_id === user_id);
          rsvped = rsvped && rsvped.length ? true : false;

              event_component = <View style={{alignSelf: 'center', textAlign: 'center', alignItems: 'center', marginTop: is_mobile ? 0 : 0, paddingBottom: 250}}>
                  <View style={{marginBottom: 20}}>
                    <Text style={{fontFamily: 'Ysabeau', fontSize: 24}}>RSVP Limit {rsvp_limit}</Text>
                          
                  </View>

                <View style={{flexDirection: 'row', marginBottom: 20, width: '100%', flexWrap: 'wrap'}}>
                  {rsvps.map(event_rsvp=>{
                    let img_size = 50;
                    let { profile } = event_rsvp;
                    profile = profile  ? profile : `{"name": "Member", "user_id": null, "photo_url": "https://diningsocial-assets.s3.amazonaws.com/empty_profile.png"}`
                    let { photo_url, name } = JSON.parse(profile);
                    photo_url = photo_url ? photo_url : "https://diningsocial-assets.s3.amazonaws.com/empty_profile.png";

                    name = name ? name.split(' ')[0] : 'Member';

                    return <TouchableOpacity onPress={()=>{this.setState({profile_attendee: {profile}, profile_open: true}, this.openEvent)}} style={{flexDirection: 'column'}}>
                        <View style={{width: img_size, height: img_size, borderRadius: 100, backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', marginRight: 10, marginBottom: 10}}><Image style={{ width: img_size,
                        height: img_size,
                        borderRadius: 100}} source={{uri: photo_url ? photo_url : 'https://diningsocial-assets.s3.amazonaws.com/empty_profile.png'}} /></View>
                        <Text style={{fontFamily: 'Ysabeau', fontSize: 16, width: img_size, overflowWrap: 'break-word'}}>{name}</Text>
                        </TouchableOpacity>
                  })}
                </View>

                <View>
                
                <View style={{alignItems: 'center'}}>
                  <View>
                          <Text style={{fontFamily: 'Ysabeau', fontSize: 24}}>{Utilities.getEventTime(event.time)}</Text>
                          
                  </View>

                  <View>
                    <TouchableOpacity onPress={()=>{window.open(place.link)}}><Text style={{textDecorationLine: 'underline', fontFamily: 'Ysabeau', fontSize: 28}}>{place.restaurant_name}</Text></TouchableOpacity>
                    
                  </View>
                </View>
                <View style={{textAlign: 'left', marginVertical: 20}}>

                  

                  <View style={{flexDirection: 'row', alignItems: 'center', width: '100%', marginBottom: 10, marginTop: 20}}>
                    <View>
                      <Text style={{fontFamily: 'Comfortaa', fontSize: 40, lineHeight: 0, height: 20, marginRight: 5}}>.</Text>
                    </View>
                    <View style={{width: 'calc(100% - 25px'}}>
                      <Text style={{fontFamily: 'Ysabeau', fontSize: 18}}>Ask for DiningSocial at the restaurant.</Text>
                    </View>
                  </View>

                  <View style={{flexDirection: 'row', alignItems: 'flex-start', width: '100%', marginBottom: 10}}>
                    <View>
                      <Text style={{fontFamily: 'Comfortaa', fontSize: 40, lineHeight: 0, height: 20, marginRight: 5}}>.</Text>
                    </View>
                    <View style={{width: 'calc(100% - 25px'}}>
                      <Text style={{fontFamily: 'Ysabeau', fontSize: 18}}>Since this event is free, it gets a bit harder making sure people who RSVP will attend. The day before the event, we'll send out an email/text to make sure you're going to make it. The first 6 people to respond will be finalized for the reservation.</Text>
                    </View>
                  </View>

                  <View style={{flexDirection: 'row', alignItems: 'flex-start', width: '100%'}}>
                    <View>
                      <Text style={{fontFamily: 'Comfortaa', fontSize: 40, lineHeight: 0, height: 20, marginRight: 5}}>.</Text>
                    </View>
                    <View style={{width: 'calc(100% - 25px'}}>
                      <Text style={{fontFamily: 'Ysabeau', fontSize: 18}}><a target="_blank" href="https://apps.apple.com/app/id392796698">GroupMe</a> chat will open 30 mins before your {event_type} so that you can locate your group. Enter your number below if you'd like to be notified.</Text>
                    </View>
                  </View>

                  <View style={{width: '100%', marginTop: 20, alignContent: 'center', alignItems: 'center', flexDirection: 'row', justifyContent: 'center'}}>
                    <Input type="text" style={{marginRight: 10}} value={update_phone} onChangeText={this.updatePhone} placeholder={"ex: 2344325433"} /> {rsvped ? <Button 
                        title={<Text style={{fontFamily: 'Ysabeau', fontSize: 14,color: 'white'}}>Update</Text>}
                        loading={updating_profile}
                        inactive={update_phone && !Utilities.isPhoneValid(update_phone)}
                        style_inactive={{
                              backgroundColor: 'gray',
                              padding: 0,
                              borderRadius: 3}}
                            style={{
                              backgroundColor: '#e32652',
                              padding: 0,
                              borderRadius: 3}}

                            onPress={this.updateProfile} /> : null }
                  </View>
                </View>
                
                </View>
                </View>

              sticky_footer = rsvped ? <View style={{paddingVertical: 20, width: '100%', alignSelf: 'center', textAlign: 'center', alignItems: 'center', paddingHorizontal: 20 }}>
              <View style={{marginBottom: 10}}>
              <Text style={{fontFamily: 'Ysabeau', fontSize: 20,color: '#11dd76'}}>You're going!</Text>
              </View>
              <Button 
                disable_imediately={true}
                title={<Text style={{fontFamily: 'Ysabeau', fontSize: 16,color: 'white'}}>CANCEL</Text>}
                loading={false}
                    style={{
                      backgroundColor: '#e32652',
                      borderRadius: 3,
                      paddingTop: 5,
                      paddingBottom: 7,
                      width: 200,
                      paddingHorizontal: 5, marginBottom: 10}}

                    onPress={this.cancelRSVP} />
            </View> : <View style={{paddingVertical: 20, width: '100%', alignSelf: 'center', textAlign: 'center', alignItems: 'center', paddingHorizontal: 20, justifyContent: 'center' }}>
                

              {is_free ? null : this.getPriceNote(price)}
              <View style={{marginTop: 20}}>
              <Text style={{fontFamily: 'Ysabeau', fontSize: 16,color: '#e32652'}}>{save_not_rsvp_valid ? "Please fix the errors above." : null}</Text>

              <Button 
                title={<Text style={{fontFamily: 'Ysabeau', fontSize: 16,color: 'white'}}>RSVP</Text>}
                loading={false}
                inactive={update_phone && !Utilities.isPhoneValid(update_phone)}
                disable_imediately={true}
                style_inactive={{
                      backgroundColor: 'gray',
                      borderRadius: 3,
                      paddingTop: 5,
                      paddingBottom: 7,
                      width: 200,
                      paddingHorizontal: 5, marginBottom: 10}}
                    style={{
                      backgroundColor: '#e32652',
                      borderRadius: 3,
                      paddingTop: 5,
                      paddingBottom: 7,
                      width: 200,
                      paddingHorizontal: 5, marginBottom: 10}}

                    onPress={()=>this.rsvp(event)} />
              </View>


            </View>

            sticky_footer = !rsvp_limit_reached ? sticky_footer : <View style={{paddingVertical: 20, width: '100%', alignSelf: 'center', textAlign: 'center', alignItems: 'center', paddingHorizontal: 20 }}>
            <View style={{}}>
            <Text style={{fontFamily: 'Ysabeau', fontSize: 20,color: '#11dd76'}}>RSVP Limit Reached</Text>
            </View>
          </View>;
        }


        this.modal_ref.setContent(event_component, is_mobile || big_event ? window_width : 500, '100vh', null, null, on_close, <Text style={{fontFamily: 'Ysabeau', fontSize: 26, textAlign: 'center', marginBottom: 5}}>Join Us</Text>, sticky_footer);
      } else {
        this.modal_ref.setContent(<View style={{alignSelf: 'center', textAlign: 'center', alignItems: 'center'}}>
          
          <Text style={{paddingHorizontal: 20, lineHeight: is_mobile ? 36 : 30, marginTop: is_mobile ? 80 : 80, fontFamily: 'Comfortaa', fontSize: is_mobile ? 30 : 24, textAlign: 'center', marginBottom: 5}}>Please login or register to view our calendar.</Text>

            <View style={{marginTop: is_mobile ? 20 : 20, alignSelf: 'center', textAlign: 'center'}}>
                <Button title={<Text style={{fontFamily: 'Ysabeau', fontSize: 16,color: 'white'}}>Login</Text>}
                      loading={false}
                      style={{
                        backgroundColor: '#e32652',
                        borderRadius: 3,
                        paddingTop: 5,
                        paddingBottom: 7,
                        width: 200,
                        paddingHorizontal: 5, marginBottom: 10}}
                      onPress={()=>{window.location.replace('/login'); this.modal_ref.close(); }} />
            </View>
            <View style={{marginTop: is_mobile ? 0 : 0, alignSelf: 'center', textAlign: 'center'}}>
                <Button title={<Text style={{fontFamily: 'Ysabeau', fontSize: 16,color: 'white'}}>Register for Free</Text>}
                      loading={false}
                      style={{
                        backgroundColor: 'black',
                        borderRadius: 3,
                        paddingTop: 5,
                        paddingBottom: 7,
                        width: 200,
                        paddingHorizontal: 5, marginBottom: 10}}
                      onPress={()=>{window.location.replace('/register'); this.modal_ref.close();}} />
            </View>
          </View>, is_mobile ? window_width : 500, '100vh', null, null, null, null)
      }
    }
  }

  handleNextPress = (width) => {
    const { currentIndex, event_dining_photos } = this.state;

    const nextIndex = currentIndex + 1 < event_dining_photos.length ? currentIndex + 1 : 0;


    console.log('nextIndex', nextIndex)

    Animated.timing(this.translateX, {
      toValue: -width * nextIndex,
      duration: 300,
      useNativeDriver: true,
    }).start();
    this.setState({ currentIndex: nextIndex });
  };

  handlePrevPress = (width) => {
    const { currentIndex, event_dining_photos } = this.state;

    const prevIndex = currentIndex - 1 >= 0 ? currentIndex - 1 : event_dining_photos.length - 1;
    Animated.timing(this.translateX, {
      toValue: -width * prevIndex,
      duration: 300,
      useNativeDriver: true,
    }).start();
    this.setState({ currentIndex: prevIndex });
  };

  handleNextPressM = (width) => {
    const { currentIndexM, member_dining_photos } = this.state;

    const nextIndex = currentIndexM + 1 < member_dining_photos.length ? currentIndexM + 1 : 0;


    console.log('nextIndex', nextIndex)

    Animated.timing(this.translateXM, {
      toValue: -width * nextIndex,
      duration: 300,
      useNativeDriver: true,
    }).start();
    this.setState({ currentIndexM: nextIndex });
  };

  handlePrevPressM = (width) => {
    const { currentIndexM, member_dining_photos } = this.state;

    const prevIndex = currentIndexM - 1 >= 0 ? currentIndexM - 1 : member_dining_photos.length - 1;
    Animated.timing(this.translateXM, {
      toValue: -width * prevIndex,
      duration: 300,
      useNativeDriver: true,
    }).start();
    this.setState({ currentIndexM: prevIndex });
  };

  getEventDiningPanels() {

    let { is_mobile, event_dining_photos, member_dining_photos, currentIndex } = this.state;

    return  <View  ref={node=>this.typesRef=node} style={{flexDirection: 'column', margin: 'auto', marginBottom: 200}}>
      <View ref={node=>this.explore=node} style={{flexDirection: 'row', borderColor: '#999999', borderBottomWidth: 1, borderTopWidth: 1}}><Text style={[style.section_title, {fontSize: 40}]}>Event Types</Text></View>
    <View style={{ width: is_mobile ? 'calc(100vw - 20px)' : '100%', borderBottomWidth: 1, borderColor: '#9d9b9b'}}>
      <View style={[{position: 'relative', overflow: 'hidden', flexDirection: is_mobile ? 'column' : 'row'}]}>
        <View style={{position: 'relative', width: is_mobile ? 'unset' : 495, overflow: 'hidden', margin: is_mobile ? 20 : 40}}>
          <Animated.View style={[{flexDirection: 'row',
              width: 495 * member_dining_photos.length
              }, { transform: [{ translateX: this.translateXM }] }]}>
              {member_dining_photos.map((photo, index) => {
              return photo.type === 'movie' ?
                  <Video
                    source={{ uri: photo.uri }}
                    style={[style.our_dining_image, {width: 495
              }]}
                    videoStyle={{position: 'relative'}}
                    useNativeControls={true}
                    spee
                    shouldPlay={currentIndexM === index}
                    resizeMode="cover"
                    rate={.7}
                    volume={0}
                    isMuted={true}
                  />
              :
              <Image key={index} source={{ uri: photo.uri }} style={[style.our_dining_image, {width: 495
              }]} resizeMode='cover' />
            })}
          </Animated.View>
          <View style={{flexDirection: 'row', margin: 'auto', justifyContent: 'space-between', marginTop: 10, width: 120, position: 'absolute', bottom: 40, left: 'calc(50% - 60px)'}}>
            <Button style={{backgroundColor: '#e32652', padding: 0, margin: 0, width: 28, height: 29}} title={<FontAwesome5 style={{justifyContent: 'center', alignSelf: 'center'}} name="caret-square-left" size={34} color="white" />}
            loading={false} onPress={()=>this.handlePrevPressM(495)} />
            <Button style={{backgroundColor: '#e32652', padding: 0, margin: 0, width: 28, height: 29}} title={<FontAwesome5 style={{justifyContent: 'center', alignSelf: 'center'}} name="caret-square-right" size={34} color="white" />}
            loading={false} onPress={()=>this.handleNextPressM(495)} />
          </View>
        </View>

        <View style={{width: is_mobile ? '100%' : 500, alignContent: 'center', alignItems: 'center', borderLeftWidth: is_mobile ? 0 : 1, borderColor: '#9d9b9b'}}>
          <View style={{margin: 40, marginTop: 30}}>
            <Text style={[style.dining_sub_title, {width: '100%'}]}>
              Member Dining
            </Text>
            <Text style={style.dining_text}>
              Member Dining is our main forte. DiningSocial brings members together in groups of 3-6 over dinner or brunch based around member availability. Our aim is to create a social atmosphere where you're always welcome and get to form connections naturally over time. As we grow, our events will be mixed genders and mixed ages. We're aiming to get to a point where we can split up age groups between 20s/30s and 40s+ based on member requests.
            </Text>
          </View>
        </View>
      </View>
    </View>
    <View style={{flexDirection: 'row', width: is_mobile ? 'calc(100vw - 20px)' : '100%', borderBottomWidth: 1, borderColor: '#9d9b9b'}}>
      <View style={[{position: 'relative', overflow: 'hidden', flexDirection: is_mobile ? 'column' : 'row', width: is_mobile ? 'calc(100vw - 20px)' : '100%'}]}>
        <View style={{width: is_mobile ? '100%' : 500, borderRightWidth: is_mobile ? 0 : 1, borderColor: '#9d9b9b'}}>
          <View style={{margin: 40}}>
            <Text style={style.dining_sub_title}>
              Event Dining
            </Text>
            <Text style={style.dining_text}>
              We also occassionally host bigger events. We like to experiment with the structure and learn more of what our members would like. Currently, our fan favorite is our monthly 10-40 person dinner / brunch event. Read more on our next event page.
            </Text>
            <View style={{flexDirection: 'row', justifyContent: is_mobile ? 'center' : 'unset'}}>
              <TouchableOpacity onPress={()=>{track('Big Dinner - Event Dining'); window.location.replace('/event/567d059b-33e3-496f-a995-f2373e5e6319')}} ><Text style={[style.four_buttons_button, {width: 300, color: 'black', borderColor: 'black', marginTop: 20, textAlign: 'center'}]}>Our Next Event Brunch</Text></TouchableOpacity>
            </View>
          </View>
        </View>
        <View style={{position: 'relative', width: is_mobile ? 'unset' : 495, overflow: 'hidden', margin: is_mobile ? 20 : 40}}>
          <Animated.View style={[{flexDirection: 'row',
              width: 495 * event_dining_photos.length
              }, { transform: [{ translateX: this.translateX }] }]}>
              {event_dining_photos.map((photo, index) => {
              return photo.type === 'movie' ?
                  <Video
                    source={{ uri: photo.uri }}
                    style={[style.our_dining_image, {width: 495
              }]}
                    videoStyle={{position: 'relative'}}
                    useNativeControls={true}
                    spee
                    shouldPlay={currentIndex === index}
                    resizeMode="cover"
                    rate={.7}
                    volume={0}
                    isMuted={true}
                  />
              :
              <Image key={index} source={{ uri: photo.uri }} style={[style.our_dining_image, {width: 495
              }]} resizeMode='cover' />
            })}
          </Animated.View>
          <View style={{flexDirection: 'row', margin: 'auto', justifyContent: 'space-between', marginTop: 10, width: 120, position: 'absolute', bottom: 40, left: 'calc(50% - 60px)'}}>
            <Button style={{backgroundColor: '#e32652', padding: 0, margin: 0, width: 28, height: 29}} title={<FontAwesome5 style={{justifyContent: 'center', alignSelf: 'center'}} name="caret-square-left" size={34} color="white" />}
            loading={false} onPress={()=>this.handlePrevPress(495)} />
            <Button style={{backgroundColor: '#e32652', padding: 0, margin: 0, width: 28, height: 29}} title={<FontAwesome5 style={{justifyContent: 'center', alignSelf: 'center'}} name="caret-square-right" size={34} color="white" />}
            loading={false} onPress={()=>this.handleNextPress(495)} />
          </View>
        </View>
      </View>
    </View>
  </View>
  }

  render() {
    let { dates, selected_index, availability, events, dinners, is_mobile } = this.state;

    let event_dining_panels = this.getEventDiningPanels();

    const nowESTOne = moment.tz('America/New_York').startOf('day');

    let to_scroll = nowESTOne.day() > 3;

    let elem = document.querySelector('div[data-0="salamander"]');
    if(elem && to_scroll) {
      elem.scrollLeft = elem.scrollWidth;
    }

    return (<View style={style.explainer_section}>
        <Modal ref={node=>this.modal_ref=node} putInParent={this.props.putInParent} />
        <View style={{backgroundColor: '#f7f8fc'}}>
            <View ref={node=>this.explore=node} style={{flexDirection: is_mobile ? 'column' : 'row', borderColor: '#999999', borderBottomWidth: 1}}>
              <Text style={style.section_title}>Our Calendar</Text>
              <View style={{justifyContent: 'center', alignContent: 'center', margin: 20, marginTop: is_mobile ? 0 : 20, width: is_mobile ? '90%' : 600, marginBottom: is_mobile ? 50 : 30}}>
                    <Text style={{fontFamily: 'Ysabeau', fontSize: 18 }}>Use the calendar below to... </Text>
                    <Text style={{marginTop: 10, fontFamily: 'Ysabeau', fontSize: 18}}>1. Mark your availability.</Text>
                    <Text style={{marginTop: 10, fontFamily: 'Ysabeau', fontSize: 18}}>2. RSVP for available events. </Text>
                    <Text style={{marginTop: 10, fontFamily: 'Ysabeau', fontSize: 18}}>To mark your availability click the 'Available' checkbox. Let us know whether you're available for a 6:30pm dinner or a 1:30pm brunch and any other notes. We place an event on a particular day depending on whether members are available. </Text>
                    <Text style={{marginTop: 10, fontFamily: 'Ysabeau', fontSize: 18}}>
                      Once an event is put on the calendar, feel free to RSVP! <TouchableOpacity onPress={()=>{this.typesRef.scrollIntoView()}} style={{marginLeft: 20}}><Text style={{fontFamily: 'Ysabeau', 
                      fontSize: 18, 
                      color: 'black', 
                      border: 'solid 1px black', 
                      paddingVertical: 5, 
                      paddingHorizontal: 20,}}>Read More</Text></TouchableOpacity>
                    </Text>
              </View>
            </View>
            <View style={{width: is_mobile ? '95%' : 924, margin: 'auto'}}>
              <View style={{flexDirection: 'row', margin: 'auto', justifyContent: 'space-between', marginTop: 10, width: 120, marginTop: 40, marginBottom: is_mobile ? 40 : 'unset'}}>
                          <Button style={{backgroundColor: '#e32652', padding: 0, margin: 0, width: 28, height: 29}} title={<FontAwesome5 style={{justifyContent: 'center', alignSelf: 'center'}} name="caret-square-left" size={34} color="white" />}
                          loading={false} onPress={this.prev} />
                          <Button style={{backgroundColor: '#e32652', padding: 0, margin: 0, width: 28, height: 29}} title={<FontAwesome5 style={{justifyContent: 'center', alignSelf: 'center'}} name="caret-square-right" size={34} color="white" />}
                          loading={false} onPress={this.next} />
                        </View>
              <View style={{width: is_mobile ? '100%' : 924}}>
              
                {dates.map((month, i)=><View style={{width: is_mobile ? '100%' : 924, margin: is_mobile ? 'unset' : 'auto'}}>
                  {i === selected_index ?
                  <>
                  <View><Text style={{fontFamily: 'Ysabeau', fontSize: 26, marginBottom: 40, backgroundColor: '#f2e5e5', width: 150, textAlign: 'center', borderRadius: 20, padding: 10}}>{Utilities.getMonthName((new Date(month[0][0].date).getMonth() + 1))}</Text></View>
                  {is_mobile ? <View style={{height: 50, width: '100%', justifyContent: 'center', alignContent: 'center', alignItems: 'center'}}>
                      <FontAwesome name="arrows-h" size={30} />    
                    </View> : null}
                  <View dataSet={['salamander']} key={'salamander'} style={{flexDirection: 'column', width: is_mobile ? '100%' : 924, margin: is_mobile ? 'unset' : 'auto', overflow: is_mobile ? 'scroll' : 'unset'}}>
                    
                    <View style={{flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10, width: is_mobile ? 'fit-content' : 'unset'}}>

                      <Text style={{width: 130, fontFamily: 'Ysabeau', fontSize: 18, textAlign: 'center'}}>Sun</Text>
                      <Text style={{width: 130, fontFamily: 'Ysabeau', fontSize: 18, textAlign: 'center'}}>Mon</Text>
                      <Text style={{width: 130, fontFamily: 'Ysabeau', fontSize: 18, textAlign: 'center'}}>Tues</Text>
                      <Text style={{width: 130, fontFamily: 'Ysabeau', fontSize: 18, textAlign: 'center'}}>Wed</Text>
                      <Text style={{width: 130, fontFamily: 'Ysabeau', fontSize: 18, textAlign: 'center'}}>Thurs</Text>
                      <Text style={{width: 130, fontFamily: 'Ysabeau', fontSize: 18, textAlign: 'center'}}>Fri</Text>
                      <Text style={{width: 130, fontFamily: 'Ysabeau', fontSize: 18, textAlign: 'center'}}>Sat</Text>
                    </View>

                    {month.map((week, week_i)=>{

                      let day_num = (new Date(week[week.length - 1].date)).getDay();

                      
                      if(week.length < 7) {
                        let new_week = _.cloneDeep(week);
                        if(week_i) {
                          for(var i = 0; i < 7 - week.length; i++) {
                            new_week.push({empty: true})
                          }
                        } else {
                          for(var i = 0; i < 7 - week.length; i++) {
                            new_week = [{empty: true}].concat(new_week);
                          }
                        }
                        week = new_week;
                      }

                      console.log('weekweek', week)
                      const nowEST = moment.tz('America/New_York').startOf('day');

                      let last_date_of_week = moment.tz(week[week.length - 1].date, 'America/New_York');
                      let is_week_gray = last_date_of_week.isBefore(nowEST, 'day');
                      
                      return <View style={{flexDirection: 'row', marginBottom: 10, justifyContent: 'flex-end', width: is_mobile ? 'fit-content' : 'unset'}}>
                      {week.map(date=>{

                        let {empty} = date;

                        if(empty) {
                          return <View style={{width: 130, height: is_week_gray ? 30 : 300, marginRight: 2, borderColor: '#e32652', backgroundColor: 'white'}}></View>
                        }

                        // Parse the date from the input and set it to EST
                        const let_the_date = moment.tz(date.date, 'America/New_York');

                        // Check if the date is before or equal to today
                        let is_gray = is_week_gray;
                        
                        if(!is_gray) {
                          is_gray = let_the_date.isBefore(nowEST, 'day');
                        }

                        let the_date = new Date(date.date + " 15:00:00");

                        let date_availability = availability ? availability[date.date] : null;

                        let is_available = false;

                        if(date_availability && availability) {
                          ({is_available} = date_availability);
                        }

                        let date_events = events ? events.filter(event=>event.calendar_date === date.date) : [];

                        let brunch = date_events.filter(event=>event.event_type==='brunch');
                        brunch = brunch && brunch.length ? brunch[0] : null;

                        let dinner = date_events.filter(event=>event.event_type==='dinner');
                        dinner = dinner && dinner.length ? dinner[0] : null;

                        let going_brunch = brunch && dinners ? dinners.filter(dinner=>dinner.event_id === brunch.event_id) : false;
                        going_brunch = going_brunch && going_brunch.length ? true : false;

                        let going_dinner = dinner && dinners ? dinners.filter(d=>d.event_id === dinner.event_id) : false;
                        going_dinner = going_dinner && going_dinner.length ? true : false;

                        return <View style={{width: 130, height: is_week_gray ? 30 : 300, marginRight: 2, borderColor: '#e32652', backgroundColor: is_gray ? (is_week_gray ? '#e9e6e6' : '#cbc6c6') : '#f2e5e5'}}>
                          <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                            <Text style={{fontFamily: 'Ysabeau', fontSize: 18, marginLeft: 5}}>{the_date.getDate()}</Text>
                          </View>

                          {is_gray ? 
                            <View style={{width: '100%', height: 70 }}></View> : 
                          
                            <View style={{width: '100%', height: 70, backgroundColor:  is_available ? 'unset' : 'unset', borderColor: '#e32652', borderTop: 1, borderBottom: 1, justifyContent: 'center' }}>
                            {!is_available ?
                            <View style={{ width: '100%', justifyContent: 'center', alignContent: 'center'}}>
                              <Button style={{backgroundColor: 'white', paddingTop: 7, paddingBottom: 4, width: 118, alignSelf: 'center', alignItems: 'center', textAlign: 'center'}} title={<Check check_style={{hilight_color: {borderColor: 'wheat'}, border_style: {borderWidth: 3, borderStyle: 'inset'}}} label={<Text style={{fontFamily: 'Comfortaa', fontSize: 14, color: 'black',  textAlign: 'center', alignItems: 'center'}}>Available</Text>} onCheck={()=>{this.setState({date: date.date}, this.availabilityModal)}} checked={false} />}
                            loading={false} onPress={()=>{this.setState({date: date.date}, this.availabilityModal)}} />
                            </View> : <View style={{ width: '100%', justifyContent: 'center', alignContent: 'center', alignItems: 'center'}}>
                              <Button style={{backgroundColor: '#e32652', paddingTop: 7, paddingBottom: 4, width: 118, alignSelf: 'center', textAlign: 'center'}} title={<Check check_style={{hilight_color: {borderColor: '#23f5f3', height: 23, marginTop: -2}, border_style: {borderWidth: 3, borderStyle: 'inset', borderColor: '#23f5f3'}}} label={<Text style={{fontFamily: 'Comfortaa', fontSize: 14, color: 'white',  textAlign: 'center', alignItems: 'center'}}>Available</Text>} onCheck={()=>{this.setState({update_date: date.date}, this.updateAvailability)}} checked={true} />}
                            loading={false} onPress={()=>{this.setState({update_date: date.date}, this.updateAvailability)}} />
                            </View>}
                          </View>}

                          <View style={{width: '100%', height: 70, marginTop: 3}}>
                            {brunch ? <TouchableOpacity onPress={()=>{this.setState({event: brunch}, this.openEvent)}} style={{ width: '100%', borderLeftWidth: 5, borderLeftColor: '#e32652', backgroundColor: going_brunch ? '#110a0a' : (brunch.big_event ? '#3b4a81': 'black'), borderColor: going_brunch ? '#e32652' : 'black' }}>
                              <>
                                {going_brunch ?  <Text style={{padding: 10, fontFamily: 'Comfortaa', fontSize: 12, color: '#e32652', textAlign: 'center'}}>You're going!</Text> : null}
                                <Text style={{padding: 10,paddingBottom: brunch.big_event ? 10 : 0, fontFamily: 'Comfortaa', fontSize: 16, color: going_brunch ? '#e32652' : 'white', textAlign: 'center'}}>{Utilities.getEventTime(brunch.time)} {brunch.big_event ? 'Group ': ''} Brunch</Text>
                                <Text style={{paddingBottom: 10, fontFamily: 'Comfortaa', fontSize: 12, color: 'white', textAlign: 'center'}}>({brunch.city})</Text>
                              </>
                              
                            </TouchableOpacity> : null}
                          </View>
                          <View style={{width: '100%', height: 10}}>
                            
                          </View>
                          <View style={{width: '100%', height: 70, marginTop: 3}}>
                            {dinner ? <TouchableOpacity onPress={()=>{this.setState({event: dinner}, this.openEvent)}} style={{ width: '100%',  borderLeftWidth: 5, borderLeftColor: '#e32652',  backgroundColor: going_dinner ? '#110a0a' : (dinner.big_event ? (dinner.virtual ? '#31d5f6' : '#3b4a81'): 'black'), borderColor: going_dinner ? '#e32652' : 'black' }}>
                              <>
                                {going_dinner ?  <Text style={{padding: 10, fontFamily: 'Comfortaa', fontSize: 12, color: '#e32652', textAlign: 'center'}}>You're going!</Text> : null}
                                <Text style={{padding: 10, paddingBottom: dinner.big_event ? 10 : 0, fontFamily: 'Comfortaa', fontSize: 16, color: going_dinner ? '#e32652' : (dinner.virtual ? '#191971' : 'white'), textAlign: 'center'}}>{Utilities.getEventTime(dinner.time)}  {dinner.virtual ? 'Virtual ': ' '}{dinner.big_event ? 'Group ': ''}Dinner</Text>
                                {!dinner.big_event  ? <Text style={{paddingBottom: 10, fontFamily: 'Comfortaa', fontSize: 12, color: 'white', textAlign: 'center'}}>({dinner.city})</Text> : null}
                              </>
                            </TouchableOpacity> : null}
                          </View>

                        </View>})}
                    </View>})}
                  </View></> : null}
                </View>)}
              </View>
            </View>
            <View style={{marginTop: 40}}></View>
            {event_dining_panels}
        </View>

      </View>
    );
  }
}

function Calendar(props) {
  if (Platform.OS === 'web') {
    let navigate = useNavigate();

    return <Calendar_Base {...props} navigate={navigate} />
  } else {
    return <Calendar_Base {...props} navigate={props.navigation.push} />
  }
}

export default Calendar;
