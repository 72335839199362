import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { StyleSheet, View, TouchableOpacity, Platform } from 'react-native';
import { Icon, Colors, Text } from '../components';
import { style } from './style';

class Check extends Component {

  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    let { checked, onCheck, label, check_style } = this.props;

    let check_wrapper, check_text, hilight_color, border_color, border_style;
    hilight_color = {}, border_color = {};
    if(check_style && check_style.check_wrapper) {
      check_wrapper = check_style.check_wrapper;   
    }

    if(check_style && check_style.hilight_color) {
      hilight_color = check_style.hilight_color;
      border_color = {borderColor: hilight_color.borderColor}
    }

    if(check_style && check_style.check_text) {
      check_text = check_style.check_text;   
    }

    if(check_style && check_style.border_style) {
      border_style = check_style.border_style;   
    }

    console.log('check_wrapper', check_wrapper)
    
    return (
      <TouchableOpacity style={check_wrapper} onPress={onCheck}>
          {checked ? 
            <View style={style.check_text_wrapper}>
              <Text style={[style.check, style.check_text_checked, hilight_color, border_style]}>&#x2713;</Text>
              <View style={{marginLeft: 10, flexWrap: 'wrap'}}><Text style={check_text}>{label}</Text></View>
            </View> : 
            <View style={style.check_text_wrapper}>
              <Text style={[style.check, style.check_text_unchecked, border_color, border_style]}></Text>
              <View style={{marginLeft: 10, flexWrap: 'wrap'}}><Text style={check_text}>{label}</Text></View>
            </View>}
      </TouchableOpacity>
    );
  }
}

export default Check
