import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { StyleSheet, View, TouchableOpacity, Dimensions } from 'react-native';
import { Text } from '../components';
import Icon from 'react-native-vector-icons/FontAwesome';
let width = Dimensions.get('window').width;

class Pager extends Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      search_results: props.search_results,
      num_per_page: props.num_per_page
    }
  }

  componentDidUpdate() {
    if(this.props.search_results.length !== this.state.search_results.length || this.props.page !== this.state.page) {
      this.setState({
        page: this.props.page,
        search_results: this.props.search_results
      })
    }
  }

  render() {
    let { page, search_results, num_per_page } = this.state;

    const goPage = (page) => {
      this.setState({page});
      this.props.goPage(page);
    }

    let page_element = <Text style={style.page_number}>{page+1}</Text>
    let prev = page !== 0 ? 
        <TouchableOpacity onPress={()=>goPage(page-1)}>
          <Text style={style.pager_button_enabled}><Icon color="white" name="arrow-left" size={20} /></Text>
        </TouchableOpacity> : 
        <Text style={style.pager_button_disabled}><Icon name="arrow-left" color={'white'} size={20} /></Text>;


    let next = search_results.length === num_per_page ? 
        <TouchableOpacity onPress={()=>goPage(page+1)}>
          <Text style={style.pager_button_enabled}><Icon name="arrow-right" color="white" size={20} /></Text>
        </TouchableOpacity> : 
        <Text style={style.pager_button_disabled}><Icon name="arrow-right" color="white" size={20} /></Text>;

    return <View style={style.pager_wrapper}>
      {prev}{page_element}{next}
    </View>
  }

}

const style = StyleSheet.create({
  pager_wrapper: {
    alignSelf: 'flex-end',
    flexDirection: 'row', 
    marginBottom: 20,
    width: 100,
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  page_number: {
    fontFamily: 'Ysabeau',
    fontSize: 20,
    paddingHorizontal: 10
  },
  pager_button_enabled: {
    backgroundColor: '#e32652',
    borderRadius: 10,
    paddingVertical: 5,
    paddingHorizontal: 10,
    cursor: 'pointer'
  },
  pager_button_disabled: {
    backgroundColor: '#ecd6db',
    borderRadius: 10,
    paddingVertical: 5,
    paddingHorizontal: 10
  },
});

export default Pager
