import { StyleSheet, Dimensions } from 'react-native';

let width = Dimensions.get('window').width;
let height = Dimensions.get('window').height;

let global_style = {
  requests_section_wrapper: {
    marginTop: 40,
    flexDirection: 'column',
    marginBottom: width > 500 ? 40 : 0
  },
  one_request_wrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 10,
    alignItems: 'center',
    alignContent: 'center'
  },
  requests_wrapper: {
    backgroundColor: '#e5e5f2',
    padding: 10,
    borderRadius: 3,
  },
  rsvp_requests_title: {
    fontFamily: 'Comfortaa',
    fontSize: 20,
    marginBottom: 40,
    marginBottom: 10
  },
  info_bubble: {
    backgroundColor: '#0dc6e3',
    borderRadius: 100,
    color: 'white',
    fontFamily: 'Comfortaa',
    paddingHorizontal: 7,
    paddingTop: 4,
    paddingBottom: 2,
    marginLeft: 10,
    fontSize: 12
  },
  button_text: {
    fontFamily: 'Ysabeau',
    fontSize: 17,
    color: 'white'
  },
  x_wrapper: {
    position: 'absolute',
    top: 0,
    right: 10,
  },
  invited_x: {
    fontFamily: 'Ysabeau',
    fontSize: 20
  },
  profile_name: {
    fontFamily: 'Ysabeau',
    marginLeft: 10,
    fontSize: 16
  },
  invited_wrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  profile_image: {
    width: 50,
    height: 50,
    borderRadius: 100,
    borderWidth: 2
  },
  profile_image_wrapper: {
    width: 50,
    height: 50,
  },
  profile_wrapper: {
    position: 'relative',
    flexDirection: 'row', 
    width: 'calc(50% - 20px)', 
    padding: 10, 
    margin: 10,
    borderRadius: 10,
    backgroundColor: '#7c7e9d',
    alignItems: 'center'
  },
  modal_header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 20
  },
  modal_title: {
    fontFamily: 'Ysabeau',
    fontSize: 30
  },
  edit_button_wrapper: {
    marginTop: width > 500 ? 'unset' : 40,
    width: 300, 
    alignItems: 'center', 
    justifyContent: width > 500 ? 'flex-end' : 'center', 
    alignSelf: width > 500 ? 'flex-end' : 'center'
  },
  publish_button_wrapper: {
    flexDirection: 'row', width: 300, alignItems: 'center', 
    justifyContent: width > 500 ? 'flex-end' : 'center', 
    alignSelf: width > 500 ? 'flex-end' : 'center'
  },
  buttons_wrapper: {
    flexDirection: 'column', 
    alignSelf: width > 500 ? 'flex-end' : 'center', 
    flex: 1
  },
  bottom_wrapper: {
    flexDirection: width > 500 ? 'row' : 'column'
  },
  explanation_bottom: {
    fontFamily: 'Ysabeau',
    fontSize: 16
  },
  section_1_wrapper: {
    flexDirection: 'column', 
    flex: width > 500 ? 1 : 'unset', 
    display: width > 500 ? 'flex' : 'block',
    marginRight: width > 500 ? 40 : 0,
    marginBottom: width > 500 ? 0 : 10
  },
  section_2_wrapper: {
    flexDirection: 'column', 
    flex: width > 500 ? 1 : 'unset', 
    display: width > 500 ? 'flex' : 'block',
    backgroundColor: '#d5d5e6', 
    borderRadius: 5, 
    padding: 20, 
  },
  section: {
    backgroundColor: '#d5d5e6', 
    borderRadius: 5, 
    padding: 20, 
    marginTop: 20
  },
  toggle_button: {
    fontFamily: 'Ysabeau',
    fontSize: 16,
    marginRight: 20,
    paddingVertical: 10,
    paddingHorizontal: 50,
    borderRadius: 5,
    backgroundColor: 'gray',
    color: 'white'
  },
  toggle_button_selected: {
    backgroundColor: '#0dc6e3',
  },
  age_wrapper: {
    marginRight: 20
  },
  age_drop_wrapper: {
    marginTop: 5,
    flexDirection: width > 500 ? 'row' : 'column',
    justifyContent: 'space-between'
  },
  input_label_small: {
    fontFamily: "Comfortaa",
    marginBottom: 5,
    fontSize: 12,
    alignSelf: 'flex-start'
  },
  age_wrapper_min: {
    marginRight: 20
  },
  placeholder_text: {
    fontFamily: 'Ysabeau',
    fontSize: 16,
    alignSelf: 'center',
    marginBottom: 5
  },
  restaurant_intro: {
    fontFamily: 'Ysabeau',
    fontSize: 16,
    marginBottom: 5
  },
  intro_title: {
    fontFamily: 'Ysabeau',
    fontSize: 30,
    alignSelf: 'center',
    marginTop: 40,
    marginBottom: 20
  },
  intro_text: {
    alignSelf: 'center',
    textAlign: 'center',
    fontFamily: 'Ysabeau',
    fontSize: 18,
    width: width > 700 ? 700 : 'calc(100vw - 20px)',
    borderRadius: 30,
    borderWidth: 1,
    borderColor: '#d0edd1',
    backgroundColor: '#d0edd0',
    padding: 20
  },
  drop_style_small: { 
    width: 180, 
    borderWidth: 1,
    padding: 10,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 12,
    backgroundColor: 'white',
    fontSize: 14,
    fontFamily: 'Comfortaa',
    color: '#575762',
    borderColor: '#bbbbc0'
  },
  drop_style: { 

    borderWidth: 1,
    padding: 10,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 12,
    backgroundColor: 'white',
    fontSize: 14,
    fontFamily: 'Comfortaa',
    color: '#575762',
    borderColor: '#bbbbc0'
  },
  interest_button: {
    color: 'white',
    fontFamily: 'Ysabeau',
    fontSize: 16,
    backgroundColor: 'gray', 
    borderRadius: 5,
    paddingHorizontal: 20,
    paddingVertical: 5,
    marginRight: 10,
    marginBottom: 10
  },
  selected: {
    backgroundColor: '#0dc6e3', 
  },
  hobbies_edit_wrapper: {
    flexDirection: 'column', 
    width: width <= 700 ? 'calc(100% - 20px)' : 800
  },
  input_text_hobbie: {
    textTransform: 'capitalize',
    marginRight: 10,
    marginBottom: 10,
    backgroundColor: '#0dc6e3',
    borderRadius: 3,
    padding: 5,
    paddingHorizontal: 20,
    color: 'white',
    fontFamily: "Ysabeau",
    lineHeight: 30,
    fontSize: 18,
    marginTop: 5,
    textAlign: 'center'
  },
  create_wrapper: {
    flexDirection: 'column',
    padding: width > 500 ? 40 : 10,
    paddingBottom: width > 500 ? 40 : 100
  },
  logistics_wrapper: {
    flexDirection: width > 500 ? 'row' : 'column', 
    justifyContent: 'space-between'
  },
  line: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginVertical: 10,
  },
  line2: {
    flex: width > 500 ? 1 : 'unset',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginVertical: 10,
  },
  column_title: {
    fontFamily: 'Comfortaa',
    fontSize: 20,
    alignSelf: 'flex-start',
    marginTop: width > 500 ? 0 : 40
  },
  line_name: {
    fontFamily: 'Ysabeau',
    fontSize: 16,
    alignSelf: 'flex-start'
  },
  line_date: {
    fontFamily: 'Ysabeau',
    fontSize: 18,
    alignSelf: 'flex-start',
    marginTop: 10,
    marginLeft: 20
  },
  line_column_name: {
    fontFamily: 'Comfortaa',
    fontSize: 16,
    marginRight: 20
  },
}

let style_small = StyleSheet.create({
  ...global_style
});

let style_normal = StyleSheet.create({
  ...global_style
});

let style = width < 1000 ? style_small : style_normal;

export {
  style
}
