import React from 'react';
import UtilitiesController from './utilitiesController';
import { Platform } from 'react-native';
import { config } from '../../config';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Utilities from '../utilities/index';
import StorageController from './storageController';
import UserController from './userController';

export default class AuthController {

    static exceptionWrapper = (fn) => async (data) => {
        try {
            return await fn(data).catch((err) => {
                throw err;
            });
        } catch (err) {
            return {
                success: false,
                error: err.message
            }
        }
    };

    static async register (data) {
        let response = await Utilities.doInternalPost('/register', data, true);

        //save token if success
        if(response && response.success) {
            localStorage.setItem("token", response.token);

            if(response.user) {
                await UserController.saveUser(response)
            }

            return response;
        } else {
            return {
                success: false,
                error: response.error
            };
        }
    }

    static async getMemberCount (data) {
        let response = await Utilities.doInternalGet('/member_count', data, true);

        return response.success ? response.member_count : 0
    }

    static async getFAQ (data) {
        let response = await Utilities.doInternalGet('/faq', data, true);

        return response.success ? response.faq : {}
    }

    static async getInternal (data) {
        let response = await Utilities.doInternalGet('/internal', data, true);

        return response.success ? response.internal : {}
    }

    static async getUsers (data) {
        let response = await Utilities.doInternalPost('/get_users', data, true);

        return response.success ? response.users : {}
    }

    static async getEvents (data) {
        let response = await Utilities.doInternalGet('/events', data, true);

        return response.success ? response : {}
    }

    static async getAdminEvents (data) {
        let response = await Utilities.doInternalPost('/data/admin/events_admin', data, false);

        return response.success ? response : {}
    }

    static async getEvent (event_id) {
        let response = await Utilities.doInternalGet(`/event/${event_id}`, {}, true);

        return response.success ? response.events : {}
    }

    static async getHomeCarousel () {
        let response = await Utilities.doInternalGet(`/home_carousel`, {}, true);

        return response
    }

    static async addNewsletter (data) {
        let response = await Utilities.doInternalPost(`/add_newsletter`, data, true);

        return response;
    }

    static async addWaitlist (data) {
        let response = await Utilities.doInternalPost(`/add_waitlist`, data, true);

        return response;
    }

    static async getUser (data) {
        let response = await Utilities.doInternalPost(`/get_user`, data, true);

        return response.success ? response.user : {}
    }

    static async getHosts (data) {
        let response = await Utilities.doInternalGet(`/hosts`, null, true);

        return response.success ? response.hosts : []
    }

    static async getPlaces (data) {
        let response = await Utilities.doInternalGet('/places', data, true);

        return response.success ? response.places : []
    }

    static async getEventAndPlace (data) {
        let response = await Utilities.doInternalPost('/event_and_place', data, true);

        return response.success ? response.event_details : {}
    }

    static async OCR (data) {
        let response = await Utilities.doInternalPost(`/ocr`, data, true);

        return response.success ? response.data : {}
    }

    static async getTables(data) {
        let response = await Utilities.doInternalPost(`/get_tables`, data, true);

        return response;
    }

    static async updateTables(data) {
        let response = await Utilities.doInternalPost(`/update_tables`, data, true);

        return response;
    }
}
