import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { View, Platform, TouchableOpacity, ScrollView, Image, Picker, Dimensions } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Input, Text } from '../../components';
import authController from '../../controllers/authController';
import UserController from '../../controllers/userController';
import EventController from '../../controllers/eventController';
import PaymentController from '../../controllers/paymentController';
import Utilities from '../../utilities';
import { DropZone, DraggableItem, Modal, CropImage } from '../../containers';
import LoginRegisterScreen from '../LoginRegister';
import RegisterScreen from '../Register';
import { style } from './style';
import { global_style } from '../../../global_style'
import { Analytics, PageHit } from 'expo-analytics';
import { config } from '../../../config';
import AuthController from '../../controllers/authController';
import { Camera, CameraView, useCameraPermissions } from 'expo-camera';
import * as Permissions from 'expo-permissions';

class Tables_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tables: [{rsvps: [], link: null}, {rsvps: [], link: null}, {rsvps: [], link: null}, {rsvps: [], link: null}],
      host_user_id: 'cc467f19-6954-4151-ba3c-c45c8563511c'
    }

    this.modal_ref = React.createRef();
    this.handleDrop = this.handleDrop.bind(this);
  }

  async componentDidMount() {
    /* analytics.hit(new PageHit('Tables'))
      .then(() => {})
      .catch(e => console.log("analytics error", e.message)); */

    let event_id = '1c0fb17e-8b90-47c1-9b6d-35929d1cf45a'
    //let event_id = 'd0b0ca81-1a0b-469a-9de0-4e7fe6861cb8';

    let token = await localStorage.getItem('token');
    let isLoggedIn = !!token;

    let user;
    if(isLoggedIn && !user) {
      user = await UserController.getUser();
      user = user ? user.user : null;
    } else {
      window.location.replace('/login')
    }

    let user_id = user ? user.id : null;

    //const { status } = await Permissions.askAsync(Permissions.CAMERA);

    let {rsvps} = await UserController.getDateRsvpsForEvent({event_id})
    let event_data = await AuthController.getEventAndPlace({event_id});
    let tables = await AuthController.getTables({event_id});
    tables = tables.tables.table_data;
    console.log('tables', tables)

    this.setState({
      tables,
      event_id,
      user_id,
      isLoggedIn,
      mounted: true,
      rsvps,
      original_rsvps: rsvps,
      hasPermission: status === 'granted'
    })
  }

  drawBubble = (event_rsvp) => {
    let img_size = 50;
    let { profile } = event_rsvp;
    profile = profile ? profile : `{"photo_url": "https://diningsocial-assets.s3.amazonaws.com/empty_profile.png", "name": "Member"}`
    let { photo_url, name, user_id } = JSON.parse(profile);
  return <View style={{flexDirection: 'column', width: img_size + 10}}>
    <View style={{width: img_size, height: img_size, borderRadius: 100, backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', marginRight: 10, marginBottom: 10}}><Image style={{ width: img_size,
    height: img_size,
    borderRadius: 100}} source={{uri: photo_url ? photo_url : 'https://diningsocial-assets.s3.amazonaws.com/empty_profile.png'}} /></View>
    </View>
                  
  }

  async handleDrop(user_id) {
    console.log('user_id', user_id)

    let { tables, rsvps, selected_table, event_id } = this.state;
    
    tables[selected_table].rsvps.push(user_id)

    rsvps = rsvps.filter(a=>a.user_id !== user_id)

    let table_data = tables;

    await AuthController.updateTables({event_id, table_data});

    this.setState({tables, rsvps})
  };

  toggleCameraFacing() {
    this.setState({
      facing: this.state.facing === 'front' ? 'back' : 'front'
    })
  }

  takePic = async()=> {
    if (this.camera) {
      let photo = await this.camera.takePictureAsync();
      console.log('photo', photo);

     /*  let text = await AuthController.OCR({image_data});
      console.log('text', text) */

      this.setState({photo}, this.cameraModal)
    }
  }

  cameraModal_old = async () => {


    let { facing, photo } = this.state;
    facing = facing ? facing : 'front';

    let window_width = Dimensions.get('window').width;
    let is_mobile = window_width < 500;

    console.log('in c', photo)

    this.modal_ref.setContent(<View style={{height: 200, width: 300}}>
       {photo ? (
          <CropImage style={{height: 200, width: 300}} photo={photo} />
        ) : (
      <View style={{height: 200}}>
      <Camera ref={ref => {
      this.camera = ref
    }} style={{flex: 1}} facing={facing}>
      <View style={{flex: 1}}>
        <TouchableOpacity style={{}} onPress={this.toggleCameraFacing}>
          <Text style={{}}>Flip Camera</Text>
        </TouchableOpacity>
      </View>
    </Camera>
    <TouchableOpacity onPress={this.takePic}><Text>take pic</Text></TouchableOpacity>
    </View>)}
    </View>, is_mobile ? window_width : 500, '100vh');
  }

  handleImageUpload = async() => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    console.log(result);

    if (!result.canceled) {
      let image_data = result.assets[0].uri

        let text = await AuthController.OCR({image_data});
        console.log('text', text) 
      
    }
  }

  cameraModal = () => {
    let window_width = Dimensions.get('window').width;
    let is_mobile = window_width < 500;

    this.modal_ref.setContent(<View style={{height: 200, width: 300}}>

   <TouchableOpacity onPress={this.handleImageUpload}><Text>upload pic</Text></TouchableOpacity>
   </View>, is_mobile ? window_width : 500, '100vh');
  }

  render() {
    let { mounted, rsvps, original_rsvps, tables } = this.state;

    let rsvps_0 = mounted ? original_rsvps.filter(a=>tables[0].rsvps.indexOf(a.user_id) !== -1) : [];
    let rsvps_1 = mounted ? original_rsvps.filter(a=>tables[1].rsvps.indexOf(a.user_id) !== -1) : [];
    let rsvps_2 = mounted ? original_rsvps.filter(a=>tables[2].rsvps.indexOf(a.user_id) !== -1) : [];
    let rsvps_3 = mounted ? original_rsvps.filter(a=>tables[3].rsvps.indexOf(a.user_id) !== -1) : [];

    return (<View style={{paddingBottom: 100}}>
         <Modal ref={node=>this.modal_ref=node} putInParent={this.props.putInParent} />
        {mounted ? 
        <View style={{flexDirection: 'column'}}>
          <View style={[style.page_container, {backgroundColor: 'white', flexDirection: 'row'}]}>
            <View style={{flex: 1}}>
              <DropZone onMouseOver={()=>{this.setState({selected_table: 0})}} children={
              <View>
                {rsvps_0.map(this.drawBubble)}
                <View>
                  <TouchableOpacity onPress={()=>this.addBill(0)}><Text>add bill</Text></TouchableOpacity>
                </View>
              </View>} />
              <DropZone onMouseOver={()=>{this.setState({selected_table: 1})}}  children={rsvps_1.map(this.drawBubble)} />
              <DropZone onMouseOver={()=>{this.setState({selected_table: 2})}}  children={rsvps_2.map(this.drawBubble)} />
              <DropZone onMouseOver={()=>{this.setState({selected_table: 3})}}  children={rsvps_3.map(this.drawBubble)} />
            </View>
            <View style={{flexWrap: 'wrap', width: 300}}>
            {rsvps.map(event_rsvp=>{
                    let img_size = 50;
                      let { profile } = event_rsvp;
                      profile = profile ? profile : `{"photo_url": "https://diningsocial-assets.s3.amazonaws.com/empty_profile.png", "name": "Member"}`
                      let { photo_url, name, user_id } = JSON.parse(profile);
                    return <DraggableItem onDrop={()=>{this.handleDrop(event_rsvp.user_id)}} key={event_rsvp.user_id}><View style={{flexDirection: 'column', width: img_size + 10}}>
                      <View style={{width: img_size, height: img_size, borderRadius: 100, backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', marginRight: 10, marginBottom: 10}}><Image style={{ width: img_size,
                      height: img_size,
                      borderRadius: 100}} source={{uri: photo_url ? photo_url : 'https://diningsocial-assets.s3.amazonaws.com/empty_profile.png'}} /></View>
                      </View></DraggableItem>
                  })}
            </View>

          </View>
        </View> : <View style={{backgroundColor: '#black', width: '100vw', height: '100vh'}}>
          <View style={{width: 60, height: 60, alignSelf: 'center', paddingTop: 100}}>
          <Progress.CircleSnail size={60} backgroundColor="black" fill='black' color={'#e32652' } />
          </View>
        </View>}
      </View>
    );
  }
}

function TablesScreen(props) {
  if (Platform.OS === 'web') {
    let navigate = useNavigate();
    let { event_id } = useParams();

    return <Tables_Base {...props} event_id={event_id} navigate={navigate} />
  } else {
    return <Tables_Base {...props} navigate={props.navigation.push} />
  }
}

export default TablesScreen;
