import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { View, Platform, TouchableOpacity, Dimensions, Image, Picker } from 'react-native';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Input, Text } from '../../components';
import authController from '../../controllers/authController';
import UserController from '../../controllers/userController';
import EventController from '../../controllers/eventController';
import PaymentController from '../../controllers/paymentController';
import Utilities from '../../utilities';
import { Modal } from '../../containers';
import { style } from './style';
import { Analytics, PageHit } from 'expo-analytics';
import { config } from '../../../config';
import AuthController from '../../controllers/authController';
const analytics = new Analytics('G-YCC5QPC8BJ');

class Points_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mounted: true
    }

  }

  async componentDidMount() {
    analytics.hit(new PageHit('Points'))
      .then(() => {})
      .catch(e => console.log("analytics error", e.message));


  }

  render() {
    let { mounted } = this.state; 

    const goTo = (link) => {
      window.open(link)
    }

    return (
      <View style={style.explainer_section}>
      <View style={style.restaurants_wrapper}>
        <View style={style.restaurant_wrapper}>
          <Text style={style.restaurant_title}>Dining Club Point System 😃</Text>

          <View style={style.whole_wrapper}>
          <View style={style.intro}>
            <Text style={style.intro_text}>
              We're brand new and could use all the help we can get to spread the word about our club. So, we've created our points system. You can share news about us in all kinds of fun ways and get points to use later toward our dinners!
            </Text>
            <Text style={style.intro_text}></Text>
            <Text style={style.intro_text}>
              Check our list below and message us on Instagram <TouchableOpacity style={style.link} onPress={()=>{goTo('https://www.instagram.com/diningsocialnj')}}>@diningsocialnj</TouchableOpacity> or email us at team@diningscoialnj.com to receive the points!
            </Text>
          </View>
            <View style={style.intro}>
              <Text style={style.intro_text}>

              </Text>
              <Text style={style.text_list_item}>
                <Text style={style.text_list_item_section}>Canceling Events</Text>
                  <Text style={style.text_list_item}>
                    <View style={style.list_line_wrapper}>
                      <Text style={style.list_item_title}>5 Points:</Text> <Text style={style.list_item_text}> Cancel your RSVP after the timeframe where you can get a refund and receive points toward next events.</Text>
                    </View>
                  </Text>
              </Text>
              <Text style={style.text_list_item}>
                <Text style={style.text_list_item_section}>Instagram</Text>
                  <Text style={style.text_list_item}>
                    <View style={style.list_line_wrapper}>
                      <Text style={style.list_item_title}>1 Point:</Text> <Text style={style.list_item_text}> Follow us</Text> <TouchableOpacity style={style.link} onPress={()=>{goTo('https://www.instagram.com/diningsocialnj')}}> @diningsocialnj</TouchableOpacity>
                    </View>
                  </Text>
                  <Text style={style.text_list_item}>
                    <View style={style.list_line_wrapper}>
                      <Text style={style.list_item_title}>1 Point:</Text> <Text style={style.list_item_text}> Post a picture from a dinner you've attended and tag </Text> <TouchableOpacity style={style.link} onPress={()=>{goTo('https://www.instagram.com/diningsocialnj')}}> @diningsocialnj</TouchableOpacity>
                    </View>
                    
                  </Text>
                  <Text style={style.text_list_item}>
                    <View style={style.list_line_wrapper}>
                      <Text style={style.list_item_title}>2 Points:</Text> <Text style={style.list_item_text}> Post a picture of yourself and others at the dinner and tag</Text> <TouchableOpacity style={style.link} onPress={()=>{goTo('https://www.instagram.com/diningsocialnj')}}>@diningsocialnj</TouchableOpacity>
                    </View>
                  </Text>
                  <Text style={style.text_list_item}>
                    <View style={style.list_line_wrapper}>
                      <Text style={style.list_item_title}>2 Points:</Text> <Text style={style.list_item_text}> Post a video from a dinner and tag</Text> <TouchableOpacity style={style.link} onPress={()=>{goTo('https://www.instagram.com/diningsocialnj')}}>@diningsocialnj</TouchableOpacity>
                    </View>
                  </Text>
                  <Text style={style.text_list_item}>
                    <View style={style.list_line_wrapper}>
                      <Text style={style.list_item_title}>4 Points:</Text> <Text style={style.list_item_text}> Send us a video from one of our dinners for us to include in one of our reels </Text>
                      </View>
                  </Text>
              </Text>
              <Text style={style.text_list_item}>
                <Text style={style.text_list_item_section}>Google</Text>
                  <Text style={style.text_list_item}>
                  <View style={style.list_line_wrapper}>
                    <Text style={style.list_item_title}>4 Points:</Text> <Text style={style.list_item_text}> Post a review on google and include "diningscoialnj.com" in the first six words. Ex: "We had dinner here with diningsocialnj.com.... "</Text>
                    </View>
                  </Text>
              </Text>
              <Text style={style.text_list_item}>
                <Text style={style.text_list_item_section}>Facebook</Text>
                  <Text style={style.text_list_item}>
                  <View style={style.list_line_wrapper}>
                    <Text style={style.list_item_title}>1 Point:</Text> <Text style={style.list_item_text}> Follow our page </Text><TouchableOpacity style={style.link} onPress={()=>{goTo('https://www.facebook.com/profile.php?id=100094769816982')}}>Dining Social</TouchableOpacity>
                    </View>
                    
                  </Text>
              </Text>
              <Text style={style.text_list_item}>
                <Text style={style.text_list_item_section}>Membership:</Text>
                  <Text style={style.text_list_item}>
                    <View style={style.list_line_wrapper}>
                      <Text style={style.list_item_title}> Whole Free Month:</Text> <Text style={style.list_item_text}> Refer a friend that subscribes and get a whole free month.</Text>
                    </View>
                  </Text>
                  <Text style={style.text_list_item}>
                    <View style={style.list_line_wrapper}>
                      <Text style={style.list_item_title}> Whole Free Dinner:</Text> <Text style={style.list_item_text}> Refer a friend that RSVPs for a dinner and get a dinner RSVP for free.</Text>
                    </View>
                  </Text>
              </Text>
            </View>

            <View style={style.legal}>
              <Text style={style.legal_text}>* All pricing plans are subject to our <TouchableOpacity onPress={()=>this.props.navigate('/terms')}><Text style={style.link}>Terms & Conditions</Text></TouchableOpacity> and any logic that doesn't make sense.</Text>    
            </View>
          </View>
        </View>
        </View>
      </View>
    );
  }
}

function PointsScreen(props) {
  if (Platform.OS === 'web') {
    let navigate = useNavigate();
    let { date, type, event_id, group } = useParams();

    return <Points_Base {...props} group={group} event_id={event_id} type={type} date={date} navigate={navigate} />
  } else {
    return <Points_Base {...props} navigate={props.navigation.push} />
  }
}

export default PointsScreen;
