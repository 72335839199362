import React, { Component } from 'react';
import { StyleSheet, View, Text, Platform } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useNavigate } from 'react-router-dom';

//library.add(faInstagram);

class DropZone_Base extends Component {
  constructor(props) {
    super(props);

  }

  render() {

    let children = this.props.children;

    return (
      <View style={{marginBottom: 50}}>
      <View
        style={{height: 200,
          width: 200,
          flexDirection: 'row',
          flexWrap: 'wrap',
          backgroundColor: '#e0e0e0',
          }}
          >
        {children}
      </View>
      <TouchableOpacity onPress={this.props.onMouseOver}><Text>select</Text></TouchableOpacity>
      </View>
    );
  }

}

function DropZone(props) {
  if(Platform.OS === 'web') {
    let navigate = useNavigate();
    return  <DropZone_Base {...props} navigate={navigate} />
  } else {
    return  <DropZone_Base {...props} navigate={props.navigation.push}  />
  }
}

export default DropZone;
