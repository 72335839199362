import { StyleSheet, Dimensions } from 'react-native';

let width = Dimensions.get('window').width;
let height = Dimensions.get('window').height;

let global_style = {

  newsletter_wrapper: {
    
  },
  newsletter_intro: {
    fontFamily: 'Ysabeau',
    fontSize: width < 700 ? 26 : 50,
    alignSelf: 'center',
    marginBottom: width < 700 ? 20 : 40,
    textAlign: 'center'
  },
  newsletter_intro_small: {
    fontFamily: 'Ysabeau',
    fontSize: 22,
    color: '#706b6b'
  },
  newsletter_intro_title: {
    fontFamily: 'Comfortaa',
    fontSize: 24,
    lineHeight: 26,
    textAlign: 'center',
    marginBottom: 30,
    color: 'white'
  },
  newsletter_block: {
    paddingHorizontal: width < 700 ? 5 : 100,
    paddingTop: 50
  },
  register_column: {
    order: width < 700 ? 1 : 2,
    padding: 50,
    backgroundColor: 'black',
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderTopLeftRadius: width < 700 ? 10 : 0,
    borderBottomLeftRadius: width < 700 ? 10 : 0,
    marginBottom: width < 700 ? 10 : 0
  },
  newsletter_column: {
    order: width < 700 ? 2 : 1,
    padding: 50,
    backgroundColor: 'black',
    borderRightWidth: 1,
    borderColor: 'white',
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    borderTopRightRadius: width < 700 ? 10 : 0,
    borderBottomRightRadius: width < 700 ? 10 : 0,
    //borderColor: '#3497e8'
  },
  input_spacing: {
    justifyContent: 'space-between'
  },
  club_button_text: {
    fontFamily: 'Ysabeau',
    fontSize: 12,
    color: 'white'
  },
  bottom_join_wrapper: {
    backgroundColor: 'black',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    paddingVertical: 50
  },
  go_button: {
    padding: 2,
    flex: 1,
    marginLeft: 10,
    borderRadius: 5
  },
  success_text: {
    fontFamily: 'Comfortaa',
    fontSize: 18,
    color: '#53f153',
    marginTop: 10
  },
  inactive_go_button: {
    backgroundColor: '#9fc5e4',
    padding: 2,
    flex: 1,
    marginLeft: 10,
    borderRadius: 5
  },
  input_box: {
    flex: 7,
    borderRadius: 5
  },
  whole_wrapper: {

  },
  testimonial_height: {
    backgroundColor: '#222326', 
    borderRadius: 20,
    height:  width > 500 ? 207 : 'unset',
    marginBottom: width > 500 ? 'unset': 30,
    width: width > 500 ? 500 : 'unset'
  },
  testimonial_summary_text: {
    fontFamily: 'Ysabeau', fontSize: 20, 
    lineHeight: width > 500 ? 30 : 28, 
    color: 'white'},
  testimonial_third_tile: {
    width: width > 500 ? 500 : 'unset',
      backgroundColor: '#222326', borderRadius: 20, 
      height: width > 500 ? 207 : 'unset',
      marginBottom: width > 500 ? 'unset': 30
  },
  testimonial_border: {
    border: '10px solid black', 
    borderRadius: 30, flexDirection: 'row', 
    padding: width > 500 ? 30 : 30
  },
  testimonial_first_tile: {
    width: width > 500 ? 500 : 'calc(100vw - 20px)',
    backgroundColor: '#222326', 
    borderRadius: 20, 
    height: width > 500 ? 207 : 'unset',
    marginBottom: width > 500 ? 'unset': 30
  },
  testimonials_top_wrapper: {
    backgroundColor: 'black', 
    flexDirection: 'column', 
    paddingHorizontal: width > 500 ? 50 : 20, 
    paddingVertical: width > 500 ? 100 : 50, 
  },
  testimonial_first_row: {flexDirection: width > 500 ? 'row' : 'column', marginBottom: width > 500 ?40 : 'unset'},
  plans_wrapper: {
    flexDirection: 'row', 
    width: 900, 
    justifyContent: 'space-between', 
    margin: 'auto'
  },
  plan_cap: {
    flexDirection: 'column', 
    backgroundColor: '#222326', 
    borderTopLeftRadius: 10, 
    borderTopRightRadius: 10,
    alignContent: 'center', 
    alignItems: 'center', 
    padding: 20,
    //border: 'solid 3px #e32652'
  },
  join_button_free: {
    backgroundColor: '#e32652',
  },
  join_button_monthly: {
    backgroundColor: '#e32652',
  },
  join_button_yearly: {
    backgroundColor: '#e32652',
  },
  legal_text: {
    fontFamily: 'Ysabeau',
    fontSize: 14,
    paddingVertical: 10,
    backgroundColor: 'black',
    color: 'white'
  },
  legal_title: {
    fontFamily: 'Comfortaa',
    fontSize: 14
  },
  intro_text: {
    fontFamily: 'Comfortaa',
    fontSize: 16,
    lineHeight: 26,
    backgroundColor: 'black',
    color: 'white',
    textAlign: 'center'
  },
  intro: {
    marginBottom: 50,
    flexDirection: 'row',
    margin: 'auto',
    width: 850,
    justifyContent: 'center',
    flexDirection: 'column',
  },
  restaurant_title: {
    fontFamily: 'Ysabeau',
    fontSize: 50,
    paddingVertical: 50,
    textAlign: 'center',
    color: 'white',
    //backgroundColor: 'black'
  },
  one_plan: {
    flexDirection: 'column', 
    width: 280, 
    alignContent: 'center',
    margin: 'auto',
    marginBottom: 40
  },
}

let style_small = StyleSheet.create({
  ...global_style,
  intro: {
    marginBottom: 50,
    flexDirection: 'row',
    margin: 50,
    justifyContent: 'center',
    flexDirection: 'column',
  },
  plans_wrapper: {
    flexDirection: 'column', 
    //width: 900, 

  },
  explainer_section: {
    flexDirection: 'column'
  },
  restaurants_title: {
    fontFamily: 'Ysabeau',
    fontSize: '50px',
    color: 'black',
    width: '100%',
    flexDirection: 'row',
    padding: 20,
    marginBottom: 80,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  restaurants_wrapper: {
    flexDirection: 'column'
  },
  restaurant_wrapper: {
    flexDirection: 'column'
  },
  memberships: {
    paddingTop: 50,
    flexDirection: 'column',
    margin: 'auto',
    justifyContent: 'center',
  },
  membership: {
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: 10,
    borderWidth: 3,
    borderColor: '#1e1007',
    width: 270
    //margin: 30
  },
  white_panel: {
    //position: 'absolute',
    height: 272,
    backgroundColor: 'white',
    width: 200,
    //left: 200,
    //borderRadius: 30
  },
  panel_monthly: {
    borderColor: 'black',
    borderWidth: 3
  },
  panel_free: {
    borderColor: '#84977e',
    borderWidth: 3
  },
  panel_yearly: {
    borderColor: '#1e1007',
    borderWidth: 3
  },
  membership_left: {
    flex: 1,
    padding: 50,
    /* borderTopLeftRadius: 26,
    borderTopRightRadius: 26, */
    width: 270,
    alignItems: 'center'
  },
  left_wrapper: {

    alignItems: 'center',

  },
  free_membership: {
    borderColor: '#84977e',
    marginBottom: 50,

  },
  monthly_membership: {
    borderColor: 'black',
    marginBottom: 50,
  },
  yearly_membership: {
    
  },
  membership_left_free: {
    backgroundColor: '#84977e',
    borderRadius: 10
  },
  membership_left_monthly: {
    //backgroundColor: 'black',
    "backgroundColor":"#332c28","opacity":"1","backgroundImage":"linear-gradient(135deg, black 25%, transparent 25%), linear-gradient(225deg, black 25%, transparent 25%), linear-gradient(45deg, black 25%, transparent 25%), linear-gradient(315deg, black 25%, #332c28 25%)","backgroundPosition":"80px 0, 80px 0, 0 0, 0 0","backgroundSize":"80px 80px",
    borderRadius: 10
  },
  membership_left_yearly: {
    //backgroundColor: '#1e1007',
    "backgroundColor":"black","opacity":"1","backgroundImage":"linear-gradient(135deg, #1c1c25 25%, transparent 25%), linear-gradient(225deg, #1c1c25 25%, transparent 25%), linear-gradient(45deg, #1c1c25 25%, transparent 25%), linear-gradient(315deg, #1c1c25 25%, black 25%)","backgroundPosition":"80px 0, 80px 0, 0 0, 0 0","backgroundSize":"80px 80px","backgroundRepeat":"repeat",
    borderRadius: 10
  },
  membership_right: {
    flex: 1,

    //margin: 10
   
    //padding: 20
  },
  membership_title: {
    fontFamily: 'Comfortaa',
    color: "#fbf5f0",
    fontSize: 30,
    textAlign: 'center',
    marginBottom: 14
  },
  membership_price: {
    fontFamily: 'Ysabeau',
    color: 'white',
    textAlign: 'center',
    fontSize: 20
  },
  membership_explainer: {
    fontFamily: 'Ysabeau',
    color: '#33342f',
    marginTop: 30,
    width: 250,
    fontSize: 16
  },
  membership_explainer_add: {
    fontFamily: 'Ysabeau',
    color: '#33342f',
    width: 220,
    fontSize: 16,
    margin: 20,
    textAlign: 'center',
    height: 100
  },
  membership_explainer_title: {
    fontFamily: 'Comfortaa',
    fontSize: 26,
    color: '#33342f'
  },
  join_button: {

    paddingVertical: 10,
    paddingHorizontal: 20,
    width: 200,
    borderRadius: 30,
    //marginTop: 40,
    marginBottom: 20,
    marginTop: 20,
    alignSelf: 'center'
  },
  join_button_text: {
    fontFamily: 'Ysabeau',
    fontSize: 20,
    textAlign: 'center',
    color: '#fbf5f0'
  },
  bottom_breaker: {
    height: 50
  },
  link: {
    fontFamily: 'Comfortaa',
    color: '#0b6665',
    fontSize: 12,
    textDecorationLine: 'underline'
  },
  legal: {
    marginTop: 50,

    flexDirection: 'row',
    margin: 'auto',
    justifyContent: 'center',
    marginBottom: 100,
    flexDirection: 'column',
    backgroundColor: 'white'
  },
});

let style_normal = StyleSheet.create({
  ...global_style,
  explainer_section: {
    flexDirection: 'column'
  },
  restaurants_title: {
    fontFamily: 'Ysabeau',
    fontSize: '50px',
    color: 'black',
    width: '100%',
    flexDirection: 'row',
    padding: 20,
    marginBottom: 80,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  restaurants_wrapper: {
    flexDirection: 'column'
  },
  restaurant_wrapper: {
    flexDirection: 'column'
  },
  memberships: {
    paddingTop: 50,
    flexDirection: 'row',
    margin: 'auto',
    width: 1100,
    justifyContent: 'center',
  },
  membership: {
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: 10,
    borderWidth: 3,
    borderColor: '#1e1007'
    //margin: 30
  },
  white_panel: {
    //position: 'absolute',
    height: 272,
    backgroundColor: 'white',
    width: 200,
    //left: 200,
    //borderRadius: 30
  },
  panel_monthly: {
    borderColor: 'black',
    borderWidth: 3
  },
  panel_free: {
    borderColor: '#84977e',
    borderWidth: 3
  },
  panel_yearly: {
    borderColor: '#1e1007',
    borderWidth: 3
  },
  membership_left: {
    flex: 1,
    padding: 50,
    /* borderTopLeftRadius: 26,
    borderTopRightRadius: 26, */
    width: 270,
    alignItems: 'center'
  },
  left_wrapper: {

    alignItems: 'center',

  },
  free_membership: {
    borderColor: '#84977e',
    marginRight: 30
  },
  monthly_membership: {
    borderColor: 'black',
    marginRight: 30
  },
  yearly_membership: {
    
  },
  membership_left_free: {
    backgroundColor: '#84977e',
  },
  membership_left_monthly: {
    backgroundColor: 'black',
    "backgroundColor":"#332c28","opacity":"1","backgroundImage":"linear-gradient(135deg, black 25%, transparent 25%), linear-gradient(225deg, black 25%, transparent 25%), linear-gradient(45deg, black 25%, transparent 25%), linear-gradient(315deg, black 25%, #332c28 25%)","backgroundPosition":"80px 0, 80px 0, 0 0, 0 0","backgroundSize":"80px 80px",
  },
  membership_left_yearly: {
    //backgroundColor: '#1e1007',
    "backgroundColor":"black","opacity":"1","backgroundImage":"linear-gradient(135deg, #1c1c25 25%, transparent 25%), linear-gradient(225deg, #1c1c25 25%, transparent 25%), linear-gradient(45deg, #1c1c25 25%, transparent 25%), linear-gradient(315deg, #1c1c25 25%, black 25%)","backgroundPosition":"80px 0, 80px 0, 0 0, 0 0","backgroundSize":"80px 80px","backgroundRepeat":"repeat"
  },
  membership_right: {
    flex: 1,

    //margin: 10
   
    //padding: 20
  },
  membership_title: {
    fontFamily: 'Comfortaa',
    color: "#fbf5f0",
    fontSize: 30,
    textAlign: 'center',
    marginBottom: 14
  },
  membership_price: {
    fontFamily: 'Ysabeau',
    color: 'white',
    textAlign: 'center',
    fontSize: 20
  },
  membership_explainer: {
    fontFamily: 'Ysabeau',
    color: '#33342f',
    marginTop: 30,
    width: 250,
    fontSize: 16
  },
  membership_explainer_add: {
    fontFamily: 'Ysabeau',
    color: '#33342f',
    width: 220,
    fontSize: 16,
    margin: 20,
    textAlign: 'center',
    height: 100
  },
  membership_explainer_title: {
    fontFamily: 'Comfortaa',
    fontSize: 26,
    color: '#33342f'
  },
  join_button: {

    paddingVertical: 10,
    paddingHorizontal: 20,
    width: 200,
    borderRadius: 30,
    //marginTop: 40,
    marginBottom: 20,
    marginTop: 20,
    alignSelf: 'center'
  },
  join_button_text: {
    fontFamily: 'Ysabeau',
    fontSize: 20,
    textAlign: 'center',
    color: '#fbf5f0'
  },
  bottom_breaker: {
    height: 200
  },
  legal_title: {
    fontFamily: 'Comfortaa',
    fontSize: 14
  },
  link: {
    fontFamily: 'Comfortaa',
    color: '#0b6665',
    fontSize: 12,
    textDecorationLine: 'underline'
  },
  legal: {
    marginTop: 50,

    flexDirection: 'row',
    margin: 'auto',
    width: 850,
    justifyContent: 'center',
    marginBottom: 100,
    flexDirection: 'column',
    backgroundColor: 'white'
  }
});

let style = width < 500 ? style_small : style_normal;

export {
  style
}