import { StyleSheet, Dimensions } from 'react-native';

let width = Dimensions.get('window').width;
let height = Dimensions.get('window').height;

let global_style = {
  membership_tab: {
    fontFamily: 'Comfortaa', 
    fontSize: 18, 
    lineHeight: 24,
    paddingVertical: 20, 
    justifyContent: 'center'
  },
  three_buttons_button: {
    fontFamily: 'Ysabeau', 
    fontSize: 20, color: 'white', 
    border: 'solid 1px white', 
    paddingVertical: 5, 
    paddingHorizontal: 20,
    width: 187
  },
  three_title: {
    fontFamily: 'Ysabeau', 
    fontSize: 40, 
    color: 'white',
    textTransform: 'uppercase'
  },
  our_dining_image: {
    width: 400,
    height: 400
  },
  dining_sub_title: {
    fontFamily: 'Ysabeau', 
    fontSize: 40,
    marginTop: 40,
    color: 'black',
    textTransform: 'uppercase',
    textAlign: 'center',
    marginBottom: 40
  },
  dining_text: {
    fontFamily: 'Ysabeau', 
    fontSize: 20,
    lineHeight: 30, 
    color: 'black'
  },
  section_title: {
    fontFamily: 'Ysabeau', 
    fontSize: 50, 
    color: 'black',
    textTransform: 'uppercase',
    borderRightWidth: 1,
    borderColor: '#9d9b9b',
    paddingVertical: 40,
    paddingHorizontal: 40
  },
  wrap_dining_image: {
    paddingVertical: 40,
    marginHorizontal: 60
  },
}

let style_small = StyleSheet.create({
  ...global_style
});

let style_normal = StyleSheet.create({
  ...global_style
});

let style = width < 1000 ? style_small : style_normal;

export {
  style
}