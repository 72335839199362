import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { StyleSheet, View, TouchableOpacity, Platform, PanResponder, Animated } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { Text } from '../../components';
import { style } from './style';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';

//library.add(faInstagram);

class DraggableItem_Base extends Component {
  constructor(props) {
    super(props);
    this.pan = new Animated.ValueXY();
    this.panResponder = PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onPanResponderMove: Animated.event(
        [null, { dx: this.pan.x, dy: this.pan.y }],
        { useNativeDriver: false }
      ),
      onPanResponderRelease: () => {
        this.pan.extractOffset();
        console.log('released')
        this.props.onDrop();
      },
    });
  }

  render() {

    return (
      <Animated.View
      {...this.panResponder.panHandlers}
      style={[this.pan.getLayout(), { width: 100,
    height: 100,
    backgroundColor: '#f0f0f0',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 10,}]}
    >
      {this.props.children}
    </Animated.View>
    );
  }

}

function DraggableItem(props) {
  if(Platform.OS === 'web') {
    let navigate = useNavigate();
    return  <DraggableItem_Base {...props} navigate={navigate} />
  } else {
    return  <DraggableItem_Base {...props} navigate={props.navigation.push}  />
  }
}

export default DraggableItem
