import { StyleSheet, Dimensions } from 'react-native';

let width = Dimensions.get('window').width;
let height = Dimensions.get('window').height;

const style_small = StyleSheet.create({
  point_circle: {

  },
  points_wrapper: {
    flexDirection: 'row',
    backgroundColor: 'black',
    width: 300,
    justifyContent: 'space-between',
    alignSelf: 'center',
    backgroundColor: '#84e648',
    borderRadius: 5,
    padding: 20,
    alignItems: 'flex-start',
    marginBottom: 20
  },
  points_link: {
    color: '#0b6665',
    textDecorationLine: 'underline',
    fontSize: 14,
    fontFamily: 'Ysabeau',
    marginTop: 20
  },
  points_title: {
    fontFamily: 'Ysabeau',
    fontSize: 20
  },
  points_count: {
    fontFamily: 'Comfortaa',
    fontSize: 16
  },
  points_left: {
    flex: 1,
    flexDirection: 'row',
    alignSelf: 'flex-end'
  },
  points_right: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'right'
  },
  wrapper: {
    position: 'relative',
    margin: 20,
  },
  arc: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 100,
    height: 100,
    borderRadius: 100,
    borderWidth: 10,
  },
  arc_start: {
    borderTopColor: 'transparent',
    borderRightColor: 'red',
    borderBottomColor: 'red',
    borderLeftColor: 'red',
    /* transform: [{ rotate: '45deg' }], */
  },
  arc_end: {
    borderTopColor: 'red',
    borderRightColor: 'red',
    borderBottomColor: 'red',
    borderLeftColor: 'transparent',
    /* transform: [{ rotate: '75deg' }], */
  },
});

const style_normal = StyleSheet.create({
  point_circle: {

  },
  points_wrapper: {
    flexDirection: 'row',
    backgroundColor: 'black',
    width: 300,
    justifyContent: 'space-between',
    alignSelf: 'flex-end',
    backgroundColor: '#84e648',
    borderRadius: 5,
    padding: 20,
    alignItems: 'flex-start',
    marginBottom: 20
  },
  points_link: {
    color: '#0b6665',
    textDecorationLine: 'underline',
    fontSize: 14,
    fontFamily: 'Ysabeau',
    marginTop: 20
  },
  points_title: {
    fontFamily: 'Ysabeau',
    fontSize: 20
  },
  points_count: {
    fontFamily: 'Comfortaa',
    fontSize: 16
  },
  points_left: {
    flex: 1,
    flexDirection: 'row',
    alignSelf: 'flex-end'
  },
  points_right: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'right'
  },
  wrapper: {
    position: 'relative',
    margin: 20,
  },
  arc: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 100,
    height: 100,
    borderRadius: 100,
    borderWidth: 10,
  },
  arc_start: {
    borderTopColor: 'transparent',
    borderRightColor: 'red',
    borderBottomColor: 'red',
    borderLeftColor: 'red',
    /* transform: [{ rotate: '45deg' }], */
  },
  arc_end: {
    borderTopColor: 'red',
    borderRightColor: 'red',
    borderBottomColor: 'red',
    borderLeftColor: 'transparent',
    /* transform: [{ rotate: '75deg' }], */
  },
});

let style = width < 1160 ? style_small : style_normal;

export {
  style
}