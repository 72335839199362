import { StyleSheet } from 'react-native';

const style = StyleSheet.create({
    check_wrapper: {
        flexDirection: 'row',
        paddingVertical: 10,
        flexWrap: 'wrap'
    },
    check_text_wrapper: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    check_text_checked: {
        borderWidth: 2,
        borderRadius: 3,
        borderColor: '#0b6665',
        color: 'white', 
        paddingHorizontal: 3,
        backgroundColor: '#0b6665'
    },
    check_text_unchecked: {
        borderWidth: 2,
        borderRadius: 3,
        padding: 8,
        borderColor: '#0b6665'
    },
    check_text: {
        fontFamily: 'Ysabeau',
        fontSize: 16,
        width: 135
    },
    check: {
        height: 20
    }
});

export {
    style
}