import { StyleSheet, Dimensions } from 'react-native';

let width = Dimensions.get('window').width;
let height = Dimensions.get('window').height;
let global_style = StyleSheet.create({
  jan_and_i: {
    width: 350,
    height: 400,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  jan_and_i_wrapper: {
    width: '350px',
    marginBottom: 25, 
    marginRight: 25,
    marginLeft: -175, 
    float: 'left'
  },
  link: {
    fontFamily: 'Ysabeau',
    fontSize: 24,
    textDecorationLine: 'underline',
    color: '#0b6665',
  },
  menu_float: {
    position: 'absolute',
    width: 200,
    top: 180,
    left: 100
  },
  gang_back: {
    backgroundImage: 'url(https://diningsocial-assets.s3.amazonaws.com/the_gang.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: 0,
    backgroundSize: 'contain',
    width: 600,
    height: 300,
    textAlign: 'end',
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'flex-end,',
    alignContent: 'flex-end',
    paddingTop: 110,
    paddingRight: 25,
    fontSize: 50,
    fontFamily: 'fantasy'
  },
  gang_back_mobile: {
    display: 'none'
  },
  my_best: {
    fontSize: 20,
    fontFamily: 'fantasy'
  },
  badger_back: {
    backgroundImage: 'url(https://diningsocial-assets.s3.amazonaws.com/pinkflower.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: 0,
    backgroundSize: 'contain',
    backgroundPositionX: 900
  },
  badger_back_mobile: {display: 'none'},
  bird_back: {
    backgroundImage: 'url(https://diningsocial-assets.s3.amazonaws.com/bird.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: 0,
    backgroundSize: 'contain',
    backgroundPositionX: 890
  },
  bird_back_mobile: {
    display: 'none'
  },
  flowerone_back_mobile: {
    display: 'none',
  },
  flowerone_back: {
    backgroundSize: 'contain',
    backgroundImage: 'url(https://diningsocial-assets.s3.amazonaws.com/flowerone.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: 0
  },
  flowertwo_back: {
    backgroundImage: 'url(https://diningsocial-assets.s3.amazonaws.com/flowertwo.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: 0,
    backgroundSize: 'contain',
    backgroundPositionX: 880
  },
  flowertwo_back_mobile: {
    display: 'none'
  },
  goose_back: {
    backgroundImage: 'url(https://diningsocial-assets.s3.amazonaws.com/sillygoose.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: 100
  },
  goose_back_mobile: {
    display: 'none'
  },
  menu_text: {
    fontFamily: 'Comfortaa',
    textDecorationLine: 'underline',
    fontSize: 20,
    marginBottom: 40
  },
  image_wrap: {
    backgroundImage: 'url(https://diningsocial-assets.s3.amazonaws.com/pinkFeathers.png)',
    backgroundRepeat: 'repeat',
    paddingVertical: 100,
    paddingBottom: 84,
    borderRadius: 10
  },
  image_wrap2: {
    backgroundImage: 'url(https://diningsocial-assets.s3.amazonaws.com/pinkleaves.png)',
    backgroundRepeat: 'repeat',
    paddingVertical: 100,
    paddingBottom: 80,
    borderRadius: 10
  },
  main_wrapper: {
    paddingBottom: 100
  },
  image_description: {
    color: 'white', 
    paddingVertical: 5, 
    paddingHorizontal: 10, 
    fontFamily: 'Comfortaa', 
    fontSize: 14
  },
  image_description_wrapper: {
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    width: 600, backgroundColor: 'black', margin: 'auto'
  },
  image_description_wrapper_jan: {
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    width: '100%', 
    backgroundColor: 'black', margin: 'auto'
  },
  main_photo: {
    width: 600,
    height: 500,
    margin: 'auto',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5
  },
  description_paragraph: {
    fontFamily: 'Ysabeau',
    fontSize: 22,
    paddingVertical: 20,
    paddingHorizontal: 300
  },
  title: {
    fontFamily: 'Ysabeau',
    fontSize: 28,
    paddingVertical: 20,
    paddingHorizontal: 300
  },
  image_opacity: {
    display: 'none'
  }
});

let style_small = StyleSheet.create(
  {
    ...global_style,
    image_opacity_wrapper: {
      position: 'relative'
    },
    image_opacity: {
      position: 'absolute',
      backgroundColor: 'white',
      opacity: .5,
      width: '100%',
      height: 500, 
      order: 2
    },
    height_450: {
      height: 450, 
    },
    height_800: {
      height: 750, 
    },
    height_250: {
      height: 250
    },
    text_mobile: {
      order: 3
    },
    mobile_signature: {
      position: 'absolute',
      marginTop: 28
    },
    goose_back: {
      
    },
    goose_back_mobile: {
      position: 'absolute',
      backgroundImage: 'url(https://diningsocial-assets.s3.amazonaws.com/sillygoose.png)',
      backgroundRepeat: 'no-repeat',
      width: '100vw',
      height: 400,
      order: 1
    },
    bird_back: {

    },
    bird_back_mobile: {
      position: 'absolute',
      backgroundImage: 'url(https://diningsocial-assets.s3.amazonaws.com/bird.png)',
      backgroundRepeat: 'no-repeat',
      backgroundPositionY: 0,
      backgroundSize: 'contain',
      width: '100vw',
      height: 400,
      order: 1
    },
    flowerone_back_mobile: {
      position: 'absolute',
      backgroundImage: 'url(https://diningsocial-assets.s3.amazonaws.com/flowerone.png)',
      backgroundRepeat: 'no-repeat',
      width: '100vw',
      height: 400,
      backgroundSize: 'contain',
      order: 1
    },
    flowerone_back: {
      
    },
    flowertwo_back_mobile: {
      position: 'absolute',
      backgroundImage: 'url(https://diningsocial-assets.s3.amazonaws.com/flowertwo.png)',
      backgroundRepeat: 'no-repeat',
      width: '100vw',
      backgroundSize: 'contain',
      height: 400,
      order: 1
    },
    flowertwo_back: {
      
    },
    badger_back: {
      
    },
    badger_back_mobile: {
      position: 'absolute',
      backgroundImage: 'url(https://diningsocial-assets.s3.amazonaws.com/pinkflower.png)',
      width: '100vw',
      backgroundSize: 'contain',
      height: 680,
      order: 1
    },
    jan_and_i: {
      width: '100%',
      height: 400,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10
    },
    jan_and_i_wrapper: {
      width: '100%',
      marginBottom: 25, 
    },
    menu_float: {
      display: 'none'
    },
    gang_back: {
      width: '100vw',
      height: 300,
      textAlign: 'end',
      flexDirection: 'column',
      display: 'flex',
      paddingRight: 25,
      fontSize: 30,
      fontFamily: 'fantasy',
      position: 'absolute'
    },
    gang_back_mobile: {
      backgroundImage: 'url(https://diningsocial-assets.s3.amazonaws.com/the_gang.png)',
      backgroundRepeat: 'no-repeat',
      backgroundPositionY: 0,
      backgroundPositionX: 30,
      backgroundSize: 'contain',
      width: '100vw',
      height: 200,
    },
    my_best: {
      fontSize: 20,
      fontFamily: 'fantasy'
    },
    menu_text: {
      fontFamily: 'Comfortaa',
      textDecorationLine: 'underline',
      fontSize: 20,
      marginBottom: 40
    },
    image_wrap: {
      backgroundImage: 'url(https://diningsocial-assets.s3.amazonaws.com/pinkFeathers.png)',
      backgroundRepeat: 'repeat',
      paddingVertical: 100,
      paddingBottom: 84,
      borderRadius: 10,
      backgroundSize: 110
    },
    image_wrap2: {
      backgroundImage: 'url(https://diningsocial-assets.s3.amazonaws.com/pinkleaves.png)',
      backgroundRepeat: 'repeat',
      borderRadius: 10,
      paddingBottom: 249,
      paddingTop: 140
    },
    main_wrapper: {
      paddingBottom: 100
    },
    image_description: {
      color: 'white', 
      paddingVertical: 5, 
      paddingHorizontal: 10, 
      fontFamily: 'Comfortaa', 
      fontSize: 14
    },
    image_description_wrapper: {
      borderBottomLeftRadius: 5,
      borderBottomRightRadius: 5,
      width: 'calc(100% - 40px)', 
      backgroundColor: 'black', margin: 'auto'
    },
    image_description_wrapper_jan: {
      borderBottomLeftRadius: 5,
      borderBottomRightRadius: 5,
      width: '100%', 
      backgroundColor: 'black', margin: 'auto'
    },
    main_photo: {
      width: 'calc(100vw-20px)',
      height: 300,
      marginHorizontal: 20,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5
    },
    description_paragraph: {
      fontFamily: 'Ysabeau',
      fontSize: 22,
      paddingVertical: 20,
      paddingHorizontal: 20
    },
    title: {
      fontFamily: 'Ysabeau',
      fontSize: 28,
      paddingVertical: 20,
      paddingHorizontal: 10
    }
});

let style = width < 1000 ? style_small : global_style;

export {
  style
}