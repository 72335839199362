import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { StyleSheet, View, TouchableOpacity, Platform, Image } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { Text } from '../../components';
import UserController from '../../controllers/userController';
import { style } from './style';

class InfoBubble_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      attendee: props.attendee,
      is_open: false,
      content: props.content
    }

  }

  async componentDidMount() {
    let token = await localStorage.getItem('token');

    this.setState({
      token,
      mounted: true
    })
  }

  async componentDidUpdate() {
    if(this.props.is_open !== this.state.is_open) {
      this.setState({
        is_open: this.props.is_open
      })
    }
  }

  render() {
    let { is_open, content } = this.state;
    
    return <View>
          <TouchableOpacity onPress={this.props.onPress} style={style.overlay}></TouchableOpacity>
          <View style={[style.floating_info, {display: 'flex'}]}>

            <TouchableOpacity onPress={this.props.onPress}><Text style={style.close_x}>x</Text></TouchableOpacity>
            
            <View style={style.fat_top}>
              {content}
            </View>

          </View>
      </View>
  }

}

function InfoBubble(props) {
  if(Platform.OS === 'web') {
    let navigate = useNavigate();
    return  <InfoBubble_Base {...props} navigate={navigate} />
  } else {
    return  <InfoBubble_Base {...props} navigate={props.navigation.push}  />
  }
}

export default InfoBubble;
