import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { StyleSheet, View, TouchableOpacity, Platform, Image, Picker, Dimensions } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import { useNavigate } from 'react-router-dom';
import { Text, Input, Button, Check } from '../../components';
import UserController from '../../controllers/userController';
import { style } from './style';
import { global_style } from '../../../global_style'

class UserNotifications_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }

    this.updateNotifs = this.updateNotifs.bind(this);
  }

  async componentDidMount() {
    let notification_preferences = await UserController.getNotifPreferences();

    this.setState({
      notification_preferences,
      mounted: true
    })
  }

  updateNotifs() {
    this.setState({updating_notifs: true}, async ()=>{
      let { notification_preferences } = this.state;
      await UserController.updateNotifPreferences({notification_preferences});
  
      this.setState({
        updating_notifs: false
      })
    });
  }

  render() {
    /* { "newsletter": true, "daily_notifications": true, "user_messages": true, "member_rsvps": true, "user_invites": true, "feedback_request": true } */

    let { mounted, notification_preferences, updating_notifs } = this.state;

    let notification_array = [];

    let window_width = Dimensions.get('window').width;
    let is_mobile = window_width < 500;
    
    if(mounted) {
      notification_array = [{
        name: "Weekly Newsletter",
        db_name: "newsletter",
        on: !!notification_preferences.newsletter
      },
      {
        name: "When a New Event is Put on the Calendar",
        db_name: "new_event_notifications",
        on: Object.keys(notification_preferences).indexOf('new_event_notifications') === -1 ? true : notification_preferences.new_event_notifications
      },
      {
        name: "Daily Notifications of Today's Events",
        db_name: "daily_notifications",
        on: !!notification_preferences.daily_notifications
      },{
        name: "When A Member Messages Me",
        db_name: "user_messages",
        on: !!notification_preferences.user_messages 
      },{
        name: "When A Member I Follow RSVPs to an Event",
        db_name: "member_rsvps",
        on: !!notification_preferences.member_rsvps
      },{
        name: "When A Member Invites Me To An Event",
        db_name: "user_invites",
        on: !!notification_preferences.user_invites
      },{
        name: "After-Event Request for Feedback",
        db_name: "feedback_request",
        on: !!notification_preferences.feedback_request
      },{
        name: "When an Event in Your Age Range Opens Up",
        db_name: "user_event_open",
        on: !!notification_preferences.user_event_open
      }];
    }

    const onCheck = (db_name) => {
      let { notification_preferences } = this.state;

      notification_preferences[db_name] = !notification_preferences[db_name] 

      console.log('notification_preferences', notification_preferences)

      this.setState({
        notification_preferences
      })
    }

    let transactions_element = <View>
      <View style={[style.dashboard_panel, style.profile_panel, {paddingBottom: 100}]}>
        <Text style={style.section_title}>Notifications</Text>
          <View style={{flexDirection: 'column', marginBottom: 20, width: is_mobile ? '95%' : 400, margin: 'auto', marginBottom: 40}}>
            {notification_array.map(notif=>{
              return <View style={style.label_row}>
                <Check label={<Text style={style.label_text}>{notif.name}</Text>} onCheck={()=>onCheck(notif.db_name)} checked={notif.on} />
              </View>
            })}
          </View>
          <View style={{marginTop: 20}}>
            <Button title={'Update'}
              loading={updating_notifs}
              style={{
                fontFamily: 'Ysabeau',
                backgroundColor: '#e32652',
                alignSelf: 'center',
                borderRadius: 3,
                paddingTop: 5,
                paddingBottom: 7,
                paddingHorizontal: 5, marginBottom: 10}}

              onPress={this.updateNotifs} /> 
          </View>
        </View>
      </View>;

    return mounted ? <>{transactions_element}</> : <View style={{flex: 1}}><Progress.CircleSnail size={60} backgroundColor="white" fill='white' color={'black' } /></View>;
  }

}

function UserNotifications(props) {
  if(Platform.OS === 'web') {
    let navigate = useNavigate();
    return  <UserNotifications_Base {...props} navigate={navigate} />
  } else {
    return  <UserNotifications_Base {...props} navigate={props.navigation.push}  />
  }
}

export default UserNotifications
