import { StyleSheet, Dimensions } from 'react-native';

let width = Dimensions.get('window').width;
let height = Dimensions.get('window').height;

let global = {
  club_button: {
    backgroundColor: '#e32652',
    borderRadius: 3,
    paddingTop: 5,
    paddingBottom: 7,
    paddingHorizontal: 20,
    margin: 'auto'
  },
  club_button_text: {
    fontFamily: 'Ysabeau',
    fontSize: 18,
    color: 'white'
  },
  club_button_disabled: {
    backgroundColor: '#ecd6db',
    borderRadius: 3,
    paddingTop: 5,
    paddingBottom: 7,
    paddingHorizontal: 20,
    margin: 'auto',
    fontFamily: 'Ysabeau',
    fontSize: 18,
    color: 'white'
  },
  club_button_full: {
    backgroundColor: '#e32652',
    borderRadius: 3,
    paddingTop: 5,
    paddingBottom: 7,
    paddingHorizontal: 20,
    margin: 'auto',
    fontFamily: 'Ysabeau',
    fontSize: 18,
    color: 'white'
  },
  error_text: {
    fontFamily: "Comfortaa",
    fontSize: 16,
    lineHeight: 24,
    color: '#e32652'
  },
  mobile: {
    display: width < 500 ? 'flex' : 'none'
  },
  desktop: {
    display: width >= 500 ? 'flex' : 'none'
  },
  position_bottom: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  space_between: {
    justifyContent: 'space-between'
  },
  mobile_view: {
    display: width < 700 ? 'flex' : 'none'
  },
  desktop_view: {
    display: width >= 700 ? 'flex' : 'none'
  },
  row: {
    flexDirection: 'row'
  },
  row_mobile_switch_column: {
    flexDirection: width < 700 ? 'column' : 'row'
  },
  column: {
    flexDirection: 'column'
  },
  column_mobile_switch_row: {
    flexDirection: width < 700 ? 'row' : 'column'
  },
  flex_one: {
    flex: 1
  },
  flex_one_mobile_none: {
    flex: width < 700 ? 'unset' : 1
  },
  big_number: {
    fontFamily: 'Ysabeau',
    fontSize: 100,
    marginBottom: 40
  },
  big_number_mobile_smaller: {
    fontFamily: 'Ysabeau',
    fontSize: width < 700 ? 50 : 100,
    marginBottom: 40
  },
  medium_description: {
    fontFamily: "Comfortaa",
    fontSize: 18,
    lineHeight: 30
  },
  normal_description: {
    fontFamily: "Comfortaa",
    fontSize: 16,
    lineHeight: 24
  },
  whole_width_row: {
    width: '100%', 
    flexDirection: 'row'
  },
  whole_width_row_mobile_switch_column: {
    width: '100%', 
    flexDirection: width < 700 ? 'column' : 'row'
  },
  dark_blue_green_background: {
    backgroundColor: '#2B4150'
  },
  dark_blue_background: {
    backgroundColor: '#191D21'
  },
  foggy_red_background: {
    backgroundColor: '#93384F'
  },
  medium_brown_background: {
    backgroundColor: '#844E50'
  },
  white_background: {
    backgroundColor: 'white'
  },
  text_color_white: {
    color: 'white'
  },
  text_vibrant_red: {
    color: '#e61f51'
  },
  text_uppercase: {
    textTransform: 'uppercase'
  },

  normal_link: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    lineHeight: 20,
    color: '#0b6665',
    textDecorationLine: 'underline',
  },
  vibrant_link: {
    color: '#3497e8',
    fontFamily: 'Comfortaa',
    fontSize: 14,
    lineHeight: 20,
    textDecorationLine: 'underline',
  },
  normal_light_link: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    lineHeight: 20,
    color: '#7ce1e0',
    textDecorationLine: 'underline',
  },
  align_text_left: {
    alignItems: 'flex-start'
  },
  small_button: {
    backgroundColor: '#0b6665',
    color: 'white',
    borderRadius: 10,
    textAlign: 'center'
  },
  small_button_text: {
    paddingHorizontal: 20,
    paddingVertical: 5,
    color: 'white',
    fontFamily: 'Ysabeau',
    fontSize: 12
  },
  small_button_light: {
    backgroundColor: '#20cac8',
    color: 'white',
    borderRadius: 10,
    textAlign: 'center'
  },
  normal_text: {
    color: 'white',
    fontFamily: 'Ysabeau',
    fontSize: 14
  },
  normal_text_medium: {
    color: 'white',
    fontFamily: 'Ysabeau',
    fontSize: 18
  },
  normal_text_dark_medium: {
    fontFamily: 'Ysabeau',
    fontSize: 18
  },
  normal_text_dark: {
    fontFamily: 'Ysabeau',
    fontSize: 14
  },
  small_text: {
    fontFamily: 'Ysabeau',
    fontSize: '14px',
  },
  text_white: {
    color: 'white'
  },
  notification_text: {
    paddingVertical: 5,
    marginBottom: 20,
    color: 'white',
    fontFamily: 'Comfortaa',
    fontSize: 14,
    lineHeight: 18,
    textTransform: 'uppercase'
  },
  panel_sub_title: {
    fontFamily: 'Comfortaa',
    fontSize: 20,
  },
  padding_40: {
    padding: 40
  },
  padding_20: {
    padding: 20
  },
  padding_subtitle: {
    paddingTop: 10,
    paddingBottom: 20
  },
  padding_top_40: {
    paddingTop: 40
  },
  padding_top_20: {
    paddingTop: 20
  },
  padding_bottom_20: {
    paddingBottom: 20
  },
  padding_5_20: {
    paddingHorizontal: 20,
    paddingVertical: 5,
  },
  panel_padding: {
    padding: 40
  },
  panel_padding_with_mobile: {
    paddingHorizontal: width < 700 ? 5 : 40,
    paddingVertical: width < 700 ? 10 : 40
  },
  sub_panel_padding: {
    paddingHorizontal: width < 700 ? 5 : 20,
    paddingVertical: width < 700 ? 10 : 20
  },
  panel_border: {
    borderWidth: 40,
    borderColor: '#191d21'
  },
  line_breaker: {
    width: '100%',
    paddingVertical: 10
  },
  list_item_link: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    marginVertical: 5,
    lineHeight: 20,
    color: '#0b6665',
    textDecorationLine: 'underline',
  },
  list_item_text: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    marginVertical: 5,
    lineHeight: 20,
  },
  list_item: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Comfortaa',
    fontSize: 14,
    marginVertical: 5,
    marginLeft: 20,
    lineHeight: 20,
    listStyle: 'disc',
    display: 'list-item'
  },
  border_40: {
    borderWidth: 40
  },
  border_dark_gray: {
    //borderColor: '#1d1d1c'
    borderColor: 'black'
  },
  border_dark_blue: {
    borderColor: '#2b4150'
  },
  border_green: {
    borderColor: '#5c9338'
  },
  modal_overlay: {
    flex: 1,
    top: 0, 
    left: 0,
    opacity: .3,
    backgroundColor: 'black',
    width: '100vw',
    height: '100%',
    overflow: 'hidden',
    position: 'fixed',
    zIndex: 100000
  },
  modal_content: {
    flex: 1,
    position: 'absolute',
    backgroundColor: 'white',
    flexDirection: 'column',
    padding: width <= 400 ? 20 : 40,
    paddingTop: 20,
    zIndex: 200001,
    borderRadius: 10,
    top: 20,
    alignItems: 'center'
  },
  modal_content_mobile: {
    flex: 1,
    position: 'absolute',
    backgroundColor: 'white',
    flexDirection: 'column',
    padding: 0,
    zIndex: 200001,
    borderRadius: 10,
    top: 20,
    alignItems: 'center',
    borderRadius: 10
  },
  modal_x_wrapper: {

  },
  modal_close_x: {
    fontFamily: 'Comfortaa',
    fontSize: 15,
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    backgroundColor: '#e32652',
    borderRadius: 100,
    alignSelf: 'flex-end',
    marginRight: -20,
    color: 'white',
    width: 20,
    height: 20,
    padding: 0,
    paddingTop: 6,
    lineHeight: 8

  },
  center_content: {
    textAlign: 'center',
    alignItems: 'center'
  }
}

let style_small = StyleSheet.create({
  ...global,
});

let style_normal = StyleSheet.create({
  ...global,
  
});

let global_style = width < 1000 ? style_small : style_normal;

export {
  global_style
}