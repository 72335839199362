import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { View, Platform, TouchableOpacity, ScrollView, Image, Picker, Dimensions } from 'react-native';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Input, Text } from '../../components';
import authController from '../../controllers/authController';
import UserController from '../../controllers/userController';
import EventController from '../../controllers/eventController';
import PaymentController from '../../controllers/paymentController';
import Utilities from '../../utilities';
import { EditProfile, Profile, FAQ, Modal } from '../../containers';
import LoginRegisterScreen from '../LoginRegister';
import RegisterScreen from '../Register';
import { style } from './style';
import { global_style } from '../../../global_style'
import { Analytics, PageHit } from 'expo-analytics';
import { config } from '../../../config';
import AuthController from '../../controllers/authController';
import Icon from 'react-native-vector-icons/FontAwesome';
import { Video } from 'expo-av';
const analytics = new Analytics('G-YCC5QPC8BJ');

class RSVP_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password: null,
      tab: 0,
      terms: true,
      num_rsvp: 1,
      event_id: props.event_id,
      confirm_inactive: true
    }

    this.scrollViewRef = React.createRef();
    this.hostedRef = React.createRef();
    this.nonHostedRef = React.createRef();
    this.logisticsRef = React.createRef();
    this.attendeesRef = React.createRef();
    this.appsRef = React.createRef();
    this.loadMoreButton = React.createRef();

    this.rsvpChange = this.rsvpChange.bind(this);
    this.rsvpClick = this.rsvpClick.bind(this);
    this.updateInput = this.updateInput.bind(this);
    this.addCalendar = this.addCalendar.bind(this);
    this.scrollTo = this.scrollTo.bind(this);
    this.updateValue = Utilities.updateValue.bind(this);

    this.confirmMakeHost = this.confirmMakeHost.bind(this);
    this.confirmResignHost = this.confirmResignHost.bind(this);
    this.confirmCancel = this.confirmCancel.bind(this);

    this.drawAttendees = this.drawAttendees.bind(this);
    this.drawAttendee = this.drawAttendee.bind(this);
    this.openProfileBubble = this.openProfileBubble.bind(this);
    this.openInParent = this.openInParent.bind(this);
    this.fillOutProfile = this.fillOutProfile.bind(this);

    this.toggleConfirmRSVP = this.toggleConfirmRSVP.bind(this);
    this.toggleMakeHost = this.toggleMakeHost.bind(this);
    this.toggleResignHost = this.toggleResignHost.bind(this);
    this.toggleCancelRSVP = this.toggleCancelRSVP.bind(this);
    this.togglePreRSVP = this.togglePreRSVP.bind(this);
    this.toggleChooseUnInvites = this.toggleChooseUnInvites.bind(this);
    this.toggleChooseInvitesUninvites = this.toggleChooseInvitesUninvites.bind(this);

    this.toggleChooseAsMember = this.toggleChooseAsMember.bind(this);

    this.getCanCancel = this.getCanCancel.bind(this);

    this.drawMessage = this.drawMessage.bind(this);
    this.sendMessage = this.sendMessage.bind(this);

    this.refreshEvery20 = this.refreshEvery20.bind(this);
    this.onRegisterClick = this.onRegisterClick.bind(this);
    this.onLoginClick = this.onLoginClick.bind(this);

    this.updateUserLogging = this.updateUserLogging.bind(this);
    this.getEventStatus = this.getEventStatus.bind(this);

    this.openRSVP = this.openRSVP.bind(this);
    this.openPreConfirm = this.openPreConfirm.bind(this);
    this.chooseAfterLogin = this.chooseAfterLogin.bind(this);

    this.getSubscription = this.getSubscription.bind(this);
    this.drawTheButton = this.drawTheButton.bind(this);

    this.subscriptionStart = this.subscriptionStart.bind(this);
    this.getPrice = this.getPrice.bind(this);
    this.toggle = this.toggle.bind(this);
    this.hasAnotherEventWithinHour = this.hasAnotherEventWithinHour.bind(this);
    this.drawInvitee = this.drawInvitee.bind(this);
    this.selectUnInvitee = this.selectUnInvitee.bind(this);
    this.saveNonInvited = this.saveNonInvited.bind(this);

    this.drawPager = this.drawPager.bind(this);
    this.learnMore = this.learnMore.bind(this);
  }

  async componentDidMount() {
    analytics.hit(new PageHit('RSVP'))
      .then(() => {})
      .catch(e => console.log("analytics error", e.message));

    let { event_id } = this.state;

    let org_user = await UserController.getUser();

    let user = org_user && org_user.user ? org_user.user : null;
    let user_id = user && user.user_id ? user.user_id : null;

    let token = await localStorage.getItem('token');

    let isLoggedIn = !!token;

    let internal = await AuthController.getInternal();

    let places = await AuthController.getPlaces();
    let messages = await EventController.getMessages({event_id});

    let cancel_url = `rsvp${event_id ? `/${event_id}`: ''}`;
    let slug = cancel_url;

    let rsvps = [];
    let tentatives = [];

    let event, place, status, host, rsvped, invite_only;
    let event_data, day;
    
    if(event_id) {
      ({rsvps, tentatives} = await UserController.getDateRsvpsForEvent({event_id}));
      event_data = await AuthController.getEventAndPlace({event_id});

      rsvped = rsvps.filter(rsvp=>rsvp.user_id === user_id);
      rsvped = rsvped && rsvped.length ? true : false;

      status = event_data.status;
      event = event_data.event_data;

      day = (new Date(event.date)).getDay();

      console.log('eventevent', event)

      if(invite_only) {
        window.location.replace(`/invite/${event_id}`)
      }

      if(event.hosted && event.hosted === 'false') {
        event.hosted = false;
      } else {
        host = event_data.host_user;
      }

      place = event_data.place_data;
    } else {
      let { new_event_template } = internal;

      event = {
        ...new_event_template
      }
      //status = this.getEventStatus(date)
    }

    places = places.filter(a=>{return a.place_data.type === 'restaurant'});
    places = places.filter(place=>{ return Object.keys(place.place_data.days).indexOf(day.toString()) !== -1});

    let dinners = []
    
    if(isLoggedIn) {
      dinners = await UserController.getDinners();
    }

    dinners = dinners && dinners.dinners ? dinners.dinners : [];
    let has_any_dinners = dinners.filter(din=>{
      return !din.event_data.free && !din.event_data.happy_hour
    });
    console.log('has_any_dinners2', has_any_dinners)
    has_any_dinners = !!has_any_dinners.length;
    
    console.log('has_any_dinners', has_any_dinners);
    let date = event.date;
    dinners = dinners.filter(a=>{return a.dinner_date === date});
    let time = event.time;

    let has_event_within_hour = this.hasAnotherEventWithinHour(dinners, time);

    let has_event = dinners.filter(dinner => {return dinner.event_id === event_id})

    let day_name = Utilities.getDayName(day);

    let discussion_open = true;

    let test_twelve = new Date();

    if(isLoggedIn) {
      this.getSubscription(tentatives, event, org_user);
    }
    
    let test_thirteen = new Date();
    console.log('eight', this.getMinuteDifference(test_twelve, test_thirteen));

    this.setState({
      user_id,
      time,
      has_any_dinners,
      subscription_loading: isLoggedIn,
      status,
      isLoggedIn,
      discussion_open,
      has_event_within_hour,
      rsvps,
      tentatives,
      host,
      event, place,
      places,
      internal,
      slug,
      day_name,
      rsvped,
      cancel_url,
      date,
      day,
      mounted: true,
      messages
    }, async ()=>{
      this.refreshEvery20();
      let people_i_met_page_one = [];
      
      if(isLoggedIn) {
        people_i_met_page_one = await UserController.getPeopleIMet({page: 0});
      }

      this.setState({
        people_i_met: people_i_met_page_one,
        people_page: 0,
        load_more_button: people_i_met_page_one.length === 10
      })
    })
  }

  hasAnotherEventWithinHour(dinners, time) {
    // Function to convert time representation (e.g., 13.5) to Date object
    function convertTimeToDate(hourDecimal) {
      const hours = Math.floor(hourDecimal);
      const minutes = Math.round((hourDecimal % 1) * 60);
      const date = new Date();
      date.setHours(hours, minutes, 0, 0);
      return date;
    }

    // Function to check if two times are more than an hour apart
    function areTimesMoreThanOneHourApart(time1, time2) {
      const date1 = convertTimeToDate(time1);
      const date2 = convertTimeToDate(time2);

      // Calculate time difference in milliseconds
      const timeDifference = Math.abs(date1 - date2);

      // Check if the time difference is greater than one hour (3600 seconds * 1000 milliseconds)
      const isMoreThanOneHourApart = timeDifference > 3600 * 1000;

      return isMoreThanOneHourApart;
    }

    let has_conflict = false;
    let time2, result;
    for(var i = 0; i < dinners.length; i++) {
      time2 = dinners[i].time;
      result = areTimesMoreThanOneHourApart(time, time2);

      if(result) {
        return true;
      }
    }
    
    return false;
  }

  toggle(name, callback) {
    console.log(name, this.state[name])
    this.setState({
      re_render: true,
      [name]: !this.state[name]
    }, () => {
      if(callback) {
        callback();
      }
    })
  }

  subscriptionStart(num) {
    let { cancel_url } = this.state;
    let starting = num === 1 ? 'yearly_loading' : 'monthly_loading';

    this.setState({[starting]: true}, async () => {
      let response = await PaymentController.createCheckoutSession({num, cancel_url});

      if(response && response.success) {
        window.location.replace(response.url);
      }
    });
  }

  getMinuteDifference(date1, date2) {
    const diffInMilliseconds = date2 - date1;
    const minutes = Math.floor(diffInMilliseconds / (100));
    return minutes;
  }

  async getSubscription(tentatives, event, user) {
    let profile = user && user.profile ? user.profile : {};

    user = user && user.user ? user.user : user;
    let user_id = user && user.user_id ? user.user_id : null;

    console.log('useruser', user);

    let { event_id } = event;
    let { subscription, is_member } = await UserController.getUserSubscriptionLive(user);

    console.log('subscriptionone', subscription, is_member, user)

    let is_host = user && user.is_host ? true : false;

    let subscription_type = subscription ? subscription.type : null;
    
    let host_account = user ? user.host_account : null;

    let selected_people, selected_people_uninvites;
    let user_is_uninvited = false;

    if(event && !event.free) {
      selected_people = event.uninvited;

      selected_people = selected_people ? selected_people[user_id] : null;

      if(!selected_people) {
        let uninvited = event.uninvited ? event.uninvited : {};

        uninvited =  [].concat(...Object.values(uninvited));

        console.log('user_id', user_id);
        console.log('uninvited3', uninvited)

        user_is_uninvited = uninvited.indexOf(user_id) !== -1;
console.log('user_is_uninviteduser_is_uninvited', user_is_uninvited)
        if(user_is_uninvited) {
          window.location.replace('/')
        }
      } else {
        selected_people_uninvites = await UserController.getUsers({user_ids: selected_people})
      }
    }

    is_member = is_member || !!host_account;
    let pre_rsvped = tentatives.length && event_id && tentatives.filter(a=>{return a.user_id === user_id}).length;

    console.log('is_member2', is_member)

    this.setState({
      subscription_type,
      selected_people_uninvites,
      subscription_loading: false,
      is_member,
      pre_rsvped,
      subscription,
      is_host,
      profile,
      selected_people,
      user,
      user_is_uninvited
    })
  }

  getEventStatus(date) {
    // check if event is more than 7 days away to mark it as status: pre
    let targetDate = new Date(date); // Replace with your specific target date.
    // Calculate the date 7 days from now.
    let sevenDaysFromNow = new Date();
    sevenDaysFromNow.setDate(sevenDaysFromNow.getDate() + 7);

    // Adjust for the time zone (EST).
    sevenDaysFromNow = new Date(sevenDaysFromNow.toLocaleString('en-US', { timeZone: 'America/New_York' }));

    // Check if the target date is exactly 7 days away from the adjusted date.
    let isSevenDaysAway = targetDate.getTime() < sevenDaysFromNow.getTime();

    let status = isSevenDaysAway ? 'public' : 'pre';

    return status;
  }

  async updateUserLogging(afterRegisterClick) {
    window.location.reload();
    /* let { event_id, date, time } = this.state
    let token = await localStorage.getItem('token');

    this.props.onLogin();

    date = date.replace(/\./g, "/");

    let isLoggedIn = !!token;

    console.log('subscription', subscription);
    let org_user = await UserController.getUser();
    let profile = org_user.profile ? org_user.profile : {};
    let user = org_user.user;
    let is_host = org_user.user ? org_user.user.host : false;
    let user_id = org_user.user ? org_user.user.user_id : null;
    let host_account = user ? user.host_account : null;
    subscription = subscription.subscription;

    let is_member = subscription && subscription.status ? subscription.status === 'trialing' || subscription.status === 'active' : false;

    is_member = is_member || !!host_account;

    let rsvps, tentatives;
    ({rsvps, tentatives} = await UserController.getDateRsvpsForEvent({event_id}));
    let pre_rsvped = tentatives.length && event_id && tentatives.filter(a=>{return a.user_id === user_id}).length;
    pre_rsvped = !!pre_rsvped;

    let dinners = await UserController.getDinners();
    dinners = dinners && dinners.dinners ? dinners.dinners : [];

    
    let has_any_dinners = dinners.length > 0;

    dinners = dinners.filter(a=>{return a.dinner_date === date})

    let has_event_within_hour = this.hasAnotherEventWithinHour(dinners, time);

    let has_event = dinners.filter(dinner => {return dinner.event_id === event_id})
    let rsvped = !!dinners.length && event_id && has_event && has_event.length && has_event[0].event_id === event_id;

    console.log('rsvped', rsvped);

    this.setState({
      pre_rsvped,
      has_any_dinners,
      rsvped,
      is_member,
      user_id,
      is_host,
      has_event_within_hour,
      isLoggedIn,
      profile
    }, () => {
      if(afterRegisterClick) {
        console.log('getting here2');
        afterRegisterClick();
      }
    }) */
  }

  openInParent(content, width, height, put_in_header, on_close, title_component) {

    this.modal_ref.setContent(content, width, height, put_in_header, null, on_close, title_component)
  }

  /* openInParent(content, state_variable, width, height, backgroundColor, no_scroll) {
    console.log('drawing in parent again', state_variable, this.state[state_variable]);

    const clickScreen = null;
    //() =>this.toggleState(state_variable)

    let window_width = Dimensions.get('window').width;
    let is_mobile = window_width <= 700;

    let inner_style = {display: 'block', width: is_mobile ? '100vw' : width, height, left: is_mobile ? 0 : `calc(50% - ${width / 2}px)`}

    this.props.putInParent(!this.state[state_variable] ? 
      <TouchableOpacity activeOpacity={1} style={{zIndex: 10000000, position: 'fixed', width: '100vw', height: '100vh'}} onPress={clickScreen}>
      <>
        <TouchableOpacity activeOpacity={1} onPress={()=>this.toggleState(state_variable)} style={global_style.modal_overlay}></TouchableOpacity>
        <View style={style.payment_content}>
        <View style={[global_style.modal_content, inner_style]}>
            <TouchableOpacity onPress={()=>this.toggleState(state_variable)}><Text style={global_style.modal_close_x}>x</Text></TouchableOpacity>
            {no_scroll ?
            <View style={{height: height - 80, backgroundColor}}>
              {content}
            </View>
            :
            <ScrollView style={{height: height - 80, backgroundColor}}>
              {content}
          </ScrollView>}
        </View>
        </View>
      </>

      </TouchableOpacity> : null);

    this.setState({
      [state_variable]: !this.state[state_variable]
    });
  } */

  openProfileBubble(profile_attendee) {

    /* this.props.putInParent(!this.state.is_profile_open ? 
      <TouchableOpacity activeOpacity={1} style={{zIndex: 10000000, position: 'fixed', width: '100vw', height: '100vh'}} onPress={this.openProfileBubble}>
        <Profile onPress={this.openProfileBubble} attendee={profile_attendee} />      
      </TouchableOpacity> : null); */

      this.modal_ref.setContent(<Profile onPress={this.openProfileBubble}  attendee={profile_attendee} />, 600, '100vh', null)
  }

  drawAttendee(attendee) {
    let photo_url, name;

    console.log('attendee', attendee)

    if(attendee) {
      let profile = attendee.profile;

      if(typeof profile === 'string') {
        profile = JSON.parse(profile);
      }

      ({photo_url, name} = profile);

      if(attendee.name) {
        name = attendee.name;
      }
    }

    return <TouchableOpacity onPress={()=>this.openProfileBubble(attendee)} style={[style.attendee_bubble]}>
      <Image style={style.attendee_photo} source={{uri: photo_url ? photo_url : 'https://diningsocial-assets.s3.amazonaws.com/empty_profile.png'}} />
        {/* <Text style={style.attendee_name_text}>{name ? name[0].toUpperCase() : "M"}</Text> */}
    </TouchableOpacity>
  }

  drawAttendees() {
    let { rsvps, tentatives, event, rsvped, user_id, host, status } = this.state;

    let attendees = rsvps;
    console.log('rsvps', rsvps);
    console.log('tentatives', tentatives);

    console.log('host', host);

    let { free } = event;

    if(host) {
      attendees = attendees.filter(attend=>{return attend.user_id !== host.user_id})
    }

    let liked = !!tentatives.filter(tent=>{return tent.user_id === user_id}).length;

    let attendee_user_ids = rsvps.map(a=>{return a.user_id});

    tentatives = tentatives.filter((tent) => !attendee_user_ids.includes(tent.user_id));

    let { hosted, is_full, demographic, canceled, rsvp_limit } = event;

    if(!is_full && rsvp_limit) {
      if(typeof rsvp_limit === 'string') { rsvp_limit = parseInt(rsvp_limit)}
      is_full = rsvp_limit === attendees.length;
    }

    let created = !!event.demographic;

    let description = hosted ? `There's no risk in being the first to RSVP. Dinners don't happen if at least 3 people don't sign up.` : (demographic ? `The first person to RSVP chose the theme to be "${demographic}". If you're in that demographic, join them for dinner!` :`First person to RSVP chooses the restaurant and the demographic of the group, so don't be shy!`)

    const onLike = async () => {
      let { isLoggedIn } = this.state;

      if(isLoggedIn) {
        event.date = the_date;
        let response = await UserController.onLike({event});
        let events = await AuthController.getEvents();
        let { rsvps, tentative } = await UserController.getDateRsvps();

        this.setState({rsvps, tentative, events});
      } else {
        this.props.navigate('/register')
      }
    }

    return !canceled ? <View style={style.info_bubble_popup}>
      {status === 'public' || free ? <>
        <Text style={[global_style.panel_sub_title, global_style.padding_subtitle]}>Attendees</Text>
        {hosted ? <>
          {!created ? <Text style={style.dont_shy}>{description}</Text> : null}
          <Text style={style.tentative_title}>Host</Text>
          {host ? this.drawAttendee(host) : "Host is needed"}
          <View style={[global_style.line_breaker]}></View>
          <Text style={style.tentative_title}>RSVPs</Text>
          <View style={[style.one_bucket, {zIndex: 100}]}>
            <View style={style.first}>
              {attendees.map((attendee, i)=>{return this.drawAttendee(attendee)})}
            </View>
          </View>
          
        </> : <>
            {!created ? <Text style={style.dont_shy}>{description}</Text> : null}
            <View style={[style.one_bucket, {zIndex: 99}]}>
            <View style={style.first}>
              {attendees.map((attendee, i)=>{return this.drawAttendee(attendee)})}
            </View>
            </View>
        
        </>}
       </>  : <>
       {hosted ? <>
       <Text style={style.tentative_title}>Host</Text>
          {host ? this.drawAttendee(host) : "Host is needed"}
          <View style={[global_style.line_breaker]}></View> </> : null}


          <Text style={style.tentative_title}>Member RSVPs</Text>
          <View style={[style.one_bucket, {zIndex: 100}]}>
            <View style={style.first}>
              {attendees.map((attendee, i)=>{return this.drawAttendee(attendee)})}
            </View>
          </View>


       <Text style={[global_style.panel_sub_title, global_style.padding_subtitle]}>Pre-RSVPs</Text>
        <View style={[style.one_bucket, {zIndex: 99}]}>
            <View style={style.first}>
              {tentatives.map((attendee, i)=>{return this.drawAttendee(attendee)})}
            </View>
            
          </View>
          <Text style={[global_style.notification_text, {color: 'black'}]}>* RSVPs will open to non-members 7 days prior to the event</Text>
       </>}
       {/* {created ?
       <Text style={style.tentative_title}>
          Tentative RSVPs  
          <TouchableOpacity onPress={()=>{this.setState({bubble_open: !this.state.bubble_open})}} style={[]}><Text style={style.info_button}>i</Text></TouchableOpacity>
          {this.state.bubble_open ? 
          <>
            <TouchableOpacity onPress={()=>{this.setState({bubble_open: !this.state.bubble_open})}} style={style.overlay}></TouchableOpacity>
            <TouchableOpacity style={style.info_bubble} onPress={()=>{this.setState({bubble_open: !this.state.bubble_open})}}><Text style={style.modal_text_title}>Tentative RSVPs</Text><Text style={style.modal_text}>Not sure of your plans yet, but interested. Let others know you're thinking of coming.</Text></TouchableOpacity>
          </> : null}
          
        </Text> : null} */}
        {/* {created ?
        <View style={[style.one_bucket, {zIndex: 99}]}>
          <View style={style.first}>
            {tentatives.map((attendee, i)=>{return this.drawAttendee(attendee)})}
          </View>
          {!liked && !rsvped && !is_full  ?
          <TouchableOpacity onPress={onLike}><Text style={style.thumbs}>👍</Text></TouchableOpacity> : null}
        </View> : null} */}
    </View> : null
  }

  scrollTo(the_ref) {

    if(the_ref && the_ref.scrollIntoView) {
      the_ref.scrollIntoView();
    }
  }

  rsvpChange(num_rsvp, itemIndex) {
    this.setState({
      num_rsvp,
    })
  }

  checkProfile() {
    let { profile } = this.state;

    return !!profile.photo_url && !!profile.name && !!profile.job;
  }

  async confirmMakeHost() {
    let { event_id } = this.state;

    await UserController.confirmMakeHost({event_id});

    window.location.reload();
  }

  async confirmResignHost() {
    let { event_id } = this.state;

    await UserController.confirmResignHost({event_id});

    window.location.reload();
  }

  async confirmCancel() {
    let { event_id } = this.state;

    this.setState({cancel_loading: true}, async ()=>{
      await UserController.confirmCancel({event_id});

      window.location.reload();
    });
  }

  drawMessage(message) {
    let { profile, message_date } = message;

    console.log('message', message);
    console.log('profile', profile);

    let date = new Date(parseInt(message_date));

    let options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    let formatter = new Intl.DateTimeFormat('en-US', options);
    
    let date_time = formatter.format(date);

    return <View style={style.one_message}>
      {this.drawAttendee({profile, name: profile.name})}
      <View style={[global_style.column, style.chat_data]}>
        <Text style={style.chat_time}>{date_time}</Text>
        <Text style={style.chat_text}>{message.message}</Text>
      </View>
    </View>
  }

  async refreshEvery20() {
    let { event_id } = this.state;

    if(this.intervalId) {
      clearInterval(this.intervalId);
    }

    if(this.timeoutId) {
      clearTimeout(this.timeoutId)
    }

    const codeToRunEvery20Seconds = async () => {
      console.log('Code running every 20 seconds...');
      let messages = await EventController.getMessages({event_id});

      this.setState({
        messages
      })
    }

    const anotherFunctionAfter10Minutes = () => {
      console.log('Another function after 10 minutes...');
      this.setState({
        message_refresh: true
      })
    }

    this.intervalId = setInterval(codeToRunEvery20Seconds, 20000); // 20,000 milliseconds = 20 seconds

    // Set a timer to run another function after 10 minutes (600,000 milliseconds = 10 minutes)
    this.timeoutId = setTimeout(() => {
      clearInterval(this.intervalId); // Stop the code from running every 20 seconds
      anotherFunctionAfter10Minutes(); // Run another function
    }, 600000); // 600,000 milliseconds = 10 minutes
  }

  async sendMessage() {
    let { text_message, event_id } = this.state;

    if(text_message && text_message.trim()) {
      this.setState({sending_message: true}, async () => {

        let created_at = (new Date()).getTime();

        let messages = await UserController.sendMessage({message: text_message, event_id, created_at });

        this.refreshEvery20();

        this.setState({
          text_message: null,
          messages,
          sending_message: false
        })
      });
    }
  }

  async rsvpClick() {
    let { isLoggedIn } = this.state;
    if(!isLoggedIn) {
        this.onLoginClick(this.chooseAfterLogin);
    } else {
      let has_profile = this.checkProfile();

      if(has_profile) {
        this.setState({rsvp_loading: true}, async () => {
          let { is_member, date, chosen_dinner_time, cancel_url, event, event_id, type, chosen_hosted, chosen_demographic, chosen_restaurant, checked_host, user_id, status, subscription_type } = this.state;

          let charge_discount = (is_member && subscription_type !== 2)

          console.log('type, chosen_hosted', type, chosen_hosted);

          if(date) {
            event = {
              ...event,
              date
            }
          }

          if(chosen_dinner_time) {
            event = {
              ...event,
              time: parseFloat(chosen_dinner_time)
            }
          }

          if(type === "-1" || type === -1) {
            if(chosen_hosted) {
              event = {
                ...event,
                hosted: chosen_hosted === "1"
              }

              if(chosen_hosted === "1" && checked_host) {
                event = {
                  ...event,
                  host: user_id
                }
              }
            }

            // For when event has already been created and user is marking themselves as host
            if(checked_host) {
              event = {
                ...event,
                host: user_id
              }
            }

            if(chosen_demographic) {
              event = {
                ...event,
                demographic: chosen_demographic,
              }
            }

            if(chosen_restaurant) {
              event = {
                ...event,
                place_id: chosen_restaurant
              }
            }
          }

          if(type || type === 0) {
            event = {
              ...event,
              type
            }
          }

          if(event_id) {
            event = {
              ...event,
              event_id
            }
          }

          console.log('event', event)

          let old_event_id = event_id;

          // Host gets free admission
          if((is_member && !charge_discount) || checked_host || event.free || status === 'pre') {
            console.log('1')
              let response = await UserController.confirmRSVP({date, event, status});
              let { event_id } = response;

              if(old_event_id) {
                window.location.reload();
              } else {
                window.location.replace(`${window.location.pathname}/${event_id}`);
              }
          } else {
            console.log('2')

            let price;
            if(charge_discount) {
              price = window.location.hostname === 'localhost' ? config.stripe_price_basic_member_test : config.stripe_price_basic_member_live
            }

            let response = await PaymentController.createEventSession({ cancel_url, event, price });
            
            console.log('response', response);
        
            if(response && response.success) {
              window.location.replace(response.url);
            }
          }
        });
      } else {
        this.fillOutProfile();
      }
    }
  }

  fillOutProfile() {
    let { profile, loading_go_profile, cancel_url } = this.state;
    this.props.putInParent(null);


    const afterProfileUpdate = (profile) => {
      this.props.putInParent(null);
      this.setState({profile, is_open: false}, ()=>{
        this.rsvpClick(); 
      });
    }

    let window_width = Dimensions.get('window').width;

    const goToProfile = () => {
      this.setState({loading_go_profile: true}, async ()=>{
        let { cancel_url } = this.state;
        await localStorage.setItem('cancel_url', cancel_url)
        window.location.replace('/dashboard/profile')
      })
    }

    this.openInParent(<View style={global_style.center_content}>
      
        <Text style={{fontFamily: 'Ysabeau', marginTop: 30, fontSize: 20, textAlign: 'center', marginBottom: 60 }}>Please fill out your photo, name and job before RSVPing.</Text>
        <View style={{marginTop: 0, textAlign: 'left'}}>
          <View>
            <Button title={<Text style={style.club_button_text}>Go to Profile</Text>}
              loading={loading_go_profile}
              disable_imediately={true}

              style_inactive={{backgroundColor: '#85bebe',
                alignSelf: 'center',
                borderRadius: 3,
                paddingTop: 5,
                paddingBottom: 7,
                paddingHorizontal: 20, width: 207}}

              style={{backgroundColor: '#e32652',
                alignSelf: 'center',
                borderRadius: 3,
                paddingTop: 5,
                paddingBottom: 7,
                paddingHorizontal: 20, width: 207}}

              onPress={goToProfile} /></View>
        </View>
    </View>, window_width <= 700 ? window_width : 400, 300)
  }

  goDirections(link) {
    window.open(link);
  }

  goTo(link) {
    window.open(link)
  }

  addCalendar(event, place) {
    let { day, date, slug } = this.state;
    let { time, type } = event;

    let day_date, month, year;
    let tokens = date.split('/');

    day_date = tokens[1];
    if(day_date.length === 1) {
        day_date = `0${day_date}`
    }

    month = tokens[0];
    if(month.length === 1) {
        month = `0${month}`
    }

    year = tokens[2];

    let hours = Math.floor(time);
    let minutes = Math.round((time - hours) * 60);

    if(minutes.toString().length === 1) {
      minutes = `0${minutes}`
    }

    let end_time = time + 1.5;

    console.log('end_time', end_time)

    let end_hours = Math.floor(end_time);
    let end_minutes = Math.round((end_time - end_hours) * 60);

    if(end_minutes.toString().length === 1) {
      end_minutes = `0${end_minutes}`
    }

    let dinner_begin_time = `${hours}${minutes}00`;
    let dinner_end_time = `${end_hours}${end_minutes}00`;

    let dates_link = `${year}${month}${day_date}T${dinner_begin_time}/${year}${month}${day_date}T${dinner_end_time}`;

    let {restaurant_name, address } = place;

    let dining_type = Utilities.getEventTypeName(type);
    let dining_type_capitalized = `${dining_type[0].toUpperCase()}${dining_type.substring(1, dining_type.length)}`

    let link = `http://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(`Dining & Social Club ${dining_type_capitalized} at ${restaurant_name}`)}&dates=${dates_link}&details=${encodeURIComponent(`Dining Social ${dining_type_capitalized} at ${restaurant_name} - Meet new friends over ${dining_type} - https://www.diningsocialnj.com/${slug}`)}&location=${address}&trp=false`;

    window.open(link);
  }

  updateInput(value, name) {

    this.setState({
      [name]: value
    });
  }

  updateState(value, name) {

    let { chosen_demographic, chosen_restaurant, chosen_dinner_time, chosen_hosted } = this.state;

    if(chosen_demographic !== null && chosen_demographic !== undefined) {
      chosen_demographic = chosen_demographic.toString();
    }

    if(chosen_restaurant !== null && chosen_restaurant !== undefined) {
      chosen_restaurant = chosen_restaurant.toString();
    }

    value = value.toString();

    let confirm_inactive = true;

    if(name === 'chosen_restaurant') {
      confirm_inactive = !chosen_hosted || chosen_hosted === "-1" || !chosen_dinner_time || chosen_dinner_time === "-1" || !chosen_demographic || chosen_demographic === "-1" || !value || value === "-1"
    }

    if(name === 'chosen_demographic') {
      confirm_inactive = !chosen_hosted || chosen_hosted === "-1" || !chosen_dinner_time || chosen_dinner_time === "-1" || !chosen_restaurant || chosen_restaurant === "-1" || !value || value === "-1"
    }

    if(name === 'chosen_dinner_time') {
      confirm_inactive = !chosen_hosted || chosen_hosted === "-1" || !chosen_restaurant || chosen_restaurant === "-1" || !chosen_demographic || chosen_demographic === "-1"  || !value || value === "-1"
    }

    if(name === 'chosen_hosted') {
      confirm_inactive = !chosen_dinner_time || chosen_dinner_time === "-1" || !chosen_restaurant || chosen_restaurant === "-1" || !chosen_demographic || chosen_demographic === "-1"  || !value || value === "-1"
    }

    this.setState({
      confirm_inactive,
      [name]: value
    })
  }

  toggleState(name, callback) {
    console.log('toggling', name, this.state[name])

    if(this.state[name]) {
      this.props.putInParent(null)
    }

    let data = {
      is_rsvp_confirm_open: false,
      is_register_open: false,
      is_login_open: false,
      is_open: false, 
      is_make_host_open: false, 
      is_resign_host_open: false,
      is_cancel_open: false
    }

    if(name) {
      data = {
        ...data,
        [name]: !this.state[name]
      }
    } else {
      this.props.putInParent(null)
    }

    this.setState(data, () => {
      if(callback) {callback()}
    })
  }

  onRegisterClick(afterRegisterClick) {
    this.toggleState('is_register_open');

    let onRegister = async () => {
      console.log('getting here');
      await this.updateUserLogging(afterRegisterClick);
    }

    this.props.putInParent(null);
    this.openInParent(<View style={global_style.center_content}><Text style={{fontFamily: 'Ysabeau', fontSize: 20, textAlign: 'center', marginBottom: 30, marginTop: -22}}><RegisterScreen onRegister={onRegister} onLoginClick={this.onLoginClick} /></Text>
    </View>, 500, 550)
  }

  onLoginClick(afterLoginCallback) {
    this.toggleState('is_login_open');

    let onLogin = async () => {
      console.log('getting here');
      await this.updateUserLogging(afterLoginCallback);
    }

    this.openInParent(<View style={global_style.center_content}><Text style={{fontFamily: 'Ysabeau', fontSize: 20, textAlign: 'center', marginBottom: 30, marginTop: -40}}><LoginRegisterScreen cancel_url={this.state.cancel_url} putInParent={this.props.putInParent} onLogin={onLogin} onRegisterClick={()=>this.onRegisterClick(afterLoginCallback)} /></Text>
    </View>, 400, 500)
  }

  drawPrice(price, free, event) {
    let { isLoggedIn, is_member, has_any_dinners } = this.state;

    let onPress;

    if(isLoggedIn) {
      onPress = () => { this.props.navigate('/dashboard/subscription') };
    } else {

      onPress = this.onLoginClick
    }

    let fee_back = !is_member ? <View><Text style={style.price_explainer_wrapper}>Get your full fee back when you 1. follow us on Instagram or Facebook, 2. take a photo of your food and your new friends at dinner, 3. and tag us with the photo/s on Instagram or Facebook.</Text></View> : null;

    const afterInfo = () => {
      this.props.putInParent(null);
      
      let { isLoggedIn } = this.state;

      let has_profile = this.checkProfile();

      if(!isLoggedIn) {
        this.onLoginClick(this.chooseAfterLogin);
      } else {
        if(has_profile) {
          this.openRSVP();
        } else {
          this.fillOutProfile();
        }
      }
    }

    let member_price = event.member_price ? event.member_price : 0;
    let fee_back_price = event.fee_back_price ? event.fee_back_price : 0;
    let is_fee_back = event.is_fee_back ? event.is_fee_back : true;

    price = typeof price === 'string' ? price : `$${price}`;
    let p_width = typeof price === 'string' ? 46 : 36;

    fee_back_price = typeof fee_back_price === 'string' ? fee_back_price : `$${fee_back_price}`;
    let f_width = typeof fee_back_price === 'string' ? 46 : 36;

    member_price = typeof member_price === 'string' ? member_price : `$${member_price}`;
    let m_price = typeof member_price === 'string' ? 46 : 36;

    return free ? <View style={style.price_wrapper}>
      { isLoggedIn && has_any_dinners && event.new_member || (!isLoggedIn && event.new_member) ? <Text style={style.price_text_free_event}>EVENT ONLY OPEN TO NEW MEMBERS</Text> :
      <Text style={style.price_text_free_event}>FREE EVENT</Text>}
    </View> : 
    
    <TouchableOpacity onPress={this.openRSVP} style={global_style.row}>
      <View style={global_style.column}>
        <View style={style.pricing_divider}>
          <Text style={[style.price_title]}>One-time</Text><Text style={[style.price_text, {width: p_width}]}>{price}</Text>
        </View>
        <View style={style.pricing_divider}>
          <Text style={[style.price_title]}>Member </Text><Text style={[style.price_text_member, {width: m_price}]}>{member_price}</Text>
        </View>
      {is_fee_back ?
        <View style={style.pricing_divider}>
          <Text style={[style.price_title]}>Fee Back Program</Text><Text style={[style.price_text_free, {width: f_width}]}>{fee_back_price}</Text>
        </View> : null}
        <View><Text style={[style.price_explanation]}>* This fee does not include the cost of the dinner. The one-time fee is offered to non-members who would like to attend an event before becoming a member.</Text></View>
        {/* <View style={style.price_wrapper}>
          <Text style={style.price_text}>${price}</Text> 
          
          <TouchableOpacity onPress={onPress}><Text style={style.price_member_text}>(member price<Text style={style.price_member_text_number}> $0.00</Text>)</Text></TouchableOpacity>


        </View>
      {fee_back} */}

      </View>
      
    </TouchableOpacity>
  }

  chooseAfterLogin() {
    let { is_member, rsvped, pre_rsvped, event, user_id, status, has_any_dinners } = this.state;

    console.log('after login/regiser', is_member, rsvped, pre_rsvped, event, user_id);

    let is_events_host = event.hosted && event.host === user_id;
    window.location.reload();
    /* if(has_any_dinners && event.new_member) {
      window.location.reload();
    } else {
      if(!rsvped && !pre_rsvped && !is_events_host) {
        if(!is_member && status !== 'public') {
          this.openPreConfirm();
        } else {
          this.openRSVP();
        }
      } else {
        this.toggleState();
      }
    } */
  }

  openPreConfirm() {
    let { is_member, checked_host, event, user_id, rsvp_loading, isLoggedIn } = this.state;
    console.log('opening preconfirm again')
    this.openInParent(<View style={global_style.center_content}><Text style={{fontFamily: 'Ysabeau', fontSize: 20, textAlign: 'center', marginBottom: 30, marginTop: 20}}>Because this event is more than 7 days away, you're only allowed to pre-RSVP. When the event becomes open, we notify our pre-RSVPed members first.</Text>
      <Button title={<Text style={style.club_button_text}>Pre-RSVP</Text>}
          loading={rsvp_loading}
          disable_imediately={true}

          style_inactive={{backgroundColor: '#85bebe',
            alignSelf: 'center',
            borderRadius: 3,
            paddingTop: 5,
            paddingBottom: 7,
            paddingHorizontal: 20, width: 207}}

          style={{backgroundColor: '#e32652',
            alignSelf: 'center',
            borderRadius: 3,
            paddingTop: 5,
            paddingBottom: 7,
            paddingHorizontal: 20, width: 207}}

          onPress={this.rsvpClick} /></View>, 400, 400)
  }

  togglePreRSVP() {
    let { isLoggedIn } = this.state;

    if(!isLoggedIn) {
      this.onLoginClick(this.chooseAfterLogin);
    } else {
      let has_profile = this.checkProfile();
      if(has_profile) {
        this.openPreConfirm();
      } else {
        this.fillOutProfile();
      }
    }
  }

  drawInvitePanel() {

    return <View>
      <Text></Text>
    </View>
  }

  learnMore() {
    let { isLoggedIn } = this.state;

    if(!isLoggedIn) {
      window.open('/pricing')
    } else {
      window.open('/dashboard/subscription')
    }
  }

  openRSVP(onClick) {
    console.log('opening rsvp again')
    let { can_cancel, cancel_text, can_rsvp } = this.getCanCancel(true);
    let { is_member, event, rsvp_loading, loading, yearly_loading, monthly_loading, ack_check, subscription_type } = this.state;

    let page_width = Dimensions.get('window').width;
    let page_height = Dimensions.get('window').height;

    let price = this.getPrice();

    let show_pricing_panel = !is_member || (is_member && subscription_type !== 2)

    if(event.free) {
      
      this.openInParent(<View style={global_style.center_content}><Text style={{fontFamily: 'Ysabeau', fontSize: 20, textAlign: 'center', marginBottom: 30, marginTop: 100, marginHorizontal: 40}}>Please do let us know if you need to cancel by returning to this page and clicking the 'cancel RSVP' button.</Text>
      {can_rsvp ? <Button title={<Text style={style.club_button_text}>Complete RSVP</Text>}

          disable_imediately={true}

          style_inactive={{backgroundColor: '#85bebe',
            alignSelf: 'center',
            borderRadius: 3,
            paddingTop: 5,
            paddingBottom: 7,
            paddingHorizontal: 20}}

          style={{backgroundColor: '#e32652',
          alignSelf: 'center',
          borderRadius: 3,
          paddingTop: 5,
          paddingBottom: 7,
          paddingHorizontal: 20}}
          onPress={this.rsvpClick} /> : null}</View>, 500, 300, null, null, <Text style={{fontFamily: 'Ysabeau', fontSize: 26, textAlign: 'center',}}>RSVP</Text>)
    } else {

      let rsvp_confirm_text;

    /*  if(checked_host || (event.hosted && event.host === user_id)) {
        rsvp_confirm_text = `As the host of the event, you will not be able to cancel your RSVP through the site. Please make sure you're able to make it!`
      } else { */
        rsvp_confirm_text = is_member ? "Please be sure to let us know if you need to cancel your RSVP. Our members do not get charged for canceling, however we do want to make sure the spot opens up for others to join the event. Now, you can also choose which people you would like to join you! You'll be able to do so after you RSVP." : cancel_text;
      //}

      console.log('rsvp_loading2', rsvp_loading)

      let modal_width = page_width <= 400 ? page_width * .9 : page_width * .8;
      let modal_member_width = 500;
      let is_mobile = page_width <= 400;

      let invite_panel = this.drawInvitePanel();

      let non_member_panel = <>
      <View style={[global_style.row_mobile_switch_column]}>
        <View style={[ global_style.colum, style.rsvp_column ]}>{
          is_mobile ?
    
            <Text style={[style.rsvp_title, {cursor: 'pointer'}]}>${price} - One-time</Text>
          :
          <Text style={[style.rsvp_title]}>One-time</Text>
        }
          {
            is_mobile ? <View style={{height: 387}}>
              <Text style={[style.rsvp_price_one_time]}>${price}</Text>

              <View style={[style.rsvp_line, {marginTop: -10}]}>
                <Text style={[style.rsvp_line_text, {textTransform: 'uppercase'}]}>
                  This fee does not include the cost of the meal or drink. It is an attendance fee.
                </Text>
              </View>
              <View style={[style.rsvp_line_top]}>
                <Text style={[style.rsvp_line_text]}>
                This fee is non-refundable unless the event is canceled
                </Text>
              </View>
              <View style={[style.rsvp_line_top]}>
                <Text style={[style.rsvp_line_text]}>
                  After the fee has been processed, it is not refundable. However, you can cancel your RSVP before 9am the day before the event.
                </Text>
              </View>
              <View style={[style.rsvp_button]}>
                <Button title={<Text style={style.club_button_text}>Confirm RSVP</Text>}
                  loading={loading}
                  style={style.club_button}
                  disable_imediately={true}
                  style_inactive={style.club_button}
                  inactive={false}
                  onPress={this.rsvpClick} />
              </View>
            </View> : <>
              <Text style={[style.rsvp_price_one_time]}>${price}</Text>

              <View style={[style.rsvp_line, {marginTop: -10}]}>
                <Text style={[style.rsvp_line_text, {textTransform: 'uppercase'}]}>
                  This fee does not include the cost of the meal or drink. It is an attendance fee.
                </Text>
              </View>
              <View style={[style.rsvp_line_top]}>
                <Text style={[style.rsvp_line_text]}>
                This fee is non-refundable unless the event is canceled
                </Text>
              </View>
              <View style={[style.rsvp_button]}>
                <Button title={<Text style={style.club_button_text}>Confirm RSVP</Text>}
                  loading={loading}
                  disable_imediately={true}
                  style={style.club_button}
                  style_inactive={style.club_button}
                  inactive={false}
                  onPress={this.rsvpClick} />
              </View>
            </>
          }

        </View>
        <View style={[global_style.column, style.rsvp_column]}>
          <Text style={[style.rsvp_title]}>Membership</Text>
          <Text style={[style.rsvp_price_member]}>$0 per event</Text>
          <View>
              <Text style={[style.rsvp_line_text]}>
                Unlimited events free of attendance fee. Additional ability to select members you'd like to meet.
                {/* Subscribe monthly or yearly and join an unlimited number of dinners, brunches, happy hours or coffees free of attendance fee. This does not include the cost of the meal or drink. */}
              </Text>
              <View style={[style.rsvp_membership_button]}>
              <Button loading={yearly_loading} 
                disable_imediately={true}
                onPress={this.learnMore}
                style={[style.subscription_box, style.subscription_yearly]} title={
                <View style={[style.a_column, {width: 250}]}>
                  <Text style={style.club_button_text}>Learn More</Text>
                </View>} />
            </View>  
            {/* <View style={[style.rsvp_membership_button, {marginTop: 10}]}>
              <Button loading={monthly_loading} 
                disable_imediately={true}
                onPress={onClick ? onClick : ()=>this.subscriptionStart(0)}
                              style={[style.subscription_monthly]} title={
                <View style={[style.a_column, {width: 250}]}>
                  <Text style={style.club_button_text}>Subscribe Monthly</Text>
                  <Text style={style.club_button_text}>$30/month</Text>
                </View>} />
            </View>
            <View style={[style.rsvp_membership_button]}>
              <Button loading={yearly_loading} 
                disable_imediately={true}
                onPress={onClick ? onClick : ()=>this.subscriptionStart(1)}
                style={[style.subscription_box, style.subscription_yearly]} title={
                <View style={[style.a_column, {width: 250}]}>
                  <Text style={style.club_button_text}>Subscribe Yearly</Text>
                  <Text style={style.club_button_text}>$25/month paid yearly</Text>
                </View>} />
            </View> */}
            
          </View>
        </View>
        <View style={[global_style.column, style.rsvp_column]}>
          <Text style={[style.rsvp_title]}>Fee Back Program</Text>
          <Text style={[style.rsvp_price_free]}>$0</Text>

            <View style={[style.rsvp_line, {marginTop: -10}]}>
              <Text style={[style.rsvp_line_text]}>
                You can get your whole one-time attendance fee back for helping us spread the word on social media after the event.
              </Text>
            </View>
            <View style={[style.rsvp_line_top]}>
              <Text style={[style.rsvp_line_text]}>
                Inquire with your host at the event or by writing to us at team@diningsocialnj.com
              </Text>
            </View>
            <View style={[style.rsvp_line_top]}>
              <Text style={[style.rsvp_line_text]}>
                This fee does not include the cost of the meal or drink.
              </Text>
            </View>
            <View style={[style.rsvp_button]}>
              <Button title={<Text style={style.club_button_text}>Confirm RSVP</Text>}
                loading={loading}
                style={style.club_button}
                disable_imediately={true}
                style_inactive={style.club_button}
                inactive={false}
                onPress={this.rsvpClick} />
            </View>
        </View>
      </View>
      </> 

      let member_panel = <>
          <Text style={{fontFamily: 'Ysabeau', fontSize: 20, textAlign: 'center', marginBottom: 30, marginTop: 20}}>{rsvp_confirm_text}</Text>

          {can_rsvp ? <Button title={<Text style={style.club_button_text}>RSVP</Text>}
            disable_imediately= {true} 

            style_inactive={{backgroundColor: '#85bebe',
              alignSelf: 'center',
              borderRadius: 3,
              paddingTop: 5,
              paddingBottom: 7,
              paddingHorizontal: 20, width: 207}}

            style={{backgroundColor: '#e32652',
            alignSelf: 'center',
            borderRadius: 3,
            paddingTop: 5,
            paddingBottom: 7,
            paddingHorizontal: 20, width: 207}}
            onPress={this.rsvpClick} /> : null}
        </>

      let show_non_member_pane = !is_member || (is_member && subscription_type !== 2)

      console.log('subscription_type', subscription_type, typeof subscription_type)

      this.openInParent(<View style={global_style.center_content}>

        {show_non_member_pane ? non_member_panel : member_panel}

        </View>, !show_pricing_panel ? modal_member_width : modal_width, !show_pricing_panel ? 500 : page_height * .9)


      /* this.openInParent(<View style={global_style.center_content}><Text style={{fontFamily: 'Ysabeau', fontSize: 20, textAlign: 'center', marginBottom: 30, marginTop: 20}}>{rsvp_confirm_text}</Text>

      {!is_member ? <>
        <Input 
          check_style={{check_wrapper: {flexDirection: 'row', flexWrap: 'wrap', display: 'flex', marginLeft: 10}, check_text: {
              marginLeft: 10,
              fontFamily: 'Ysabeau',
              fontSize: 16,
              width: 190, 
              color: 'white'}}}
          type={"check"} 
          onCheck={()=>{this.setState({ack_check: !this.state.ack_check})}} 
          checked={ack_check}
          label={<Text style={[global_style.normal_text_dark]}>I understand that the ${price} fee is only for attendance and does not include the cost of the dinner.</Text>} />
      </> : null}

      {can_rsvp ? <Button title={<Text style={style.club_button_text}>RSVP</Text>}
          disable_imediately= {true} 

          style_inactive={{backgroundColor: '#85bebe',
            alignSelf: 'center',
            borderRadius: 3,
            paddingTop: 5,
            paddingBottom: 7,
            paddingHorizontal: 20, width: 207}}

          style={{backgroundColor: '#e32652',
          alignSelf: 'center',
          borderRadius: 3,
          paddingTop: 5,
          paddingBottom: 7,
          paddingHorizontal: 20, width: 207}}
          onPress={this.rsvpClick} /> : null}</View>, "is_rsvp_confirm_open", 400, is_member ? 400 : 300, 'white') */
    }
  }

  toggleConfirmRSVP() {
    let { isLoggedIn } = this.state;

    

    if(!isLoggedIn) {
      this.onLoginClick(this.chooseAfterLogin);
    } else {
      let has_profile = this.checkProfile();
      if(has_profile) {
        this.openRSVP();
      } else {
        this.fillOutProfile();
      }
    }
  }

  toggleMakeHost() {

    this.openInParent(<View style={global_style.center_content}>
  <Text style={{fontFamily: 'Ysabeau', fontSize: 20, textAlign: 'center', marginBottom: 60}}>
    Thanks for choosing to host! We'll send out a notification to all attendees letting them know.
  </Text>
  <Button title={<Text style={style.club_button_text}>Confirm</Text>}

style_inactive={{backgroundColor: '#85bebe',
            alignSelf: 'center',
            borderRadius: 3,
            paddingTop: 5,
            paddingBottom: 7,
            paddingHorizontal: 20, width: 207}}

      style={{backgroundColor: '#e32652',
      borderRadius: 3,
      paddingTop: 5,
      paddingBottom: 7,
      paddingHorizontal: 20, width: 207}}
      disable_imediately={true}
      onPress={this.confirmMakeHost} /></View>, 400, 300)
  }

  toggleResignHost() {

    let { can_cancel, can_cancel_by_date } = this.getCanCancel();

    let the_text;

    if(can_cancel_by_date) {
      the_text = <Text style={{fontFamily: 'Ysabeau', fontSize: 20, textAlign: 'center', marginBottom: 60}}>
        Once you resign as host, we'll send out a notification to all attendees letting them know a host is needed.
      </Text>
    } else {
      the_text = <Text style={{fontFamily: 'Ysabeau', fontSize: 20, textAlign: 'center', marginBottom: 60}}>
        Since you're past the deadline for cancelation, resigning as host means you could lose your host status. Please do still let us know if you can't make it.
      </Text>
    }

    this.openInParent(<View style={global_style.center_content}>
  {the_text}
  <Button title={<Text style={style.club_button_text}>Resign</Text>}
            style_inactive={{backgroundColor: '#85bebe',
            alignSelf: 'center',
            borderRadius: 3,
            paddingTop: 5,
            paddingBottom: 7,
            paddingHorizontal: 20, width: 207}}
      style={{backgroundColor: '#e32652',
      borderRadius: 3,
      paddingTop: 5,
      paddingBottom: 7,
      paddingHorizontal: 20, width: 207}}
      disable_imediately={true}
      onPress={this.confirmResignHost} /></View>, 400, can_cancel_by_date ? 300 : 400)
  }

  selectUnInvitee(attendee) {
    let { user_id } = attendee;
    let { selected_people, selected_people_uninvites } = this.state;
    selected_people = selected_people ? selected_people : [];
    selected_people_uninvites = selected_people_uninvites ? selected_people_uninvites : [];

    console.log('user_id', user_id)
    console.log('selected_people_uninvitesselected_people_uninvites', selected_people_uninvites)
    console.log('selected_peopleselected_people', selected_people)

    let index_of_user = selected_people.indexOf(user_id);
    if(index_of_user === -1) {
      selected_people.push(user_id);
      selected_people_uninvites.push(attendee);
    } else {
      selected_people.splice(index_of_user, 1)

      selected_people_uninvites = selected_people_uninvites.filter(a => a.user_id !== user_id);
    }

    console.log('selected_people_uninvitesselected_people_uninvites2', selected_people_uninvites)
    console.log('selected_peopleselected_people2', selected_people)

    this.setState({
      selected_people_uninvites,
      selected_people,
      is_choose_uninvites_open: false
    }, ()=>{
      this.toggleChooseUnInvites();
    })
  }

  drawInvitee(attendee, onSelect) {
    let photo_url, name, job;

    let { selected_people, user_id } = this.state;

    selected_people = selected_people ? selected_people : [];

    console.log('attendee', attendee)

    if(attendee) {
      let profile = attendee.profile;
      user_id = attendee.user_id;

      if(typeof profile === 'string') {
        profile = JSON.parse(profile);
      }

      ({photo_url, name, job} = profile);

      if(attendee.name) {
        name = attendee.name;
      }
    }

    console.log('selected_people3', selected_people);
    let selected = selected_people.indexOf(user_id) !== -1;

    return <TouchableOpacity onPress={()=>onSelect(attendee)} style={[style.invitee_bubble]}>
      
      <Image style={style.invitee_photo} source={{uri: photo_url ? photo_url : 'https://diningsocial-assets.s3.amazonaws.com/empty_profile.png'}} />
      <View style={{flexDirection: 'column', width: 100, overflowWrap: 'anywhere'}}>
        <Text style={style.uninvite_name}>{name}</Text>
        <Text style={style.uninvite_job}>{job}</Text>
      </View>
      <Text style={style.uninvite_select}>{selected ? 'de-select': 'select'}</Text>
    </TouchableOpacity>
  }

  async saveNonInvited() {
    let { event_id, selected_people } = this.state;

    await UserController.saveUnInvited({event_id, selected_people});

    this.toggleChooseUnInvites()
  }

  toggleChooseAsMember() {


    let window_width = Dimensions.get('window').width;
    let is_mobile = window_width <= 700;

    this.openInParent(<View style={global_style.center_content}>
      <Text style={{fontFamily: 'Ysabeau', fontSize: 20, textAlign: 'center'}}>Not vibing with someone?</Text> 
      <Text style={style.experience_text}>Your experience is the most important thing to us. Don't be shy to let us know. As a member, you're able to tell us which members you would not like to dine with so that they cannot RSVP after you have.</Text> 
      <Button loading={false} title={<Text style={[style.club_button_text, {marginHorizontal: 0}]}>Become a Member</Text>}
                style_inactive={{backgroundColor: 'black',
            alignSelf: 'center',
            borderRadius: 3,
            paddingTop: 5,
            paddingBottom: 7,
            paddingHorizontal: 20, width: 207}}
          style={{backgroundColor: 'black',
          borderRadius: 3,
          paddingTop: 5,
          marginTop: 20,
          paddingBottom: 7,
          paddingHorizontal: 20, width: 250}}
          disable_imediately={true}
          onPress={()=>{window.location.replace('/dashboard/subscription')}} />
      </View>, is_mobile ? 200 : 400, 300)
  }

  async toggleChooseInvitesUninvites() {
    let { event, user_id } = this.state;
    let { created_by } = event;

    if(user_id === created_by) {
      this.creatorToggle();
    } else {
      this.toggleChooseUnInvites();
    }
  }

  async creatorToggle() {
    let { event, user_id } = this.state;
    let { invited, uninvited } = event;

    console.log('invitedinvited', invited);
    console.log('user_id', user_id);

    let user_invited = invited ? invited[user_id] : null;
    user_invited = user_invited && user_invited.length ? true : false;

    let user_uninvited = uninvited ? uninvited[user_id] : null;
    user_uninvited = user_uninvited && user_uninvited.length ? true : false;

      this.props.putInParent(null)
      this.openInParent(<View style={global_style.center_content}>
          <Text style={{fontFamily: 'Ysabeau', fontSize: 20, textAlign: 'center'}}>Not vibing with someone or would realy like to see someone again?</Text> 
          <Text style={style.experience_text}>We want to make sure you're getting the best experience possible. Don't be shy and let us know who you'd like to see or not see again.</Text> 

        <View style={{marginTop: 30, flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
          <Button title={<Text style={style.club_button_text}>Invite</Text>}
            loading={false}
            style={style.club_button}
            style_inactive={style.club_button_inactive}
            inactive={user_uninvited}
            onPress={user_uninvited ? null : this.toggleChooseInvites} />

          <Button title={<Text style={style.club_button_text}>Un-Invite</Text>}
            loading={false}
            style={style.club_button}
            style_inactive={style.club_button_inactive}
            inactive={user_invited}
            onPress={user_invited ? null : this.toggleChooseUnInvites} />
          </View>
          {user_invited ? <Text>You've already invited a few members. De-selected from the list to be able to uninvite members.</Text> : null}
          {user_uninvited ? <Text>You've already un-invited a few members. De-selected from the list to be able to invite members.</Text> : null}
        </View>, 450, 300);
  }

  drawPager() {
    let { people_page, people_i_met } = this.state;

    const goPage = async (people_page) => {
      this.setState({loading: true}, async () => {
        let people_i_met = await UserController.getPeopleIMet({page: people_page});
        this.setState({
          people_i_met,
          people_page,
          is_choose_uninvites_open: false,
        }, ()=>{
          this.toggleChooseUnInvites();
        })
      });
    }

    let page_element = <Text style={style.page_number}>{people_page+1}</Text>
    let prev = people_page !== 0 ? 
        <TouchableOpacity onPress={()=>goPage(people_page-1)}>
          <Text style={style.pager_button_enabled}><Icon color="white" name="arrow-left" size={20} /></Text>
        </TouchableOpacity> : 
        <Text style={style.pager_button_disabled}><Icon name="arrow-left" color={'white'} size={20} /></Text>;


    let next = people_i_met && people_i_met.length === 10 ? 
        <TouchableOpacity onPress={()=>goPage(people_page+1)}>
          <Text style={style.pager_button_enabled}><Icon name="arrow-right" color="white" size={20} /></Text>
        </TouchableOpacity> : 
        <Text style={style.pager_button_disabled}><Icon name="arrow-right" color="white" size={20} /></Text>;

    return <View style={style.pager_wrapper}>
      {prev}{page_element}{next}
    </View>
  }

  async toggleChooseUnInvites() {
    this.props.putInParent(null)
    this.setState({is_choose_open: false}, ()=>{
      let { people_i_met, selected_people_uninvites } = this.state;

      console.log('selected_people_uninvites', selected_people_uninvites)
      
      let uninvited_people = selected_people_uninvites ? selected_people_uninvites : [];

      this.openInParent(<View style={global_style.center_content}>
        <Text style={{fontFamily: 'Ysabeau', fontSize: 20, textAlign: 'center'}}>Not vibing with someone?</Text> 
        <Text style={style.experience_text}>Your experience is the most important thing to us. Don't be shy to let us know.</Text> 

        <View style={{flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
          <View style={style.invite_column}>
            <Text style={style.uninvite_title}>People you've met</Text>
            {this.drawPager()}
            <ScrollView style={{height: 200, paddingBottom: 100}}>
              <>
                
                {people_i_met.length ?
                people_i_met.map(a=>this.drawInvitee(a, this.selectUnInvitee)) : <Text style={[global_style.normal_text_dark, {backgroundColor: '#d0edd0', borderRadius: 10, padding: 20}]}>You haven't met any people yet. Once you do, you'll be able to say which people you'd like to meet again when you RSVP for an event.
                  </Text>}
              </>
            </ScrollView>
          </View>
          <View style={style.invite_column}>
          <Text style={style.uninvite_title}>People you've Uninvited</Text>
            <ScrollView style={{flex: 1, height: 200}}>
            <View style={{height: 270, paddingBottom: 100, overflow: 'scroll'}}>
              {uninvited_people.map(a=>this.drawInvitee(a, this.selectUnInvitee))}
              </View>
            </ScrollView>
          </View>
        </View>
        <View style={{marginTop: 20}}>
        <Button title={<Text style={style.club_button_text}>Save</Text>}
          loading={false}
          style={style.club_button}
          style_inactive={style.club_button}
          inactive={false}
          onPress={this.saveNonInvited} />
          </View>
        </View>, 800, 550)
    });
  }

  toggleCancelRSVP() {
    let { cancel_loading, checked_host, event, user_id } = this.state;

    let { can_cancel, can_cancel_by_date } = this.getCanCancel();

    let is_host = checked_host || (event.hosted && event.host === user_id);

    this.openInParent(<View style={global_style.center_content}>
      {event.free ? <Text style={{fontFamily: 'Ysabeau', fontSize: 20, textAlign: 'center', marginTop: 40, marginBottom: 60}}>You're canceling your RSVP. Thank you for letting us know!</Text> : <>
            <Text style={{fontFamily: 'Ysabeau', fontSize: 20, textAlign: 'center', marginBottom: 10}}>Your fee will not be refunded, however please do let us know if you cannot make it so we can inform attendees.</Text>
      </>}

      <Button loading={cancel_loading} title={<Text style={[style.club_button_text, {marginHorizontal: 0}]}>Cancel RSVP</Text>}
                style_inactive={{backgroundColor: '#85bebe',
            alignSelf: 'center',
            borderRadius: 3,
            paddingTop: 5,
            paddingBottom: 7,
            paddingHorizontal: 20, width: 207}}
          style={{backgroundColor: '#e32652',
          borderRadius: 3,
          paddingTop: 5,
          paddingBottom: 7,
          paddingHorizontal: 20, width: 208}}
          disable_imediately={true}
          onPress={this.confirmCancel} /></View>, 400, 400)
  }

  getCanCancel(add_one) {
    let { rsvped, date, event, rsvps, chosen_hosted, checked_host, user_id } = this.state;

    console.log('date', date)

    let rsvp_nums = rsvps ? rsvps.length : 0;

    let hosted_event = chosen_hosted ? chosen_hosted === "1" : event.hosted;

    let rsvp_limit = hosted_event ? event.rsvp_limit_hosted : event.rsvp_limit_not_hosted;

    let rsvp_needed = event.rsvp_needed ? event.rsvp_needed : (event.hosted ? 4 : 3);

    let needs_more = rsvps ? ( rsvp_needed - rsvp_nums ) : null;

    let event_date_time = new Date(`${date}`);

    var previous_day = new Date(event_date_time.getTime() - (24 * 60 * 60 * 1000));

    // Set the time to 9:00 AM
    previous_day.setHours(9, 0, 0, 0);

    let current_date = new Date();
    let can_cancel;
    let can_cancel_by_date;

    console.log('previous_day < current_date', previous_day < current_date);

    if (previous_day < current_date) {
      can_cancel_by_date = false;
      can_cancel = needs_more > 0;
    } else {
      can_cancel_by_date = true;
      can_cancel = true;
    }

    console.log('can_cancel_by_date', can_cancel_by_date);

    let is_host = checked_host || (event.hosted && event.host === user_id);
    
    let host_text = hosted_event ?  ' plus the host' : '';

    let and_show = event.free ? ' and you DO NOT show up.': '.'

    let unless_more = needs_more > 0 && !is_host ? ` But still, you will only be charged when 3 people${host_text} RSVP${and_show}` : '';
    
    let cancel_date = previous_day.toLocaleString('en-US', { 
      weekday: 'long', 
      month: 'long', 
      day: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit'
    });

    let cancel_text =`${can_cancel_by_date ? `You can cancel without charge before ${cancel_date}` : `Please make sure you're able to make it. The deadline for canceling has past.`}${unless_more}`;

   /*  if(checked_host || (event.hosted && event.host === user_id)) {
      cancel_text = `As the host of the event, you will not be able to cancel your RSVP through the site. Please make sure you're able to make it!`
    } */

    let can_rsvp = rsvp_nums < rsvp_limit;

    console.log('needs_more, event.hosted, event.host, checked_host', needs_more, event.hosted, event.host, checked_host)

    if(rsvp_nums === rsvp_limit - 1 && event.hosted && !event.host && !checked_host) {
      can_rsvp = false;
      cancel_text = `Since you're the last person to RSVP, you must make yourself the host. Please read below about the hosts responsibilities.`
    }

    return { can_cancel, needs_more, cancel_text, can_cancel_by_date, can_rsvp };
  }

  drawTheButton(open_event, rsvps_open) {

    let { subscription_loading, mounted, rsvped, has_event_within_hour, is_member, pre_rsvped, status, isLoggedIn, loading, confirm_inactive, subscription_type, has_any_dinners, event, user_is_uninvited } = this.state;

    let { free } = event;

    let show_confirm_button = !user_is_uninvited && isLoggedIn && !rsvped && !has_event_within_hour && rsvps_open > 0 && (status === 'public' || (is_member && subscription_type === 2) || free) && (event.new_member ? !has_any_dinners : true);

    let show_pricing_panel = !is_member || (is_member && subscription_type !== 2)
    console.log('subscription_type', subscription_type)

    let show_pre_rsvp_button = !free && !rsvped && !has_event_within_hour && show_pricing_panel && !pre_rsvped && status === 'pre' && isLoggedIn;

    return <View>{subscription_loading || !mounted ? <View style={{width: 60, height: 60, alignSelf: 'center', paddingTop: 100}}>
    <Progress.CircleSnail size={60} backgroundColor="black" fill='black' color={'white' } />
    </View> : <View>

      {show_confirm_button ?
      <Button title={<Text style={style.club_button_text}>Confirm RSVP</Text>}
        loading={loading}
        style={style.club_button}
        style_inactive={style.club_button}
        inactive={false}
        onPress={this.toggleConfirmRSVP} /> : null }

      {show_pre_rsvp_button ?
          <Button title={<Text style={style.club_button_text}>Pre-RSVP</Text>}
            loading={loading}
            style={style.club_button}
            style_inactive={style.club_button_inactive}
            inactive={!open_event ? false : confirm_inactive}
            onPress={this.togglePreRSVP} /> : null}

      {user_is_uninvited ? <Text style={{color: 'white'}}>This event is invite only</Text> : null}

      {has_event_within_hour && !rsvped ? <Text style={[global_style.medium_description, global_style.center_content, global_style.text_vibrant_red, global_style.text_uppercase, global_style.padding_top_20]}>You're already have another event within the hour</Text> : null}

      {rsvped ? <View style={style.buttons_wrapper}>

        {!free ?
        (is_member ? <Button title={<Text style={style.club_button_text}>Choose Invites</Text>}
            style={[style.club_button, style.choose_button]}
            style_inactive={style.club_button_inactive}
            onPress={this.toggleChooseInvitesUninvites} /> : <Button title={<Text style={style.club_button_text}>Choose Invites</Text>}
            style={[style.club_button, {backgroundColor: '#25dafb', marginBottom: 20}]}
            style_inactive={style.club_button_inactive}
            onPress={this.toggleChooseAsMember} />) : <View style={{flex: 1}}></View>}
              
      <Button title={<Text style={style.club_button_text}>Cancel RSVP</Text>}
            style={style.club_button}
            style_inactive={style.club_button_inactive}
            onPress={this.toggleCancelRSVP} />


  
      </View> : null}
  
      {!isLoggedIn ?
          <Button title={<Text style={style.club_button_text}>RSVP</Text>}
            loading={loading}
            style={style.club_button}
            style_inactive={style.club_button_inactive}
            inactive={!open_event ? false : confirm_inactive}
            onPress={this.togglePreRSVP} /> : null}
  
    </View> }</View>
  }

  getPrice() {
    let { chosen_hosted, event, is_member, subscription_type } = this.state;

    let charge_discount = (is_member && subscription_type !== 2)

    let price;
    if(charge_discount) {
      price = 10;
    } else {
      if(chosen_hosted && chosen_hosted !== "-1") {
        price = chosen_hosted === "1" ? event.price_hosted : event.price_not_hosted;
        //free = chosen_hosted !== "1";
      }

      if(event.demographic) {
        price = event.hosted ? event.price_hosted : event.price_not_hosted;
      }
    }

    console.log('eventprice', event)
    console.log('price', price)

    return price;
  }

  render() {
    let { subscription_loading, mounted, date, is_member, rsvped, day_name, event, place, chosen_demographic, places, chosen_restaurant, type, chosen_dinner_time, chosen_hosted, internal, is_host, checked_host, host, user_id, rsvps, discussion_open, messages, text_message, sending_message, message_refresh, isLoggedIn, status, pre_rsvped, subscription_type } = this.state; 

    let restaurant_container, price, main_image, restaurant_name, dinner_time, directions_link, background_color, event_name, dining_type, demographic, hosted, demographics, description, free, tbd, place_holder_image;
    let place_saved = !!place;

    console.log('chosen_hosted', event)

    if(mounted) {
      dinner_time = event.time ? Utilities.getEventTime(event.time) : null;
      event_name = event.name;
      ({type, demographic, hosted, description, place_holder_image, main_image} = event)

      console.log('chosen_restaurant', chosen_restaurant, typeof chosen_restaurant)

      if(chosen_restaurant && chosen_restaurant !== '-1') {
        place = places.filter(place=>{return place.id === chosen_restaurant});
        if(place && place.length) {
          place = place[0].place_data
        }
      }

      free = !!event.free;

      tbd = !!event.tbd;

      price = this.getPrice();

      console.log('place', place)

      let window_width = Dimensions.get('window').width;
      let is_mobile = window_width < 700;
      
      if(place) {
        main_image = main_image ? main_image : place.main_image;
        restaurant_name = place.restaurant_name;
        directions_link = place.directions_link;
        restaurant_container = <View style={style.restaurant_wrapper}>
          {is_mobile ? (rsvped ? <Text style={[style.main_title_name_mobile]}>You're going to: {event_name}</Text> : <Text style={[style.main_title_name_mobile]}>{event_name}</Text>) : null}
          <View style={[style.kitchestep_image_text_wrapper]}>
            <View style={style.kitchenstep_main_wrapper}>
            {place.main_video ? 
                <View style={{position: 'relative', marginTop: 40}}>
                  <View style={{width: 300, position: 'relative', marginHorizontal: 'auto'}}>
                    <Image style={{width: 300, height: 520, position: 'absolute'}} source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/HappyHourThumb.png'}} />
                  </View>
                <Video
                  posterSource={{uri: 'https://diningsocial-assets.s3.amazonaws.com/HappyHourThumb.png'}}
                  source={{ uri: place.main_video }}
                  style={{width: 300, position: 'relative', marginHorizontal: 'auto'}}
                  videoStyle={{position: 'relative'}}
                  useNativeControls={true}
                  shouldPlay={false}
                  resizeMode="contain" // Or adjust as needed
                />
                </View> :
              <Image resizeMode={'contain'} style={style.kitchenstep_main} source={{uri: main_image}} />}
              <TouchableOpacity onPress={()=>{window.open(place.link)}} style={style.visit_restaurant}><Text style={style.visit_restaurant_text}>Visit {restaurant_name}  &#x2197;</Text></TouchableOpacity>
            </View>
          </View>
          <View> 
          <Text style={style.details_title}>Event Details</Text>
          <Text style={style.description}>{description}</Text>
          </View>
        </View>;
      } else {
        restaurant_name = 'Restaurant TBD';
        restaurant_container = <View style={style.restaurant_wrapper}>
          <Text style={style.restaurant_title}>{restaurant_name}</Text>
          <View style={[style.kitchestep_image_text_wrapper]}>
            <View style={style.kitchenstep_main_wrapper}>
              <Image resizeMode={'contain'} style={style.kitchenstep_main} source={{uri: place_holder_image ? place_holder_image : 'https://s3.amazonaws.com/www.diningsocialnj.com/restaurant_placeholder.png'}} />
            </View>
          </View>
          <View> 
            <Text style={style.details_title}>Event Details</Text>
            <Text style={style.description}>{description}</Text>
          </View>
        </View>;
      }

      dining_type = Utilities.getEventTypeName(type);

      ({ demographics } = internal);
    }

    let open_event_types = [{name: "Choose an event type...", value: -1}].concat(demographics);

    let dinner_times = [{name: '5:30pm', value: 17.5}, {name: '6pm', value: 18}, {name: '6:30pm', value: 18.5}, {name: '7pm', value: 19}, {name: '7:30pm', value: 19.5}, {name: '8pm', value: 20}]

    let open_event = mounted ? !demographic && (type === -1 || type === "-1") : false;

    let non_hosted = mounted ? !event.hosted : false;

    let needs_host = mounted ? !open_event && !non_hosted && !event.host : false;

    let needs_more;
    let charged_at, can_cancel, cancel_text, rsvps_open, invited;
    if(event) {
      ({invited} = event);
      
      ({ can_cancel, needs_more, cancel_text } = this.getCanCancel(true));

      charged_at = <Text style={global_style.notification_text}>{cancel_text}</Text>;

      let current_rsvps = rsvps.length;

      rsvps_open = event.hosted ? event.rsvp_limit_hosted - current_rsvps : event.rsvp_limit_not_hosted - current_rsvps;
    }

    console.log('status234, mounted', status, status === 'pre', true)

    console.log('!is_member && !checked_host && !subscription_loading rsvped || rsvps_open === 0', !is_member, !checked_host, !subscription_loading, rsvped, rsvps_open)

    let show_pricing_panel = !is_member || (is_member && subscription_type !== 2)

    return (<View style={{paddingBottom: 100}}>
         <Modal ref={node=>this.modal_ref=node} putInParent={this.props.putInParent} />
        {mounted ? 
        <View style={{flexDirection: 'column'}}>
          <View style={[style.page_container, {backgroundColor: background_color}]}>
            <View style={[style.one_column, style.restaurant_container]}>{restaurant_container}</View>
            <View style={[style.one_column, rsvped ? style.data_column_rsvped : style.data_column]}>

              {status === 'public' ?
                  (needs_more > 0 ? <Text style={style.needs_host}>⭐️ This event needs {needs_more} more RSVPS ⭐️</Text> : <Text style={style.needs_host}>{rsvps_open === 0 ? 'FULL' : `${rsvps_open} RSVPs open`}</Text>) : null}

              {needs_host ? <Text style={style.needs_host}>⭐️ This event needs a host ⭐️</Text> : null}

              {rsvped ? <Text style={style.main_title_name}>You're going to: {event_name}</Text> : <Text style={style.main_title_name}>{event_name}</Text>}

              {/* {charged_at && rsvps_open !== 0 && !is_member && status === 'public' ? charged_at : null}
 */}
              { open_event ?
                <View style={style.line}>
                  <Text style={style.line_name}>What</Text>
                  <Text style={style.line_date}>
                    <View style={style.picker}>
                      <Picker
                        selectedValue={chosen_demographic}
                        mode={'dialog'}
                        style={style.drop_style}
                        onValueChange={(value)=>this.updateState(value, 'chosen_demographic')}
                      >
                        {open_event_types.map(v => {
                          return <Picker.Item
                            fontSize={16}
                            style={{ fontSize: 16 }}
                            key={v.value}
                            label={v.name}
                            value={v.value}
                          />
                        })}
                      </Picker>
                    </View> {type === -1 || type === "-1" ? 'Dinner' : 'Brunch'}
                  </Text>
                  
                </View> : null}


              { open_event ?
                <View style={style.line}>
                  <Text style={style.line_name}>Type</Text>
                  <Text style={style.line_date}>
                    <View style={style.picker}>
                      <Picker
                        selectedValue={chosen_hosted}
                        mode={'dialog'}
                        style={style.drop_style}
                        onValueChange={(value)=>this.updateState(value, 'chosen_hosted')}
                      >
                        {[{name: "Choose a type", value: "-1"}, {name: "Not Hosted", value: "0"}, {name: "Hosted", value: "1"}].map(v => {
                          return <Picker.Item
                            fontSize={16}
                            style={{ fontSize: 16 }}
                            key={v.value}
                            label={v.name}
                            value={v.value}
                          />
                        })}
                      </Picker>
                    </View> {/* <TouchableOpacity onPress={()=>{this.scrollTo(this.hostedRef)}} style={[]}><Text style={style.info_button}>i</Text></TouchableOpacity> */}
                  </Text>
                  
                </View> : <View style={style.line}>
                  <Text style={style.line_name}>Type</Text>
                  <Text style={style.line_date}>
                      {event.hosted ? "Hosted" : "Not hosted" }
                    {/* <TouchableOpacity onPress={()=>{this.scrollTo(this.nonHostedRef)}} style={[]}><Text style={style.info_button}>i</Text></TouchableOpacity> */}

                    <View style={{marginLeft: 10}}>
                  {chosen_hosted || event.hosted ?
                  <>
                    {chosen_hosted === "0" || (!event.hosted && !open_event) ?
                    <></> : 
                    <View style={global_style.column}>
                      <Text style={[{ flexDirection: "row", display: "flex", alignItems: "flex-end" }]}>
                    
                        {is_host && (((!event.host && !open_event) || open_event) || (event.host && event.hosted && host && host.user_id === user_id)) ? 
                            (
                              (event.host && event.hosted && host && host.user_id === user_id) ? 
                            <><Input 
                              check_style={{check_wrapper: {flexDirection: 'row', flexWrap: 'wrap', display: 'flex', marginLeft: 10}, check_text: {
                                  marginLeft: 10,
                                  fontFamily: 'Ysabeau',
                                  fontSize: 16,
                                  width: 190, 
                                  color: 'white'}}}
                              type={"check"} 
                              onCheck={this.toggleResignHost} 
                              checked={true}
                              label={<Text style={[global_style.normal_text, global_style.padding_5_20, {color: 'yellow'}]}>You're hosting this event</Text>} />
                            </>
                              :
                            <><Input 
                              check_style={{check_wrapper: {flexDirection: 'row', flexWrap: 'wrap', display: 'flex', marginLeft: 10}, check_text: {
                                  marginLeft: 10,
                                  fontFamily: 'Ysabeau',
                                  fontSize: 16,
                                  width: 170, 
                                  color: 'white'}}}
                              type={"check"} 
                              onCheck={()=>this.updateValue("checked_host", true)} 
                              checked={checked_host}
                              label={event.host && event.hosted && host && host.user_id === user_id ? <Text style={[global_style.normal_text, global_style.padding_5_20,  {color: 'yellow'}]}>You're hosting this event</Text> : <Text  style={[global_style.normal_text]}>I'd like to be the host</Text>} />
                              
                              {(rsvped || pre_rsvped) && checked_host !== undefined && checked_host !== !!event.host && !open_event ? 
                                <TouchableOpacity onPress={this.toggleMakeHost} style={global_style.small_button_light}>
                                  <Text style={global_style.small_button_text}>Confirm</Text>
                                </TouchableOpacity> : null }
                            </>
                            ) : (
                              (!event.host && event.hosted || (chosen_hosted === "1" && open_event)) ?
                                <Text style={global_style.normal_light_link}>This event will need a host</Text>  : <Text style={[global_style.normal_text, {color: 'yellow'}]}>
                                  Your host is {host.profile.name}
                                </Text>
                            )
                          }
                      </Text>
                    </View>}
                    </> : null}
                </View>
                  </Text>
                  
                </View>}

                

              {/* { non_hosted && !open_event ?
                <View style={style.line}>
                  <Text style={style.line_name}>What</Text>
                  <Text style={style.line_date}>
                    {demographic} (Free-form)
                  </Text>
                  
                </View> : null} */}

              <View style={style.line}>
                <Text style={style.line_name}>When</Text>
                <Text style={style.line_date}>
                  {day_name} - {date} {dinner_time ? dinner_time : null}&nbsp;&nbsp;
                
                  {rsvped ?
                  <View>
                    <TouchableOpacity onPress={()=>this.addCalendar(event, place)}><Text style={global_style.normal_light_link}>Add to Calendar</Text></TouchableOpacity>
                  </View> : null}
                </Text>
                
                {!dinner_time ?
                <Text style={style.line_date}>
                <View style={style.picker}>
                  <Picker
                    selectedValue={chosen_dinner_time}
                    mode={'dialog'}
                    style={style.drop_style}
                    onValueChange={(value)=>this.updateState(value, 'chosen_dinner_time')}
                    >
                      {[{name: "Choose a dinner time", value: -1}].concat(dinner_times).map(v => {
                        return <Picker.Item
                          fontSize={16}
                          style={{ fontSize: 16 }}
                          key={v.value}
                          label={v.name}
                          value={v.value}
                        />
                      })}
                    </Picker>
                  </View>
                </Text> : null}
              </View>
              
              {tbd ? <View style={style.line}>
                <Text style={style.line_name}>Where</Text>
                <View style={global_style.column}>
                <Text style={style.line_date}>
                  TBD
                </Text>
                </View>
              </View> : null}
              
              {place_saved ? 
              <View style={style.line}>
                <Text style={style.line_name}>Where</Text>
                <View style={global_style.column}>
                <Text style={style.line_date}>
                  {restaurant_name}&nbsp;&nbsp;<TouchableOpacity onPress={()=>this.goDirections(directions_link)}><Text style={global_style.normal_light_link}>Get Directions &#x2197;</Text></TouchableOpacity>
                </Text>
                </View>
              </View> : null}

              {!place_saved && !tbd ?
              <View style={style.line}>
                <Text style={style.line_name}>Where</Text>
                <Text style={[style.line_date, global_style.column, {display: 'flex'}]}>
                  <View style={style.picker}>
                  <Picker
                    selectedValue={chosen_restaurant}
                    mode={'dialog'}
                    style={style.drop_style}
                    onValueChange={(value)=>this.updateState(value, 'chosen_restaurant')}
                  >
                    {[{id: "-1", place_data: {restaurant_name: "Choose a restaurant"}}].concat(places).map(v => {
                      return <Picker.Item
                        fontSize={16}
                        style={{ fontSize: 16 }}
                        key={v.id}
                        label={v.place_data.restaurant_name}
                        value={v.id}
                      />
                    })}
                  </Picker>
                  </View>
                </Text>
              </View> : null}

              {show_pricing_panel && !checked_host && !subscription_loading ?
              (rsvped || rsvps_open === 0 ? null :
                <>
                  <View style={style.line}>
                    <View style={[style.line_name, global_style.column_mobile_switch_row]}>
                      <Text style={style.line_name}>Attendance</Text>
                      <Text style={[style.line_name, style.fee]}>Fee*</Text></View>
                    <View style={[style.line_date]}>
                      {price ? <>
                      {this.drawPrice(price, free, event)}</> : <>
                      <Text style={style.line_date}>N/A</Text>
                      </>}
                    </View>
                    
                  </View>
                  {/* <View style={[style.line, { marginTop: 0 }]}>
                    <Text style={style.line_name}></Text>
                    <Text style={[style.line_date, global_style.row, {marginLeft: 74}]}>
                        <Text style={[global_style.small_text, global_style.text_white]}>
                        * Please make sure you're able to make it. All fees are non-refundable to ensure a proper RSVP count. Read more on our</Text><TouchableOpacity onPress={()=>{window.open('https://www.diningsocialnj.com/terms')}}><Text style={style.terms_link}>Terms & Conditions</Text></TouchableOpacity>
                    </Text>
                  </View> */}
                </>
              ) : null}

              <View style={style.main_rsvp_wrapper}>{this.drawTheButton(open_event, rsvps_open)}</View>

              {rsvped ? <View style={style.bon_appetit}><Text style={style.main_title_name}>Bon Appétit!</Text></View> : null}

              {/* <View style={[style.line, global_style.column, global_style.align_text_left, { marginTop: 20}]}>
                  <Text style={style.more_info}>More info</Text>
                  <TouchableOpacity onPress={()=>{this.scrollTo(this.logisticsRef)}}><Text style={global_style.normal_light_link}>Logistics</Text></TouchableOpacity>
                  <TouchableOpacity onPress={()=>{this.goTo('/icebreakers')}}><Text style={global_style.normal_light_link}>Ice-breakers</Text></TouchableOpacity>
                  <TouchableOpacity onPress={()=>{this.scrollTo(this.nonHostedRef)}}><Text style={global_style.normal_light_link}>Hosted vs Non-Hosted</Text></TouchableOpacity>
                  <TouchableOpacity onPress={()=>{this.scrollTo(this.appsRef)}}><Text style={global_style.normal_light_link}>Apps</Text></TouchableOpacity>
                </View> */}
            </View>
          </View>

          <View ref={node => this.attendeesRef = node} style={[global_style.whole_width_row_mobile_switch_column, global_style.panel_padding_with_mobile, global_style.panel_border]}>
            <View style={[global_style.flex_one_mobile_none, global_style.sub_panel_padding]}>
              {this.drawAttendees()}
            </View>
            <View style={[global_style.flex_one_mobile_none, global_style.sub_panel_padding, global_style.flex_one]}>
              <View style={global_style.row_mobile_switch_column}>
                <Text style={[global_style.panel_sub_title, global_style.padding_subtitle]}>Discussion</Text>
                {message_refresh ? <TouchableOpacity style={style.message_link} onPress={()=>{window.location.reload()}}><Text style={global_style.normal_link}>New messages, refresh page</Text></TouchableOpacity> : null}
              </View>
                
              {discussion_open ? <View style={[global_style.flex_one, global_style.space_between]}>
                <ScrollView style={[global_style.flex_one, style.chat_messages_wrapper]}>
                  {messages && messages.length ?
                    messages.map(message => { return this.drawMessage(message)}) :
                    <Text style={[style.no_messages_text]}>No messages yet</Text>
                    }
                </ScrollView>
                <View style={style.chat_bottom}>
                  <Input placeholder={"Have a comment or question?"} style={{flex: 12}} value={text_message} onChangeText={(event)=>{this.updateState(event, 'text_message')}} multiline={"true"} />

                  {isLoggedIn ?
                  <Button title={<Text style={style.club_button_text}><Icon name="arrow-right" size={40} /></Text>}
                        style={{
                          flex: 1,
                        borderRadius: 10,
                        paddingTop: 5,
                        paddingBottom: 7,
                        paddingHorizontal: 20, width: 50, marginLeft: 5}}
                        loading={sending_message} 
                        onPress={this.sendMessage} /> : 
                        <Button title={<Text style={style.club_button_text}><Icon name="arrow-right" size={40} /></Text>}
                        style={{
                          flex: 1,
                        borderRadius: 10,
                        paddingTop: 5,
                        paddingBottom: 7,
                        paddingHorizontal: 20, width: 50, marginLeft: 5}}
                        loading={sending_message} 
                        onPress={this.onLoginClick} />}
                </View>
              </View> : <View>
                <Text>Not open yet.</Text>
              </View>}
            </View>
          </View>
          {/* <View>
            <FAQ />
          </View> */}

          {/* <View ref={node => this.logisticsRef = node} style={[global_style.whole_width_row_mobile_switch_column]}>
            <View style={[global_style.column, global_style.flex_one_mobile_none, global_style.padding_40, global_style.dark_blue_green_background]}>
              <Text style={[global_style.big_number_mobile_smaller, global_style.text_color_white]}>.01</Text>
              <Text style={[global_style.medium_description, global_style.text_color_white]}>After you RSVP, you can cancel until 9am the day before the dinner.</Text>
            </View>
            <View style={[global_style.column, global_style.flex_one_mobile_none, global_style.padding_40, global_style.foggy_red_background]}>
              <Text style={[global_style.big_number_mobile_smaller, global_style.text_color_white]}>.02</Text>
              <Text style={[global_style.medium_description, global_style.text_color_white]}>Since our dinners do not happen until 3 people RSVP (in addition to the host), you won't be charged until the 3rd person confirms.</Text>
            </View>
            <View style={[global_style.column, global_style.flex_one_mobile_none, global_style.padding_40]}>
              <Text style={global_style.big_number_mobile_smaller}>.03</Text>
              <Text style={global_style.medium_description}>Dinner attendance is free for the host. In addition, if you host 2 dinners a month, you'll get free membership 😀.</Text><TouchableOpacity onPress={()=>{this.props.navigate('/dashboard/hosting')}}><Text style={style.list_item_link}>Sign up to be the host.</Text></TouchableOpacity>
            </View>
          </View> */}

          {/* <View style={[global_style.whole_width_row_mobile_switch_column]}>
            <View style={[global_style.column, global_style.flex_one, global_style.padding_40, global_style.dark_blue_background]}>
              <View ref={node => this.nonHostedRef = node} style={[global_style.white_background, global_style.padding_40]}>
                <Text style={style.event_description_title}>Non-Hosted</Text>
                  <Text style={style.event_description_description}>
                    Events that do not have a host are mainly different because there is no one that assigns themselves as the leader at the dinner. Because of this, there is no one to help make everyone feel included in the conversation and take charge at organizing the bill at the end. This type of dinner is best suited for extroverts that tend to have an easy time chatting with new people. This type of dinner is limited to 4 people.

                      <Text>
                        <Text style={style.list_item}>
                          Always ask for DiningSocial at the restaurant, however if for some reason the restaurant fails to register the reservaton properly, you can always ask for Aleksandra
                        </Text>
                        <Text style={style.list_item}>
                          Some of the restaurants also offer the ability for everyone to get separate checks - please ask them before the dinner to do so.
                        </Text>
                        <Text style={style.list_item}>
                          If the restaurant does not provide separate checks, please use the app called "Tab" to divide up the bill in addition to Venmo. One person will have to be assigned as the person who pays the restaurant and the rest of the attendees can Venmo, Zelle or use any other payment to reimburse.
                        </Text>
                      </Text>
                    </Text>
                </View>
            </View>
            <View ref={node => this.hostedRef = node} style={[global_style.column, global_style.flex_one, global_style.padding_40, global_style.foggy_red_background]}>
              <View style={[global_style.white_background, global_style.padding_40, global_style.column]}>
                <Text style={style.event_description_title}>Hosted</Text>
                  <Text style={style.event_description_description}>
                    Hosted events are the best. As a participant, you get to relax, enjoy your food and conversation. Your host is there to make sure everyone has a great time and gets to share a bit about themselves. They also make sure to organize the bill at the end of the meal using the apps below.
                  </Text>
                  <Text style={style.event_description_description}>
                    Anyone can create a hosted dinner even members that are not hosts. Any member host can choose to assign themselves to a hosted dinner that still needs a host. If a hosted event does not end up having a host, it will be canceled. 
                  </Text>
                  <Text style={style.event_description_description}>
                    Hosts also are not charged the participation fee and they get free membership in exchange for hosting dinners twice a month.
                  </Text>
                  <TouchableOpacity onPress={()=>{this.scrollTo(this.appsRef)}}><Text style={global_style.normal_link}>Apps</Text></TouchableOpacity>
                  {!is_host ?
                  <TouchableOpacity onPress={()=>{this.goTo('/dashboard/hosting')}}><Text style={global_style.normal_link}>Apply to be a host</Text></TouchableOpacity> : null}
                </View>
            </View>
          </View> */}
          {/* <View ref={node => this.appsRef = node}>
            <View style={[global_style.white_background, global_style.column]}>
              <View style={[global_style.whole_width_row_mobile_switch_column]}>
                <View style={[global_style.flex_one_mobile_none, global_style.padding_40, global_style.border_40, global_style.border_dark_blue]}>
                  <Text style={global_style.panel_sub_title}>Tab</Text>
                    <Text style={style.event_description_description}>
                      We use the app called Tab to divi up the bill at the end of each meal. With Tab
                    </Text>
                    <Text style={global_style.list_item}>
                      <Text style={global_style.list_item_text}>
                        Take a picture of the bill.
                      </Text>
                    </Text>
                    <Text style={global_style.list_item}>
                      <Text style={global_style.list_item_text}>
                        Make sure all the entries are correct and edit any entries that are not. If necessary, take a better picture to minimize your work.
                      </Text>
                    </Text>
                    <Text style={global_style.list_item}>
                      <Text style={global_style.list_item_text}>
                        Add the name of the participants.
                      </Text>
                      </Text>
                      <Text style={global_style.list_item}>
                      <Text style={global_style.list_item_text}>
                        Pass around your phone for everyone to select the items that they've had.
                      </Text>
                      </Text>
                      <Text style={global_style.list_item}>
                      <Text style={global_style.list_item_text}>
                        After everyone selected their items, go on to the next step, provide a percentage for the tip (unless the tip has already been included) and make sure all the taxes are correct. If there's an extra charge that was added for any reason and it does not show on the app, add that to the tax line or any line where this charge won't count toward the tip.
                      </Text>
                      </Text>
                      <Text style={global_style.list_item}>
                      <Text style={global_style.list_item_text}>
                        In the last step, everyone can see their totals and what they owe to the host. Use Venmo, Zelle, another app or even cash to settle up the bill with the host.
                      </Text>
                      </Text>
                
                    <View style={[global_style.line_breaker]}></View>
                    <Text style={global_style.panel_sub_title}>Android</Text>
                    <TouchableOpacity onPress={()=>{this.goTo('https://play.google.com/store/apps/details?id=com.bring10.tab')}}><Text style={global_style.normal_link}>https://play.google.com/store/apps/details?id=com.bring10.tab</Text></TouchableOpacity>
                    <View style={[global_style.line_breaker]}></View>
                    <Text style={global_style.panel_sub_title}>Apple</Text>
                    <TouchableOpacity onPress={()=>{this.goTo('https://apps.apple.com/us/app/tab-the-simple-bill-splitter/id595068606')}}><Text style={global_style.normal_link}>https://apps.apple.com/us/app/tab-the-simple-bill-splitter/id595068606</Text></TouchableOpacity>
                </View>
                <View style={[global_style.flex_one_mobile_none, global_style.padding_40, global_style.border_40, global_style.border_green]}>
                  <Text style={global_style.panel_sub_title}>Venmo or Zelle</Text>
                    <Text style={style.event_description_description}>
                      We use the app called Venmo or Zelle to pay the host. With Venmo or Zelle
                    </Text>
                    <Text style={global_style.list_item}>
                      <Text style={global_style.list_item_text}>
                        Get the total including the tip that you owe from the host
                      </Text>
                    </Text>
                    <Text style={global_style.list_item}>
                      <Text style={global_style.list_item_text}>
                        Use one of the apps to pay the host
                      </Text>
                    </Text>
                    <View style={[global_style.line_breaker]}></View>
                    <Text style={global_style.panel_sub_title}>Android Venmo</Text>
                    <TouchableOpacity onPress={()=>{this.goTo('https://play.google.com/store/apps/details?id=com.venmo')}}><Text style={global_style.normal_link}>https://play.google.com/store/apps/details?id=com.venmo</Text></TouchableOpacity>
                    <View style={[global_style.line_breaker]}></View>
                    <Text style={global_style.panel_sub_title}>Android Zelle</Text>
                    <TouchableOpacity onPress={()=>{this.goTo('https://play.google.com/store/apps/details?id=com.zellepay.zelle')}}><Text style={global_style.normal_link}>https://play.google.com/store/apps/details?id=com.zellepay.zelle</Text></TouchableOpacity>
                    <View style={[global_style.line_breaker]}></View>
                    <Text style={global_style.panel_sub_title}>Apple Venmo</Text>
                    <TouchableOpacity onPress={()=>{this.goTo('https://apps.apple.com/us/app/venmo/id351727428')}}><Text style={global_style.normal_link}>https://apps.apple.com/us/app/venmo/id351727428</Text></TouchableOpacity>
                    <View style={[global_style.line_breaker]}></View>
                    <Text style={global_style.panel_sub_title}>Apple Zelle</Text>
                    <TouchableOpacity onPress={()=>{this.goTo('https://apps.apple.com/us/app/zelle/id1260755201')}}><Text style={global_style.normal_link}>https://apps.apple.com/us/app/zelle/id1260755201</Text></TouchableOpacity>
                </View>
              </View>
            </View>
          </View> */}
        </View> : <View style={{backgroundColor: '#black', width: '100vw', height: '100vh'}}>
          <View style={{width: 60, height: 60, alignSelf: 'center', paddingTop: 100}}>
          <Progress.CircleSnail size={60} backgroundColor="black" fill='black' color={'#e32652' } />
          </View>
        </View>}
      </View>
    );
  }
}

function RSVPScreen(props) {
  if (Platform.OS === 'web') {
    let navigate = useNavigate();
    let { event_id } = useParams();

    return <RSVP_Base {...props} event_id={event_id} navigate={navigate} />
  } else {
    return <RSVP_Base {...props} navigate={props.navigation.push} />
  }
}

export default RSVPScreen;
