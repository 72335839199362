import { StyleSheet, Dimensions } from 'react-native';

let width = Dimensions.get('window').width;
let height = Dimensions.get('window').height;

let style_small = StyleSheet.create({
  logo_button: {
    marginLeft: 20
  },
  header_container: {
    backgroundColor: 'black',
    height: '50px',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 60,
    //paddingHorizontal: 20,
    zIndex: 2,
    borderBottomWidth: 0
    //borderBottomWidth: 1,
    //borderColor: '#dcd3d2'
  },
  logo_text: {
    fontFamily: 'Ysabeau',
    fontSize: '30px',
    color: 'white',
    borderLeftWidth: 5,
    borderColor: '#e32652',
    paddingLeft: 10,
    lineHeight: 36
    /* textShadowColor: 'rgba(0, 0, 0, 1)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 5 */
  },
  account_button_mobile: {
    paddingTop: 20, 
    paddingBottom: 20, 
    flexDirection: 'row', 
    justifyContent: 'space-between' 
  },
  account_button: { 
    paddingTop: 20, 
    paddingBottom: 20, 
    flexDirection: 'row', 
    justifyContent: 'space-between' 
  },
  account_email_button: { 
    display: 'none'
  },  
  account_drop: {
    backgroundColor: 'white',
    position: 'absolute',
    right: 40,
    top: 50,
    zIndex: 100,
    borderWidth: 1,
    borderRadius: 3,
    paddingVertical: 10
  },
  user_email: {
    fontFamily: 'Ysabeau',
    padding: 5,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc'
  },
  account_link_text: {
    fontFamily: 'Ysabeau',
    paddingVertical: 5,
    paddingHorizontal: 3,
    color: 'white'
  },
  right_buttons: {
    flexDirection: 'row',
    width: 152,
    justifyContent: 'space-around'
  }
});

let style_normal = StyleSheet.create({
  user_email: {
    fontFamily: 'Ysabeau',
    paddingHorizontal: 5,
    paddingBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc'
  },
  header_container: {
    backgroundColor: 'black',
    height: '50px',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 40,
    zIndex: 2,
    //borderBottomWidth: 1,
    //borderColor: '#dcd3d2'
  },
  logo_text: {
    fontFamily: 'Ysabeau',
    fontSize: '30px',
    color: 'white',
    borderLeftWidth: 5,
    borderColor: '#e32652',
    paddingLeft: 10,
    lineHeight: 30
    /* textShadowColor: 'rgba(0, 0, 0, 1)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 5 */
  },
  account_button: { 
    paddingTop: 20, 
    paddingBottom: 20, 
    flexDirection: 'row', 
    justifyContent: 'space-between' 
  },  
  account_email_button: { 
    paddingTop: 20, 
    paddingBottom: 20, 
    flexDirection: 'row', 
    justifyContent: 'space-between' 
  },  
  account_button_mobile: {
    display: 'none'
  },
  account_drop: {
    backgroundColor: 'white',
    position: 'absolute',
    right: 40,
    top: 50,
    zIndex: 100,
    borderWidth: 1,
    borderRadius: 3,
    paddingVertical: 10
  },
  account_link_text: {
    fontFamily: 'Ysabeau',
    paddingVertical: 5,
    paddingHorizontal: 20,
    color: 'white'
  },
  right_buttons: {
    flexDirection: 'row'
  }
});

let style = width < 1160 ? style_small : style_normal;

export {
  style
}