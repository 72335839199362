import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { View, Platform } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Text } from '../../components';
import authController from '../../controllers/authController';
import UserController from '../../controllers/userController';
import EventController from '../../controllers/eventController';
import Utilities from '../../utilities';
import { Header } from '../../containers';
import { style } from './style';

class Event_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password: null,
      tab: 0,
      terms: true
    }
  }

  async componentDidMount() {
    /* console.log('props', this.props);
    
    let slug = '';
    let event = await EventController.getEvent(slug);

    console.log('event', event); */
  }

  render() {

    return (
      <View style={style.page_container}>
        <Text>EVENTS page</Text>
      </View>
    );
  }
}

function EventScreen(props) {
  if (Platform.OS === 'web') {
    let navigate = useNavigate();
    return <Event_Base {...props} navigate={navigate} />
  } else {
    return <Event_Base {...props} navigate={props.navigation.push} />
  }
}

export default EventScreen;
